import Ajax from '../utils/ajax'

export default {
  // 获取开票最小额度
  getInvoiceMinAmount (params) {
    return Ajax.get('/api/euOrderInvoice/get/minAmount', { params })
  },

  // 保存开票规则
  saveInvoiceRule (params) {
    return Ajax.post('/api/euOrderInvoice/save/rule', params)
  },

  // 确认开具发票IIASAAS
  saveOpenedInvoiceByIIASAAS (params) {
    return Ajax.post('/api/platformOrderInvoice/confirmOpenInvoice', params)
  },

  // 获取客户余额管理
  getEuBalanceList (params) {
    return Ajax.post('/api/finance/list/page/euBalance', params)
  },

  // 同步更新用户余额
  syncEndUserBalance (params) {
    return Ajax.post('/api/finance/syncEndUserBalance', params)
  },

  // 获取用户余额流水
  getEuBalanceFlow (params) {
    return Ajax.post('/api/finance/list/page/euBalanceFlow', params)
  },

  // 用户提现申请列表
  getCashoutApplyList (params) {
    return Ajax.post('/api/finance/list/page/euBalanceCashoutApply', params)
  },

  // 用户余额提现确认
  cashoutConfirm (params) {
    return Ajax.post('/api/finance/cashoutConfirm', params)
  },

  // 财务确认退款
  confirmRefund (params) {
    return Ajax.post('/api/order/update/eu/afterSaleOrder/towLevelAudit', params)
  },

  // 财务获取售后订单列表
  getFinanceSaledOrderList (params) {
    return Ajax.post('/api/order/list/page/eu/afterSaleOrder/towLevelAudit', params)
  },

  // 保存客户信用设置
  saveEuCreditSet (params) {
    return Ajax.post('/api/finance/saveConfig', params)
  },

  // 获取eu用户账户汇总流水
  getEuAccountFlow (params) {
    return Ajax.post('/api/finance/list/page/euAccountFlow', params)
  },

  // 获取eu用户账单列表
  getEuCreditBillList (params) {
    return Ajax.post('/api/finance/list/page/euCreditBill', params)
  },

  // 获取eu用户信用支付流水
  getEuCreditFlow (params) {
    return Ajax.post('/api/finance/list/page/euCreditFlow', params)
  },

  // 人工余额充值
  saveBalanceRecharge (params) {
    return Ajax.post('/api/finance/euBalanceArtificialRecharge', params)
  },

  // 企业账户余额
  getEnterpriseBalance (params) {
    return Ajax.get('/api/finance/get/distributor/balance', { params })
  },

  // 企业提现申请
  saveCashoutApply (params) {
    return Ajax.post('/api/finance/pay/cashOut', params)
  },

  // 获取企业余额流水
  getEnterpriseBalanceFlow (params) {
    return Ajax.post('/api/finance/list/page/distributor/balance/flow', params)
  },

  // 云采购订单支付
  balancePayPurchOrder (params) {
    return Ajax.post('/api/finance/pay/balancePay', params)
  },

  // 获取平台信用信息
  getIIACredit (params) {
    return Ajax.get('/api/cloud/supply/getCredit', { params })
  },

  // 保存云平台信用设置
  savePlatCreditConfig (params) {
    return Ajax.post('/api/cloud/supply/saveCreditConfig', params)
  },

  // 获取云平台信用流水
  getPlatCreditFlow (params) {
    return Ajax.post('/api/cloud/supply/list/page/credit/flow', params)
  },

  // 获取云平台信用账单列表
  getPlatCreditBill (params) {
    return Ajax.post('/api/cloud/supply/list/page/credit/bill', params)
  },

  // 获取IIASaaS运费对账单
  getTransportBill (params) {
    return Ajax.post('/api/logistics/deppon/bill/listPage', params)
  },

  // 查询运费流水
  getTransportBillDetail (params) {
    return Ajax.post('/api/logistics/deppon/bill/listPageFlow', params)
  },

  // 余额支付运费账单
  savePayTransportCostByBalance (params) {
    return Ajax.post('/api/finance/pay/payLogisticServiceFee', params)
  },

  /** 保险START **/
  // 获取保险账单列表
  getInsuranceBillList (params) {
    return Ajax.post('/api/logistics/insure/listPagePinganBill', params)
  },

  // 获取平安保险流水
  getInsuranceBillDetail (params) {
    return Ajax.post('/api/logistics/insure/listPagePinganInsureFlow', params)
  }

/** 保险END **/
}
