import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Button, Upload, Input, message, Checkbox, Row } from 'antd'

import DiscernModal from '../discernModal/index'
import UploadApi from '@/common/api/uploadApi'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { getStore } from '@/common/utils/mUtils'

import styles from './index.module.scss'

const FormItem = Form.Item
const { TextArea } = Input
const { uploadManualSearchSku } = UploadApi
const { downManualTemp } = DownloadApi
const { repLineBreak } = filter
const sourceMap = [
  { value: 'needSpot', label: '现货' },
  { value: 'needFutures', label: '期货' },
  { value: 'needCloudSpot', label: '现货调货' },
  { value: 'needCloudFutures', label: '期货调货' }
]

class SearchForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    selectedEndInfo: PropTypes.object,
    handleSubmit: PropTypes.func,
    onUpload: PropTypes.func
  }
  state = {
    discernModal: false, // 图文识别弹窗
    params: {
      needSpot: true
    }
  }

  componentDidMount () {}

  // 点击搜索
  handleSearch = () => {
    const { form, selectedEndInfo = {}, handleSubmit } = this.props
    let source = this.props.form.getFieldValue('source') || []

    if (!selectedEndInfo.euId || +selectedEndInfo.euId === 0) {
      return message.warning('请选择客户')
    }
    if (source.length === 0) {
      return message.warning('请至少选择一种供货渠道')
    }
    let value = form.getFieldValue('textValue')

    if (value === undefined || value === '') {
      return message.warning('请输入您要下单的产品与数量')
    }

    let vals = repLineBreak(value, '<br>')
    let arr = vals.split('<br>')

    handleSubmit(arr, source)
  }

  // 点击下载模板
  handleDownloadManualTemp = () => {
    downManualTemp({})
  }

  // 上传产品信息
  handleChangeUploadSkuList = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        this.props.onUpload(data || [])
      } else {
        message.error('上传失败')
      }
    }
  }

  // 点击图文识别
  showIntelDiscernModal = () => {
    this.setState({
      discernModal: true
    })
  }

  // 复制到文本框
  confirmIntelResult = (str) => {
    setTimeout(() => {
      this.props.form.setFieldsValue({
        textValue: str
      })

      this.closeModal()
    }, 30)
  }

  closeModal = () => {
    this.setState({
      discernModal: false
    })
  }

  getvalues = (e) => {
    let params = {}
    e.map((key) => { params[key] = true })

    this.setState({
      params: params
    })
  }

  render () {
    const { selectedEndInfo } = this.props
    const { getFieldDecorator } = this.props.form
    const { discernModal = false, params = {} } = this.state

    return (
      <>

        <Card bodyStyle={{ padding: '15px' }}>

          <Form>
            <Row className={`${styles.formitemRow}`}>
              <FormItem label='您需要哪种渠道' style={{ marginBottom: '2px' }}>
                {
                  getFieldDecorator('source', {
                    initialValue: ['needSpot'],
                    rules: [
                      { required: true, message: '请至少选择一种渠道' }
                    ]
                  })(
                    <Checkbox.Group options={sourceMap} onChange={(e) => { this.getvalues(e) }} />
                  )
                }
              </FormItem>
            </Row>

            <FormItem style={{ marginBottom: '6px' }}>
              {
                getFieldDecorator('textValue', {

                })(
                  <TextArea placeholder='请输入型号或订货号，并且使用双等号将数量隔开...例如：&#10;RXM2CB2BD==10&#10;10000498==5' autoComplete='off'
                    rows={6}
                  />
                )
              }
            </FormItem>
            <FormItem style={{ marginBottom: '0px' }}>
              <Button type='primary' style={{ width: '110px' }} onClick={() => this.handleSearch()}>搜索</Button>

              <Upload name='file' showUploadList={false}
                headers={{
                  Token: getStore('token')
                }}
                data={{ ...params, endUserId: selectedEndInfo.euId }}
                accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                action={uploadManualSearchSku}
                onChange={this.handleChangeUploadSkuList}
              >
                <Button type='primary' style={{ width: '110px', marginLeft: '12px' }} disabled={!selectedEndInfo.euId}>导入产品</Button>
              </Upload>

              <Button type='primary' style={{ width: '110px', marginLeft: '12px' }} onClick={() => this.handleDownloadManualTemp()}>下载模板</Button>

              <Button type='primary' style={{ width: '110px', marginLeft: '12px' }} onClick={() => this.showIntelDiscernModal()}>图文识别</Button>
            </FormItem>
          </Form>
        </Card>

        {
          discernModal
            ? <DiscernModal
              onConfirm={(params) => this.confirmIntelResult(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const BatchSearch = Form.create()(SearchForm)

export default BatchSearch
