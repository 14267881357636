import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Breadcrumb, Icon, Avatar, Divider, Badge, Button, Dropdown, Row, Tabs, Empty } from 'antd'
import { setUserInfo, setNoticeDetail, setUnnoticeCount } from '@/reducers/action'

import { removeStore, renderNotifyTypeLabel } from '@/common/utils/mUtils'
import { routerMap } from '@/routes/router'
import Api from '@/common/api/index'

import styles from './headerbar.module.scss'

const { TabPane } = Tabs

class HeaderBar extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    dispatch: PropTypes.func,
    userInfo: PropTypes.object,
    unnoticeCount: PropTypes.number
  }

  state = {
    unreadList: [],
    noticeVisible: false // 消息下拉窗
  }

  componentDidMount () {

  }

  // 获取已接收的消息列表
  getReceivedNotifysList = () => {
    let params = {
      msgType: 'ALL',
      msgReadType: 'UNREAD',
      pageNo: 1,
      pageSize: 10
    }
    Api.getReceivedNotifysList(params, { HideLoading: true, NoError: true }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _list = []
        if (data && data.list) {
          _list = data.list.slice(0, 4)
        }
        this.setState({
          unreadList: _list
        })
      }
    })
  }

  handleJumpLink = () => {
    this.props.history.push('/home')
  }

  handleLoginOut = () => {
    removeStore('token')
    this.props.dispatch(setUserInfo({ userInfo: {} }))
    this.props.dispatch(setUnnoticeCount({ unnoticeCount: 0 }))
    this.props.history.push('/login')
  }

  // 点击跳转到消息详情
  handleLinkToNotifyDetail = (record) => {
    this.setState({
      noticeVisible: false
    }, () => {
      this.props.dispatch(setNoticeDetail({ noticeDetail: record }))
      this.props.history.push({ pathname: '/admin/notifyDetail' })
    })
  }

  // 点击查看更多消息
  handleLinkToInformationList = () => {
    this.setState({
      noticeVisible: false
    }, () => {
      this.props.history.push({ pathname: '/admin/informationList', state: { activeTab: 'RECEIVED' } })
    })
  }

  // 监听下拉菜单click事件
  changeNoticeVisible = (visible) => {
    this.setState({
      noticeVisible: visible
    }, () => {
      if (visible) {
        this.getReceivedNotifysList()
      }
    })
  }

  // 消息下拉窗
  renderDropdownMenuElement = (unreadList) => {
    return (
      <Tabs>
        <TabPane tab={'通知消息'} key='dropTabPane-1'>
          <Row className={`${styles.noticeDropWrap}`}>
            <Row className={`${styles.noticeDropContainer}`}>
              {
                unreadList.length > 0
                  ? <Row>
                    <Row className={`${styles.noticeHeader}`}>
                      <div className={`${styles.dropTitle}`}>未读消息</div>
                      <div className={`${styles.dropAction}`} onClick={() => this.handleLinkToInformationList()}>查看更多</div>
                    </Row>

                    <div className={`${styles.noticeBody}`}>
                      {
                        unreadList.map((item, i) => (
                          <a className={`${styles.noticeItem}`} key={`unnotice-${item.id}-${i}`}
                            onClick={() => this.handleLinkToNotifyDetail(item)}
                          >
                            <span>{ renderNotifyTypeLabel(item.msgType) }</span>
                            <span style={{ marginLeft: '5px' }}>{item.title}</span>
                          </a>
                        ))
                      }
                    </div>
                  </Row>
                  : <Row>
                    <Empty description='暂无消息' />
                  </Row>
              }
            </Row>
          </Row>
        </TabPane>
      </Tabs>
    )
  }

  render () {
    const { location, userInfo = {}, unnoticeCount = 0 } = this.props
    const { unreadList = [], noticeVisible = false } = this.state

    return (
      <div className={`${styles.headerBar}`}>
        <div className={`${styles.customBread}`}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to='/admin/home'>首页</Link>
            </Breadcrumb.Item>
            {
              routerMap.map((route, i) => {
                if (route.path && location.pathname.match(route.path)) {
                  return (<Breadcrumb.Item key={i}>{route.name}</Breadcrumb.Item>)
                }
              })
            }
          </Breadcrumb>
        </div>

        <div className={`${styles.customCon}`}>
          <Dropdown trigger={['click']} visible={noticeVisible}
            overlay={this.renderDropdownMenuElement(unreadList)}
            onVisibleChange={this.changeNoticeVisible}
          >
            <Button type='link'>
              <Badge count={unnoticeCount}>
                <Icon type='bell' style={{ fontSize: '16px' }} />
              </Badge>
              <span style={{ marginLeft: '2px' }}>系统消息</span>
            </Button>
          </Dropdown>

          <Button type='link' target='_blank'
            href={'https://picture.china-iia.com/instructions/2020-05-30%E5%95%86%E5%9F%8E%E5%90%8E%E5%8F%B0%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8CV1.10.9%28%E5%8F%91%E5%B8%83%E7%89%88%E6%9C%AC%29.pdf'}>
            <Icon type='file-unknown' /><span style={{ marginLeft: '2px' }}>帮助文档</span>
          </Button>

          <Button type='link' target='_blank'
            href={`http://wpa.qq.com/msgrd?v=3&uin=3248773436&site=qq&menu=yes`}
          >
            <Icon type='qq' /><span style={{ marginLeft: '2px' }}>QQ客服</span>
          </Button>

          <div className={`${styles.inline} ${styles.userDropdown}`}>
            <div>
              <Avatar size={24} />
              <span style={{ marginLeft: '5px' }}>{userInfo.account}</span>
            </div>
          </div>
          <Divider type='vertical' />
          <div className={`${styles.inline} ${styles.logout}`}>
            <Icon type='poweroff' style={{ padding: '5px' }}
              onClick={() => this.handleLoginOut()}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.userInfo,
    unnoticeCount: state.unnoticeCount
  }
}

export default connect(mapStateToProps)(withRouter(HeaderBar))
