import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Progress, Badge, Row, Divider, Button, Empty } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import Api from '@/common/api/index'

const taskDescMaps = {
  MODEL_LIBRARY: [
    { type: 'PUT_ON_SHELVES', label: '上架操作', desc: '产品上架' },
    { type: 'PULL_OFF_SHELVES', label: '下架操作', desc: '产品下架' },
    { type: 'PRODUCT_ADD', label: '添加到产品库操作', desc: '从型号库添加至我的产品库' },
    { type: 'PRODUCT_DELETE', label: '删除操作', desc: '产品删除' }
  ],
  PRODUCT_LIBRARY: [
    { type: 'PUT_ON_SHELVES', label: '上架操作', desc: '产品上架' },
    { type: 'PULL_OFF_SHELVES', label: '下架操作', desc: '产品下架' },
    { type: 'PRODUCT_DELETE', label: '删除操作', desc: '产品删除' }
  ],
  SPOTPRICE_COMMON_GROUP: [
    { type: 'EU_COMMON_PRICE_GROUP', label: '通用价格组设置', desc: 'EU用户现货通用价格组设置' }
  ],
  SPOTPRICE_GUEST_GROUP: [
    { type: 'EU_GUEST_PRICE_GROUP', label: '客户价格组设置', desc: 'EU用户现货客户价格组设置' }
  ],
  FUTURESPRICE_COMMON_GROUP: [
    { type: 'EU_FUTURE_COMMON_PRICE_GROUP', label: '通用价格组设置', desc: 'EU用户期货通用价格组设置' }
  ],
  FUTURESPRICE_GUEST_GROUP: [
    { type: 'EU_FUTURE_GUEST_PRICE_GROUP', label: '客户价格组设置', desc: 'EU用户期货客户价格组设置' }
  ]
}

const syncStatusMaps = [
  { id: 'SYNC_SUCCESS', name: '同步成功', badge: 'success' },
  { id: 'SYNC_FAIL', name: '同步失败', badge: 'error' },
  { id: 'SYNC_ACCEPTED', name: '待同步', badge: 'processing' }
]

export default class ProgressModal extends Component {
  static propTypes = {
    taskKey: PropTypes.string,
    onCancel: PropTypes.func
  }
  state = {
    tableData: []
  }

  componentWillMount () {
    this.getProductTaskProgress()
  }

  componentWillUnmount () {}

  // 获取进度
  getProductTaskProgress = async () => {
    const { taskKey } = this.props
    const arr = taskDescMaps[taskKey]
    const asyncTaskTypeList = $lodash.map(arr, (o) => { return o.type })

    const res = await Api.getProductTaskProgress(asyncTaskTypeList)
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data || []
      })
    }
  }

  // 渲染任务描述
  renderTaskDescText = (type) => {
    const { taskKey } = this.props
    const arr = taskDescMaps[taskKey]

    const obj = arr.find(item => { return item.type === type })
    const str = obj !== undefined ? obj.desc : ''

    return str
  }

  renderSyncStatusBadgeNode = (record) => {
    const obj = syncStatusMaps.find((o) => { return o.id === record.syncStatus })

    return obj !== undefined ? <Badge status={obj.badge} text={<><label className='label'>执行结果</label><span>{ obj.name }</span></>} /> : null
  }

  render () {
    const { taskKey, onCancel } = this.props
    const { tableData = [] } = this.state

    return (
      <>
        <Modal title='任务进度'
          bodyStyle={{ padding: '16px 24px', height: '400px', overflow: 'auto' }}
          width={800}
          visible
          onCancel={() => onCancel()}
          footer={[
            <Button key={`progressBtn-1`} onClick={() => onCancel()}>关闭</Button>,
            <Button key={`progressBtn-2`} type='primary' onClick={() => this.getProductTaskProgress()}>刷新</Button>
          ]}
        >
          {
            tableData.length > 0
              ? <ul>
                {
                  tableData.map((item, i) => (
                    <li key={`progressItem-${i}`}>
                      <Row>
                        <div style={{ marginBottom: '10px' }}>
                          <div style={{ marginBottom: '5px' }}>任务号：{item.id}</div>
                          <div style={{ marginBottom: '5px' }}>
                            <span>任务描述：</span>
                            <span>{this.renderTaskDescText(item.type)}</span>
                          </div>
                          <div>
                            <span style={{ marginRight: '15px' }}>操作人：{item.createName}</span>
                            <span>操作时间：{moment(item.createTime).format('YYYY-MM-DD hh:mm:ss')}</span>
                          </div>
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                          {
                            ['SPOTPRICE_COMMON_GROUP', 'SPOTPRICE_GUEST_GROUP', 'FUTURESPRICE_COMMON_GROUP', 'FUTURESPRICE_GUEST_GROUP'].includes(taskKey)
                              ? <>
                                <Badge status='warning' text={`设置总数：${item.taskNum}`} style={{ marginRight: '20px' }} />
                                { this.renderSyncStatusBadgeNode(item) }
                              </>
                              : <>
                                <Badge status='warning' text={`添加总数：${item.taskNum}`} style={{ marginRight: '20px' }} />
                                <Badge status='success' text={`已成功数：${item.syncNum}`} style={{ marginRight: '20px' }} />
                                <Badge status='processing' text={`待处理数：${item.taskNum - item.syncNum}`} />
                              </>
                          }
                        </div>
                        <div>
                          <Progress
                            percent={+item.taskNum > 0 ? ((+item.syncNum / +item.taskNum) * 100).toFixed(2) : 0.00}
                          />
                        </div>
                      </Row>
                      {
                        i === tableData.length - 1
                          ? null
                          : <Divider />
                      }
                    </li>
                  ))
                }
              </ul>
              : <Empty />
          }

        </Modal>
      </>
    )
  }
}
