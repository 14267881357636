import React, { Component } from 'react'
import { Card, Row, List } from 'antd'

export default class MyTask extends Component {
  static propTypes = {}
  state = {
    data: [
      {
        title: '财务',
        list: [
          {
            name: '订单付款',
            nums: 10,
            type: 10
          },
          {
            name: '订单退款',
            nums: 100,
            type: 11
          }
        ]
      },
      {
        title: '管理',
        list: [
          {
            name: '新特价审批',
            nums: 10,
            type: 20
          }
        ]
      },
      {
        title: '仓库',
        list: [
          {
            name: '订单发货',
            nums: 10,
            type: 30
          }
        ]
      }
    ]
  }

  render () {
    const { data = [] } = this.state
    return (
      <div>
        {
          data.map((row, index) => (
            <Row key={`task-${index}`} style={{ marginBottom: '30px' }}>
              <Card title={`待处理事务--${row.title}`}>
                <List
                  dataSource={row.list}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta title={item.name} />
                      <div>{item.nums}</div>
                    </List.Item>
                  )}
                />
              </Card>
            </Row>
          ))
        }
      </div>
    )
  }
}
