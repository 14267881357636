import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Card, Table, Button, Icon, Modal, Badge, message } from 'antd'
import $lodash from 'lodash'

import ResultModal from './resultModal/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const { TabPane } = Tabs
const tabPanneMaps = [
  { id: 'ALL', name: '全部数据' },
  { id: 'SUCCESS', name: '匹配成功', color: '#52c41a' },
  { id: 'FAIL', name: '匹配失败', color: '#f5222d' },
  { id: 'RESEMBLE', name: '近似型号', color: '#faad14' }
]

export default class BatchSearchResult extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    activeKey: 'ALL',
    originData: [],
    similarDataList: [], // 相似列表
    tableData: [],
    rowDetail: {},
    firstSign: false,
    resultModal: false
  }

  columns = [
    {
      title: '序号',
      width: 40,
      align: 'center',
      key: 'searchResultCol-0',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '输入数据',
      key: 'searchResultCol-1',
      dataIndex: 'inputData'
    },
    {
      title: '品牌',
      key: 'searchResultCol-2',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'searchResultCol-3',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'searchResultCol-4',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'searchResultCol-5',
      dataIndex: 'name'
    },
    {
      title: '产品类型',
      key: 'searchResultCol-6',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '匹配状态',
      key: 'searchResultCol-7',
      render: (text, record, index) => (
        <>{this.renderMatchStatus(record.matchResult)}</>
      )
    },
    {
      title: '云仓价格',
      key: 'searchResultCol-8',
      render: (text, record, index) => (
        <>
          {
            record.supplyInfoList && record.supplyInfoList.length > 0
              ? <>
                {
                  record.supplyInfoList.map((item, n) => (
                    <div key={`${record.commoditySkuId}-${index}-${n}`}>&yen;&nbsp;{(+item.platformPrice * 100 / 100).toFixed(2)}</div>
                  ))
                }
              </>
              : '----'
          }
        </>
      )
    },
    {
      title: '库存数量',
      key: 'searchResultCol-9',
      render: (text, record, index) => (
        <>
          {
            record.supplyInfoList && record.supplyInfoList.length > 0
              ? <>
                {
                  record.supplyInfoList.map((item, n) => (
                    <div key={`${record.commoditySkuId}-${index}-${n}`}>{ +item.stock }</div>
                  ))
                }
              </>
              : '----'
          }
        </>
      )
    },
    {
      title: '上架状态',
      key: 'searchResultCol-10',
      render: (text, record, index) => (
        <>
          {
            record.cloudPurchaseStatus === 'ENABLE'
              ? <Badge status='success' text={<span style={{ fontSize: '12px' }}>已上架</span>} />
              : <Badge status='error' text={<span style={{ fontSize: '12px' }}>未上架</span>} />
          }
        </>
      )
    },
    {
      title: '操作',
      key: 'searchResultCol-11',
      render: (text, record, index) => (
        <>
          {
            record.matchResult !== 'FAIL'
              ? <>
                {
                  record.cloudPurchaseStatus === 'ENABLE'
                    ? <Button size='small' type='primary' onClick={() => this.handlePutOff(record, record.skuGoodsType === 'CLOUD_SPOT' ? 'updateStockStatus' : 'updateFuturesStatus', index)}>下架</Button>
                    : <Button size='small' type='primary' onClick={() => this.handlePutOn(record, record.skuGoodsType === 'CLOUD_SPOT' ? 'updateStockStatus' : 'updateFuturesStatus', index)}>上架</Button>
                }
              </>
              : null
          }
        </>
      )
    }
  ]

  componentDidMount () {
    const { state } = this.props.history.location

    this.getCloudLibraryBatchSearchResult({ ...state.query, pageNo: 1, pageSize: 999 })
  }

  // 获取搜索结果
  getCloudLibraryBatchSearchResult = async (params) => {
    const res = await Api.getCloudLibraryBatchSearchResult(params)
    const { code, data } = res
    if (+code === 10000) {
      const { firstSign = false } = this.state
      let newdata = []
      let similarData = []

      if (data && data.allDataList && data.allDataList.length > 0) {
        newdata = $lodash.map(data.allDataList, (record) => {
          const { matchData = {}, inputData, matchResult } = record
          return {
            inputData: inputData,
            matchResult: matchResult,
            ...matchData
          }
        })
      }

      if (data && data.similarDataList && data.similarDataList.length > 0) {
        similarData = $lodash.map(data.similarDataList, (record) => {
          const { matchData = {}, inputData } = record
          return {
            inputData: inputData,
            matchResult: 'RESEMBLE',
            ...matchData
          }
        })
      }

      this.setState({
        originData: newdata,
        similarDataList: similarData,
        tableData: newdata,
        totalNum: data.totalNum || 0,
        successNum: data.successNum || 0,
        failNum: data.failNum || 0,
        similarNum: data.similarNum || 0,
        firstSign: true,
        resultModal: !firstSign
      })
    }
  }

  // 云库产品上架
  saveCloudPurchasePutOnShelves = async (record, type, index) => {
    const params = {
      commoditySkuIdList: [record.commoditySkuId],
      [type]: 'ENABLE'
    }
    const res = await Api.saveCloudPurchasePutOnShelves(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      const { tableData = [] } = this.state

      tableData[index].cloudPurchaseStatus = 'ENABLE'
      this.setState({
        tableData
      })
    } else {
      message.warning(res.message)
    }
  }

  // 云库产品下架
  saveCloudPurchasePutOffShelves = async (record, type, index) => {
    const params = {
      commoditySkuIdList: [record.commoditySkuId],
      [type]: 'DISABLE'
    }

    const res = await Api.saveCloudPurchasePutOffShelves(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      const { tableData = [] } = this.state
      tableData[index].cloudPurchaseStatus = 'DISABLE'

      this.setState({
        tableData
      })
    } else {
      message.warning(res.message)
    }
  }

  // 点击切换tab
  handleChangeTabs = (key) => {
    const { originData = [], similarDataList = [] } = this.state
    let newdata = originData

    if (key === 'RESEMBLE') {
      newdata = similarDataList
    } else if (key === 'SUCCESS' || key === 'FAIL') {
      newdata = $lodash.filter(originData, (o) => { return o.matchResult === key })
    }

    this.setState({
      tableData: newdata,
      activeKey: key
    })
  }

  // 点击上架按钮
  handlePutOn = (record, type, index) => {
    this.saveCloudPurchasePutOnShelves(record, type, index)
  }

  handlePutOff = (record, type, index) => {
    Modal.confirm({
      title: '确定下架？',
      onOk: () => {
        this.saveCloudPurchasePutOffShelves(record, type, index)
      }
    })
  }

  // 点击导出明细
  handleDownload = () => {
    const { state } = this.props.history.location
    DownloadApi.downCloudLibraryBatchSearchResult({ ...state.query, pageNo: 1, pageSize: 999 })
  }

  renderMatchStatus = (status) => {
    const obj = $lodash.find(tabPanneMaps, (o) => { return o.id === status })
    const el = obj !== undefined ? <span style={{ color: obj.color }}>{obj.name}</span> : '----'

    return el
  }

  handleback = () => {
    this.props.history.go(-1)
  }

  closeModal = () => {
    this.setState({
      resultModal: false
    })
  }

  render () {
    const { tableData = [], resultModal = false, totalNum = 0, successNum = 0, failNum = 0, similarNum = 0 } = this.state

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Button onClick={() => this.handleback()}><Icon type='arrow-left' />返回</Button>
        </div>

        <div style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '15px 15px 0px 15px' }}>
            <div className={`${styles.tabLand}`}>
              <Tabs onChange={(key) => this.handleChangeTabs(key)}
                tabBarExtraContent={<Button type='primary' onClick={() => this.handleDownload()}><Icon type='download' />导出明细</Button>}
              >
                {
                  tabPanneMaps.map((item, i) => (
                    <TabPane tab={item.name} key={item.id} />
                  ))
                }
              </Tabs>
            </div>
          </Card>
        </div>

        <Card bodyStyle={{ padding: '15px' }}>
          <Table rowKey={(record, index) => `${record.inputData}-${index}`}
            dataSource={tableData}
            columns={this.columns}
            pagination={false}
          />
        </Card>

        {
          resultModal
            ? <ResultModal
              total={totalNum}
              success={successNum}
              fail={failNum}
              resemble={similarNum}
              onConfirm={() => this.handleDownload()}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}
