import React, { Component } from 'react'
import { Card, Row, Table, message, Button } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import QuoteModal from '../component/quote-modal'

import Api from '@/common/api'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'

const { downQuote } = DownloadApi
const { pageSizeOptions } = filter

export default class EubomList extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'endUserId',
        formType: 'Select',
        itemParams: {
          label: '客户名称',
          help: ''
        },
        cptParams: {
          showSearch: true,
          placeholder: '请输入...',
          autoComplete: 'off',
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['endUserId', 'showName']
      }
    ],
    quoteModal: false,
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {}
  }

  columns = [
    {
      title: '序号',
      key: 'quoteList-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '报价单编号',
      key: 'quoteList-1',
      dataIndex: 'serialNumber'
    },
    {
      title: '创建时间',
      key: 'quoteList-2',
      render: (text, record, index) => (
        <div>
          {record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----'}
        </div>
      )
    },
    {
      title: '操作',
      key: 'quoteLis-3',
      width: 200,
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small' style={{ marginRight: '8px' }}
            onClick={() => this.showQuoteDetailModal(record)}
          >
            查看详情
          </Button>

          <Button type='primary' size='small'
            onClick={() => this.handleDownloadQuote(record)}
          >
            导出报价单
          </Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getGuesteuUserLike()
  }

  // 查询客户信息
  getGuesteuUserLike = (val = '') => {
    let params = {
      pageNo: 1,
      pageSize: 999,
      searchName: val
    }

    Api.getGuesteuUserLike(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const { searchData } = this.state
        searchData[0].options = data.list || []

        this.setState({
          searchData
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 获取报价单
  getEndUserQuoteList = () => {
    const { pages, query = {} } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getEndUserQuoteList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 获取报价单详情
  getQuoteDetail = (params) => {
    return Api.getQuoteDetail(params)
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (!search.endUserId) {
      return message.warning('请选择客户')
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getEndUserQuoteList()
    })
  }

  // 点击导出报价单
  handleDownloadQuote = (record) => {
    const { query } = this.state
    downQuote({ euQuotationId: record.euQuotationId, endUserId: query.endUserId })
  }

  // 点击查看详情
  showQuoteDetailModal = (record) => {
    const { query } = this.state
    let obj = { ...record, endUserId: query.endUserId }

    this.setState({
      quoteModal: true,
      rowDetail: obj || {}
    })
  }

  closeModal = () => {
    this.setState({
      quoteModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEndUserQuoteList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEndUserQuoteList()
    })
  }

  render () {
    const { searchData, pages, query, tableData = [], quoteModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Row>

          <Row>
            <Table rowKey='euQuotationId'
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination} />
          </Row>
        </Card>

        {
          quoteModal
            ? <QuoteModal
              detailInfo={{ ...rowDetail, endUserId: query.endUserId }}
              getDetail={(params) => this.getQuoteDetail(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}
