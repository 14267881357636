import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Descriptions, Divider } from 'antd'
import filter from '@/common/utils/filter'

const { shipmentUtilMap } = filter

export default class OperateInfo extends Component {
  static propTypes = {
    operateType: PropTypes.number,
    detailInfo: PropTypes.object
  }
  state = {}

  componentDidMount () { }

  render () {
    const { detailInfo = {}, operateType = 0 } = this.props

    return (
      <div>
        <Row>
          <Descriptions>
            {
              operateType === 1 || operateType === 5
                ? <Descriptions.Item label='上架类型'>
                  {
                    detailInfo.updateStockStatus === 'ENABLE'
                      ? <span style={{ marginRight: '8px' }}>现货</span>
                      : null
                  }

                  {
                    detailInfo.updateFuturesStatus === 'ENABLE'
                      ? <span>期货</span>
                      : null
                  }

                </Descriptions.Item>
                : null
            }

            {
              detailInfo.updateFuturesStatus === 'ENABLE'
                ? <Descriptions.Item label='期货参数'>
                  <span>货期:<span style={{ marginLeft: '3px' }}>
                    {detailInfo.updateDeliveryPeriodDays}{ shipmentUtilMap['FUTURES']}</span>
                  </span>
                  <Divider type='vertical' />
                  <span>预付款:<span style={{ marginLeft: '3px' }}>
                    {detailInfo.updatePrepaymentRatio}%</span>
                  </span>
                </Descriptions.Item>
                : null
            }

            {
              operateType === 2
                ? <Descriptions.Item label='下架类型'>
                  {
                    detailInfo.updateStockStatus === 'DISABLE'
                      ? <span style={{ marginRight: '8px' }}>现货</span>
                      : null
                  }

                  {
                    detailInfo.updateFuturesStatus === 'DISABLE'
                      ? <span>期货</span>
                      : null
                  }
                </Descriptions.Item>
                : null
            }

          </Descriptions>
        </Row>
      </div>
    )
  }
}
