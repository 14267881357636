import React, { Component } from 'react'
import { Card, Table, message, Button } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import RoleModal from '../component/role-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter
const personStatusMap = [
  { type: 'ALL', code: '-1', label: '全部' },
  { type: 'ENABLED', code: '0', label: '可用' },
  { type: 'FREEZE', code: '1', label: '冻结' }
]

export default class DepartList extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'account',
        formType: 'Input',
        itemParams: {
          label: '用户名'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'realName',
        formType: 'Input',
        itemParams: {
          label: '员工姓名'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'phone',
        formType: 'Input',
        itemParams: {
          label: '手机号码'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '账号状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: personStatusMap,
        optionValue: ['type', 'label']
      }
    ],
    roleModal: false,
    type: 1,
    query: {},
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'setRole-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '登录名称',
      key: 'setRole-1',
      dataIndex: 'account'
    },
    {
      title: '员工姓名',
      key: 'setRole-2',
      dataIndex: 'realName'
    },
    {
      title: '联系方式',
      key: 'setRole-3',
      dataIndex: 'phone'
    },
    {
      title: '角色',
      key: 'serRole-4',
      render: (text, record, index) => (
        <>{ this.renderPersonRoleName(record)}</>
      )
    },
    {
      title: '添加时间',
      key: 'setRole-5',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '最后登录时间',
      key: 'setRole-6',
      render: (text, record, index) => (
        <div>
          {
            +record.lastLoginTime > 0
              ? moment(record.lastLoginTime).format('YYYY-MM-DD HH:mm:ss')
              : null
          }
        </div>
      )
    },
    {
      title: '操作',
      key: 'depart-6',
      width: 200,
      render: (text, record, index) => (
        <>
          <Button type='primary' size='small' onClick={() => this.showSetRoleModal(record)}>
            设置角色
          </Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getMemberList()
  }

  // 获取员工列表
  getMemberList = () => {
    const { pages, query } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getMemberList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  getRoleList = (params) => {
    return Api.getRoleList(params)
  }

  // 保存员工角色设置
  saveSetPersonRole = (params) => {
    Api.setPersonAuthor(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.closeModal()
        this.getMemberList()
      }
    })
  }

  handleSearch = (search = {}) => {
    const { pages } = this.state
    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1,
        total: 0
      }
    }, () => {
      this.getMemberList()
    })
  }

  // 点击 设置角色
  showSetRoleModal = (record) => {
    this.setState({
      roleModal: true,
      rowDetail: record || {}
    })
  }

  renderPersonRoleName = (record = {}) => {
    let { roleInfoList = [] } = record
    let str = ''

    if (roleInfoList && roleInfoList.length > 0) {
      let arr = []
      roleInfoList.map(item => {
        arr.push(item.roleName)
      })

      str = arr.join(',')
    }

    return str
  }

  closeModal = () => {
    this.setState({
      roleModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getMemberList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getMemberList()
    })
  }

  render () {
    const { searchData, tableData = [], pages, roleModal = false, rowDetail = {}, type = 1 } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '15px' }}>
          <Table rowKey='supplierUserId'
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
          />
        </Card>

        {
          roleModal
            ? <RoleModal
              detailInfo={rowDetail}
              type={type}
              getRoleList={(params) => this.getRoleList(params)}
              onConfirm={(params) => this.saveSetPersonRole(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}
