import React, { Component } from 'react'
import { Card, Row, Tabs } from 'antd'

import MallBase from './mallBase'
import MerchantBase from '../component/merchant-base'
import ComponyIntro from '../component/compony-intro'
import RealpriceSet from '../component/realprice-set'

import Api from '@/common/api/index'

const { TabPane } = Tabs

const mallBaseTabMap = [
  { name: '商城配置信息', key: 'mallConfig1' },
  { name: '商城基本信息', key: 'mallConfig2' },
  { name: '公司介绍配置', key: 'mallConfig3' },
  { name: '真实价格配置', key: 'mallConfig4' }
]

class MallBaseForm extends Component {
  static propTypes = {

  }

  state = {
    curTabkey: 'mallConfig1'
  }

  componentDidMount () {

  }

  // 获取商户基本信息
  getMerchantBaseInfo = (params) => {
    return Api.getMerchantBaseInfo(params)
  }

  // 切换tab
  changeTab = (key) => {
    this.setState({
      curTabkey: key
    })
  }

  render () {
    const { curTabkey = 'mallConfig1' } = this.state

    return (
      <div>
        <Card bodyStyle={{ padding: '15px' }}>
          <Row>
            <Tabs activeKey={curTabkey} onChange={this.changeTab}>
              {
                mallBaseTabMap.map((item) => (
                  <TabPane tab={item.name} key={item.key} />
                ))
              }
            </Tabs>
          </Row>
          {
            curTabkey === 'mallConfig1'
              ? <MallBase />
              : null
          }

          {
            curTabkey === 'mallConfig2'
              ? <MerchantBase getDetail={(params) => this.getMerchantBaseInfo(params)} />
              : null
          }

          {
            curTabkey === 'mallConfig3'
              ? <ComponyIntro />
              : null
          }

          {
            curTabkey === 'mallConfig4'
              ? <RealpriceSet />
              : null
          }

        </Card>
      </div>
    )
  }
}

export default MallBaseForm
