import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Button, message, Row } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import PurchdetailModal from '../component/purchdetail-modal'
import Descriptions from './../../order/euorder/descriptions'
import Updowm from '../../order/component/down'
import DetailModal from './detailModal'
import SaledDrawer from './saledDrawer/index'
import OrderEnclosureDrawer from '@/pages/order/component/orderEnclosureDrawer/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import { renderAddressLabel, renderOrderStatusLabel, renderOrderPaymentStatusLabel, renderOrderDeliveryStatusLabel, renderLogisticsWayLabel } from '@/common/utils/mUtils'
import filter from '@/common/utils/filter'

const { downOrderUrlcloudPurchase } = DownloadApi
const { pageSizeOptions, orderStatusMaps, orderDeliveryStatusMaps, orderPaymentStatusMaps } = filter

export default class PaymentList extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    summary: {},
    detailModal: false,
    searchData: [
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...'
        }
      },
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {

        }
      },
      {
        name: 'orderStatusList',
        formType: 'Select',
        itemParams: {
          label: '订单状态'
        },
        cptParams: {
          placeholder: '请选择...',
          mode: 'multiple',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderStatusMaps
        ]
      },
      {
        name: 'paymentStatus',
        formType: 'Select',
        itemParams: {
          label: '付款状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderPaymentStatusMaps
        ]
      },
      {
        name: 'deliveryStatus',
        formType: 'Select',
        itemParams: {
          label: '发货状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderDeliveryStatusMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    purchDetailModal: false,
    customModal: false,
    saledModal: false, // 售后弹窗
    enclosureModal: false, // 订单附件弹窗
    rowDetail: {},
    expandedRowKeys: [] // 控制展开的行
  }

  columns = [
    {
      title: '序号',
      key: 'purchaseOrderCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '订单日期',
      key: 'purchaseOrderCol-1',
      render: (text, record, index) => (
        <>
          {record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : null}
        </>
      )
    },
    {
      title: '订单编号',
      key: 'purchaseOrderCol-2',
      dataIndex: 'orderId'
    },
    {
      title: '订单金额',
      key: 'purchaseOrderCol-3',
      dataIndex: 'payableAmount',
      render: (text, record, index) => (
        <>
          &yen;{(+record.payableAmount * 100 / 100).toFixed(2)}
        </>
      )
    },
    {
      title: '已付金额',
      key: 'purchaseOrderCol-4',
      dataIndex: 'paidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.paidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '未付金额',
      key: 'purchaseOrderCol-5',
      dataIndex: 'unpaidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.unpaidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '订单状态',
      key: 'purchaseOrderCol-6',
      dataIndex: 'orderStatus',
      render: (text, record, index) => (
        renderOrderStatusLabel(record.orderStatus)
      )
    },
    {
      title: '付款状态',
      key: 'purchaseOrderCol-7',
      render: (text, record, index) => (
        <>
          {renderOrderPaymentStatusLabel(record.paymentStatus)}
        </>
      )
    },
    {
      title: '发货状态',
      key: 'purchaseOrderCol-8',
      render: (text, record, index) => (
        <>{ renderOrderDeliveryStatusLabel(record.deliveryStatus) }</>
      )
    },
    {
      title: '订单附件',
      key: 'purchaseOrderCol-9',
      render: (text, record, index) => (
        <>
          <Button type='primary' size='small' onClick={() => this.showOrderEnclosureModal(record)}>点击查看</Button>
        </>
      )
    },
    {
      title: '操作',
      key: 'purchaseOrderCol-10',
      width: 200,
      render: (text, record, index) => {
        return (
          <>
            <Button type='primary' size='small' onClick={() => this.handleShowPurchDetail(record)}>查看明细</Button>

            <Button type='primary' size='small' style={{ marginLeft: '6px' }} onClick={() => this.handleDownloadOpen(record)}>下载合同</Button>

            {
              record.orderStatus === 'ORDER_COMPLETE'
                ? <Button type='primary' size='small' style={{ marginTop: '6px' }} disabled={!record.afterSaleDetail.isCompleted} onClick={() => this.showSaledFormModal(record)}>
                  {
                    record.afterSaleDetail && record.afterSaleDetail.isCompleted
                      ? '申请售后'
                      : '售后中'
                  }
                </Button>
                : null
            }
          </>
        )
      }
    }
  ]

  componentDidMount () {
    this.getCloudPurchOrderList()
    this.getTradeOrderAmountSummary()
  }

  // 获取云采购订单列表
  getCloudPurchOrderList = () => {
    const { query = {}, pages } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCloudPurchOrderList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          expandedRowKeys: [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 获取订单明细
  getCloudPurchOrderDetail = (params) => {
    return Api.getCloudPurchOrderDetail(params)
  }

  // 获取订单金额汇总
  getTradeOrderAmountSummary = () => {
    const { pages } = this.state
    let params = {
      orderType: 'MERCHANT_ORDER',
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getTradeOrderAmountSummary(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          summary: data || {}
        })
      }
    })
  }

  // 生成合同
  submitCustractNumber = async (params) => {
    const res = await Api.submitCustractNumber(params)
    const { code, data } = res
    if (+code === 10000) {
      if (!data || data === '') {
        return message.warning('暂无可下载的合同链接')
      }

      window.open(data)
    } else {
      message.warning(res.message)
    }
  }

  // 点击展开订单详细
  getCloudPurchOrdersunList = (expanded, record) => {
    let { tableData = [] } = this.state

    let i = tableData.findIndex(item => { return +item.orderId === +record.orderId })

    if (expanded) {
      Api.getCloudPurchOrdersunList({ orderId: record.orderId }).then(res => {
        const { code, data } = res
        if (+code === 10000) {
          tableData[i].orderList = data
          this.setState({
            tableData
          })
        }
      })
    }
  }

  // 提交申请售后
  savePurchaseOrderSaledApply = async (params) => {
    const res = await Api.savePurchaseOrderSaledApply(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getCloudPurchOrderList()
    }
  }

  // 查看订单明细
  handleShowDetailModal = (record) => {
    this.setState({
      rowDetail: record,
      detailModal: true
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.orderTime) {
      search.beginDate = search.orderTime.length > 0 ? search.orderTime[0].format('YYYY-MM-DD') : ''
      search.endDate = search.orderTime.length > 0 ? search.orderTime[1].format('YYYY-MM-DD') : ''
      delete search.orderTime
    }
    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getCloudPurchOrderList()
    })
  }

  // 点击展开、收起子列表
  handleChangeExpand = (expanded, record) => {
    let { expandedRowKeys = [] } = this.state

    this.setState({
      expandedRowKeys: expanded ? [...expandedRowKeys, record.orderId] : $lodash.pull(expandedRowKeys, record.orderId)
    }, () => {
      this.getCloudPurchOrdersunList(expanded, record)
    })
  }

  // 点击查看订单明细
  handleShowPurchDetail = (record) => {
    this.setState({
      purchDetailModal: true,
      rowDetail: record || {}
    })
  }

  /* 点击下载合同
  *  判断是否有合同编号 如果有直接下载
  *  2- 没有合同 弹窗 是否自定义
  */
  handleDownloadOpen = (record = {}) => {
    this.submitCustractNumber({ orderId: record.orderId })
  }

  // 点击申请售后
  showSaledFormModal = (record) => {
    this.setState({
      rowDetail: record,
      saledModal: true
    })
  }

  // 点击查看订单附件
  showOrderEnclosureModal = (record) => {
    this.setState({
      rowDetail: record,
      enclosureModal: true
    })
  }

  closeModal = () => {
    this.setState({
      purchDetailModal: false,
      detailModal: false,
      saledModal: false,
      enclosureModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCloudPurchOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudPurchOrderList()
    })
  }

  // 渲染订单详情
  renderExpandRow = (row) => {
    const rowColumns = [
      {
        title: '发货批次',
        key: `expandRow-${row.pickingId}-0`,
        render: (text, record, index) => `${index + 1}`
      },

      {
        title: '发货人',
        key: `expandRow-${row.pickingId}-0111`,
        render: (text, record, index) => ('IIASaaS')
      },
      {
        title: '发货方式',
        key: `expandRow-${row.pickingId}-1`,
        render: (text, record, index) => (
          <>
            {
              ['ORDER_CLOUD_STOCK_PROCUREMENT', 'ORDER_CLOUD_FUTURES_PROCUREMENT'].includes(row.orderType) || record.logisticsOrderStatus !== 'CANCEL'
                ? renderLogisticsWayLabel(record.logisticsWay)
                : '----'
            }
          </>
        )
      },
      {
        title: '快递公司',
        key: `expandRow-${row.pickingId}-2`,
        render: (text, record, index) => (
          <>
            {
              ['ORDER_CLOUD_STOCK_PROCUREMENT', 'ORDER_CLOUD_FUTURES_PROCUREMENT'].includes(row.orderType) || record.logisticsOrderStatus !== 'CANCEL'
                ? <>{record.courierName && record.courierName !== '' ? record.courierName : '----'}</>
                : '----'
            }
          </>
        )
      },
      {
        title: '运单号',
        key: `expandRow-${row.pickingId}-3`,
        render: (text, record, index) => (
          <>
            {
              ['ORDER_CLOUD_STOCK_PROCUREMENT', 'ORDER_CLOUD_FUTURES_PROCUREMENT'].includes(row.orderType) || record.logisticsOrderStatus !== 'CANCEL'
                ? <>{record.courierNumberList && record.courierNumberList.length > 0 ? record.courierNumberList.join(',') : '----'}</>
                : '----'
            }
          </>
        )
      },
      {
        title: '发货时间',
        key: `expandRow-${row.pickingId}-4`,
        render: (text, record, index) => (
          <>
            {
              ['ORDER_CLOUD_STOCK_PROCUREMENT', 'ORDER_CLOUD_FUTURES_PROCUREMENT'].includes(row.orderType) || record.logisticsOrderStatus !== 'CANCEL'
                ? <>{record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----'}</>
                : '----'
            }
          </>
        )
      },
      {
        title: '收货地址',
        key: `expandRow-${row.pickingId}-6`,
        ellipsis: true,
        render: (text, record, index) => (
          <>
            {
              ['ORDER_CLOUD_STOCK_PROCUREMENT', 'ORDER_CLOUD_FUTURES_PROCUREMENT'].includes(row.orderType) || record.logisticsOrderStatus !== 'CANCEL'
                ? <div title={renderAddressLabel(record.province, record.city, record.area, record.address)}>
                  { renderAddressLabel(record.province, record.city, record.county, record.address) }
                </div>
                : '----'
            }
          </>
        )
      },
      {
        title: '操作',
        key: `expandRow-${row.pickingId}-5`,
        render: (text, record, index) => (
          <div>
            <Button type='link' size='small' onClick={() => this.handleShowDetailModal({ ...record, ...row })}>
              查看详情
            </Button>
          </div>
        )
      }
    ]

    return (
      <Row style={{ padding: '2px 5px' }}>
        <Table rowKey={(item, i) => { return `${item}--${i}` }}
          columns={rowColumns}
          dataSource={row.orderList}
          pagination={false}
        />
      </Row>
    )
  }

  render () {
    const { pages, summary = {}, detailModal = false, tableData, query = {}, searchData, purchDetailModal = false, saledModal = false, enclosureModal = false,
      rowDetail = {}, expandedRowKeys = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }} style={{ marginBottom: '15px' }}>
          <SearchForm
            {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </Card>

        <Card bodyStyle={{ padding: '15px' }}>
          <Row>
            <Updowm query={query} api={downOrderUrlcloudPurchase} />
            <Descriptions summary={summary} />
          </Row>
          <Table rowKey='orderId'
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
            expandedRowKeys={expandedRowKeys}
            onExpand={(expanded, record) => this.handleChangeExpand(expanded, record)}
            expandedRowRender={(record) => this.renderExpandRow(record)}
          />
        </Card>

        {
          purchDetailModal
            ? <PurchdetailModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              getDetail={(params) => this.getCloudPurchOrderDetail(params)}
            />
            : null
        }

        {
          detailModal
            ? <DetailModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        <SaledDrawer visible={saledModal}
          detailInfo={rowDetail}
          onConfirm={(params) => this.savePurchaseOrderSaledApply(params)}
          onCancel={() => this.closeModal()}
        />

        {/* 订单附件弹窗 */}
        <OrderEnclosureDrawer
          visible={enclosureModal}
          name='PURCHASE_ENCLOSURE'
          detailInfo={rowDetail}
          onCancel={() => this.closeModal()}
        />
      </>
    )
  }
}
