import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Progress } from 'antd'
import styles from './uploadinfo.module.scss'
class uploadinfo extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    success: this.props.history.location.state.data, // 成功的个数
    total: this.props.history.location.state.total.length // 总数

  }

  goBack = () => {
    this.props.history.push({ pathname: '/admin/customerList' })
  }
  render () {
    const { success, total } = this.state
    const bl = (success / total).toFixed(2) * 100
    return (
      <Card style={{ width: '100%', height: '100%' }}>

        <Button className={styles.btn} type='primary' onClick={() => { this.goBack() }}>返回列表</Button>
        <div className={styles.divmain}>
          <div className={styles.pro}>
            <Progress strokeColor={{
              '0%': '#68F9B2 ',
              '100%': '#05A6FC '
            }} type='dashboard' percent={bl} />
            <span className={styles.divspan}>完全数据</span>
          </div>

          <div className={` ${styles.commont}`}>
            <div className={`${styles.commdiv} ${styles.sccuessimg}`}>{total}</div>
            <div className={styles.commtxt}>数据已上传</div>
          </div>
          <div className={`${styles.commont}`}>
            <div className={`${styles.commdiv} ${styles.issccuessimg}`}>{success }</div>
            <div className={styles.commtxt}>完全匹配</div>
          </div>
          <div className={`${styles.commont}`}>
            <div className={`${styles.commdiv} ${styles.errcomm} ${styles.nosccuessimg}`}>{total - success }</div>
            <div className={styles.commtxt}>需要修改</div>
          </div>
        </div>
      </Card>
    )
  }
}

export default uploadinfo
