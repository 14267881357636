import React, { Component } from 'react'
import { Card, Row, List, Button, message, Modal } from 'antd'
import moment from 'moment'

import CashoutModal from '../component/cashout-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class EnterpriseWallet extends Component {
  static propTypes = {}
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [], // 余额流水
    info: {}, // 余额信息
    cashModal: false, // 提现申请弹窗
    hasPayword: false // 是否设置支付密码
  }

  componentDidMount () {
    this.getHasPayPassword()
    this.getEnterpriseBalance()
    this.getEnterpriseBalanceFlow()
  }

  // 查询用户是否设置支付密码
  getHasPayPassword = () => {
    Api.getHasPayPassword({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          hasPayword: data || false
        })
      }
    })
  }

  // 获取企业账户余额
  getEnterpriseBalance = () => {
    Api.getEnterpriseBalance({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data || {}
        })
      }
    })
  }

  // 获取余额流水
  getEnterpriseBalanceFlow = () => {
    const { pages } = this.state
    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getEnterpriseBalanceFlow(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 提现申请
  saveCashoutApply = (params) => {
    Api.saveCashoutApply(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getEnterpriseBalance()
      }
    })
  }

  // 点击提现申请
  handleShowCashModal = () => {
    const { hasPayword = false } = this.state
    if (!hasPayword) {
      Modal.warning({
        title: '温馨提示',
        content: (<div>
          <p>企业账户没有设置支付密码</p>
          <p>
            请联系管理员前往&nbsp;首页/支付设置&nbsp;设置支付密码
          </p>
        </div>),
        onOk: () => {}
      })
    } else {
      this.setState({
        cashModal: true
      })
    }
  }

  closeModal = () => {
    this.setState({
      cashModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEnterpriseBalanceFlow()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEnterpriseBalanceFlow()
    })
  }

  render () {
    const { tableData = [], pages, info = {}, cashModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Card>
            <Row>
              <Row style={{ marginBottom: '20px' }}>
                <label>当前余额</label>
                <span style={{ paddingLeft: '20px', fontSize: '20px', color: '#ed4014' }}>
                  &yen;{(+info.totalBalance * 100 / 100).toFixed(2)}
                </span>
              </Row>
              <Row>
                <Button type='primary'
                  disabled={+info.totalBalance === 0}
                  onClick={this.handleShowCashModal}
                >
                  申请提现
                </Button>
              </Row>
            </Row>
          </Card>
        </Row>

        <Row>
          <Card title='余额流水'>
            <Row>
              <List
                dataSource={tableData}
                pagination={pagination}
                renderItem={record => (
                  <List.Item>
                    <List.Item.Meta
                      title={<span>{record.remarks}</span>}
                      description={<div>
                        {
                          record.createTime
                            ? <span>{moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')}</span>
                            : null
                        }
                      </div>}
                    ></List.Item.Meta>
                    <div style={{ color: '#ed4014' }}>
                      {record.amountType === 'ADD' ? '+' : '-'}&yen;{(+record.amount * 100 / 100).toFixed(2)}
                    </div>
                  </List.Item>
                )}
              />
            </Row>
          </Card>
        </Row>

        {
          cashModal
            ? <CashoutModal
              detailInfo={info}
              onCancel={this.closeModal}
              onConfirm={(params) => this.saveCashoutApply(params)}
            />
            : null
        }
      </div>
    )
  }
}
