import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Table, Modal, Button, Card, message } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import PurchdetailModal from '@/pages/cloud-purchase/component/purchdetail-modal'
import PaymentModal from '@/pages/cloud-purchase/component/payment-modal' // 支付方式弹窗

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions, cloudPurchOrderStatusMap } = filter

class PaymentList extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单号'
        },
        cptParams: {
          placeholder: '请输入...'
        }
      },
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '下单日期'
        },
        cptParams: {}
      },
      {
        name: 'orderStatus',
        formType: 'Select',
        itemParams: {
          label: '订单状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...cloudPurchOrderStatusMap
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    type: 1, // 1- EU云采购订单 2- 财务云采购订单
    tableData: [],
    balanceInfo: {}, // 企业账户余额信息
    purchDetailModal: false,
    paymentModal: false, // 支付方式弹窗
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'cloudpurchorder-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '订单日期',
      key: 'cloudpurchorder-1',
      render: (text, record, index) => (
        <>{ record.orderTime ? <>{moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss')}</> : '----' }</>
      )
    },
    {
      title: '订单编号',
      key: 'cloudpurchorder-2',
      dataIndex: 'orderId'
    },
    {
      title: '订单金额',
      key: 'cloudpurchorder-3',
      dataIndex: 'payableAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.payableAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '已付金额',
      key: 'cloudpurchorder-4',
      dataIndex: 'paidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.paidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '未付金额',
      key: 'cloudpurchorder-5',
      dataIndex: 'unpaidAmount',
      render: (text, record, index) => (
        <>&yen;{ (+record.unpaidAmount * 100 / 100).toFixed(2) }</>
      )
    },
    {
      title: '状态',
      key: 'cloudpurchorder-6',
      render: (text, record, index) => (
        this.renderOrderStatusLabel(record.orderStatus)
      )
    },
    {
      title: '操作',
      key: 'cloudpurchorder-7',
      width: 240,
      render: (text, record, index) => {
        return (
          <>
            <Button type='primary' size='small' onClick={() => this.handleShowPurchDetail(record)}>查看明细</Button>

            {
              ['PAYING', 'TAIL_PAYING'].includes(record.orderStatus)
                ? <Button type='primary' size='small' style={{ marginLeft: '6px' }} onClick={() => this.handleFinancePay(record)}>付款</Button>
                : null
            }

            <Button type='primary' size='small' style={{ marginLeft: '6px' }} onClick={() => this.handleDownloadOpen(record)}>
              下载合同
            </Button>

          </>
        )
      }
    }
  ]

  componentDidMount () {
    this.getEnterpriseBalance()
    this.getCloudPurchOrderList()
  }

  // 获取云采购订单列表
  getCloudPurchOrderList = () => {
    const { query = {}, pages } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCloudPurchOrderList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取订单明细
  getCloudPurchOrderDetail = (params) => {
    return Api.getCloudPurchOrderDetail(params)
  }

  // 获取企业账户余额
  getEnterpriseBalance = () => {
    Api.getEnterpriseBalance({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          balanceInfo: data || {}
        })
      }
    })
  }

  // 余额支付云采购订单
  balancePayPurchOrder = (params, type) => {
    const { rowDetail = {} } = this.state

    Api.balancePayPurchOrder(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.closeModal()
        if (type === 2) {
          Modal.success({
            title: '采购订单支付成功',
            content: '',
            onOk: () => {
              this.getCloudPurchOrderList()
            }
          })
        } else {
          this.jumpToOrderPay({ ...rowDetail, balanceDeduction: params.amount })
        }
      }
    })
  }

  // 生成合同
  submitCustractNumber = async (params) => {
    const res = await Api.submitCustractNumber(params)
    const { code, data } = res
    if (+code === 10000) {
      if (!data || data === '') {
        return message.warning('暂无可下载的合同链接')
      }

      window.open(data)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.orderTime) {
      search.beginDate = search.orderTime.length > 0 ? search.orderTime[0].format('YYYY-MM-DD') : ''
      search.endDate = search.orderTime.length > 0 ? search.orderTime[1].format('YYYY-MM-DD') : ''

      delete search.orderTime
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getCloudPurchOrderList()
    })
  }

  // 点击查看订单明细
  handleShowPurchDetail = (record) => {
    this.setState({
      purchDetailModal: true,
      rowDetail: record || {}
    })
  }

  // 财务点击付款按钮
  handleFinancePay = (record = {}) => {
    const { balanceInfo = {} } = this.state

    if (+balanceInfo.totalBalance > 0) {
      this.setState({
        paymentModal: true,
        rowDetail: record
      })
    } else {
      this.jumpToOrderPay({ ...record, balanceDeduction: 0 })
    }
  }

  /* 点击下载合同
  *  判断是否有合同编号 如果有直接下载
  *  2- 没有合同 弹窗 是否自定义
  */
  handleDownloadOpen = async (record = {}) => {
    this.submitCustractNumber({ orderId: record.orderId })
  }

  // 跳转到付款
  jumpToOrderPay = (record) => {
    this.props.history.push({ pathname: '/admin/orderPay', state: { ...record } })
  }

  // 选择的支付方式
  selectedPaymethod = (params, type = 1) => {
    // type: 1- 线下转账 2-使用余额，支付成功 3-使用余额且需要线下转账
    if (type === 1) {
      this.closeModal()
      this.jumpToOrderPay({ ...params, balanceDeduction: 0 })
    } else {
      this.balancePayPurchOrder(params, type)
    }
  }

  // 根据订单状态渲染文字
  renderOrderStatusLabel = (status) => {
    const obj = cloudPurchOrderStatusMap.find(item => { return item.id === status })
    const label = obj !== undefined ? obj.name : null

    return label
  }

  closeModal = () => {
    this.setState({
      purchDetailModal: false,
      paymentModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCloudPurchOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudPurchOrderList()
    })
  }

  render () {
    const { pages, tableData, searchData, purchDetailModal = false, paymentModal = false, balanceInfo = {}, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '15px' }}>
          <Table rowKey='orderId'
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
          />
        </Card>

        {
          purchDetailModal
            ? <PurchdetailModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              getDetail={(params) => this.getCloudPurchOrderDetail(params)}
            />
            : null
        }

        {
          paymentModal
            ? <PaymentModal
              detailInfo={rowDetail}
              balanceInfo={balanceInfo}
              onCancel={this.closeModal}
              onConfirm={(params, type) => this.selectedPaymethod(params, type)}
            />
            : null
        }
      </>
    )
  }
}

export default withRouter(PaymentList)
