import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'antd'

import HomeUi1 from './homeui1'
import HomeUi2 from './homeui2'

export default class PostDetail extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    templateId: 0,
    info: {}, // 模板配置信息
    autofitup: false, // 是否自动装修
    tooltipVisible: false // 提示弹窗
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location

    this.setState({
      templateId: state.templateId || 0
    })
  }

  handleGoBack = () => {
    this.props.history.push({ pathname: '/admin/homePoster' })
  }

  render () {
    const { templateId = 0 } = this.state

    return (
      <div style={{ paddingBottom: '60px' }}>
        <Row>
          {
            templateId === 0
              ? <HomeUi1 history={this.props.history}
                modelType={0}
                onCancel={() => this.handleGoBack()}
              />
              : null
          }

          {
            templateId === 1
              ? <HomeUi2 history={this.props.history}
                modelType={1}
                onCancel={() => this.handleGoBack()}
              />
              : null
          }
        </Row>
      </div>
    )
  }
}
