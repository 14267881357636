import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Api from '@/common/api/index'
import { Modal, Button, Row, message } from 'antd'

import UploadCp from '../component/uploadimg-cp'
import UploadPdf from '../component/uploading-pdf'

class uploadimmodal extends Component {
    static propTypes ={
      onCancel: PropTypes.func,
      rowdetail: PropTypes.number
    }
   state = {
     listImages: [],
     detailsImages: [],
     detailsPdfs: []
   }

   componentDidMount () {
     this.getProductSkuImg()
   }

   // 获取详情信息
  getProductSkuImg = () => {
    let param = {
      commoditySkuId: this.props.rowdetail
    }
    Api.getProductSkuImg(param).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        this.setState({
          listImages: data.skuImageList || [],
          detailsImages: data.skuDetailImageList || [],
          detailsPdfs: data.skuDetailPdfList || []
        })
      }
    })
  }

  // 上传产品图片
  handleUploadListImage = (params, key) => {
    let newArr = this.state[key] || []

    if (key === 'listImages') {
      newArr.push({ imageUrl: params, mainType: 'NOT_MAIN' })
    } else {
      newArr.push({ imageUrl: params, showStatus: 'NOT_SHOW' })
    }

    let newlistImages = newArr.map((item) => item.mainType === 'MAIN').join(',')
    let detailsImages = newArr.map((item) => item.showStatus === 'SHOW').join(',')

    if (key === 'listImages' && !newlistImages.includes(true)) {
      newArr.map((item, i) => {
        if (i === 0) {
          item.mainType = 'MAIN'
        }
      })
    }

    if (key === 'detailsImages' && !detailsImages.includes(true)) {
      newArr.map((item, i) => {
        if (i === 0) {
          item.showStatus = 'SHOW'
        }
      })
    }

    this.setState({
      [key]: newArr
    })
  }

  // 删除图片
  handleDeleteImage = (index, type) => {
    let newArr = [...this.state[type]]
    newArr.splice(index, 1)

    this.setState({
      [type]: newArr
    })
  }

  // 选择设置主要或显示
  handleSelectedImage = (index, type, checked) => {
    let newArr = this.state[type]

    if (type === 'listImages') {
      newArr.map((item, i) => {
        item['mainType'] = i === index ? 'MAIN' : 'NOT_MAIN'
      })
    }

    if (type === 'detailsImages') {
      newArr[index]['showStatus'] = checked ? 'SHOW' : 'NOT_SHOW'
    }

    this.setState({
      [type]: newArr
    })
  }

  // PDF
  // pdf上传
  handleUploadPdf = (param) => {
    const { detailsPdfs } = this.state
    detailsPdfs.push(param)

    let newdetailsPdfs = detailsPdfs.map((item) => item.showStatus === 'SHOW').join(',')
    if (!newdetailsPdfs.includes(true)) {
      detailsPdfs.map((item, i) => {
        if (i === 0) {
          item.showStatus = 'SHOW'
        }
      })
    }
    this.setState({
      detailsPdfs
    })
  }

  // pdf删除
  handleDeletePdf = (index) => {
    const { detailsPdfs } = this.state
    detailsPdfs.splice(index, 1)
    this.setState({
      detailsPdfs
    })
  }

  // PDF设置唯一显示
  handleSelectedpdf = (index) => {
    const { detailsPdfs } = this.state
    detailsPdfs.map((item, i) => {
      if (i === index) {
        item.showStatus = 'SHOW'
      } else {
        item.showStatus = 'NOT_SHOW'
      }
    })

    this.setState({
      detailsPdfs
    })
  }

  // 应用图片
  handleSaveImageTemp = () => {
    const { detailsImages = [], detailsPdfs = [], listImages = [] } = this.state

    const params = {
      skuIdList: [this.props.rowdetail],
      skuImageList: listImages,
      skuDetailImageList: detailsImages,
      skuDetailPdfList: detailsPdfs
    }

    Api.updateProductSkuImg(params).then(res => {
      const { code } = res

      if (+code === 10000) {
        message.success('应用成功')
        this.props.onCancel()
      }
    })
  }

  render () {
    const { listImages = [], detailsImages = [], detailsPdfs = [] } = this.state
    return (
      <div>
        <Modal bodyStyle={{ padding: '8px 15px 24px 15px', height: '600px', overflow: 'auto' }}
          width={1000}
          visible
          onOk={this.handleOk}
          onCancel={this.props.onCancel}
          footer={null}
        >
          <UploadCp title='产品图' type='listImages'
            list={listImages}
            onUpload={(params, type) => this.handleUploadListImage(params, type)}
            onDelete={(index, type) => this.handleDeleteImage(index, type)}
            onSelect={(index, type) => this.handleSelectedImage(index, type)}
          />

          <UploadCp title='详情图' type='detailsImages'
            list={detailsImages}
            onUpload={(params, type) => this.handleUploadListImage(params, type)}
            onDelete={(index, type) => this.handleDeleteImage(index, type)}
            onSelect={(index, type, checked) => this.handleSelectedImage(index, type, checked)}
          />

          <p>PDF：</p>
          <UploadPdf list={detailsPdfs}
            onUpload={(params) => this.handleUploadPdf(params)}
            onDelete={(index) => this.handleDeletePdf(index)}
            onSelect={(index) => this.handleSelectedpdf(index)}
          />
          <Row style={{ paddingTop: '20px', textAlign: 'center' }}>
            <Button type='primary' onClick={() => { this.handleSaveImageTemp() }}>应用</Button>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default uploadimmodal
