import React, { Component } from 'react'
import { Card, Table } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const { pageSizeOptions } = filter

export default class EndUserCartDetails extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'endUserId',
        formType: 'Select',
        itemParams: {
          label: '客户名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['endUserId', 'showName']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {}
  }
  columns = [
    {
      title: '序号',
      key: 'enduserCartListCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'enduserCartListCol-1',
      dataIndex: 'brandId',
      render: (text, record, index) => (
        <>{ record.englishBrandName && record.englishBrandName !== '' ? `${record.brandName}/${record.englishBrandName}` : record.brandName }</>
      )
    },
    {
      title: '型号',
      key: 'enduserCartListCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'enduserCartListCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'enduserCartListCol-4',
      dataIndex: 'name'
    },
    {
      title: '产品类型',
      key: 'enduserCartListCol-5',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '单价',
      key: 'enduserCartListCol-6',
      render: (text, record, index) => {
        const { supplyInfoList = [], commoditySkuId, skuGoodsType } = record
        return (
          <ul>
            {
              supplyInfoList.map((item, n) => (
                <li key={`priceItem-${commoditySkuId}-${skuGoodsType}-${n}`}>
                  <p className={`${styles.inline} ${styles.priceCol}`}>
                    { +item.price > 0 ? <>&yen;{ +item.price }</> : <>面议</> }
                  </p>

                  {
                    ['FUTURES', 'CLOUD_FUTURES'].includes(skuGoodsType)
                      ? <>
                        <p className={`${styles.inline} ${styles.stockCol}`}><label className={`label`}>货期</label>{item.deliveryPeriodDays}工作日</p>
                        <p className={`${styles.inline} ${styles.stockCol}`}><label className={`label`}>预付比例</label>{(+item.prepaymentRatio * 100 / 100).toFixed(2)}%</p>
                      </>
                      : <p className={`${styles.inline} ${styles.stockCol}`}><label className={`label`}>库存</label>{+item.stock}</p>
                  }
                </li>
              ))
            }
          </ul>
        )
      }
    },
    {
      title: '数量',
      key: 'enduserCartListCol-7',
      dataIndex: 'quantity',
      render: (text, record, index) => {
        const { supplyInfoList = [], commoditySkuId, skuGoodsType } = record
        return (
          <ul>
            {
              supplyInfoList.map((item, n) => (
                <li key={`quantityItem-${commoditySkuId}-${skuGoodsType}-${n}`}>{ +item.quantity }</li>
              ))
            }
          </ul>
        )
      }
    },
    {
      title: '金额',
      key: 'enduserCartListCol-8',
      dataIndex: 'total',
      render: (text, record, index) => {
        const { supplyInfoList = [] } = record
        const _total = $lodash.sumBy(supplyInfoList, (o) => { return +o.price * o.quantity })

        return (<>&yen;{ (_total * 100 / 100).toFixed(2) }</>)
      }
    },
    {
      title: '产品状态',
      key: 'enduserCartListCol-9',
      dataIndex: 'onSaleStatus',
      render: (text, record, index) => (
        <>{ record.onSaleStatus === 'DISABLE' ? <span style={{ color: '#F5222D' }}>下架</span> : <span style={{ color: '#52C41A' }}>上架</span> }</>
      )
    }
  ]

  componentDidMount () {
    this.getGuesteuUserLike('')
    this.getEUCartSkuDetails()
  }

  // 搜索客户信息
  getGuesteuUserLike = (val = '') => {
    Api.getGuesteuUserLike({ searchName: val, pageNo: 1, pageSize: 999 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state
        searchData[0].options = data.list || []

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取EU客户购物车详情
  getEUCartSkuDetails = async () => {
    const { pages, query = {} } = this.state
    const res = await Api.getEUCartSkuDetails({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages = {} } = this.state

    this.setState({
      query: search || {},
      pages: {
        ...pages,
        pageNo: 1,
        total: 0
      }
    }, () => {
      this.getEUCartSkuDetails()
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEUCartSkuDetails()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEUCartSkuDetails()
    })
  }

  render () {
    const { pages, searchData, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>
        <Card bodyStyle={{ padding: '15px' }}>
          <Table rowKey={(record, index) => `enduserCartCol-${index}`}
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}
