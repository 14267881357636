import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Row, Input, Button } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import Api from '@/common/api/index'

const FormItem = Form.Item

class ConfirmForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    hasPayword: false
  }

  componentDidMount () {
    this.getHasPayPassword()
  }

  // 查询是否有支付密码
  getHasPayPassword = () => {
    Api.getHasPayPassword({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          hasPayword: data || false
        })
      }
    })
  }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {} } = this.props
        let { password } = values
        let jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values['password'] = jsencrypt.encrypt(password)

        values.orderId = detailInfo.orderId
        values.currentOrderStatus = detailInfo.orderStatus
        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { form, detailInfo = {}, onCancel } = this.props
    const { getFieldDecorator } = form
    const { hasPayword = false } = this.state
    return (
      <>
        <Modal title='确认收款' visible
          maskClosable={false}
          onCancel={() => onCancel()}
          onOk={() => this.handleConfirm()}
          footer={[
            <Button key={`footerBtn-0`} onClick={() => onCancel()}>取消</Button>,
            <Button type='primary' disabled={!hasPayword} key={`footerBtn-1`} onClick={() => this.handleConfirm()}>确定</Button>
          ]}
        >
          <Row style={{ marginBottom: '10px' }}>
            <div style={{ fontSize: '14px', color: '#333333' }}>此次确认收款&nbsp;
              <span style={{ color: '#F5222D' }}>&yen;{(+detailInfo.currentPayAmount * 100 / 100).toFixed(2)}</span>
            </div>
          </Row>
          <Row>
            <Form>
              {
                hasPayword
                  ? <FormItem label='收款密码'>
                    {
                      getFieldDecorator('password', {
                        rules: [
                          { required: true, message: '请输入收款密码' }
                        ]
                      })(
                        <Input.Password autoComplete='off' />
                      )
                    }
                  </FormItem>
                  : <FormItem label='收款密码'>
                    <>
                      <p>企业账户没有设置收款密码</p>
                      <p>请联系管理员前往&nbsp;财务管理/设置收款密码&nbsp;管理收款密码</p>
                    </>
                  </FormItem>
              }

            </Form>
          </Row>
        </Modal>
      </>
    )
  }
}

const ConfirmModal = Form.create()(ConfirmForm)

export default ConfirmModal
