import Ajax from '../utils/ajax'

export default {
  // 获取合同列表
  getContractList (params) {
    return Ajax.post('/api/contract/manager/list/page/contract', params)
  },

  // 合同模板 - 获取合同模板列表
  getContractTemplList (params) {
    return Ajax.post('/api/contract/manager/list/page/contract/template', params)
  },

  // 合同模板 - 新增合同模板
  saveAddContractTemp (params) {
    return Ajax.post('/api/contract/manager/add/contract/template', params)
  },

  // 合同模板 - 编辑合同模板
  saveEditContractTemp (params) {
    return Ajax.post('/api/contract/manager/update/contract/template', params)
  },

  // 合同模板 - 更新合同模板状态
  updateContractTempStatus (params) {
    return Ajax.post('/api/contract/manager/batch/update/contract/template/status', params)
  },

  // 合同模板 - 删除合同模板
  deleteContractTemp (params) {
    return Ajax.post('/api/contract/manager/batch/delete/contract/template', params)
  },

  // 合同模板 - 获取模板详情
  getContractTempDetail (params) {
    return Ajax.post('/api/contract/manager/get/contract/template', params)
  },

  // 合同模板 - 获取合同自定义选项
  getContractTempFieldMap (params) {
    return Ajax.get('/api/contract/manager/getTemplateFieldMap', { params })
  },

  // 订单汇总- 下载合同- 获取合同下载链接
  getEUOrderContractDownloadLink (params) {
    return Ajax.post('/api/contract/manager/custom', params)
  },

  // 订单汇总-重新生成合同
  createEUOrderContractAgain (params) {
    return Ajax.get('/api/contract/manager/regenerate', { params })
  },

  // 下载合同- 获取合同链接
  createCustomContract (params) {
    return Ajax.post('/api/contract/manager/custom', params)
  },

  // 查询合同委托人信息
  getContractDemanderInfo (params) {
    return Ajax.get('/api/contract/manager/demanderInfo', { params })
  },

  // 订单汇总- EU合同附件列表查询
  getEUOrderContractEnclosureList (params) {
    return Ajax.post('/api/merchant/contract/select/by/all', params)
  },

  // 订单汇总- 删除合同附件
  deleteEUOrderContractEnclosure (params) {
    return Ajax.get('/api/merchant/contract/delete', { params })
  },

  // 云供应订单- 获取云供应订单合同附件列表
  getSupplyContractEnclosureList (params) {
    return Ajax.get('/api/cloud/supply/order/order/file/findContractByUserAndType', { params })
  },

  // 云供应订单- 保存云供应订单合同附件删除
  saveDeleteSupplyContractEnclosure (params) {
    return Ajax.get('/api/cloud/supply/order/file/delete', { params })
  },

  // 云采购订单- 获取云采购订单附件列表
  getPurchaseContractEnclosureList (params) {
    return Ajax.get('/api/cloud/procurementOrder/order/file/findContractByUserAndType', { params })
  },

  // 云采购订单- 云采购订单附件删除
  saveDeletePurchaseContractEnclosure (params) {
    return Ajax.get('/api/cloud/procurementOrder/file/delete', { params })
  }

}
