import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Form, Row, Col, Select, Input, Button, message } from 'antd'
import filter from '@/common/utils/filter'

const FormItem = Form.Item
const Option = Select.Option
const { TextArea } = Input
const { repLineBreak } = filter

class SearchForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    handleSubmit: PropTypes.func
  }
  state = {

  }

  componentDidMount () {

  }

  handleSearch = () => {
    let values = this.props.form.getFieldsValue()
    let _values = {}

    if (values.brandId === undefined && !values.searchValues) {
      message.warning('请选择品牌或输入想搜索的产品')
      return false
    }

    if (values.searchValues && values.searchValues !== '') {
      let vals = repLineBreak(values.searchValues, '<br>')
      _values[values.keyword] = vals.split('<br>')
    }

    this.props.handleSubmit(_values)
  }

  render () {
    const { getFieldDecorator } = this.props.form

    return (
      <div>
        <Form>
          <Row gutter={24}>
            <Col span={18}>
              <Row>
                <FormItem style={{ marginBottom: '0px', display: 'inline-block', width: '90px' }}>
                  {
                    getFieldDecorator('keyword', {
                      initialValue: 'itemNumberList'
                    })(
                      <Select>
                        <Option value={'itemNumberList'}>订货号</Option>
                        <Option value={'modelList'}>型号</Option>
                      </Select>
                    )
                  }
                </FormItem>

                <FormItem style={{ marginBottom: '0px', display: 'inline-block', width: 'calc(100% - 90px)' }}>
                  {
                    getFieldDecorator('searchValues', {

                    })(
                      <TextArea rows={4} />
                    )
                  }
                </FormItem>
              </Row>
            </Col>

            <Col span={6}>
              <Row>
                <Button type='primary' onClick={this.handleSearch}>搜索</Button>
              </Row>
            </Col>
          </Row>

        </Form>
      </div>
    )
  }
}

const Search = Form.create()(SearchForm)

export default withRouter(Search)
