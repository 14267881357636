import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, InputNumber, Radio, Select, Alert, Col } from 'antd'
import $lodash from 'lodash'

import Api from '@/common/api/index'

const FormItem = Form.Item
const Option = Select.Option
const settypeMap = [
  { type: 'FIXED', label: '设置利润' },
  { type: 'PERCENTAGE', label: '设置利润率' }
]

const profitTypeMaps = {
  'DEFAULT': 'DEFAULT', 'SKU': 'SKU', 'SKUBATCH': 'SKU', 'CONDITION': 'CONDITION'
}

class ProfitForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.string.isRequired, // 'DEFAULT'-全部设置默认利润率 SKU-sku设置利润  SKUBATCH-批量利润设置 CONDITION-按条件批量设置利润
    detailInfo: PropTypes.object,
    keys: PropTypes.array,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    brandList: [], // 产品列表
    profitType: 'FIXED',
    profit: 0, // 利润
    profitRate: 0 // 利润率
  }

  componentDidMount () {
    const { type = 'DEFAULT', detailInfo = {} } = this.props

    switch (type) {
      case 'DEFAULT':
        this.getDefaultProfitSetting()
        break
      case 'CONDITION':
        this.getBrandList({ brandListType: 'ALL_BRAND' })
        break
      default:
        this.setState({
          profitType: detailInfo.profitType || 'FIXED',
          profit: detailInfo.profitType && detailInfo.profitType === 'FIXED' ? +detailInfo.profitNum : 0,
          profitRate: detailInfo.profitType && detailInfo.profitType === 'PERCENTAGE' ? +detailInfo.profitNum : 0
        })
    }
  }

  // 获取默认利润设置
  getDefaultProfitSetting = async () => {
    let res = await Api.getDefaultProfitSetting({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        profitType: data.profitType ? data.profitType : 'FIXED',
        profit: data.profitType && data.profitType === 'FIXED' ? +data.profitNum : 0,
        profitRate: data.profitType && data.profitType === 'PERCENTAGE' ? +data.profitNum : 0
      })
    }
  }

  // 获取品牌
  getBrandList = (params) => {
    Api.getBrandList(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        this.setState({
          brandList: data || []
        })
      }
    })
  }

  // 获取条件搜索结果
  getCloudSettingSearch = (value) => {
    Api.getCloudPurchaseList({ brandId: [value], isData: false, pageNo: 1, pageSize: 10 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          searchResult: {
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {}, type, keys = [] } = this.props

        values['profitSettingType'] = profitTypeMaps[type]
        values['profitNum'] = values.profitType === 'PERCENTAGE' ? values.profitRate / 100 : values.profit

        delete values['profitRate']
        delete values['profit']

        if (type === 'SKU') {
          if (['FUTURES', 'CLOUD_FUTURES'].includes(detailInfo.skuGoodsType)) {
            values['futureSkuIdList'] = [detailInfo.commoditySkuId]
          } else {
            values['skuIdList'] = [detailInfo.commoditySkuId]
          }
        } else if (type === 'SKUBATCH') {
          const newdatas = $lodash.map(keys, (values) => {
            return {
              commoditySkuId: $lodash.split(values, '-')[0],
              skuGoodsType: $lodash.split(values, '-')[1]
            }
          })

          const skuIdList = $lodash.map($lodash.filter(newdatas, (o) => { return ['SPOT', 'CLOUD_SPOT'].includes(o.skuGoodsType) }), 'commoditySkuId')
          const futureSkuIdList = $lodash.map($lodash.filter(newdatas, (o) => { return ['FUTURES', 'CLOUD_FUTURES'].includes(o.skuGoodsType) }), 'commoditySkuId')
          values['skuIdList'] = skuIdList
          values['futureSkuIdList'] = futureSkuIdList
        }

        this.props.onConfirm(values, type)
      }
    })
  }

  onChangeIsSetCommonPrice = (val) => {
    this.setState({
      isDefaultPrice: +val
    })
  }

  render () {
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    }

    const { getFieldDecorator, getFieldValue } = this.props.form
    const { type = 'DEFAULT' } = this.props
    const { brandList = [], profitType = 'FIXED', profit = 0, profitRate = 0, searchResult = {} } = this.state

    return (
      <>
        <Modal title='利润设置' visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Form>
            {
              type === 'CONDITION'
                ? <>
                  <FormItem label='品牌' {...formItemLayout}>
                    {
                      getFieldDecorator('brandId', {
                        rules: [
                          { required: true, message: '请选择要查询的品牌' }
                        ]
                      })(
                        <Select showSearch optionFilterProp='children'
                          onChange={(value) => this.getCloudSettingSearch(value)}
                        >
                          {
                            brandList.map((brandOpt, i) => (
                              <Option value={brandOpt.brandId} key={`brandOpt-${i}`}>{brandOpt.brandName}</Option>
                            ))
                          }
                        </Select>
                      )
                    }
                  </FormItem>

                  {
                    getFieldValue('brandId') !== undefined
                      ? <FormItem style={{ marginBottom: '0px' }}>
                        <Col span={14} offset={6}>
                          <Alert type='info' message={
                            <div style={{ width: '100%' }}>
                              {`条件搜索结果数量为: ${searchResult.total}`}
                            </div>
                          } />
                        </Col>
                      </FormItem>
                      : null
                  }
                </>
                : null
            }

            <FormItem label='设置类型' {...formItemLayout}>
              {
                getFieldDecorator('profitType', {
                  initialValue: profitType,
                  rules: [
                    { required: true, message: '请填写利润类型' }
                  ]
                })(
                  <Radio.Group>
                    {
                      settypeMap.map((item, i) => (<Radio value={item.type} key={`profitType-${i}`}>{item.label}</Radio>))
                    }
                  </Radio.Group>
                )
              }
            </FormItem>

            {
              getFieldValue('profitType') === 'FIXED'
                ? <FormItem label='利润' {...formItemLayout}>
                  {
                    getFieldDecorator('profit', {
                      initialValue: profit,
                      rules: [
                        { required: true, message: '请填写利润' }
                      ]
                    })(
                      <InputNumber min={0} />
                    )
                  }
                </FormItem>
                : null
            }

            {
              getFieldValue('profitType') === 'PERCENTAGE'
                ? <FormItem label={<span className={`required`}>利润率</span>} {...formItemLayout} style={{ marginBottom: '0px' }}>
                  <FormItem style={{ display: 'inline-block' }}>
                    {
                      getFieldDecorator('profitRate', {
                        initialValue: profitRate * 100,
                        rules: [
                          { required: true, message: '请填写利润率' }
                        ]
                      })(<InputNumber min={0} />)
                    }
                  </FormItem>
                  <span style={{ marginLeft: '5px' }}>%</span>
                </FormItem>
                : null
            }

          </Form>
        </Modal>
      </>
    )
  }
}

const ProfitModal = Form.create()(ProfitForm)

export default ProfitModal
