import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'

import SupplySpotProducts from '../supplySpotProducts/index' // 现货云供应
import SpplyFuturesProducts from '../supplyFuturesProducts/index' // 期货云供应
import styles from './index.module.scss'

const { TabPane } = Tabs
const tabPaneMaps = [
  { id: 'CLOUD_SPOT', name: '现货云供应' },
  { id: 'CLOUD_FUTURES', name: '期货云供应' }
]

export default class CloudSupplyProducts extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    activeTabKey: 'CLOUD_SPOT'
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location

    this.setState({
      activeTabKey: state.activeTabKey && state.activeTabKey !== '' ? state.activeTabKey : 'CLOUD_SPOT'
    })
  }

  // 切换Tab
  handleChangeTabPane = (key) => {
    this.setState({
      activeTabKey: key
    })
  }

  render () {
    const { activeTabKey = 'CLOUD_SPOT' } = this.state

    return (
      <>
        <div className={`${styles.tabsLand}`}>
          <Tabs onChange={(key) => this.handleChangeTabPane(key)}>
            {
              tabPaneMaps.map((item, index) => (
                <TabPane tab={item.name} key={item.id} />
              ))
            }
          </Tabs>
        </div>

        {
          activeTabKey === 'CLOUD_SPOT'
            ? <SupplySpotProducts history={this.props.history} />
            : null
        }

        {
          activeTabKey === 'CLOUD_FUTURES'
            ? <SpplyFuturesProducts history={this.props.history} />
            : null
        }
      </>
    )
  }
}
