import React, { } from 'react'
import { Card } from 'antd'
import PropTypes from 'prop-types'

function InviceHeader (props) {
  const { bankInfo = {} } = props
  return (
    <div style={{ marginTop: '24px' }}>
      <Card title='发票抬头' headStyle={{ padding: '0px', borderBottom: 'none' }} bodyStyle={{ padding: '0px' }} bordered={false}>
        <p style={{ marginBottom: '10px' }}><label className='label'>增值税专用发票（企业）抬头</label>{bankInfo.merchantName || ''}</p>
        <p style={{ marginBottom: '10px' }}><label className='label'>纳税人识别号</label>{bankInfo.merchantCreditNo || ''}</p>
        <p style={{ marginBottom: '10px' }}><label className='label'>地址/电话</label>{bankInfo.address || ''}</p>
        <p><label className='label'>开户行及账号</label>{bankInfo.accountBank ? `${bankInfo.accountBank} ${bankInfo.account}` : ''}</p>
      </Card>
    </div>
  )
}
InviceHeader.propTypes = {
  bankInfo: PropTypes.object
}

export default InviceHeader
