import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'

import styles from './index.module.scss'

export default class UploadThumbnail extends Component {
  static propTypes = {
    imageUrl: PropTypes.string,
    onDelete: PropTypes.func
  }
  state = {}

  componentDidMount () {}

  render () {
    const { imageUrl = '', onDelete } = this.props

    return (
      <div className={`${styles.thumbnailWrap}`}>
        <div className={`${styles.thumbnailInfo}`}>
          <img className={`${styles.thumbnailPic}`} src={imageUrl} />

          <span className={`${styles.previewActions}`}>
            <a href={imageUrl} target='_blank' rel='noopener noreferrer'><Icon type='eye' style={{ fontSize: '20px', color: 'rgba(255, 255, 255, 0.85)' }} /></a>

            {
              onDelete
                ? <a onClick={() => onDelete()}><Icon type='delete' style={{ margin: '0px 4px', fontSize: '20px', color: 'rgba(255, 255, 255, 0.85)' }} /></a>
                : null
            }
          </span>
        </div>
      </div>
    )
  }
}
