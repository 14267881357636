import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Form, Table, Row, Col, message, Modal, Upload, Button, Badge, InputNumber, Popconfirm, Tag, Icon } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import BatchSearchMenu from '@/components/batchSearchMenu/index'
import SoldlogModal from '../component/soldlogModal/index' // 日志弹窗
import UploadModal from '@/pages/store-house/component/upload-modal' // 导入失败详情弹窗
import DelayedModal from '../component/delayedModal/index'
import HandleModal from '@/pages/price/price-setting/handle-modal'
import UploadSheetModal from '@/components/uploadSheetModal'

import Api from '@/common/api'
import UploadApi from '@/common/api/uploadApi'
import DownloadApi from '@/common/api/downloadApi'
import { getStore, renderCloudStoreLevelLabel } from '@/common/utils/mUtils'
import filter from '@/common/utils/filter'

const { uploadMatchExcelUrl } = UploadApi
const { downloadCloudSupplyFuturesTemplate } = DownloadApi
const { pageSizeOptions, productPutOnMap, importConfig, brandAgencyStatusMaps, cloudStoreLevelMaps } = filter
const { confirm } = Modal
const SKUTYPE = 'FUTURES'

// 可编辑单元格
const EditableContext = React.createContext()

class EditableCell extends Component {
  static propTypes = {
    form: PropTypes.object,
    editing: PropTypes.bool,
    dataIndex: PropTypes.string,
    title: PropTypes.string,
    inputType: PropTypes.string,
    record: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.array
  }

  renderCell = ({ getFieldDecorator }) => {
    const { editing, dataIndex, title, inputType, record, index, children, ...restProps } = this.props

    return (
      <td {...restProps}>
        {editing
          ? (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(dataIndex, {
                rules: [
                  { required: true, message: `请输入${title}!` }
                ],
                initialValue: record[dataIndex]
              })(<InputNumber />)}
            </Form.Item>
          )
          : (
            children
          )}
      </td>
    )
  }

  render () {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
  }
}

class SupplyFuturesProducts extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object,
    dispatch: PropTypes.func
  }
  state = {
    searchData: [
      {
        name: 'keyword',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号或订货号',
          autoComplete: 'off'
        }
      },
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      },
      {
        name: 'cloudSupplyFuturesStatus',
        formType: 'Select',
        itemParams: {
          label: '上架状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...productPutOnMap
        ]
      },
      {
        name: 'brandAgencyStatus',
        formType: 'Select',
        itemParams: {
          label: '代理证状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...brandAgencyStatusMaps
        ]
      },
      {
        name: 'outDateLevel',
        formType: 'Select',
        itemParams: {
          label: '云仓等级'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...cloudStoreLevelMaps
        ]
      },
      {
        name: 'modifyTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '更新时间'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    selectedRowKeys: [],
    logModal: false,
    uploadModal: false,
    rowDetail: {}, // 当前选择的行数据
    editingKey: '', // 编辑中的产品ID
    failList: [], // 导入失败的数据集合
    totalNumber: 0,
    failNumber: 0,
    successNumber: 0,
    failModal: false,
    searchfailList: [], // 检索失败列表
    searchFailNumber: 0, // 检索失败数
    searchSuccessNumber: 0, // 检索成功数
    searchTotalNumber: 0, // 检索总数

    handleModal: false,
    delayedModal: false, // 延时提示弹窗
    brandList: [], // 云供应品牌列表
    showPage: false
  }

  columns = [
    {
      title: '序号',
      key: 'supplyFuturesProductsCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'supplyFuturesProductsCol-1',
      width: 120,
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'supplyFuturesProductsCol-2',
      width: 120,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <span dangerouslySetInnerHTML={{ __html: record.model }} />
          { record.modelExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '订货号',
      key: 'supplyFuturesProductsCol-3',
      width: 120,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
          { !record.modelExactMatch && record.itemNumberExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '产品名称',
      key: 'supplyFuturesProductsCol-4',
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '系列',
      key: 'supplyFuturesProductsCol-5',
      width: 120,
      dataIndex: 'series',
      ellipsis: true
    },
    {
      title: '价格',
      key: 'supplyFuturesProductsCol-6',
      dataIndex: 'cloudSupplyFuturesPrice',
      editable: true,
      render: (text, record, index) => (
        <>&yen;{ record.cloudSupplyFuturesPrice}</>
      )
    },
    {
      title: '货期',
      key: 'supplyFuturesProductsCol-7',
      dataIndex: 'deliveryPeriodDays',
      editable: true,
      render: (text, record, index) => (
        <>{ +record.deliveryPeriodDays }工作日</>
      )
    },
    {
      title: '预付比例',
      key: 'supplyFuturesProductsCol-8',
      dataIndex: 'prepaymentRatio',
      editable: true,
      render: (text, record, index) => (
        <>{ (+record.prepaymentRatio * 100 / 100).toFixed(2) }%</>
      )
    },
    {
      title: '当前状态',
      key: 'supplyFuturesProductsCol-9',
      width: 120,
      render: (text, record, index) => (
        <>{ this.renderCloudSupplyStatusLabel(record.cloudSupplyFuturesStatus) }</>
      )
    },
    {
      title: '代理证状态',
      key: 'supplyFuturesProductsCol-10',
      width: 120,
      render: (text, record, index) => (
        <>{ this.renderBrandAgencyStatusLabel(record.brandAgencyStatus) }</>
      )
    },
    {
      title: '云仓等级',
      key: 'supplyFuturesProductsCol-11',
      dataIndex: 'outDateLevel',
      render: (text, record, index) => (
        <>{ renderCloudStoreLevelLabel(record.outDateLevel) }</>
      )
    },
    {
      title: '更新时间',
      key: 'supplyFuturesProductsCol-12',
      dataIndex: 'modifyTime',
      render: (text, record, index) => (
        <>{ record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '操作',
      key: 'supplyFuturesProductsCol-13',
      width: 200,
      fixed: 'right',
      render: (text, record, index) => {
        const { editingKey } = this.state
        const editable = this.isEditing(record)

        return editable ? (
          <>
            <EditableContext.Consumer>
              {
                form => (
                  <Button type='link' size='small' onClick={() => this.handleSave(form, record)} >保存</Button>
                )
              }
            </EditableContext.Consumer>

            <Popconfirm title='确定放弃编辑?' onConfirm={() => this.handleCancel()}>
              <Button type='link' size='small'>取消</Button>
            </Popconfirm>
          </>
        ) : (
          <>
            {
              record.brandAgencyStatus === 'AUDIT_SUCCESS'
                ? <>
                  <Button type='primary' size='small' disabled={editingKey !== ''} style={{ marginLeft: '8px' }}
                    onClick={() => this.handleEdit(record.commoditySkuId)}
                  >
                    {
                      record.cloudSupplyFuturesStatus === 'ENABLE'
                        ? '编辑'
                        : '上架'
                    }
                  </Button>

                  <Button type='primary' size='small' style={{ marginLeft: '8px' }}
                    disabled={editingKey !== '' || record.cloudSupplyFuturesStatus === 'DISABLE'}
                    onClick={() => this.handleSetSoldOut([record.commoditySkuId])}
                  >下架</Button>
                </>
                : null
            }

            <Button type='primary' size='small' disabled={editingKey !== ''} style={{ marginLeft: '8px' }} onClick={() => this.showOperateLogModal(record)}>
              日志
            </Button>
          </>
        )
      }
    }
  ]

  componentDidMount () {
    this.getInitPageData()
  }

  // 获取页面相关数据
  getInitPageData = async () => {
    let result = await Api.getBrandList({ brandListType: 'CLOUD_SUPPLY_BRAND' })

    if (+result.code === 10000 && result.data && result.data.length > 0) {
      let { searchData = [] } = this.state

      result.data.map(item => {
        item.showLabel = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
      })

      searchData[1].options = $lodash.concat([{ brandId: 0, showLabel: '全部' }], result.data)

      this.setState({
        searchData
      }, () => { this.getCloudFuturesProductList() })
    }
  }

  // 获取云供应期货产品列表
  getCloudFuturesProductList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCloudFuturesProductList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _list = []

        if (data.pageBean && data.pageBean.list && data.pageBean.list.length > 0) {
          _list = data.pageBean.list
        }

        if (_list.length > 0) {
          _list.map(item => {
            item.copyCloudSupplyPrice = +item.cloudSupplyPrice || 0
          })
        }

        this.setState({
          tableData: _list || [],
          pages: {
            ...pages,
            total: data.pageBean ? data.pageBean.total : 0
          },
          failModal: +data.failNumber > 0,
          searchFailNumber: +data.failNumber,
          searchTotalNumber: +data.totalNumber,
          searchSuccessNumber: +data.successNumber,
          searchfailList: data.failMessageList || []
        })
      }
    })
  }

  // 云供应上架
  saveCloudApplySoldIn = async (params) => {
    const res = await Api.saveCloudApplySoldIn(params)
    const { code } = res
    if (+code === 10000) {
      this.setState({
        editingKey: '',
        delayedModal: true
      }, () => {
        this.delayedUploadTableList()
      })
    } else {
      message.warning(res.message)
    }
  }

  // 云供应下架
  saveCloudSupplySoldOut = async (params) => {
    const res = await Api.saveCloudSupplySoldOut(params)
    const { code } = res
    if (+code === 10000) {
      this.setState({
        selectedRowKeys: [],
        delayedModal: true
      }, () => {
        this.delayedUploadTableList()
      })
    } else {
      message.warning(res.message)
    }
  }

  // 上传EXCEL智能解析
  saveUploadExcelMatchSmartparse = async (params) => {
    const res = await Api.saveUploadExcelMatchSmartparse(params)
    const { code, data } = res
    if (+code === 10000) {
      this.changeUploadSuccess(data)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    const { brandId = 0, modifyTimes } = search

    search.brandId = brandId > 0 ? [brandId] : [0]

    if (modifyTimes) {
      search['beginDate'] = modifyTimes.length === 2 ? modifyTimes[0].format('YYYY-MM-DD') : undefined
      search['endDate'] = modifyTimes.length === 2 ? modifyTimes[1].format('YYYY-MM-DD') : undefined
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => {
      this.getCloudFuturesProductList()
    })
  }

  // 点击下架(批量下架)产品
  handleSetSoldOut = (keys = []) => {
    confirm({
      title: '确定下架云供应产品？',
      content: '',
      onOk: () => {
        const { pages } = this.state
        let params = {
          commoditySkuIdList: keys,
          skuType: SKUTYPE,
          pageNo: pages.pageNo,
          pageSize: pages.pageSize
        }
        this.saveCloudSupplySoldOut(params)
      }
    })
  }

  // 选择产品
  onSelectChange = (keys, rows) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 点击编辑
  handleEdit = (key) => {
    this.setState({
      editingKey: key
    })
  }

  // 点击保存编辑
  handleSave = (form, record) => {
    form.validateFields((err, values) => {
      if (!err) {
        values.commoditySkuId = record.commoditySkuId
        values.skuType = SKUTYPE

        this.saveCloudApplySoldIn(values)
      }
    })
  }

  // 取消编辑
  handleCancel = () => {
    this.setState({
      editingKey: ''
    })
  }

  // 渲染上架状态
  renderCloudSupplyStatusLabel = (status) => {
    const obj = productPutOnMap.find((o) => { return o.id === status })
    const el = obj !== undefined ? <Badge text={obj.name} color={obj.color} /> : null

    return el
  }

  // 渲染代理证状态
  renderBrandAgencyStatusLabel = (status) => {
    const obj = brandAgencyStatusMaps.find((o) => { return o.id === status })
    const el = obj !== undefined ? (<span style={{ color: obj.color }}>{ obj.name }</span>) : null

    return el
  }

  // 编辑中
  isEditing = (record) => record.commoditySkuId === this.state.editingKey

  // 点击查看日志
  showOperateLogModal = (record) => {
    this.setState({
      logModal: true,
      rowDetail: record || {}
    })
  }

  // 上传价格
  onChangeUploadFile = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ handleModal: true })

      return false
    }

    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        setTimeout(() => {
          this.setState({
            handleModal: false,
            uploadSheetDetails: {
              name: data.tableName || '',
              list: data.sheetNames || [],
              file: info.file.originFileObj
            },
            uploadSheetModal: true
          })
        }, 2000)
      } else {
        this.closeModal()
        message.error(info.file.response.message || `${info.file.name}上传失败`)
      }
    } else if (info.file.status === 'error') {
      this.closeModal()
      message.error(`${info.file.name}上传失败`)
    }
  }

  // 点击确定选择导入EXCEL的sheet
  handleConfirmUploadSheet = (values) => {
    const formData = new FormData()

    $lodash.forEach(values, (value, key) => {
      formData.append(key, value)
    })

    this.saveUploadExcelMatchSmartparse(formData)
  }

  // 监听上传成功
  changeUploadSuccess = (params) => {
    let config = {
      pageSourch: 'supplyFuturesSetting',
      sheetSourch: importConfig['supplyFuturesSetting'].sheetSourch
    }

    this.props.history.push({ pathname: '/admin/sheetsConfirm', state: { config: config, data: { importId: params } } })
  }

  // 点击查看导入失败详情
  showFailDetailModal = () => {
    this.setState({
      uploadModal: true
    })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      logModal: false,
      uploadModal: false,
      failModal: false,
      rowDetail: {},
      handleModal: false,
      uploadSheetModal: false
    })
  }

  // 延时请求
  delayedUploadTableList = () => {
    setTimeout(() => {
      this.setState({
        delayedModal: false
      }, () => this.getCloudFuturesProductList())
    }, 2000)
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCloudFuturesProductList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudFuturesProductList()
    })
  }

  render () {
    const { searchData, tableData, pages, logModal = false, uploadModal = false, rowDetail, failModal = false, searchfailList = [],
      searchFailNumber = 0, searchSuccessNumber = 0, searchTotalNumber = 0,
      selectedRowKeys = [], failList = [], totalNumber = 0, failNumber = 0, successNumber = 0, handleModal = false, delayedModal = false,
      uploadSheetModal = false, uploadSheetDetails = {} } = this.state

    const components = {
      body: {
        cell: EditableCell
      }
    }

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: 'number',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      }
    })

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }} style={{ marginBottom: '15px' }}>
          <div style={{ display: 'inline-block', width: '120px', lineHeight: '40px', verticalAlign: 'top' }}>
            <BatchSearchMenu
              onConfirm={(values) => this.handleSearch(values)}
            />
          </div>

          <Row style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Row>
        </Card>

        <Card bodyStyle={{ padding: '15px' }}>
          <Row gutter={24} style={{ marginBottom: '12px' }}>
            <Col span={12}>
              <Button type='primary' disabled={selectedRowKeys.length === 0}
                onClick={() => this.handleSetSoldOut(selectedRowKeys)}
              >批量下架</Button>

              <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => downloadCloudSupplyFuturesTemplate()}>下载模板</Button>

              <Upload name='file' showUploadList={false}
                headers={{ Token: getStore('token') }}
                accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                action={uploadMatchExcelUrl}
                onChange={this.onChangeUploadFile}
              >
                <Button type='primary' style={{ marginLeft: '10px' }}><Icon type='cloud-upload' />导入价格</Button>
              </Upload>

              {
                failList.length > 0
                  ? <Button type='primary' style={{ marginLeft: '10px' }}
                    onClick={() => this.showFailDetailModal()}
                  >查看失败详情</Button>
                  : null
              }
            </Col>

            <Col span={12} style={{ textAlign: 'right' }}>
              <span style={{ color: '#F5222D' }}>注：请尽快更新产品价格或库存，超过120个小时未更新的产品数据将不会显示在云仓中</span><Tag color='#D4DCE4' style={{ marginLeft: '12px' }}>无效产品</Tag>
            </Col>
          </Row>

          <EditableContext.Provider value={this.props.form}>
            <Table rowKey='commoditySkuId'
              components={components}
              columns={columns}
              dataSource={tableData}
              pagination={pagination}
              rowSelection={rowSelection}
              rowClassName={(record, index) => { return record.outDateLevel === 'LAST_LEVEL' ? 'darkBackground' : null }}
              scroll={{ x: 2000 }}
            />
          </EditableContext.Provider>
        </Card>

        {
          logModal
            ? <SoldlogModal
              skuType={SKUTYPE}
              detailInfo={rowDetail}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          uploadModal
            ? <UploadModal
              totalNumber={totalNumber}
              failNumber={failNumber}
              successNumber={successNumber}
              list={failList}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          failModal
            ? <UploadModal
              type={1}
              totalNumber={searchTotalNumber}
              successNumber={searchSuccessNumber}
              failNumber={searchFailNumber}
              list={searchfailList}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          handleModal
            ? <HandleModal letterArr={['EXCEL', '分', '析', '中', '...']} />
            : null
        }

        {
          delayedModal
            ? <DelayedModal />
            : null
        }

        {
          uploadSheetModal
            ? <UploadSheetModal
              name={uploadSheetDetails.name}
              list={uploadSheetDetails.list}
              file={uploadSheetDetails.file}
              onConfirm={(values) => this.handleConfirmUploadSheet(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const EditableFormTable = Form.create()(SupplyFuturesProducts)

export default connect()(EditableFormTable)
