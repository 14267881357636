import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Row, Input } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import filter from '@/common/utils/filter'

const FormItem = Form.Item
const { paywordReg } = filter

class PaywordForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {}

  componentDidMount () {}

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let { password } = values
        let jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values['password'] = jsencrypt.encrypt(password)
        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form

    return (
      <div>
        <Modal title='支付密码'
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Row>
            <Form>
              <FormItem>
                {
                  getFieldDecorator('password', {
                    getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                    validate: [
                      {
                        trigger: 'onBlur',
                        rules: [
                          {
                            validator (rule, value, callback) {
                              if (value && !paywordReg.test(value)) {
                                /* eslint-disable */
                                callback('支付密码格式不正确')
                                /* eslint-enable */
                              } else {
                                callback()
                              }
                            }
                          }
                        ]
                      },
                      {
                        trigger: ['onChange', 'onBlur'],
                        rules: [{ required: true, message: '请您填写6位数字支付密码' }]
                      }
                    ]
                  })(
                    <Input.Password placeholder='请输入6位数字支付密码' autoComplete='off'></Input.Password>
                  )
                }
              </FormItem>
            </Form>
          </Row>
        </Modal>
      </div>
    )
  }
}

const PaywordModal = Form.create()(PaywordForm)

export default PaywordModal
