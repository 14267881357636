import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setShowLoading } from '@/reducers/action'
import { Card, Row, Button, message, Table, Modal, Upload, Icon, PageHeader } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import BatchSearchMenu from '@/components/batchSearchMenu/index'
import ProductModal from '../price-setting/productModal'
import UploadModal from '@/pages/store-house/component/upload-modal'
import HandleModal from '@/pages/price/price-setting/handle-modal'
import UploadSheetModal from '@/components/uploadSheetModal'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import filter from '@/common/utils/filter'
import { getStore, getBracketStr, getCaption } from '@/common/utils/mUtils'
import { downloadExcel } from '@/common/utils/downloadFile'

const { confirm } = Modal
const { uploadMatchExcelUrl } = UploadApi
const { pageSizeOptions, importConfig } = filter

class CommonGroupDetail extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func
  }
  state = {
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      }
    ],
    detailInfo: {},
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {}, // 检索条件
    tableData: [], // SKU折扣列表
    selectedRowKeys: [], // 价格组选中的SKU集合
    productModal: false,
    searchFailModal: false, // 批量检索失败弹窗
    batchSearchResult: {},
    handleModal: false,
    uploadSheetModal: false,
    uploadSheetDetails: {
      name: '',
      list: []
    }
  }

  columns = [
    {
      title: '序号',
      key: 'discountSku-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'discountSku-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'discountSku-2',
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'discountSku-3',
      dataIndex: 'itemNumber',
      ellipsis: true
    },
    {
      title: '产品名称',
      key: 'discountSku-5',
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '通用价格',
      key: 'discountSku-6',
      dataIndex: 'defaultPrice',
      render: (text, record, index) => (
        <>
          {
            +record.defaultPrice > 0
              ? <span>&yen;{+record.defaultPrice}</span>
              : '面议'
          }
        </>
      )
    },
    {
      title: '折扣价格',
      key: 'discountSku-7',
      dataIndex: 'groupPrice',
      render: (text, record, index) => (
        <>&yen;{+record.groupPrice}</>
      )
    }
  ]

  componentDidMount () {
    const { state } = this.props.history.location
    if (state && state.detailInfo) {
      this.setState({
        detailInfo: state.detailInfo || {}
      }, () => {
        this.getBrandList({ brandListType: 'MERCHANT_BRAND' })
        this.getCommonGroupSkuListByGroupId()
      })
    }
  }

  // 获取品牌
  getBrandList = (params) => {
    Api.getBrandList(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        data.map(item => {
          item['showLabel'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[0].options = $lodash.concat([{ brandId: 0, showLabel: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取通用价格组详情
  getCommonGroupSkuListByGroupId = async () => {
    const { pages, detailInfo, query = {} } = this.state
    const { state } = this.props.history.location
    let params = {
      ...query,
      priceGroupId: detailInfo.priceGroupId,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    let res = state.state === 'future'
      ? await Api.getFutureCommonGroupSkuListByGroupId(params)
      : await Api.getCommonGroupSkuListByGroupId(params)
    const { code, data } = res
    if (+code === 10000) {
      const { pages } = this.state

      this.setState({
        tableData: data.pageBean.list || [],
        pages: {
          ...pages,
          total: data.pageBean.total || 0
        },
        batchSearchResult: {
          failNumber: +data.failNumber,
          totalNumber: +data.totalNumber,
          successNumber: +data.successNumber,
          failList: data.failMessageList || []
        },
        searchFailModal: +data.failNumber > 0
      })
    } else {
      message.warning(res.message)
    }
  }

  // 获取可添加到通用价格组的SKU列表
  getSkuListByCanAddtoCommonGroupItem = (params) => {
    const { state } = this.props.history.location
    return state.state === 'future'
      ? Api.getFutureSkuListByCanAddtoCommonGroupItem(params)
      : Api.getSkuListByCanAddtoCommonGroupItem(params)
  }

  // 添加SKU到通用价格组
  addSkuToCommonGroupItem = async (params) => {
    const { state } = this.props.history.location
    const { detailInfo = {} } = this.state
    params['priceGroupId'] = detailInfo.priceGroupId

    let res = state.state === 'future'
      ? await Api.addFutureSkuToCommonGroupItem(params, { HideLoading: true })
      : await Api.addSkuToCommonGroupItem(params, { HideLoading: true })

    if (+res.code === 10000) {
      this.closeModal()
      message.success('操作成功')

      setTimeout(() => {
        this.props.dispatch(setShowLoading({ loading: false }))
        this.getCommonGroupSkuListByGroupId()
      }, 1500)
    } else {
      this.props.dispatch(setShowLoading({ loading: false }))
      message.warning(res.message)
    }
  }

  // 从通用价格组删除SKU
  deleteSkuToCommonGroupItem = async (params) => {
    const { state } = this.props.history.location
    let res = state.state === 'future'
      ? await Api.deleteFutureSkuToCommonGroupItem(params, { HideLoading: true })
      : await Api.deleteSkuToCommonGroupItem(params, { HideLoading: true })

    if (+res.code === 10000) {
      setTimeout(() => {
        this.props.dispatch(setShowLoading({ showLoading: { loading: false } }))
        message.success('操作成功')

        this.setState({
          selectedRowKeys: []
        }, () => this.getCommonGroupSkuListByGroupId())
      }, 1500)
    } else {
      this.props.dispatch(setShowLoading({ showLoading: { loading: false } }))
      message.warning(res.message)
    }
  }

  // 上传EXCEL智能解析
  saveUploadExcelMatchSmartparse = async (params) => {
    const res = await Api.saveUploadExcelMatchSmartparse(params)
    const { code, data } = res
    if (+code === 10000) {
      this.changeUploadSuccess(data)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (+search.brandId > 0) {
      search['brandId'] = [search.brandId]
    } else {
      delete search.brandId
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => this.getCommonGroupSkuListByGroupId())
  }

  // 点击添加产品
  handleAddSkuByPriceGroup = () => {
    this.setState({
      productModal: true
    })
  }

  // 点击删除sku
  handleDeleteSkuByPriceGroup = () => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        const { selectedRowKeys = [], detailInfo = {} } = this.state
        let params = {
          priceGroupId: detailInfo.priceGroupId,
          commoditySkuIdList: selectedRowKeys
        }

        this.props.dispatch(setShowLoading({ loading: true, tip: '处理中...' }))
        this.deleteSkuToCommonGroupItem(params)
      }
    })
  }

  // 上传SKU信息
  changeUploadSkuByPriceGroup = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ handleModal: true })

      return false
    }

    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        setTimeout(() => {
          this.setState({
            handleModal: false,
            uploadSheetDetails: {
              name: data.tableName || '',
              list: data.sheetNames || [],
              file: info.file.originFileObj
            },
            uploadSheetModal: true
          })
        }, 2000)
      } else {
        this.closeModal()
        message.error(info.file.response.message || `${info.file.name}上传失败`)
      }
    } else if (info.file.status === 'error') {
      this.closeModal()
      message.error(`${info.file.name}上传失败`)
    }
  }

  // 点击确定选择导入EXCEL的sheet
  handleConfirmUploadSheet = (values) => {
    const formData = new FormData()

    $lodash.forEach(values, (value, key) => {
      formData.append(key, value)
    })

    this.saveUploadExcelMatchSmartparse(formData)
  }

  // 监听上传成功
  changeUploadSuccess = (params) => {
    const { detailInfo = {} } = this.state
    const { state } = this.props.history.location
    let type = state && state.state === 'future' ? 'futurepricegroupSetting' : 'pricegroupSetting'
    let typeA = state && state.state === 'future' ? 'futurecuspricegroupSetting' : 'cuspricegroupSetting'
    let config = {
      pageSourch: detailInfo.groupType === 'COMMON' ? type : typeA,
      sheetSourch: detailInfo.groupType === 'COMMON'
        ? importConfig[`${type}`].sheetSourch
        : importConfig[`${typeA}`].sheetSourch,
      commonGuestType: detailInfo.groupType,
      priceGroupId: detailInfo.priceGroupId
    }

    this.props.history.push({ pathname: '/admin/sheetsConfirm', state: { config: config, data: { importId: params } } })
  }

  // 点击选择价格组SKU
  onSelectEndSkuChange = (keys) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 点击导出批量检索失败明细
  handleDownFailDetail = () => {
    const { batchSearchResult = {} } = this.state
    if (!batchSearchResult.failList || batchSearchResult.failList.length === 0) {
      return message.warning('暂无数据')
    }

    let _list = [
      ['品牌', '订货号', '型号', '失败原因']
    ]

    batchSearchResult.failList.forEach(item => {
      let arr = getBracketStr(item).split('/')
      let _res = getCaption(item)
      let _arr = []
      _arr.push(arr[0], arr[1], arr[2], arr[3], _res)

      _list.push(_arr)
    })

    downloadExcel(_list, '失败明细.xlsx')
  }

  closeModal = () => {
    this.setState({
      productModal: false,
      searchFailModal: false,
      handleModal: false,
      uploadSheetModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCommonGroupSkuListByGroupId()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCommonGroupSkuListByGroupId()
    })
  }

  render () {
    const { searchData = {}, pages, tableData = [], selectedRowKeys = [], productModal = false, detailInfo = {},
      searchFailModal = false, batchSearchResult = {}, handleModal = false, uploadSheetModal = false, uploadSheetDetails = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const endRowSelection = {
      columnWidth: 44,
      selectedRowKeys: selectedRowKeys,
      onChange: (keys) => this.onSelectEndSkuChange(keys)
    }

    return (
      <div>
        <Row style={{ marginBottom: '16px' }}>
          <Card bodyStyle={{ padding: '16px' }}>
            <PageHeader style={{ padding: '0px 0px 16px 0px' }}
              title={<div style={{ fontSize: '16px' }}>
                <span>{detailInfo.priceGroupName}</span>
                <span style={{ marginLeft: '12px' }}>折扣率: {+detailInfo.discount > 0 ? `${(+detailInfo.discount * 100).toFixed(2)}%` : '----'}</span>
              </div>
              }
              onBack={() => this.props.history.go(-1)}
            />
            <Row>
              <Row style={{ display: 'inline-block', width: '120px', lineHeight: '40px', verticalAlign: 'top' }}>
                <BatchSearchMenu
                  onConfirm={(values) => this.handleSearch(values)}
                />
              </Row>
              <Row style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
                <SearchForm {...this.props}
                  dataSource={searchData}
                  handleSubmit={(obj) => this.handleSearch(obj)}
                />
              </Row>
            </Row>
          </Card>
        </Row>

        <Row>
          <Card bodyStyle={{ padding: '16px' }}>
            <Row style={{ marginBottom: '16px' }}>
              <Button type='primary'
                disabled={selectedRowKeys.length === 0}
                onClick={this.handleDeleteSkuByPriceGroup}
              ><Icon type='delete' />删除产品</Button>

              <Button type='primary'
                style={{ marginLeft: '12px' }}
                onClick={() => this.handleAddSkuByPriceGroup()}
              ><Icon type='plus' />添加产品</Button>

              <Upload name='file' showUploadList={false}
                headers={{
                  Token: getStore('token')
                }}
                data={{ priceGroupId: detailInfo.priceGroupId }}
                accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                action={uploadMatchExcelUrl}
                onChange={this.changeUploadSkuByPriceGroup}
              >
                <Button type='primary' style={{ marginLeft: '12px' }}><Icon type='cloud-upload' />上传产品</Button>
              </Upload>
            </Row>

            <Row>
              <Table rowKey='commoditySkuId'
                columns={this.columns}
                dataSource={tableData}
                pagination={pagination}
                rowSelection={endRowSelection}
              />
            </Row>
          </Card>
        </Row>

        {
          productModal
            ? <ProductModal
              detailInfo={detailInfo}
              type={detailInfo.groupType}
              getProductList={(params) => this.getSkuListByCanAddtoCommonGroupItem(params)}
              onCancel={() => this.closeModal()}
              onConfirm={(values) => {
                this.props.dispatch(setShowLoading({ loading: true, tip: '处理中...' }))
                this.addSkuToCommonGroupItem(values)
              }}
            />
            : null
        }

        {
          searchFailModal
            ? <UploadModal type={1} hasDownBtn={1}
              totalNumber={batchSearchResult.totalNumber}
              successNumber={batchSearchResult.successNumber}
              failNumber={batchSearchResult.failNumber}
              list={batchSearchResult.failList}
              onCancel={() => this.closeModal()}
              onDownload={() => this.handleDownFailDetail()}
            />
            : null
        }

        {
          handleModal
            ? <HandleModal letterArr={['EXCEL', '分', '析', '中', '...']} />
            : null
        }

        {
          uploadSheetModal
            ? <UploadSheetModal
              name={uploadSheetDetails.name}
              list={uploadSheetDetails.list}
              file={uploadSheetDetails.file}
              onConfirm={(values) => this.handleConfirmUploadSheet(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </div>
    )
  }
}

export default connect()(CommonGroupDetail)
