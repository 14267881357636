import React, { Component } from 'react'
import { Row, Card, Tabs } from 'antd'

import SysnotifysList from './sysnotifysList'
import SysnotifysSended from './sysnotifysSended'

import styles from './index.module.scss'

const { TabPane } = Tabs

export default class SystemNotifys extends Component {
  static propTypes = {}
  state = {
    activeTab: 'RECEIVED'
  }

  componentDidMount () { }

  // 点击切换tab
  handleChangeTabPane = (key) => {
    this.setState({
      activeTab: key
    })
  }

  render () {
    const { activeTab = 'RECEIVED' } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '12px 0px 0px 0px' }} style={{ marginBottom: '15px' }}>
          <Row className={`${styles.tabsLand}`}>
            <Tabs activeKey={activeTab} onChange={this.handleChangeTabPane}>
              <TabPane tab={'系统通知列表'} key={'RECEIVED'} />
              <TabPane tab={'系统通知发送历史'} key={'SENDED'} />
            </Tabs>
          </Row>
        </Card>

        {
          activeTab === 'RECEIVED'
            ? <SysnotifysList />
            : null
        }

        {
          activeTab === 'SENDED'
            ? <SysnotifysSended />
            : null
        }
      </>
    )
  }
}
