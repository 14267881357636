import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Form, Select, Input } from 'antd'

const FormItem = Form.Item
const Option = Select.Option
const floortplMaps = [
  { id: '1', name: '品牌推荐' },
  { id: '2', name: '横图' },
  { id: '3', name: '左图右文案' },
  { id: '4', name: '左文案右图' },
  { id: '5', name: '产品专区' }
]

class FloorForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number, // 1-新增 2-编辑
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    selectedKey: ''
  }

  componentDidMount () { }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onConfirm(values)
      }
    })
  }

  // 选择模板
  handleChangeSelectFloorTpl = (key) => {
    this.setState({
      selectedKey: key
    })
  }

  render () {
    const { type = 1, detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form
    const { selectedKey = '' } = this.state

    return (
      <div>
        <Modal title={type === 1 ? `新增楼层` : '编辑楼层名称'}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Row>
            <Form>
              {
                type === 1
                  ? <FormItem label='楼层模板'>
                    {
                      getFieldDecorator('type', {
                        rules: [
                          { required: true, message: '请选择模板' }
                        ]
                      })(
                        <Select onChange={this.handleChangeSelectFloorTpl}>
                          {
                            floortplMaps.map((item, i) => (
                              <Option value={item.id} key={`floortpl-${i}`}>{item.name}</Option>
                            ))
                          }
                        </Select>
                      )
                    }
                  </FormItem>
                  : null
              }

              {
                +selectedKey > 2 || type === 2
                  ? <FormItem label='楼层名称'>
                    {
                      getFieldDecorator('name', {
                        initialValue: detailInfo.name || '',
                        rules: [
                          { required: true, message: '请填写楼层名称' }
                        ]
                      })(
                        <Input placeholder='请输入...' autoComplete='off' />
                      )
                    }
                  </FormItem>
                  : null
              }

            </Form>
          </Row>
        </Modal>
      </div>
    )
  }
}

const FloorModal = Form.create()(FloorForm)

export default FloorModal
