import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Upload } from 'antd'

import UploadApi from '@/common/api/uploadApi'

import { getStore } from '@/common/utils/mUtils'
import IntelImage from '@/assets/images/intel_upload_image.png'
import styles from './index.module.scss'

const { uploadMatchExcelUrl } = UploadApi
const { Dragger } = Upload

class UploadModal extends Component {
  static propTypes = {
    onUpload: PropTypes.func,
    onCancel: PropTypes.func
  }

  render () {
    const { onCancel, onUpload } = this.props

    return (
      <>
        <Modal title='文件上传' width={980}
          visible
          onCancel={() => onCancel()}
          footer={null}
        >
          <div className={`${styles.uploadLand}`}>
            <Dragger name='file' showUploadList={false}
              accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              headers={{ Token: getStore('token') }}
              action={uploadMatchExcelUrl}
              onChange={(info) => onUpload(info)}
            >
              <div className={`${styles.draggerContent}`}>
                <div style={{ flex: '1' }}>
                  <div className={`${styles.imgBox}`}>
                    <img className={`${styles.pic}`} src={IntelImage} />
                  </div>
                  <div style={{ marginBottom: '12px' }}>
                    <div>点击上传或将文件拖拽至此</div>
                    <p className={'redColor'}>只支持上传excel</p>
                  </div>
                </div>
              </div>
            </Dragger>
          </div>
        </Modal>
      </>
    )
  }
}

export default UploadModal
