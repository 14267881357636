import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Select, message } from 'antd'

const Option = Select.Option

export default class PrinterModal extends Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  state = {
    selectedPrint: ''
  }

  // 点击确认
  handleConfirm = () => {
    const { selectedPrint = '' } = this.state

    if (selectedPrint === '') {
      return message.warning('请选择打印机')
    }

    this.props.onConfirm(selectedPrint)
  }

  // 选择打印机
  selectedPrinter = (name) => {
    this.setState({
      selectedPrint: name
    })
  }

  render () {
    const { list = [] } = this.props
    return (
      <Modal title='选择打印机' visible
        maskClosable={false}
        onCancel={this.props.onCancel}
        onOk={this.handleConfirm}
      >
        <Select style={{ width: '100%' }} placeholder='请选择打印机'
          onChange={this.selectedPrinter}
        >
          {
            list.map((item, i) => (
              <Option value={item} key={`printer-${i}`}>{item}</Option>
            ))
          }
        </Select>
      </Modal>
    )
  }
}
