import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, Form, Button, Input, message } from 'antd'

import AuthorTree from '../component/author-tree'

import Api from '@/common/api/index'

const FormItem = Form.Item
const { TextArea } = Input

class AuthorityForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }
  state = {
    detailInfo: {},
    info: {},
    selectedMenuKeys: [],
    menuListInfo: []
  }

  componentDidMount () {
    const { location } = this.props.history
    const { state } = location

    this.setState({
      detailInfo: state || {}
    }, () => {
      if (state.type && state.type === 2) {
        // 编辑
        this.getRoleDetail()
      } else {
        // 新增
        this.getMenuListForRole()
      }
    })
  }

  // 获取菜单树
  getMenuListForRole = () => {
    Api.getMenuListForRole({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: {
            menuNodeList: data || []
          }
        })
      }
    })
  }

  // 设置默认展开/选中的菜单
  setDefaultExpandKeys = (list) => {
    let openArr = []
    let selectedArr = []

    if (list.length > 0) {
      list.map(menu => {
        let { children = [] } = menu
        if (menu.menuPermission === 'MENU_PERMISSION_YES') {
          openArr.push(`${menu.menuId}`)
        }

        if (children.length > 0) {
          children.map(item => {
            if (item.menuPermission === 'MENU_PERMISSION_YES') {
              selectedArr.push(`${item.menuId}`)
            }
          })
        }
      })
    }

    return selectedArr
  }

  // 获取角色详情
  getRoleDetail = () => {
    const { detailInfo = {} } = this.state
    let params = {
      roleId: detailInfo.roleId
    }

    Api.getRoleDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _list = data.menuNodeList || []
        let _defaultSelected = []

        if (_list && _list.length > 0) {
          _defaultSelected = this.setDefaultExpandKeys(_list)
        }

        this.setState({
          info: data || {},
          selectedMenuKeys: _defaultSelected
        })
      }
    })
  }

  // 保存更新设置
  saveRoleAuthor = (params) => {
    Api.saveRoleAuthor(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
      }
    })
  }

  // 保存新增设置
  addRoleAuthor = (params) => {
    Api.addRoleAuthor(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
      }
    })
  }

  // 点击保存
  handleSave = () => {
    const { info = {}, selectedMenuKeys = [], detailInfo = {} } = this.state

    this.props.form.validateFields((err, values) => {
      if (!err) {
        let params = {
          ...values,
          menuIdList: selectedMenuKeys
        }

        if (detailInfo.type && detailInfo.type === 2) {
          params.roleId = info.roleId
          this.saveRoleAuthor(params)
        } else {
          this.addRoleAuthor(params)
        }
      }
    })
  }

  handleBack = () => {
    this.props.history.goBack()
  }

  onSelectedMenuKeys = (keys = []) => {
    this.setState({
      selectedMenuKeys: keys
    })
  }

  closeModal = () => {
    this.setState({
      modal: {
        departModal: false
      },
      modaltype: 1,
      rowDetail: {}
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { detailInfo = {}, info = {} } = this.state
    const { menuNodeList = [] } = info

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Button onClick={() => this.handleBack()}>返回列表</Button>
          </Row>

          <Row>
            <Col span={4}>
              <Form>
                <FormItem label='角色名称'>
                  {
                    getFieldDecorator('roleName', {
                      initialValue: info.roleName || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Input placeholder='请输入...' autoComplete='off'
                        disabled={detailInfo.type === 2 && info.roleType && info.roleType === 'SYS_ROLE'}
                      />
                    )
                  }
                </FormItem>
                <FormItem label='角色描述'>
                  {
                    getFieldDecorator('description', {
                      initialValue: info.description || ''

                    })(
                      <TextArea rows={4} placeholder='请输入...' autoComplete='off'
                        disabled={detailInfo.type === 2 && info.roleType && info.roleType === 'SYS_ROLE'}
                      />
                    )
                  }
                </FormItem>
              </Form>
            </Col>

            <Col span={20} style={{ paddingLeft: '32px' }}>
              {
                menuNodeList.length > 0
                  ? <AuthorTree list={menuNodeList}
                    onSelected={(params) => this.onSelectedMenuKeys(params)}
                  />
                  : null
              }
            </Col>
          </Row>

          <Row style={{ textAlign: 'center' }}>
            <Button type='primary'
              onClick={() => this.handleSave()}>保存</Button>
          </Row>
        </Card>
      </>
    )
  }
}

const Authority = Form.create()(AuthorityForm)

export default Authority
