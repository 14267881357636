import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, Tabs, Icon, Button, Table, InputNumber, Badge, Popconfirm, message } from 'antd'
import $lodash from 'lodash'

import HandleModal from '@/pages/price/price-setting/handle-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { tableColumnsMaps } from './localdatas'

const { TabPane } = Tabs
const { pageSizeOptions } = filter
const tabMaps = [
  { type: 'ALL', label: '所有型号', icon: '', color: '' },
  { type: 'SUCCESS', label: '匹配成功', icon: 'check-circle', color: '#52c41a' },
  { type: 'FAIL', label: '需要修正', icon: 'exclamation-circle', color: '#f5222d' }
]
const _letterArrMaps = {
  'PRICE_DEFAULT': ['价', '格', '导', '入', '中'],
  'PRICE_GUEST': ['价', '格', '导', '入', '中'],
  'PRICE_GROUP_COMMON': ['价', '格', '导', '入', '中'],
  'PRICE_GROUP_GUEST': ['价', '格', '导', '入', '中'],
  'PRODUCT_CLOUD_SUPPLY': ['价', '格', '导', '入', '中'],
  'PRODUCT_STOCK': ['库', '存', '导', '入', '中'],
  'PRODUCT_LIBRARY_IMPORT': ['数', '据', '导', '入', '中']
}

export default class index extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    curtabKey: '',
    detailInfo: {},
    handleModal: false,
    editingKey: '',
    tableData: [],
    columns: [
      {
        title: '序号',
        key: 'indexNumber',
        width: 44,
        align: 'center',
        render: (text, record, index) => (`${index + 1}`)
      },
      {
        title: '品牌',
        key: 'brandName',
        width: 130,
        dataIndex: 'brandName',
        ellipsis: true
      },
      {
        title: '匹配编号',
        key: 'matchNo',
        width: 130,
        ellipsis: true,
        dataIndex: 'matchNo',
        render: (text, record, index) => (
          <>{record.matchNo}</>
        )
      },
      {
        title: '状态',
        key: 'matchResult',
        width: 100,
        dataIndex: 'matchResult',
        render: (text, record, index) => (
          <>
            {
              record.matchResult === 'SUCCESS'
                ? <Badge status='success' text='成功' />
                : <Badge status='error' text='失败' />
            }
          </>
        )
      },
      {
        title: '原因',
        key: 'failReason',
        width: 300,
        dataIndex: 'failReason',
        render: (text, record, index) => (
          <div style={{ color: record.matchResult === 'SUCCESS' ? 'rgb(82, 196, 26)' : 'rgb(245, 34, 45)' }}>
            {record.failReason}
          </div>
        )
      },
      {
        title: '最低折扣',
        key: 'importLowestPrice',
        width: 150,
        render: (text, record, index) => (
          this.renderLowPriceCell(record, index)
        )
      },
      {
        title: '通用价格',
        key: 'importDefaultPrice',
        width: 150,
        dataIndex: 'importDefaultPrice',
        render: (text, record, index) => (
          this.renderDefaultPriceCell(record, index)
        )
      },
      {
        title: '客价',
        key: 'importGuestPrice',
        width: 150,
        render: (text, record, index) => (
          this.renderGuestPriceCell(record, index)
        )
      },
      {
        title: '库存总数',
        key: 'importTotalStock',
        width: 150,
        render: (text, record, index) => (
          this.renderStockTotalCell(record, index)
        )
      },
      {
        title: '供应价格',
        key: 'importSupplyPrice',
        width: 150,
        render: (text, record, index) => (
          this.renderCloudSupplyPriceCell(record, index)
        )
      },
      {
        title: '货期',
        key: 'deliveryPeriodDays',
        dataIndex: 'importDeliveryTime',
        editable: true,
        render: (text, record, index) => (
          <>
            {
              record.importDeliveryTime && record.importDeliveryTime !== ''
                ? <>{+record.importDeliveryTime }工作日</>
                : null
            }
          </>
        )
      },
      {
        title: '预付比例',
        key: 'prepaymentRatio',
        dataIndex: 'importPrepayment',
        editable: true,
        render: (text, record, index) => (
          <>
            {
              record.importPrepayment && record.importPrepayment !== ''
                ? <>{(+record.importPrepayment * 100 / 100).toFixed(2)}%</>
                : null
            }
          </>
        )
      },
      {
        title: '型号',
        key: 'model',
        width: 120,
        ellipsis: true,
        dataIndex: 'model'
      },
      {
        title: '订货号',
        key: 'itemNumber',
        width: 120,
        ellipsis: true,
        dataIndex: 'itemNumber'
      },
      {
        title: '产品名称',
        key: 'name',
        width: 150,
        ellipsis: true,
        dataIndex: 'name'
      },
      {
        title: '操作',
        key: 'operation',
        width: 120,
        render: (text, record, index) => {
          const { editingKey } = this.state
          const editable = this.isEditing(record)
          return editable ? (
            <>
              <Button type='primary' size='small' onClick={() => this.handleSave(record, index)} style={{ marginRight: '8px' }}>保存</Button>

              <Popconfirm title='确定放弃编辑?' onConfirm={() => this.handleCancel(index)}>
                <Button type='primary' size='small'>取消</Button>
              </Popconfirm>
            </>
          ) : (
            <>
              {
                record.modify
                  ? <Button type='primary' size='small' disabled={editingKey !== ''} onClick={() => this.handleEdit(record)}>
                    编辑
                  </Button>
                  : null
              }
            </>
          )
        }
      }
    ]
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location
    const { config = {}, detailInfo = {} } = state
    let { columns } = this.state
    let _columns = []
    console.log('config===', config)

    if (['PRICE_DEFAULT', 'FUTURE_PRICE_DEFAULT'].includes(config.sheetSourch)) {
      Api.getLowestPriceDisabeldStatus({ configType: 'LOWEST_PRICE' }).then(res => {
        const { code, data } = res
        if (+code === 10000) {
          _columns = config.sheetSourch ? $lodash.intersectionBy(columns, tableColumnsMaps[config.sheetSourch], 'key') : columns

          if (data && data === 'DISABLE') {
            _columns = $lodash.filter(_columns, (item) => { return item.key !== 'importLowestPrice' })
          }

          this.setState({
            curtabKey: 'ALL',
            detailInfo: detailInfo,
            config: config,
            columns: _columns
          }, () => {
            this.getUploadPriceMatchResult()
          })
        }
      })

      return false
    }

    const tableColumns = config.sheetSourch === 'PRODUCT_LIBRARY_IMPORT' ? tableColumnsMaps[config.importType] : tableColumnsMaps[config.sheetSourch]

    _columns = config.sheetSourch ? $lodash.intersectionBy(columns, tableColumns, 'key') : columns

    this.setState({
      curtabKey: 'ALL',
      detailInfo: detailInfo,
      config: config,
      columns: _columns
    }, () => {
      this.getUploadPriceMatchResult()
    })
  }

  // 保存导入价格设置
  saveImportPriceSetting = (params) => {
    Api.saveImportPriceSetting(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        setTimeout(() => {
          const { detailInfo, config = {} } = this.state
          this.props.history.push({ pathname: '/admin/settingResult', state: { importId: detailInfo.importId, config: config } })
        }, 3000)
      }
    })
  }

  // 点击下一步
  handleSaveImportSetting = () => {
    const { detailInfo } = this.state

    this.setState({
      handleModal: true
    }, () => {
      this.saveImportPriceSetting({ importId: detailInfo.importId })
    })
  }

  // 获取导入结果详细
  getUploadPriceMatchResult = () => {
    const { detailInfo, curtabKey = 'ALL', pages } = this.state

    let params = {
      importId: detailInfo.importId,
      result: curtabKey,
      matchType: 'MATCH',
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getUploadPriceMatchResult(params).then(res => {
      const { code, data = [] } = res
      if (+code === 10000) {
        let _list = data.list || []

        if (_list.length > 0) {
          _list.map(item => {
            item.copyLowestDiscount = item.lowestDiscount || 0
            item.copyLowestPrice = item.importLowestPrice || 0

            item.copyDefaultDiscount = item.defaultDiscount || 0
            item.copyDefaultPrice = item.importDefaultPrice || 0
          })
        }

        this.setState({
          tableData: _list,
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 保存价格编辑
  saveUpdateMetchFailResult = (params, i) => {
    Api.saveUpdateMetchFailResult(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        let { tableData } = this.state

        tableData[i].matchResult = 'SUCCESS'
        tableData[i].failReason = '请求成功'

        this.setState({
          editingKey: '',
          tableData
        })
      }
    })
  }

  // 渲染最低折扣单元列
  renderLowPriceCell = (record, index) => {
    const editable = this.isEditing(record)
    return editable ? (
      <Row>
        {
          record.priceType && record.priceType === 'PRICE'
            ? null
            : <div style={{ marginBottom: '5px' }}>
              <label style={{ marginRight: '5px' }}>折扣:</label>
              <InputNumber
                size='small'
                min={0}
                max={100}
                formatter={value => `${value}%`}
                parser={value => value.replace('%', '')}
                onChange={(value) => this.onChangeDiscount(value / 100, index, 'importLowest')}
              />
            </div>
        }

        <div>
          <label style={{ marginRight: '5px' }}>价格:</label>
          <InputNumber
            size='small'
            name='lowPrice'
            min={0}
            value={record.importLowestPrice}
            onChange={(value) => this.onChangePrice(value, index, 'importLowest')}
          />
        </div>
      </Row>
    ) : (
      <Row>
        {
          record.priceType && record.priceType === 'PRICE'
            ? null
            : <div style={{ marginBottom: '5px' }}>
              <label style={{ marginRight: '5px' }}>折扣:</label>
              <span>{this.renderShowDiscountText(+record.importLowestPrice, record.price)}</span>
            </div>
        }
        <div>
          <label style={{ marginRight: '5px' }}>价格:</label>
          <span>
            {+record.importLowestPrice > 0 ? +record.importLowestPrice : '面议'}
          </span>
        </div>
      </Row>
    )
  }

  // 渲染展示通用折扣单元格
  renderDefaultPriceCell = (record, index) => {
    const editable = this.isEditing(record)

    return (
      <Row>
        {
          <div>
            {
              record.priceType && record.priceType === 'PRICE'
                ? null
                : <div>
                  {
                    editable
                      ? <div style={{ marginBottom: '5px' }}>
                        <label style={{ marginRight: '5px' }}>折扣:</label>
                        <InputNumber
                          size='small'
                          min={0}
                          max={100}
                          formatter={value => `${value}%`}
                          parser={value => value.replace('%', '')}
                          onChange={(value) => this.onChangeDiscount(value / 100, index, 'importDefault')}
                        />
                      </div>
                      : <div style={{ marginBottom: '5px' }}>
                        <label style={{ marginRight: '5px' }}>折扣:</label>
                        <span>{this.renderShowDiscountText(record.importDefaultPrice, +record.price)}</span>
                      </div>
                  }
                </div>
            }
          </div>
        }

        <div>
          <label style={{ marginRight: '5px' }}>价格:</label>
          <span>
            {+record.importDefaultPrice > 0 ? +record.importDefaultPrice : '面议'}
          </span>
        </div>
      </Row>
    )
  }

  // 渲染客价单元格
  renderGuestPriceCell = (record, index) => {
    const editable = this.isEditing(record)

    return editable ? (
      <Row>
        {
          record.priceType && record.priceType === 'PRICE'
            ? null
            : <div style={{ marginBottom: '5px' }}>
              <label style={{ marginRight: '5px' }}>折扣:</label>
              <InputNumber
                size='small'
                min={0}
                max={100}
                formatter={value => `${value}%`}
                parser={value => value.replace('%', '')}
                onChange={(value) => this.onChangeDiscount(value / 100, index, 'importGuest')}
              />
            </div>
        }

        <div>
          <label style={{ marginRight: '5px' }}>价格:</label>
          <InputNumber
            size='small'
            name='guestPrice'
            min={0}
            onChange={(value) => this.onChangePrice(value, index, 'importGuest')}
          />
        </div>
      </Row>
    ) : (
      <Row>
        {
          record.priceType && record.priceType === 'PRICE'
            ? null
            : <div style={{ marginBottom: '5px' }}>
              <label style={{ marginRight: '5px' }}>折扣:</label>
              <span>{this.renderShowDiscountText(+record.importGuestPrice, record.price)}</span>
            </div>
        }
        <div>
          <label style={{ marginRight: '5px' }}>价格:</label>
          <span>
            {+record.importGuestPrice > 0 ? +record.importGuestPrice : '面议'}
          </span>
        </div>
      </Row>
    )
  }

  // 渲染库存总数单元格
  renderStockTotalCell = (record, index) => {
    const editable = this.isEditing(record)

    return editable ? (
      <Row>
        <InputNumber
          size='small'
          name='importTotalStock'
          min={0}
          onChange={(value) => this.onChangeInputNumber(value, index, 'importTotalStock')}
        />
      </Row>
    ) : (
      <Row>
        <div>
          {+record.importTotalStock}
        </div>
      </Row>
    )
  }

  // 渲染云供应价格单元格
  renderCloudSupplyPriceCell = (record, index) => {
    const editable = this.isEditing(record)

    return editable ? (
      <Row>
        <InputNumber
          size='small'
          name='importGuestPrice'
          min={0}
          onChange={(value) => this.onChangeInputNumber(value, index, 'importGuestPrice')}
        />
      </Row>
    ) : (
      <Row>
        <div>
          {+record.importGuestPrice}
        </div>
      </Row>
    )
  }

  // 渲染通用价格组 通用价格单元格
  renderCommonPriceGroupCell = (record, index) => {
    return <div>&yen;{+record.guestPrice}</div>
  }

  // 点击切换tab
  handleChangeTabPane = (key) => {
    const { pages } = this.state
    this.setState({
      curtabKey: key,
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: 20,
        total: 0
      }
    }, () => {
      this.getUploadPriceMatchResult()
    })
  }

  // 点击编辑
  handleEdit = (record) => {
    this.setState({
      editingKey: record.rowId
    })
  }

  // 点击保存编辑
  handleSave = (record, i) => {
    const { config = {} } = this.state
    let obj = {
      rowId: record.rowId,
      importId: record.importId,
      importType: config.importType
    }

    if (config.sheetSourch === 'PRICE_DEFAULT') {
      obj.defaultPrice = record.importDefaultPrice
      obj.lowestPrice = record.importLowestPrice
    }

    if (config.sheetSourch === 'PRICE_GUEST' || config.sheetSourch === 'PRODUCT_CLOUD_SUPPLY') {
      obj.guestPrice = record.importGuestPrice
    }

    if (config.sheetSourch === 'PRODUCT_STOCK') {
      obj.totalStock = record.importTotalStock
    }

    this.saveUpdateMetchFailResult(obj, i)
  }

  // 点击取消编辑
  handleCancel = (i) => {
    let { tableData } = this.state

    tableData[i].lowestDiscount = tableData[i].copyLowestDiscount
    tableData[i].lowestPrice = tableData[i].copyLowestPrice
    tableData[i].defaultDiscount = tableData[i].copyDefaultDiscount

    this.setState({
      tableData,
      editingKey: ''
    })
  }

  // 编辑中
  isEditing = (record) => record.rowId === this.state.editingKey

  // 监听折扣输入
  onChangeDiscount = (value, i, type) => {
    let { tableData } = this.state

    tableData[i][`${type}Discount`] = value
    tableData[i][`${type}Price`] = this.calcPrice(tableData[i].price, value)

    this.setState({
      tableData: tableData
    })
  }

  // 监听价格输入
  onChangePrice = (value, i, type) => {
    let { tableData } = this.state

    tableData[i][`${type}Price`] = value
    tableData[i][`${type}Discount`] = this.calcDiscount(tableData[i].price, value)

    this.setState({
      tableData: tableData
    })
  }

  // 根据价格计算折扣
  calcDiscount = (basePrice, val) => {
    let disc = (val * 100) / (basePrice * 100)

    return disc
  }

  // 根据折扣计算价格
  calcPrice = (basePrice, val) => {
    const price = ((basePrice * 100) * val / 100).toFixed(4)
    return price
  }

  // 监听数量输入
  onChangeInputNumber = (value, i, type) => {
    let { tableData } = this.state

    tableData[i][`${type}`] = value

    this.setState({
      tableData: tableData
    })
  }

  // 渲染展示折扣
  renderShowDiscountText = (price, basePrice) => {
    let text = 'NAN%'

    if (+price > 0) {
      text = `${(+price * 10000 / (+basePrice * 10000) * 100).toFixed(2)}%`
    }

    return text
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getUploadPriceMatchResult()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getUploadPriceMatchResult()
    })
  }

  render () {
    const { pages, config = {}, detailInfo = {}, handleModal = false, columns, tableData } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '16px' }}>
          <Card bodyStyle={{ padding: '16px' }}>
            <Row>
              <Col span={12}>
                <Row gutter={12}>
                  <Col span={8} style={{ color: 'rgba(0, 0, 0, 0.8)' }}>
                    <div style={{ fontWeight: '600', marginBottom: '4px' }}>总共</div>
                    <div style={{ fontSize: '18px' }}>{detailInfo.totalNum}</div>
                  </Col>
                  <Col span={8} style={{ color: '#52c41a' }}>
                    <div style={{ fontWeight: '600', marginBottom: '4px' }}>匹配成功</div>
                    <div style={{ fontSize: '18px' }}>{detailInfo.successNum}</div>
                  </Col>
                  <Col span={8} style={{ color: '#f5222d' }}>
                    <div style={{ fontWeight: '600', marginBottom: '4px' }}>需要修正</div>
                    <div style={{ fontSize: '18px' }}>{detailInfo.failNum}</div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Row>
        <Row>
          <Card bodyStyle={{ padding: '16px' }}>
            <Row>
              <Tabs onChange={this.handleChangeTabPane} tabBarExtraContent={<div>
                <Button type='primary' onClick={() => this.handleSaveImportSetting()}>
                  下一步<Icon type='arrow-right' />
                </Button>
              </div>}>
                {
                  tabMaps.map((titem, n) => (
                    <TabPane tab={<span>{titem.icon !== '' ? <Icon type={titem.icon} style={{ color: titem.color !== '' ? titem.color : '' }} /> : null}{titem.label}</span>}
                      key={titem.type}
                    />
                  ))
                }
              </Tabs>

            </Row>

            <Row>
              <Table rowKey='rowId' bordered
                columns={columns}
                dataSource={tableData}
                pagination={pagination}
                scroll={{ x: 1500 }}
              />
            </Row>
          </Card>
        </Row>

        {
          handleModal
            ? <HandleModal
              letterArr={_letterArrMaps[config.sheetSourch]}
            />
            : null
        }

      </>
    )
  }
}
