import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Card, Form, Row, Col, Select, message, Button } from 'antd'

import Api from '@/common/api/index'
import { renderAddressLabel, formatBankAccount } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const FormItem = Form.Item
const { Option } = Select

class EndLand extends Component {
  static propTypes = {
    onRef: PropTypes.func,
    onSelect: PropTypes.func,
    history: PropTypes.object
  }
  state = {
    customerList: [], // 客户列表
    selectedUserInfo: {}, // 选中的客户信息
    endModal: false
  }

  componentDidMount () {
    this.props.onRef(this)
    this.getGuesteuUserLike({ searchName: '', pageNo: 1, pageSize: 999 })
  }

  // 搜索客户列表
  getGuesteuUserLike = (params, defaultUserId) => {
    Api.getGuesteuUserLike(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          customerList: data.list || []
        }, () => {
          const { customerList = [] } = this.state
          let i = customerList.findIndex((item) => { return item.endUserId === defaultUserId })
          if (defaultUserId && i > -1) {
            this.getGuestListBySeller(defaultUserId)
          }
        })
      } else {
        message.error(data.message)
      }
    })
  }

  // 选择客户获取详情
  getGuestListBySeller = (endUserId) => {
    Api.getGuestListBySeller({ euId: endUserId, pageNo: 1, pageSize: 10 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        if (data && data.list && data.list.length > 0) {
          this.setState({
            selectedUserInfo: {
              endUserId: endUserId,
              ...data.list[0]
            }
          }, () => {
            this.props.onSelect(this.state.selectedUserInfo)
          })
        } else {
          message.warning('暂无详细信息')
        }
      } else {
        message.error(data.message)
      }
    })
  }

  // 新增客户
  saveAddEnduser = (params) => {
    Api.saveAddEnduser(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        message.success('新增客户成功')
        this.closeModal()
        this.getGuesteuUserLike({ searchName: '', pageNo: 1, pageSize: 999 }, data.id)
      }
    })
  }

  // 点击添加新客户
  getAddEnduserDetail = (record, type = 1) => {
    this.props.history.push({ pathname: '/admin/enduser-detail', state: { ...record, type: type } })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      endModal: false
    })
  }

  render () {
    const { customerList = [], selectedUserInfo = {} } = this.state

    return (
      <>
        <Card title='基本信息' bodyStyle={{ padding: '15px', minHeight: '160px' }}>
          <Form>
            <Row gutter={24}>
              <Col span={10}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label={<span className='required'>客户名称</span>}>
                    <Select placeholder='请输入...' showSearch optionFilterProp='children'
                      value={selectedUserInfo.endUserId}
                      onSelect={(val) => { this.getGuestListBySeller(val) }}
                    >
                      {
                        customerList.map((item, i) => (
                          <Option value={item.endUserId} key={`enOption-${i}`}>{item.showName}</Option>
                        ))
                      }
                    </Select>
                  </FormItem>
                </div>
              </Col>
              <Col span={4}>
                <FormItem>
                  <Button type='primary' onClick={() => this.getAddEnduserDetail({}, 1)}>新增客户</Button>
                </FormItem>
              </Col>
            </Row>

            {
              +selectedUserInfo.enterpriseId > 0
                ? <>
                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='税务登记号' style={{ marginBottom: '0px' }}>
                      { selectedUserInfo.euEnterprise && selectedUserInfo.euEnterprise.taxId ? selectedUserInfo.euEnterprise.taxId : '----' }
                    </FormItem>
                  </div>

                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='公司地址' style={{ marginBottom: '0px' }}>
                      {renderAddressLabel(selectedUserInfo.euEnterprise.province, selectedUserInfo.euEnterprise.city, selectedUserInfo.euEnterprise.county, selectedUserInfo.euEnterprise.address)}
                    </FormItem>
                  </div>

                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='公司电话' style={{ marginBottom: '0px' }}>
                      { selectedUserInfo.euEnterprise && selectedUserInfo.euEnterprise.telephone ? selectedUserInfo.euEnterprise.telephone : '----' }
                    </FormItem>
                  </div>

                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='公司开户行' style={{ marginBottom: '0px' }}>
                      { selectedUserInfo.euEnterprise && selectedUserInfo.euEnterprise.bank ? selectedUserInfo.euEnterprise.bank : '----' }
                    </FormItem>
                  </div>

                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='开户账号' style={{ marginBottom: '0px' }}>
                      { selectedUserInfo.euEnterprise && selectedUserInfo.euEnterprise.bankAccount ? formatBankAccount(selectedUserInfo.euEnterprise.bankAccount) : '----' }
                    </FormItem>
                  </div>
                </>
                : null
            }
          </Form>
        </Card>

      </>
    )
  }
}

export default withRouter(EndLand)
