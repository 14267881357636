import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, InputNumber, Input, message } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import filter from '@/common/utils/filter'

const FormItem = Form.Item
const { paywordReg } = filter

class CashForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    info: {}
  }

  componentDidMount () {

  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.amount === 0) {
          return message.warning('提现金额不可为0元！')
        }

        let { password } = values
        let jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values['password'] = jsencrypt.encrypt(password)

        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { detailInfo } = this.props
    const { getFieldDecorator } = this.props.form

    const formmItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    }

    return (
      <div>
        <Modal title='余额提现'
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <div>
            <Form>
              <FormItem label='银行名称' {...formmItemLayout}>
                {
                  getFieldDecorator('bank', {
                    rules: [
                      { required: true, message: '请填写收款银行名称' }
                    ]
                  })(
                    <Input placeholder='请输入收款银行名称...' autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label='银行卡号' {...formmItemLayout}>
                {
                  getFieldDecorator('account', {
                    rules: [
                      { required: true, message: '请填写收款银行卡号' }
                    ]
                  })(
                    <Input placeholder='请输入收款银行卡号...' autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label='账户名称' {...formmItemLayout}>
                {
                  getFieldDecorator('accountName', {
                    rules: [
                      { required: true, message: '请填写收款银行账户名称' }
                    ]
                  })(
                    <Input placeholder='请输入收款银行账户名称...' autoComplete='off' />
                  )
                }
              </FormItem>

              <FormItem label='提现金额' {...formmItemLayout}
                extra={+detailInfo.totalBalance > 0 ? `可提现最大金额 ${+detailInfo.totalBalance} 元` : ''}
              >
                {
                  getFieldDecorator('amount', {
                    rules: [
                      { required: true, message: '请填写要提现的金额' }
                    ]
                  })(
                    <InputNumber
                      min={0}
                      max={+detailInfo.totalBalance}
                      placeholder='请输入提现金额...'
                      autoComplete='off'
                      disabled={+detailInfo.totalBalance === 0}
                      style={{ width: '100%' }} />
                  )
                }
              </FormItem>
              <FormItem label='支付密码' {...formmItemLayout}>
                {
                  getFieldDecorator('password', {
                    getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                    validate: [
                      {
                        trigger: 'onBlur',
                        rules: [
                          {
                            validator (rule, value, callback) {
                              if (value && !paywordReg.test(value)) {
                                /* eslint-disable */
                                callback('支付密码格式不正确')
                                /* eslint-enable */
                              } else {
                                callback()
                              }
                            }
                          }
                        ]
                      },
                      {
                        trigger: ['onChange', 'onBlur'],
                        rules: [{ required: true, message: '请您填写6位数字支付密码' }]
                      }
                    ]
                  })(
                    <Input.Password placeholder='请输入6位数字支付密码...'
                      autoComplete='off'
                      style={{ width: '100%' }} />
                  )
                }
              </FormItem>
            </Form>
          </div>
        </Modal>
      </div>
    )
  }
}

const CashModal = Form.create()(CashForm)

export default CashModal
