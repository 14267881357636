import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Table, Badge, message, Modal, Button } from 'antd'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import SetcreditModal from '../component/setcredit-modal'
import RechargeModal from '../component/rechargeModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter
const creditMap = [
  { type: 'ENABLE_UNKNOWN', label: '未开通', status: 'warning' },
  { type: 'ENABLE_ON', label: '启用中', status: 'success' },
  { type: 'ENABLE_OFF', label: '禁用中', status: 'error' }
]

const { confirm } = Modal

class Eubanlancelist extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'endUserId',
        formType: 'Select',
        itemParams: {
          label: '客户名称'
        },
        cptParams: {
          showSearch: true,
          placeholder: '请输入...',
          autoComplete: 'off',
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['endUserId', 'showName']
      }
    ],
    setcreditModal: false,
    rechargeModal: false, // 余额充值弹窗
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {},
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'balanceList-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '客户名称',
      key: 'balanceList-1',
      dataIndex: 'customerName'
    },
    {
      title: '真实姓名',
      key: 'balanceList-2',
      dataIndex: 'realName'
    },
    {
      title: '账户余额',
      key: 'balanceList-3',
      dataIndex: 'totalBalance',
      render: (text, record, index) => (
        <>&yen;{(+record.totalBalance * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '信用支付',
      key: 'balanceList-4',
      render: (text, record, index) => (
        <div>
          <Badge status={this.renderCreditBadge(record.enable)} />
          <span>
            {this.renderCreditStatusLabel(record.enable)}
          </span>
        </div>
      )
    },
    {
      title: '信用额度',
      key: 'balanceList-5',
      render: (text, record, index) => (
        <div>{record.usedAmount}/{record.maxAmount}</div>
      )
    },
    {
      title: '账期时长(天)',
      key: 'balanceList-6',
      dataIndex: 'period'
    },
    {
      title: '最近还款日',
      key: 'balanceList-7',
      render: (text, record, index) => (
        <div>
          {
            +record.lastPeriodTime > 0
              ? <span>{moment(record.lastPeriodTime).format('YYYY-MM-DD')}</span>
              : ''
          }

        </div>
      )
    },
    {
      title: '操作',
      key: 'balanceList-8',
      width: 350,
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small'
            style={{ marginRight: '8px' }}
            onClick={() => this.showSetCreditModal(record)}>设置信用</Button>

          <Button type='primary' size='small'
            style={{ marginRight: '8px' }}
            onClick={() => this.showRechargeModal(record)}>余额充值</Button>

          <Button type='primary' size='small'
            style={{ marginRight: '8px' }}
            onClick={() => this.showSysBalanceModal(record)}>余额更新</Button>

          <Button type='primary' size='small'
            onClick={() => this.handleJumpToDetail(record)}
          >查看详情</Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getGuesteuUserLike()
    this.getEuBalanceList()
  }

  // 查询客户信息
  getGuesteuUserLike = (val = '') => {
    let params = {
      pageNo: 1,
      pageSize: 999,
      searchName: val
    }

    Api.getGuesteuUserLike(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const { searchData } = this.state
        searchData[0].options = data.list || []

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取客户列表
  getEuBalanceList = () => {
    const { pages, query } = this.state
    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize,
      ...query
    }

    Api.getEuBalanceList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 保存客户余额充值
  saveBalanceRecharge = (params) => {
    Api.saveBalanceRecharge(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getEuBalanceList()
      }
    })
  }

  // 保存客户信用设置
  saveSetCredit = (params) => {
    Api.saveEuCreditSet(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getEuBalanceList()
      }
    })
  }

  // 更新客户余额
  syncEndUserBalance = (params) => {
    Api.syncEndUserBalance(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getEuBalanceList()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getEuBalanceList()
    })
  }

  // 点击查看详情
  handleJumpToDetail = (record) => {
    this.props.history.push({ pathname: '/admin/creditDetail', state: { detailInfo: record } })
  }

  // 点击设置信用支付
  showSetCreditModal = (record) => {
    if (record.enterpriseName && record.enterpriseName !== '') {
      this.setState({
        setcreditModal: true,
        rowDetail: record || {}
      })
    } else {
      Modal.warning({
        title: '友情提示',
        content: (<div>
          <div>抱歉，您目前没有开通个人信用的权限，</div>
          <div>如需开通请咨询 IIASAAS 客服人员</div>
        </div>)
      })
    }
  }

  // 点击余额充值
  showRechargeModal = (record) => {
    this.setState({
      rechargeModal: true,
      rowDetail: record
    })
  }

  // 点击余额更新
  showSysBalanceModal = (record) => {
    confirm({
      title: '温馨提示',
      content: '到账后预计5到10分钟余额才能同步成功',
      onOk: () => {
        this.syncEndUserBalance({ endUserId: record.endUserId })
      }
    })
  }

  // 渲染信用支付状态
  renderCreditStatusLabel = (type = '') => {
    const obj = creditMap.find(item => { return item.type === type })
    const label = obj !== undefined ? obj.label : ''

    return label
  }

  // 选用Badge 状态
  renderCreditBadge = (type = '') => {
    const obj = creditMap.find(item => { return item.type === type })
    const label = obj !== undefined ? obj.status : ''

    return label
  }

  closeModal = () => {
    this.setState({
      setcreditModal: false,
      rechargeModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEuBalanceList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEuBalanceList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], setcreditModal = false, rechargeModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>
        <Row>
          <Card bodyStyle={{ padding: '15px' }}>
            <Table rowKey='id'
              dataSource={tableData}
              columns={this.columns}
              pagination={pagination}
            />
          </Card>
        </Row>

        {
          setcreditModal
            ? <SetcreditModal
              detailInfo={rowDetail}
              onConfirm={(params) => this.saveSetCredit(params)}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          rechargeModal
            ? <RechargeModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.saveBalanceRecharge(params)}
            />
            : null
        }
      </div>
    )
  }
}

export default withRouter(Eubanlancelist)
