import React, { Component } from 'react'
import { Row, Table, Button, Modal, message, Pagination, Card } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import Descriptions from './descriptions'
import DetailModal from '../component/detail-modal'
import ConfirmModal from './confirm-modal'
import Updowm from '../component/down'
import OrderEnclosureDrawer from '../component/orderEnclosureDrawer' // 订单附件弹窗

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { renderOrderStatusLabel, renderOrderPaymentStatusLabel, renderOrderDeliveryStatusLabel } from '@/common/utils/mUtils'

const { downOrderUrl } = DownloadApi
const { pageSizeOptions, orderStatusMaps, orderDeliveryStatusMaps, orderPaymentStatusMaps } = filter
const orderFromMaps = [
  { id: 'CREATED_ON_LINE', name: '客户下单' },
  { id: 'CREATED_BY_HAND', name: '手工创建' }
]

class Euorder extends Component {
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {}, // 检索参数
    detailModal: false, // 查看详情弹窗
    confirmModal: false, // 确认收款弹窗
    type: 1, // 订单类型 1-云订单 2-期货订单 3-现货订单
    rowDetail: {},
    expandedRowKeys: [],
    summary: {},
    searchData: [
      {
        name: 'endUserId',
        formType: 'Select',
        itemParams: {
          label: '客户名称'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['endUserId', 'showName']
      },
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'orderFrom',
        formType: 'Select',
        itemParams: {
          label: '订单来源'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderFromMaps
        ]
      },
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {

        }
      },
      {
        name: 'orderStatusList',
        formType: 'Select',
        itemParams: {
          label: '订单状态'
        },
        cptParams: {
          placeholder: '请选择...',
          mode: 'multiple',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderStatusMaps
        ]
      },
      {
        name: 'paymentStatus',
        formType: 'Select',
        itemParams: {
          label: '付款状态'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderPaymentStatusMaps
        ]
      },
      {
        name: 'deliveryStatus',
        formType: 'Select',
        itemParams: {
          label: '发货状态'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderDeliveryStatusMaps
        ]
      }
    ],
    enclosureModal: false // 订单附件弹窗
  }
  columns = [
    {
      title: '序号',
      key: 'euorder-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '订单日期',
      key: 'euorder-1',
      width: 120,
      render: (text, record, index) => (
        <>{ record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '订单编号',
      key: 'euorder-2',
      dataIndex: 'orderId'
    },
    {
      title: '订单来源',
      key: 'euorder-3',
      dataIndex: 'orderFrom',
      render: (text, record, index) => (
        <>{ this.renderOrderFromLabel(record.orderFrom) }</>
      )
    },
    {
      title: '客户名称',
      key: 'euorder-4',
      width: 150,
      dataIndex: 'customerName'
    },
    {
      title: '订单金额',
      key: 'euorder-5',
      dataIndex: 'payableAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.payableAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '已付金额',
      key: 'euorder-6',
      dataIndex: 'paidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.paidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '未付金额',
      key: 'euorder-7',
      dataIndex: 'unpaidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.unpaidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '付款状态',
      key: 'euorder-8',
      dataIndex: 'paymentStatus',
      render: (text, record, index) => (
        <>{renderOrderPaymentStatusLabel(record.paymentStatus)}</>
      )
    },
    {
      title: '发货状态',
      key: 'euorder-9',
      dataIndex: 'deliveryStatus',
      render: (text, record, index) => (
        <>{ renderOrderDeliveryStatusLabel(record.deliveryStatus) }</>
      )
    },
    {
      title: '订单状态',
      key: 'euorder-10',
      dataIndex: 'orderStatus',
      render: (text, record, index) => (
        <>{ renderOrderStatusLabel(record.orderStatus) }</>
      )
    },
    {
      title: '订单附件',
      key: 'euorder-11',
      render: (text, record, index) => (
        <>
          <Button type='primary' size='small' onClick={() => this.handleShowEnclosureModal(record)}>点击查看</Button>
        </>
      )
    },
    {
      title: '操作',
      key: 'euorder-12',
      width: 220,
      render: (text, record, index) => (
        <>
          {
            ['PAYING_FRONT', 'PAYING', 'TAIL_PAYING'].includes(record.orderStatus)
              ? <Button type='primary' size='small' style={{ marginRight: '6px', marginBottom: '6px' }} onClick={() => this.handleShowConfirmModal(record)}>确认收款</Button>
              : null
          }

          {
            ['PAYING', 'ORDER_COMPLETE', 'WAIT_TO_RECEIVE', 'CANCEL_AFTER_PAYING', 'TAIL_PAYING', 'WAIT_TO_SHIP', 'WAIT_ORDER_GOODS'].includes(record.orderStatus)
              ? <>
                <Button type='primary' size='small' style={{ marginRight: '6px', marginBottom: '6px' }} onClick={() => this.handleDownloadContract(record)}>下载合同</Button>
                <Button type='primary' size='small' style={{ marginBottom: '6px' }} onClick={() => this.handleCreateContractAgain(record)}>重新生成合同</Button>
              </>
              : null
          }

        </>
      )
    }
  ]

  componentDidMount () {
    this.getGuesteuUserLike() // 客户联动查询
    this.getListData() // 列表初始化
    this.getTradeOrderAmountSummary() // 价格显示初始化
  }

  // 获取EU订单列表
  getEuOrderList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getEuOrderList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          expandedRowKeys: [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取订单金额汇总
  getTradeOrderAmountSummary = () => {
    const { pages } = this.state
    let params = {
      orderType: 'ALL',
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getTradeOrderAmountSummary(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          summary: data || {}
        })
      }
    })
  }

  // 搜索客户信息
  getGuesteuUserLike = (val = '') => {
    Api.getGuesteuUserLike({ searchName: val, pageNo: 1, pageSize: 999 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state
        searchData[0].options = data.list || []

        this.setState({
          searchData
        })
      }
    })
  }

  // 确认收款
  saveConfirmEuPayment = (params) => {
    Api.saveConfirmEuPayment(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.closeModal()

        Modal.success({
          title: '操作成功',
          content: '确认收款成功',
          onOk: () => {
            this.getListData()
          }
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 获取合同下载链接
  getEUOrderContractDownloadLink = async (params) => {
    const res = await Api.getEUOrderContractDownloadLink(params)
    const { code, data } = res
    if (+code === 10000) {
      if (!data || data === '') {
        return message.warning('暂无可下在的合同链接')
      }

      window.open(data)
    }
  }

  // 重新生成合同
  createEUOrderContractAgain = async (params) => {
    const res = await Api.createEUOrderContractAgain(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
    }
  }

  // 点击搜索
  handleSearch = (search) => {
    const { searchData = [], pages } = this.state
    let _searchData = [...searchData]
    if (search.orderTime) {
      search.beginDate = search.orderTime.length > 0 ? search.orderTime[0].format('YYYY-MM-DD') : ''
      search.endDate = search.orderTime.length > 0 ? search.orderTime[1].format('YYYY-MM-DD') : ''
    }

    delete search.orderTime

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {},

      searchData: _searchData
    }, () => {
      this.getTradeOrderAmountSummary()
      this.getListData()
    })
  }

  // 点击查看订单明细
  handleShowDetail = (record) => {
    this.setState({
      detailModal: true,
      type: record.type,
      rowDetail: record || {}
    })
  }

  // 点击确认收款
  handleShowConfirmModal = (record) => {
    this.setState({
      confirmModal: true,
      rowDetail: record
    })
  }

  // 点击查看订单附件
  handleShowEnclosureModal = (record) => {
    this.setState({
      rowDetail: record,
      enclosureModal: true
    })
  }

  // 点击下载合同
  handleDownloadContract = (record) => {
    this.getEUOrderContractDownloadLink({ orderId: record.orderId })
  }

  // 点击重新生成合同
  handleCreateContractAgain = (record) => {
    Modal.confirm({
      title: '确定重新生成合同？',
      content: '此操作会根据订单数据和公司基础数据重新生成合同',
      onOk: () => {
        this.createEUOrderContractAgain({ orderId: record.orderId })
      }
    })
  }

  // 点击展开、收起子列表
  handleChangeExpand = (expanded, record) => {
    let { expandedRowKeys = [] } = this.state

    this.setState({
      expandedRowKeys: expanded ? [...expandedRowKeys, record.orderId] : $lodash.pull(expandedRowKeys, record.orderId)
    }, () => {
      this.getEuOrderDetail(expanded, record)
    })
  }

  // 渲染订单来源名称
  renderOrderFromLabel = (status) => {
    const obj = orderFromMaps.find((item) => { return item.id === status })
    const label = obj !== undefined ? obj.name : null

    return label
  }

  // 渲染订单详情
  renderExpandRow = (record) => {
    const rowColumns = [
      {
        title: '子订单',
        key: `expandRow-${record.orderId}-0`,
        dataIndex: 'name'
      },
      {
        title: '订单金额',
        key: `expandRow-${record.orderId}-1`,
        render: (text, record, index) => (
          <div>{(+record.payableAmount * 100 / 100).toFixed(2)}</div>
        )
      },
      {
        title: '操作',
        key: `expandRow-${record.orderId}-5`,
        render: (text, record, i) => (
          <Button type='primary' size='small' onClick={() => this.handleShowDetail(record)}>
            查看明细
          </Button>
        )
      }
    ]

    return (
      <Row style={{ padding: '2px 5px' }}>
        <Table rowKey='id'
          columns={rowColumns}
          dataSource={record.orderList}
          pagination={false}
        />
      </Row>
    )
  }

  // 渲染子订单支付状态
  renderChildOrderEuPayStatusLabel = (status, type, rowType) => {
    let str = ''

    if (rowType === 1) {
      str = type > 1 ? renderOrderStatusLabel(status) : '----'
    } else { // 供应付款状态
      str = type === 1 ? renderOrderStatusLabel(status) : '----'
    }

    return str
  }

  // 获取列表数据
  getListData = () => {
    this.getEuOrderList()
  }

  // 获取订单详细
  getEuOrderDetail = (expanded, record) => {
    let { tableData = [] } = this.state

    let i = tableData.findIndex(item => { return +item.orderId === +record.orderId })

    if (expanded && !tableData[i].spotOrderInfo) {
      Api.getEuOrderDetail({ orderId: record.orderId, pageNo: 1, pageSize: 20 }).then(res => {
        const { code, data } = res
        if (+code === 10000) {
          let arr = []
          if (data.cloudOrderInfo && +data.cloudOrderInfo.id > 0) {
            data.cloudOrderInfo.name = `${data.cloudOrderInfo.orderId}-(云采购)`
            data.cloudOrderInfo.type = 1
            arr.push(data.cloudOrderInfo)
          }

          if (data.futuresOrderInfo && +data.futuresOrderInfo.id > 0) {
            data.futuresOrderInfo.name = `${data.futuresOrderInfo.orderId}-(自营期货)`
            data.futuresOrderInfo.type = 2
            arr.push(data.futuresOrderInfo)
          }

          if (data.spotOrderInfo && +data.spotOrderInfo.id > 0) {
            data.spotOrderInfo.name = `${data.spotOrderInfo.orderId}-(自营现货)`
            data.spotOrderInfo.type = 3
            arr.push(data.spotOrderInfo)
          }

          tableData[i].orderList = arr

          this.setState({
            tableData
          })
        }
      })
    }
  }

  closeModal = () => {
    this.setState({
      type: 1,
      detailModal: false,
      confirmModal: false,
      enclosureModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getListData()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getListData()
    })
  }

  render () {
    const { tableData = [], summary = {}, query = {}, pages, detailModal = false, rowDetail = {}, type = 1, confirmModal = false, searchData = [], expandedRowKeys = [],
      enclosureModal = false } = this.state

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '15px' }}>
          <Updowm query={query} api={downOrderUrl} />
          <Descriptions summary={summary} />

          <Table rowKey='orderId'
            pagination={false}
            columns={this.columns}
            dataSource={tableData}
            expandedRowKeys={expandedRowKeys}
            onExpand={(expanded, record) => this.handleChangeExpand(expanded, record)}
            expandedRowRender={(record) => this.renderExpandRow(record)}
          />
          {tableData.length > 0 ? <Row style={{ marginTop: '16px', textAlign: 'right' }}>
            <Pagination
              showSizeChanger
              showQuickJumper
              total={pages.total}
              showTotal={(total) => { return `共 ${total} 条` }}
              current={pages.pageNo}
              pageSize={pages.pageSize}
              pageSizeOptions={pageSizeOptions}
              onChange={this.pageChange}
              onShowSizeChange={this.pageSizeChange}
            />
          </Row> : null

          }

          {
            detailModal
              ? <DetailModal
                type={type}
                detailInfo={rowDetail}
                onCancel={() => this.closeModal()}
              />
              : null
          }

          {
            confirmModal
              ? <ConfirmModal
                detailInfo={rowDetail}
                onConfirm={(params) => this.saveConfirmEuPayment(params)}
                onCancel={() => this.closeModal()}
              />
              : null
          }
        </Card>

        {/* 订单附件弹窗 */}
        <OrderEnclosureDrawer
          visible={enclosureModal}
          name='EU_ENCLOSURE'
          detailInfo={rowDetail}
          onCancel={() => this.closeModal()}
        />
      </>
    )
  }
}

export default Euorder
