import React, { Component } from 'react'
import { Row, Col, Card, Button, Table, message, Tabs, Icon } from 'antd'
import $lodash from 'lodash'
import PropTypes from 'prop-types'
import styles from './beforeload.module.scss'
import DownloadApi from '@/common/api/downloadApi'
import Api from '@/common/api/index'
import HandleModal from '@/pages/price/price-setting/handle-modal'
const { TabPane } = Tabs
const { downloadfailList } = DownloadApi
const tabMaps = [
  { type: 'ALL', label: '全部数据', icon: '', color: '' },
  { type: 'SUCCESS', label: '匹配成功', icon: 'check-circle', color: '#52c41a' },
  { type: 'FAIL', label: '需要修正', icon: 'exclamation-circle', color: '#f5222d' }
]
const _letterArrMaps = ['客', '户', '导', '入', '中']
class beforeuoload extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    tableData: this.props.history.location.state,
    total: this.props.history.location.state,
    isSuccess: $lodash.filter(this.props.history.location.state, (item) => { return item.isSuccess === true }),
    noSuccess: $lodash.filter(this.props.history.location.state, (item) => { return item.isSuccess === false }),
    type: 0,
    handleModal: false
  }
  columns = [
    {
      title: '序号',
      key: 'customer-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '登录名',
      width: 240,
      key: 'customer-1',
      dataIndex: 'adminLoginName'
    },
    {
      title: '登录密码',
      width: 240,
      key: 'customer-2',
      dataIndex: 'password'

    },
    {
      title: '真实姓名',
      width: 240,
      key: 'customer-3',
      dataIndex: 'adminName'
    },
    {
      title: '公司名称',
      width: 240,
      key: 'customer-4',
      dataIndex: 'name'
    },
    {
      title: '法人姓名',
      width: 240,
      key: 'customer-5',
      dataIndex: 'accountName'
    },
    {
      title: '注册地址',
      width: 240,
      key: 'customer-6',
      dataIndex: 'address'
    },
    {
      title: '统一社会信用代码',
      width: 240,
      key: 'customer-7',
      dataIndex: 'taxId'
    },
    {
      title: '开户银行',
      width: 240,
      key: 'customer-8',
      dataIndex: 'bank'
    },
    {
      title: '开票电话',
      width: 240,
      key: 'customer-9',
      dataIndex: 'telephone'
    },
    {
      title: '银行账号',
      width: 240,
      key: 'customer-10',
      dataIndex: 'bankAccount'
    },
    {
      title: '是否成功',
      width: 240,
      key: 'customer-11',
      render: (text, record, index) => {
        return (
          <span className={record.isSuccess === true ? styles.divgreen : styles.divred}>{ record.isSuccess === true ? '是' : '否'}</span>
        )
      }
    },
    {
      title: '原因',
      width: 300,
      key: 'customer-12',
      dataIndex: 'failReason',
      render: (text, record, index) => {
        return (
          <span className={styles.divred}>{ record.failReason}</span>
        )
      }
    }
  ]
  componentDidMount () {
  }

  // 返回上一级
  goback = () => {
    this.props.history.goBack()
  }

  // 导出失败详情
  uploadfile = () => {
    const { noSuccess } = this.state
    downloadfailList({ templates: noSuccess })
  }
  // 确认导入
  getTonext = () => {
    this.setState({
      handleModal: true
    }, () => {
      this.uploadinfoOk()
    })
  }

  // 导入
  uploadinfoOk = () => {
    const { total } = this.state
    Api.downloadfailList({ euEnterprises: total }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        setTimeout(() => {
          this.props.history.push({ pathname: '/admin/uploadinfo', state: { total, data: data } })
        }, 3000)
      } else {
        message.error(res.data.message)
      }
    })
  }

  // 点击切换tab
  handleChangeTabPane = (key) => {
    const { tableData, isSuccess, noSuccess } = this.state
    let data = []
    if (key === 'ALL') { data = tableData }
    if (key === 'SUCCESS') { data = isSuccess }
    if (key === 'FAIL') { data = noSuccess }
    this.setState({
      tableData: data
    })
  }
  render () {
    const { tableData, handleModal = false, isSuccess, noSuccess, total } = this.state
    return (
      <>
        <Card bodyStyle={{ padding: '16px' }}>
          <Row>
            <Col span={12}>
              <Row gutter={12}>
                <Col span={8} style={{ color: 'rgba(0, 0, 0, 0.8)' }}>
                  <div style={{ fontWeight: '600', marginBottom: '4px' }}>总共</div>
                  <div style={{ fontSize: '18px' }}>{total.length}</div>
                </Col>
                <Col span={8} style={{ color: '#52c41a' }}>
                  <div style={{ fontWeight: '600', marginBottom: '4px' }}>匹配成功</div>
                  <div style={{ fontSize: '18px' }}>{isSuccess.length}</div>
                </Col>
                <Col span={8} style={{ color: '#f5222d' }}>
                  <div style={{ fontWeight: '600', marginBottom: '4px' }}>需要修正</div>
                  <div style={{ fontSize: '18px' }}>{noSuccess.length}</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>

        <Card style={{ marginTop: '20px' }}>
          <Row>
            <Tabs onChange={this.handleChangeTabPane} tabBarExtraContent={<div>
              <Button style={{ margin: '0 10px' }} onClick={() => { this.goback() }}>取消匹配</Button>
              <Button onClick={() => { this.uploadfile() }} type='primary'>导出失败详情</Button>
              <Button style={{ marginLeft: '10px' }} type='primary' onClick={() => { this.getTonext() }}>确认导入</Button>
            </div>}>
              {
                tabMaps.map((titem, n) => (
                  <TabPane tab={<span>{titem.icon !== '' ? <Icon type={titem.icon} style={{ color: titem.color !== '' ? titem.color : '' }} /> : null}{titem.label}</span>}
                    key={titem.type}
                  />
                ))
              }
            </Tabs>

          </Row>

          <Row style={{ marginTop: '20px' }}>
            <Table rowKey={(index, ket) => { return `${index} ${ket}-1` }}
              columns={this.columns}
              dataSource={tableData}
              scroll={{ x: 1500 }}
            />
          </Row>
        </Card>

        {

          handleModal
            ? <HandleModal
              letterArr={_letterArrMaps}
            />
            : null
        }
      </>
    )
  }
}

export default beforeuoload
