import React, { Component } from 'react'
import { Row, message, Button, Icon } from 'antd'
import BraftEditor from 'braft-editor' // 文档 https://www.yuque.com/braft-editor/be/qg394y 或 https://braft.margox.cn/
import { ContentUtils } from 'braft-utils'
import MediaModal from '@/components/media-modal/index'

import Api from '@/common/api/index'

import 'braft-editor/dist/index.css'
import styles from './componyintro.module.scss'

class CompanyIntro extends Component {
  static propTypes = {

  }
  state = {
    editorState: BraftEditor.createEditorState(null),
    mediaModal: false,
    mediaType: null
  }

  componentDidMount () {
    this.getComponyIntro()
  }

  // 获取公司介绍详情
  getComponyIntro = () => {
    let params = {
      pageNo: 1,
      pageSize: 99999
    }

    Api.getComponyIntro(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _list = data.pageList.list || []
        let { editorState } = this.state

        if (_list.length > 0) {
          editorState = BraftEditor.createEditorState(_list[0].content)
        }

        this.setState({
          editorState
        })
      }
    })
  }

  // 保存公司介绍
  saveComponyIntro = (params) => {
    Api.saveComponyIntro(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
      }
    })
  }

  // 点击保存
  handleSubmit = () => {
    const { editorState = '' } = this.state

    if (editorState === '') {
      return message.warning('请编辑内容')
    }
    let mediaList = []

    mediaList.push({
      content: editorState.toHTML()
    })

    this.saveComponyIntro({ mediaList })
  }

  // 监听富文本变化
  handleChangeEditorState = (editorState) => {
    this.setState({
      editorState
    })
  }

  // 点击确定上传图片
  handleSetMedia = (params) => {
    let _editorState = ContentUtils.insertMedias(this.state.editorState, [
      { type: params.type, url: params.url }
    ])

    this.setState({
      editorState: _editorState,
      mediaModal: false,
      mediaType: null
    })
  }

  showMediaModal = (type) => {
    this.setState({
      mediaModal: true,
      mediaType: type
    })
  }

  closeModal = () => {
    this.setState({
      mediaModal: false,
      mediaType: ''
    })
  }

  render () {
    const { editorState, mediaModal = false, mediaType } = this.state
    const controls = [
      'undo', 'redo', 'separator',
      'font-size', 'line-height', 'letter-spacing', 'separator',
      'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
      'superscript', 'subscript', 'remove-styles', 'emoji', 'separator', 'text-indent', 'text-align', 'separator',
      'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
      'link', 'separator', 'hr', 'separator',
      // 'media',
      'separator',
      'clear'
    ]

    const extendControls = [
      {
        key: 'uploadpic-btn',
        type: 'component',
        component: (
          <Button type='link' className='control-item button upload-button' data-title='插入图片'
            onClick={() => this.showMediaModal('IMAGE')}
          >
            <Icon type='picture' />
          </Button>
        )
      }
    ]

    return (
      <>
        <Row>
          <BraftEditor value={editorState}
            controls={controls}
            extendControls={extendControls}
            onChange={this.handleChangeEditorState}
            forceNewLine
          />
        </Row>
        <Row className={`${styles.footerBar}`}>
          <Button type='primary' onClick={() => this.handleSubmit()}>保存</Button>
        </Row>

        {
          mediaModal
            ? <MediaModal
              mediaType={mediaType}
              onConfirm={(params) => this.handleSetMedia(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}

export default CompanyIntro
