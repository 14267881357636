import Ajax from '../utils/ajax'

export default {
  // 获取当前IP地址
  getCurrentNetworkAddress (params) {
    return Ajax.get('/api/user/getIp', { params })
  },

  // 获取简报统计
  getAnalysis (params) {
    return Ajax.get('/api/biData/briefing/biData', { params })
  },

  // 发送短信验证码(新)
  sendSmsCaptchaAndValidate (params) {
    return Ajax.post('/api/sms/send', params)
  },

  // 查询收取验证码的手机号
  getAdminPhoneToDesensitization (params) {
    return Ajax.get('/api/user/getAdminPhoneToDesensitization', { params })
  },

  // 发送合同链接短信
  sendContractNoticeSms (params) {
    return Ajax.get('/api/contract/manager/contractSms', { params })
  },

  /* 滑动验证码START */
  // 获取验证图片信息
  getCaptchaVerifyInfo (params) {
    return Ajax.post('/api/captcha/get', params)
  },

  // 校验滑块验证码
  checkCaptchaVerify (params) {
    return Ajax.post('/api/captcha/check', params)
  }
  /* 滑动验证码END */
}
