import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, Select, InputNumber, Radio } from 'antd'
import $lodash from 'lodash'

import AREA from '@/assets/json/area'

const FormItem = Form.Item
const Option = Select.Option
const provinceOptions = $lodash.map(AREA.provinceList, (value, key) => { return { code: key, name: value } })

class FreightForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number,
    detailInfo: PropTypes.object,
    getDetail: PropTypes.func,
    getGuesteuUserLike: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    data: {},
    templateType: 'TEMPLATE_COMMON', // TEMPLATE_COMMON: 通用, TEMPLATE_GUEST: 客户
    searchOptions: [],
    destination: [] // 选择的目的地集合
  }

  componentDidMount () {
    const { type = 1 } = this.props

    if (type === 2) {
      this.getFreightDetail()
    } else {
      this.getGuesteuUserLike()
    }
  }

  // 获取模板详情
  getFreightDetail = () => {
    const { getDetail, detailInfo } = this.props

    getDetail({ templateId: detailInfo.templateId }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _arr = []
        if (data && data.addressList && data.addressList.length > 0) {
          data.addressList.map(row => {
            _arr.push(`${row.province}`)
          })
        }

        this.setState({
          data,
          templateType: data.templateType || 'TEMPLATE_COMMON',
          destination: _arr
        }, () => {
          this.getGuesteuUserLike(data.realName)
        })
      }
    })
  }

  // 获取客户信息
  getGuesteuUserLike = (val = '') => {
    let params = {
      pageNo: 1,
      pageSize: 999,
      searchName: val
    }
    this.props.getGuesteuUserLike(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          searchOptions: data.list || []
        })
      }
    })
  }

  // 点击确认
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type = 1, detailInfo } = this.props

        if (type === 2) {
          values.templateId = detailInfo.templateId
        }

        let arr = []
        values.destination.map(item => {
          arr.push({ province: item })
        })

        values.addressList = arr

        delete values.destination

        this.props.onConfirm(values)
      }
    })
  }

  // 选择目的地
  handleChangeDestination = (keys) => {
    const n = keys.findIndex(code => { return code === 'ALL' })
    const newArr = n > -1 ? $lodash.map(provinceOptions, 'code') : keys

    setTimeout(() => {
      this.props.form.setFieldsValue({
        destination: newArr
      })
    }, 20)
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 }
    }

    const { searchOptions = [], data = {}, destination = [] } = this.state

    return (
      <div>
        <Modal title='运费模板'
          width={800}
          visible
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Form {...formItemLayout}>
            <FormItem label='模板名称'>
              {
                getFieldDecorator('templateName', {
                  initialValue: data && data.templateName ? data.templateName : '',
                  rules: [
                    { required: true, message: '该项必填' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='模板类型'>
              {
                getFieldDecorator('templateType', {
                  initialValue: data.templateType || 'TEMPLATE_COMMON'
                })(
                  <Radio.Group>
                    <Radio value={'TEMPLATE_COMMON'}>通用</Radio>
                    <Radio value={'TEMPLATE_GUEST'}>客户</Radio>
                  </Radio.Group>
                )
              }
            </FormItem>

            {
              this.props.form.getFieldValue('templateType') === 'TEMPLATE_GUEST'
                ? <FormItem label='客户名称' {...formItemLayout}>
                  {
                    getFieldDecorator('endUserId', {
                      initialValue: data.endUserId || '',
                      rules: [
                        { required: true, message: '该项必填' }
                      ]
                    })(
                      <Select placeholder='请选择客户' showSearch
                        optionFilterProp='children'
                      >
                        {
                          searchOptions.map((opt) => (
                            <Option value={opt.endUserId} key={opt.endUserId}>{opt.showName}</Option>
                          ))
                        }
                      </Select>
                    )
                  }
                </FormItem>
                : null
            }

            <FormItem label='目的地'>
              {
                getFieldDecorator('destination', {
                  initialValue: destination,
                  rules: [
                    { required: true, message: '请选择目的地' }
                  ]
                })(
                  <Select mode='multiple'
                    getPopupContainer={triggerNode => triggerNode.parentElement}
                    onChange={this.handleChangeDestination}
                  >
                    <Option value='ALL' key='ALL'>全部</Option>
                    {
                      provinceOptions.map((item, index) => (
                        <Option value={item.code} key={item.code}>{item.name}</Option>
                      ))
                    }
                  </Select>
                )
              }
            </FormItem>

            <FormItem label='满额免邮费'>
              {
                getFieldDecorator('satisfyPrice', {
                  initialValue: data && data.satisfyPrice ? data.satisfyPrice : '',
                  rules: [
                    { required: true, message: '该项必填' }
                  ]
                })(
                  <InputNumber placeholder='请输入...' autoComplete='off'
                    style={{ width: '100%' }}
                  />
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </div>
    )
  }
}
const FreightModal = Form.create()(FreightForm)

export default FreightModal
