import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table, Button } from 'antd'

import DownloadApi from '@/common/api/downloadApi'

const { downQuote } = DownloadApi

export default class QuoteModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    getDetail: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20, options: ['10', '20', '50', '100', '200'] },
    tableData: []
  }
  columns = [
    {
      title: '型号',
      key: 'quoteDetail-1',
      width: 160,
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'quoteDetail-2',
      width: 130,
      dataIndex: 'itemNumber',
      ellipsis: true
    },
    {
      title: '产品',
      key: 'quoteDetail-3',
      width: 180,
      ellipsis: true,
      render: (text, record, index) => (
        <div className={`coloneLine`}>
          <span className={`rowBrandName`}>{record.brandName}</span>
          <span>{record.name}</span>
        </div>
      )
    },
    {
      title: '价格',
      key: 'quoteDetail-5',
      width: 200,
      render: (text, record, index) => (
        <>&yen;{(record.discountPrice * 100 / 100).toFixed(2)}</>
      )
    }
  ]

  componentDidMount () {
    this.getQuoteDetailById()
  }

  // 获取详情
  getQuoteDetailById = () => {
    const { detailInfo } = this.props

    let params = {
      endUserId: detailInfo.endUserId,
      euQuotationId: detailInfo.euQuotationId
    }

    this.props.getDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data || []
        })
      }
    })
  }

  // 点击导出
  downloadQuote = () => {
    const { detailInfo } = this.props
    downQuote({ euQuotationId: detailInfo.euQuotationId, endUserId: detailInfo.endUserId })
  }

  render () {
    const { tableData = [] } = this.state

    return (
      <div>
        <Modal title='报价单详情'
          width={1000}
          maskClosable={false}
          visible
          onCancel={this.props.onCancel}
          footer={[
            <Button type='primary'
              style={{ marginRight: '16px' }}
              icon='download'
              onClick={this.downloadQuote}
            >
              导出报价单
            </Button>,
            <Button
              onClick={this.props.onCancel}
            >关闭</Button>
          ]}
        >
          <Table rowKey='id'
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
            scroll={{ y: 380 }}
          />
        </Modal>
      </div>
    )
  }
}
