import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Table, message } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import BatchSearchMenu from '@/components/batchSearchMenu/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class ProductModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    type: PropTypes.string,
    getProductList: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {},
    selectedRowKeys: [] // 选中的SKU集合
  }

  columns = [
    {
      title: '序号',
      key: 'product-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'product-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'product-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'product-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'product-5',
      dataIndex: 'name'
    },
    {
      title: '通用价格',
      key: 'product-6',
      dataIndex: 'defaultPrice',
      render: (text, record, index) => (
        <div>
          {
            +record.defaultPrice > 0
              ? <span>&yen;{+record.defaultPrice}</span>
              : '面议'
          }
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getBrandList()
    this.getCanAddSkuList()
  }

  // 获取所有的品牌
  getBrandList = () => {
    Api.getBrandList({ brandListType: 'ALL_BRAND' }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        data.map(item => {
          item['showLabel'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[0].options = $lodash.concat([{ brandId: 0, showLabel: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取可添加的SKU列表
  getCanAddSkuList = () => {
    const { detailInfo = {}, type } = this.props
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    switch (type) {
      case 'GUESTPRICE':
        params['endUserId'] = detailInfo.endUserId
        break
      case 'COMMON':
        params['priceGroupId'] = detailInfo.priceGroupId
        break
      case 'GUEST':
        params['priceGroupId'] = detailInfo.priceGroupId
        break
    }

    this.props.getProductList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.pageBean.list || [],
          pages: {
            ...pages,
            total: data.pageBean.total || 0
          },

          searchfailModal: +data.failNumber > 0,
          searchFailNumber: +data.failNumber,
          searchTotalNumber: +data.totalNumber,
          searchSuccessNumber: +data.successNumber,
          searchfailList: data.failMessageList || []
        })
      } else {
        message.error(res.message)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (+search.brandId > 0) {
      search['brandId'] = [search.brandId]
    } else {
      delete search.barndId
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getCanAddSkuList()
    })
  }

  // 点击确定
  handleConfirm = () => {
    const { selectedRowKeys = [] } = this.state
    if (selectedRowKeys.length === 0) {
      return message.warning('请选择要添加的产品！')
    }

    let params = {
      commoditySkuIdList: selectedRowKeys
    }
    this.props.onConfirm(params)
  }

  // 点击选择产品
  onSelectChange = (keys) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCanAddSkuList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCanAddSkuList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], selectedRowKeys = [] } = this.state

    // 分页配置
    const pagination = {
      size: 'small',
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 40,
      selectedRowKeys: selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <div>
        <Modal title='可添加产品' width={1200} bodyStyle={{ padding: '16px', height: '500px', overflowY: 'auto' }}
          visible
          maskClosable={false}
          onOk={() => this.handleConfirm()}
          onCancel={() => this.props.onCancel()}
        >
          <Row style={{ marginBottom: '16px' }}>
            <Row style={{ display: 'inline-block', width: '120px', lineHeight: '40px', verticalAlign: 'top' }}>
              <BatchSearchMenu
                onConfirm={(values) => this.handleSearch(values)}
              />
            </Row>
            <Row style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
              <SearchForm
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Row>
          <Row>
            <Table rowKey='commoditySkuId'
              pagination={pagination}
              columns={this.columns}
              dataSource={tableData}
              rowSelection={rowSelection}
            />
          </Row>
        </Modal>
      </div>
    )
  }
}
