import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Tabs } from 'antd'

import HomeDecorate from './home-decorate'
import NavDecorate from './navitem-decorate'

const { TabPane } = Tabs
const tabsMaps = [
  { label: '首页装修', type: 'HOME' },
  { label: '导航装修', type: 'NAV' }
]

export default class HomePoster extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    curtab: 'HOME'
  }

  componentDidMount () { }

  handleChangeTabPane = (key) => {
    this.setState({
      curtab: key
    })
  }

  render () {
    const { curtab = 'HOME' } = this.state

    return (
      <div>
        <Card bodyStyle={{ padding: '15px' }}>
          <Row>
            <Tabs onChange={this.handleChangeTabPane}>
              {
                tabsMaps.map((item, n) => (
                  <TabPane tab={item.label} key={item.type} />
                ))
              }

            </Tabs>
          </Row>

          <Row>
            {
              curtab === 'HOME'
                ? <HomeDecorate history={this.props.history}

                />
                : null
            }

            {
              curtab === 'NAV'
                ? <NavDecorate />
                : null
            }
          </Row>
        </Card>
      </div>
    )
  }
}
