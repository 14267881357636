import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Form, Input, Button, message, Tabs } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import VerifyModal from '@/components/verify-modal/index'
import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const FormItem = Form.Item
const { TabPane } = Tabs
const { paywordReg } = filter

class PaywordForm extends Component {
  static propTypes = {
    form: PropTypes.object
  }
  state = {
    type: 1, // 1-设置支付密码 2-修改支付密码 3-重置支付密码
    btnDisabled: false,
    yztime: 59,
    tabMaps: [
      { key: '1', label: '设置支付密码' }
    ],
    adminPhone: ''
  }

  componentDidMount () {
    this.getAdminPhoneToDesensitization()
    this.getHasPayPassword()
  }

  // 组件卸载时 关闭定时器
  componentWillUnmount () {
    this.clearInterval()
  }

  // 获取收取验证码的手机号码
  getAdminPhoneToDesensitization = () => {
    Api.getAdminPhoneToDesensitization({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          adminPhone: data || ''
        })
      }
    })
  }

  // 点击获取短信验证码
  sendSmsCaptchaAndValidate = (params) => {
    params['sendSmsCaptchaEnum'] = 'SMS_CAPTCHA_TO_ADMIN'
    Api.sendSmsCaptchaAndValidate(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.setInterval()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 查询是否设置支付密码
  getHasPayPassword = () => {
    Api.getHasPayPassword({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { tabMaps } = this.state
        if (data) {
          tabMaps = [
            { key: '2', label: '修改收款密码' },
            { key: '3', label: '重置收款密码' }
          ]
        }
        this.setState({
          type: data ? 2 : 1,
          tabMaps
        }, () => {
          if (!data) {
            this.onChangeTabkey(1)
          }
        })
      }
    })
  }

  // 更新保存支付密码
  updatePayPassword = (params) => {
    Api.updatePayPassword(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.handleCancel()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 点击切换tab
  onChangeTabkey = (key) => {
    this.setState({
      type: +key
    })
  }

  // 点击获取验证码
  showVerifyModal = () => {
    this.setState({
      verifyModal: true
    })
  }

  // 验证成功
  handleVerifySlideCheckSuccess = (params) => {
    this.setState({
      verifyModal: false
    }, () => {
      this.sendSmsCaptchaAndValidate(params)
    })
  }

  // 点击提交保存
  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { key, type } = this.state
        values.verificationKey = key
        let { oldPassword, newPassword } = values

        let jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])

        values['newPassword'] = jsencrypt.encrypt(newPassword)
        if (type === 2) {
          values['oldPassword'] = jsencrypt.encrypt(oldPassword)
        }

        this.updatePayPassword(values)
      }
    })
  }

  // 点击取消
  handleCancel = () => {
    this.props.form.resetFields()
  }

  // 倒计时
  countDown = () => {
    const { yztime = 59 } = this.state
    if (yztime === 1) {
      this.clearInterval()
    } else {
      this.setState({ btnDisabled: true, yztime: yztime - 1 })
    }
  }

  // 开始定时器
  setInterval = () => {
    this.timer = setInterval(this.countDown, 1000)
  }

  // 清除定时器
  clearInterval = () => {
    clearInterval(this.timer)
    this.setState({
      btnDisabled: false,
      yztime: 59
    })
  }

  // 点击关闭验证弹窗
  closeModal = () => {
    this.setState({
      verifyModal: false
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form

    const { type = 1, btnDisabled = false, yztime = 59, tabMaps = [], adminPhone = '', verifyModal = false } = this.state

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 16 }
    }

    const tailFormItemLayout = {
      wrapperCol: { span: 16, offset: 4 }
    }

    return (
      <>
        <Card title={`设置收款密码`} bodyStyle={{ padding: '15px' }}>
          <Row style={{ marginBottom: '20px' }}>
            <Tabs onChange={this.onChangeTabkey}>
              {
                tabMaps.map((item, i) => (
                  <TabPane tab={item.label} key={item.key} />
                ))
              }
            </Tabs>
          </Row>
          <Row style={{ width: '650px' }}>
            <Form>
              {
                type === 1 || type === 3
                  ? null
                  : <FormItem label='原收款密码' {...formItemLayout}>
                    {
                      getFieldDecorator('oldPassword', {
                        getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                        validate: [
                          {
                            trigger: 'onBlur',
                            rules: [
                              {
                                validator (rule, value, callback) {
                                  if (value && !paywordReg.test(value)) {
                                    /* eslint-disable */
                                    callback('收款密码格式不正确')
                                    /* eslint-enable */
                                  } else {
                                    callback()
                                  }
                                }
                              }
                            ]
                          },
                          {
                            trigger: ['onChange', 'onBlur'],
                            rules: [{ required: true, message: '请您填写原6位数字收款密码' }]
                          }
                        ]
                      })(
                        <Input.Password></Input.Password>
                      )
                    }
                  </FormItem>
              }

              <FormItem label={type === 1 ? '收款密码' : '新收款密码'}
                extra={'收款密码为6位数字'}
                {...formItemLayout}
              >
                {
                  getFieldDecorator('newPassword', {
                    getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                    validate: [
                      {
                        trigger: 'onBlur',
                        rules: [
                          {
                            validator (rule, value, callback) {
                              if (value && !paywordReg.test(value)) {
                                /* eslint-disable */
                                callback('收款密码格式不正确')
                                /* eslint-enable */
                              } else {
                                callback()
                              }
                            }
                          }
                        ]
                      },
                      {
                        trigger: ['onChange', 'onBlur'],
                        rules: [{ required: true, message: '请您填写6位数字收款密码' }]
                      }
                    ]
                  })(
                    <Input.Password></Input.Password>
                  )
                }
              </FormItem>

              {
                type === 1 || type === 3
                  ? <FormItem label='短信验证码' {...formItemLayout} help={adminPhone}>

                    <div style={{ display: 'inline-block', marginRight: '12px', width: 'calc(100% - 142px)' }}>
                      {
                        getFieldDecorator('randomCode', {
                          rules: [
                            { required: true, message: '请填写手机验证码' }
                          ]
                        })(
                          <Input placeholder='请输入...' autoComplete='off'
                            style={{ width: '100%' }}
                          />
                        )
                      }
                    </div>
                    <Button
                      disabled={btnDisabled}
                      onClick={this.showVerifyModal}
                      style={{ width: '130px' }}
                    >
                      {
                        !btnDisabled ? '获取验证码' : `${yztime} s后重新发送`
                      }
                    </Button>

                  </FormItem>
                  : null
              }

              <FormItem {...tailFormItemLayout}>
                <Button type='primary' style={{ marginRight: '30px' }} onClick={this.handleSubmit}>保存</Button>
                <Button onClick={this.handleCancel}>取消</Button>
              </FormItem>
            </Form>
          </Row>
        </Card>

        {
          verifyModal
            ? <VerifyModal
              onConfirm={(params) => this.handleVerifySlideCheckSuccess(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}

const SetPayword = Form.create()(PaywordForm)

export default SetPayword
