import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, Form, InputNumber, Button, Divider, message, Tag, Icon } from 'antd'

import Api from '@/common/api/index'
import AREA from '@/assets/json/area'
import TEL_AREA_CODES from '@/assets/json/telAreaCodes'
// import CourierJson from '@/assets/json/courier.json'
// import filter from '@/common/utils/filter'

import styles from './index.module.scss'

// const { expressTypeMaps } = filter
const FormItem = Form.Item

class FreightForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    info: PropTypes.object,
    addressList: PropTypes.array,
    sendAddressId: PropTypes.number
  }
  state = {
    freight: 0,
    recommendList: [], // 推荐
    isOpened: false
  }

  // 获取物流推荐
  getRecommendTransports = async (params) => {
    const res = await Api.getRecommendTransports(params)
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        recommendList: data || []
      })
    }
  }

  // 点击提交
  handleSubmit = () => {
    const { form, info = {}, sendAddressId, addressList = [] } = this.props
    if (!sendAddressId || +sendAddressId === 0) {
      return message.warning('请选择发货地址')
    }

    form.validateFields((err, values) => {
      if (!err) {
        const selectedAddress = addressList.find((item) => { return item.id === sendAddressId })

        if (selectedAddress !== undefined) {
          values.senderAddress = {
            province: selectedAddress.province,
            city: selectedAddress.city,
            county: selectedAddress.county,
            address: selectedAddress.address,
            provinceName: this.renderCalculatorCityName('provinceList', selectedAddress.province),
            cityName: this.renderCalculatorCityName('cityList', selectedAddress.city),
            countyName: this.renderCalculatorCityName('countyList', selectedAddress.county)
          }

          values.sendCityCode = TEL_AREA_CODES[selectedAddress.city].telAreaCode
        }

        values.receiverAddress = {
          province: info.province,
          city: info.city,
          county: info.county,
          address: info.address,
          provinceName: this.renderCalculatorCityName('provinceList', info.province),
          cityName: this.renderCalculatorCityName('cityList', info.city),
          countyName: this.renderCalculatorCityName('countyList', info.county)
        }

        values.receiverCityCode = TEL_AREA_CODES[info.city].telAreaCode

        this.getRecommendTransports(values)
      }
    })
  }

  // 点击展开、收起
  handleChangeOpened = (isopen) => {
    this.setState({
      isOpened: !isopen
    })
  }

  // 找出城市码数组
  findCityCodeByAddressId = (id) => {
    const { addressList = [] } = this.props
    let codes = []
    let obj = addressList.find((item) => { return item.id === id })

    if (obj !== undefined) {
      codes.push(obj.province, obj.city, obj.county)
    }

    return codes
  }

  // 渲染省、市、区名称
  renderCalculatorCityName = (key = 'cityList', cityCode) => {
    let name = AREA[key][cityCode] || ''

    return name
  }

  render () {
    // const { freight = 0, recommend = '' } = this.state
    const { form, info = {}, sendAddressId } = this.props
    const { getFieldDecorator } = form
    const { recommendList = [], isOpened = false } = this.state

    return (
      <>
        <Card title={'运费时效查询'} bodyStyle={{ padding: '15px' }}>
          <Row>
            <Form className={`${styles.freightForm}`}>
              <Row gutter={12}>
                <Col span={3}>
                  <FormItem label='原寄地'>
                    <div>{ this.renderCalculatorCityName('cityList', this.findCityCodeByAddressId(sendAddressId)[1]) }</div>
                  </FormItem>
                </Col>

                <Col span={3}>
                  <FormItem label='目的地'>
                    <div>{ info.orderType === 'ORDER_CLOUD_STOCK' ? 'IIASaaS平台' : this.renderCalculatorCityName('cityList', info.city) }</div>
                  </FormItem>
                </Col>

                <Col span={6}>
                  <FormItem label='预估重量(kg)'>
                    {
                      getFieldDecorator('totalWeight', {
                        rules: [
                          { required: true, message: '请输入重量' }
                        ]
                      })(
                        <InputNumber autoComplete='off' style={{ width: '100%' }} />
                      )
                    }
                  </FormItem>
                </Col>

                <Col span={6}>
                  <FormItem label={<span>预估体积(m<sup>3</sup>)</span>}>
                    {
                      getFieldDecorator('totalVolume', {
                        initialValue: 0.00001,
                        rules: [
                          { required: true, message: '请输入体积' }
                        ]
                      })(
                        <InputNumber autoComplete='off' style={{ width: '100%' }} />
                      )
                    }
                  </FormItem>
                </Col>

                <Col span={3}>
                  <FormItem style={{ marginBottom: '0px', textAlign: 'center' }}>
                    <Button type='primary' onClick={() => this.handleSubmit()}>计算</Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Row>

          <Divider />

          <>
            <Row gutter={16}>
              <Col span={4}><label className={`label`}>承运公司</label></Col>
              <Col span={6}><label className={`label`}>快递类型</label></Col>
              <Col span={4}><label className={`label`}>运输方式</label></Col>
              <Col span={4}><label className={`label`}>预计运费</label></Col>
              <Col span={6}><label className={`label`}>预计时效</label></Col>
            </Row>

            <>
              {
                recommendList.map((item, n) => (
                  <Row gutter={16} key={`recommendRow-${n}`} className={`${styles.recommendRow} ${!isOpened && n > 4 ? styles.none : null}`}>
                    <Col span={4}>{item.logisticsCompany}</Col>
                    <Col span={6}><span>{item.transportType}</span>{n === 0 ? <Tag color='#FF4444' style={{ marginLeft: '12px' }}>推荐使用</Tag> : null}</Col>
                    <Col span={4}>{ item.logisticsType }</Col>
                    <Col span={4}>{ item.freight }</Col>
                    <Col span={6}>{ item.time }</Col>
                  </Row>
                ))
              }
            </>

            {
              recommendList.length > 5
                ? <Row style={{ textAlign: 'center' }}>
                  <Button type='link' size='small' onClick={() => this.handleChangeOpened(isOpened)}>
                    <span>{ isOpened ? '点击收起' : '点击展开' }</span><Icon type='double-right' className={`${styles.icon} ${isOpened ? styles.up : null}`} />
                  </Button>
                </Row>
                : null
            }
          </>
        </Card>
      </>
    )
  }
}

const FreightCalculator = Form.create()(FreightForm)

export default FreightCalculator
