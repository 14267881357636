import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

export default class StoreoutTable extends Component {
  static propTypes = {
    orderInfo: PropTypes.object,
    orderDetail: PropTypes.array
  }
  state = {}

  render () {
    const { orderInfo = {}, orderDetail = [] } = this.props

    return (
      <div id='salesTable' style={{ display: 'none' }}>
        <h3 style={{ marginBottom: '0.3em', fontSize: '16pt', fontWeight: 'bold', textAlign: 'center' }}>拣货单</h3>

        <table width='100%' style={{ fontSize: '10pt' }}>
          <tbody>
            <tr>
              <td width='33.3333%'>
                <p style={{ marginBottom: '5px' }}>订单号：{orderInfo.orderId}</p>
              </td>
              <td width='33.3333%'>
                <p style={{ marginBottom: '5px' }}>订单日期：{orderInfo && orderInfo.orderTime ? moment(orderInfo.orderTime).format('YYYY-MM-DD HH:mm:ss') : ''}</p>
              </td>
              <td width='33.3333%'>
                <p style={{ marginBottom: '5px' }}>出库单号：{orderInfo.outboundOrderNo}</p>
              </td>
            </tr>
            <tr>
              <td colSpan='3'>
                <p>客户名称：{orderInfo.customerName}</p>
              </td>
            </tr>
          </tbody>
        </table>

        <>
          {
            orderDetail && orderDetail.length > 0
              ? <table border='1' cellSpacing='0' cellPadding='2' width='100%'
                style={{ marginTop: '1em', fontSize: '10pt', borderColor: '#333333', borderCollapse: 'collapse', textAlign: 'center' }}
              >
                <thead>
                  <tr>
                    <th width='5%' style={{ fontWeight: '500' }}>序号</th>
                    <th width='15%' style={{ fontWeight: '500' }}>品牌</th>
                    <th width='22%' style={{ fontWeight: '500' }}>产品信息</th>
                    <th width='20%' style={{ fontWeight: '500' }}>型号</th>
                    <th width='20%' style={{ fontWeight: '500' }}>订货号</th>
                    <th width='10%' style={{ fontWeight: '500' }}>拣货数量</th>
                    <th width='8%' style={{ fontWeight: '500' }}>单位</th>
                  </tr>
                </thead>

                <tfoot>
                  <tr>
                    <th colSpan={7} style={{ textAlign: 'left' }}>
                      <label style={{ fontSize: '10pt', fontWeight: '500' }}>备注：</label>
                      { orderInfo.remarks }
                    </th>
                  </tr>
                </tfoot>

                <tbody>
                  {
                    orderDetail.map((item, i) => (
                      <tr key={`pickProduct-${i}`}>
                        <td style={{ textAlign: 'center' }}>{i + 1}</td>

                        <td>{item.englishBrandName ? `${item.brandName}/${item.englishBrandName}` : item.brandName}</td>

                        <td>{item.name}</td>

                        <td>{item.model}</td>

                        <td>{item.itemNumber}</td>

                        <td>{item.sendNumber}</td>

                        <td>{item.unit}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              : null
          }
        </>

        <table width='100%' style={{ marginTop: '1.5em' }}>
          <tbody>
            <tr>
              <td width='25%'><p style={{ fontSize: '10pt' }}>拣货员：</p></td>
              <td width='25%'><p style={{ fontSize: '10pt' }}>清点员：</p></td>
              <td width='25%'><p style={{ fontSize: '10pt' }}>复核员：</p></td>
              <td width='25%'><p style={{ fontSize: '10pt' }}>打包员：</p></td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
