import React, { Component } from 'react'
import { Icon, Popover, Descriptions, Button, Checkbox, InputNumber, Modal, message } from 'antd'
import { withRouter } from 'react-router-dom'
import $lodash from 'lodash'

import Swiper from 'swiper/dist/js/swiper.min.js'
import PreviewSwiper from './previewSwiper'
import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { getUrlParams, renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'
import placeImg from '@/assets/images/place.jpg'

import styles from './detail.module.scss'
import 'swiper/dist/css/swiper.min.css'

const { shipmentUtilMap, skuStopFlagMaps } = filter

class CloudproductDetail extends Component {
  static propTypes = {

  }
  state = {
    query: {},
    detailsHasCart: false, // 是否有加入购物车
    info: {},
    attrList: [],
    supplyInfoList: [],
    productImageList: [],
    detailImageList: [],
    detailPdfList: [], // PDF列表
    curimgIndex: 0,
    curtabkey: 1, // 当前选择的tab
    popover: true // 提示弹窗
  }

  componentDidMount () {
    let _query = getUrlParams()
    this.setState({
      query: _query,
      detailsHasCart: _query.detailsHasCart || false
    }, () => {
      this.getCloudProductDetail()
    })
  }

  // 初始化swiper
  initSwiper = () => {
    /* eslint-disable */
    let galleryThumbs = new Swiper('#gallertThumbs', {
      slidesPerView: 5,
      spaceBetween: 6,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
    /* eslint-enable */
  }

  // 获取产品详情
  getCloudProductDetail = () => {
    const { query } = this.state

    Api.getCloudProductDetail({ ...query }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let productImageList = []
        let detailPdfList = []
        let detailImageList = []
        let supplyInfoList = []

        if (data) {
          productImageList = data.listImages || []
          detailPdfList = data.detailsPdfs || []
          detailImageList = data.detailsImages || []
          supplyInfoList = data.supplyInfoList || []
        }

        this.setState({
          info: data || {},
          attrList: data.attributeList || [],
          productImageList: productImageList,
          detailImageList: detailImageList,
          detailPdfList: detailPdfList,
          supplyInfoList: supplyInfoList
        })
      }
    })
  }

  // 加入购物车
  saveAddToCart = async (params) => {
    const res = await Api.saveAddToCart(params)
    const { code } = res
    if (+code === 10000) {
      Modal.success({
        title: '加入购物车成功~'
      })
    } else {
      message.warning(res.message)
    }
  }

  // 点击加入购物车
  handleConfirmAddToCart = () => {
    const { info = {}, supplyInfoList = [] } = this.state
    const newArr = $lodash.filter(supplyInfoList, (item) => { return item.checked && +item.quantity > 0 })
    let cartList = []

    if (newArr.length === 0) {
      Modal.warning({
        title: '请选择产品并且填写数量',
        maskClosable: true
      })

      return false
    }

    for (let row of newArr) {
      if ((['SPOT', 'CLOUD_SPOT'].includes(info.skuGoodsType)) && +row.quantity > +row.stock) {
        Modal.warning({
          title: <>订货号:&nbsp;{info.itemNumber}&nbsp;现货库存不足</>,
          maskClosable: true
        })

        return false
      }

      if (row.quantity < (+row.minOrder)) {
        Modal.warning({
          title: <>订货号:&nbsp;{info.itemNumber}&nbsp;最少购买{+row.minOrder}件</>,
          maskClosable: true
        })

        return false
      }

      cartList.push({
        commoditySkuId: info.commoditySkuId,
        goodsType: info.skuGoodsType,
        supplierId: row.supplierId,
        quantity: row.quantity
      })
    }

    if (cartList.length === 0) {
      return message.warning('暂无可添加的商品')
    }

    this.saveAddToCart({ cartList: cartList })
  }

  // 监听数量变化
  handleChangeQuantity = (val, i) => {
    let { supplyInfoList = [] } = this.state
    supplyInfoList[i].quantity = val

    this.setState({
      supplyInfoList: [...supplyInfoList]
    })
  }

  // 监听是否选中
  handleChangeCheckedPrice = (checked, i) => {
    let { supplyInfoList = [] } = this.state
    supplyInfoList[i].checked = checked

    this.setState({
      supplyInfoList: [...supplyInfoList]
    })
  }

  // 点击产品缩略图
  handleSelectedImg = (i) => {
    this.setState({
      curimgIndex: i
    })
  }

  // 点击切换tab
  handleChangeTabs = (key) => {
    this.setState({
      curtabkey: +key
    })
  }

  renderSkuStopFlagLabel = (status) => {
    const obj = skuStopFlagMaps.find((item) => { return item.id === status })
    const label = obj !== undefined ? obj.name : null

    return label
  }

  closePopover = () => {
    this.setState({
      popover: false
    })
  }

  render () {
    const { detailsHasCart = false, info = {}, productImageList = [], detailImageList = [], detailPdfList = [], curtabkey = 1, popover = true, supplyInfoList = [] } = this.state

    let pdfUrl = ''

    if (detailPdfList.length > 0) {
      pdfUrl = detailPdfList[0] || ''
    }

    return (
      <>
        <div className={`${styles.w1200}`}>
          {/* 产品基本信息 */}
          <div className={`${styles.floor} ${styles.productIntro}`}>
            {
              productImageList && productImageList.length > 0
                ? <PreviewSwiper list={productImageList} />
                : <img src={placeImg} width={300} />
            }
            <div className={`${styles.infoZoom}`}>
              <div className={`${styles.name}`} style={{ marginBottom: '10px' }}>
                <div className={styles.branchsty}>{info.englishBrandName && info.englishBrandName !== '' ? `${info.brandName}/${info.englishBrandName}` : info.brandName}</div>{info.name}
              </div>
              <div className={`${styles.chooseWrap} clearfix`}>
                <div className={`${styles.chooseItem}`}>
                  <div className={`${styles.chooseLabel}`}>
                    订<em className={styles.hiddlen}>货</em>货<em className={styles.hiddlen}>货</em>号
                  </div>
                  <div className={`${styles.chooseValue}`}>
                    { info.itemNumber ? info.itemNumber : '---' }
                  </div>
                </div>

                <div className={`${styles.chooseItem} clearfix`}>
                  <div className={`${styles.chooseLabel}`}>
                    型<em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em>号
                  </div>
                  <div className={`${styles.chooseValue}`}>
                    { info.model ? info.model : '----' }
                  </div>
                </div>

                <div className={`${styles.chooseItem} clearfix`}>
                  <div className={`${styles.chooseLabel}`}>
                    品<em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em>牌
                  </div>
                  <div className={`${styles.chooseValue}`}>
                    {
                      info && info.englishBrandName && info.englishBrandName !== ''
                        ? `${info.brandName}/${info.englishBrandName}`
                        : info.brandName
                    }
                  </div>
                </div>

                <div className={`${styles.chooseItem} clearfix`}>
                  <div className={`${styles.chooseLabel}`}>
                    系<em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em>列
                  </div>
                  <div className={`${styles.chooseValue}`}>
                    { info.series }
                  </div>
                </div>

                <div className={`${styles.chooseItem} clearfix`}>
                  <div className={`${styles.chooseLabel}`}>
                   类<em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em>型
                  </div>
                  {
                    info.skuGoodsType
                      ? <span className={`${styles.branchsty} ${['FUTURES', 'CLOUD_FUTURES'].includes(info.skuGoodsType) ? styles.futuresTag : ''}`}>
                        { renderSkuGoodsTypeLabel(info.skuGoodsType) }
                      </span>
                      : '----'
                  }
                </div>

                {
                  supplyInfoList && supplyInfoList.length > 0
                    ? <ul className={styles.productprice}>
                      {
                        supplyInfoList.map((item, i) => (
                          <li className={styles.Pul} key={i}>
                            {
                              detailsHasCart
                                ? <Checkbox style={{ marginRight: '6px' }} checked={item.checked} onChange={(e) => this.handleChangeCheckedPrice(e.target.checked, i)} />
                                : null
                            }

                            <span className={styles.Pprice}>{+item.price > 0 ? +item.price : '面议'}</span>

                            <span style={{ marginLeft: '40px' }}>
                              <span className={styles.branchsty}>{renderSkuGoodsTypeLabel(info.skuGoodsType)}</span>
                              {
                                ['FUTURES', 'CLOUD_FUTURES'].includes(info.skuGoodsType)
                                  ? <span className={styles.Pnum}>周期:{item.deliveryPeriodDays}{ shipmentUtilMap[info.skuGoodsType]}</span>
                                  : <span>库存:{+item.stock}</span>
                              }
                            </span>

                            <span className={styles.lastli}>
                              {
                                detailsHasCart
                                  ? <InputNumber size='small' value={item.quantity} min={1} onChange={(value) => this.handleChangeQuantity(value, i)} />
                                  : null
                              }
                              <span style={{ marginLeft: '8px' }}>最小订货量: {item.minOrder}</span>
                            </span>
                          </li>
                        ))
                      }
                    </ul>
                    : null
                }

                {
                  detailsHasCart
                    ? <div style={{ marginTop: '10px' }}>
                      <Button type='danger' className={`${styles.buyBtn}`} onClick={() => this.handleConfirmAddToCart()}>加入购物车</Button>
                    </div>
                    : null
                }
              </div>
            </div>
          </div>

          <div className={`${styles.floor}`}>
            <div style={{ height: '100%' }}>
              <div className={`${styles.tabBar}`}>
                <div className={`${styles.tabsNav}`}>
                  <div className={`${styles.tabItem} ${+curtabkey === 1 ? styles.tabActive : ''}`} onClick={() => this.handleChangeTabs('1')}>商品介绍</div>

                  {
                    detailPdfList.length > 0
                      ? <Popover
                        content={<div className={`${styles.popoverContent}`}>
                          <span style={{ marginRight: '10px' }}>点击PDF，查看更多信息</span>
                          <a className={`${styles.popoverClose}`} onClick={this.closePopover}><Icon type='close-circle' /></a>
                        </div>}
                        visible={popover}
                      >
                        <div className={`${styles.tabItem} ${+curtabkey === 2 ? styles.tabActive : ''}`} onClick={() => this.handleChangeTabs('2')}>PDF</div>
                      </Popover>
                      : null
                  }

                </div>
              </div>

              <div style={{ padding: '16px 0px', borderBottom: '1px solid #E8E8E8' }}>
                <Descriptions>
                  <Descriptions.Item label='品牌'>{ info.brandName }</Descriptions.Item>
                  <Descriptions.Item label='系列'>{ info.series }</Descriptions.Item>
                  <Descriptions.Item label='型号'>{ info.model }</Descriptions.Item>
                  <Descriptions.Item label='订货号'>{ info.itemNumber }</Descriptions.Item>
                  <Descriptions.Item label='产品名称'>{ info.name }</Descriptions.Item>
                  <Descriptions.Item label='最小包装'>{ info.minPackQuantity }</Descriptions.Item>
                  <Descriptions.Item label='最小订货量'>{ info.minOrder }</Descriptions.Item>
                  <Descriptions.Item label='单位'>{ info.unit }</Descriptions.Item>
                  <Descriptions.Item label='是否停产'>{ this.renderSkuStopFlagLabel(info.stopFlag) }</Descriptions.Item>
                  <Descriptions.Item label='商品描述'>{ info.introduce }</Descriptions.Item>
                </Descriptions>
              </div>

              {
                +curtabkey === 1
                  ? <>
                    {
                      detailImageList.length > 0
                        ? <ul className={`${styles.picList}`}>
                          {
                            detailImageList.map((item, i) => (
                              <li className={`${styles.picItem}`} key={`detailImg-${i}`}>
                                <img className={`${styles.detailimg}`} src={item} />
                              </li>
                            ))
                          }
                        </ul>
                        : null
                    }
                  </>
                  : <>
                    {
                      detailPdfList && detailPdfList.length > 0
                        ? <div style={{ height: '100%' }}>
                          <iframe src={pdfUrl} width='100%' height='600px' />
                        </div>
                        : null
                    }
                  </>
              }
            </div>
          </div>
        </div>
      </ >
    )
  }
}

export default withRouter(CloudproductDetail)
