import React, { Component } from 'react'
import { Row, Card, Table, Button, Switch, message, Modal } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import MemberModal from '../component/member-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const personStatusMap = [
  { type: 'ALL', code: '-1', label: '全部' },
  { type: 'ENABLED', code: '0', label: '启用' },
  { type: 'FREEZE', code: '1', label: '禁用' }
]

const { confirm } = Modal
const { pageSizeOptions } = filter

export default class MemberList extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'account',
        formType: 'Input',
        itemParams: {
          label: '登录名'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'realName',
        formType: 'Input',
        itemParams: {
          label: '员工姓名'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'phone',
        formType: 'Input',
        itemParams: {
          label: '手机号码'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '账号状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: personStatusMap,
        optionValue: ['type', 'label']
      }
    ],
    memberModal: false,
    type: 1,
    query: {},
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    rowDetail: {}
  }
  columns = [
    {
      title: '序号',
      key: 'member-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '登录名',
      key: 'member-1',
      dataIndex: 'account'
    },
    {
      title: '姓名',
      key: 'member-2',
      dataIndex: 'realName'
    },
    {
      title: '联系方式',
      key: 'member-9',
      dataIndex: 'phone'
    },
    {
      title: '邮箱地址',
      key: 'member-3',
      dataIndex: 'email'
    },
    {
      title: '邀请码',
      key: 'member-5',
      dataIndex: 'invitationCode'
    },
    {
      title: '最后登录时间',
      key: 'member-6',
      dataIndex: 'lastLoginTime',
      render: (text, record, index) => (
        <>{ record.lastLoginTime ? moment(record.lastLoginTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '是否启用',
      key: 'member-7',
      dataIndex: 'status',
      render: (text, record, index) => (
        <div>
          <Switch
            size='small'
            checkedChildren='启用' unCheckedChildren='禁用'
            checked={record.status && record.status === 'ENABLED'}
            onChange={(checked) => this.changePersonStatus(checked, record)}
          />
        </div>
      )
    },
    {
      title: '操作',
      key: 'member-8',
      width: 220,
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small' style={{ marginRight: '8px' }} onClick={() => this.showMemberModal(record, 2)}>编辑</Button>

          <Button type='primary' size='small' onClick={() => this.handleResetPassword(record)}>
            重置密码
          </Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getPersonList()
  }

  // 获取员工列表
  getPersonList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getPersonList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取员工信息详情
  getPersonDetail = (params) => {
    return Api.getPersonDetail(params)
  }

  // 保存新增员工信息
  saveAddPerson = (params) => {
    Api.saveAddPerson(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.closeModal()
        this.getPersonList()
      }
    })
  }

  // 保存编辑员工信息
  saveEditPerson = (params) => {
    Api.saveEditPerson(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.closeModal()
        this.getPersonList()
      }
    })
  }

  // 更新员工账号启用/禁用状态
  updatePersonStatus = (params) => {
    Api.updatePersonStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.getPersonList()
      }
    })
  }

  // 重置员工登录密码
  submitResetPassword = (params) => {
    Api.submitResetPassword(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.countDown()
      }
    })
  }

  // 点击新增/编辑成员
  showMemberModal = (row = {}, type) => {
    this.setState({
      memberModal: true,
      type: type,
      rowDetail: row
    })
  }

  // 点击保存员工信息
  handleSubmitMember = (params) => {
    const { type = 1 } = this.state

    if (type === 1) {
      this.saveAddPerson(params)
    } else {
      this.saveEditPerson(params)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1,
        total: 0
      }
    }, () => {
      this.getPersonList()
    })
  }

  // 点击启用禁用
  changePersonStatus = (checked, record) => {
    let params = {
      supplierUserId: record.supplierUserId,
      status: checked ? 'ENABLED' : 'FREEZE'
    }

    this.updatePersonStatus(params)
  }

  // 点击重置密码按钮
  handleResetPassword = (record) => {
    confirm({
      title: '确定重置密码？',
      content: '',
      onOk: () => {
        this.submitResetPassword({ supplierUserId: record.supplierUserId })
      }
    })
  }

  // 5s倒计时
  countDown = () => {
    let secondsToGo = 5

    const modal = Modal.success({
      title: '重置密码成功！',
      content: <div>
        <div>初始密码：111111</div>
        <div>请妥善保管密码</div>
        <div>{secondsToGo} s后将关闭窗口</div>
      </div>,
      onOk: () => {

      }
    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        content: <div>
          <div>初始密码：111111</div>
          <div>请妥善保管密码</div>
          <div>{secondsToGo} s后将关闭窗口</div>
        </div>
      })
    }, 1000)

    setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
    }, secondsToGo * 1000)
  }

  closeModal = () => {
    this.setState({
      memberModal: false,
      type: 1,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPersonList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPersonList()
    })
  }

  render () {
    const { searchData = [], tableData = [], pages, memberModal = false, type = 1, rowDetail } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }} style={{ marginBottom: '15px' }}>
          <SearchForm
            {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </Card>

        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ marginBottom: '12px' }}>
            <Button type='primary'
              style={{ marginRight: '10px', width: '110px', height: '30px' }}
              onClick={() => this.showMemberModal({}, 1)}
            >
              添加成员
            </Button>
          </Row>

          <Table rowKey='supplierUserId'
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
          />
        </Card>

        {
          memberModal
            ? <MemberModal
              data={rowDetail}
              type={type}
              getDetail={(params) => this.getPersonDetail(params)}
              onConfirm={(params) => this.handleSubmitMember(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}
