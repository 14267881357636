import React, { Component } from 'react'
import { Card, Table, Badge, message, Button } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import ApplylogModal from '../component/applylogModal'
import ApplyModal from './applyModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions, auditStatusMap } = filter

export default class SupplyBrand extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      },
      {
        name: 'auditStatus',
        formType: 'Select',
        itemParams: {
          label: '审核状态',
          extra: ''
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...auditStatusMap
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    applylogModal: false,
    applyModal: false,
    rowDetail: {},
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'supplyBrand-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'supplyBrand-1',
      render: (text, record, index) => (
        <div>{record.brandName}{record.englishBrandName ? `/${record.englishBrandName}` : null}</div>
      )
    },
    {
      title: '审核状态',
      key: 'supplyBrand-2',
      render: (text, record, index) => (
        <div>
          {this.renderAuditStatus(record.auditStatus)}
        </div>
      )
    },
    {
      title: '操作',
      key: 'supplyBrand-3',
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small' style={{ marginRight: '8px' }}
            disabled={record.auditStatus === 'AUDIT_ACCEPTED' || record.auditStatus === 'AUDIT_SUCCESS'}
            onClick={() => this.showCloudApplyModal(record)}
          >
            云供应申请
          </Button>

          <Button type='primary' size='small'
            onClick={() => this.showApplyLogModal(record)}
          >
            申请日志
          </Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getBrandList()
    this.getSupplyBrandList()
  }

  // 获取检索品牌
  getBrandList = () => {
    Api.getBrandList({ brandListType: 'ALL_BRAND' }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state

        if (data && data.length > 0) {
          data.map((item) => {
            item.showLabel = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
          })

          searchData[0].options = $lodash.concat([{ brandId: 0, showLabel: '全部' }], data)
        }

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 获取云供应品牌列表
  getSupplyBrandList = () => {
    const { pages, query } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getSupplyBrandList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 云供应品牌申请
  supplyBrandAuthApply = (params) => {
    Api.supplyBrandAuthApply(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.closeModal()
        this.getSupplyBrandList()
      }
    })
  }

  // 获取品牌授权申请日志
  getSupplyBrandAuthFlow = (params) => {
    return Api.getSupplyBrandAuthFlow(params)
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getSupplyBrandList()
    })
  }

  // 点击发起云供应
  showCloudApplyModal = (record) => {
    this.setState({
      applyModal: true,
      rowDetail: record
    })
  }

  // 点击查看申请日志
  showApplyLogModal = (record = {}) => {
    this.setState({
      applylogModal: true,
      rowDetail: record || {}
    })
  }

  // 渲染申请状态
  renderAuditStatus = (status) => {
    const obj = auditStatusMap.find(item => { return item.id === status })
    const el = obj !== undefined ? <Badge status={obj.badge} text={obj.name} /> : null

    return el
  }

  closeModal = () => {
    this.setState({
      applylogModal: false,
      applyModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSupplyBrandList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSupplyBrandList()
    })
  }

  render () {
    const { searchData, pages, tableData, applylogModal = false, applyModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }} style={{ marginBottom: '15px' }}>
          <SearchForm
            {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </Card>

        <Card bodyStyle={{ padding: '15px' }}>
          <Table rowKey='brandId'
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>

        {
          applyModal
            ? <ApplyModal
              detailInfo={rowDetail}
              onConfirm={(params) => this.supplyBrandAuthApply(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          applylogModal
            ? <ApplylogModal
              detailInfo={rowDetail}
              getDetail={(params) => this.getSupplyBrandAuthFlow(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}
