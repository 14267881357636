import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import $lodash from 'lodash'

import Api from '@/common/api/index'

import { Modal, Form, Row, Col, Select, Input, Checkbox, Alert } from 'antd'

const FormItem = Form.Item
const Option = Select.Option
const { TextArea } = Input

const tempTypeMap = [
  { type: 'COMMON', label: '通用模板' },
  { type: 'GUEST', label: '客户模板' }
]

class RactForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    type: PropTypes.number,
    getDetail: PropTypes.func,
    getSearchCustomer: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    info: {},
    euOptions: [], // 客户列表
    selectedUserId: 0, // 当前选择的用户
    plainOptions: [], // 自定义选项集合
    selectedKeys: [] // 选择的选项
  }

  componentDidMount () {
    axios.all([Api.getContractTempFieldMap({}), Api.getGuesteuUserLike({ pageNo: 1, pageSize: 999, searchName: '' })])
      .then(axios.spread((fieldRes, euoptionsRes) => {
        if (+fieldRes.code === 10000 && +euoptionsRes.code === 10000) {
          const { type = 1 } = this.props
          let _fieldMaps = []

          if (fieldRes.data) {
            Object.keys(fieldRes.data).forEach(item => {
              let obj = {
                label: fieldRes.data[item],
                value: item,
                required: false
              }
              _fieldMaps.push(obj)
            })

            this.setState({
              plainOptions: _fieldMaps,
              euOptions: euoptionsRes.data.list || []
            }, () => {
              if (+type === 2) {
                this.getContractTempDetail()
              }
            })
          }
        }
      }))
  }

  // 查询客户信息
  getGuesteuUserLike = (val = '') => {
    let params = {
      pageNo: 1,
      pageSize: 999,
      searchName: val
    }

    this.props.getSearchCustomer(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          euOptions: data.list || []
        })
      }
    })
  }

  // 获取模板详情
  getContractTempDetail = () => {
    const { detailInfo } = this.props
    let params = {
      templateId: detailInfo.agreementId
    }

    this.props.getDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let str = ''
        let _selectedUserIds = []
        let _selectedKeys = []

        if (data && data.agreementTemplateType && data.agreementTemplateType !== '') {
          str = data.agreementTemplateType
        }

        if (data.userInfoList && data.userInfoList.length > 0) {
          data.userInfoList.map(item => {
            _selectedUserIds.push(item.endUserId)
          })
        }

        if (data && data.productFields && data.productFields !== '') {
          _selectedKeys = data.productFields.split(',')
        }

        this.setState({
          info: data || {},
          defaultUserIds: _selectedUserIds,
          selectedTempType: str,
          selectedKeys: _selectedKeys
        })
      }
    })
  }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo, type } = this.props
        const { selectedKeys = [] } = this.state

        if (type === 2) {
          values.agreementId = detailInfo.agreementId
        }

        values.fieldsList = selectedKeys

        this.props.onConfirm(values)
      }
    })
  }

  // 选择导出项
  handleChangeSelectplainItem = (val) => {
    let { selectedKeys = [] } = this.state
    let n = selectedKeys.findIndex(item => { return item === val })

    if (n > -1) {
      selectedKeys.splice(n, 1)
    } else {
      selectedKeys.push(val)
    }

    this.setState({
      selectedKeys
    })
  }

  render () {
    const { type = 1 } = this.props
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { euOptions = [], info = {}, defaultUserIds = [], plainOptions = [], selectedKeys = [] } = this.state

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 }
    }

    return (
      <>
        <Modal title={type === 1 ? '新增合同模板' : '编辑合同模板'}
          width={800}
          visible
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Form>
            <FormItem label='模板类型' {...formItemLayout}>
              {
                getFieldDecorator('agreementTemplateType', {
                  initialValue: info && info.agreementTemplateType ? info.agreementTemplateType : '',
                  rules: [
                    { required: true, message: '该项必选' }
                  ]
                })(
                  <Select>
                    {
                      tempTypeMap.map((item, i) => (
                        <Option value={item.type} key={`tempType-${i}`}>{item.label}</Option>
                      ))
                    }
                  </Select>
                )
              }
            </FormItem>

            {
              getFieldValue('agreementTemplateType') === 'GUEST'
                ? <FormItem label='客户名称' {...formItemLayout}>
                  {
                    getFieldDecorator('endUserIdList', {
                      initialValue: defaultUserIds,
                      rules: [
                        { required: true, message: '该项必选' }
                      ]
                    })(
                      <Select mode='multiple'
                        showSearch
                        optionFilterProp='children'
                        onSearch={(val) => this.getGuesteuUserLike(val)}
                      >
                        {
                          euOptions.map((cusitem, i) => (
                            <Option value={cusitem.endUserId} key={cusitem.endUserId}>{cusitem.showName}</Option>
                          ))
                        }
                      </Select>
                    )
                  }
                </FormItem>
                : null
            }

            <FormItem label='模板名称' {...formItemLayout}>
              {
                getFieldDecorator('agreementTemplateName', {
                  initialValue: info && info.agreementTemplateName ? info.agreementTemplateName : '',
                  rules: [
                    { required: true, message: '该项必选' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>
            <FormItem label='补充条款' {...formItemLayout}>
              {
                getFieldDecorator('agreementTemplateContent', {
                  initialValue: info && info.agreementTemplateContent ? info.agreementTemplateContent : '',
                  rules: [
                    { required: true, message: '该项必选' }
                  ]
                })(
                  <TextArea placeholder='请输入...' autoComplete='off' rows={4} />
                )
              }
            </FormItem>

            <>
              <Alert type='info' message={`可以勾选想导出的选项`} style={{ marginBottom: '10px' }} />
              {
                plainOptions.length > 0
                  ? <Row gutter={12}>
                    <Col span={6}>
                      <Checkbox defaultChecked disabled>序号</Checkbox>
                    </Col>
                    {
                      plainOptions.map((item, i) => (
                        <Col span={6} key={`optionsCol-${i}`}>
                          <Checkbox value={`${item.value}`} checked={$lodash.includes(selectedKeys, item.value)}
                            onChange={(e) => this.handleChangeSelectplainItem(e.target.value)}
                          >
                            {item.label}
                          </Checkbox>
                        </Col>
                      ))
                    }
                    <Col span={6}>
                      <Checkbox defaultChecked disabled>含税合计金额</Checkbox>
                    </Col>
                    <Col span={6}>
                      <Checkbox defaultChecked disabled>未税合计金额</Checkbox>
                    </Col>
                    <Col span={6}>
                      <Checkbox defaultChecked disabled>税率</Checkbox>
                    </Col>
                    <Col span={6}>
                      <Checkbox defaultChecked disabled>税额</Checkbox>
                    </Col>
                    <Col span={6}>
                      <Checkbox defaultChecked disabled>预付款/定金</Checkbox>
                    </Col>
                    <Col span={6}>
                      <Checkbox defaultChecked disabled>尾款</Checkbox>
                    </Col>
                  </Row>
                  : null
              }
            </>
          </Form>
        </Modal>
      </>
    )
  }
}

const RactModal = Form.create()(RactForm)
export default RactModal
