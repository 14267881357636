/* 密码规则
 *  6-12位字符 可包含数字，大小写字母
 */
const passReg = /^[a-zA-Z0-9]{6,12}$/

/* 支付密码规则
 *  6位数字
 */
const paywordReg = /^\d{6}$/

// 手机号码规则
const phoneReg = /^1[3456789]\d{9}$/

/* 登录名规则
 *  4-16位字符 可包含数字、大小写字母、下划线
 */
const loginnameReg = /^[a-zA-Z0-9_]{4,16}$/

/* 邮箱规则
 */
const emailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/

// 去掉字符串首尾空格
const trim = (str) => {
  return str.replace(/(^\s*)|(\s*$)/g, '')
}

// 去掉字符串所有空格
const trimAll = (str) => {
  return str.replace(/\s+/g, '')
}

// 去掉字符串所有中横线
const trimLine = (str) => {
  return str.replace(/-/g, '')
}

// 替换字符串中的换行
const repLineBreak = (str, sym) => {
  return str.replace(/\n/g, sym)
}

/**
 * 分页页码配置
 */
const pageSizeOptions = ['10', '20', '50', '100', '200']

const authorMap = [
  { type: 1, label: '供应商' },
  { type: 2, label: '代理商' },
  { type: 3, label: '商户' }
]

// 商品是否停产
const skuStopFlagMaps = [
  { id: 'ON_SALE', name: '否' },
  { id: 'STOP_PRODUCTION', name: '是' }
]

// EU订单状态
const orderStatusMaps = [
  { id: 'PAYING', code: 10, name: '待付款' },
  { id: 'CANCEL_IN_PAYING', code: 11, name: '未支付取消' },
  { id: 'CANCEL_OVERDUE', code: 12, name: '超时取消' },
  { id: 'CANCEL_MANUAL', code: 13, name: '手动取消' },
  { id: 'WAIT_ORDER_GOODS', code: 20, name: '待订货' },
  { id: 'TAIL_PAYING', code: 80, name: '待付尾款' },
  { id: 'CANCEL_AFTER_PAYING', code: 90, name: '已付首款取消' },
  { id: 'WAIT_TO_SHIP', code: 100, name: '待发货' },
  { id: 'WAIT_TO_RECEIVE', code: 110, name: '待收货' },
  { id: 'ORDER_COMPLETE', code: 1000, name: '已完成' }
]

// 云采购订单状态
const cloudPurchOrderStatusMap = [
  { id: 'INITIAL', name: '待EU用户付款' },
  { id: 'PAYING', name: '待付款' },
  { id: 'CANCEL_IN_PAYING', name: '未支付取消' },
  { id: 'CANCEL_OVERDUE', name: '超时取消' },
  { id: 'CANCEL_MANUAL', code: 13, name: '手动取消' },
  { id: 'CANCEL_AFTER_PAYING', name: '已付首款取消' },
  { id: 'TAIL_PAYING', name: '待付尾款' },
  { id: 'WAIT_TO_SHIP', name: '待发货' },
  { id: 'WAIT_ORDER_GOODS', name: '待订货' },
  { id: 'WAIT_TO_RECEIVE', name: '已发货' },
  { id: 'ORDER_COMPLETE', name: '已完成' }
]

// 订单类型
const orderTypeMaps = [
  { id: 'ORDER_STOCK', name: '自营现货订单', ident: 'SPOT' },
  { id: 'ORDER_CLOUD_STOCK', name: '云现货供应订单', ident: 'SPOT' },
  { id: 'ORDER_FUTURES', name: '自营期货订单', ident: 'FUTURES' },
  { id: 'ORDER_CLOUD_FUTURES', name: '云期货供应订单', ident: 'FUTURES' }
]

// 发货- 拣货单发货状态
const pickingOrderSendStatusMaps = [
  { id: 'AWAIT_SEND_OUT', code: '3', name: '待发货' },
  { id: 'PART_DELIVER_GOODS', code: '105', name: '部分发货' },
  { id: 'ALREADY_SEND_OUT', code: '4', name: '已发货' }
]

// 订单列表 -订单发货状态
const orderDeliveryStatusMaps = [
  { id: 'NO', code: 10, name: '待发货' },
  { id: 'PART', code: 50, name: '部分发货' },
  { id: 'COMPLETE', code: 100, name: '已发货' }
]

// 订单列表 -订单到货状态
const orderArriverStatusMaps = [
  { id: 'UN_ARRIVED', code: 10, name: '未到货' },
  { id: 'PART', code: 100, name: '部分到货' },
  { id: 'ARRIVED', code: 50, name: '已到货' }
]

// 订单列表 -订单支付状态
const orderPaymentStatusMaps = [
  { id: 'NO', code: 10, name: '未付款' },
  { id: 'PART', code: 50, name: '已付首款' },
  { id: 'COMPLETE', code: 100, name: '全部付款' }
]

// 云供应品牌审核状态
const auditStatusMap = [
  { id: 'AUDIT_UNKNOWN', name: '未申请', badge: 'warning' },
  { id: 'AUDIT_ACCEPTED', name: '审核中', badge: 'processing' },
  { id: 'AUDIT_SUCCESS', name: '已通过', badge: 'success' },
  { id: 'AUDIT_FAIL', name: '已拒绝', badge: 'error' },
  { id: 'AUDIT_OVERDUE', name: '已逾期', badge: 'default' }
]

// 云供应代理证状态
const brandAgencyStatusMaps = [
  { id: 'AUDIT_SUCCESS', name: '已审核通过', color: '#52C41A' },
  { id: 'AUDIT_FAIL', name: '缺少代理证', color: '#f5222d' }
]

// 导入类型
const importConfig = {
  'futurepriceSetting': { sheetSourch: 'FUTURE_PRICE_DEFAULT' }, // 期货
  'priceSetting': { sheetSourch: 'PRICE_DEFAULT' },
  'futurecuspriceSetting': { sheetSourch: 'FUTURE_PRICE_GUEST' }, // 期货
  'cuspriceSetting': { sheetSourch: 'PRICE_GUEST' },
  'futurepricegroupSetting': { sheetSourch: 'FUTURE_PRICE_GROUP_COMMON' }, // 期货
  'pricegroupSetting': { sheetSourch: 'PRICE_GROUP_COMMON' },
  'futurecuspricegroupSetting': { sheetSourch: 'FUTURE_PRICE_GROUP_GUEST' }, // 期货
  'cuspricegroupSetting': { sheetSourch: 'PRICE_GROUP_GUEST' },
  'storeInSetting': { sheetSourch: 'PRODUCT_STOCK' },
  'supplyProductSetting': { sheetSourch: 'PRODUCT_CLOUD_SUPPLY' }, // 云供应现货导入设置
  'supplyFuturesSetting': { sheetSourch: 'PRODUCT_CLOUD_FUTURES_SUPPLY' }, // 云供应期货导入设置
  'enquireProducts': { sheetSourch: 'ENQUIRY' }, // 询价产品
  'PRODUCT_LIBRARY_IMPORT': { sheetSourch: 'PRODUCT_LIBRARY_IMPORT' }, // 我的产品库导入
  'cloudLibrarySetting': { sheetSourch: 'CLOUDLIBRARY_BATCHSEARCH_PRODUCT' } // 云库产品列表导入搜索
}

// 现货 &期货 &云供应 &云采购 上架状态
const productPutOnMap = [
  { id: 'ENABLE', name: '已上架', color: '#52C41A' },
  { id: 'DISABLE', name: '未上架', color: '#F5222D' }
]

// 云仓等级枚举
const cloudStoreLevelMaps = [
  { id: 'FIRST_LEVEL', name: '一级' },
  { id: 'SECOND_LEVEL', name: '二级' },
  { id: 'LAST_LEVEL', name: '三级' }
]

// 添加到产品库状态
const productAddMap = [
  { id: 'ENABLE', name: '已添加' },
  { id: 'DISABLE', name: '未添加' }
]

// 快递服务类型枚举
const logisticsWayMaps = [
  { id: 'SELF', name: '自营服务' },
  { id: 'SAAS', name: 'IIASaaS服务' },
  { id: 'TAKE_THEIR', name: '客户自提' }
]

// 消息类型
const notifyTypeMaps = [
  { id: 'SYSTEM_MSG', name: '系统通知' },
  { id: 'SYSTEM_NOTIFY', name: '活动通知' },
  { id: 'SYSTEM_ANNOUNCEMENT', name: '系统公告' },
  { id: 'TASK_DISTRIBUTE', name: '任务分发' },
  { id: 'PLATFORM_ANNOUNCEMENT', name: 'IIASaaS公告' }
]

// 消息阅读状态
const notifyReadMaps = [
  { id: 'UNREAD', name: '未读' },
  { id: 'READ', name: '已读' }
]

// 消息发送对象
const merchantNotifyTypeMaps = [
  { id: 'M_EU_EMPLOYEE_ALL', name: '全体', color: '#333333' },
  { id: 'M_EU_ALL', name: '全体客户', color: '#333333' },
  { id: 'M_EU_PART', name: '部分客户', color: '#1890ff' },
  { id: 'M_EU_SINGLE', name: '单个客户', color: '#1890ff' },
  { id: 'M_EMPLOYEE_ALL', name: '全体员工', color: '#1890ff' },
  { id: 'M_EMPLOYEE_PART', name: '部分员工', color: '#1890ff' },
  { id: 'M_EMPLOYEE_SINGLE', name: '单个员工', color: '#1890ff' }
]

// IIA公告通知对象枚举
const iiaNoticeObjectMaps = [
  { id: 'EU', name: 'EU' },
  { id: 'MERCHANT', name: '商户' },
  { id: 'SUPPLIER', name: '供应商' },
  { id: 'IIASAAS', name: 'IIASaaS' }
]

// 图片大小限制
const imageMaxSizeMaps = {
  'productImage': 2,
  'bannerImage': 2,
  'coverImage': 2
}

// 期货 货期单位
const shipmentUtilMap = {
  'FUTURES': '天',
  'CLOUD_FUTURES': '个工作日'
}

// IIASaaS快递服务公司
const saasCouierCodeMaps = [
  {
    id: 'SAAS-DBL',
    courierCode: 'SAAS-DBL',
    courierName: 'IIASaaS服务（德邦）',
    aliasCode: 'DBL',
    aliasName: '德邦快递'
  },
  // {
  //   id: 'SAAS-SF',
  //   courierCode: 'SAAS-SF',
  //   courierName: 'IIASaaS服务（顺丰）',
  //   aliasCode: 'SF',
  //   aliasName: '顺丰速运'
  // },
  // {
  //   id: 'SAAS-JD',
  //   courierCode: 'SAAS-JD',
  //   courierName: 'IIASaaS服务（京东）',
  //   aliasCode: 'JD',
  //   aliasName: '京东快递'
  // },
  {
    id: 'SAAS-KYSY',
    courierCode: 'SAAS-KYSY',
    courierName: 'IIASaaS服务（跨越速运）',
    aliasCode: 'KYSY',
    aliasName: '跨越速运'
  }
]

// IIASAAS快递服务类型
const expressTypeMaps = {
  'SAAS-DBL': [
    { id: 'PACKAGE', name: '标准快递' },
    { id: 'RCP', name: '大件快递360' },
    { id: 'JZKH', name: '精准卡航' },
    { id: 'JZQY_LONG', name: '精准汽运' },
    { id: 'NZBRH', name: '重包入户' },
    { id: 'ZBTH', name: '重包特惠' }
  ],
  'SAAS-SF': [
    { id: '1', name: '顺丰特快（空运）' },
    { id: '2', name: '顺丰标快（陆运）' },
    { id: '255', name: '顺丰卡航（物流）' },
    { id: '111', name: '顺丰干配' },
    { id: '231', name: '陆运包裹' },
    { id: '263', name: '同城半日达（快递）' }
  ],
  'SAAS-JD': [
    { id: '1', name: '特惠送' },
    { id: '6', name: '特快零担' },
    { id: '25', name: '特快重货' }
  ],
  'SAAS-KYSY': [
    { id: '20', name: '次日达' },
    { id: '30', name: '隔日达' },
    { id: '40', name: '陆运件' },
    { id: '50', name: '同城次日' },
    { id: '160', name: '省内次日' }
  ]
}

// IIASaaS快递服务- 签单返还枚举类
const expressReceiptFlags = {
  'SAAS-DBL': [
    { id: 'DEPPON_0', name: '无需返单' },
    { id: 'DEPPON_1', name: '签收单原件返回' },
    { id: 'DEPPON_2', name: '电子签收单' }
  ],
  'SAAS-SF': [
    { id: 'SF_NO', name: '无需返单' },
    { id: 'SF_IN03', name: '纸质回单' },
    { id: 'SF_IN149', name: '电子回单' }
  ],
  'SAAS-JD': [
    { id: 'JD_0', name: '无签单' },
    { id: 'JD_1', name: '纸质签单' },
    { id: 'JD_3', name: '电子签单' },
    { id: 'JD_4', name: '纸质签单和电子签单' }
  ],
  'SAAS-KYSY': [
    { id: 'KY_20', name: '无需回单', key: '20' },
    { id: 'KY_10', name: '回单原件(含回单照片)', key: '10' },
    { id: 'KY_30', name: '回单照片', key: '30' },
    { id: 'KY_40', name: '电子回单', key: '40' }
  ]
}

// IIASaaS快递服务- 签收单返回类型枚举类
const expressReceiptReturnRequirements = {
  'SAAS-DBL': [
    { id: 'R1', name: '签名' },
    { id: 'R2', name: '盖章' },
    { id: 'R3', name: '签身份证号' },
    { id: 'R4', name: '身份证复印件' },
    { id: 'R5', name: '仓库收货回执单' }
  ]
}

// IIASaaS快递服务- 木架包装方式枚举
const expressPackWoodenFrames = {
  'SAAS-KYSY': [
    { id: '0', name: '不打木架' },
    { id: '10', name: '打卡板' },
    { id: '20', name: '打木架' },
    { id: '30', name: '打木箱' }
  ]
}

// 账单状态(德邦 顺丰 保险)
const billStatusMaps = [
  { id: 'ALL', name: '全部' },
  { id: 'NOT_OVERDUE', name: '未逾期', badge: 'success' },
  { id: 'OVERDUE', name: '已逾期', badge: 'error' },
  { id: 'SETTLED', name: '已结算', badge: 'warning' }
]

// 上传图片规则
const uploadImageRules = {
  'logoImage': { width: 200, height: 60, maxSize: 2 },
  'commonSealImage': { width: 137, height: 137, maxSize: 2 }
}

// 上传图片大小
const IMAGEMAXSIZE = 2

// 产品类型
export const skuGoodsTypeMaps = [
  { id: 'SPOT', name: '现货', aliasName: '现货' },
  { id: 'FUTURES', name: '期货', aliasName: '期货' },
  { id: 'CLOUD_SPOT', name: '云现货', aliasName: '现货调货' },
  { id: 'CLOUD_FUTURES', name: '云期货', aliasName: '期货调货' }
]

// 询价单-数据类型枚举
const enquireMatchStatusMaps = [
  { id: 'MATCHED', name: '已收录数据' },
  { id: 'UNMATCHED', name: '未收录数据' }
]

// 询价单-询价单提交类型
const enquireOrderStateMaps = [
  { id: 'PLATFORM_DRAFT', name: '草稿' },
  { id: 'INTERNAL_DRAFT', name: '草稿' },
  { id: 'PLATFORM', name: '运营提交' },
  { id: 'MERCHANT', name: '商户提交' },
  { id: 'INTERNAL', name: '内部提交' },
  { id: 'MERCHANT_PRE_QUOTATION', name: '预报价询价' },
  { id: 'MERCHANT_QUICK_ORDER', name: '快速下单询价' }
]

// 询价单-报价类型
const answerEnquiredStatusMaps = [
  { id: 'NO', name: '暂未报价' },
  { id: 'RECEIVE', name: '同意报价' },
  { id: 'REFUSE', name: '拒绝报价' },
  { id: 'COMMUNICATE', name: '沟通报价' }
]

// 合同模板- 模板状态
const contractTemplateEnableStatusMaps = [
  { id: 'ENABLE', name: '启用' },
  { id: 'DISABLE', name: '禁用' }
]

// 发票- 发票类型枚举
const invoiceTypeMaps = [
  { id: 'ORDINARY_INVOICE', name: '增值税普通发票' },
  { id: 'SPECIAL_INVOICE', name: '增值税专用发票' }
]

// 发票- 开票状态枚举
const invoiceOpenedStatusMaps = [
  { id: 'OPENING', name: '待开票' },
  { id: 'OPENED', name: '已开票' }
]

// 售后- 售后类型
const saledTypeMaps = [
  { id: 'REFUND_RETURN_PRODUCTS', name: '退货退款' },
  { id: 'REFUND', name: '退款' },
  { id: 'CHANGE_PRODUCTS', name: '换货' },
  { id: 'ADD_DELIVER', name: '补寄' }
]

// 售后- 云采购售后状态
const saledStatusMaps = [
  { id: 'WAITING', name: '待受理' },
  { id: 'WAITING_AUDIT', name: '待审核' },
  { id: 'AUDIT_FAILED', name: '审核驳回' },
  { id: 'REJECT', name: '拒绝申请' },
  { id: 'WAITING_BACK', name: '待退货' },
  { id: 'WAITING_REFUND', name: '待退款' },
  { id: 'WAITING_DELIVER', name: '待发货' },
  { id: 'COMPLETE', name: '已完成' }
]

// 售后- 自营、云供应 售后状态枚举
const selfSaledStatusMaps = [
  { id: 'WAITING_AUDIT', name: '待审核' },
  { id: 'AUDIT_SUCCESS', name: '审核通过' },
  { id: 'AUDIT_FAILED', name: '审核驳回' }
]

// 售后- 售后原因枚举
const saledReasonTypeMaps = [
  { id: 'PRODUCT_FOR_PAGE_NOT_SAME', name: '商品与页面描述不符' },
  { id: 'SKU_CHOOSE_ERROR', name: '型号订货号选择错误' },
  { id: 'GOODS_MISSED', name: '少件/发错货/未收到货' },
  { id: 'GOODS_WAS_BAD', name: '商品破损/包装破损/序列号模糊' },
  { id: 'PRODUCTS_QUALITY_PROBLEM', name: '商品质量问题/存在故障' },
  { id: 'FAKE_OR_SECOND_HAND_GOODS', name: '商品疑似假货/二手商品' }
]

// 售后- 退款方式枚举
const saledRefundTypeMaps = [
  { id: 'ORIGINAL_PAYMENT_TYPE', name: '原支付返还' }
]

// 售后- 退货方式
const saledBackGoodsTypeMaps = [
  { id: 'IIASAAS', name: 'IIASaaS服务上门取件' },
  { id: 'SELF', name: '自行退货至IIASaaS' }
]

export default {
  orderDeliveryStatusMaps,
  orderArriverStatusMaps, // 订单到货状态
  orderPaymentStatusMaps,
  passReg,
  paywordReg,
  phoneReg,
  loginnameReg,
  emailReg,

  pageSizeOptions,
  authorMap,
  cloudPurchOrderStatusMap,
  cloudStoreLevelMaps,

  orderStatusMaps,
  orderTypeMaps,
  pickingOrderSendStatusMaps,

  auditStatusMap,
  brandAgencyStatusMaps,
  importConfig,

  productPutOnMap,
  productAddMap,
  notifyTypeMaps,
  notifyReadMaps,
  merchantNotifyTypeMaps,
  iiaNoticeObjectMaps,

  logisticsWayMaps,
  expressReceiptFlags,
  expressReceiptReturnRequirements,
  expressPackWoodenFrames,
  imageMaxSizeMaps,
  shipmentUtilMap,
  saasCouierCodeMaps,
  expressTypeMaps,
  billStatusMaps,
  uploadImageRules,

  trim,
  trimAll,
  trimLine,
  repLineBreak,
  IMAGEMAXSIZE,

  skuStopFlagMaps,
  skuGoodsTypeMaps,
  enquireMatchStatusMaps,
  enquireOrderStateMaps,
  answerEnquiredStatusMaps,

  contractTemplateEnableStatusMaps,

  invoiceTypeMaps,
  invoiceOpenedStatusMaps,

  saledTypeMaps,
  saledStatusMaps,
  selfSaledStatusMaps,
  saledReasonTypeMaps,
  saledRefundTypeMaps,
  saledBackGoodsTypeMaps
}
