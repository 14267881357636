import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Row, message, Button, Icon, Tooltip, Modal } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import { getLodop } from '@/assets/plugin/LodopFuncs'
import filter from '@/common/utils/filter'
import { renderAddressLabel, renderOrderTypeLabel, renderPickingOrderSendStatusLabel, renderLogisticsWayLabel } from '@/common/utils/mUtils'

import SearchForm from '@/components/tool-form/index'
import DetailModal from './detailModal'
import PrintLogistics from '../component/print-logistics'
import PrinterModal from '@/components/printer-modal/index'

import Api from '@/common/api'
import styles from './storeout.module.scss'

const { pageSizeOptions, orderTypeMaps, pickingOrderSendStatusMaps } = filter
const { confirm, warning } = Modal

const orderTypeOptions = $lodash.filter(orderTypeMaps, (o) => { return $lodash.includes(o.id, 'STOCK') })

export default class StoreEnter extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {

        }
      },
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'endUserId',
        formType: 'Select',
        itemParams: {
          label: '客户名称'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['endUserId', 'showName']
      },
      {
        name: 'pickingStatus',
        formType: 'Select',
        itemParams: {
          label: '发货状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...pickingOrderSendStatusMaps
        ]
      },
      {
        name: 'orderType',
        formType: 'Select',
        itemParams: {
          label: '订单类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL_SPOT', name: '全部' },
          ...orderTypeOptions
        ]
      }
    ],
    query: {},
    tableData: [],
    rowDetail: {}, // 当前选择的行数据
    curOrderType: 1,
    ewaybillInfo: {}, // 物流面单信息
    courierNumberList: [], // 运单号集合
    printerModal: false, // 打印机弹窗
    detailModal: false, // 明细弹窗
    printerList: [], // 打印机列表
    expandedRowKeys: [] // 可展开的列表key
  }

  columns = [
    {
      title: '序号',
      key: 'storelist-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '订单类型',
      key: 'storelist-7',
      width: 100,
      render: (text, record, index) => (
        <>{ renderOrderTypeLabel(record.orderType) }</>
      )
    },
    {
      title: '订单日期',
      key: 'storelist-1',
      width: 140,
      dataIndex: 'orderTime',
      render: (text, record, index) => (
        <>
          { record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '订单编号',
      key: 'storelist-2',
      dataIndex: 'orderId',
      width: 140
    },
    {
      title: '客户名称',
      key: 'storelist-3',
      ellipsis: true,
      dataIndex: 'customerName'
    },
    {
      title: '联系人',
      key: 'storelist-4',
      width: 100,
      dataIndex: 'consignee'
    },
    {
      title: '联系方式',
      key: 'storelist-5',
      width: 100,
      dataIndex: 'consigneePhoneNumber'
    },
    {
      title: '收货地址',
      key: 'storelist-6',
      ellipsis: true,
      render: (text, record, index) => (
        <div title={renderAddressLabel(record.province, record.city, record.county, record.address)}>
          { renderAddressLabel(record.province, record.city, record.county, record.address) }
        </div>
      )
    },
    {
      title: '发货状态',
      key: 'storelist-8',
      width: 80,
      render: (text, record, index) => (
        <>{ renderPickingOrderSendStatusLabel(record.pickingStatus) }</>
      )
    },
    {
      title: '操作',
      key: 'storelist-9',
      width: 120,
      render: (text, record, index) => (
        <div>
          {
            record.deliverButtonStatus
              ? <Button type='primary' size='small' onClick={() => this.handleJumpToSend(record)}>去发货</Button>
              : null
          }
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getGuesteuUserLike()
    this.getStoreOutOrderList()
  }

  // 获取出库订单列表
  getStoreOutOrderList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getStoreOutOrderList(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          expandedRowKeys: [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取发货批次列表
  getSendedBatchList = (expanded, record) => {
    if (!expanded) { return false }

    Api.getSendedBatchList({ pickingId: record.pickingId }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { tableData } = this.state
        let i = tableData.findIndex((item) => { return item.pickingId === record.pickingId })

        tableData[i]['batchList'] = data || []

        this.setState({
          tableData
        })
      }
    })
  }

  // 获取物流面单信息
  getEWaybillInfo = (params) => {
    Api.getEWaybillInfo(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          ewaybillInfo: data || {}
        }, () => {
          this.lodopPrintEWaybill()
        })
      }
    })
  }

  // 模糊搜索客户
  getGuesteuUserLike = (val = '') => {
    Api.getGuesteuUserLike({ searchName: val, pageNo: 1, pageSize: 999 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state
        searchData[2].options = data.list || []

        this.setState({
          searchData
        })
      }
    })
  }

  // 查询是否可取消快递
  getAllowCancelExpress = async (record) => {
    const res = await Api.getAllowCancelExpress({ pickingId: record.pickingId, batchNum: record.batchNum })
    const { code, data } = res
    if (+code === 10000) {
      if (!data) {
        warning({
          width: 353,
          title: <><label className={`${styles.label}`}>揽件情况为</label><span>已揽件</span></>,
          content: <>很抱歉，此单快递不可以取消，请联系IIASaaS客服反馈问题</>
        })

        return false
      }

      confirm({
        title: <><label className={`${styles.label}`}>揽件情况为</label><span>未揽件</span></>,
        content: <>可以进行取消操作，是否要取消快递？</>,
        onOk: () => {
          this.saveCancelExpress({ pickingId: record.pickingId, batchNum: record.batchNum })
        }
      })
    }
  }

  // 取消物流订单
  saveCancelExpress = async (params) => {
    const res = await Api.saveCancelExpress(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      let { tableData = [] } = this.state
      let i = tableData.findIndex((row) => { return row.pickingId === params.pickingId })
      let n = tableData[i].batchList.findIndex((o) => { return o.batchNum === params.batchNum })

      tableData[i].batchList[n].logisticsOrderStatus = 'CANCEL'

      this.setState({
        tableData
      })
    } else {
      message.warning(res.message)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.orderTime) {
      search.beginDate = search.orderTime.length === 2 ? search.orderTime[0].format('YYYY-MM-DD') : undefined
      search.endDate = search.orderTime.length === 2 ? search.orderTime[1].format('YYYY-MM-DD') : undefined

      delete search.orderTime
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => this.getStoreOutOrderList())
  }

  // 点击展开、收起子列表
  handleChangeExpand = (expanded, record) => {
    let { expandedRowKeys = [] } = this.state

    this.setState({
      expandedRowKeys: expanded ? [...expandedRowKeys, record.pickingId] : $lodash.pull(expandedRowKeys, record.pickingId)
    }, () => {
      this.getSendedBatchList(expanded, record)
    })
  }

  // 查看订单明细
  handleShowDetailModal = (record) => {
    this.setState({
      rowDetail: record,
      detailModal: true
    })
  }

  // 确定修改拣货单备注
  setRowDetailRemarks = (outboundRemarks) => {
    const { tableData = [], rowDetail = {} } = this.state
    const i = tableData.findIndex((row) => { return row.pickingId === rowDetail.pickingId })
    const n = tableData[i].batchList.findIndex((o) => { return o.batchNum === rowDetail.batchNum })

    tableData[i].batchList[n].remarks = outboundRemarks

    this.setState({
      tableData: [
        ...tableData
      ],
      rowDetail: {
        ...rowDetail,
        remarks: outboundRemarks
      }
    })
  }

  // 点击去发货
  handleJumpToSend = (record) => {
    this.props.history.push({ pathname: '/admin/storeSend', state: record })
  }

  // 点击打印面单
  handlePrintEWaybill = (record) => {
    if (!record.courierNumberList || record.courierNumberList.length === 0) {
      return message.warning('暂无运单号')
    }

    let params = {
      mailNoList: record.courierNumberList,
      courierCode: record.courierCode,
      isSupplyOrder: record.orderType === 'ORDER_CLOUD_STOCK' && record.logisticsWay === 'SAAS' && record.courierCode === 'DBL'
    }

    this.setState({
      courierNumberList: record.courierNumberList
    }, () => {
      this.getEWaybillInfo(params)
    })
  }

  // 校验是否可打印 &选择打印机
  lodopPrintEWaybill = () => {
    let { printerList = [] } = this.state
    let LODOP = getLodop()

    if (!LODOP) {
      return message.error('请下载打印插件')
    }

    let printerCount = LODOP.GET_PRINTER_COUNT() || 0

    if (printerCount === 0) {
      return message.warning('暂无打印机，请确认是否已连接打印机')
    }

    for (let i = 0; i < printerCount; i++) {
      printerList.push(LODOP.GET_PRINTER_NAME(i))
    }

    this.setState({
      printerList,
      printerModal: true
    })
  }

  // 选择打印机确认
  onConfirmPriter = (name) => {
    this.setState({
      printerModal: false,
      printerList: []
    }, () => {
      this.printEWayBill(name)
    })
  }

  // 打印
  printEWayBill = (name) => {
    let ewayHtml = document.getElementById('expressPrintArea').innerHTML
    let LODOP = getLodop()
    const { courierNumberList = [] } = this.state
    for (let i = 0; i < courierNumberList.length; i++) {
      LODOP.PRINT_INIT('初始化打印') // 打印初始化
      LODOP.SET_PRINTER_INDEXA(name)
      LODOP.SET_PRINT_PAGESIZE(0, '75mm', '130mm', 'CreateCustomPage')
      LODOP.SET_SHOW_MODE('BKIMG_WIDTH', '75mm')
      LODOP.SET_SHOW_MODE('BKIMG_HEIGHT', '130mm')
      LODOP.SET_PRINT_MODE('CUSTOM_TASK_NAME', 'EWaybill' + i)

      LODOP.ADD_PRINT_TABLE(0, 0, '75mm', '130mm', ewayHtml)
      LODOP.ADD_PRINT_BARCODE('61mm', '12mm', '50mm', '18mm', '128B', courierNumberList[i])

      LODOP.PRINT()
    }
  }

  // 点击重新发货
  handleLinkToStoreSend = (record) => {
    this.props.history.push({ pathname: '/admin/storeSend', state: { ...record, type: 'redispatched' } })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      detailModal: false,
      printerModal: false,
      rowDetail: {}
    })
  }

  // 渲染说明内容
  renderIIATooltipInner = () => {
    return <div>
      <h4 style={{ color: '#ffffff' }}>IIA快递核算方法：</h4>
      <p>1：快递费用是德邦直接输出数据。</p>
      <p>2：标准快递&大件快递：一般3公斤以下是标准快递，3公斤以上是大件快递。因为这样计算快递费更少。建议3公斤以上发大件快递</p>
      <p>3：价格采用四舍五入整数：例如：10.4元收取10元。10.6元收取11元。</p>
      <p>4：快递费用计算：以5公斤为例子：</p>
      <p>A：标准快递：首重1公斤 10+续重4公斤4*2=18  18*0.67=12.06  收12元</p>
      <p>B：大件快递：首重3公斤13+续重1.43*2=15.86  15.86*0.67=10.63 收11元</p>
    </div>
  }

  // 渲染展开子节点内容
  renderExpandRow = (row) => {
    const rowColumns = [
      {
        title: '发货批次',
        key: `expandRow-${row.pickingId}-0`,
        dataIndex: 'batchNum'
      },

      {
        title: '发货人',
        key: `expandRow-${row.pickingId}-0111`,
        dataIndex: 'operatorName'
      },
      {
        title: '发货方式',
        key: `expandRow-${row.pickingId}-1`,
        render: (text, record, index) => {
          return (
            <div>
              {
                row.orderType !== 'ORDER_CLOUD_STOCK_PROCUREMENT' && record.logisticsOrderStatus === 'CANCEL'
                  ? '----'
                  : renderLogisticsWayLabel(record.logisticsWay)
              }
            </div>
          )
        }
      },
      {
        title: '快递公司',
        key: `expandRow-${row.pickingId}-2`,
        render: (text, record, index) => (
          <div>
            {
              row.orderType !== 'ORDER_CLOUD_STOCK_PROCUREMENT' && record.logisticsOrderStatus === 'CANCEL'
                ? '----'
                : <>{ record.courierName && record.courierName !== '' ? record.courierName : '----' }</>
            }
          </div>
        )
      },
      {
        title: '运单号',
        key: `expandRow-${row.pickingId}-3`,
        render: (text, record, index) => (
          <div>
            {
              row.orderType !== 'ORDER_CLOUD_STOCK_PROCUREMENT' && record.logisticsOrderStatus === 'CANCEL'
                ? '----'
                : <>{ record.courierNumberList && record.courierNumberList.length > 0 ? record.courierNumberList.join(',') : '----' }</>
            }
          </div>
        )
      },
      {
        title: '发货时间',
        key: `expandRow-${row.pickingId}-4`,
        dataIndex: 'createTime',
        render: (text, record, index) => (
          <div>
            {
              row.orderType !== 'ORDER_CLOUD_STOCK_PROCUREMENT' && record.logisticsOrderStatus === 'CANCEL'
                ? '----'
                : <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
            }
          </div>
        )
      },
      {
        title: '发货地址',
        key: `expandRow-${row.pickingId}-6`,
        render: (text, record, index) => (
          <>
            {
              row.orderType !== 'ORDER_CLOUD_STOCK_PROCUREMENT' && record.logisticsOrderStatus === 'CANCEL'
                ? '----'
                : <p title={renderAddressLabel(record.province, record.city, record.area, record.address)}>
                  { renderAddressLabel(record.province, record.city, record.county, record.address) }
                </p>
            }
          </>
        )
      },

      {
        title: '操作',
        key: `expandRow-${row.pickingId}-5`,
        render: (text, record, index) => (
          <div>
            <Button type='link' size='small' onClick={() => this.handleShowDetailModal({ ...record, customerName: row.customerName, orderId: row.orderId, orderTime: row.orderTime, outboundOrderNo: row.outboundOrderNo })}>
              查看详情
            </Button>

            {
              row.orderType !== 'ORDER_CLOUD_STOCK_PROCUREMENT' && record.logisticsWay === 'SAAS' && record.courierCode === 'DBL'
                ? <Button type='link' size='small' onClick={() => this.handlePrintEWaybill({ ...record, orderType: row.orderType })}>打印面单</Button>
                : null
            }

            {
              row.orderType !== 'ORDER_CLOUD_STOCK_PROCUREMENT' && record.logisticsOrderStatus === 'CREATE'
                ? <Button type='link' size='small' onClick={() => this.getAllowCancelExpress(record)}>取消快递</Button>
                : null
            }

            {
              row.orderType !== 'ORDER_CLOUD_STOCK_PROCUREMENT' && record.logisticsOrderStatus === 'CANCEL'
                ? <Button type='link' size='small' onClick={() => this.handleLinkToStoreSend(record)}>重新发货</Button>
                : null
            }

          </div>
        )
      }
    ]

    return (
      <Row style={{ padding: '2px 5px' }}>
        <Table rowKey='batchNum'
          columns={rowColumns}
          dataSource={row.batchList}
          pagination={false}
        />
      </Row>
    )
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getStoreOutOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getStoreOutOrderList()
    })
  }

  render () {
    const { tableData, expandedRowKeys = [], pages, searchData, rowDetail = {}, printerModal = false, detailModal = false,
      ewaybillInfo = {}, printerList = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ marginBottom: '10px' }}>
            <Tooltip title={this.renderIIATooltipInner()}placement='bottom'>
              <span>
                <span style={{ marginRight: '5px' }}>IIA快递说明</span>
                <Icon type='question-circle'
                  style={{ fontSize: '16px' }}
                />
              </span>
            </Tooltip>

          </Row>

          <Row>
            <Table rowKey='pickingId'
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination}
              expandedRowKeys={expandedRowKeys}
              onExpand={(expanded, record) => this.handleChangeExpand(expanded, record)}
              expandedRowRender={(record) => this.renderExpandRow(record)}
              scroll={{ x: 1200 }}
            />
          </Row>
        </Card>

        {
          detailModal
            ? <DetailModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
              onConfirmOutboundRemarks={(outboundRemarks) => this.setRowDetailRemarks(outboundRemarks)}
            />
            : null
        }

        <div>
          <PrintLogistics ewaybillInfo={ewaybillInfo} orderInfo={rowDetail} />
        </div>

        {
          printerModal && printerList.length > 0
            ? <PrinterModal
              list={printerList}
              onConfirm={(name) => this.onConfirmPriter(name)}
              onCancel={this.closeModal}
            />
            : null
        }

      </>
    )
  }
}
