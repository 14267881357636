import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table, Row, Col } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'

import Api from '@/common/api/index'
// import filter from '@/common/utils/filter'

// const { pageSizeOptions } = filter

export default class ProductModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌...',
          autoComplete: 'off',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showName']
      },
      {
        name: 'model',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号或订货号...',
          autoComplete: 'off'
        }
      }
    ],
    query: {},
    tableData: [],
    selectedRowKeys: []
  }
  columns = [
    {
      title: '序号',
      key: 'productCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'productCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'productCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'productCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'productCol-4',
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'productCol-5',
      dataIndex: 'unit'
    }
  ]

  componentDidMount () {
    this.getBrandList()
  }

  // 获取品牌
  getBrandList = () => {
    Api.getBrandList({ brandListType: 'ALL_BRAND', pageNo: 1, pageSize: 999 }).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData = [] } = this.state

        data.map(item => {
          item['showName'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[0].options = data

        this.setState({
          searchData: searchData
        })
      }
    })
  }

  // 获取可添加的产品列表
  getProductListInCorrectEnquireDetails = async () => {
    const { query = {} } = this.state
    const res = await Api.getProductListInCorrectEnquireDetails({ ...query })
    const { code, data } = res

    if (+code === 10000) {
      this.setState({
        tableData: data || []
      })
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    this.setState({
      query: search || {}
    }, () => {
      this.getProductListInCorrectEnquireDetails()
    })
  }

  // 点击确定
  handleConfirm = () => {
    const { detailInfo = {}, onConfirm } = this.props
    const { selectedRows = [] } = this.state
    const selectedObj = selectedRows[0]

    const params = {
      ...detailInfo,
      collaborateState: 'NO_QUOTATION',
      enquiryCollaborateId: 0,
      dataType: 'SEARCH',
      matchingState: 'MATCHED',
      commoditySkuId: selectedObj.commoditySkuId,
      brandId: selectedObj.brandId,
      brandName: selectedObj.brandName,
      model: selectedObj.model,
      itemNumber: selectedObj.itemNumber,
      name: selectedObj.name,
      unit: selectedObj.unit
    }

    onConfirm(params)
  }

  // 点击选择产品
  onSelectChange = (keys, rows) => {
    this.setState({
      selectedRows: rows
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getProductListInCorrectEnquireDetails()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getProductListInCorrectEnquireDetails()
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { searchData, tableData = [], selectedRows = [] } = this.state

    // 分页配置
    // const pagination = {
    //   size: 'small',
    //   showSizeChanger: true,
    //   showQuickJumper: true,
    //   total: pages.total,
    //   showTotal: (total) => { return `共 ${total} 条` },
    //   current: pages.pageNo,
    //   pageSize: pages.pageSize,
    //   pageSizeOptions: pageSizeOptions,
    //   onChange: this.pageChange,
    //   onShowSizeChange: this.pageSizeChange
    // }

    const selectedRowKeys = $lodash.map(selectedRows, (o) => { return o.commoditySkuId })

    const rowSelection = {
      columnWidth: 44,
      type: 'radio',
      selectedRowKeys: selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <Modal title='修正产品' width={1200} bodyStyle={{ padding: '16px', height: '500px', overflowY: 'auto' }}
        visible
        maskClosable={false}
        onCancel={() => this.props.onCancel()}
        onOk={() => this.handleConfirm()}
      >
        <Row gutter={12} style={{ marginBottom: '15px' }}>
          <Col span={4} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            <label className={`label`}>品牌</label>
            <span title={detailInfo.brandName}>{ detailInfo.brandName }</span>
          </Col>
          <Col span={6} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            <label className={`label`}>型号</label>
            <span title={detailInfo.model}>{ detailInfo.model }</span>
          </Col>
          <Col span={6} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            <label className={`label`}>订货号</label>
            <span title={detailInfo.itemNumber}>{ detailInfo.itemNumber }</span>
          </Col>
          <Col span={6} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            <label className={`label`}>产品名称</label>
            <span title={detailInfo.name}>{ detailInfo.name }</span>
          </Col>
          <Col span={2} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            <label className={`label`}>单位</label>
            <span title={detailInfo.unit}>{ detailInfo.unit }</span>
          </Col>
        </Row>

        <div style={{ marginBottom: '12px' }}>
          <SearchForm {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </div>

        <Table rowKey='commoditySkuId' bordered
          columns={this.columns}
          dataSource={tableData}
          rowSelection={rowSelection}
          pagination={false}
        />
      </Modal>
    )
  }
}
