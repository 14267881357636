import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table } from 'antd'
import moment from 'moment'

import Api from '@/common/api'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

class logsmodal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
    // onConfirm: PropTypes.func
  }
  state = {
    tableData: [],
    pages: { total: 0, pageNo: 1, pageSize: 20 }
  }
  columns=[
    {
      title: '序号',
      key: 'key-1',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '操作人',
      key: 'productLibraryt-1',
      width: 80,
      ellipsis: true,
      dataIndex: 'createName'
    },

    {
      title: '产品状态',
      key: 'productLibrary-2',
      width: 160,
      ellipsis: true,
      render: (text, record, index) => (
        <div >
          <span>{`现货${record.stockStatus === 'ENABLE' ? '已上架' : '未上架'}`}</span>
          <span style={{ marginLeft: '10px' }}>{ `期货${record.futuresStatus === 'ENABLE' ? '已上架' : '未上架'}`}</span>
        </div>
      )
    },
    {
      title: '操作时间',
      key: 'productLibrary-3',
      width: 150,
      ellipsis: true,
      render: (text, record, index) => (
        <div>
          { moment(record.createTime).format('YYYY-MM-DD HH:ss:mm')}
        </div>
      )
    },
    {
      title: '备注',
      key: 'key-3',
      ellipsis: true,
      dataIndex: 'remarks'
    }
  ]
  componentDidMount () {
    this.productlistPageLog()
  }

  productlistPageLog = () => {
    const { detailInfo } = this.props
    const { pages } = this.state

    const params = {
      commoditySkuId: detailInfo.commoditySkuId,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.productlistPageLog(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.productlistPageLog()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.productlistPageLog()
    })
  }
  render () {
    const { onCancel } = this.props
    const { tableData, pages } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <Modal title='操作日志' width={800}
        visible
        onCancel={() => onCancel()}
      >
        <Table rowKey={(item, i) => { return `${item.createTime}-${i}` }}
          columns={this.columns}
          dataSource={tableData}
          pagination={pagination}
          scroll={{ y: 500 }}
        />
      </Modal>
    )
  }
}

export default logsmodal
