import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Tabs } from 'antd'

import CommonPrice from './commonPrice'
import CustomerPrice from './customerPrice'
import filter from '@/common/utils/filter'

import styles from './index.module.scss'

const { importConfig } = filter

const { TabPane } = Tabs
const linkMaps = [
  '',
  '/admin/priceSetting',
  '/admin/cuspriceSetting',
  '/admin/pricegroupSetting'
]

class PriceSetting extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    activeKey: 'COMMONPRICE' // 当前显示的模块
  }

  componentDidMount () {

  }

  handleChangeTabPane = (key) => {
    this.setState({
      activeKey: key
    })
  }

  // 点击按钮
  handleJumpToLink = (i, query = {}) => {
    if (i === 0) {
      return false
    }

    this.props.history.push({ pathname: linkMaps[i], state: { ...query } })
  }

  // 监听上传成功
  changeUploadSuccess = (params) => {
    let config = {
      pageSourch: 'priceSetting',
      sheetSourch: importConfig['priceSetting'].sheetSourch
    }

    this.props.history.push({ pathname: '/admin/sheetsConfirm', state: { config: config, data: { importId: params } } })
  }

  render () {
    const { activeKey = 'COMMONPRICE' } = this.state

    return (
      <div>
        <Row style={{ marginBottom: '16px' }}>
          <Card bodyStyle={{ padding: '12px 0px 0px 0px' }}>
            <Row className={`${styles.tabWrap}`}>
              <Tabs onChange={this.handleChangeTabPane}>
                <TabPane tab='通用价格' key='COMMONPRICE' />
                <TabPane tab='客户价格' key='CUSPRICE' />
              </Tabs>
            </Row>
          </Card>
        </Row>

        {
          activeKey === 'COMMONPRICE'
            ? <CommonPrice history={this.props.history} />
            : null
        }

        {
          activeKey === 'CUSPRICE'
            ? <CustomerPrice history={this.props.history} />
            : null
        }

      </div>
    )
  }
}

export default PriceSetting
