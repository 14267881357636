/**
 * 合同管理模块
 */
import ContractList from '@/pages/contract/contract-list/index' // 合同列表
import ContractTemps from '@/pages/contract/contract-temp/index' // 合同模板管理

export default [
  {
    path: '/admin/contractList',
    name: '合同管理',
    component: ContractList
  },
  {
    path: '/admin/contractTemp',
    name: '合同模板管理',
    component: ContractTemps
  }
]
