import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, Row, Col, Badge, Button } from 'antd'

export default class UploadModal extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onDownload: PropTypes.func,
    totalNumber: PropTypes.number,
    failNumber: PropTypes.number,
    successNumber: PropTypes.number,
    list: PropTypes.array,
    type: PropTypes.number,
    hasDownBtn: PropTypes.number
  }
  state = {}

  renderUploadFooter = () => {
    const { hasDownBtn = 0, list = [] } = this.props
    let footer = [
      <Button type='primary' key={`failBtn-3`} onClick={this.props.onCancel}>确定</Button>,
      <Button key={`failBtn-2`} onClick={this.props.onCancel}>取消</Button>
    ]

    if (+hasDownBtn === 1 && list.length > 0) {
      footer.push(<Button type='primary' key={`failBtn-1`}
        onClick={this.props.onDownload}
      >
        导出失败明细
      </Button>)
    }

    footer.reverse()

    return footer
  }

  render () {
    const { list = [], totalNumber = 0, failNumber = 0, successNumber = 0, type = 0 } = this.props
    return (
      <div>
        <Modal
          width={800}
          title={`失败详情`}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          footer={this.renderUploadFooter()}
        >
          <Row>
            <Col span={8}>
              <Badge status='warning' text={`${type === 1 ? '搜索' : '导入'}总数: ${totalNumber}`} />
            </Col>

            <Col span={8}>
              <Badge status='success' text={`${type === 1 ? '搜索' : '导入'}成功: ${successNumber}`} />
            </Col>

            <Col span={8}>
              <Badge status='error' text={`${type === 1 ? '搜索' : '导入'}失败: ${failNumber}`} />
            </Col>
          </Row>
          {
            list.length > 0
              ? <Row style={{ maxHeight: '400px', overflow: 'auto', paddingRight: '16px' }}>
                {
                  list.map((item, i) => (
                    <div key={`failItemNumber-${i}`}
                      style={{ padding: '0px 10px 5px 10px' }}
                    >{item}</div>
                  ))
                }
              </Row>
              : <Row style={{ padding: '32px' }}>
                <p style={{ textAlign: 'center' }}>暂无数据</p>
              </Row>
          }

        </Modal>
      </div>
    )
  }
}
