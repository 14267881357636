import Ajax from '../utils/ajax'

export default {
  // 获取报价单模板列表
  getQuoteTempList (params) {
    return Ajax.post('/api/quotation/template/list/page/quotationTemplate', params)
  },

  // 获取报价单模板详情
  getQuoteTempDetail (params) {
    return Ajax.post('/api/quotation/template/getQuotationTemplateInfo', params)
  },

  // 新增报价单模板
  saveAddQuoteTemp (params) {
    return Ajax.post('/api/quotation/template/addQuotationTemplate', params)
  },

  // 编辑报价单模板
  saveEditQuoteTemp (params) {
    return Ajax.post('/api/quotation/template/updateQuotationTemplate', params)
  },

  // 删除报价单模板
  deleteQuoteTemp (params) {
    return Ajax.post('/api/quotation/template/deleteQuotationTemplate', params)
  },

  // 启用/禁用报价单模板
  updateQuoteTempEnableStatus (params) {
    return Ajax.post('/api/quotation/template/updateQuotationTemplateStatus', params)
  },

  // 获取报价单自定义选项
  getQuoteTemplateFieldMap (params) {
    return Ajax.get('/api/quotation/template/getQuotationTemplateFieldMap', { params })
  }
}
