import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

const tabMaps = [
  { id: 1, name: '加入购物车' },
  { id: 2, name: '确认订单信息' },
  { id: 3, name: '支付订单' }
]

class CartSteps extends Component {
  static propTypes = {
    type: PropTypes.number
  }

  state = {

  }

  render () {
    const { type } = this.props

    return (
      <div className={styles.steps}>
        {
          tabMaps.map((item, i) => (
            <div key={`stepItem-${i}`} className={`${styles.stepsItem} ${type === item.id ? styles.active : ''}`}>
              <div className={`${styles.itemContainer}`}>
                <div className={`${styles.itemIcon}`}>
                  <span className={`${styles.iconInner}`}>{ item.id }</span>
                </div>

                <div className={`${styles.itemTitle}`}>{ item.name }</div>

                <div className={`${styles.itemTail}`} style={{ display: i === tabMaps.length - 1 ? 'none' : 'block' }}>
                  <div className={`${styles.tailContent}`}>
                    <span className={`${styles.tail}`} />
                    <span className={`${styles.tail}`} />
                    <span className={`${styles.tail}`} />
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    )
  }
}

export default CartSteps
