import axios from 'axios'
import config from '@/common/utils/config'
import { getStore, removeStore } from './mUtils'
import configureStore from './configureStore'
import XLSX from 'xlsx'
import { message } from 'antd'
const { store } = configureStore()

const { host } = config

// 创建a标签下载文件
const createDownload = (blob, fileName) => {
  if ('download' in document.createElement('a')) { // 非IE下载
    const elink = document.createElement('a')
    elink.download = fileName
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href)// 释放URL 对象
    document.body.removeChild(elink)
  } else { // IE10+下载
    navigator.msSaveBlob(blob, fileName)
  }
}

// 字符串转为字符流
export const stringToBuffer = (str) => {
  let buf = new ArrayBuffer(str.length)
  let view = new Uint8Array(buf)
  for (var i = 0; i !== str.length; ++i) {
    view[i] = str.charCodeAt(i) & 0xFF
  }
  return buf
}

export const downloadFileByGet = (url, params) => {
  axios({
    method: 'get',
    url: `${host}${url}`,
    params: params,
    headers: {
      'Token': getStore('token') || ''
    },
    responseType: 'blob'
  }).then(res => {
    const fileName = decodeURI(res.headers['content-disposition'].match(/filename=(.*)/)[1])
    const blob = new Blob([res.data])
    createDownload(blob, fileName)
  }).catch(error => {
    if (error.response) {
      const status = error.response.status
      var reader = new FileReader()
      reader.onload = function (event) {
        const data = JSON.parse(reader.result)// 内容就在这里
        switch (status) {
          case 400:
            message.warning(data.message)
            break
          case 401:
            removeStore('token')
            store.dispatch({ type: 'SET_USER_INFO', data: { userInfo: {} } })
            window.location.href = '/login'
            break
          case 500:
            message.error('服务器开小差了，请稍后再试！')
            break
        }
      }
      reader.readAsText(error.response.data)
    } else if (error.request) {
    } else {
      message.warning(error.message)
    }
  })
}

// 测试
export const downloadFileByPost = (url, params) => {
  axios({
    method: 'post',
    url: `${host}${url}`,
    data: params,
    headers: {
      'Token': getStore('token') || ''
    },
    responseType: 'blob'
  }).then(res => {
    const fileName = decodeURI(res.headers['content-disposition'].match(/filename=(.*)/)[1])
    const blob = new Blob([res.data])
    createDownload(blob, fileName)
  }).catch(error => {
    if (error.response) {
      const status = error.response.status
      let reader = new FileReader()
      reader.readAsText(error.response.data)
      reader.onload = function (event) {
        const data = JSON.parse(reader.result)// 内容就在这里
        switch (status) {
          case 400:
            message.warning(data.message)
            break
          case 401:
            removeStore('token')
            store.dispatch({ type: 'SET_USER_INFO', data: { userInfo: {} } })
            window.location.href = '/login'
            break
          case 500:
            message.error('服务器开小差了，请稍后再试！')
            break
        }
      }
    } else if (error.request) {
    } else {
      message.warning(error.message)
    }
  })
}

/**
 * 前端导出EXCEL
 */
export const downloadExcel = (data, name) => {
  let sheet = XLSX.utils.aoa_to_sheet(data)
  let sheetName = name || 'sheet1'
  let workbook = {
    SheetNames: [sheetName],
    Sheets: {}
  }
  workbook.Sheets[sheetName] = sheet

  let wopts = {
    bookType: 'xlsx',
    bookSST: false,
    type: 'binary'
  }

  let wbout = XLSX.write(workbook, wopts)

  let blob = new Blob([stringToBuffer(wbout)], { type: 'application/octet-stream' })

  let elink = document.createElement('a')
  elink.href = URL.createObjectURL(blob)
  elink.download = name
  document.body.appendChild(elink)
  elink.click()
  URL.revokeObjectURL(elink.href) // 释放URL 对象
  document.body.removeChild(elink)
}
