import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Form, Input, Upload, Icon, message } from 'antd'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'

import styles from './setfloor.module.scss'

const { uploadOrdinaryImageUrl } = UploadApi
const FormItem = Form.Item
const { TextArea } = Input
const SIZE = 2
const MAXLEN = 2
const imgSizeTips = [
  { type: 1, tipText: `图片宽为400px, 图片大小不超过${SIZE}MB` },
  { type: 2, tipText: `图片宽为1200px, 图片大小不超过${SIZE}MB` },
  { type: 3, tipText: `图片尺寸为 240px * 620px, 图片大小不超过${SIZE}MB` },
  { type: 4, tipText: `图片尺寸为 400px * 400px, 图片大小不超过${SIZE}MB` }
]

class SetfloorForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number, // 1-新增 2-编辑
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    editInfo: {},
    imageList: [], // 图片列表
    remark: '' // 文案
  }

  componentDidMount () {
    const { type = 1 } = this.props
    if (type === 2) {
      this.getNavFloorContainer()
    }
  }

  // 获取楼层内容
  getNavFloorContainer = () => {
    const { detailInfo = {} } = this.props
    Api.getNavFloorContainer({ id: detailInfo.id }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { imageList, remark = '' } = this.state
        if (data && data.elementList && data.elementList.length > 0) {
          data.elementList.map((item, i) => {
            if (item.imageUrl && item.imageUrl !== '') {
              imageList.push(item.imageUrl)
            }
          })

          remark = data.elementList[0].remark
        }

        this.setState({
          editInfo: data || {},
          imageList,
          remark
        })
      }
    })
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {} } = this.props
        let { imageList = [] } = this.state
        let arr = []

        if (imageList.length === 0) {
          return message.warning('请上传图片')
        }

        imageList.map(item => {
          if (item && item !== '') {
            arr.push({
              imageUrl: item,
              remark: values.remark
            })
          }
        })

        this.props.onConfirm({ id: detailInfo.id, elementList: arr })
      }
    })
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < SIZE
    if (!isLt1M) {
      message.warning(`图片大小不可超过${SIZE}MB`)
    }

    return isLt1M
  }

  // 上传图片
  handleChangeUpload = (info, i) => {
    if (info.file.status === 'done') {
      let { imageList = [] } = this.state
      const { data } = info.file.response

      imageList.push(data.imageUrl)

      this.setState({
        imageList
      })
    }
  }

  // 删除图片
  handleRemoveImage = (i) => {
    let { imageList = [] } = this.state

    imageList.splice(i, 1)

    this.setState({
      imageList
    })
  }

  // 上传图片文字提示
  renderImgSizeTips = (type) => {
    const obj = imgSizeTips.find(item => { return item.type === type })
    const text = obj !== undefined ? obj.tipText : ''

    return text
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { imageList = [], remark = '' } = this.state

    return (
      <div>
        <Modal title='编辑楼层' bodyStyle={{ padding: '12px' }}
          width={800}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Row style={{ marginBottom: '10px', color: 'rgb(229, 0, 79)' }}>
            <span>建议：{this.renderImgSizeTips(1)}</span>
          </Row>
          <Row className={`${styles.editRow}`}>
            {
              imageList.length > 0
                ? <div className={styles.uploadArea}>
                  {
                    imageList.map((item, i) => (
                      <div className={styles.thumbWrap} key={`imgdetailkey-${i}`}>
                        <div className={styles.thumbInfo}>
                          <img src={item} />

                          <a className={styles.prewModal}>
                            <Icon type='delete' style={{ fontSize: '20px' }}
                              onClick={() => this.handleRemoveImage(i)}
                            />
                          </a>
                        </div>
                      </div>
                    ))
                  }
                </div>
                : null
            }

            {
              imageList.length < MAXLEN
                ? <div className={styles.uploadArea}>
                  <Upload
                    showUploadList={false}
                    listType='picture-card'
                    accept='image/png, image/jpg, image/jpeg'
                    headers={{ Token: getStore('token') }}
                    action={uploadOrdinaryImageUrl}
                    beforeUpload={this.beforeUpload}
                    onChange={(info) => this.handleChangeUpload(info)}
                  >
                    <div>
                      <Icon type='plus' />
                      <div style={{ fontSize: '12px' }}>点击上传</div>
                    </div>

                  </Upload>
                </div>
                : null
            }

            <div className={styles.inputArea}>
              <Form>
                <FormItem label='文案' style={{ marginBottom: '0px' }}>
                  {
                    getFieldDecorator('remark', {
                      initialValue: remark || '',
                      rules: [
                        { required: true, message: '请填写文案' }
                      ]
                    })(
                      <TextArea rows={4} placeholder='请输入...' autoComplete='off' />
                    )
                  }
                </FormItem>
              </Form>
            </div>

          </Row>
        </Modal>
      </div>
    )
  }
}

const SetfloorModal = Form.create()(SetfloorForm)

export default SetfloorModal
