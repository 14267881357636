import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Descriptions } from 'antd'

import { renderAddressLabel, formatBankAccount } from '@/common/utils/mUtils'
import styles from './index.module.scss'

// 发票抬头信息
export default class InvoiceAmount extends Component {
  static propTypes = {
    invoiceInfo: PropTypes.object
  }
  render () {
    const { invoiceInfo = {} } = this.props

    return (
      <div className={`${styles.invoiceAmount}`}>
        <Descriptions bordered column={1}>
          <Descriptions.Item label='发票抬头（公司名称）'>{ invoiceInfo.invoiceTitle }</Descriptions.Item>
          <Descriptions.Item label='纳税人识别号'>{ invoiceInfo.creditNo }</Descriptions.Item>
          <Descriptions.Item label='单位地址、电话'>
            <p>{renderAddressLabel(invoiceInfo.province, invoiceInfo.city, invoiceInfo.county, invoiceInfo.address)}</p>
            <p>{ invoiceInfo.tel }</p>
          </Descriptions.Item>
          <Descriptions.Item label='开户行及账号'>
            <p>{ invoiceInfo.bankName }</p>
            <p>{ invoiceInfo && invoiceInfo.bankCard ? formatBankAccount(invoiceInfo.bankCard) : null }</p>
          </Descriptions.Item>
        </Descriptions>
      </div>
    )
  }
}
