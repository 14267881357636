import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Button, message, Descriptions, Alert } from 'antd'

import Api from '@/common/api/index'

import styles from './orderpay.module.scss'

const descMaps = [
  '1、付款金额务必与订单金额保持一致，支付成功后将直接关联订单，订单状态自动切换成已支付状态',
  '2、付款金额务必与左侧付款账户保持一致，切勿付款到任何线下等方式联系的其他账号',
  '3、如果订单金额与付款金额不一致，付款金额将会自动转入账户余额',
  '4、多次支付后，余额金额满足订单金额后，再订单页面点确认支付，将会自动关联订单并完成支付'
]

class OrderPay extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    orderInfo: {}, // 订单信息
    bankInfo: {// 银行信息

    }
  }

  componentDidMount () {
    const { state } = this.props.history.location

    if (!state) {
      return message.warning('页面参数不合法！')
    }

    this.setState({
      orderInfo: state || {}
    }, () => {
      this.getOfflinePayInfo()
    })
  }

  // 获取线下支付信息
  getOfflinePayInfo = () => {
    const { orderInfo = {} } = this.state
    let params = {
      orderId: orderInfo.orderId
    }

    Api.getOfflinePayInfo(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          bankInfo: data || {}
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 点击返回
  handleBack = () => {
    this.props.history.goBack()
  }

  // 渲染注意事项html
  renderAlertDescription = () => {
    return (
      <div>
        <ul>
          {
            descMaps.map((item, i) => (
              <li key={`desc-${i}`}
                style={{ marginBottom: '5px', listStyle: 'none' }}
              >{item}</li>
            ))
          }
        </ul>
      </div>
    )
  }

  render () {
    const { bankInfo = {}, orderInfo = {} } = this.state

    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Button icon='left' onClick={this.handleBack}>返回列表</Button>
        </Row>

        <Row gutter={16}>
          <Col span={14}>
            <Card>
              <Row style={{ marginBottom: '20px' }}>
                <Descriptions>
                  <Descriptions.Item label='订单金额'>
                    &yen;{(+orderInfo.payableAmount * 100 / 100).toFixed(2)}
                  </Descriptions.Item>

                  <Descriptions.Item label='余额支付'>
                    &yen;{(+orderInfo.balanceDeduction * 100 / 100).toFixed(2)}
                  </Descriptions.Item>

                  <Descriptions.Item label='应付金额'>
                    &yen;{(((+orderInfo.currentPayAmount * 100) - (+orderInfo.balanceDeduction * 100)) / 100).toFixed(2)}
                  </Descriptions.Item>
                </Descriptions>
              </Row>
              <Row className={`${styles.tableView}`}>
                <table style={{ width: '100%' }}>
                  <thead />
                  <tbody>
                    <tr className={`${styles.tableRow}`}>
                      <td className={`${styles.rowLabel}`}>
                        <div>收款方</div>
                      </td>
                      <td className={`${styles.rowCon}`}>
                        <div>{bankInfo.payee}</div>
                      </td>
                    </tr>

                    <tr className={`${styles.tableRow}`}>
                      <td className={`${styles.rowLabel}`}>
                        <div>收款账号</div>
                      </td>
                      <td className={`${styles.rowCon}`}>
                        {
                          bankInfo.receivableAccount
                            ? <div>
                              {bankInfo.receivableAccount.replace(/[\s]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')}
                            </div>
                            : null
                        }

                      </td>
                    </tr>

                    <tr className={`${styles.tableRow}`}>
                      <td className={`${styles.rowLabel}`}>
                        <div>银行行号</div>
                      </td>
                      <td className={`${styles.rowCon}`}>
                        <div>{bankInfo.bank}</div>
                      </td>
                    </tr>

                    <tr className={`${styles.tableRow}`}>
                      <td className={`${styles.rowLabel}`}>
                        <div>开户行</div>
                      </td>
                      <td className={`${styles.rowCon}`}>
                        <div>{bankInfo.openBank}</div>
                      </td>
                    </tr>

                    <tr className={`${styles.tableRow}`}>
                      <td className={`${styles.rowLabel}`}>
                        <div>订单编号</div>
                      </td>
                      <td className={`${styles.rowCon}`}>
                        <div>{orderInfo.orderId}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Row>
            </Card>
          </Col>
          <Col span={10}>
            <Card>
              <Alert type='error'
                message='注意事项'
                description={this.renderAlertDescription()}
              />
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default OrderPay
