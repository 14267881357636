import React, { Component } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { Modal, Row, Form, Input, Radio, Button, message } from 'antd'

import Api from '@/common/api/index'

import styles from './contract.module.scss'

const FormItem = Form.Item

class SendForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    endUserId: PropTypes.number,
    orderId: PropTypes.number,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    demanderCover: {}, // 合同委托人信息
    receivedInfo: {}, // 订单收件人信息
    step: 1,
    query: {}
  }

  componentDidMount () {
    const { endUserId, orderId } = this.props

    axios.all([Api.getContractDemanderInfo({ endUserId }), Api.getEuorderReceivedInfo({ orderId: orderId })])
      .then(axios.spread((demandRes, receivedRes) => {
        if (+demandRes.code === 10000 && +receivedRes.code === 10000) {
          this.setState({
            demanderCover: demandRes.data || {},
            receivedInfo: receivedRes.data || {}
          })
        } else {
          message.error('获取数据失败')
        }
      }))
  }

  // 查询合同委托人信息
  getContractDemanderInfo = () => {
    const { endUserId } = this.props
    Api.getContractDemanderInfo({ endUserId }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          demanderCover: data || {}
        })
      }
    })
  }

  // 获取订单收件人信息
  getEuorderReceivedInfo = () => {
    const { orderId } = this.props
    Api.getEuorderReceivedInfo({ orderId: orderId }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          receivedInfo: data || {}
        })
      }
    })
  }

  // 点击下一步
  handleNext = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          query: values,
          step: 2
        })
      }
    })
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { orderId } = this.props
        const { query = {} } = this.state
        this.props.onConfirm({ ...query, ...values, orderId })
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { step = 1, demanderCover = {}, receivedInfo = {} } = this.state

    return (
      <div>
        <Modal closable={false}
          visible
          width={500}
          maskClosable={false}
          onCancel={() => this.props.onCancel()}
          footer={
            [
              <Button key={`cancel-0`} onClick={this.props.onCancel}>取消</Button>,
              <Button type='primary' key={`confirm-0`} onClick={step === 1 ? this.handleNext : this.handleConfirm}>
                确定
              </Button>
            ]
          }
          onOk={() => this.handleConfirm()}
        >
          <Row style={{ paddingTop: '30px', width: '400px', margin: '0px auto' }}>
            <Form>
              {
                step === 1
                  ? <Row>
                    <Row className={`${styles.formitemRow}`}>
                      <FormItem label='自定义合同编号' style={{ marginBottom: '0px' }}>
                        {
                          getFieldDecorator('isCustom', {
                            initialValue: 0
                          })(
                            <Radio.Group>
                              <Radio value={0}>否</Radio>
                              <Radio value={1}>是</Radio>
                            </Radio.Group>
                          )
                        }
                      </FormItem>
                    </Row>

                    {
                      +this.props.form.getFieldValue('isCustom') === 1
                        ? <Row className={`${styles.formitemRow}`}>
                          <FormItem>
                            {
                              getFieldDecorator('contractNumber', {
                                rules: [
                                  { required: true, message: '请输入合同编号' }
                                ]
                              })(
                                <Input placeholder='请输入合同编号' autoComplete='off' />
                              )
                            }
                          </FormItem>
                        </Row>
                        : null
                    }

                    <Row className={`${styles.formitemRow}`}>
                      <FormItem label='委托人姓名' style={{ marginBottom: '5px' }}>
                        {
                          getFieldDecorator(`demanderCover[${'assignor'}]`, {
                            initialValue: demanderCover.assignor || ''
                          })(
                            <Input placeholder='请输入客户姓名...' autoComplete='off' />
                          )
                        }
                      </FormItem>
                    </Row>

                    <Row className={`${styles.formitemRow}`}>
                      <FormItem label='电话' style={{ marginBottom: '5px' }}>
                        {
                          getFieldDecorator(`demanderCover[${'phone'}]`, {
                            initialValue: demanderCover.phone || ''
                          })(
                            <Input placeholder='请输入客户电话...' autoComplete='off' />
                          )
                        }
                      </FormItem>
                    </Row>

                    <Row className={`${styles.formitemRow}`}>
                      <FormItem label='开户银行' style={{ marginBottom: '5px' }}>
                        {
                          getFieldDecorator(`demanderCover[${'bank'}]`, {
                            initialValue: demanderCover.bank || ''
                          })(
                            <Input placeholder='请输入客户开户银行名称...' autoComplete='off' />
                          )
                        }
                      </FormItem>
                    </Row>

                    <Row className={`${styles.formitemRow}`}>
                      <FormItem label='账户名称' style={{ marginBottom: '5px' }}>
                        {
                          getFieldDecorator(`demanderCover[${'accountName'}]`, {
                            initialValue: demanderCover.accountName || ''
                          })(
                            <Input placeholder='请输入客户账户名称...' autoComplete='off' />
                          )
                        }
                      </FormItem>
                    </Row>

                    <Row className={`${styles.formitemRow}`}>
                      <FormItem label='银行账号' style={{ marginBottom: '0px' }}>
                        {
                          getFieldDecorator(`demanderCover[${'account'}]`, {
                            initialValue: demanderCover.account || ''
                          })(
                            <Input placeholder='请输入客户银行账号...' autoComplete='off' />
                          )
                        }
                      </FormItem>
                    </Row>
                  </Row>
                  : null
              }

              {
                step === 2
                  ? <Row className={`${styles.formitemRow}`}>
                    <FormItem label='是否发送链接到'>
                      {
                        getFieldDecorator('phoneNumber', {
                          initialValue: receivedInfo.phoneNumber || '',
                          rules: [
                            { required: true, message: '请填写接收短信的手机号码' },
                            {
                              validator (rule, value, callback) {
                                if (value && !/^1[3456789]\d{9}$/.test(value)) {
                                /* eslint-disable */
                                callback('手机号码格式不正确')
                                /* eslint-enable */
                                } else {
                                  callback()
                                }
                              }
                            }
                          ]
                        })(
                          <Input autoComplete='off' placeholder='请输入...' />
                        )
                      }
                    </FormItem>
                  </Row>
                  : null
              }

            </Form>
          </Row>
        </Modal>
      </div>
    )
  }
}

const SendModal = Form.create()(SendForm)

export default SendModal
