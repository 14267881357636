import Ajax from '../utils/ajax'

export default {
  // 获取EU订单金额汇总
  getTradeOrderAmountSummary (params) {
    return Ajax.post('/api/order/getOrderAmountSum', params)
  },

  // EU订单列表
  getEuOrderList (params) {
    return Ajax.post('/api/order/list/page/euOrder', params)
  },

  // EU订单 - 确认收款
  saveConfirmEuPayment (params) {
    return Ajax.post('/api/finance/eu/order/payment/confirm', params)
  },

  // 获取eu订单详情
  getEuOrderDetail (params) {
    return Ajax.post('/api/order/get/euTotalOrder', params)
  },

  // 获取eu订单收件人信息
  getEuorderReceivedInfo (params) {
    return Ajax.post('/api/order/getOrderReceiverInfo', params)
  },

  // 获取现货订单列表
  getStockOrderList (params) {
    return Ajax.post('/api/order/list/page/stock/self/order', params)
  },

  // 现货订单明细
  getStockOrderDetail (params) {
    return Ajax.post('/api/order/list/page/stock/self/order/details', params)
  },

  // 期货订单列表
  getFuturesOrderList (params) {
    return Ajax.post('/api/order/list/page/futures/self/order', params)
  },

  // 手工订单 - 批量查询SKU信息
  getBatchSearchSku (params) {
    return Ajax.post('/api/quick/order/search', params)
  },

  // 手工订单 - 纠错搜索
  getAmendedSkus (params) {
    return Ajax.post('/api/quick/order/search/correct', params)
  },

  // 手工订单 - 获取客户下单信息
  getEuOrderSkuOneInfo (params) {
    return Ajax.get('/api/handOrder/skuOneInfo', { params })
  },

  // 手工订单下单
  submitManualOrder (params) {
    return Ajax.post('/api/quick/order/generate', params)
  },

  // 获取售后订单列表
  getSaledOrderList (params) {
    return Ajax.post('/api/order/list/page/eu/afterSaleOrder/oneLevelAudit', params)
  },

  // 获取售后订单详情
  getSaledOrderDetail (params) {
    return Ajax.post('/api/order/get/euAfterSaleOrderDetail', params)
  },

  // 更新售后订单状态
  updateSaledOrderStatus (params) {
    return Ajax.post('/api/order/update/eu/afterSaleOrder/oneLevelAudit', params)
  },

  // 获取客户收件地址列表
  getEndUserReceivedAddressList (params) {
    return Ajax.get('/api/guest/supplier/guest/addressList', { params })
  },

  // 保存新增客户收货地址
  saveAddEndUserReceiveAddress (params) {
    return Ajax.post('/api/guest/address/add', params)
  },

  // 保存编辑客户收货地址
  saveEditEndUserReceiveAddress (params) {
    return Ajax.post('/api/guest/address/modify', params)
  },

  // 删除客户收货地址
  deleteEndUserReceivedAddress (params) {
    return Ajax.post('/api/guest/address/remove', params)
  },

  // 获取导出自定义项 --导出EU订单
  getOrderDownColList (params) {
    return Ajax.get('/api/excel/export/getExcelExportOrderFieldMap', { params })
  },
  // 获取订单信息字段
  getOrdergetOrderFieldMap (params) {
    return Ajax.get('/api/excel/data/download/getOrderFieldMap', { params })
  },
  // -获取期货订单信息字段
  getOrdergetFutureOrderFieldMap (params) {
    return Ajax.get('/api/excel/data/download/getFutureOrderFieldMap', { params })
  },
  // -获取产品信息字段
  getOrdergetProductFieldMap (params) {
    return Ajax.get('/api/excel/data/download/getProductFieldMap', { params })
  },
  // 获取外部订单列表
  getOutsideOrderList (params) {
    return Ajax.post('/api/external/order/list/page', params)
  },

  // 获取外部订单产品明细
  getOutsideSkuList (params) {
    return Ajax.post('/api/external/order/list/page/sku/info', params)
  },

  // 查看订单付款凭证信息
  getCloudOrderPayVoucherDetails (params) {
    return Ajax.post('/api/cloud/supply/order/list/page/history', params)
  },

  /* 快速下单相关API START */
  // 快速下单- 搜索
  getPurchaseCloudBatchSearch (params) {
    return Ajax.post('/api/just/inner/purchase/search', params)
  },

  // 快速下单- 纠错搜索
  getPurChaseCloudSkuCorrect (params) {
    return Ajax.post('/api/just/inner/purchase/search/correct', params)
  },

  /* 快速下单相关API END */

  /* 购物车相关API START */
  // 获取EU客户购物车详情
  getEUCartSkuDetails (params) {
    return Ajax.post('/api/just/inner/purchase/shopping/cart/listForInner', params)
  },

  // 购物车- 获取购物车列表
  getCartSkuList (params) {
    return Ajax.post('/api/just/inner/purchase/shopping/cart/list', params)
  },

  // 购物车- 添加到购物车
  saveAddToCart (params) {
    return Ajax.post('/api/just/inner/purchase/shopping/cart/add', params)
  },

  // 购物车- 删除购物车
  saveRemoveSkuToCart (params) {
    return Ajax.post('/api/just/inner/purchase/shopping/cart/remove', params)
  },

  // 结算- 下单预览
  getQuickOrderPreviewDatas (params) {
    return Ajax.post('/api/just/inner/purchase/order/preview', params)
  },

  // 结算- 生成订单
  submitGenerateQuickOrder (params) {
    return Ajax.post('/api/just/inner/purchase/order/generate', params)
  }

  /* 购物车相关API END */
}
