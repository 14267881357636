import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Form, Row, Col, Button, Icon, Input, Upload, message } from 'antd'
import $lodash from 'lodash'

import DiscernModal from '../discernModal/index'
import HandleModal from '@/pages/price/price-setting/handle-modal'
import UploadSheetModal from '@/components/uploadSheetModal'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'
import { getStore } from '@/common/utils/mUtils'

import IntelImage from '@/assets/images/intel_upload_image.png'
import styles from './index.module.scss'

const FormItem = Form.Item
const { Dragger } = Upload
const { TextArea } = Input
const { repLineBreak } = filter
const { uploadMatchExcelUrl } = UploadApi
const { downloadCommonTemplate } = DownloadApi

class EnquireSearchForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object,
    onSearch: PropTypes.func
  }
  state = {
    discernModal: false, // 图文识别弹窗
    handleModal: false,
    uploadSheetModal: false,
    uploadSheetDetails: {}
  }

  // 上传EXCEL智能解析
  saveUploadExcelMatchSmartparse = async (params) => {
    const res = await Api.saveUploadExcelMatchSmartparse(params)
    const { code, data } = res
    if (+code === 10000) {
      this.changeUploadSuccess(data)
    }
  }

  // 点击搜索
  handleSearch = () => {
    const { form, onSearch } = this.props

    form.validateFields((err, values) => {
      if (!err) {
        const { keywords } = values
        const searchArr = repLineBreak(keywords, '<br>').split('<br>')
        const newdatas = searchArr.filter((o) => { return o !== '' })

        if (newdatas.length === 0) { return message.warning('请输入要搜索的型号或订货号！') }

        const unitList = $lodash.map(newdatas, (o) => {
          const arr = o.split('==')

          return {
            itemNumberModel: arr.length > 0 ? arr[0] : '',
            quantity: arr[1] && +arr[1] > 0 ? +arr[1] : 1
          }
        })

        onSearch({ unitList: unitList })
      }
    })
  }

  // 点击图文识别按钮
  showDiscernModal = (bool) => {
    this.setState({
      discernModal: bool
    })
  }

  // 复制到文本框
  handleCopyToSearch = (str) => {
    setTimeout(() => {
      this.props.form.setFieldsValue({ keywords: str })

      this.showDiscernModal(false)
    }, 30)
  }

  // 监听上传EXCEL
  handleChangeUploadFile = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ handleModal: true })

      return false
    }

    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        setTimeout(() => {
          this.setState({
            handleModal: false,
            uploadSheetDetails: {
              name: data.tableName || '',
              list: data.sheetNames || [],
              file: info.file.originFileObj
            },
            uploadSheetModal: true
          })
        }, 2000)
      } else {
        this.closeModal()
        message.error(info.file.response.message || `${info.file.name}上传失败`)
      }
    } else if (info.file.status === 'error') {
      this.closeModal()
      message.error('上传失败')
    }
  }

  // 点击确定选择导入EXCEL的sheet
  handleConfirmUploadSheet = (values) => {
    const formData = new FormData()

    $lodash.forEach(values, (value, key) => {
      formData.append(key, value)
    })

    formData.append('startRowNum', 1)

    this.saveUploadExcelMatchSmartparse(formData)
  }

  // 监听上传成功
  changeUploadSuccess = (params) => {
    this.props.history.push({ pathname: '/admin/enquireMatchSheet', state: { importId: params } })
  }

  closeModal = () => {
    this.setState({
      handleModal: false,
      uploadSheetModal: false
    })
  }

  render () {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { discernModal = false, handleModal = false, uploadSheetModal = false, uploadSheetDetails = {} } = this.state

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '12px' }}>
            <Form>
              <Row gutter={24} style={{ marginBottom: '12px' }}>
                <Col span={8} offset={16} style={{ textAlign: 'right' }}>
                  <Button onClick={() => downloadCommonTemplate({ excelTemplateType: 'ENQUIRY_IMPORT' })}>下载模板</Button>
                  <Button style={{ marginLeft: '16px' }} onClick={() => this.showDiscernModal(true)}>图文识别</Button>
                  <Button type='primary' style={{ marginLeft: '16px' }} onClick={() => this.handleSearch()}>搜索</Button>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={8}>
                  <div>
                    <Dragger name='file' showUploadList={false}
                      accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      headers={{ Token: getStore('token') }}
                      action={uploadMatchExcelUrl}
                      onChange={(info) => this.handleChangeUploadFile(info)}
                    >
                      <div className={`${styles.draggerContent}`}>
                        <div style={{ flex: '1' }}>
                          <div className={`${styles.imgBox}`}>
                            <img className={`${styles.pic}`} src={IntelImage} />
                          </div>
                          <div style={{ marginBottom: '12px' }}>
                            <Button><Icon type='plus' style={{ fontSize: '14px' }} />上传文件</Button>
                          </div>
                          <p>点击上传或将文件拖拽至此</p>
                        </div>
                      </div>
                    </Dragger>
                  </div>
                </Col>
                <Col span={16}>
                  <FormItem style={{ marginBottom: '0px' }}>
                    {
                      getFieldDecorator('keywords', {
                        rules: [
                          { required: false, message: '请输入要搜索的型号或订货号' }
                        ]
                      })(
                        <TextArea rows={14} autoComplete='off'
                          placeholder='请输入型号或订货号，并且使用双等号将数量隔开...例如：&#10;RXM2CB2BD==10&#10;10000498==5'
                        />
                      )
                    }
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>

        {
          discernModal
            ? <DiscernModal
              onConfirm={(str) => this.handleCopyToSearch(str)}
              onCancel={() => this.showDiscernModal(false)}
            />
            : null
        }

        {
          handleModal
            ? <HandleModal letterArr={['EXCEL', '分', '析', '中', '...']} />
            : null
        }

        {
          uploadSheetModal
            ? <UploadSheetModal
              name={uploadSheetDetails.name}
              list={uploadSheetDetails.list}
              file={uploadSheetDetails.file}
              onConfirm={(values) => this.handleConfirmUploadSheet(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const EnquireSearch = Form.create()(EnquireSearchForm)

const mapStateToProps = (state) => {
  return {
    editEnquireDetails: state.editEnquireDetails
  }
}

export default connect(mapStateToProps)(EnquireSearch)
