import Ajax from '../utils/ajax'

/* 客户价格组END */

export default {
  // 获取最低价格启用/禁用状态
  getLowestPriceDisabeldStatus (params) {
    return Ajax.post('/api/merchant/config/get/switch/lowestPrice', params)
  },

  // 保存最低价格启用禁用状态
  saveLowestPriceDisabledStatus (params) {
    return Ajax.post('/api/merchant/config/save/switch/lowestPrice', params)
  },

  /* 通用价格START */
  // 通用价格 - 获取商户产品默认价格
  getCommonDefaultPriceList (params) {
    return Ajax.post('/api/merchant/product/info/price/default/list/page', params)
  },

  // 通用价格 - 获取商户产品默认价格(期货)
  getCommonfutureDefaultPriceList (params) {
    return Ajax.post('/api/merchant/product/info/future/price/default/list/page', params)
  },

  // 通用价格 - 更新默认价格
  updateCommonDefaultPrice (params) {
    return Ajax.post('/api/price/manage/default/update', params)
  },

  // 通用价格 - 更新默认价格（期货）
  updateFutureCommonDefaultPrice (params) {
    return Ajax.post('/api/future/price/manage/default/update', params)
  },

  /* 通用价格END */

  /* 客价START */
  // 客价 - 获取产品客价列表
  getGuestPriceList (params) {
    return Ajax.post('/api/merchant/product/info/price/guest/list/page', params)
  },

  // 客价 - 获取产品客价列表（期货）
  getGuestFuturePriceList (params) {
    return Ajax.post('/api/merchant/product/info/future/price/guest/list/page', params)
  },

  // 更新客户价格
  updateGuestPrice (params) {
    return Ajax.post('/api/price/manage/guest/update', params)
  },
  // 更新客户价格（期货）
  updatefutureGuestPrice (params) {
    return Ajax.post('/api/future/price/manage/guest/update', params)
  },

  // 客价- 删除SKU
  deleteSkuByGuestPrice (params) {
    return Ajax.post('/api/price/manage/guest/delete', params)
  },
  // 客价- 删除SKU（期货）
  deleteFutureSkuByGuestPrice (params) {
    return Ajax.post('/api/future/price/manage/guest/delete', params)
  },

  // 客价 - 可添加的SKU集合
  getAllowAddSkuListbyGuestPrice (params) {
    return Ajax.post('/api/merchant/product/info/price/guest/add/list/page', params)
  },

  // 客价 - 可添加的SKU集合（期货）
  getAllowFutureAddSkuListbyGuestPrice (params) {
    return Ajax.post('/api/merchant/product/info/future/price/guest/add/list/page', params)
  },
  // 客价 - 添加SKU
  saveAddSkuByGuestPrice (params) {
    return Ajax.post('/api/price/manage/guest/add', params)
  },

  // 客价 - 添加SKU（期货）
  saveFutureAddSkuByGuestPrice (params) {
    return Ajax.post('/api/future/price/manage/guest/add', params)
  },
  /* 客价END */

  /* 通用价格组START */
  // 通用价格组 - 获取商户价格组产品价格
  getPriceListInCommonPriceGroup (params) {
    return Ajax.post('/api/price/group/manage/common/list/page', params)
  },

  // 通用价格组 - 获取商户价格组产品价格(期货)
  getFuturePriceListInCommonPriceGroup (params) {
    return Ajax.post('/api/future/price/group/manage/common/list/page', params)
  },

  // 保存新增价格组
  saveAddGroupInCommonPriceGroup (params) {
    return Ajax.post('api/price/group/manage/common/add', params)
  },
  // 保存新增价格组（期货）
  saveFutureAddGroupInCommonPriceGroup (params) {
    return Ajax.post('api/future/price/group/manage/common/add', params)
  },

  // 编辑价格组
  saveEditGroupInCommonPriceGroup (params) {
    return Ajax.post('/api/price/group/manage/common/update', params)
  },
  // 编辑价格组(期货)
  saveFutureEditGroupInCommonPriceGroup (params) {
    return Ajax.post('/api/future/price/group/manage/common/update', params)
  },

  // 删除价格组
  deleteGroupInCommonPriceGroup (params) {
    return Ajax.post('/api/price/group/manage/common/delete', params)
  },
  // 删除价格组(期货)
  deleteFutureGroupInCommonPriceGroup (params) {
    return Ajax.post('/api/future/price/group/manage/common/delete', params)
  },

  // 更新价格组启用禁用状态
  updateEnabledStatusInCommonPriceGroup (params) {
    return Ajax.post('/api/price/group/manage/common/status/update', params)
  },
  // 更新价格组启用禁用状态(期货)
  updateFutureEnabledStatusInCommonPriceGroup (params) {
    return Ajax.post('/api/future/price/group/manage/common/status/update', params)
  },

  // 获取价格组详情
  getCommonGroupDetailByGroupId (params) {
    return Ajax.post('/api/price/group/manage/common/info', params)
  },
  // 获取价格组详情(期货)
  getFutureCommonGroupDetailByGroupId (params) {
    return Ajax.post('/api/future/price/group/manage/common/info', params)
  },

  // 获取价格组SKU价格列表
  getCommonGroupSkuListByGroupId (params) {
    return Ajax.post('/api/merchant/product/info/price/group/common/list/page', params)
  },
  // 获取价格组SKU价格列表(期货)
  getFutureCommonGroupSkuListByGroupId (params) {
    return Ajax.post('/api/merchant/product/info/future/price/group/common/list/page', params)
  },

  // 获取可添加到通用价格组的SKU列表
  getSkuListByCanAddtoCommonGroupItem (params) {
    return Ajax.post('/api/merchant/product/info/price/group/common/add/list/page', params)
  },

  // 获取可添加到通用价格组的SKU列表（期货）
  getFutureSkuListByCanAddtoCommonGroupItem (params) {
    return Ajax.post('/api/merchant/product/info/future/price/group/common/add/list/page', params)
  },

  // 添加SKU到通用价格组
  addSkuToCommonGroupItem (params) {
    return Ajax.post('/api/price/group/manage/common/sku/add', params)
  },

  // 添加SKU到通用价格组(期货)
  addFutureSkuToCommonGroupItem (params) {
    return Ajax.post('/api/future/price/group/manage/common/sku/add', params)
  },

  // 从通用价格组删除SKU
  deleteSkuToCommonGroupItem (params) {
    return Ajax.post('/api/price/group/manage/common/sku/delete', params)
  },
  // 从通用价格组删除SKU(期货)
  deleteFutureSkuToCommonGroupItem (params) {
    return Ajax.post('/api/future/price/group/manage/common/sku/delete', params)
  },

  /* 通用价格组END */

  /* 客户价格组START */
  getPriceListInGuestPriceGroup (params) {
    return Ajax.post('/api/price/group/manage/guest/list/page', params)
  },
  /* 客户价格组START (期货) */
  getFuturePriceListInGuestPriceGroup (params) {
    return Ajax.post('/api/future/price/group/manage/guest/list/page', params)
  },

  // 保存新增价格组
  saveAddGroupInGuestPriceGroup (params) {
    return Ajax.post('/api/price/group/manage/guest/add', params)
  },
  // 保存新增价格组(期货)
  saveFutureAddGroupInGuestPriceGroup (params) {
    return Ajax.post('/api/future/price/group/manage/guest/add', params)
  },

  // 编辑价格组
  saveEditGroupInGuestPriceGroup (params) {
    return Ajax.post('/api/price/group/manage/guest/update', params)
  },
  // 编辑价格组(期货)
  saveFutureEditGroupInGuestPriceGroup (params) {
    return Ajax.post('/api/future/price/group/manage/guest/update', params)
  },
  // 删除价格组
  deleteGroupInGuestPriceGroup (params) {
    return Ajax.post('/api/price/group/manage/guest/delete', params)
  },
  // 删除价格组(期货)
  deleteFutureGroupInGuestPriceGroup (params) {
    return Ajax.post('/api/future/price/group/manage/guest/delete', params)
  },

  // 更新价格组启用禁用状态
  updateEnabledStatusInGuestPriceGroup (params) {
    return Ajax.post('/api/price/group/manage/guest/status/update', params)
  },
  // 更新价格组启用禁用状态(期货)
  updateFutureEnabledStatusInGuestPriceGroup (params) {
    return Ajax.post('/api/future/price/group/manage/guest/status/update', params)
  },

  // 获取客户价格组详情
  getGuestGroupDetailByGroupId (params) {
    return Ajax.post('/api/price/group/manage/guest/info', params)
  },
  // 获取客户价格组详情(期货)
  getFutureGuestGroupDetailByGroupId (params) {
    return Ajax.post('/api/future/price/group/manage/guest/info', params)
  },

  // 获取价格组SKU价格列表
  getGuestGroupSkuListByGroupId (params) {
    return Ajax.post('/api/merchant/product/info/price/group/guest/list/page', params)
  },
  // 获取价格组SKU价格列表(期货)
  getFutureGuestGroupSkuListByGroupId (params) {
    return Ajax.post('/api/merchant/product/info/future/price/group/guest/list/page', params)
  },

  // 获取可添加到客户价格组的SKU列表
  getSkuListByCanAddtoGuestGroupItem (params) {
    return Ajax.post('/api/merchant/product/info/price/group/guest/add/list/page', params)
  },
  // 获取可添加到客户价格组的SKU列表（期货）
  getFutureSkuListByCanAddtoGuestGroupItem (params) {
    return Ajax.post('/api/merchant/product/info/future/price/group/guest/add/list/page', params)
  },

  // 添加SKU到客户价格组
  addSkuToGuestGroupItem (params) {
    return Ajax.post('/api/price/group/manage/guest/sku/add', params)
  },

  // 添加SKU到客户价格组(期货)
  addFutureSkuToGuestGroupItem (params) {
    return Ajax.post('/api/future/price/group/manage/guest/sku/add', params)
  },
  // 从客户价格组删除SKU
  deleteSkuToGuestGroupItem (params) {
    return Ajax.post('/api/price/group/manage/guest/sku/delete', params)
  },
  // 从客户价格组删除SKU(期货)
  deleteFutureSkuToGuestGroupItem (params) {
    return Ajax.post('/api/future/price/group/manage/guest/sku/delete', params)
  }
}
