import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import uploadImg from '@/assets/images/uploading_3.gif'

import styles from './handle.module.scss'

export default class HandleModal extends Component {
  static propTypes = {
    letterArr: PropTypes.array
  }
  state = {}

  componentDidMount () { }

  render () {
    const { letterArr = [] } = this.props

    return (
      <div>
        <Modal bodyStyle={{ padding: '0px' }} width={600} closable={false}
          visible
          maskClosable={false}
          footer={null}
        >
          <div className={`${styles.container}`}>
            {
              letterArr && letterArr.length > 0
                ? <div className={`${styles.bounceLand}`}>
                  <div className={`${styles.bounce}`}>
                    {
                      letterArr.map((item, i) => (
                        <span className={`${styles.letter}`} key={`letter-${i}`}>{item}</span>
                      ))
                    }
                  </div>
                </div>
                : null
            }

            <img className={`${styles.pic}`} src={uploadImg} />
            <div className={`${styles.copyRight}`}>
              IIASAAS助力工业的未来
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
