import React, { Component } from 'react'
import { Row, Card, Table, message, Button } from 'antd'

import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import VoucherModal from '../component/voucherModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter
const cashOutMap = [
  { type: '-1', label: '全部' },
  { type: '0', label: '待打款' },
  { type: '1', label: '已打款' }
]

export default class CashoutList extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'endUserId',
        formType: 'Select',
        itemParams: {
          label: '客户名称',
          help: ''
        },
        cptParams: {
          showSearch: true,
          placeholder: '请输入...',
          autoComplete: 'off',
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['endUserId', 'showName']
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '打款状态'
        },
        cptParams: {
          placeholder: '选择...',
          autoComplete: 'off'
        },
        options: cashOutMap,
        optionValue: ['type', 'label']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {},
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'cashoutList-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '客户名称',
      key: 'cashoutList-1',
      dataIndex: 'customerName'
    },
    {
      title: '提现金额',
      key: 'cashoutList-2',
      dataIndex: 'amount',
      render: (text, record, index) => (
        <>&yen;{(+record.amount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '银行账号',
      key: 'cashoutList-3',
      render: (text, record, index) => (
        <div>
          {record && record.account
            ? record.account.replace(/[\s]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')
            : null
          }
        </div>
      )
    },
    {
      title: '银行名称',
      key: 'cashoutList-4',
      dataIndex: 'bank'
    },
    {
      title: '持卡人',
      key: 'cashoutList-5',
      dataIndex: 'accountName'
    },
    {
      title: '申请时间',
      key: 'cashoutList-6',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '状态',
      key: 'cashoutList-7',
      dataIndex: 'status',
      render: (text, record, index) => (
        <>{ +record.status === 1 ? '已打款' : '待打款' }</>
      )
    },
    {
      title: '操作',
      key: 'cashoutList-8',
      width: 100,
      render: (text, record, index) => (
        <>
          {
            +record.status === 0
              ? <Button type='primary' size='small' onClick={() => this.showVoucherModal(record)}>确认打款</Button>
              : null
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getGuesteuUserLike('')
    this.getCashoutApplyList()
  }

  // 查询客户信息
  getGuesteuUserLike = (val = '') => {
    let params = {
      pageNo: 1,
      pageSize: 999,
      searchName: val
    }

    Api.getGuesteuUserLike(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const { searchData } = this.state
        searchData[0].options = data.list || []

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取提现申请列表
  getCashoutApplyList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCashoutApplyList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 提现打款确认
  cashoutConfirm = (params) => {
    Api.cashoutConfirm(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getCashoutApplyList()
    })
  }

  // 点击确认付款
  showVoucherModal = (record) => {
    this.setState({
      rowDetail: record || {},
      voucherModal: true
    })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      voucherModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCashoutApplyList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCashoutApplyList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], voucherModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '15px' }}>
          <Table rowKey='id'
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>

        {
          voucherModal
            ? <VoucherModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.cashoutConfirm(params)}
            />
            : null
        }
      </>
    )
  }
}
