import React, { Component } from 'react'
import { Card, Table, Row, message, Button, Alert, Tooltip, Tag, Pagination, Badge } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import BatchSearchMenu from '@/components/batchSearchMenu/index'
import ProfitModal from '../component/profit-modal'
import ProgressModal from '../component/progress-modal'

import Api from '@/common/api'
import filter from '@/common/utils/filter'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

import styles from './index.module.scss'

const { pageSizeOptions } = filter
const effectiveTipMaps = [
  { type: 'PROFIT_TYPE_BLANK', code: 0, label: '无生效利润设置' },
  { type: 'PROFIT_TYPE_DEFAULT', code: 1, label: '默认利润设置' },
  { type: 'PROFIT_TYPE_SPECIAL', code: 2, label: '特别利润设置' }
]

export default class PurchProd extends Component {
  static propTypes = {

  }
  state = {
    searchData: [
      {
        name: 'keyword',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号或订货号...',
          autoComplete: 'off'
        }
      },
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    selectedRowKeys: [],
    profitModal: false, // 设置利润弹窗
    progressModal: false, // 进度条弹窗
    type: 'DEFAULT', // DEFAULT- 默认利润设置 SKU-sku批量利润设置 CONDITION-条件批量设置利润
    rowDetail: {} // 当前选择的行数据
  }

  columns = [
    {
      title: '序号',
      key: 'purchaseProductCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'purchaseProductCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'purchaseProductCol-2',
      width: 160,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.model }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.model }} />
          </Tooltip>

          { record.modelExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '订货号',
      key: 'purchaseProductCol-3',
      width: 140,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
          </Tooltip>

          { !record.modelExactMatch && record.itemNumberExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '表价',
      key: 'purchaseProductCol-4',
      width: 120,
      dataIndex: 'price',
      render: (text, record, index) => (
        <>{ +record.price > 0 ? <>&yen;{record.price}</> : <>----</> }</>
      )
    },
    {
      title: '产品类型',
      key: 'purchaseProductCol-5',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '云仓价格',
      key: 'purchaseProductCol-6',
      width: 200,
      render: (text, record, index) => {
        const { cloudProcurementInfoList = [], skuGoodsType = 'SPOT' } = record

        return (
          <>
            {
              cloudProcurementInfoList && cloudProcurementInfoList.length > 0
                ? <>
                  {
                    cloudProcurementInfoList.map((supptem, suppidnx) => (
                      <div key={`priceCell-${suppidnx}`} style={{ height: '20px', lineHeight: '20px', display: 'flex' }}>
                        <span style={{ verticalAlign: 'middle' }}>&yen;{+supptem.cloudPrice}</span>
                        {supptem.special ? <Badge count={'特'} style={{ marginTop: '-4px', marginLeft: '4px' }} /> : null }

                        { ['FUTURES', 'CLOUD_FUTURES'].includes(skuGoodsType) ? <span style={{ marginLeft: '10px' }}>预付比例：{(+supptem.prepaymentRatio * 100 / 100).toFixed(2)}%</span> : null }
                      </div>
                    ))
                  }
                </>
                : <>----</>
            }
          </>
        )
      }
    },
    {
      title: '货期',
      key: 'purchaseProductCol-7',
      dataIndex: 'deliveryPeriodDays',
      render: (text, record, index) => {
        const { cloudProcurementInfoList = [], skuGoodsType } = record

        return ['FUTURES', 'CLOUD_FUTURES'].includes(skuGoodsType) ? <>
          {
            cloudProcurementInfoList && cloudProcurementInfoList.map((item, n) => (
              <div key={`${record.commoditySkuId}-deliveryPeriodDays-${index}-${n}`}>
                { item.deliveryPeriodDays ? `${+item.deliveryPeriodDays}工作日` : '当天发货' }
              </div>
            ))
          }
        </>
          : '当天发货'
      }
    },
    {
      title: '库存',
      key: 'purchaseProductCol-8',
      dataIndex: 'stock',
      width: 140,
      render: (text, record, index) => {
        const { cloudProcurementInfoList = [], skuGoodsType = 'SPOT' } = record

        return ['FUTURES', 'CLOUD_FUTURES'].includes(skuGoodsType) ? (
          <span style={{ fontSize: '16px' }}>&infin;</span>
        )
          : (
            <div>
              {
                cloudProcurementInfoList && cloudProcurementInfoList.length > 0
                  ? <>
                    {
                      cloudProcurementInfoList.map((supptem, suppidnx) => (
                        <div key={`stockCell-${suppidnx}`} style={{ height: '20px', lineHeight: '20px' }}>{supptem.stock}</div>
                      ))
                    }
                  </>
                  : <>----</>
              }
            </div>
          )
      }
    },
    {
      title: '利润设置',
      key: 'purchaseProductCol-9',
      width: 140,
      render: (text, record, index) => (
        <div>
          {
            record.profitType && record.profitType !== ''
              ? <Tooltip title={<div>生效设置：{this.renderEffectiveTipLabel(record.sellPriceType)}</div>}>
                <div>
                  <label className={`${styles.label}`}>{record.profitType === 'FIXED' ? '利润' : '利润率'}</label>
                  {
                    record.profitType === 'FIXED'
                      ? <span>&yen;{+record.profitNum}</span>
                      : <span>{(+record.profitNum * 100 * 100 / 100).toFixed(2)}%</span>
                  }
                </div>
              </Tooltip>
              : null
          }
        </div>
      )

    },
    {
      title: '最低售价',
      key: 'purchaseProductCol-10',
      width: 120,
      dataIndex: 'acceptablePrice',
      render: (text, record, index) => (
        <>&yen;{+record.acceptablePrice}</>
      )
    },
    {
      title: '产品名称',
      key: 'purchaseProductCol-11',
      width: 150,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '分类',
      key: 'purchaseProductCol-13',
      dataIndex: 'classificationName',
      ellipsis: true
    },
    {
      title: '系列',
      key: 'purchaseProductCol-14',
      width: 120,
      dataIndex: 'series',
      ellipsis: true
    },
    {
      title: '操作',
      key: 'purchaseProductCol-15',
      width: 150,
      fixed: 'right',
      render: (text, record, index) => (
        <>
          <Button type='primary' size='small' onClick={() => this.showSetProfitModal(record, 'SKU')}>设置利润</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getBrandList({ brandListType: 'ALL_BRAND' })
    this.getCloudPurchaseList()
  }

  // 获取品牌
  getBrandList = (params) => {
    Api.getBrandList(params).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        data.map(item => {
          item.showLabel = item.brandName
          if (item.englishBrandName && item.englishBrandName !== '') {
            item.showLabel = `${item.brandName}/${item.englishBrandName}`
          }
        })

        searchData[1].options = $lodash.concat([{ brandId: 0, showLabel: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取云采购产品列表
  getCloudPurchaseList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCloudPurchaseList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 云采购产品利润设置
  saveCloudPurchaseProductProfit = async (params, type) => {
    const res = await Api.saveCloudPurchaseProductProfit(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getCloudPurchaseList()
    } else {
      message.warning(res.message)
    }
  }

  // 获取进度条
  getCloudPurchaseProfitProgress = (params) => {
    return Api.getCloudPurchaseProfitProgress(params)
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.brandId && +search.brandId > 0) {
      search['brandId'] = [search.brandId]
    } else {
      delete search.brandId
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => {
      this.getCloudPurchaseList()
    })
  }

  // 勾选
  onSelectChange = (keys, rows) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 点击保存利润设置
  handleSaveProfit = (params, type) => {
    this.saveCloudPurchaseProductProfit(params, type)
  }

  // 展示利润设置弹窗
  showSetProfitModal = (record = {}, type) => {
    this.setState({
      type: type,
      rowDetail: record,
      profitModal: true
    })
  }

  showProgressModal = () => {
    this.setState({
      progressModal: true
    })
  }

  // 渲染利润设置生效提示
  renderEffectiveTipLabel = (status) => {
    const obj = effectiveTipMaps.find(item => { return item.type === status })
    const label = obj !== undefined ? obj.label : ''

    return label
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      profitModal: false,
      progressModal: false,
      type: 'DEFAULT',
      rowDetail: {},
      selectedRowKeys: []
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCloudPurchaseList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudPurchaseList()
    })
  }

  // 渲染表格className
  renderRowClassName = (record, index) => {
    let className = 'light-row'
    if (index % 2 === 1) {
      className = 'darkBackground'
    }
    return className
  }

  render () {
    const { searchData, tableData, pages, profitModal = false, progressModal = false, type = 1, rowDetail = {}, selectedRowKeys = [] } = this.state

    const pagination = {
      // showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: record => ({
        disabled: record.hasPurchase
      })
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <Row style={{ display: 'inline-block', width: '120px', lineHeight: '40px', verticalAlign: 'top' }}>
              <BatchSearchMenu onConfirm={(values) => this.handleSearch(values)} />
            </Row>
            <Row style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
              <SearchForm {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Card>
        </Row>

        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ marginBottom: '10px' }}>
            <Button type='primary' onClick={() => this.showSetProfitModal({}, 'DEFAULT')}>默认利润设置</Button>

            <Button type='primary' style={{ marginLeft: '10px' }}
              disabled={selectedRowKeys.length === 0}
              onClick={() => this.showSetProfitModal({}, 'SKUBATCH')}
            >批量利润设置</Button>

            <Button type='primary' style={{ marginLeft: '10px' }}
              onClick={() => this.showSetProfitModal({}, 'CONDITION')}
            >条件利润设置</Button>

            <Button type='primary' style={{ marginLeft: '10px' }}
              onClick={() => this.showProgressModal()}
            >查看设置进度</Button>
          </Row>

          <Row style={{ marginBottom: '5px' }}>
            <Alert type='info' showIcon
              message={`受全国交通影响，云仓现货会延迟发货，请您耐心等待`}
            />
          </Row>

          <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}`}
            rowSelection={rowSelection}
            rowClassName={(record, index) => this.renderRowClassName(record, index)}
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
            scroll={{ x: 2000 }}
          />

          <div style={{ margin: '16px 0px', textAlign: 'right' }}>
            <Pagination {...pagination} />
          </div>
        </Card>

        {
          profitModal
            ? <ProfitModal
              type={type}
              detailInfo={rowDetail}
              keys={selectedRowKeys}
              onConfirm={(params, type) => this.handleSaveProfit(params, type)}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          progressModal
            ? <ProgressModal
              getProgress={(params) => this.getCloudPurchaseProfitProgress(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}
