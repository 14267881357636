import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Statistic } from 'antd'

import styles from './index.module.scss'

class AlertLand extends Component {
  static propTypes = {
    info: PropTypes.object
  }

  render () {
    const { info = {} } = this.props

    return (
      <Row gutter={24}>
        <Col span={12}>
          <div className={`${styles.colcontent} ${styles.background}`}>
            <div className={`${styles.imgBox} ${styles.orderIcon}`} />
            <div className={`${styles.statisticBox}`}>
              <Statistic title='云仓总订单数' value={+info.cloudOrderNum} valueStyle={{ fontSize: '16px' }} />
            </div>
          </div>

        </Col>
        <Col span={12}>
          <div className={`${styles.colcontent} ${styles.background}`}>
            <div className={`${styles.imgBox} ${styles.stockIcon}`} />
            <div className={`${styles.statisticBox}`}>
              <Statistic title='云仓库存价值' value={+info.cloudStockTotalAmount} prefix={<span>&yen;</span>} precision={2} valueStyle={{ fontSize: '16px' }} />
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default AlertLand
