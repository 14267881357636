import React, { Component } from 'react'
import { Card, Row, Tabs } from 'antd'

import EuinvoiceList from '../euinvoice-list/index' // EU开票管理
import PurchaseInvoice from '../purchase-invoice/index' // 云采购开票管理
import SupplyInvoice from '../supply-invoice/index' // 云供应开票管理
import InvoiceRule from '../invoice-rule/index' // 开票规则管理
import InvoiceAddress from '@/pages/home/address-list/index' // 收票地址管理

import styles from './index.module.scss'

const { TabPane } = Tabs
const menuMaps = [
  { title: '自营开票管理', key: 'EUINVOICE' },
  { title: '云采购开票管理', key: 'PURCHINVOICE' },
  { title: '云供应开票管理', key: 'SUPPLYINVOICE' },
  { title: '开票规则管理', key: 'INVOICERULE' },
  { title: '收票地址管理', key: 'INVOICEADDRESS' }
]

export default class index extends Component {
  static propTypes = {}
  state = {
    curTabkey: 'EUINVOICE'
  }

  componentDidMount () {

  }

  // 点击切换tab
  handleToggleTabmenu = (key) => {
    this.setState({
      curTabkey: key
    })
  }

  render () {
    const { curTabkey = 'EUINVOICE' } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '12px 0px 0px 0px' }} style={{ marginBottom: '15px' }}>
          <Row className={styles.tabsLand}>
            <Tabs onChange={this.handleToggleTabmenu}>
              {
                menuMaps.map((menu, n) => (
                  <TabPane tab={menu.title} key={menu.key} />
                ))
              }
            </Tabs>
          </Row>
        </Card>

        {
          curTabkey === 'EUINVOICE'
            ? <EuinvoiceList />
            : null
        }

        {
          curTabkey === 'PURCHINVOICE'
            ? <PurchaseInvoice />
            : null
        }

        {
          curTabkey === 'SUPPLYINVOICE'
            ? <SupplyInvoice />
            : null
        }

        {
          curTabkey === 'INVOICERULE'
            ? <InvoiceRule />
            : null
        }

        {
          curTabkey === 'INVOICEADDRESS'
            ? <InvoiceAddress />
            : null
        }

      </>
    )
  }
}
