import Ajax from '../utils/ajax'

export default {
  // 云供应品牌列表
  getSupplyBrandList (params) {
    return Ajax.post('/api/cloud/supply/list/page/brandAuth', params)
  },

  // 云供应品牌授权申请
  supplyBrandAuthApply (params) {
    return Ajax.post('/api/cloud/supply/brandAuthApply', params)
  },

  // 获取云供应品牌授权日志
  getSupplyBrandAuthFlow (params) {
    return Ajax.post('/api/cloud/supply/list/page/brandAuthFlow', params)
  },

  // 云供应商品列表
  getCloudProductList (params) {
    return Ajax.post('/api/merchant/product/info/product/cloud/supply/list/page', params)
  },

  // 获取云供应期货产品列表
  getCloudFuturesProductList (params) {
    return Ajax.post('/api/merchant/product/info/product/cloud/futures/list/page', params)
  },

  // 保存云供应上架
  saveCloudApplySoldIn (params) {
    return Ajax.post('/api/cloud/supply/enable', params)
  },

  // 云供应下架
  saveCloudSupplySoldOut (params) {
    return Ajax.post('/api/cloud/supply/disable', params)
  },

  // 云供应设置日志列表
  getCloudSupplyLogList (params) {
    return Ajax.post('/api/cloud/supply/list/page/info/log', params)
  },

  // 云供应订单列表
  getSupplyOrderList (params) {
    return Ajax.post('/api/cloud/supply/order/list/page/spot', params)
  },

  // 云供应订单明细
  getSupplyOrderDetail (params) {
    return Ajax.post('/api/cloud/supply/order/list/page/spot/goods', params)
  },

  // 云供应订单确认收款
  confirmSupplyCollect (params) {
    return Ajax.post('/api/cloud/supply/order/receipt/confirm', params)
  },

  // 云供应订单- 获取合同下载链接
  getCloudSupplyContractDownFile (params) {
    return Ajax.post('/api/platform/contract/purchase/downloadFile', params)
  }
}
