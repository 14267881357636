import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Button } from 'antd'

import styles from './index.module.scss'

function Footer (props) {
  const { checkboxDisabled = false, checkedAll = false, totalValues = {}, onSelectedAll, onRemove, onAddToCart, onLinkToCart, onCreateEnquire } = props

  return (
    <div className={styles.goodsAccount}>
      <div className={`${styles.actions}`}>
        <Checkbox disabled={checkboxDisabled} style={{ color: '#333333' }} checked={checkedAll} onChange={(e) => onSelectedAll(e.target.checked)}>全选</Checkbox>
        <Button type='link' size='small' className={`${styles.deleteLinkBtn}`} onClick={() => onRemove()}>删除选中</Button>
        <Button type='primary' style={{ marginLeft: '12px' }} onClick={() => onCreateEnquire()}>一键询价</Button>
        <span style={{ marginLeft: '8px', color: '#333333' }}>价格不满意？点击左侧一键询价</span>
      </div>

      <div className={`${styles.contents}`}>
        <span>已选中&nbsp;{totalValues.goods}&nbsp;种商品，共&nbsp;{ totalValues.nums }&nbsp;件</span>
        <label className={`label`} style={{ marginLeft: '12px' }}>总价（不含运费）</label>
        <span className={`${styles.money}`}>&yen;{(+totalValues.amount * 100 / 100).toFixed(2)}</span>
        <Button type='danger' style={{ marginLeft: '32px' }} onClick={() => onAddToCart()}>加入购物车</Button>
        <Button type='primary' style={{ marginLeft: '12px' }} onClick={() => onLinkToCart()}>去购物车结算</Button>
      </div>
    </div>
  )
}

Footer.propTypes = {
  totalValues: PropTypes.object,
  checkedAll: PropTypes.bool,
  checkboxDisabled: PropTypes.bool,
  onSelectedAll: PropTypes.func,
  onRemove: PropTypes.func,
  onAddToCart: PropTypes.func,
  onLinkToCart: PropTypes.func,
  onCreateEnquire: PropTypes.func
}

export default Footer
