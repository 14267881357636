import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Table, Button, Icon, Modal, message, Badge } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import filter from '@/common/utils/filter'

import Api from '@/common/api/index'
import styles from './informat.module.scss'

const { pageSizeOptions, merchantNotifyTypeMaps } = filter
const { confirm } = Modal
const sendStatusMaps = [
  { type: 'ALL', label: '全部' },
  { type: 'SENT', label: '已发送', color: '#52c41a' },
  { type: 'UNSENT', label: '未发送', color: '#1890ff' },
  { type: 'UNKNOWN', label: '草稿', color: '#faad14' }
]
const informationOptions = [
  { type: 'ALL', label: '全部' },
  { type: 'SYSTEM_NOTIFY', label: '活动通知' },
  { type: 'SYSTEM_ANNOUNCEMENT', label: '系统公告' },
  { type: 'TASK_DISTRIBUTE', label: '任务分发' }
]

export default class InformationManage extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'msgType',
        formType: 'Select',
        itemParams: {
          label: '信息类型'
        },
        cptParams: {

        },
        options: informationOptions,
        optionValue: ['type', 'label'],
        rules: {
          initialValue: 'ALL'
        }
      },
      {
        name: 'sendTime',
        formType: 'RangePicker',
        itemParams: {
          label: '发布时间'
        },
        cptParams: {

        }
      },
      {
        name: 'msgSendStatus',
        formType: 'Select',
        itemParams: {
          label: '发送状态'
        },
        cptParams: {

        },
        options: sendStatusMaps,
        optionValue: ['type', 'label'],
        rules: {
          initialValue: 'ALL'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {
      msgType: 'ALL',
      msgReadType: 'ALL'
    }
  }

  columns = [
    {
      title: '序号',
      key: 'informationManage-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '信息类型',
      key: 'informationManage-1',
      width: 80,
      render: (text, record, index) => (
        <div>{ this.renderNotifyTypeLabel(record.msgType) }</div>
      )
    },
    {
      title: '标题',
      key: 'informationManage-2',
      ellipsis: true,
      dataIndex: 'title'
    },
    {
      title: '内容',
      key: 'informationManage-3',
      ellipsis: true,
      dataIndex: 'content'
    },
    {
      title: '发布时间',
      key: 'informationManage-4',
      width: 110,
      render: (text, record, index) => (
        <div>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </div>
      )
    },
    {
      title: '发布人',
      key: 'informationManage-5',
      dataIndex: 'sendName'
    },
    {
      title: '发送状态',
      key: 'informationManage-6',
      width: 80,
      render: (text, record, index) => (
        <div>
          { this.renderNotifySendStatusLabel(record.status) }
        </div>
      )
    },
    {
      title: '发送对象',
      key: 'informationManage-7',
      width: 120,
      render: (text, record, index) => (
        <div>
          { this.renderMerchantNotifyToMemberRow(record, index) }
        </div>
      )
    },
    {
      title: '操作',
      key: 'informationManage-8',
      width: 160,
      render: (text, record, index) => (
        <div>
          {
            record.status === 'SENT'
              ? null
              : <div>
                <Button type='primary' size='small' onClick={() => this.handleLinkToEditNotifys(record, 2)}>编辑</Button>
                <Button type='primary' size='small' style={{ marginLeft: '6px' }}
                  onClick={() => this.handleDeleteNotify(record)}
                >删除</Button>
              </div>
          }

        </div>
      )
    }
  ]

  componentDidMount () {
    this.getNotifyManageList()
  }

  // 获取消息列表
  getNotifyManageList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getNotifyManageList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 删除消息任务
  deleteNotifyTask = (params) => {
    Api.deleteNotifyTask(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getNotifyManageList()
      } else {
        message.error(res.message)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.sendTime && search.sendTime.length === 2) {
      search['beginDate'] = moment(search.sendTime[0]).format('YYYY-MM-DD')
      search['endDate'] = moment(search.sendTime[1]).format('YYYY-MM-DD')

      delete search.sendTime
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getNotifyManageList()
    })
  }

  // 点击新增/编辑消息
  handleLinkToEditNotifys = (record, type) => {
    this.props.history.push({ pathname: '/admin/editNotifys', state: { detailInfo: record, type: type } })
  }

  // 点击删除
  handleDeleteNotify = (record) => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        this.deleteNotifyTask({ id: record.id })
      }
    })
  }

  renderNotifyTypeLabel = (status) => {
    const obj = informationOptions.find(item => { return item.type === status })
    const label = obj !== undefined ? obj.label : ''

    return label
  }

  renderNotifySendStatusLabel = (status) => {
    let _badge = {}
    let obj = sendStatusMaps.find(item => {
      return item.type === status
    })

    if (obj) {
      _badge = obj
    }

    return <Badge color={_badge.color} text={<span style={{ fontSize: '12px' }}>{_badge.label}</span>} />
  }

  // 渲染发送对象
  renderMerchantNotifyToMemberRow = (record, i) => {
    let _notifyToElement = (
      <div>----</div>
    )

    const obj = merchantNotifyTypeMaps.find(item => { return record.merchantNotifyType === item.type })

    if (obj) {
      if (obj.id === 'M_EU_EMPLOYEE_ALL' || obj.id === 'M_EU_ALL') {
        _notifyToElement = (
          <div style={{ color: obj.color }}>{ obj.name}</div>
        )
      } else {
        _notifyToElement = (
          <div style={{ position: 'relative' }}>
            <a style={{ color: obj.color }} onClick={() => this.getSendedNotifyUserNameList(record, i)}>{obj.name}</a>

            {
              record['notifyObj'] && record['notifyObj'].length > 0 && record['visible']
                ? <div className={`${styles.popover}`}>
                  <div className={`${styles.overInner}`}>
                    <div className={`${styles.innerTitle}`}>
                      <a className={`${styles.closeBtn}`} onClick={() => this.handleClosePopover(i)}>关闭</a>
                    </div>
                    <div className={`${styles.innerContent}`}>
                      {
                        record.notifyObj.map((str, n) => (
                          <p style={{ marginBottom: '10px' }} key={`${record.id}-${n}`}>{str}</p>
                        ))
                      }
                    </div>
                  </div>
                </div>
                : null
            }
          </div>
        )
      }
    }

    return _notifyToElement
  }

  // 渲染发送对象列表
  getSendedNotifyUserNameList = (record, i) => {
    let params = {
      msgManagerId: record.id,
      pageNo: 1,
      pageSize: 99999
    }
    Api.getSendedNotifyUserNameList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { tableData } = this.state
        tableData[i]['notifyObj'] = data.list || []
        tableData[i]['visible'] = true
        this.setState({
          tableData
        })
      }
    })
  }

  // 点击关闭气泡弹窗
  handleClosePopover = (i) => {
    let { tableData } = this.state
    tableData[i]['visible'] = false

    this.setState({
      tableData
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getNotifyManageList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getNotifyManageList()
    })
  }

  render () {
    const { searchData, pages, tableData } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15x' }} style={{ marginBottom: '15px' }}>
          <SearchForm {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </Card>

        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ marginBottom: '15px' }}>
            <Button type='primary' style={{ width: '110px', height: '30px', padding: '0px' }}
              onClick={() => this.handleLinkToEditNotifys({}, 1)}>
              <Icon type='plus' />新增消息
            </Button>
          </Row>

          <Table rowKey='id'
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}
