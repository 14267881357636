/**
 * 消息通知
 */
import InformationList from '@/pages/notifys/informationList/index' // 信息列表
import SystemNotifys from '@/pages/notifys/systemNotifys/index' // 系统通知
import InformationManage from '@/pages/notifys/informationManage/index' // 信息管理
import NotifyDetail from '@/pages/notifys/notifyDetail/index' // 消息详情
import EditNotifys from '@/pages/notifys/editNotifys/index' // 编辑通知消息

export default [
  {
    path: '/admin/informationList',
    name: '信息列表',
    component: InformationList
  },
  {
    path: '/admin/systemNotifys',
    name: '系统通知',
    component: SystemNotifys
  },
  {
    path: '/admin/informationManage',
    name: '信息管理',
    component: InformationManage
  },
  {
    path: '/admin/notifyDetail',
    name: '消息详情',
    component: NotifyDetail
  },
  {
    path: '/admin/editNotifys',
    name: '消息详情',
    component: EditNotifys
  }
]
