import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table } from 'antd'
import $lodash from 'lodash'

import Api from '@/common/api/index'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

export default class DetailModal extends Component {
  static propTypes = {
    type: PropTypes.number, // 1-云 2-自营期货 3-自营现货
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20, options: ['10', '20', '50', '100', '200'] },
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'orderDetailCol-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'orderDetailCol-1',
      width: 120,
      ellipsis: true,
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'orderDetailCol-2',
      width: 140,
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'orderDetailCol-3',
      width: 130,
      dataIndex: 'itemNumber',
      ellipsis: true
    },
    {
      title: '产品名称',
      key: 'orderDetailCol-4',
      width: 150,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'orderDetailCol-6',
      width: 80,
      dataIndex: 'unit',
      ellipsis: true
    },
    {
      title: '产品类型',
      key: 'orderDetailCol-7',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '数量',
      key: 'orderDetailCol-8',
      width: 80,
      dataIndex: 'quantity'
    },
    {
      title: '货期',
      key: 'orderDetailCol-9',
      dataIndex: 'deliveryPeriodDays',
      render: (text, record, index) => (
        <>
          {
            ['FUTURES', 'CLOUD_FUTURES'].includes(record.skuGoodsType) && +record.deliveryPeriodDays > 0
              ? <>{+record.deliveryPeriodDays}个工作日</>
              : '当天发货'
          }
        </>
      )
    },
    {
      title: '预付比例',
      key: 'orderDetailCol-10',
      dataIndex: 'prepaymentRatio',
      render: (text, record, index) => (
        <>
          {
            ['FUTURES', 'CLOUD_FUTURES'].includes(record.skuGoodsType)
              ? <>{ (+record.prepaymentRatio * 100 / 100).toFixed(2) }%</>
              : '----'
          }
        </>
      )
    },
    {
      title: '未税单价',
      key: 'orderDetailCol-11',
      width: 100,
      dataIndex: 'excludingTaxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{+record.excludingTaxUnitPrice}</>
      )
    },
    {
      title: '含税单价',
      key: 'orderDetailCol-12',
      width: 100,
      dataIndex: 'taxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{+record.taxUnitPrice}</>
      )
    },
    {
      title: '含税单品小计',
      key: 'orderDetailCol-13',
      dataIndex: 'totalTaxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{ ($lodash.round(+record.totalTaxUnitPrice, 2) * 100 / 100).toFixed(2)}</>
      )
    }
  ]

  componentDidMount () {
    const { type = 1 } = this.props

    if (type === 1) {
      this.getEuCloudPurchOrderDetail()
    } else if (type === 3) {
      this.getStockOrderDetail()
    } else if (type === 2) {
      this.getStockFuturesInDetail()
    }
  }

  // 获取自营现货订单明细
  getStockOrderDetail = () => {
    const { detailInfo } = this.props
    const { pages } = this.state
    let params = {
      orderId: detailInfo.orderId,
      pageNo: 1,
      pageSize: 99999
    }

    Api.getStockOrderDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取自营期货订单明细
  getStockFuturesInDetail = () => {
    const { detailInfo } = this.props
    const { pages } = this.state
    let params = {
      orderId: detailInfo.orderId,
      pageNo: 1,
      pageSize: 99999
    }
    Api.getStockFuturesInDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取eu云订单明细
  getEuCloudPurchOrderDetail = () => {
    const { detailInfo } = this.props
    const { pages } = this.state
    let params = {
      orderId: detailInfo.orderId,
      pageNo: 1,
      pageSize: 99999
    }

    Api.getEuCloudPurchOrderDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      const { type = 1 } = this.props
      if (type === 1) {
        this.getEuCloudPurchOrderDetail()
      } else if (type === 3) {
        this.getStockOrderDetail()
      } else if (type === 2) {
        this.getStockFuturesInDetail()
      }
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      const { type = 1 } = this.props
      if (type === 1) {
        this.getEuCloudPurchOrderDetail()
      } else if (type === 3) {
        this.getStockOrderDetail()
      } else if (type === 2) {
        this.getStockFuturesInDetail()
      }
    })
  }

  render () {
    const { tableData = [] } = this.state
    // const pagination = {
    //   showSizeChanger: true,
    //   total: pages.total,
    //   showTotal: (total) => { return `共 ${total} 条` },
    //   current: pages.pageNo,
    //   pageSize: pages.pageSize,
    //   pageSizeOptions: pages.options,
    //   onChange: this.pageChange,
    //   onShowSizeChange: this.pageSizeChange
    // }

    return (
      <>
        <Modal title='订单明细' bodyStyle={{ padding: '16px' }}
          width={1000}
          visible
          maskClosable={false}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
        >
          <Table rowKey={(record, index) => `${record.commoditySkuId}-${index}`}
            pagination={false}
            dataSource={tableData}
            columns={this.columns}
            rowClassName={(record, index) => (+record.quantity < 0) || (+record.quantity === 0) ? 'redColor' : ''}
            scroll={{ x: 1300, y: 400 }}
          />
        </Modal>
      </>
    )
  }
}
