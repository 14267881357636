import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tree } from 'antd'

const { TreeNode } = Tree

export default class AuthorTree extends Component {
  static propTypes = {
    list: PropTypes.array,
    onSelected: PropTypes.func
  }
  state = {
    expandKeys: [], // 展开的菜单key值集合
    selectedKeys: {} // 选中的菜单key值集合
  }

  componentDidMount () {
    this.setDefaultExpandKeys()
  }

  // 勾选菜单
  selectedKeys = (keys, info) => {
    this.setState({
      selectedKeys: keys
    }, () => {
      this.props.onSelected(keys)
    })
  }

  // 点击展开/收起菜单
  onExpandMenuTree = (keys = []) => {
    this.setState({
      expandKeys: keys
    })
  }

  // 设置默认展开/选中的菜单
  setDefaultExpandKeys = () => {
    const { list = [] } = this.props
    let openArr = []
    let selectedArr = []

    if (list.length > 0) {
      list.map(menu => {
        let { children = [] } = menu
        if (menu.menuPermission === 'MENU_PERMISSION_YES') {
          openArr.push(`${menu.menuId}`)
        }

        if (children.length > 0) {
          children.map(item => {
            if (item.menuPermission === 'MENU_PERMISSION_YES') {
              selectedArr.push(`${item.menuId}`)
            }
          })
        }
      })
    }

    this.setState({
      expandKeys: openArr,
      selectedKeys: selectedArr
    })
  }

  render () {
    const { list = [] } = this.props
    const { expandKeys = [], selectedKeys = {} } = this.state

    return (
      <div>
        {
          <Tree
            checkable
            expandedKeys={expandKeys}
            checkedKeys={selectedKeys}
            onExpand={this.onExpandMenuTree}
            onCheck={this.selectedKeys}
          >
            {
              list.map(menu => (
                <TreeNode title={menu.menuName} key={`${menu.menuId}`}
                  style={{ display: 'inline-block', verticalAlign: 'top', minWidth: '200px' }}
                >
                  {
                    menu.children && menu.children.length > 0 && menu.children.map(item => (
                      <TreeNode title={item.menuName} key={`${item.menuId}`} />
                    ))
                  }
                </TreeNode>
              ))
            }
          </Tree>
        }

      </div>
    )
  }
}
