import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Col, Icon, Button } from 'antd'
import $lodash from 'lodash'

import Api from '@/common/api/index'
import moment from 'moment'

import AppletModal from './applet-modal'

import styles from '@/pages/finance/order-refund/refund.module.scss'

const saledStatusMap = [
  { type: 'ALL', label: '全部' },
  { type: 'AWAIT_CONFIRM', label: '待处理' },
  { type: 'ALREADY_CONFIRM', label: '已处理' },
  { type: 'REJECT', label: '已驳回' },
  { type: 'FINANCE_AUDITING', label: '财务审核中' }
]

const { confirm } = Modal

export default class SaledrefundModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    appletModal: false,
    saledDetail: {}, // 退款明细
    orderGoods: {}, // 产品信息
    imgList: [] // 上传的图片
  }

  componentDidMount () {
    this.getSaledOrderDetail()
  }

  // 获取退款订单详情
  getSaledOrderDetail = () => {
    const { detailInfo } = this.props
    let params = {
      afterSaleOrderId: detailInfo.afterSaleOrderId
    }

    Api.getSaledOrderDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _list = data.uploadVoucherList || []

        this.setState({
          orderGoods: data || {},
          imgList: _list
        })
      }
    })
  }

  // 点击预览图片
  handleJumpOpenPreview = (url) => {
    window.open(url, '_blank')
  }

  // 点击确定退款
  handleConfirmSalend = () => {
    confirm({
      title: '确定退款',
      content: '',
      onOk: () => {
        const { orderGoods } = this.state
        let params = {
          afterSaleOrderStatus: 'ALREADY_CONFIRM',
          afterSaleOrderId: orderGoods.afterSaleOrderId
        }

        this.props.onConfirm(params)
      }
    })
  }

  renderSaledOrderStaus = (status) => {
    const obj = saledStatusMap.find(item => { return item.type === status })
    const label = obj !== undefined ? obj.label : ''

    return label
  }

  // 根据状态渲染底部
  renderFooterInner = (status) => {
    let arr = [
      <Button key={`btnClose-0`} onClick={this.props.onCancel}>关闭</Button>
    ]

    if (status === 'FINANCE_AUDITING') {
      arr.push(
        <Button key={`btnClose-2`} type='primary'
          onClick={() => this.handleConfirmSalend()}
        >确定退款</Button>
      )
    }

    return arr
  }

  closeModal = () => {
    this.setState({
      appletModal: false
    })
  }

  render () {
    const { appletModal = false, orderGoods = {}, imgList = [] } = this.state

    return (
      <div>
        <Modal title='退款详情'
          width={1200}
          visible
          maskClosable={false}
          bodyStyle={{ padding: '10px' }}
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
          footer={this.renderFooterInner(orderGoods.afterSaleOrderStatus)}
        >
          <Row>
            <Row style={{
              padding: '10px', marginBottom: '10px', border: '1px solid #e8e8e8', backgroundColor: 'rgb(255, 218, 185)'
            }}
            >
              <Col span={3}>
                <div>型号</div>
                <div>{orderGoods.model}</div>
              </Col>
              <Col span={3}>
                <div>订货号</div>
                <div>{orderGoods.itemNumber}</div>
              </Col>
              <Col span={4}>
                <div>产品</div>
                <div>{orderGoods.name}</div>
              </Col>
              <Col span={3}>
                <div>退货数量</div>
                <div>{orderGoods.goodsCount}</div>
              </Col>
              <Col span={3}>
                <div>单价</div>
                <div>
                  &yen;{+orderGoods.sellingPrice}
                </div>
              </Col>
              <Col span={3}>
                <div>退款金额</div>
                {
                  orderGoods.afterSaleOrderStatus !== 'AWAIT_CONFIRM'
                    ? <div>&yen;{ ($lodash.round(+orderGoods.backAmount, 2) * 100 / 100).toFixed(2)}</div>
                    : <div>--</div>
                }

              </Col>
            </Row>
          </Row>

          <Row>
            <ul>
              <li className={`${styles.detailRow}`}>
                <label className={`${styles.detailLabel}`}>服务单号:</label>
                <span>{orderGoods.afterSaleOrderId}</span>
              </li>

              <li className={`${styles.detailRow}`}>
                <label className={`${styles.detailLabel}`}>订单号:</label>
                <span>{orderGoods.orderId}</span>
              </li>

              <li className={`${styles.detailRow}`}>
                <label className={`${styles.detailLabel}`}>申请状态:</label>
                <span>{this.renderSaledOrderStaus(orderGoods.afterSaleOrderStatus)}</span>
              </li>

              <li className={`${styles.detailRow}`}>
                <label className={`${styles.detailLabel}`}>申请时间:</label>
                <span>{moment(orderGoods.createTime).format('YYYY-MM-DD HH:mm:ss')}</span>
              </li>

              <li className={`${styles.detailRow}`}>
                <label className={`${styles.detailLabel}`}>用户账号:</label>
                <span>{orderGoods.userName}</span>
              </li>

              <li className={`${styles.detailRow}`}>
                <label className={`${styles.detailLabel}`}>真实姓名:</label>
                {
                  orderGoods.realName && orderGoods.realName !== ''
                    ? <span>{orderGoods.realName}</span>
                    : <span>--</span>
                }
              </li>

              <li className={`${styles.detailRow}`}>
                <label className={`${styles.detailLabel}`}>联系电话:</label>
                <span>{orderGoods.phoneNumber}</span>
              </li>

              <li className={`${styles.detailRow}`}>
                <label className={`${styles.detailLabel}`}>问题描述:</label>
                <span>{orderGoods.backRemark}</span>
              </li>

              <li className={`${styles.detailRow}`}>
                <div style={{ display: 'inline-block', width: '90px', textAlign: 'right', marginRight: '8px', verticalAlign: 'top', color: 'rgba(0, 0, 0, 0.85)' }}>
                相关图片:
                </div>
                <div style={{ display: 'inline-block' }}>
                  {
                    imgList.length > 0
                      ? <div>
                        {
                          imgList.map((item, i) => (
                            <div className={`${styles.thumbWrap}`} key={`saledPic-${i}`}>
                              <div className={styles.thumbInfo}>
                                <img src={item.image} />

                                <a className={styles.prewModal}>
                                  <Icon type='eye' style={{ fontSize: '20px' }}
                                    onClick={() => this.handleJumpOpenPreview(item.image)}
                                  />
                                </a>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                      : <span>暂无图片</span>
                  }
                </div>
              </li>
            </ul>
          </Row>
        </Modal>

        {
          appletModal
            ? <AppletModal
              payAmount={+orderGoods.totalPrice}
              onConfirm={(params) => { this.handleConfirmUpdateStatus(params) }}
              onCancel={this.closeModal}
            />
            : null
        }
      </div>
    )
  }
}
