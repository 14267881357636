import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Form, Input, Button, Icon, Upload, message } from 'antd'

import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'

import styles from './media.module.scss'

const FormItem = Form.Item
const { Dragger } = Upload

const { uploadOrdinaryImageUrl } = UploadApi

class MediaForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    mediaType: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    type: 1 // 资源方式 1- 网络资源 2-本地资源
  }

  componentDidMount () { }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values['type'] = this.props.mediaType

        this.props.onConfirm(values)
      }
    })
  }

  // 点击切换资源方式
  handleToggleResources = () => {
    let { type = 1 } = this.state
    this.setState({
      type: type === 1 ? 2 : 1
    })
  }

  // 上传图片
  handleChangeUpload = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response

      if (+code === 10000) {
        setTimeout(() => {
          this.props.form.setFieldsValue({
            url: data.imageUrl
          })
        }, 20)
      } else {
        message.error('上传失败')
      }
    }
  }

  // 点击删除
  handleDelete = () => {
    this.props.form.setFieldsValue({
      url: ''
    })
  }

  render () {
    const { mediaType } = this.props
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { type = 1 } = this.state

    return (
      <>
        <Modal title='媒体库' width={800} bodyStyle={{ padding: '0px' }}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          footer={null}
        >
          <Form>
            <Row className={`${styles.mediaContent}`}>
              {
                type === 1
                  ? <Row className={`${styles.mediaExternal}`}>
                    <FormItem>
                      {
                        getFieldDecorator('url', {

                        })(
                          <Input placeholder='资源地址' autoComplete='off' />
                        )
                      }
                    </FormItem>
                  </Row>
                  : <Row style={{ width: '100%', padding: '0px 15px' }}>
                    {
                      getFieldValue('url') === undefined || getFieldValue('url') === ''
                        ? <Dragger name='file'
                          showUploadList={false}
                          accept={mediaType === 'IMAGE' ? 'image/png, image/jpg, image/jpeg, image/gif' : 'video/mp4'}
                          headers={{ Token: getStore('token') }}
                          action={uploadOrdinaryImageUrl}
                          onChange={(info) => this.handleChangeUpload(info)}
                        ><div className={`${styles.uploadTips}`}>点击上传</div>
                        </Dragger>
                        : <div className={`${styles.picBox}`}>
                          {
                            mediaType === 'IMAGE'
                              ? <img className={`${styles.pic}`} src={getFieldValue('url')} />
                              : <video src={this.props.form.getFieldValue('url')} />
                          }
                          <div className={`${styles.deleteWrap}`}>
                            <a onClick={() => this.handleDelete()}><Icon type='delete' style={{ fontSize: '24px', color: '#ffffff' }} /></a>
                          </div>
                        </div>
                    }

                    <FormItem style={{ display: 'none' }}>
                      {
                        getFieldDecorator('url', {

                        })(
                          <Input autoComplete='off' disabled />
                        )
                      }
                    </FormItem>
                  </Row>
              }
            </Row>

            <Row className={`${styles.mediaFooter}`}>
              <FormItem style={{ marginBottom: '0px', display: 'inline-block', width: '50%' }}>
                <a className={`${styles.linkBtn}`} onClick={() => this.handleToggleResources()}>
                  <Icon type='plus' style={{ marginRight: '4px' }} />{`添加${type === 1 ? '本地资源' : '网络资源'}`}
                </a>
              </FormItem>
              <FormItem style={{ marginBottom: '0px', display: 'inline-block', width: '50%', textAlign: 'right' }}>
                <Button onClick={() => this.props.onCancel()}>取消</Button>

                <Button type='primary' style={{ marginLeft: '15px' }}
                  onClick={() => this.handleConfirm()}
                  disabled={getFieldValue('url') === undefined || getFieldValue('url') === ''}
                >
                  确定
                </Button>
              </FormItem>
            </Row>
          </Form>
        </Modal>
      </>
    )
  }
}

const MediaModal = Form.create()(MediaForm)

export default MediaModal
