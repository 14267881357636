import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Card, Table, Button, Icon, Modal } from 'antd'
import $lodash from 'lodash'

import { setHandleModal } from '@/reducers/action'
import SearchForm from '@/components/tool-form/index'
import OperateInfo from './operate-info'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

import styles from './condition.module.scss'
const { pageSizeOptions, repLineBreak } = filter
const operateMaps = [
  {},
  { code: 1, type: 'TASK_PUT_ON_SHELVES', label: '上架操作', desc: '从型号库上架' },
  { code: 2, type: 'TASK_PULL_OFF_SHELVES', label: '下架操作', desc: '产品下架' },
  { code: 3, type: 'TASK_PRODUCT_ADD', label: '添加到产品库操作', desc: '从型号库添加至我的产品库' },
  { code: 4, type: 'TASK_PRODUCT_DELETE', label: '删除操作', desc: '产品删除' },
  { code: 5, type: 'TASK_PRODUCT_PUT_ON_SHELVES', label: '上架操作', desc: '从产品库上架' }
]

class SearchCondition extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func
  }
  state = {
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌',
          extra: ''
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        rules: {

        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      },
      {
        name: 'model',
        formType: 'TextArea',
        itemParams: {
          label: '型号',
          extra: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          rows: 4
        }
      },
      {
        name: 'itemNumber',
        formType: 'TextArea',
        itemParams: {
          label: '订货号',
          extra: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          rows: 4
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    operateType: 1, // 操作类型 1-从型号库上架操作 2-下架操作 3-添加产品库操作 4-删除操作 5-从产品库上架
    detailInfo: {},
    query: {}, // 检索条件
    failModal: false,
    failNumber: 0,
    totalNumber: 0,
    successNumber: 0,
    failList: []
  }
  columns = [
    {
      title: '序号',
      key: 'cloudprod-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'cloudprod-1',
      width: 120,
      ellipsis: true,
      render: (text, record, index) => (
        <div>
          <div>{record.brandName}</div>
          <div>
            {
              record.englishBrandName && record.englishBrandName !== ''
                ? record.englishBrandName
                : '--'
            }
          </div>
        </div>
      )
    },
    {
      title: '型号',
      key: 'cloudprod-2',
      width: 160,
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'cloudprod-3',
      width: 130,
      dataIndex: 'itemNumber',
      ellipsis: true
    },
    {
      title: '产品',
      key: 'cloudprod-5',
      width: 180,
      ellipsis: true,
      render: (text, record, index) => (
        <div className={`coloneLine`}>
          {record.name}
        </div>
      )
    },
    {
      title: '分类',
      key: 'cloudprod-6',
      dataIndex: 'classificationName',
      ellipsis: true
    }
  ]

  componentDidMount () {
    const { state = {} } = this.props.history.location
    const { query = {} } = this.state

    this.setState({
      detailInfo: state.detailInfo || {},
      operateType: state.operateType,
      query: {
        ...query,
        brandId: [+state.brandId]
      }
    }, () => {
      this.getBrandList({ brandListType: 'ALL_BRAND' })
    })
  }

  // 获取品牌
  getBrandList = (params) => {
    Api.getBrandList(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        let { searchData, query = {} } = this.state
        let _list = data || []

        if (_list.length > 0) {
          _list.map(item => {
            item.showLabel = item.brandName

            if (item.englishBrandName && item.englishBrandName !== '') {
              item.showLabel = `${item.brandName}/${item.englishBrandName}`
            }
          })
        }

        searchData[0].options = _list

        if (+query.brandId > 0) {
          searchData[0].rules = {
            initialValue: +query.brandId
          }
        }

        this.setState({
          searchData
        }, () => {
          if (+query.brandId > 0) {
            this.getProductListInsearchCondition()
          }
        })
      }
    })
  }

  // 获取搜索结果数据
  getProductListInsearchCondition = () => {
    const { pages, query = {}, operateType } = this.state
    let params = {
      ...query,
      searchType: operateMaps[operateType].type,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.getProductListInsearchCondition(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        if (+code === 10000) {
          this.setState({
            tableData: data.pageBean.list || [],
            pages: {
              ...pages,
              total: data.pageBean.total
            },
            failModal: +data.failNumber > 0,
            failNumber: +data.failNumber,
            totalNumber: +data.totalNumber,
            successNumber: +data.successNumber,
            failList: data.failMessageList || []
          })
        }
      }
    })
  }

  // 提交按条件批量操作
  saveBatchOperateByConditionInProductModule = (params) => {
    Api.saveBatchOperateByConditionInProductModule(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        setTimeout(() => {
          this.props.dispatch(setHandleModal({ handleModal: false }))

          Modal.success({
            title: '任务提交成功',
            content: <div>
              <p>系统正在处理...</p>
              <p>此次任务处理需要一段时间，请耐心等待</p>
            </div>,
            onOk: () => {
              this.props.history.go(-1)
            }
          })
        }, 2000)
      }
    })
  }

  // 点击确定
  handleSubmit = () => {
    const { query = {}, detailInfo = {}, operateType } = this.state

    let params = {
      ...query,
      ...detailInfo,
      searchType: operateMaps[operateType].type,
      pageNo: 1,
      pageSize: 20
    }
    this.props.dispatch(setHandleModal({ handleModal: true }))
    this.saveBatchOperateByConditionInProductModule(params)
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    let _search = {}

    Object.keys(search).forEach(item => {
      if (search[item]) {
        _search[item] = search[item]
      }
    })

    if ($lodash.isEmpty(_search)) {
      Modal.warning({
        title: '请选择条件',
        content: <div>
          您可选择要执行操作的品牌 或输入型号、订货号
        </div>
      })
      return false
    }

    Object.keys(_search).forEach(key => {
      if (key === 'brandId') {
        _search[key] = [_search[key]]
      }

      if (key === 'model' || key === 'itemNumber') {
        let vals = repLineBreak(_search[key], '<br>')
        _search[key] = vals.split('<br>')
      }
    })

    this.setState({
      query: _search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getProductListInsearchCondition()
    })
  }

  // 点击返hui
  handleBack = () => {
    this.props.history.go(-1)
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getProductListInsearchCondition()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getProductListInsearchCondition()
    })
  }

  render () {
    const { pages, searchData, detailInfo = {}, operateType = 1, query = {}, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div style={{ paddingBottom: '60px' }}>
        <Row style={{ marginBottom: '20px' }}>
          <Button onClick={() => this.handleBack()}>
            <Icon type='left' />返回
          </Button>
        </Row>

        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <h3>将要执行按条件批量{operateMaps[+operateType].label}</h3>

            {
              operateType === 1 || operateType === 2 || operateType === 5
                ? <Row>
                  <OperateInfo
                    operateType={operateType}
                    detailInfo={detailInfo}
                  />
                </Row>
                : null
            }

          </Card>
        </Row>

        <Row>
          <Card bodyStyle={{ padding: '15px' }}>
            <Row style={{ marginBottom: '15px' }}>
              <SearchForm
                {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
            <Row>
              <Table rowKey='commoditySkuId'
                columns={this.columns}
                dataSource={tableData}
                pagination={pagination}
              />
            </Row>
          </Card>
        </Row>

        <Row className={`${styles.submitLand}`}>
          <Button type='primary'
            disabled={$lodash.isEmpty(query) || tableData.length === 0}
            onClick={() => this.handleSubmit()}>
            确定
          </Button>
        </Row>
      </div>
    )
  }
}

export default connect()(SearchCondition)
