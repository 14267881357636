import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, Row, Alert, Table, Button, InputNumber, Checkbox } from 'antd'
import $lodash from 'lodash'

import ErrorRow from '../errorRow/errorRow'

import { setOrderBatchSkuList, setManualOrderAmounts } from '@/reducers/action'
import filter from '@/common/utils/filter'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const { shipmentUtilMap } = filter

class ProductTable extends Component {
  static propTypes = {
    list: PropTypes.array,
    manualOrderAmounts: PropTypes.object,
    dispatch: PropTypes.func,
    selectedEndInfo: PropTypes.object
  }
  state = {

  }

  columns = [
    {
      title: '序号',
      key: 'manualProductCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '',
      key: 'manualProductCol-1',
      width: 100,
      ellipsis: true,
      dataIndex: 'itemNo',
      render: (text, record, index) => {
        const { commoditySkuId, skuGoodsType, imageUrl = '' } = record
        const obj = {
          children: <div className={styles.prodBoximg}>
            <Link to={{ pathname: '/commodity-detail', search: `?commoditySkuId=${commoditySkuId}&skuGoodsType=${skuGoodsType}` }} target='_blank'>
              <img src={imageUrl} className={styles.prodBoximgs} />
            </Link>
          </div>,
          props: {}
        }

        if (record.quickOrderSearchResult !== 'SUCCESS') {
          obj.props.colSpan = 0
        }

        return obj
      }
    },
    {
      title: '产品信息',
      key: 'manualProductCol-2',
      render: (text, record, index) => {
        const obj = {
          children: {},
          props: {}
        }

        if (record.quickOrderSearchResult === 'SUCCESS') {
          obj.children = (
            <Row>
              <Link to={{ pathname: '/commodity-detail', search: `?commoditySkuId=${record.commoditySkuId}&skuGoodsType=${record.skuGoodsType}` }} target='_blank'>
                <span className={`${styles.brand}`}>
                  {record.brandName}{record.englishBrandName && record.englishBrandName !== '' ? `/${record.englishBrandName}` : null}
                </span>
                <div className={`${styles.name}`} dangerouslySetInnerHTML={{ __html: record.name }} />

                {/* 型号、订货号 */}
                <p className={`${styles.prodProps}`}>
                  <label className={`${styles.propsLabel}`}>型<em style={{ visibility: 'hidden' }}>型</em>号</label>
                  <span dangerouslySetInnerHTML={{ __html: record.model }} />
                </p>

                <p className={`${styles.prodProps}`}>
                  <label className={`${styles.propsLabel}`}>订货号</label>
                  <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
                </p>
              </Link>
            </Row>
          )
        } else if (record.quickOrderSearchResult === 'ERROR' || record.quickOrderSearchResult === 'CHANGE_CHANNEL') {
          obj.children = (
            <ErrorRow rowIndex={index}
              itemNumber={record.itemNumberModel}
              onRemove={() => { this.handleRemoveRow(record) }}
              selectedEndInfo={this.props.selectedEndInfo}
              searchResult={record.quickOrderSearchResult}
            />
          )
          obj.props.colSpan = 5
        }

        return obj
      }
    },
    {
      title: <Row className={`${styles.hpriceCol}`}>
        <div className={`${styles.headPrice}`}>单价</div>
        <div className={`${styles.headQuantity}`}>数量</div></Row>,
      key: 'manualProductCol-4',
      width: 740,
      render: (text, record, index) => {
        const obj = {
          children: <Row>{this.renderCloudPriceCell(record, index)}</Row>,
          props: {}
        }

        if (record.quickOrderSearchResult !== 'SUCCESS') {
          obj.props.colSpan = 0
        }

        return obj
      }
    },
    {
      title: '金额',
      key: 'manualProductCol-5',
      width: 140,
      render: (text, record, index) => {
        const obj = {
          children: <div>{ this.renderCalcSumTotal(record) }</div>,
          props: {}
        }

        if (record.quickOrderSearchResult !== 'SUCCESS') {
          obj.props.colSpan = 0
        }

        return obj
      }
    },
    {
      title: '操作',
      key: 'manualProductCol-6',
      width: 80,
      render: (text, record, index) => {
        const obj = {
          children: (
            <div>
              <Button type='primary' size='small' onClick={() => this.handleRemoveRow(record)}>删除</Button>
            </div>
          ),
          props: {}
        }

        if (record.quickOrderSearchResult !== 'SUCCESS') {
          obj.props.colSpan = 0
        }

        return obj
      }
    }
  ]

  componentDidMount () { }

  // 点击删除
  handleRemoveRow = (record) => {
    let { list = [] } = this.props
    let i = $lodash.findIndex(list, (item) => { return item.rowKey === record.rowKey })

    list.splice(i, 1)

    const _manualOrderAmounts = this.calcSelectedAmounts([...list])
    this.props.dispatch(setOrderBatchSkuList({ batchSkuList: [...list] }))
    this.props.dispatch(setManualOrderAmounts({ manualOrderAmounts: { ..._manualOrderAmounts } }))
  }

  // 监听-价格选中
  handleChangePriceChecked = (checked, n, i) => {
    let { list = [] } = this.props

    list[n]['supplyInfoList'][i].checked = checked

    const _manualOrderAmounts = this.calcSelectedAmounts([...list])
    this.props.dispatch(setOrderBatchSkuList({ batchSkuList: [...list] }))
    this.props.dispatch(setManualOrderAmounts({ manualOrderAmounts: { ..._manualOrderAmounts } }))
  }

  // 监听-数量变化
  handleChangeQuantity = (value, n, i) => {
    let { list = [] } = this.props

    list[n]['supplyInfoList'][i]['quantity'] = value

    const _manualOrderAmounts = this.calcSelectedAmounts([...list])
    this.props.dispatch(setOrderBatchSkuList({ batchSkuList: [...list] }))
    this.props.dispatch(setManualOrderAmounts({ manualOrderAmounts: { ..._manualOrderAmounts } }))
  }

  // 计算相关金额
  calcSelectedAmounts = (list) => {
    /* 过滤掉错误的数据 */
    const newdatas = $lodash.filter(list, (item) => { return item.skuGoodsType })
    let _totalAmount = 0 // 商品总金额
    let _spotAmount = 0 // 现货总金额
    let _futuresFrontMoney = 0 // 期货定金

    if (newdatas.length > 0) {
      for (let record of newdatas) {
        let { supplyInfoList = [], skuGoodsType } = record

        /* 过略掉未选中的价格和没填写数量的数据 */
        let newArr = $lodash.filter(supplyInfoList, (stem) => { return stem.checked && +stem.quantity > 0 })

        if (newArr.length > 0) {
          _totalAmount += $lodash.sumBy(newArr, (atem) => { return +atem.price * 100 * +atem.quantity / 100 })
        }

        if (['SPOT', 'CLOUD_SPOT'].includes(skuGoodsType)) {
          _spotAmount += $lodash.sumBy(newArr, (atem) => { return +atem.price * 100 * +atem.quantity / 100 })
        }

        if (['FUTURES', 'CLOUD_FUTURES'].includes(skuGoodsType)) {
          _futuresFrontMoney += $lodash.sumBy(newArr, (atem) => { return +atem.price * 100 * atem.prepaymentRatio * +atem.quantity / 100 / 100 })
        }
      }
    }

    return {
      totalAmount: _totalAmount,
      spotAmount: _spotAmount,
      futuresFrontMoney: _futuresFrontMoney
    }
  }

  /* 渲染登录状态单价CELL
   *
  */
  renderCloudPriceCell = (record, n) => {
    const { supplyInfoList = [], skuGoodsType } = record

    return (
      <>
        {
          supplyInfoList && supplyInfoList.length > 0

            ? <Row>
              {
                supplyInfoList.map((item, i) => (
                  <Row className={`${styles.priceRow}`} key={`priceCell-${record.commoditySkuId}-${i}`}>

                    <div className={`${styles.priceCol}`}>
                      <div className={`${styles.inlineCol}`}>
                        <div className={`${styles.alignCenter}`}>
                          <Checkbox checked={item.checked}
                            onChange={(e) => this.handleChangePriceChecked(e.target.checked, n, i)}
                          />
                          <span style={{ marginLeft: '5px' }}>{+item.price > 0 ? <>&yen;{ +item.price }</> : `面议`}</span>

                        </div>
                      </div>

                      {
                        ['SPOT', 'CLOUD_SPOT'].includes(skuGoodsType)
                          ? <div className={`${styles.inlineCol}`}>
                            <div className={`${styles.alignCenter}`}>
                              <span className={`${styles.tagKey}`}>{ renderSkuGoodsTypeLabel(skuGoodsType, 'aliasName') }</span>
                              <span className={`${styles.tagValue}`}>库存:&nbsp;{+item.stock}</span>
                            </div>
                          </div>
                          : null
                      }

                      {
                        ['FUTURES', 'CLOUD_FUTURES'].includes(skuGoodsType)
                          ? <>
                            <div className={`${styles.inlineCol} ${styles.inlineFutures}`}>
                              <div className={`${styles.alignCenter}`}>
                                <span className={`${styles.tagKey} ${styles.futuresTag}`}>{ renderSkuGoodsTypeLabel(skuGoodsType, 'aliasName') }</span>
                                <span className={`${styles.tagValue}`}>周期:&nbsp;
                                  {+item.deliveryPeriodDays}{shipmentUtilMap[skuGoodsType]}
                                </span>
                              </div>
                            </div>

                            <div className={`${styles.inlineCol}`}>
                              <div className={`${styles.alignCenter}`}>
                                <span className={`${styles.tagValue}`}>预付比例:&nbsp;
                                  {(+item.prepaymentRatio * 100 / 100).toFixed(2)}%
                                </span>
                              </div>
                            </div>
                          </>
                          : null
                      }
                    </div>

                    <div className={`${styles.quantityCol}`}>
                      {
                        item.checked
                          ? <InputNumber size='small' value={item.quantity} min={0}
                            onChange={(value) => this.handleChangeQuantity(value, n, i)}
                          />
                          : null
                      }
                    </div>
                  </Row>
                ))
              }
            </Row>
            : null
        }
      </>
    )
  }

  // 提示文案
  renderAlertTipText = () => {
    const { manualOrderAmounts = {} } = this.props
    const { totalAmount = 0, spotAmount = 0, futuresFrontMoney = 0 } = manualOrderAmounts

    return (
      <div className={`${styles.tipRow}`}>
        <div className={`${styles.tipCol}`}>
          <label className={`${styles.tipLabel}`}>产品总金额</label>
          <span>&yen;{ (+totalAmount * 100 / 100).toFixed(2) }</span>
        </div>

        <div className={`${styles.tipCol}`}>
          <label className={`${styles.tipLabel}`}>现货金额</label>
          <span>&yen;{ (+spotAmount * 100 / 100).toFixed(2) }</span>
        </div>

        <div className={`${styles.tipCol}`}>
          <label className={`${styles.tipLabel}`}>期货定金</label>
          <span>&yen;{ (+futuresFrontMoney * 100 / 100).toFixed(2) }</span>
        </div>

        <div className={`${styles.tipCol}`}>
          <label className={`${styles.tipLabel}`}>期货尾款</label>
          <span>&yen;{ ((totalAmount - spotAmount - futuresFrontMoney) * 100 / 100).toFixed(2) }</span>
        </div>

        <div className={`${styles.tipCol}`}>
          <label className={`${styles.tipLabel}`}>运费</label>
          <span>免运费</span>
        </div>

        <div className={`${styles.tipCol}`}>
          <label className={`${styles.tipLabel}`}>本次应付</label>
          <span>{ ((spotAmount + futuresFrontMoney) * 100 / 100).toFixed(2) }</span>
        </div>
        <div className={`${styles.tipCol}`}>
          <label className={`${styles.tipLabel}`}>剩余应付</label>
          <span>&yen;{ ((totalAmount - spotAmount - futuresFrontMoney) * 100 / 100).toFixed(2) }</span>
        </div>
      </div>
    )
  }

  // 计算单品金额
  renderCalcSumTotal = (record) => {
    const { supplyInfoList = [] } = record
    let newArr = $lodash.filter(supplyInfoList, (stem) => { return stem.checked && +stem.quantity > 0 })
    const total = newArr.length > 0 ? $lodash.sumBy(newArr, (atem) => { return +atem.price * +atem.quantity }) : 0

    return <>&yen;{(total * 100 / 100).toFixed(2)}</>
  }

  render () {
    const { list = [] } = this.props
    return (
      <Card bodyStyle={{ padding: '15px' }}>
        <Row style={{ marginBottom: '8px' }}>
          <Alert type='info' message={this.renderAlertTipText()} />
        </Row>

        <Table rowKey='rowKey'
          columns={this.columns}
          dataSource={list}
          pagination={false}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.batchSkuList,
    manualOrderAmounts: state.manualOrderAmounts || {}
  }
}

export default connect(mapStateToProps)(ProductTable)
