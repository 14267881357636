import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, message, Modal } from 'antd'

import Api from '@/common/api/index'

import SetfloorModal from './setfloor1Modal'
import FloorModal from './floorModal'

import styles from './navdecorate.module.scss'

const { confirm } = Modal

export default class FloorTpl3 extends Component {
  static propTypes = {
    floorInfo: PropTypes.object,
    onRefush: PropTypes.func,
    onDelete: PropTypes.func
  }
  state = {
    editModal: false,
    floorModal: false
  }

  componentDidMount () {

  }

  // 保存编辑楼层名称
  saveEditNavFloorName = (params) => {
    Api.saveEditNavFloorName(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.props.onRefush()
      }
    })
  }

  // 更新楼层内容
  updateNavFloorContainer = (params) => {
    Api.updateNavFloorContainer(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.props.onRefush()
      }
    })
  }

  // 点击编辑楼层
  handleShowEditModal = () => {
    this.setState({
      editModal: true
    })
  }

  // 点击删除楼层
  handleRemoveFloor = () => {
    confirm({
      title: '确定删除该楼层？',
      onOk: () => {
        const { floorInfo = {} } = this.props
        this.props.onDelete({ id: floorInfo.id })
      }
    })
  }

  // 点击编辑名称
  handleShowEditNameModal = () => {
    this.setState({
      floorModal: true
    })
  }

  closeModal = () => {
    this.setState({
      editModal: false,
      floorModal: false
    })
  }

  render () {
    const { floorInfo = {} } = this.props
    const { editModal = false, floorModal = false } = this.state
    const { elementList = [] } = floorInfo
    let rowDetail = {}

    if (elementList && elementList.length > 0) {
      rowDetail = elementList[0]
    }

    return (
      <div>
        <Row className={`${styles.floorRow}`} gutter={12}>
          <Col span={20}>
            <div className={`${styles.floorTpl3} ${styles.solidLine}`}>
              <div className={`${styles.floorTitle}`}>
                {floorInfo.name}
              </div>

              {
                +rowDetail.id > 0
                  ? <div className={`${styles.flex}`}>
                    {
                      elementList.map((item, i) => (
                        <div className={`${styles.pullLeft}`} key={`f3-key-${i}`}>
                          <div className={`${styles.imgWrap}`}>
                            <img src={item.imageUrl} />
                          </div>
                        </div>
                      ))
                    }

                    <div className={`${styles.mediaBody}`}>
                      <div>
                        {rowDetail.remark}
                      </div>
                    </div>
                  </div>
                  : <div className={`${styles.flex}`}>
                    <div className={`${styles.pullLeft}`}>
                      <div className={`${styles.imgWrap} ${styles.seat}`}>
                        图片
                      </div>
                    </div>
                    <div className={`${styles.mediaBody} ${styles.seat}`}>
                      <div>
                        描述文案
                      </div>
                    </div>
                  </div>
              }
            </div>
          </Col>

          <Col span={4}>
            <div style={{ marginBottom: '10px' }}>
              <Button type='primary' size='small'
                onClick={() => this.handleShowEditNameModal()}
              >
                编辑名称
              </Button>
            </div>

            <div style={{ marginBottom: '10px' }}>
              <Button type='primary' size='small'
                onClick={() => this.handleShowEditModal()}
              >
                编辑内容
              </Button>
            </div>

            <div>
              <Button type='danger' size='small'
                onClick={() => this.handleRemoveFloor()}
              >
                删除楼层
              </Button>
            </div>
          </Col>
        </Row>

        {
          editModal
            ? <SetfloorModal
              type={+rowDetail.id > 0 ? 2 : 1}
              detailInfo={floorInfo}
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.updateNavFloorContainer(params)}
            />
            : null
        }

        {
          floorModal
            ? <FloorModal
              type={2}
              detailInfo={{ name: floorInfo.name }}
              onCancel={this.closeModal}
              onConfirm={(params) => this.saveEditNavFloorName(params)}
            />
            : null
        }
      </div>
    )
  }
}
