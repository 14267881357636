import React from 'react'
import PropTypes from 'prop-types'
import { InputNumber } from 'antd'

import styles from './index.module.scss'

export default function GoodsQuantityItem (props) {
  const { list = [], commoditySkuId, skuGoodsType, disabled = false, onChangeInput } = props

  return (
    <ul className={`${styles.goodsPriceWrap}`}>
      {
        list.map((item, i) => (
          <li className={`${styles.goodsItemContent}`} key={`QUANTITY-${commoditySkuId}-${skuGoodsType}-${i}`}>
            {
              item.checked
                ? <>
                  <InputNumber size='small' min={1} disabled={disabled}
                    value={item.quantity}
                    onChange={(value) => onChangeInput(value, i)}
                  />

                  {
                    skuGoodsType === 'CLOUD_SPOT' && item.quantity > item.stock
                      ? <span style={{ marginLeft: '6px', color: '#F5222D' }}>库存不足</span>
                      : null
                  }

                  {
                    +item.quantity < +item.minOrder
                      ? <span style={{ marginLeft: '6px', color: '#F5222D' }}>最少购买{item.minOrder}件</span>
                      : null
                  }
                </>
                : null
            }
          </li>
        ))
      }
    </ul>
  )
}

GoodsQuantityItem.propTypes = {
  list: PropTypes.array,
  commoditySkuId: PropTypes.number,
  skuGoodsType: PropTypes.string,
  disabled: PropTypes.bool,
  onChangeInput: PropTypes.func
}
