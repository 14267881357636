import React, { Component } from 'react'
import { Row, Tabs } from 'antd'

import PurchorderPay from './purchorderPay' // 云采购付款
import TransportBill from '../transport-bill/index' // IIA运费对账单
import PaninsuranceBill from '../paninsurance-bill/index' // 保险账单

import styles from './iiasaas.module.scss'

const { TabPane } = Tabs
const menuMaps = [
  { title: '云采购支付', key: 'PURCHASEPAY' },
  { title: '运费账单（德邦）', key: 'SAASDBLBILL' },
  // { title: '运费账单（顺丰）', key: 'SAASSFBILL' },
  { title: '保险账单', key: 'PANINSURANCE' }
]

export default class IIAsaasFinance extends Component {
  static propTypes = {}
  state = {
    curTabkey: 'PURCHASEPAY'
  }

  // 点击切换tab
  handleToggleTabmenu = (key) => {
    this.setState({
      curTabkey: key
    })
  }

  render () {
    const { curTabkey = 'PURCHASEPAY' } = this.state

    return (
      <>
        <Row className={`${styles.tabBarLand}`}>
          <Row className={`${styles.tabBar}`}>
            <Tabs onChange={this.handleToggleTabmenu}>
              {
                menuMaps.map((menu, n) => (
                  <TabPane tab={menu.title} key={menu.key} />
                ))
              }
            </Tabs>
          </Row>
        </Row>

        {
          curTabkey === 'PURCHASEPAY'
            ? <PurchorderPay />
            : null
        }

        {
          curTabkey === 'SAASDBLBILL'
            ? <TransportBill />
            : null
        }

        {
          curTabkey === 'SAASSFBILL'
            ? <TransportBill />
            : null
        }

        {
          curTabkey === 'PANINSURANCE'
            ? <PaninsuranceBill />
            : null
        }

      </>
    )
  }
}
