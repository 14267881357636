import React, { Component } from 'react'
import { Card, Row } from 'antd'

import styles from './video.module.scss'

export default class PriceVideo extends Component {
  render () {
    return (
      <div>
        <Card bodyStyle={{ padding: '15px' }}>
          <Row>
            <div className={`${styles.videoWrap}`}>
              <video id='stockVideo'
                style={{ display: 'block', margin: '0px auto', width: '800px' }}
                controls
                src='https://picture.china-iia.com/video/%E4%BB%B7%E6%A0%BC%E8%AE%BE%E7%BD%AE%E7%BB%88%E7%89%882020-06-08_x264.mp4'
              />
            </div>
          </Row>
        </Card>
      </div>
    )
  }
}
