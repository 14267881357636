import Ajax from '../utils/ajax'

export default {
  // 快递服务- 获取出库单号
  getOutboundOrderNum (params) {
    return Ajax.get('/api/warehouse/get/picking/distributorOrderNo', { params })
  },

  // 快递服务- 确认发货
  saveExpressServiceSendGoods (params) {
    return Ajax.post('/api/warehouse/supplier/confirm/deliver/goods', params)
  },

  // 获取快递记录列表
  getExpressRecordsList (params) {
    return Ajax.post('/api/warehouse/get/distributorPickingInfo/list', params)
  },

  // 快递服务-查询快递是否可取消
  getServiceCanCancelExpress (params) {
    return Ajax.post('/api/warehouse/getSupplierAllowCancel', params)
  },

  // 快递服务-取消出库单快递
  cancelGoodsOutExpress (params) {
    return Ajax.post('/api/warehouse/supplier/cancel/deliver/goods', params)
  },

  // 快递记录- 更新出库单备注信息
  updateExpressRecordsOutboundRemarks (params) {
    return Ajax.post('/api/warehouse/update/distributorPickingInfo', params)
  }
}
