export const tableColumnsMaps = {
  PRICE_DEFAULT: [ // 现货价格设置- 现货通用价格导入
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'importLowestPrice' },
    { key: 'importDefaultPrice' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' },
    { key: 'operation' }
  ],
  FUTURE_PRICE_DEFAULT: [ // 期货价格设置- 期货通用价格导入
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'importLowestPrice' },
    { key: 'importDefaultPrice' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' },
    { key: 'operation' }
  ],
  PRICE_GROUP_COMMON: [ // 现货价格设置- 现货通用价格组导入
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'importDefaultPrice' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' },
    { key: 'operation' }
  ],
  FUTURE_PRICE_GROUP_COMMON: [ // 期货价格设置- 期货通用价格组导入
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'importDefaultPrice' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' },
    { key: 'operation' }
  ],
  PRICE_GUEST: [ // 现货价格设置- 现货客户价格导入
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'importGuestPrice' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' },
    { key: 'operation' }
  ],
  FUTURE_PRICE_GUEST: [ // 期货价格设置- 期货客户价格导入
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'importGuestPrice' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' },
    { key: 'operation' }
  ],
  PRICE_GROUP_GUEST: [ // 现货价格设置- 现货客户价格组导入
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'importGuestPrice' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' },
    { key: 'operation' }
  ],

  FUTURE_PRICE_GROUP_GUEST: [ // 期货价格设置- 期货客户价格组导入
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'importGuestPrice' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' },
    { key: 'operation' }
  ],
  PRODUCT_STOCK: [ // 现货入库库存导入
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'importTotalStock' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' },
    { key: 'operation' }
  ],
  PRODUCT_CLOUD_SUPPLY: [ // 产品云供应设置-现货价格导入
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'importSupplyPrice' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' },
    { key: 'operation' }
  ],
  PRODUCT_CLOUD_FUTURES_SUPPLY: [ // 产品云供应设置- 期货价格导入
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'importSupplyPrice' },
    { key: 'deliveryPeriodDays' },
    { key: 'prepaymentRatio' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' },
    { key: 'operation' }
  ],
  ENQUIRY: [ // 询价产品导入
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' },
    { key: 'operation' }
  ],
  PRODUCT_STOCK_PUT_ON: [ // 导入-现货上架
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' },
    { key: 'operation' }
  ],
  PRODUCT_FUTURE_PUT_ON: [ // 导入-期货上架
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' },
    { key: 'operation' }
  ],
  PRODUCT_ADD: [ // 导入-添加到产品库
    { key: 'indexNumber' },
    { key: 'brandName' },
    { key: 'matchNo' },
    { key: 'matchResult' },
    { key: 'failReason' },
    { key: 'model' },
    { key: 'itemNumber' },
    { key: 'name' },
    { key: 'operation' }
  ]
}
