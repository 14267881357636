import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, InputNumber, Input, Select } from 'antd'

import Api from '@/common/api/index'

const FormItem = Form.Item
const Option = Select.Option

const groupTypeMaps = [
  { id: 'COMMON', name: '通用类型' },
  { id: 'GUEST', name: '客户类型' }
]

class PricegroupForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number,
    groupType: PropTypes.string,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    selectedType: '', // 选择的类型
    euOptions: [], // EU客户列表
    info: {},
    defaultUserIds: [] // 默认选中的客户集合
  }

  componentDidMount () {
    const { type = 1, detailInfo = {} } = this.props

    this.getGuesteuUserLike('')
    if (type === 2) {
      this.getPriceGroupInfo()
    } else {
      this.setState({
        selectedType: detailInfo.groupType
      })
    }
  }

  // 查询客户信息
  getGuesteuUserLike = (val = '') => {
    let params = {
      pageNo: 1,
      pageSize: 999,
      searchName: val
    }

    Api.getGuesteuUserLike(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          euOptions: data.list || []
        })
      }
    })
  }

  // 获取价格组详细信息
  getPriceGroupInfo = () => {
    const { detailInfo, groupType = 'COMMON' } = this.props
    let params = {
      priceGroupId: detailInfo.priceGroupId
    }

    if (groupType === 'COMMON') {
      this.getCommonGroupDetailByGroupId(params)
    } else {
      this.getGuestGroupDetailByGroupId(params)
    }
  }

  // 获取通用组信息
  getCommonGroupDetailByGroupId = async (params) => {
    const { detailInfo } = this.props
    let handleDetail = detailInfo.state === 'future'
      ? await Api.getFutureCommonGroupDetailByGroupId(params)
      : await Api.getCommonGroupDetailByGroupId(params)
    const { code, data } = handleDetail
    if (+code === 10000) {
      let str = ''
      let _selectedUserIds = []

      if (data && data.commonGuestType && data.commonGuestType !== '') {
        str = data.commonGuestType
      }

      if (data.userInfoList && data.userInfoList.length > 0) {
        data.userInfoList.map(item => {
          _selectedUserIds.push(item.endUserId)
        })
      }

      this.setState({
        info: data || {},
        defaultUserIds: _selectedUserIds,
        selectedType: str
      }, () => {
        this.getGuesteuUserLike(data.customerName)
      })
    }
  }

  // 获取客户组信息
  getGuestGroupDetailByGroupId = async (params) => {
    const { detailInfo } = this.props
    let handleDetail = detailInfo.state === 'future'
      ? await Api.getFutureGuestGroupDetailByGroupId(params)
      : await Api.getGuestGroupDetailByGroupId(params)
    const { code, data } = handleDetail
    if (+code === 10000) {
      let str = ''
      let _selectedUserIds = []

      if (data && data.commonGuestType && data.commonGuestType !== '') {
        str = data.commonGuestType
      }

      if (data.userInfoList && data.userInfoList.length > 0) {
        data.userInfoList.map(item => {
          _selectedUserIds.push(item.endUserId)
        })
      }

      this.setState({
        info: data || {},
        defaultUserIds: _selectedUserIds,
        selectedType: str
      }, () => {
        this.getGuesteuUserLike(data.customerName)
      })
    }
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo, type } = this.props
        if (type === 2) {
          values.priceGroupId = detailInfo.priceGroupId
        }
        values.discount = values.discount / 100
        this.props.onConfirm(values)
      }
    })
  }

  // 监听模板选择
  onSelectedType = (val) => {
    this.setState({
      selectedType: val
    })
  }

  render () {
    const { type = 1, detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form
    const { selectedType, euOptions = [], defaultUserIds = [], info = {} } = this.state

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 }
    }
    console.log('defaultUserIds', defaultUserIds)

    return (
      <div>
        <Modal title={type === 1 ? '新增价格组' : '编辑价格组'}
          visible
          with={800}
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Form>
            <FormItem label='价格组名称' {...formItemLayout}>
              {
                getFieldDecorator('priceGroupName', {
                  initialValue: info.priceGroupName || '',
                  rules: [
                    { required: true, message: '请填写' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='价格组类型' {...formItemLayout}>
              {
                getFieldDecorator('commonGuestType', {
                  initialValue: info.commonGuestType || detailInfo.groupType,
                  rules: [
                    { required: true, message: '请填写' }
                  ]
                })(
                  <Select placeholder='请选择...' autoComplete='off'
                    onChange={this.onSelectedType}
                    disabled
                  >
                    {
                      groupTypeMaps.map((item, i) => (
                        <Option value={item.id} key={`opt-${i}`}>{item.name}</Option>
                      ))
                    }
                  </Select>
                )
              }
            </FormItem>

            {
              selectedType === 'GUEST'
                ? <FormItem label='客户名称' {...formItemLayout}>
                  {
                    getFieldDecorator('endUserIdList', {
                      initialValue: defaultUserIds,
                      rules: [
                        { required: true, message: '请选择客户' }
                      ]
                    })(
                      <Select
                        mode='multiple'
                        showSearch
                        optionFilterProp='children'
                        onSearch={(val) => this.getGuesteuUserLike(val)}
                      >
                        {
                          euOptions.map((cusitem, i) => (
                            <Option value={cusitem.endUserId} key={cusitem.endUserId}>{cusitem.showName}</Option>
                          ))
                        }
                      </Select>
                    )
                  }
                </FormItem>
                : null
            }

            <FormItem label='价格组折扣' {...formItemLayout}>
              {
                getFieldDecorator('discount', {
                  initialValue: +info.discount * 100 || '',
                  rules: [
                    { required: true, message: '请填写价格组折扣' }
                  ]
                })(
                  <InputNumber placeholder='请输入...' autoComplete='off' min={1} />
                )
              }
              <span style={{ marginLeft: '6px' }}>%</span>
            </FormItem>
          </Form>
        </Modal>
      </div>
    )
  }
}

const PricegroupModal = Form.create()(PricegroupForm)

export default PricegroupModal
