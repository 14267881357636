/**
 * 首页模块路由
*/
import Home from '@/pages/home/home/index'
import SetAccount from '@/pages/home/account/index'
import MyTask from '@/pages/home/my-task/index'
import Setpayword from '@/pages/home/setpayword/index' // 设置支付密码&修改支付密码
import AddressList from '@/pages/home/address-list/index' // 地址管理

export default [
  {
    path: '/admin/home',
    name: '首页',
    component: Home
  },
  {
    path: '/admin/setAccount',
    name: '账户设置',
    component: SetAccount
  },
  {
    path: '/admin/myTask',
    name: '我的任务',
    component: MyTask
  },
  {
    path: '/admin/setpword',
    name: '设置收款密码',
    component: Setpayword
  },
  {
    path: '/admin/address',
    name: '地址管理',
    component: AddressList
  }
]
