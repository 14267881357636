import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Table, Divider, Popconfirm, Row, message } from 'antd'

import SearchForm from '@/components/tool-form/index'
import BatchSearchMenu from '@/components/batchSearchMenu/index'
import UploadCp from '../component/uploadimg-cp'
import UploadPdf from '../component/uploading-pdf'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class uploadimg extends Component {
  static propTypes ={
    history: PropTypes.object
  }

  state={
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    selectedRowKeys: [],
    alonekeys: [], // 单个关联
    tableData: [],
    listImages: [],
    detailsImages: [],
    detailsPdfs: [],
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      },
      {
        name: 'keyword',
        formType: 'Input',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '请输入型号或订货号',
          autoComplete: 'off'
        }
      }
    ]
  }
  columns=[
    {
      title: '序号',
      key: 'key-1',
      width: 50,
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'productLibraryt-1',
      width: 120,
      ellipsis: true,
      render: (text, record, index) => (
        <div>
          <div>{record.brandName}</div>
          <div>
            {
              record.englishBrandName && record.englishBrandName !== ''
                ? record.englishBrandName
                : '--'
            }
          </div>
        </div>
      )
    },

    {
      title: '型号',
      key: 'productLibrary-2',
      width: 160,
      ellipsis: true,
      render: (text, record, index) => (
        <div dangerouslySetInnerHTML={{ __html: record.model }} />
      )
    },
    {
      title: '订货号',
      key: 'productLibrary-3',
      width: 130,
      ellipsis: true,
      render: (text, record, index) => (
        <div dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
      )
    },
    {
      title: '产品描述',
      key: 'key-3',
      dataIndex: 'name'
    },
    {
      title: '操作',
      key: 'key-6',
      render: (text, record, index) => (
        <Popconfirm
          title='确定关联吗'
          onConfirm={() => { this.updateProductSkuImg(1, record.commoditySkuId) }}
          okText='确定'
          cancelText='取消'
        >
          <a href='#'>关联</a>
        </Popconfirm>

      )
    }
  ]

  componentDidMount () {
    this.getBrandList({ brandListType: 'MERCHANT_BRAND' })
    this.getMyProductListInProductModule()
  }

  // 返回列表
  handleJumpToLink = () => {
    this.props.history.push({ pathname: '/admin/productLibrary' })
  }

  // 获取品牌
  getBrandList = (params) => {
    Api.getBrandList(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        let { searchData } = this.state

        if (data && data.length > 0) {
          data.map(item => {
            item.showLabel = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
          })
        }

        searchData[0].options = [{ brandId: 0, showLabel: '全部' }, ...data]

        this.setState({
          searchData
        })
      }
    })
  }

   // 获取产品列表
   getMyProductListInProductModule = () => {
     const { pages, query } = this.state

     let params = {
       ...query,
       pageNo: pages.pageNo,
       pageSize: pages.pageSize
     }

     Api.getMyProductListInProductModule(params).then(res => {
       const { code, data = {} } = res

       if (+code === 10000) {
         this.setState({
           tableData: data.pageBean.list || [],
           pages: {
             ...pages,
             total: data.pageBean.total || 0
           },
           failModal: +data.failNumber > 0,
           failNumber: +data.failNumber,
           totalNumber: +data.totalNumber,
           successNumber: +data.successNumber,
           failList: data.failMessageList || []
         })
       }
     })
   }

   // 点击搜索
   handleSearch = (search = {}) => {
     const { pages } = this.state
     if (search.brandId && +search.brandId > 0) {
       search.brandId = [search.brandId]
     } else {
       delete search.brandId
     }

     this.setState({
       pages: {
         ...pages,
         pageNo: 1
       },
       query: search || {}
     }, () => this.getMyProductListInProductModule())
   }

  // 点击勾选
  onSelectChange = (keys, rows) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 上传产品图片
  handleUploadListImage = (params, type) => {
    let newArr = [...this.state[type]]

    type === 'listImages'
      ? newArr.push({
        imageUrl: params,
        mainType: 'NOT_MAIN'
      })
      : newArr.push({
        imageUrl: params,
        showStatus: 'NOT_SHOW'
      })

    let newlistImages = newArr.map((item) => item.mainType === 'MAIN').join(',')
    let detailsImages = newArr.map((item) => item.showStatus === 'SHOW').join(',')

    if (type === 'listImages' && !newlistImages.includes(true)) {
      newArr.map((item, i) => {
        if (i === 0) {
          item.mainType = 'MAIN'
        }
      })
    }

    if (type === 'detailsImages' && !detailsImages.includes(true)) {
      newArr.map((item, i) => {
        if (i === 0) {
          item.showStatus = 'SHOW'
        }
      })
    }

    this.setState({
      [type]: newArr
    })
  }

  // 删除图片
  handleDeleteImage = (index, type) => {
    let newArr = [...this.state[type]]
    newArr.splice(index, 1)

    this.setState({
      [type]: newArr
    })
  }

  // 选择设置主要或显示
  handleSelectedImage = (index, type, checked) => {
    let newArr = [...this.state[type]]

    if (type === 'listImages') {
      newArr.map((item, i) => {
        item['mainType'] = i === index ? 'MAIN' : 'NOT_MAIN'
      })
    }

    if (type === 'detailsImages') {
      newArr[index]['showStatus'] = checked ? 'SHOW' : 'NOT_SHOW'
    }

    this.setState({
      [type]: newArr
    })
  }

  // PDF
  // pdf上传
  handleUploadPdf = (param) => {
    const { detailsPdfs } = this.state
    detailsPdfs.push(param)

    let newdetailsPdfs = detailsPdfs.map((item) => item.showStatus === 'SHOW').join(',')
    if (!newdetailsPdfs.includes(true)) {
      detailsPdfs.map((item, i) => {
        if (i === 0) {
          item.showStatus = 'SHOW'
        }
      })
    }
    this.setState({
      detailsPdfs
    })
  }

  // pdf删除
  handleDeletePdf = (index) => {
    const { detailsPdfs } = this.state
    detailsPdfs.splice(index, 1)
    this.setState({
      detailsPdfs
    })
  }

  // PDF设置唯一显示
  handleSelectedpdf = (index) => {
    const { detailsPdfs } = this.state
    detailsPdfs.map((item, i) => {
      item.showStatus = i === index ? 'SHOW' : 'NOT_SHOW'
    })

    this.setState({
      detailsPdfs
    })
  }

  // 批量关联图片
  updateProductSkuImg = (type, id) => {
    const { selectedRowKeys = [], detailsImages = [], detailsPdfs = [], listImages = [] } = this.state

    let params = {
      skuIdList: type === 2 ? selectedRowKeys : [id],
      skuImageList: listImages,
      skuDetailImageList: detailsImages,
      skuDetailPdfList: detailsPdfs
    }

    Api.updateProductSkuImg(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('关联成功')
      }
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getMyProductListInProductModule()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getMyProductListInProductModule()
    })
  }

  render () {
    const { tableData, pages, searchData, selectedRowKeys, listImages = [], detailsImages = [], detailsPdfs = [] } = this.state

    const rowSelection = {
      selectedRowKeys,
      columnWidth: 34,
      onChange: this.onSelectChange
    }

    // 分页配置
    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Button icon='left' onClick={this.handleJumpToLink}>返回产品列表</Button>

        <div style={{ marginTop: '20px' }}>
          <Card>
            <UploadCp title='产品图' type='listImages'
              list={listImages}
              onUpload={(params, type) => this.handleUploadListImage(params, type)}
              onDelete={(index, type) => this.handleDeleteImage(index, type)}
              onSelect={(index, type) => this.handleSelectedImage(index, type)}
            />

            <UploadCp title='详情图' type='detailsImages'
              list={detailsImages}
              onUpload={(params, type) => this.handleUploadListImage(params, type)}
              onDelete={(index, type) => this.handleDeleteImage(index, type)}
              onSelect={(index, type, checked) => this.handleSelectedImage(index, type, checked)}
            />

            <p>PDF：</p>
            <UploadPdf list={detailsPdfs}
              onUpload={(params) => this.handleUploadPdf(params)}
              onDelete={(index) => this.handleDeletePdf(index)}
              onSelect={(index) => this.handleSelectedpdf(index)}
            />

            <Divider />

            <h3>批量关联图片</h3>

            <Row style={{ display: 'inline-block', width: '120px', lineHeight: '40px', verticalAlign: 'top' }}>
              <BatchSearchMenu
                onConfirm={(values) => this.handleSearch(values)}
              />
            </Row>
            <Row style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
              <SearchForm
                {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Card>

          <Card style={{ marginTop: '20PX' }}>
            <Popconfirm
              title='确定关联吗'
              onConfirm={() => { this.updateProductSkuImg(2) }}
              okText='确定'
              cancelText='取消'
            >
              <Button type='primary' className={'mb10'} disabled={selectedRowKeys.length === 0}>勾选图片关联</Button>
            </Popconfirm>

            <Table rowKey='commoditySkuId'
              columns={this.columns}
              dataSource={tableData}
              rowSelection={rowSelection}
              pagination={pagination}
            />
          </Card>
        </div>
      </>

    )
  }
}
