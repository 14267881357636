import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Form, Button } from 'antd'

import DrawerContents from '../drawerContents/index'

import styles from './index.module.scss'

class PurchaseDrawerForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    visible: PropTypes.bool,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }

  render () {
    const { detailInfo = {} } = this.props
    const { form, visible = false, onCancel } = this.props

    return (
      <Drawer title={<><span>售后申请</span><span style={{ marginLeft: '16px' }}>售后单号：{ detailInfo.id }</span></>}
        width={1300} bodyStyle={{ paddingBottom: '80px' }}
        visible={visible}
        destroyOnClose
        placement='right'
        closable={false}
        onClose={() => onCancel()}
      >
        <Form>
          {
            visible
              ? <DrawerContents form={form} detailInfo={detailInfo} />
              : null
          }
        </Form>

        <div className={`${styles.btns}`}>
          <Button onClick={() => onCancel()}>取消</Button>
        </div>
      </Drawer>
    )
  }
}

const PurchaseDrawer = Form.create()(PurchaseDrawerForm)

export default PurchaseDrawer
