import React, { Component } from 'react'
import { Card, Row, Col, Descriptions } from 'antd'

import Api from '@/common/api/index'

import styles from './home.module.scss'

export default class Home extends Component {
  static propTypes = {}
  state = {
    info: {}
  }

  componentDidMount () {
    this.getAnalysis()
  }

  // 获取统计信息
  getAnalysis = () => {
    Api.getAnalysis({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data || {}
        })
      }
    })
  }

  render () {
    const { info } = this.state

    return (
      <div>
        <Row gutter={20} style={{ marginBottom: '24px' }}>
          <Col span={6}>
            <Card title='今日成交额'>
              <div className={`${styles.count} ${styles.redColor}`}>
                {
                  info.dayTradeTotalAmount && +info.dayTradeTotalAmount > 0
                    ? info.dayTradeTotalAmount
                    : 0.00
                }
              </div>
            </Card>
          </Col>

          <Col span={6}>
            <Card title='今日成交客户数'>
              <div className={`${styles.count} ${styles.blueColor}`}>
                {
                  info.addTradeUserTotal && +info.addTradeUserTotal > 0
                    ? info.addTradeUserTotal
                    : 0
                }
              </div>
            </Card>
          </Col>

          <Col span={6}>
            <Card title='今日现货订单数'>
              <div className={`${styles.count} ${styles.greenColor}`}>
                {
                  info.dayStockOrderTotal && +info.dayStockOrderTotal > 0
                    ? info.dayStockOrderTotal
                    : 0
                }
              </div>
            </Card>
          </Col>

          <Col span={6}>
            <Card title='今日期货订单数'>
              <div className={`${styles.count} ${styles.voilColor}`}>
                {
                  info.dayFuturesOrderTotal && +info.dayFuturesOrderTotal > 0
                    ? info.dayFuturesOrderTotal
                    : 0
                }
              </div>
            </Card>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={12}>
            <Card title='交易统计'>
              <Descriptions bordered>
                <Descriptions.Item label='总成交金额'>
                  <div className={`${styles.itemCount}`}>
                    {
                      info.tradeTotalAmount && +info.tradeTotalAmount > 0
                        ? info.tradeTotalAmount
                        : 0.00
                    }
                  </div>

                </Descriptions.Item>
                <Descriptions.Item label='总客户数'>
                  <div className={`${styles.itemCount}`}>
                    {
                      info.endUserTotal && +info.endUserTotal > 0
                        ? info.endUserTotal
                        : 0
                    }
                  </div>
                </Descriptions.Item>

                <Descriptions.Item label='新增客户数'>
                  <div className={`${styles.itemCount}`}>
                    {
                      info.addUserTotal && +info.addUserTotal > 0
                        ? info.addUserTotal
                        : 0
                    }
                  </div>
                </Descriptions.Item>

                <Descriptions.Item label='成交客户数'>
                  <div className={`${styles.itemCount}`}>
                    {
                      info.tradeUserTotal && +info.tradeUserTotal > 0
                        ? info.tradeUserTotal
                        : 0
                    }
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col span={12}>
            <Card title='订单统计'>
              <Descriptions bordered>
                <Descriptions.Item label='待支付订单'>
                  <div className={`${styles.itemCount}`}>
                    {
                      info.unPaidOrderTotal && +info.unPaidOrderTotal > 0
                        ? info.unPaidOrderTotal
                        : 0
                    }
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label='待发货订单'>
                  <div className={`${styles.itemCount}`}>
                    {
                      info.unDeliverOrderTotal && +info.unDeliverOrderTotal > 0
                        ? info.unDeliverOrderTotal
                        : 0
                    }
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label='现货订单数'>
                  <div className={`${styles.itemCount}`}>
                    {
                      info.stockOrderTotal && +info.stockOrderTotal > 0
                        ? info.stockOrderTotal
                        : 0
                    }
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label='期货订单数'>
                  <div className={`${styles.itemCount}`}>
                    {
                      info.futuresOrderTotal && +info.futuresOrderTotal > 0
                        ? info.futuresOrderTotal
                        : 0
                    }
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
