import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Alert } from 'antd'

import styles from './index.module.scss'

export default class HelpCard extends Component {
  static propTypes = {
    iscloudServer: PropTypes.bool,
    tips: PropTypes.string
  }
  state = {}

  componentDidMount () {}

  render () {
    const { iscloudServer = false, tips = '' } = this.props

    return (
      <div>
        <Row style={{ marginBottom: '10px' }}>
          <Alert type='error'
            message={<div className={`${styles.messageCont}`}>
              <p>1: 收货人确认签收的，视为货物已经收到并验收合格。</p>
              <p>2: 若发现不一致或损坏应拒收，及时拍照保存，第一时间联系德邦查找原因。IIASaaS将辅助客户进行处理推进。</p>
              <p>3: 若发快递为到付时，请在发货时告知快递员用IIASaaS月结账号，否则不享受折扣。</p>
              <p>4: 为订单添加保险时，若发出货物与订单货物不符，则不予理赔。</p>
              <p>5: IIASaaS运费计算价格以基础表价推算，一切运费价格以当地营业部为准，详情请线下咨询。</p>
            </div>}
          />
        </Row>

        {
          iscloudServer && tips !== ''
            ? <Row>
              <Alert type='info'
                message={<div>
                  <h4>IIASaaS快递服务核算方法:</h4>
                  <div className={`${styles.messageCont}`} dangerouslySetInnerHTML={{ __html: this.props.tips }} />
                </div>}
              />
            </Row>
            : null
        }

      </div>
    )
  }
}
