/**
 * 财务模块
 */
import InvoiceList from '@/pages/finance/invoice/index' // 开票申请
import InvoiceRule from '@/pages/finance/invoice-rule/index' // 开票规则设置
import EubalanceList from '@/pages/finance/eubalance-list/index' // 客户余额列表
import CashoutList from '@/pages/finance/cashout-list/index' // 客户提现申请

import OrderRefund from '@/pages/finance/order-refund/index' // 订单退款
import CreditDetail from '@/pages/finance/credit-detail/index' // 信用详情

import EnterpriseWallet from '@/pages/finance/enterprise-wallet/index' // 企业账户余额
import IIAsaasFinance from '@/pages/finance/iiasaas-finance/index' // IIAsaas财务
import OrderPay from '@/pages/finance/order-pay/index' // 订单付款

import TransportBill from '@/pages/finance/transport-bill/index' // IIASaaS运费对账单

export default [
  {
    path: '/admin/invoiceList',
    name: '开票管理',
    component: InvoiceList
  },
  {
    path: '/admin/invoiceRule',
    name: '开票规则',
    component: InvoiceRule
  },
  {
    path: '/admin/eubalanceList',
    name: '客户余额列表',
    component: EubalanceList
  },
  {
    path: '/admin/applyCash',
    name: '客户提现申请',
    component: CashoutList
  },
  {
    path: '/admin/orderRefund',
    name: '订单退款',
    component: OrderRefund
  },
  {
    path: '/admin/creditDetail',
    name: '余额信用',
    component: CreditDetail
  },
  {
    path: '/admin/enterpriseWallet',
    name: '账户余额管理',
    component: EnterpriseWallet
  },
  {
    path: '/admin/cloudFinance',
    name: 'IIASAAS专享',
    component: IIAsaasFinance
  },
  {
    path: '/admin/orderPay',
    name: '订单支付',
    component: OrderPay
  },
  {
    path: '/admin/transportBill',
    name: 'IIASaaS运费账单',
    component: TransportBill
  }
]
