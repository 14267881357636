import React, { Component } from 'react'
import { Row, Button } from 'antd'
import DowncolModal from './downcol-modal'
// import DownloadApi from '@/common/api/downloadApi'
import PropTypes from 'prop-types'
// const { downOrderUrl } = DownloadApi
class down extends Component {
  static propTypes = {
    query: PropTypes.object,
    future: PropTypes.bool,
    api: PropTypes.func
  }
  state = {
    downcolModal: false
  }
  // 点击导出订单
  handleShowDownColModal = () => {
    this.setState({
      downcolModal: true
    })
  }

  // 点击导出订单
  downloadOrder = (values) => {
    const { query, api } = this.props
    let params = {
      fieldList: values.fieldList,
      pageSize: 10,
      pageNo: 1
    }
    if (query.endUserId && +query.endUserId > 0) {
      params['endUserId'] = query.endUserId
    }
    if (query.orderId) {
      params['orderId'] = query.orderId
    }
    if (query.paymentStatus) {
      params['ordpaymentStatuserId'] = query.paymentStatus
    }
    if (query.deliveryStatus) {
      params['deliveryStatus'] = query.deliveryStatus
    }
    if (query.orderStatus) {
      params['orderStatus'] = query.orderStatus
    }
    if (query.beginDate) {
      params['beginDate'] = query.beginDate
      params['endDate'] = query.endDate
    }
    api(params)
  }

    closeModal = () => {
      this.setState({
        type: 1,
        detailModal: false,
        confirmModal: false,
        sendModal: false,
        downcolModal: false,
        rowDetail: {}
      })
    }
  closeModal = () => {
    this.setState({
      downcolModal: false
    })
  }
  render () {
    const { downcolModal } = this.state
    const { future } = this.props
    return (
      <>
        <Row style={{ marginBottom: '15px', marginTop: '10px' }}>
          <Button type='primary'
            style={{ width: '110px', height: '30px', marginRight: '5px' }}
            onClick={this.handleShowDownColModal}
          >导出订单</Button>
          <span style={{ color: 'red' }}>温馨提示：导出前请先选择想导出的订单日期，点击搜索后再点击导出订单。</span>
        </Row>
        {
          downcolModal
            ? <DowncolModal
              future={future}
              onConfirm={(params) => this.downloadOrder(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}

export default down
