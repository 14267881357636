import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Table, message, Button } from 'antd'
import moment from 'moment'
import { setNoticeDetail } from '@/reducers/action'

import SearchForm from '@/components/tool-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderNotifyTypeLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, notifyTypeMaps, notifyReadMaps } = filter

class ReceivedInformation extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func
  }
  state = {
    searchData: [
      {
        name: 'msgType',
        formType: 'Select',
        itemParams: {
          label: '信息类型'
        },
        cptParams: {

        },
        options: [
          { id: 'ALL', name: '全部' },
          ...notifyTypeMaps
        ],
        rules: {
          initialValue: 'ALL'
        }
      },
      {
        name: 'receviedTime',
        formType: 'RangePicker',
        itemParams: {
          label: '接收时间'
        },
        cptParams: {

        }
      },
      {
        name: 'msgReadType',
        formType: 'Select',
        itemParams: {
          label: '读取状态'
        },
        cptParams: {

        },
        options: [
          { id: 'ALL', name: '全部' },
          ...notifyReadMaps
        ],
        rules: {
          initialValue: 'ALL'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {
      msgType: 'ALL',
      msgReadType: 'ALL'
    }
  }

  columns = [
    {
      title: '序号',
      key: 'receivedInformation-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '信息类型',
      key: 'receivedInformation-1',
      render: (text, record, index) => (
        <>{ renderNotifyTypeLabel(record.msgType) }</>
      )
    },
    {
      title: '标题',
      key: 'receivedInformation-2',
      ellipsis: true,
      dataIndex: 'title'
    },
    {
      title: '内容',
      key: 'receivedInformation-3',
      ellipsis: true,
      render: (text, record, index) => (
        <>
          {
            record.msgType === 'PLATFORM_ANNOUNCEMENT'
              ? <a onClick={() => this.handleLinkToNoticeDetail(record)}>点击查看详情</a>
              : <>{record.content}</>
          }
        </>
      )
    },
    {
      title: '接收时间',
      key: 'receivedInformation-4',
      render: (text, record, index) => (
        <div>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </div>
      )
    },
    {
      title: '发布人',
      key: 'receivedInformation-5',
      dataIndex: 'sendName'
    },
    {
      title: '读取状态',
      key: 'receivedInformation-6',
      render: (text, record, index) => (
        <>
          {
            record.readStatus === 'READ' ? '已读' : '未读'
          }
        </>
      )
    },
    {
      title: '操作',
      key: 'receivedInformation-7',
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small' onClick={() => this.handleLinkToNoticeDetail(record)}>查看</Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getReceivedNotifysList()
  }

  // 获取已接收的消息列表
  getReceivedNotifysList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.getReceivedNotifysList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      } else {
        message.error(res.message)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.receviedTime) {
      search['beginDate'] = search.receviedTime.length > 0 ? moment(search.receviedTime[0]).format('YYYY-MM-DD') : undefined
      search['endDate'] = search.receviedTime.length > 0 ? moment(search.receviedTime[1]).format('YYYY-MM-DD') : undefined

      delete search.receivedTime
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getReceivedNotifysList()
    })
  }

  // 点击查看详情
  handleLinkToNoticeDetail = (record = {}) => {
    this.props.dispatch(setNoticeDetail({ noticeDetail: record }))
    this.props.history.push({ pathname: '/admin/notifyDetail' })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getReceivedNotifysList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getReceivedNotifysList()
    })
  }

  render () {
    const { searchData, pages, tableData } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }} style={{ marginBottom: '15px' }}>
          <SearchForm {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </Card>

        <Card bodyStyle={{ padding: '15px' }}>
          <Table rowKey='id'
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}

export default connect()(ReceivedInformation)
