import React, { Component } from 'react'
import { Card, Row, Table, Button, Modal, message, Switch } from 'antd'
import moment from 'moment'

import FreightModal from '../component/freight-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { confirm } = Modal
const { pageSizeOptions } = filter

export default class freightTemp extends Component {
  static propTypes = {}
  state = {
    freightModal: false,
    type: 1,
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'freight-0',
      width: 40,
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '模板名称',
      key: 'freight-1',
      dataIndex: 'templateName'
    },
    {
      title: '客户名称',
      key: 'freight-2',
      render: (text, record, index) => (
        <div>
          {
            record.templateType === 'TEMPLATE_GUEST'
              ? <span>{record.customerName}</span>
              : <span>通用模板</span>
          }
        </div>
      )
    },
    {
      title: '满额免',
      key: 'freight-4',
      dataIndex: 'satisfyPrice'
    },
    {
      title: '添加时间',
      key: 'freight-7',
      render: (text, record, index) => (
        <div>
          {moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')}
        </div>
      )
    },
    {
      title: '是否启用',
      key: 'freight-8',
      render: (text, record, index) => (
        <div>
          {
            this.renderDisableStatus(record)
          }
        </div>
      )
    },
    {
      title: '操作',
      key: 'freight-9',
      width: 200,
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small'
            style={{ marginRight: '8px' }}
            onClick={() => this.showFreighModal(record, 2)}
          >编辑</Button>

          <Button type='primary' size='small'
            onClick={() => this.handleRemoveFreight(record)}
          >删除</Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getFreightList()
  }

  // 获取运费模板列表
  getFreightList = () => {
    const { pages } = this.state
    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getFreightList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 更新运费模板状态
  updateFreight = (params) => {
    Api.updateFreight(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getFreightList()
      }
    })
  }

  // 保存运费模板
  saveFreight = (params) => {
    Api.saveFreight(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getFreightList()
      }
    })
  }

  // 保存运费模板修改
  saveModifyFreight = (params) => {
    Api.saveModifyFreight(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getFreightList()
      }
    })
  }

  // 获取运费模板详情
  getFreightDetail = (params) => {
    return Api.getFreightDetail(params)
  }

  // 根据查询客户信息
  getGuesteuUserLike = (params) => {
    return Api.getGuesteuUserLike(params)
  }

  // 新增/编辑模板
  showFreighModal = (record, type) => {
    this.setState({
      freightModal: true,
      type: type,
      rowDetail: record || {}
    })
  }

  // 点击删除
  handleRemoveFreight = (record) => {
    confirm({
      title: '确定删除该模板？',
      content: '',
      onOk: () => {
        let arr = []
        arr.push(record.templateId)

        this.updateFreight({ delFlag: 1, templateIdList: arr })
      }
    })
  }

  // 点击启用/禁用模板
  changeFreightStatus = (checked, record) => {
    let arr = []
    arr.push(record.templateId)

    let params = {
      disableStatus: checked ? 'DISABLE_NO' : 'DISABLE_YES',
      templateIdList: arr
    }

    this.updateFreight(params)
  }

  // 保存运费模板
  handleSaveFreight = (params) => {
    const { type = 1 } = this.state
    if (type === 1) {
      this.saveFreight(params)
    } else {
      this.saveModifyFreight(params)
    }
  }

  closeModal = () => {
    this.setState({
      freightModal: false,
      type: 1,
      rowDetail: {}
    })
  }

  renderDisableStatus = (record) => {
    let obj = (
      <Switch
        size='small'
        checkedChildren='启用' unCheckedChildren='禁用'
        checked={record.disableStatus && record.disableStatus === 'DISABLE_NO'}
        onChange={(checked) => this.changeFreightStatus(checked, record)}
      />
    )

    return obj
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getFreightList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getFreightList()
    })
  }

  render () {
    const { tableData = [], pages, freightModal = false, rowDetail = {}, type = 1 } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共${total}` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ marginBottom: '15px' }}>
            <Button type='primary'
              style={{ marginRight: '10px', width: '110px', height: '30px' }}
              onClick={() => this.showFreighModal({}, 1)}
            >
              添加模板
            </Button>
          </Row>

          <Row>
            <Table rowKey='templateId'
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination}
            />
          </Row>
        </Card>

        {
          freightModal
            ? <FreightModal
              type={type}
              detailInfo={rowDetail}
              onConfirm={(params) => this.handleSaveFreight(params)}
              onCancel={this.closeModal}
              getDetail={(params) => this.getFreightDetail(params)}
              getGuesteuUserLike={(params) => this.getGuesteuUserLike(params)}
            />
            : null
        }
      </div>
    )
  }
}
