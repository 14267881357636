/**
 * 公共页面路由
 * */
import SheetsConfirm from '@/pages/public/sheets-confirm/index' // EXCEL数据确认
import MatchResult from '@/pages/public/match-result/index' // 匹配结果简报
import MatchList from '@/pages/public/match-list/index' // 匹配结果详情
import SettingResult from '@/pages/public/setting-success/index' // 设置结果
import EnquireMatchSheet from '@/pages/public/enquireMatchSheet/index' // 询价产品导入数据确认
import EnquireMatchReport from '@/pages/public/enquireMatchReport/index' // 询价产品匹配简报
import EnquireMatchDetails from '@/pages/public/enquireMatchDetails/index' // 询价产品匹配详情

export default [{
  path: '/admin/sheetsConfirm',
  name: 'EXCEL确认',
  component: SheetsConfirm
},
{
  path: '/admin/matchReport',
  name: '匹配结果简报',
  component: MatchResult
},
{
  path: '/admin/matchList',
  name: '匹配结果详情',
  component: MatchList
},
{
  path: '/admin/settingResult',
  name: '设置结果',
  component: SettingResult
},
{
  path: '/admin/enquireMatchSheet',
  name: 'EXCEL确认',
  component: EnquireMatchSheet
},
{
  path: '/admin/enquireMatchReport',
  name: '匹配结果简报',
  component: EnquireMatchReport
},
{
  path: '/admin/enquireMatchDetails',
  name: '匹配结果详情',
  component: EnquireMatchDetails
}
]
