import React, { Component } from 'react'
import { Card, Table, Switch, message } from 'antd'

import Api from '@/common/api/index'

export default class PaymethodList extends Component {
  static propTypes = {}
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20, options: ['10', '20', '50', '100', '200'] },
    tableData: []
  }

  coluns = [
    {
      title: '支付方式',
      key: 'paymethod-0',
      dataIndex: 'paymentName'
    },
    {
      title: '支付方式描述',
      key: 'paymethod-1',
      dataIndex: 'paymentDescription'
    },
    {
      title: '是否启用',
      key: 'paymethod-2',
      render: (text, record, index) => (
        <div>
          <Switch
            size='small'
            checked={record.enabledStatus && record.enabledStatus === 'ENABLE'}
            checkedChildren='启用'
            unCheckedChildren='禁用'
            onChange={(checked) => this.handleChangeSwitch(checked, record)}
          />
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getPaymethodList()
  }

  getPaymethodList = () => {
    Api.getPaymethodList({ pageNo: 1, pageSize: 20 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || []
        })
      }
    })
  }

  saveUpdatePaymethodStatus = (params) => {
    Api.updatePaymethodStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getPaymethodList()
      }
    })
  }

  handleChangeSwitch = (checked, record) => {
    let params = {
      enabledStatus: checked ? 'ENABLE' : 'DISABLE',
      paymentId: record.paymentId || ''
    }

    this.saveUpdatePaymethodStatus(params)
  }

  render () {
    const { tableData = [] } = this.state

    return (
      <Card bodyStyle={{ padding: '15px' }}>
        <Table rowKey='paymentId'
          columns={this.coluns}
          dataSource={tableData}
          pagination={false}
        />
      </Card>
    )
  }
}
