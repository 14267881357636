import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Row, Col, Radio, Select, InputNumber, Icon, Input, Checkbox, Alert, DatePicker } from 'antd'
import $lodash from 'lodash'

import HelpCard from './sendHelp/index'
import InsuraexplanModal from './insuraexplanModal/index'

import CourierJson from '@/assets/json/courier.json'
// import SAASCourierJson from '@/assets/json/saasCourier.json'
import LogisticsExplainJson from '@/assets/json/logisticsExplain.json'
import filter from '@/common/utils/filter'
import { getCourierName } from '@/common/utils/mUtils'
import styles from './send.module.scss'

const { saasCouierCodeMaps, expressTypeMaps, expressReceiptFlags, expressReceiptReturnRequirements, expressPackWoodenFrames } = filter
const FormItem = Form.Item
const Option = Select.Option
const { TextArea } = Input
const { RangePicker } = DatePicker

const MIN_INSURE_AMOUNT = 300 // 保险最小免赔额

// 快递服务类型自营选项
const SELFLogisticsWays = [
  { id: 'SELF-NO', courierName: '自营服务' },
  { id: 'TAKE_THEIR-NO', courierName: '客户自提' }
]
let formitemId = 1

class SendserverForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    info: PropTypes.object,
    insureConfig: PropTypes.object,
    defaultLogisticsPayType: PropTypes.string,
    sendAddressList: PropTypes.array,
    insurePay: PropTypes.string,
    productAmount: PropTypes.number // 此次发货货物金额
  }
  state = {
    selfInputList: [''],
    tips: '',
    explanModal: false
  }

  // 选择快递服务
  handleChangeServerType = (value) => {
    const { form } = this.props
    const { tips = '' } = this.state

    this.setState({
      tips: $lodash.includes(saasCouierCodeMaps, value) ? tips : ''
    }, () => {
      form.setFieldsValue({
        transportType: null
      })
    })
  }

  // 选择快递方式
  handleChangeTransport = (value, courierCode) => {
    this.setState({
      tips: LogisticsExplainJson[courierCode][value]
    })
  }

  // 渲染快递方式选项
  renderTransportTypeOptions = (courierCode) => {
    let newArr = expressTypeMaps[courierCode]

    return (
      <Select onChange={(value) => this.handleChangeTransport(value, courierCode)}>
        {
          newArr.map((item) => (
            <Option value={item.id} key={item.id}>{ item.name }</Option>
          ))
        }
      </Select>
    )
  }

  // 点击添加快递单号输入框
  handleAddInput = () => {
    const { form } = this.props
    const keys = form.getFieldValue('keys')
    const nextKeys = keys.concat(formitemId++)

    form.setFieldsValue({
      keys: nextKeys
    })
  }

  // 删除快递单号输入框
  handleDeleteInput = (k) => {
    const { form } = this.props

    const keys = form.getFieldValue('keys')

    if (keys.length === 1) { return false }

    form.setFieldsValue({
      keys: keys.filter(key => key !== k)
    })
  }

  // 渲染自营服务快递单号formitem
  renderSelfServerFormItem = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form
    getFieldDecorator('keys', { initialValue: [0] })
    let keys = getFieldValue('keys')

    return (
      <>
        {
          keys.map((row, i) => (
            <Row className={`${styles.formitemRow}`} key={`formInput-${i}`}>
              <FormItem label={<label className={`${styles.required}`}>快递单号</label>} style={{ marginBottom: '0px' }} >
                <FormItem style={{ display: 'inline-block', width: 'calc(100% - 80px)' }}>
                  {
                    getFieldDecorator(`courierNumberList[${row}]`, {
                      rules: [
                        { required: true, message: '请输入快递单号' }
                      ]
                    })(
                      <Input placeholder='请输入快递单号' autoComplete='off' />
                    )
                  }
                </FormItem>
                <FormItem style={{ display: 'inline-block', width: '80px' }}>
                  {
                    i === keys.length - 1
                      ? <a style={{ padding: '2px 3px', margin: '0px 5px' }} onClick={() => this.handleAddInput()}>
                        <Icon type='plus' style={{ fontSize: '20px' }} />
                      </a>
                      : <span style={{ display: 'inline-block', width: '24px', height: '24px', margin: '0px 5px' }} />
                  }

                  <a style={{ padding: '2px 3px' }} onClick={() => this.handleDeleteInput(row)}>
                    <Icon type='delete' style={{ fontSize: '20px' }} />
                  </a>
                </FormItem>
              </FormItem>
            </Row>
          ))
        }

      </>
    )
  }

  // 渲染SAAS服务内容
  renderSAASServerNodes = ({ getFieldDecorator }) => {
    const { form, info = {}, insureConfig = {}, defaultLogisticsPayType = '', insurePay = '', productAmount = 0 } = this.props
    let wayEnums = form.getFieldValue('logisticsWayEnum').split('-')

    const tailFormItemLayout = {
      wrapperCol: {
        sm: {
          span: 24,
          offset: 2
        }
      }
    }

    return (
      <>
        <Row className={`${styles.formitemRow}`}>
          <FormItem label={<label className={`${styles.required}`}>快递公司</label>}>
            <span>{getCourierName(wayEnums[1])}</span>
          </FormItem>
        </Row>

        <Row className={`${styles.formitemRow}`}>
          <FormItem label='快递方式'>
            {
              getFieldDecorator('transportType', {
                rules: [
                  { required: true, message: '请选择快递方式' }
                ]
              })(this.renderTransportTypeOptions(form.getFieldValue('logisticsWayEnum')))
            }
          </FormItem>
        </Row>

        <Row className={`${styles.formitemRow}`}>
          <FormItem label='包裹数量'>
            {
              getFieldDecorator('totalNumber', {
                rules: [
                  { required: true, message: '请填写包裹数量' }
                ]
              })(
                <InputNumber min={1} />
              )
            }
          </FormItem>
        </Row>

        <Row className={`${styles.formitemRow}`}>
          <FormItem label='付费类型'>
            {
              getFieldDecorator('logisticsPayType', {
                initialValue: form.getFieldValue('logisticsWayEnum') !== 'SAAS-JD' ? (defaultLogisticsPayType || 'MONTHLY_STATEMENT') : 'MONTHLY_STATEMENT',
                rules: [
                  { required: true, message: '请选择付费类型' }
                ]
              })(
                <Radio.Group buttonStyle='solid'>
                  <Radio.Button value='MONTHLY_STATEMENT'>包邮</Radio.Button>
                  { form.getFieldValue('logisticsWayEnum') !== 'SAAS-JD' ? <Radio.Button value='FREIGHT_COLLECT'>到付</Radio.Button> : null }
                </Radio.Group>
              )
            }
          </FormItem>
        </Row>

        {
          ['SAAS-DBL', 'SAAS-KYSY'].includes(form.getFieldValue('logisticsWayEnum'))
            ? <div className={`${styles.formitemRow}`}>
              <FormItem label='保价金额'>
                {
                  getFieldDecorator('insuranceValue', {
                    rules: [
                      { required: false, message: '请填写保价金额' }
                    ]
                  })(
                    <InputNumber formatter={value => `￥ ${value}`} style={{ width: '120px' }} />
                  )
                }
              </FormItem>
            </div>
            : null
        }

        {
          ['SAAS-DBL', 'SAAS-SF', 'SAAS-JD', 'SAAS-KYSY'].includes(form.getFieldValue('logisticsWayEnum'))
            ? <Row className={`${styles.formitemRow}`}>
              <FormItem label='签单返还'>
                {
                  getFieldDecorator('receiptType', {
                    initialValue: expressReceiptFlags[form.getFieldValue('logisticsWayEnum')][0].id,
                    rules: [
                      { required: true, message: '请选择签单返还' }
                    ]
                  })(
                    <Select>
                      {
                        expressReceiptFlags[form.getFieldValue('logisticsWayEnum')].map((receiOpt, i) => (
                          <Option value={receiOpt.id} key={`JDRECEIPT-${i}`}>{ receiOpt.name }</Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>
            </Row>
            : null
        }

        {
          ['SAAS-DBL'].includes(form.getFieldValue('logisticsWayEnum')) && ['DEPPON_1', 'DEPPON_2'].includes(form.getFieldValue('receiptType'))
            ? <div className={`${styles.formitemRow}`}>
              <FormItem label='签收回单返单类型'>
                {
                  getFieldDecorator('returnRequirement', {
                    rules: [
                      { required: true, message: '请选择签收回单返单类型' }
                    ]
                  })(
                    <Select>
                      {
                        expressReceiptReturnRequirements[form.getFieldValue('logisticsWayEnum')].map((receiOpt, i) => (
                          <Option value={receiOpt.id} key={`returnRequirement-${i}`}>{ receiOpt.name }</Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>
            </div>
            : null
        }

        { // 木架方式
          ['SAAS-KYSY'].includes(form.getFieldValue('logisticsWayEnum'))
            ? <Row className={`${styles.formitemRow}`}>
              <FormItem label='木架方式'>
                {
                  getFieldDecorator('woodenFrame', {
                    initialValue: '0',
                    rules: [
                      { required: true, message: '请选择木架方式' }
                    ]
                  })(
                    <Select>
                      {
                        expressPackWoodenFrames[form.getFieldValue('logisticsWayEnum')].map((woodOpt, i) => (
                          <Option value={woodOpt.id} key={`woodenFrameOpt-${i}`}>{ woodOpt.name }</Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>
            </Row>
            : null
        }

        {
          ['SAAS-DBL'].includes(form.getFieldValue('logisticsWayEnum'))
            ? <div className={`${styles.formitemRow}`}>
              <FormItem label='上门接货期限'>
                {
                  getFieldDecorator('pickUpTimes', {

                  })(
                    <RangePicker showTime />
                  )
                }
              </FormItem>
            </div>
            : null
        }

        {
          ['SAAS-KYSY'].includes(form.getFieldValue('logisticsWayEnum'))
            ? <div className={`${styles.formitemRow}`}>
              <FormItem label='预计重量'>
                {
                  getFieldDecorator('actualWeight', {

                  })(
                    <InputNumber style={{ width: '120px' }} />
                  )
                }
                <span style={{ marginLeft: '8px' }}>kg</span>
                <span className={'redColor'} style={{ marginLeft: '8px' }}>此项会影响调配车辆，请认真估计</span>
              </FormItem>
            </div>
            : null
        }

        {
          ['SAAS-DBL', 'SAAS-SF', 'SAAS-KYSY'].includes(form.getFieldValue('logisticsWayEnum'))
            ? <div className={`${styles.formitemRow}`}>
              <FormItem label='备注'>
                {
                  getFieldDecorator('waybillRemark', {

                  })(
                    <TextArea placeholder={`请输入内容，不超过${['SAAS-DBL', 'SAAS-SF'].includes(form.getFieldValue('logisticsWayEnum')) ? 100 : 200}字...`}
                      rows={4}
                      maxLength={['SAAS-DBL', 'SAAS-SF'].includes(form.getFieldValue('logisticsWayEnum')) ? 100 : 200}
                    />
                  )
                }
              </FormItem>
            </div>
            : null
        }

        {
          insureConfig && insureConfig.status === 'ENABLE'
            ? <>
              <Row className={`${styles.formitemRow}`}>
                <FormItem {...tailFormItemLayout} style={{ marginBottom: '0px' }}>
                  {
                    getFieldDecorator('isPinganInsure', {
                      valuePropName: 'checked',
                      initialValue: insureConfig.type === 'YEAR' || (info && info.orderType === 'ORDER_CLOUD_STOCK' && productAmount > MIN_INSURE_AMOUNT)
                    })(<Checkbox disabled={info && info.orderType === 'ORDER_CLOUD_STOCK' && productAmount > MIN_INSURE_AMOUNT}>为订单添加平安保险</Checkbox>)
                  }

                  <a style={{ marginLeft: '30px' }} onClick={() => this.showInsuraExplanModal()}>平安保险说明&nbsp;<Icon type='question-circle' /></a>
                </FormItem>
              </Row>

              {
                this.props.form.getFieldValue('isPinganInsure') === true
                  ? <Row className={`${styles.formitemRow}`}>
                    <FormItem {...tailFormItemLayout}>
                      <Alert type='error' message={<span>本次保险金额:&nbsp;{insurePay}</span>} />
                    </FormItem>
                  </Row>
                  : null
              }

            </>
            : null
        }

        <Row className={`${styles.formitemRow}`}>
          <FormItem label='温馨提示'>
            <Row style={{ color: '#F5222D', fontSize: '16px' }}>
              为保证您的发货速度，确认发货后，请提前联系快递小哥来取件&nbsp;<Icon type='smile' style={{ fontSize: '20px' }} />
            </Row>
          </FormItem>
        </Row>
      </>
    )
  }

  // 展示保险说明弹窗
  showInsuraExplanModal = () => {
    this.setState({
      explanModal: true
    })
  }

  closeModal = () => {
    this.setState({
      explanModal: false
    })
  }

  render () {
    const { form, info } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { tips = '', explanModal = false } = this.state

    const LogisticsWays = info.orderType && ['ORDER_CLOUD_STOCK', 'ORDER_CLOUD_FUTURES'].includes(info.orderType)
      ? saasCouierCodeMaps
      : [...SELFLogisticsWays, ...saasCouierCodeMaps]

    return (
      <>
        <Card title='快递信息' bodyStyle={{ padding: '15px' }}>
          <Row gutter={24}>
            <Col span={18} className={`${styles.bodyContainer}`}>
              <Row className={`${styles.serverWrap}`}>
                <Form>
                  <Row className={`${styles.formitemRow}`}>
                    <FormItem label='快递服务'>
                      {
                        getFieldDecorator('logisticsWayEnum', {
                          initialValue: 'SAAS-DBL',
                          rules: [
                            { required: true, message: '请选择快递服务' }
                          ]
                        })(
                          <Radio.Group onChange={(e) => this.handleChangeServerType(e.target.value)}>
                            {
                              LogisticsWays.map((item, i) => (
                                <Radio value={item.id} key={item.id}>{ item.courierName }</Radio>
                              ))
                            }
                          </Radio.Group>
                        )
                      }
                    </FormItem>
                  </Row>

                  {
                    getFieldValue('logisticsWayEnum') === 'SELF-NO'
                      ? <>
                        <Row className={`${styles.formitemRow}`}>
                          <FormItem label='快递公司'>
                            {
                              getFieldDecorator('courierCode', {
                                rules: [
                                  { required: true, message: '请选择快递公司' }
                                ]
                              })(
                                <Select placeholder='请选择...'
                                  showSearch
                                  optionFilterProp='children'
                                >
                                  {
                                    CourierJson.map((item) => (
                                      <Option value={item.courierCode} key={item.courierCode}>
                                        {item.courierName}
                                      </Option>
                                    ))
                                  }
                                </Select>
                              )
                            }
                          </FormItem>
                        </Row>
                        { this.renderSelfServerFormItem() }
                      </>
                      : null
                  }

                  {
                    $lodash.includes(getFieldValue('logisticsWayEnum'), 'SAAS-')
                      ? this.renderSAASServerNodes({ getFieldDecorator })
                      : null
                  }
                </Form>
              </Row>
            </Col>

            <Col span={6}>
              <HelpCard iscloudServer={$lodash.includes(getFieldValue('logisticsWayEnum'), 'SAAS-')}
                tips={tips} />
            </Col>
          </Row>
        </Card>

        {
          explanModal
            ? <InsuraexplanModal
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const SendserverCard = Form.create()(SendserverForm)

export default SendserverCard
