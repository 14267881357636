import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, Table, Row } from 'antd'

import SearchForm from '@/components/tool-form/index'
import BatchSearchMenu from '@/components/batchSearchMenu/index'
import UploadimgModal from './uploadimg-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class uploadimg extends Component {
  static propTypes ={
    history: PropTypes.object
  }

  state={
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    uploadimgmodal: false,
    tableData: [],
    rowdetail: '',
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      },
      {
        name: 'keyword',
        formType: 'Input',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '请输入型号或订货号',
          autoComplete: 'off'
        }
      }
    ]
  }
  columns=[
    {
      title: '序号',
      key: 'key-1',
      width: 50,
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'productLibraryt-1',
      width: 120,
      ellipsis: true,
      render: (text, record, index) => (
        <div>
          <div>{record.brandName}</div>
          <div>
            {
              record.englishBrandName && record.englishBrandName !== ''
                ? record.englishBrandName
                : '--'
            }
          </div>
        </div>
      )
    },

    {
      title: '型号',
      key: 'productLibrary-2',
      width: 160,
      ellipsis: true,
      render: (text, record, index) => (
        <div dangerouslySetInnerHTML={{ __html: record.model }} />
      )
    },
    {
      title: '订货号',
      key: 'productLibrary-3',
      width: 130,
      ellipsis: true,
      render: (text, record, index) => (
        <div dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
      )
    },
    {
      title: '产品描述',
      key: 'key-3',
      dataIndex: 'name'
    },
    {
      title: '操作',
      key: 'key-6',
      render: (text, record, index) => (
        <a onClick={() => { this.getuploadimg(record.commoditySkuId) }}>编辑</a>
      )
    }
  ]

  // 返回列表
  handleJumpToLink=() => {
    this.props.history.push('/admin/productLibrary')
  }

  componentDidMount () {
    this.getBrandList({ brandListType: 'MERCHANT_BRAND' })
    this.getMyProductListInProductModule()
  }

  // 获取品牌
  getBrandList = (params) => {
    Api.getBrandList(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        let { searchData } = this.state
        let _options = [
          { brandId: 0, showLabel: '全部' }
        ]

        if (data && data.length > 0) {
          data.map(item => {
            item.showLabel = item.brandName

            if (item.englishBrandName && item.englishBrandName !== '') {
              item.showLabel = `${item.brandName}/${item.englishBrandName}`
            }
          })
        }

        searchData[0].options = _options.concat(data)

        this.setState({
          searchData
        })
      }
    })
  }

 // 获取产品列表
 getMyProductListInProductModule = () => {
   const { pages, query } = this.state

   let params = {
     ...query,
     pageNo: pages.pageNo,
     pageSize: pages.pageSize
   }

   Api.getMyProductListInProductModule(params).then(res => {
     const { code, data = {} } = res
     if (+code === 10000) {
       this.setState({
         tableData: data.pageBean.list || [],
         pages: {
           ...pages,
           total: data.pageBean.total || 0
         },
         failModal: +data.failNumber > 0,
         failNumber: +data.failNumber,
         totalNumber: +data.totalNumber,
         successNumber: +data.successNumber,
         failList: data.failMessageList || []
       })
     }
   })
 }

   // 点击搜索
   handleSearch = (search = {}) => {
     const { pages } = this.state
     if (search.brandId && +search.brandId > 0) {
       search.brandId = [search.brandId]
     } else {
       delete search.brandId
     }

     this.setState({
       pages: {
         ...pages,
         pageNo: 1
       },
       query: search || {}
     }, () => this.getMyProductListInProductModule())
   }

 // 翻页
 pageChange = (page, pageSize) => {
   const { pages } = this.state
   this.setState({
     pages: {
       ...pages,
       pageNo: page
     }
   }, () => {
     this.getMyProductListInProductModule()
   })
 }

 // 改变每页条数
 pageSizeChange = (current, size) => {
   const { pages } = this.state

   this.setState({
     pages: {
       ...pages,
       pageNo: 1,
       pageSize: size
     }
   }, () => {
     this.getMyProductListInProductModule()
   })
 }

  // 编辑图片
  getuploadimg = (commoditySkuId) => {
    this.setState({
      uploadimgmodal: true,
      rowdetail: commoditySkuId
    })
  }

  // 关闭弹框
  closeModal = () => {
    this.setState({
      rowdetail: '',
      uploadimgmodal: false
    })
  }

  render () {
    const { tableData, pages, uploadimgmodal, searchData, rowdetail } = this.state

    // 分页配置
    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Button icon='left' onClick={this.handleJumpToLink}>返回产品列表</Button>

        <div style={{ marginTop: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <div style={{ display: 'inline-block', width: '120px', lineHeight: '40px', verticalAlign: 'top' }}>
              <BatchSearchMenu
                onConfirm={(values) => this.handleSearch(values)}
              />
            </div>

            <Row style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
              <SearchForm
                {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Card>

          <Card style={{ marginTop: '20PX' }}>
            <Table rowKey='commoditySkuId'
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination}
            />
          </Card>

          {
            uploadimgmodal
              ? <UploadimgModal
                onCancel={() => { this.closeModal() }}
                rowdetail={rowdetail}

              />
              : null
          }
        </div>
      </>

    )
  }
}
