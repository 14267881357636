import Ajax from '../utils/ajax'

export default {
  // 获取现货入库商品清单
  getStoreInProdList (params) {
    return Ajax.post('/api/merchant/product/info/product/stock/list/page', params)
  },

  // 手动更新总库存
  updateTotalStockInSingle (params) {
    return Ajax.post('/api/warehouse/update/totalStock', params)
  },

  // 获取期货 入库& 出库订单列表
  getStockFuturesOut (params) {
    return Ajax.post('/api/warehouse/list/page/futures/picking/order', params)
  },

  // 获取期货自营订单产品明细
  getStockFuturesInDetail (params) {
    return Ajax.post('/api/order/list/page/futures/self/order/details', params)
  },

  // 保存自营期货产品入库
  saveFuturesInStock (params) {
    return Ajax.post('/api/warehouse/futures/putInStorage', params)
  },

  // 入库延期交货接口
  getStockFuturesDelay (params) {
    return Ajax.post('/api/warehouse/futures/backOrder', params)
  },

  // 获取云期货订单产品明细
  getCloudfuturesOrderProductDetails (params) {
    return Ajax.post('/api/warehouse/platform/cloud/order/goods/detail', params)
  },

  // 保存云期货订单产品入库
  saveCloudfuturesOrderProductInStock (params) {
    return Ajax.post('/api/warehouse/cloud/futures/putInStorage', params)
  },

  // 保存云期货延期交货
  saveCloudfuturesDelaybackOrder (params) {
    return Ajax.post('/api/warehouse/cloud/futures/backOrder', params)
  },

  // 保存期货产品分批发货
  saveFuturesProductPrepareBatchSend (params) {
    return Ajax.post('/api/warehouse/futures/order/batch', params)
  },

  // 获取出库订单列表
  getStoreOutOrderList (params) {
    return Ajax.post('/api/warehouse/list/page/picking/order', params)
  },

  // 获取出库拣货单明细
  getStoreOutPickingDetail (params) {
    return Ajax.post('/api/warehouse/list/page/picking/batch/sku/info', params)
  },

  // 获取发货详细
  getSendOrderInfo (params) {
    return Ajax.post('/api/warehouse/get/deliver/goods/detail', params)
  },

  // 获取EU订单收货信息
  getEuOrderReceivingAddress (params) {
    return Ajax.post('/api/warehouse/get/eu/order/address', params)
  },

  // 确认发货
  saveSend (params) {
    return Ajax.post('/api/warehouse/confirm/deliver/goods', params)
  },

  // 确认重新发货
  saveRedispatchedSend (params) {
    return Ajax.post('/api/warehouse/anew/confirm/deliver/goods', params)
  },

  // 获取物流公司列表
  getCourierInfo (params) {
    return Ajax.get('/api/common/list/courierInfo', { params })
  },

  // 获取发货批次列表
  getSendedBatchList (params) {
    return Ajax.post('/api/warehouse/list/picking/order/batch/info', params)
  },

  // 获取发货的物流运单号信息
  getEWaybillInfo (params) {
    return Ajax.post('/api/logistics/get/deppon/express/manifest/info', params)
  },

  // 查看物流轨迹
  getLogisticsTraceByMailNo (params) {
    return Ajax.get('/api/order/order/logistic', { params })
  },

  // 保存拣货单备注信息
  saveSalesBillRemarks (params) {
    return Ajax.post('/api/warehouse/save/picking/order/remarks', params)
  },

  // 查询订单库存占用明细
  getStockOccupyDetail (params) {
    return Ajax.get('/api/warehouse/stockOccupyInfo', { params })
  },

  /** 发货地址管理START **/
  // 获取发货地址列表
  getSendAddressList (params) {
    return Ajax.post('/api/address/list/sender', params)
  },

  // 新增发货地址
  saveSendAddress (params) {
    return Ajax.post('/api/address/add/sender', params)
  },

  // 编辑发货地址
  saveEditSendAddress (params) {
    return Ajax.post('/api/address/update/sender', params)
  },

  // 删除发货地址
  deleteSendAddress (params) {
    return Ajax.post('/api/address/update/status/sender', params)
  },

  // 获取发货地址详情
  getSendAddressDetail (params) {
    return Ajax.get('/api/mall/manage/get/shipAddress', { params })
  },
  /** 发货地址管理END **/

  // 更新EU订单收货地址
  updateRecevingAddressByOrderId (params) {
    return Ajax.post('/api/warehouse/update/eu/order/address', params)
  },

  // 获取物流推荐
  // getRecommendTransports (params) {
  //   return Ajax.post('/api/logistics/get/recommend/transport/type', params)
  // },

  // 运费计算器- 获取物流推荐
  getRecommendTransports (params) {
    return Ajax.post('/api/logistics/getTransportPriceAndTimeList', params)
  },

  // 获取平安保险服务配置信息
  getPinganInsureConfig (params) {
    return Ajax.get('/api/warehouse/getPinganInsureConfig', { params })
  },

  // 查询发货快递付费类型
  getFreightPayType (params) {
    return Ajax.get('/api/warehouse/getFreightPayType', { params })
  },

  // 查询发货列表-快递是否可取消
  getAllowCancelExpress (params) {
    return Ajax.get('/api/warehouse/getAllowCancel', { params })
  },

  // 取消物流订单
  saveCancelExpress (params) {
    return Ajax.post('/api/warehouse/cancel', params)
  }
}
