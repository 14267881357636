import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Progress, Badge, Row, Pagination } from 'antd'

import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class ProgressModal extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    getProgress: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }

  componentWillMount () {
    this.getProgress()
    this.interval = setInterval(() => this.getProgress(), 3000)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  // 获取进度
  getProgress = () => {
    const { pages } = this.state

    this.props.getProgress({ pageNo: pages.pageNo, pageSize: pages.pageSize }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getProgress()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getProgress()
    })
  }

  render () {
    const { pages, tableData = [] } = this.state

    return (
      <div>
        <Modal title='查看进度'
          width={800}
          visible
          maskClosable={false}
          onOk={this.props.onCancel}
          onCancel={this.props.onCancel}
          bodyStyle={{ height: '350px', overflow: 'scroll' }}
        >
          <Row>
            <Row>
              {
                tableData.length > 0
                  ? <ul>
                    {
                      tableData.map((item, i) => (
                        <li key={`progressItem-${i}`}
                          style={i === tableData.length - 1 ? {} : { marginBottom: '15px' }}
                        >
                          <div style={{ marginBottom: '5px' }}>
                            <span>批次号：{item.batchNo}</span>
                          </div>
                          <div style={{ marginBottom: '5px' }}>
                            <Badge status='warning' text={`添加总数：${item.quantity}`}
                              style={{ marginRight: '20px' }}
                            />

                            <Badge status='success' text={`已成功数：${item.finishQuantity}`}
                              style={{ marginRight: '20px' }}
                            />

                            <Badge status='processing' text={`待处理数：${item.quantity - item.finishQuantity}`} />
                          </div>
                          <div>
                            <Progress
                              percent={((+item.finishQuantity / +item.quantity) * 100).toFixed(2)}
                            />
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                  : <div style={{ textAlign: 'center' }}>
                    暂无数据
                  </div>
              }
            </Row>

            <Row style={{ paddingTop: '20px', textAlign: 'right' }}>
              <Pagination
                showSizeChanger
                total={pages.total}
                showTotal={(total) => { return `共 ${total} 条` }}
                pageSizeOptions={pageSizeOptions}
                current={pages.pageNo}
                pageSize={pages.pageSize}
                onChange={this.pageChange}
                onShowSizeChange={this.pageSizeChange}
              />
            </Row>
          </Row>

        </Modal>
      </div>
    )
  }
}
