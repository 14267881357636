import React from 'react'
import { Modal, Icon } from 'antd'

export default function DelayedModal () {
  return (
    <>
      <Modal visible maskClosable={false} footer={null} closable={false} width={500}>
        <div style={{ display: 'flex', height: '100px', alignItems: 'center', justifyContent: 'center' }}>
          <Icon type='check-circle' style={{ fontSize: '24px', color: '#52c41a', marginRight: '15px' }} />
          <span style={{ fontSize: '16px' }}>数据处理中</span>
        </div>
      </Modal>
    </>
  )
}
