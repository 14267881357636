import Ajax from '../utils/ajax'

export default {
  // 自营开票- 获取自营开票数据列表
  getEUOrderInvoiceList (params) {
    return Ajax.post('/api/euOrderInvoice/list/page/record', params)
  },

  // 自营开票- 获取EU发票详情信息
  getEUOrderInvoiceDetails (params) {
    return Ajax.post('/api/euOrderInvoice/get/record/info', params)
  },

  // 自营开票- 确认EU开具发票
  submitInvoiceOpend (params) {
    return Ajax.post('/api/euOrderInvoice/confirmOpenInvoice', params)
  },

  // 自营开票- 确认EU重新开票
  submitEUOrderInvoiceRepeatOpend (params) {
    return Ajax.post('/api/euOrderInvoice/reConfirmOpenInvoice', params)
  },

  // 云采购发票- 获取开票中数据列表
  getPurchaseOrderInvoiceOpeningList (params) {
    return Ajax.post('/api/merchantOrderInvoice/list/page/opening', params)
  },

  // 云采购发票- 获取开票中详情数据
  getPurchaseOrderInvoiceOpeningDetails (params) {
    return Ajax.post('/api/merchantOrderInvoice/get/merchantOrderInvoice/detail', params)
  },

  // 云采购发票- 获取开票完成数据列表
  getPurchaseOrderInvoiceOpenedList (params) {
    return Ajax.post('/api/merchantOrderInvoice/list/page/opened', params)
  },

  // 云采购发票- 获取开票完成详情数据
  getPurchaseOrderInvoiceOpenedDetails (params) {
    return Ajax.get('/api/merchantOrderInvoice/get/merchantInvoiceRecord/detail', { params })
  },

  // 运供应发票- 获取云供应申请开票列表
  getSupplyOrderInvoiceApplyOpeningList (params) {
    return Ajax.post('/api/platformOrderInvoice/list/page/invoiceRecordHistory', params)
  },

  // 云供应发票- 获取开票详情
  getSupplyOrderInvoiceOpeningDetails (params) {
    return Ajax.post('/api/platformOrderInvoice/get/record/info', params)
  },

  // 云供应发票- 确认云供应开票
  saveSupplyOrderInvoiceOpened (params) {
    return Ajax.post('/api/platformOrderInvoice/confirmOpenInvoice', params)
  },

  // 云供应发票- 确认重新开票
  saveSupplyOrderInvoiceRepeatOpened (params) {
    return Ajax.post('/api/platformOrderInvoice/confirmOpenInvoiceReload', params)
  }
}
