import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Checkbox, Row, Col, message } from 'antd'
import lodash from 'lodash'
import Api from '@/common/api/index'

export default class DowncolModal extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    future: PropTypes.bool
  }
  state = {
    ProducMap: [], // 产品信息字段
    ProduccheckAll: true, //
    OrderMap: [], // 订单信息字段
    OrdecheckAll: true,
    selectedKeys: []
  }

  componentDidMount () {
    this.getOrdergetOrderFieldMap()
    this.getOrdergetProductFieldMap()
  }

  // 获得产品信息
  getOrdergetProductFieldMap = async () => {
    let res = await Api.getOrdergetProductFieldMap({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        ProducMap: this.getMap(data).map
      })
    }
  }

  // 获得订单信息
  getOrdergetOrderFieldMap = async () => {
    const { future } = this.props
    let res = future
      ? await Api.getOrdergetFutureOrderFieldMap({})
      : await Api.getOrdergetOrderFieldMap({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        OrderMap: this.getMap(data).map
      })
    }
  }

  // 初始化循环
  getMap = (data) => {
    let arr = []
    let defaultArr = []
    Object.keys(data).forEach((item, i) => {
      let obj = {
        value: item,
        label: data[item],
        checked: true
      }
      arr.push(obj)
      defaultArr.push(item)
    })
    let obj = {
      map: arr,
      keys: defaultArr
    }
    return obj
  }

  // 点击确定
  handleConfirm = () => {
    const { OrderMap, ProducMap } = this.state
    let arr = []
    let aoo = []
    OrderMap.forEach(item => {
      if (item.checked === true) {
        arr.push(item.value)
      }
    })
    ProducMap.forEach(item => {
      if (item.checked === true) {
        aoo.push(item.value)
      }
    })
    if ((aoo.length + arr.length) === 0) {
      return message.warning('请选择要导出的字段')
    }

    this.props.onConfirm({ fieldList: [...aoo, ...arr] })
  }
  // 产品信息全选或不全选
  onCheckAllChangeProduc = (e, type) => {
    const { ProducMap, OrderMap } = this.state
    if (type && type === 1) {
      this.getFormmatLiat(OrderMap, e.target.checked, type)
    } else {
      this.getFormmatLiat(ProducMap, e.target.checked)
    }
  };

  // 全选数据格式化
  getFormmatLiat = (list, check, type) => {
    list.forEach((item, i) => {
      item.checked = !!check
    })
    if (type && type === 1) {
      this.setState({
        OrderMap: list,
        OrdecheckAll: check
      })
    } else {
      this.setState({
        ProducMap: list,
        ProduccheckAll: check
      })
    }
  }
  // 复选框变化
  ProducCheckbox = (i, type) => {
    const { ProducMap, OrderMap } = this.state
    let list = type && type === 1 ? OrderMap : ProducMap
    list[i].checked = list[i].checked !== true
    this.setState({
      list
    }, () => {
      let obj = lodash.filter(list, item => { return item.checked === false })
      if (type && type === 1) {
        this.setState({
          OrdecheckAll: !(obj.length > 0)
        })
      } else {
        this.setState({
          ProduccheckAll: !(obj.length > 0)
        })
      }
    })
  }
  render () {
    const { ProducMap, ProduccheckAll, OrderMap, OrdecheckAll } = this.state
    return (
      <div>
        <Modal title='自定义导出项' bodyStyle={{ padding: '15px 20px' }}
          visible
          width={600}
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}

        >
          <Row gutter={24} style={{ width: '80%', margin: '0 auto' }}>
            <Col span={12}>
              {
                ProducMap && ProducMap.length > 0
                  ? <Row>
                    <Checkbox
                      onChange={(e) => { this.onCheckAllChangeProduc(e) }}
                      checked={ProduccheckAll}
                    >
                     产品信息
                    </Checkbox>
                    <Row>
                      {
                        ProducMap.map((item, i) => (
                          <div span={6} key={`optionsCol-${i}`}>
                            <Checkbox style={{ marginLeft: '15px' }} onChange={() => { this.ProducCheckbox(i) }} checked={item.checked} value={item.value}>{item.label}</Checkbox>
                          </div>
                        ))
                      }
                    </Row>
                  </Row>
                  : null
              }
            </Col>

            <Col span={12}>
              {
                OrderMap && OrderMap.length > 0
                  ? <Row>
                    <Checkbox
                      onChange={(e) => { this.onCheckAllChangeProduc(e, 1) }}
                      checked={OrdecheckAll}
                    >
                     订单信息
                    </Checkbox>
                    <Row>
                      {
                        OrderMap.map((item, i) => (
                          <div span={6} key={`optionsCol-${i}`}>
                            <Checkbox style={{ marginLeft: '15px' }} onChange={() => { this.ProducCheckbox(i, 1) }} checked={item.checked} value={item.value}>{item.label}</Checkbox>
                          </div>
                        ))
                      }
                    </Row>
                  </Row>
                  : null
              }
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}
