import React, { Component } from 'react'
import { Row, Tabs, Button, Icon, message, Modal, Alert } from 'antd'

import Api from '@/common/api/index'

import PageModal from './pageModal'
import FloorModal from './floorModal'
import FloorTpl1 from './floorTpl1'
import FloorTpl2 from './floorTpl2'
import FloorTpl3 from './floorTpl3'
import FloorTpl4 from './floorTpl4'
import FloorTpl5 from './floorTpl5'

const { TabPane } = Tabs
const { confirm } = Modal

export default class NavitemDecorate extends Component {
  static propTypes = {}
  state = {
    pageModal: false,
    pageList: [], // 所有页面集合
    curTabKey: '', // 当前选中的tab项
    navInfo: {},
    floorList: []
  }

  componentDidMount () {
    this.getListByType()
    // this.getMallNavList()
  }

  // 获取所有自定义二级页面列表
  getListByType = () => {
    Api.getListByType({ type: '20000' }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          pageList: data || []
        }, () => {
          if (data && data.length > 0) {
            this.handleChangeTabs(data[0].id)
          }
        })
      }
    })
  }

  // 删除二级页面
  deleteTabPane = (params) => {
    Api.deleteNavFloor(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getListByType()
      }
    })
  }

  // 新增二级页面
  saveAddPage = (params) => {
    Api.saveAddNavFloor(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getListByType()
      }
    })
  }

  // 获取导航菜单内容
  getMallNavList = () => {
    const { curTabKey = '' } = this.state
    Api.getMallNavList({ type: 20000, id: curTabKey }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          navInfo: data || {},
          floorList: data && data.containerVoList ? data.containerVoList : []
        })
      }
    })
  }

  // 创建页面楼层
  saveAddNavFloor = (params) => {
    Api.saveAddNavFloor(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getMallNavList()
      }
    })
  }

  // 删除楼层
  deleteNavFloor = (params) => {
    Api.deleteNavFloor(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getMallNavList()
      }
    })
  }

  // 点击切换tab
  handleChangeTabs = (key) => {
    this.setState({
      curTabKey: key,
      navInfo: {},
      floorList: []
    }, () => {
      this.getMallNavList()
    })
  }

  // 点击新增TabPane
  handleAddTabPane = () => {
    this.setState({
      pageModal: true
    })
  }

  // 点击删除TabPane
  handleRemoveTabPane = (targetKey) => {
    confirm({
      title: '确定删除该页面?',
      onOk: () => {
        this.deleteTabPane({ id: +targetKey })
      }
    })
  }

  // 确定创建楼层
  handleConfirmCreateFloor = (values) => {
    const { curTabKey = '' } = this.state
    values.parentId = curTabKey

    this.saveAddNavFloor(values)
  }

  // 点击创建楼层
  handleCreateFloor = () => {
    this.setState({
      floorModal: true
    })
  }

  closeModal = () => {
    this.setState({
      floorModal: false,
      pageModal: false
    })
  }

  render () {
    const { pageModal = false, floorModal = false, pageList = [], floorList = [], curTabKey = '' } = this.state

    const operations = (
      <Button type='primary' size='small'
        onClick={this.handleAddTabPane}
      >
        <Icon type='plus' />新增页面
      </Button>
    )

    return (
      <div>
        <Row>
          <Tabs type='editable-card'
            hideAdd
            tabBarExtraContent={operations}
            onChange={this.handleChangeTabs}
            onEdit={this.handleRemoveTabPane}
          >
            {
              pageList.map((titem) => (
                <TabPane tab={titem.name} key={titem.id} />
              ))
            }
          </Tabs>
        </Row>

        {
          pageList.length > 0
            ? <Row>
              <Row>
                <div style={{ marginBottom: '8px' }}>
                  <Alert type='warning' message={`页面地址: /brand-intro?id=${curTabKey}`} />
                </div>

                {
                  floorList.length > 0
                    ? <div>
                      {
                        floorList.map((item, f) => (
                          <Row key={`floor-${f}`}>
                            {
                              +item.type === 1
                                ? <Row style={{ marginBottom: '16px' }}>
                                  <FloorTpl1
                                    floorInfo={item}
                                    onRefush={this.getMallNavList}
                                    onDelete={(params) => this.deleteNavFloor(params)}
                                  />
                                </Row>
                                : null
                            }

                            {
                              +item.type === 2
                                ? <Row style={{ marginBottom: '16px' }}>
                                  <FloorTpl2
                                    floorInfo={item}
                                    onRefush={this.getMallNavList}
                                    onDelete={(params) => this.deleteNavFloor(params)}
                                  />
                                </Row>
                                : null
                            }

                            {
                              +item.type === 3
                                ? <Row style={{ marginBottom: '16px' }}>
                                  <FloorTpl3
                                    floorInfo={item}
                                    onRefush={this.getMallNavList}
                                    onDelete={(params) => this.deleteNavFloor(params)}
                                  />
                                </Row>
                                : null
                            }

                            {
                              +item.type === 4
                                ? <Row style={{ marginBottom: '16px' }}>
                                  <FloorTpl4
                                    floorInfo={item}
                                    onRefush={this.getMallNavList}
                                    onDelete={(params) => this.deleteNavFloor(params)}
                                  />
                                </Row>
                                : null
                            }

                            {
                              +item.type === 5
                                ? <Row style={{ marginBottom: '16px' }}>
                                  <FloorTpl5
                                    floorInfo={item}
                                    onRefush={this.getMallNavList}
                                    onDelete={(params) => this.deleteNavFloor(params)}
                                  />
                                </Row>
                                : null
                            }
                          </Row>
                        ))
                      }
                    </div>
                    : <div style={{ padding: '30px 0px', textAlign: 'center', color: '#666666' }}>
                      暂无页面内容信息，您可以点击下方 创建楼层 来编辑你要装饰的内容
                    </div>
                }

              </Row>

              <Row>
                <Button type='dashed' block
                  onClick={() => this.handleCreateFloor(1, {})}
                >
                  <Icon type='plus' />
                   创建楼层
                </Button>
              </Row>
            </Row>
            : null
        }

        {
          floorModal
            ? <FloorModal
              onConfirm={(values) => this.handleConfirmCreateFloor(values)}
              onCancel={() => this.closeModal()}
            />
            : null

        }

        {
          pageModal
            ? <PageModal
              onConfirm={(params) => this.saveAddPage(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </div>
    )
  }
}
