/**
 * 产品模块
 */
import ModelLibrary from '@/pages/product/model-library/index' // 型号库
import ProductLibrary from '@/pages/product/product-library/index' // 我的产品库
import SearchCondition from '@/pages/product/search-condition/index' // 条件操作
import UploadImg from '@/pages/product/product-library/uploadimg' // 上传图片
import LotUploadImg from '@/pages/product/product-library/lotuploadimg' // 批量上传图片

export default [
  {
    name: '型号库',
    path: '/admin/modelLibrary',
    component: ModelLibrary
  },
  {
    name: '我的产品库',
    path: '/admin/productLibrary',
    component: ProductLibrary
  },
  {
    name: '条件操作',
    path: '/admin/searchCondition',
    component: SearchCondition
  },
  {
    name: '上传图片',
    path: '/admin/uploadimg',
    component: UploadImg
  },
  {
    name: '批量设置图片',
    path: '/admin/lotuploadimg',
    component: LotUploadImg
  }
]
