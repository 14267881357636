import React, { Component } from 'react'
import UploadApi from '@/common/api/uploadApi'
import PropTypes from 'prop-types'
import { Icon, Upload, Radio, message, Button } from 'antd'

import { getStore } from '@/common/utils/mUtils'

import styles from './index.module.scss'
const { uploadOrdinaryImageUrl } = UploadApi

export default class imgpdf extends Component {
  static propTypes = {

    list: PropTypes.array,
    onUpload: PropTypes.func,
    onDelete: PropTypes.func,
    onSelect: PropTypes.func
  }
  state = {}

   // PDF上传
   pdfhandleChangeUpload = (info) => {
     if (info.file.status === 'done') {
       const { code, data } = info.file.response
       if (+code === 10000 && data && data.imageUrl) {
         this.props.onUpload({ imageUrl: data.imageUrl, showStatus: 'NOT_SHOW' })
       } else {
         message.error('上传失败')
       }
     } else if (info.file.status === 'error') {
       message.error('上传失败')
     }
   }

   // 上传判断
   pdfbeforeUpload=(file) => {
     const isLt1M = file.type === 'application/pdf'

     if (!isLt1M) {
       message.warning(`请上传pdf文件`)
     }

     return isLt1M
   }

   render () {
     const { list = [] } = this.props
     return (
       <div >

         <div style={{ marginTop: '10px', display: 'flex' }}>
           {
             list.map((item, i) => (
               <div className={`${styles.inline}`} key={`uploadProd-${i}`}>
                 <div>
                   <div className={`${styles.thumbWrap}`}>
                     <div className={styles.thumbInfo}>
                       <div className={styles.pdfbaimg}>
                         <span className={styles.prewModal}>
                           <a href={item.imageUrl} target='_blank' style={{ color: 'white' }}>
                             <Icon type='eye' style={{ fontSize: '20px' }} />
                           </a>
                           <a >
                             <Icon type='delete' style={{ fontSize: '20px', color: 'rgba(255, 255, 255, 0.85)' }}
                               onClick={() => this.props.onDelete(i)}
                             />
                           </a>
                         </span>
                       </div>
                     </div>
                   </div>
                   <div>
                     <Radio
                       checked={item.showStatus === 'SHOW'}
                       disabled={item.imageUrl === ''}
                       onChange={() => this.props.onSelect(i)}
                     >
                        设置唯一显示
                     </Radio>
                   </div>

                 </div>
               </div>
             ))

           }

           <div style={{ display: 'flex', marginLeft: '20px' }} className={styles.pdfup} >
             <Upload showUploadList={false} key={`upload-${0}`}
               accept='pdf'
               headers={{ Token: getStore('token') }}
               action={uploadOrdinaryImageUrl}
               listType='text'
               beforeUpload={this.pdfbeforeUpload}
               onPreview={this.handlePreview}
               onChange={(info) => this.pdfhandleChangeUpload(info)}
             >
               <div>
                 <Button>点击上传</Button>
               </div>
             </Upload>
           </div>
         </div>
       </div>
     )
   }
}
