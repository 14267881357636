console.log(PATH_ENV)

var hostArr = window.location.host.split('.')
var linkhost = window.location.host.split('.')
var protocol = window.location.protocol // 域名协议

hostArr[0] = 'manage'
linkhost[0] = PATH_ENV === 'prod' ? 'web' : 'h5'
let _url = hostArr.join('.')
let _h5url = linkhost.join('.')

const BASEURL = {
  prod: `${protocol}//${_url}`,
  pre: `http://${_url}`,
  test: `http://${_url}`,
  dev: `http://manage.nanjingsaas.cn:8080`
}

const H5HOST = {
  prod: `https://${_h5url}`,
  pre: `http://${_h5url}`,
  test: `http://${_h5url}`,
  dev: `http://h5.nanjingsaas.cn:8080`
}

const SOCKETWS = {
  prod: `wss://ws.iiasaas.com`,
  test: `ws://192.168.1.11:7999`,
  dev: `ws://192.168.1.11:7999`
}

// 袁: http://192.168.1.102:8300
// 文：http://manage.wenyuxiang.com
// 郑：http://192.168.1.104:8300
// pre测试 http://manage.china-iia.net
// 测试: http://manage.mall.com

// 测试穿透: http://manage.nanjingsaas.cn:8080

const host = BASEURL[PATH_ENV]
const h5host = H5HOST[PATH_ENV]
const socketWs = SOCKETWS[PATH_ENV]

export default {
  host,
  h5host,
  socketWs
}
