import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Row, Col, Button, Divider, Input, Radio, Cascader, Upload, Icon, message, Modal, AutoComplete } from 'antd'
import $lodash from 'lodash'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'

import filter from '@/common/utils/filter'
import { getStore, addressToTree } from '@/common/utils/mUtils'

import styles from './customer.module.scss'

const { loginnameReg, passReg } = filter
const { uploadOrdinaryImageUrl } = UploadApi
const FormItem = Form.Item
const AutoCompleteOption = AutoComplete.Option
const { confirm } = Modal
const cityOptions = addressToTree()

const endUserMap = [
  { type: 1, label: '个人用户' },
  { type: 2, label: '企业用户' }
]

const checkEndPriseResultMaps = [
  { status: 'success', message: '公司名称可用' },
  { status: 'error', message: '公司名称已存在' }
]

const checkLoginResultMaps = [
  { status: 'success', message: '登录名可用' },
  { status: 'error', message: '登录名已存在' }
]

class EnduerForm extends Component {
  constructor (props) {
    super(props)
    this.onSearchCompanyList = $lodash.debounce(this.onSearchCompanyList, 300)
  }

  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }

  state = {
    type: 1, // 1-新增 2-编辑
    endUser: {}, // 个人信息
    euEnterprise: {}, // 企业信息
    licenseFile: '', // 营业执照图片
    endUserType: 1, // 1-个人用户 2-企业用户
    uncompanyList: [], // 可选的公司列表
    checkEndPriseResult: {},
    checkLoginResult: {}
  }

  componentDidMount () {
    const { location } = this.props.history
    const { state } = location

    let endUserType = 1
    let _licenseFile = ''

    if (state) {
      if (+state.enterpriseId > 0) {
        endUserType = 2
        if (state.euEnterprise.businessLicenseImg && state.euEnterprise.businessLicenseImg !== '') { }
        _licenseFile = state.euEnterprise.businessLicenseImg
      }

      this.setState({
        endUser: state.endUser || {},
        euEnterprise: state.euEnterprise || {},
        type: state.type,
        endUserType,
        licenseFile: _licenseFile
      })
    }
  }

  // 新增客户
  saveAddEnduser = (params) => {
    Api.saveAddEnduser(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
      }
    })
  }

  // 保存客户编辑
  saveEditEnduser = (params) => {
    Api.saveEditEnduser(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
      }
    })
  }

  // 重置客户账号密码
  resetAccount = (params) => {
    Api.resetAccount(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
      }
    })
  }

  // 校验公司名称是否存在
  checkExistsName = (params) => {
    let { existsNameType } = params
    Api.checkExistsName(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let str = ''
        let obj = {}
        if (existsNameType === 'EU_ENTERPRISE_NAME') {
          str = 'checkEndPriseResult'
          obj = checkEndPriseResultMaps[+data]
        } else {
          str = 'checkLoginResult'
          obj = checkLoginResultMaps[+data]
        }

        this.setState({
          [str]: obj
        })
      }
    })
  }

  // 点击重置账号
  handleResetAccount = (row) => {
    confirm({
      title: '确定重置该账号登录密码?',
      content: '',
      onOk: () => {
        let params = {
          euId: row.id
        }
        this.resetAccount(params)
      }
    })
  }

  // 点击保存
  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { type = 1, endUser = {}, euEnterprise = {}, endUserType = 1, licenseFile } = this.state
        let { password } = values
        let jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values['password'] = jsencrypt.encrypt(password)

        if (type === 2) {
          if (endUserType === 2) {
            values.id = +euEnterprise.id > 0 ? euEnterprise.id : ''
          }
          values.euId = endUser.id || ''
        } else {
          values.userType = endUserType
        }

        if (values.citys && values.citys.length > 0) {
          let { citys } = values

          values.province = citys[0]
          values.city = citys[1]
          values.county = citys[2]
        }

        values['businessLicenseImg'] = licenseFile
        delete values.citys
        if (type === 1) {
          this.saveAddEnduser(values)
        } else {
          this.saveEditEnduser(values)
        }
      }
    })
  }

  // 上传营业执照
  beforeUploadLicense = (file, fileList) => {
    const isLt1M = file.size / 1024 / 1024 < 1
    if (!isLt1M) {
      message.warning('图片大小不可超过 1MB！')
    }

    return isLt1M
  }

  // 上传营业执照
  onChangeBusinessLicenseImg = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response

      if (+code === 10000) {
        this.setState({
          licenseFile: data.imageUrl
        })
      } else {
        message.error('上传图片失败')
      }
    }
  }

  // 删除营业执照
  removeUploadLicense = () => {
    this.setState({
      licenseFile: ''
    })
  }

  // 监听客户类型
  changeSelectedEndUser = (val) => {
    this.setState({
      endUserType: val
    })
  }

  // 模糊搜索企业列表
  onSearchCompanyList = (key) => {
    Api.getOnSearchCompanyList({ keyword: key, pageNo: 1, pageSize: 10 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          uncompanyList: data || []
        })
      }
    })
  }

  // 选择企业
  onChangeSelectCompany = (params) => {
    Api.getCompanyDetailInfo(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        if (data && data.econKindCode) {
          setTimeout(() => {
            this.props.form.setFieldsValue({
              taxId: data.creditNo || '',
              accountName: data.operName || '',
              address: data.address || '',
              citys: [`${data.province}`, `${data.city}`, `${data.county}`]
            })
          }, 10)
        }
      }
    })
  }

  // 点击选择公司
  handleSelectedCompany = (value) => {
    setTimeout(() => {
      this.props.form.setFieldsValue({
        name: value
      }, () => {
        this.onChangeSelectCompany({ keyword: value })
      })
    }, 10)
  }

  // 返回列表
  handleBack = () => {
    this.props.history.goBack()
  }

  // 点击重置
  handleReset = () => {
    this.props.form.resetFields()
    this.setState({
      licenseFile: ''
    })
  }

  // 点击校验公司名称&&登录名是否存在
  handleCheckExistsName = (keyword, type) => {
    this.props.form.validateFields([keyword], (err, values) => {
      if (!err) {
        let params = {
          existsNameType: type,
          name: type === 'EU_ENTERPRISE_NAME' ? values.name : values.loginName
        }
        this.checkExistsName(params)
      }
    })
  }

  // 设置省市区级联回显
  renderCascaderDefaultValue = () => {
    const { euEnterprise = {} } = this.state
    let arr = []

    if (+euEnterprise.province > 0 && +euEnterprise.city > 0) {
      arr.push(`${euEnterprise.province}`, `${euEnterprise.city}`, `${euEnterprise.county}`)
    }

    return arr
  }

  // 提示
  getcompany = () => {
    return (<span style={{ color: 'red' }}>*注：公司名称只支持中文，和中文括号（）</span>)
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { endUser = {}, licenseFile, euEnterprise = {}, type = 1, endUserType = 1, uncompanyList = [], checkLoginResult = {}, checkEndPriseResult = {} } = this.state

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Button icon='left' onClick={() => this.handleBack()}>
            返回
          </Button>
        </Row>

        <Card title='商城客户信息' bodyStyle={{ padding: '15px' }}>
          <Row className={`${styles.container}`}>
            <Form>
              <Row style={{ marginBottom: '24px' }}>
                <label className={`${styles.formLabel}`}>客户类型</label>
                <Radio.Group
                  disabled={!!(type === 2 && +endUser.enterpriseId > 0)}
                  value={endUserType}
                  onChange={(e) => this.changeSelectedEndUser(e.target.value)}
                >
                  {
                    endUserMap.map((item, i) => (
                      <Radio value={item.type} key={`endUserType-${i}`}>{item.label}</Radio>
                    ))
                  }
                </Radio.Group>
              </Row>

              <Row className={`${styles.baseLand}`}>
                <FormItem style={{ marginBottom: '0px' }}>
                  <FormItem style={{ marginBottom: '0px', display: 'inline-block', width: `${type === 1 ? 'calc(100% - 120px)' : '100%'}` }}>
                    <Row className={`${styles.formitemRow}`}>
                      <FormItem label='登录名' hasFeedback={checkLoginResult.status && checkLoginResult.status !== ''}
                        help={<span className={`${checkLoginResult.status === 'error' ? styles.error : styles.success}`}>{checkLoginResult.message}</span>}
                        validateStatus={checkLoginResult.status}
                        extra={<span className={`${styles.formExtra}`}>登录名长度为4-16位字符，只能包含数字,大小写字母,下划线</span>}>
                        {
                          getFieldDecorator('loginName', {
                            initialValue: endUser && endUser.username ? endUser.username : '',
                            validate: [
                              {
                                trigger: 'onBlur',
                                rules: [
                                  {
                                    validator (rule, value, callback) {
                                      if (value && !loginnameReg.test(value)) {
                                        /* eslint-disable */
                                    callback('登录名格式不正确')
                                    /* eslint-enable */
                                      } else {
                                        callback()
                                      }
                                    }
                                  }
                                ]
                              },
                              {
                                trigger: ['onChange', 'onBlur'],
                                rules: [{ required: true, message: '请您填写登录名' }]
                              }
                            ]
                          })(
                            <Input placeholder='请输入客户登录名...' autoComplete='off' disabled={type === 2 && +endUser.status === 1} />
                          )
                        }
                      </FormItem>
                    </Row>
                  </FormItem>
                  {
                    type === 1
                      ? <FormItem style={{ marginBottom: '0px', display: 'inline-block', width: '110px', marginLeft: '10px' }}>
                        <Button type='primary' style={{ padding: '0px', width: '110px' }} onClick={() => this.handleCheckExistsName('loginName', 'EU_USERNAME')}>
                          校验是否存在
                        </Button>
                      </FormItem>
                      : null
                  }
                </FormItem>

                <FormItem style={{ marginBottom: '0px' }}>
                  <FormItem style={{ display: 'inline-block', width: 'calc(100% - 120px)', marginBottom: '0px' }}>
                    <Row className={`${styles.formitemRow}`}>
                      <FormItem label='登录密码'
                        extra={<span className={`${styles.formExtra}`}>密码长度为6-12位字符，可包含数字,字母(区分大小写)</span>}
                      >
                        {
                          getFieldDecorator('password', {
                            initialValue: endUser && endUser.password ? endUser.password : '',
                            getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                            validate: type === 2 ? [
                              {
                                rules: [{ required: true, message: '请您填写登录密码' }]
                              }
                            ] : [
                              {
                                trigger: 'onBlur',
                                rules: [
                                  {
                                    validator (rule, value, callback) {
                                      if (value && !passReg.test(value)) {
                                        /* eslint-disable */
                                        callback('登录密码格式不正确')
                                        /* eslint-enable */
                                      } else {
                                        callback()
                                      }
                                    }
                                  }
                                ]
                              },
                              {
                                trigger: ['onChange', 'onBlur'],
                                rules: [{ required: true, message: '请您填写登录密码' }]
                              }
                            ]
                          })(
                            <Input.Password placeholder='请输入6-12位登录密码' autoComplete='off' disabled={type === 2 && +endUser.status === 1} />
                          )
                        }
                      </FormItem>
                    </Row>
                  </FormItem>
                  <FormItem style={{ display: 'inline-block', width: '110px', marginLeft: '10px', marginBottom: '0px' }}>
                    {
                      type === 2
                        ? <Button type='primary'
                          style={{ width: '110px' }}
                          onClick={() => this.handleResetAccount(endUser)}
                        >重置密码</Button>
                        : null
                    }
                  </FormItem>
                </FormItem>

                <Row className={`${styles.formitemRow}`}>
                  <FormItem label='真实姓名'
                    extra={<span className={`${styles.formExtra}`}>友情提示：真实姓名只支持中文</span>}
                  >
                    {
                      getFieldDecorator('realName', {
                        initialValue: endUser && endUser.realname ? endUser.realname : '',
                        getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                        validate: [
                          {
                            trigger: 'onBlur',
                            rules: [
                              {
                                validator (rule, value, callback) {
                                  if (value && !/^[\u4E00-\u9FA5]{2,4}$/.test(value)) {
                                    /* eslint-disable */
                                    callback('真实姓名必须为中文')
                                    /* eslint-enable */
                                  } else {
                                    callback()
                                  }
                                }
                              }
                            ]
                          },
                          {
                            trigger: ['onChange', 'onBlur'],
                            rules: [{ required: true, message: '请您填写中文真实姓名' }]
                          }
                        ]
                      })(
                        <Input placeholder='请输入客户真实姓名...' autoComplete='off' />
                      )
                    }
                  </FormItem>
                </Row>

                <Row className={`${styles.formitemRow}`}>
                  <FormItem label='手机号码' extra={<span className={`${styles.formExtra}`}>请输入一个可用于收取验证码的电话号码</span>}>
                    {
                      getFieldDecorator('adminPhoneNumber', {
                        initialValue: endUser && endUser.phoneNumber ? endUser.phoneNumber : '',
                        validate: [
                          {
                            trigger: 'onBlur',
                            rules: [
                              {
                                validator (rule, value, callback) {
                                  if (value && !/^\d+[-]?\d+$/g.test(value)) {
                                    /* eslint-disable */
                                   callback('手机号码格式不正确')
                                /* eslint-enable */
                                  } else {
                                    callback()
                                  }
                                }
                              }
                            ]
                          },
                          {
                            trigger: 'onChange',
                            rules: [{ required: false, message: '请您填写收件人手机号码' }]
                          }
                        ]
                      })(
                        <Input placeholder='请输入手机号...' autoComplete='off' />
                      )
                    }

                  </FormItem>
                </Row>
              </Row>

              <Divider dashed />

              {
                endUserType === 1
                  ? null
                  : <Row>
                    <Row gutter={24}>
                      <Col span={10} style={{ minHeight: '65px' }}>
                        <FormItem style={{ marginBottom: '0px' }}>
                          <FormItem style={{ marginBottom: '0px', display: 'inline-block', width: `${type === 1 ? 'calc(100% - 120px)' : '100%'}` }}>
                            <Row className={`${styles.formitemRow}`}>
                              <Row className={`${styles.searchLand}`}>
                                <FormItem label='公司名称' hasFeedback={checkEndPriseResult.status && checkEndPriseResult.status !== ''}
                                  help={<span className={`${checkEndPriseResult.status === 'error' ? styles.error : styles.success}`}>{checkEndPriseResult.message || ''}</span>}
                                  validateStatus={checkEndPriseResult.status || null}
                                >
                                  {
                                    getFieldDecorator('name', {
                                      initialValue: euEnterprise.name || '',
                                      rules: [
                                        { required: true, message: '该项必填' }
                                      ]
                                    })(
                                      <AutoComplete placeholder='公司名称'
                                        onSearch={(value) => this.onSearchCompanyList(value)}
                                        onSelect={(value) => this.handleSelectedCompany(value)}
                                      >
                                        {
                                          uncompanyList.length > 0 && uncompanyList.map((comp) => (
                                            <AutoCompleteOption key={comp.name}>{comp.name}</AutoCompleteOption>
                                          ))
                                        }
                                      </AutoComplete>
                                    )
                                  }
                                </FormItem>
                              </Row>
                            </Row>
                          </FormItem>

                          {
                            type === 1
                              ? <FormItem style={{ marginBottom: '0px', display: 'inline-block', width: '110px', marginLeft: '10px' }}>
                                <FormItem>
                                  <Button type='primary' style={{ width: '110px', padding: '0px' }}
                                    onClick={() => this.handleCheckExistsName('name', 'EU_ENTERPRISE_NAME')}>
                                    校验是否存在
                                  </Button>
                                </FormItem>
                              </FormItem>
                              : null
                          }
                        </FormItem>
                      </Col>

                      <Col span={10} className={`${styles.formitemRow}`} style={{ minHeight: '65px' }}>
                        <FormItem label='法人姓名'>
                          {
                            getFieldDecorator('accountName', {
                              initialValue: euEnterprise && euEnterprise.accountName ? euEnterprise.accountName : '',
                              rules: [
                                { required: false, message: '该项必填' }
                              ]
                            })(
                              <Input placeholder='基本存款账户法定代表人(单位负责人)' autoComplete='off' />
                            )
                          }
                        </FormItem>
                      </Col>
                      <Col span={10} className={`${styles.formitemRow}`} style={{ minHeight: '65px' }}>
                        <FormItem label='地区'>
                          {
                            getFieldDecorator('citys', {
                              initialValue: this.renderCascaderDefaultValue(),
                              rules: [
                                { required: false, message: '该项必填' }
                              ]
                            })(
                              <Cascader placeholder='请选择省市区'
                                fieldNames={{ value: 'code', label: 'name', children: 'childList' }}
                                options={cityOptions}
                              />
                            )
                          }
                        </FormItem>
                      </Col>

                      <Col span={10} className={`${styles.formitemRow}`} style={{ minHeight: '65px' }}>
                        <FormItem label='统一社会信用代码'>
                          {
                            getFieldDecorator('taxId', {
                              initialValue: euEnterprise && euEnterprise.taxId ? euEnterprise.taxId : '',
                              rules: [
                                { required: true, message: '该项必填' }
                              ]
                            })(
                              <Input placeholder='15位营业执照注册号或18位统一社会信用代码' />
                            )
                          }
                        </FormItem>
                      </Col>

                      <Col span={10} className={`${styles.formitemRow}`} style={{ minHeight: '65px' }}>
                        <FormItem label='注册地址'>
                          {
                            getFieldDecorator('address', {
                              initialValue: euEnterprise && euEnterprise.address ? euEnterprise.address : '',
                              rules: [
                                { required: true, message: '该项必填' }
                              ]
                            })(
                              <Input placeholder='公司注册详细地址' autoComplete='off' />
                            )
                          }
                        </FormItem>
                      </Col>

                      <Col span={10} className={`${styles.formitemRow}`} style={{ minHeight: '65px' }}>
                        <FormItem label='开户银行'>
                          {
                            getFieldDecorator('bank', {
                              initialValue: euEnterprise && euEnterprise.bank ? euEnterprise.bank : '',
                              rules: [
                                { required: false, message: '该项必填' }
                              ]
                            })(
                              <Input placeholder='基本存款账户开户银行' autoComplete='off' />
                            )
                          }
                        </FormItem>
                      </Col>

                      <Col span={10} className={`${styles.formitemRow}`} style={{ minHeight: '65px' }}>
                        <FormItem label='开票电话'>
                          {
                            getFieldDecorator('telephone', {
                              initialValue: euEnterprise && euEnterprise.telephone ? euEnterprise.telephone : '',
                              rules: [
                                { required: false, message: '该项必填' }
                              ]
                            })(
                              <Input placeholder='请输入开票电话' autoComplete='off' />
                            )
                          }
                        </FormItem>
                      </Col>

                      <Col span={10} className={`${styles.formitemRow}`} style={{ minHeight: '65px' }}>
                        <FormItem label='银行账号'>
                          {
                            getFieldDecorator('bankAccount', {
                              initialValue: euEnterprise && euEnterprise.bankAccount ? euEnterprise.bankAccount : '',
                              rules: [
                                { required: false, message: '该项必填' }
                              ]
                            })(
                              <Input placeholder='基本存款账户账号' autoComplete='off' />
                            )
                          }
                        </FormItem>
                      </Col>

                      <Col span={10} className={`${styles.formitemRow}`} style={{ minHeight: '65px' }}>
                        <FormItem label='银行联行号'>
                          {
                            getFieldDecorator('bankNo', {
                              initialValue: euEnterprise && euEnterprise.bankNo ? euEnterprise.bankNo : ''
                            })(
                              <Input placeholder='基本银行联行号' autoComplete='off' />
                            )
                          }
                        </FormItem>
                      </Col>

                      <Col span={10} style={{ minHeight: '65px' }}>
                        <FormItem label='营业执照电子版' style={{ display: 'inline-block', width: 'calc(100% - 50px)' }}>
                          {
                            licenseFile !== undefined && licenseFile !== ''
                              ? <div className={styles.thumbWrap}>
                                <div className={styles.thumbInfo}>
                                  <img src={licenseFile} />

                                  <div className={styles.prewModal}>
                                    <a href={licenseFile} target='_blank'>
                                      <Icon type='search' style={{ fontSize: '20px' }} />
                                    </a>

                                    <Icon type='delete' style={{ fontSize: '20px' }}
                                      onClick={() => this.removeUploadLicense()}
                                    />

                                  </div>
                                </div>
                              </div>
                              : <Upload name='file'
                                listType='picture-card'
                                accept='image/png, image/jpg, image/jpeg'
                                showUploadList={false}
                                action={uploadOrdinaryImageUrl}
                                headers={{ Token: getStore('token') }}
                                beforeUpload={this.beforeUploadLicense}
                                onChange={(info) => this.onChangeBusinessLicenseImg(info)}
                                onRemove={this.removeUploadLicense}
                              >
                                <div>
                                  <Icon type='plus' />
                                  <div>点击上传</div>
                                </div>
                              </Upload>
                          }

                        </FormItem>
                      </Col>
                    </Row>
                  </Row>
              }
            </Form>
          </Row>

          <Row style={{ paddingTop: '35px', textAlign: 'center' }}>
            <Button type='primary' style={{ width: '100px' }}
              onClick={() => this.handleSubmit()}
            >保存</Button>
          </Row>
        </Card>
      </>
    )
  }
}

const EndUser = Form.create()(EnduerForm)

export default EndUser
