import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Table } from 'antd'
import moment from 'moment'

import CreditbillModal from './creditbill-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class PlatbillList extends Component {
  static propTypes = {
    detailInfo: PropTypes.object
  }
  state = {
    billdetailModal: false,
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    rowDetail: {}
  }
  columns = [
    {
      title: '账单',
      key: 'creditBill-0',
      dataIndex: 'name'
    },
    {
      title: '账单金额',
      key: 'creditBill-1',
      dataIndex: 'amount',
      render: (text, record, index) => (
        <>&yen;{(+record.amount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '到期日期',
      key: 'creditBill-2',
      render: (text, record, index) => (
        <div>
          {
            +record.dueDate > 0
              ? <span>{moment(record.dueDate).format('YYYY-MM-DD')}</span>
              : ''
          }

        </div>
      )
    },
    {
      title: '已结款金额',
      key: 'creditBill-3',
      dataIndex: 'repayAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.repayAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '未结款金额',
      key: 'creditBill-4',
      dataIndex: 'overdueAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.overdueAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '操作',
      key: 'creditBill-5',
      render: (text, record, index) => (
        <div>
          <a onClick={() => this.showBillDetailModal(record)}>查看详情</a>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getPlatCreditBill()
  }

  // 获取云平台信用账单列表
  getPlatCreditBill = () => {
    const { pages } = this.state
    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getPlatCreditBill(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取账单明细
  getPlatCreditFlow = (params) => {
    return Api.getPlatCreditFlow(params)
  }

  // 点击查看账单明细
  showBillDetailModal = (record) => {
    this.setState({
      billdetailModal: true,
      rowDetail: record || {}
    })
  }

  // 关闭详细
  closeModal = () => {
    this.setState({
      billdetailModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPlatCreditBill()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPlatCreditBill()
    })
  }

  render () {
    const { detailInfo } = this.props
    const { tableData = [], pages, billdetailModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Row>
          <Table rowKey='id'
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Row>

        {
          billdetailModal
            ? <CreditbillModal
              type={99}
              detailInfo={{ ...detailInfo, dueMonth: rowDetail.dueMonth }}
              getDetail={(params) => this.getPlatCreditFlow(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </div>
    )
  }
}
