
let Socket = ''
let setIntervalWesocketPush = null
let connectCount = 0 // WS连接计数

/**
 * 建立websocket连接
 * @param {string} url ws地址
*/
const createSocket = (url) => {
  Socket && Socket.close()
  if (!Socket) {
    Socket = new WebSocket(url)
    connectCount = connectCount + 1
    Socket.onopen = () => {
      console.log('建立socket链接')
      // sendPing()
    }

    Socket.onerror = () => {
      console.log(`onerrorWS: 建立websocket连接失败，准备第${connectCount}次重连......`)
      reconnectWS(url)
    }

    Socket.onclose = (e) => {
      console.log('websocket close')
      Socket = null
    }

    Socket.onmessage = (e) => {
      if (e && e.data) {
        window.dispatchEvent(new CustomEvent('onmessageWS', {
          detail: {
            data: e.data
          }
        }))
      }
    }
  }
}

/* 连接失败重连 */
const reconnectWS = (url) => {
  Socket.close()
  clearInterval(setIntervalWesocketPush)

  Socket = null

  if (+connectCount === 3) {
    return false
  }

  setTimeout(() => {
    createSocket(url)
  }, 10000)
}

/* 断开websocket连接 */
const oncloseWS = () => {
  Socket.close()
  clearInterval(setIntervalWesocketPush)
  Socket = null
}

/** 发送心跳
 * @param {number} time 心跳间隔毫秒 默认30000
 * @param {string} ping 心跳名称 默认字符串ping
 */
const sendPing = (time = 5000, ping = 'ping') => {
  if (Socket) {
    clearInterval(setIntervalWesocketPush)
    Socket.send(ping)
    setIntervalWesocketPush = setInterval(() => {
      console.log(ping)
      Socket.send(ping)
    }, time)
  }
}

export {
  Socket,
  createSocket,
  reconnectWS,
  oncloseWS,
  sendPing
}
