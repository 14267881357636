/**
 * 订单模块
 */
import EuOrder from '@/pages/order/euorder/index' // eu订单列表
import SaledOrder from '@/pages/order/saledOrder/index' // 售后订单列表
import ManualOrder from '@/pages/order/manual/index' // 手工订单
import QuickOrder from '@/pages/order/quickOrder/index' // 快速下单
import Cart from '@/pages/order/cart/index' // 购物车
import OrderSubmit from '@/pages/order/orderSubmit' // 订单预览&结算
import OrderPay from '@/pages/order/orderPay/index' // 订单支付
import OrderTransferDetails from '@/pages/order/orderTransferDetails/index'
import OrderSuccess from '@/pages/order/order-success/index' // 手工订单提交成功
import OutsideOrder from '@/pages/order/outside-order/index' // 外部订单

export default [
  {
    path: '/admin/euOrder',
    name: 'EU订单列表',
    component: EuOrder
  },
  {
    path: '/admin/saledOrder',
    name: '售后管理',
    component: SaledOrder
  },
  {
    path: '/admin/manualOrder',
    name: '手工订单管理',
    component: ManualOrder
  },
  {
    path: '/admin/quickOrder',
    name: '快速下单',
    component: QuickOrder
  },
  {
    path: '/admin/cart',
    name: '我的购物车',
    component: Cart
  },
  {
    path: '/admin/orderSubmit',
    name: '订单结算',
    component: OrderSubmit
  },
  {
    path: '/admin/orderPay',
    name: '支付订单',
    component: OrderPay
  },
  {
    path: '/admin/orderTransferDetails',
    name: '下单成功',
    component: OrderTransferDetails
  },
  {
    path: '/admin/orderSuccess',
    name: '下单成功',
    component: OrderSuccess
  },
  {
    path: '/admin/outsideOrder',
    name: '外部订单',
    component: OutsideOrder
  }
]
