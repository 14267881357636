import React, { Component } from 'react'

import styles from './index.module.scss'

export default class EmptyLogistics extends Component {
  render () {
    return (
      <div>
        <span className={`${styles.errorTitle}`}>
          抱歉,此单号暂无轨迹
        </span>
        <ul className={`${styles.errorBox} clearfix`}>
          <li className={`${styles.showError} ${styles.errorIcon1}`}>
            运单号正确请稍后重试，可能是网络问题。<br />或点击右上角快递公司官网地址进行查询。
          </li>
          <li className={`${styles.showError} ${styles.errorIcon2}`}>
            可能是运单号错误，<br />请重新输入
          </li>
        </ul>
      </div>
    )
  }
}
