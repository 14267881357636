/**
 * 商城模块路由
 */
import MallBase from '@/pages/mall/mall-base/index' // 商城配置信息
import FreightTemp from '@/pages/mall/freight-temp/index' // 运费模板
import PaymethodList from '@/pages/mall/paymethod-list/index'
import MallRenovating from '@/pages/mall/renovation'
import HomePoster from '@/pages/mall/home-poster/index' // 商城广告管理
import PosterDetail from '@/pages/mall/poster-detail/index' // 商城广告模板
import Announcement from '@/pages/mall/announcementManage/index' // 公告管理
import AnnounceEdit from '@/pages/mall/announcementEdit/index' // 公告编辑

export default [
  {
    path: '/admin/mallConfig',
    name: '商城配置信息',
    component: MallBase
  },
  {
    path: '/admin/homePoster',
    name: '商城广告设置',
    component: HomePoster
  },
  {
    path: '/admin/posterDetail',
    name: '商城首页模板',
    component: PosterDetail
  },
  {
    path: '/admin/mallRenovating',
    name: '首页装修',
    component: MallRenovating
  },
  {
    path: '/admin/payConfig',
    name: '支付方式配置',
    component: PaymethodList
  },
  {
    path: '/admin/freightTemp',
    name: '运费模板管理',
    component: FreightTemp
  },
  {
    path: '/admin/announcement',
    name: '公告管理',
    component: Announcement
  },
  {
    path: '/admin/announcementEdit',
    name: '公告编辑',
    component: AnnounceEdit
  }
]
