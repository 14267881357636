import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class EditTable extends Component {
  static propTypes = {
    onSelect: PropTypes.func,
    selectedRowKeys: PropTypes.array
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    selectedRowKeys: [] // 选中的员工ID集合
  }
  columns = [
    {
      title: '序号',
      key: 'editTable-0',
      width: 40,
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '成员账号',
      key: 'editTable-1',
      dataIndex: 'account'
    },
    {
      title: '员工姓名',
      key: 'editTable-2',
      dataIndex: 'realName'
    },
    {
      title: '联系方式',
      key: 'editTable-3',
      dataIndex: 'phone'
    },
    {
      title: '账号状态',
      key: 'editTable-4',
      render: (text, record, index) => (
        <div>
          {
            record.status === 'ENABLED'
              ? '启用'
              : '禁用'
          }
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getPersonList()
  }

  // 获取员工列表
  getPersonList = () => {
    const { pages } = this.state
    Api.getPersonList({ pageNo: pages.pageNo, pageSize: pages.pageSize }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  onChangeSelectedRowKeys = (keys) => {
    this.props.onSelect(keys)
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPersonList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPersonList()
    })
  }

  render () {
    const { selectedRowKeys = [] } = this.props
    const { pages, tableData } = this.state

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys,
      onChange: this.onChangeSelectedRowKeys
    }

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Table rowKey='supplierUserId'
          dataSource={tableData}
          columns={this.columns}
          rowSelection={rowSelection}
          pagination={pagination}
        />
      </div>
    )
  }
}
