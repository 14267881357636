import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Modal, message } from 'antd'
import $lodash from 'lodash'

import QuickSearch from './quickSearch'
import Renovatset from './renvoatSet'

import Api from '@/common/api/index'

import styles from './renovation.module.scss'

export default class Renovation extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    rowDetail: {},
    coverList: [], // 封面
    elementList: [], // 产品
    activekey: null // 当前激活的WidgetItem

  }

  componentDidMount () {
    const { state = {} } = this.props.history.location

    this.setState({
      rowDetail: state.record
    }, () => {
      this.getMallHomeInfo()
    })
  }

  // 获取专区内容
  getMallHomeInfo = () => {
    let { rowDetail = {} } = this.state
    Api.getMallHomeInfo({ pageContainerId: rowDetail.id }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _list = data.elementList || []
        let _coverList = [
          { id: 0, imageUrl: '', linkUrl: '', type: 99 }
        ]

        let obj = _list.find(item => {
          return +item.type === 99
        })

        if (obj) {
          _coverList[0] = obj
        }

        this.setState({
          info: data || {},
          elementList: $lodash.filter(_list, (record) => { return +record.type !== 99 }),
          coverList: _coverList || []
        })
      } else {
        message.error(res.message)
      }
    })
  }

  // 更新专区内容
  saveMallHomeEdit = (params) => {
    Api.saveMallHomeEdit(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        Modal.success({
          title: '操作成功',
          content: '',
          onOk: () => {
            this.handleGoBack()
          }
        })
      }
    })
  }

  // 快速填充
  handleQuickFill = (rows) => {
    let { elementList = [] } = this.state
    let _elementList = $lodash.concat(elementList, rows)

    this.setState({
      elementList: _elementList.length > 8 ? _elementList.slice(0, 8) : _elementList
    })
  }

  // 点击新增WidgetItem
  handleCreateItem = () => {
    let { elementList = [] } = this.state
    if (elementList.length === 8) {
      return message.warning(`最多可添加 8 项数据！`)
    }

    elementList.push({ name: '', linkUrl: '', imageUrl: '' })

    this.setState({
      elementList: elementList
    })
  }

  // 点击清除
  handleDeleteItem = (i) => {
    let { elementList = [] } = this.state

    elementList.splice(i, 1)

    this.setState({
      elementList
    })
  }

  // 图片上传
  changeUploadImage = (url, i, type) => {
    let { elementList = [], coverList = [] } = this.state

    if (type === 99) {
      coverList[i].imageUrl = url
    } else {
      elementList[i].imageUrl = url
    }

    this.setState({
      elementList,
      coverList
    })
  }

  // 前端删除图片
  handleRemoveImage = (i, type) => {
    let { elementList = [], coverList = [] } = this.state

    if (type === 99) {
      coverList[i].imageUrl = ''
    } else {
      elementList[i].imageUrl = ''
    }

    this.setState({
      elementList,
      coverList
    })
  }

  // 返回
  handleGoBack = () => {
    this.props.history.goBack()
  }

  render () {
    const { rowDetail = {}, elementList = [], coverList = [] } = this.state

    return (
      <div className={`${styles.renovationWrap}`}>
        <Row gutter={12}>
          <Col span={10}>
            <Row>
              <QuickSearch len={elementList.length}
                onConfirm={(rows) => this.handleQuickFill(rows)}
              />
            </Row>

          </Col>

          <Col span={14}>
            <Card bodyStyle={{ padding: '12px' }} title={rowDetail.name || ''}>
              <Row>
                <Renovatset
                  detailInfo={rowDetail}
                  list={elementList}
                  coverList={coverList}
                  onCreate={() => this.handleCreateItem()}
                  onDelete={(i) => this.handleDeleteItem(i)}
                  onUpload={(url, i, type) => this.changeUploadImage(url, i, type)}
                  onRemove={(i, type) => this.handleRemoveImage(i, type)}
                  onConfirm={(params) => this.saveMallHomeEdit(params)}
                  onCancel={() => this.handleGoBack()}
                />
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
