import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Button, Tabs } from 'antd'

import moment from 'moment'

import EuaccountFlow from '../component/euaccount-flow'
import EubalanceFlow from '../component/eubalance-flow'
import Creditbill from '../component/creditbill-flow'

import styles from './credit.module.scss'

const { TabPane } = Tabs

const creditTabs = [
  { key: 1, name: '汇总明细' },
  { key: 2, name: '余额明细' },
  { key: 3, name: '信用账单' }
]

export default class index extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    detailInfo: {},
    curkey: -1
  }

  componentDidMount () {
    const { state } = this.props.history.location
    if (state && state.detailInfo) {
      this.setState({
        detailInfo: state.detailInfo,
        curkey: 1
      })
    }
  }

  handleChangeTab = (key) => {
    this.setState({
      curkey: key
    })
  }

  // 点击返回
  handleJumpToLink = () => {
    this.props.history.push({ pathname: '/admin/eubalanceList' })
  }

  render () {
    const { detailInfo, curkey = 1 } = this.state

    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Button icon='left'
            onClick={this.handleJumpToLink}
          >
            返回列表
          </Button>
        </Row>
        <Row>
          <Card>
            <Row style={{ marginBottom: '10px' }}>
              <div className={`${styles.inline} ${styles.userName}`}>
                <span>{detailInfo.endUserName}</span>
              </div>
              <div className={`${styles.inline}`}>
                {detailInfo.enterpriseName}
              </div>
            </Row>
            <Row gutter={10} style={{ marginBottom: '10px' }}>
              <Col span={6}>
                <div>当前余额</div>
                <div className={`${styles.redBold}`}>
                  {(+detailInfo.totalBalance * 100 / 100).toFixed(2)}
                </div>
              </Col>

              <Col span={6}>
                <div>使用额度/信用额度</div>
                <div className={`${styles.redBold}`}>
                  {+detailInfo.usedAmount}/{+detailInfo.maxAmount}
                </div>
              </Col>
              <Col span={6}>
                <div>最近到期日</div>
                <div className={`${styles.redBold}`}>
                  {
                    detailInfo.lastPeriodTime
                      ? moment(detailInfo.lastPeriodTime).format('YYYY-MM-DD')
                      : '----'
                  }
                </div>
              </Col>
              <Col span={6}>
                <div>账期</div>
                <div className={`${styles.redBold}`}>{+detailInfo.period}天</div>
              </Col>
            </Row>
            <Row>
              <Tabs onChange={this.handleChangeTab}>
                {
                  creditTabs.map((item) => (
                    <TabPane tab={item.name} key={item.key} />
                  ))
                }
              </Tabs>
              <div>
                {
                  +curkey === 1
                    ? <EuaccountFlow
                      detailInfo={detailInfo}
                    />
                    : null
                }

                {
                  +curkey === 2
                    ? <EubalanceFlow
                      detailInfo={detailInfo}
                    />
                    : null
                }

                {
                  +curkey === 3
                    ? <Creditbill
                      detailInfo={detailInfo}
                    />
                    : null
                }

              </div>
            </Row>
            <Row />
          </Card>
        </Row>
      </div>
    )
  }
}
