export const saveEuKeyMap = {
  name: 'receiver',
  mobile: 'phoneNumber',
  status: 'category',
  province: 'province',
  city: 'city',
  county: 'county',
  address: 'address',
  addressRegion: 'addressRegion',
  endUserId: 'endUserId',
  id: 'id'
}
