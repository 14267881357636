import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, InputNumber, message, Button } from 'antd'

import Api from '@/common/api/index'

const FormItem = Form.Item

class InvoiceForm extends Component {
  static propTypes = {
    form: PropTypes.object
  }
  state = {
    minAmount: 0
  }

  componentDidMount () {
    this.getInvoiceMinAmount()
  }

  // 获取开票最小额度
  getInvoiceMinAmount = () => {
    Api.getInvoiceMinAmount({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          minAmount: data || 0
        })
      }
    })
  }

  // 保存规则设置
  saveInvoiceRule = (params) => {
    Api.saveInvoiceRule(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
      }
    })
  }

  // 点击提交
  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.saveInvoiceRule(values)
      }
    })
  }

  render () {
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 10 }
    }

    const tailFormItemLayout = {
      wrapperCol: { span: 14, offset: 4 }
    }

    const { getFieldDecorator } = this.props.form
    const { minAmount = 0 } = this.state

    return (
      <Card bodyStyle={{ padding: '15px' }}>
        <Form>
          <FormItem label='开票最小金额' {...formItemLayout}>
            {
              getFieldDecorator('minAmount', {
                initialValue: minAmount,
                rules: [
                  { required: true, message: '该项必填' }
                ]
              })(
                <InputNumber placeholder='请输入...' autoComplete='off'
                  style={{ width: '200px' }}
                />
              )
            }
          </FormItem>
          <FormItem {...tailFormItemLayout}>
            <Button type='primary' onClick={this.handleSubmit}>
                保存
            </Button>
          </FormItem>
        </Form>
      </Card>
    )
  }
}

const Invoice = Form.create()(InvoiceForm)

export default Invoice
