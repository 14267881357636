import Ajax from '../utils/ajax'

export default {
  // 获取商城配置信息
  getMallConfig (params) {
    return Ajax.get('/api/mall/manage/get/mallInfoConfig', { params })
  },

  // 获取商户基本信息
  getMerchantBaseInfo (params) {
    return Ajax.get('/api/mall/manage/get/platformMerchantInfo', { params })
  },

  // 保存商城配置
  saveMallConfig (params) {
    return Ajax.post('/api/mall/manage/save/mallInfoConfig', params)
  },

  // 获取运费模板列表
  getFreightList (params) {
    return Ajax.post('/api/mall/manage/list/page/freight/template', params)
  },

  // 更新运费模板状态
  updateFreight (params) {
    return Ajax.post('/api/mall/manage/batch/update/template/byId', params)
  },

  // 保存运费模板
  saveFreight (params) {
    return Ajax.post('/api/mall/manage/insert/template/info', params)
  },

  // 修改运费模板
  saveModifyFreight (params) {
    return Ajax.post('/api/mall/manage/update/template/info/byId', params)
  },

  // 获取运费模板详情
  getFreightDetail (params) {
    return Ajax.post('/api/mall/manage/get/freight/template/info', params)
  },

  // 获取支付配置列表
  getPaymethodList (params) {
    return Ajax.post('/api/mall/manage/list/page/payment/supplier', params)
  },

  // 更新支付方式启用状态
  updatePaymethodStatus (params) {
    return Ajax.post('/api/mall/manage/addOrUpdate/payment/supplier/status', params)
  },

  // 获取首页配置容器
  getMallHomePageContainer (params) {
    return Ajax.get('/api/mall/manage/get/homePageContainer', { params })
  },

  // 获取商城首页配置详情
  getMallHomeInfo (params) {
    return Ajax.post('/api/mall/manage/get/homePageContainer/info', params)
  },

  // 新增专区
  saveCreateSeriesArea (params) {
    return Ajax.post('/api/mall/manage/add/homePage/series/container', params)
  },

  // 保存专区名称修改
  saveModifySeriesAreaName (params) {
    return Ajax.post('/api/mall/manage/update/homePage/series/container', params)
  },

  // 编辑保存
  saveMallHomeEdit (params) {
    return Ajax.post('/api/mall/manage/save/pageElement', params)
  },

  // 删除系列专区
  removeSeriesArea (params) {
    return Ajax.post('/api/mall/manage/delete/homePage/series/container', params)
  },

  // 获取公司介绍
  getComponyIntro (params) {
    return Ajax.post('/api/companyIntroduce/supplier/media/list', params)
  },

  // 保存公司介绍
  saveComponyIntro (params) {
    return Ajax.post('/api/companyIntroduce/supplier/media/mediaSave', params)
  },

  // 获取帮助中心菜单树
  getMallHelpMenus (params) {
    return Ajax.get('/api/mall/manage/list/menuNodeTree', { params })
  },

  // 新增/修改 帮助中心菜单
  saveMallHelpMenu (params) {
    return Ajax.post('/api/mall/manage/save/menu', params)
  },

  // 删除帮助中心菜单
  deleteMallHelpMenu (params) {
    return Ajax.post('/api/mall/manage/delete/menu', params)
  },

  // 根据菜单获取内容
  getContainerByMenuId (params) {
    return Ajax.post('/api/mall/manage/get/content', params)
  },

  // 保存内容编辑
  saveHelpContainer (params) {
    return Ajax.post('/api/mall/manage/save/content', params)
  },

  // 查询是否开启一键装修
  getHasAutoFitup (params) {
    return Ajax.get('/api/mall/manage/mallConfig/query', { params })
  },

  // 更新是否一键装修状态
  updateAutoFitup (params) {
    return Ajax.post('/api/mall/manage/mallConfig/update', params)
  },

  // 查询是否有初始化商城
  getMallInitialInfo (params) {
    return Ajax.get('/api/mallInitial/hasInitial', { params })
  },

  // 获取banner模板列表
  getMallBannerTempList (params) {
    return Ajax.get('/api/mallInitial/bannerList', { params })
  },

  // 保存初始化装修
  saveMallInitialSet (params) {
    return Ajax.post('/api/mallInitial/initial', params)
  },

  /* 自定义导航装修start */

  // 获取自定义页面列表
  getListByType (params) {
    return Ajax.post('/api/mall/manage/listByType', params)
  },

  // 获取到导航页
  getMallNavList (params) {
    return Ajax.post('/api/mall/manage/get/andInit/pageContainer', params)
  },

  // 保存新增楼层
  saveAddNavFloor (params) {
    return Ajax.post('/api/mall/manage/add/container', params)
  },

  // 保存修改楼层名称
  saveEditNavFloorName (params) {
    return Ajax.post('/api/mall/manage/update/container', params)
  },

  // 删除楼层
  deleteNavFloor (params) {
    return Ajax.post('/api/mall/manage/delete/container', params)
  },

  // 更新楼层内容
  updateNavFloorContainer (params) {
    return Ajax.post('/api/mall/manage/addOrUpdate/pageElement', params)
  },

  // 获取楼层内容
  getNavFloorContainer (params) {
    return Ajax.post('/api/mall/manage/get/containerInfo', params)
  },

  /* 自定义导航装修end */
  // 获取商户是否展示真实价格设置
  getShowRealPriceSeted (params) {
    return Ajax.post('/api/merchant/config/get/switch/one', params)
  },

  // 保存商户是否展示真实价格设置
  saveShowRealPriceSetting (params) {
    return Ajax.post('/api/merchant/config/save/switch/one', params)
  }
}
