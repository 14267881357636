import Parse, {
  ParseAddress,
  Utils
} from './parse'

Parse.Utils = Utils
Parse.ParseAddress = ParseAddress

export default {
  Parse,
  Utils
}
