import React, { Component } from 'react'
import { Card, Table, Button } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class ContractList extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'demander',
        formType: 'Input',
        itemParams: {
          label: '终端客户'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '创建时间'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'contractList-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '合同名称',
      key: 'contractList-1',
      render: (text, record, index) => (
        <>{`${record.name}(${record.number})`}</>
      )
    },
    {
      title: '订单编号',
      key: 'contractList-3',
      dataIndex: 'orderId'
    },
    {
      title: '终端客户',
      key: 'contractList-2',
      dataIndex: 'demander'
    },
    {
      title: '创建时间',
      key: 'contractList-4',
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----'}
        </>
      )
    },
    {
      title: '操作',
      key: 'contractList-6',
      render: (text, record, index) => (
        <div>
          {
            record.fileKey && record.fileKey !== ''
              ? <Button type='primary' size='small'
                onClick={() => this.handleDownLoadContract(record)}
              >
                下载
              </Button>
              : null
          }

        </div>
      )
    }
  ]

  componentDidMount () {
    this.getContractList()
  }

  // 获取列表
  getContractList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getContractList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击下载合同
  handleDownLoadContract = (record) => {
    const w = window.open('about:blank')
    w.location.href = `${record.fileKey}`
  }

  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.times) {
      search.beginDate = search.times.length > 0 ? search.times[0].format('YYYY-MM-DD') : ''
      search.endDate = search.times.length > 0 ? search.times[1].format('YYYY-MM-DD') : ''

      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => {
      this.getContractList()
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getContractList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getContractList()
    })
  }

  render () {
    const { tableData, pages, searchData = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共${total}` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '15px' }}>
          <Table rowKey='id'
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}
