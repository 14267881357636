import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, InputNumber } from 'antd'

const FormItem = Form.Item

class PrepayForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {}

  componentDidMount () {

  }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {} } = this.props

        values.endUserId = detailInfo.euId
        values.prepaymentRatio = +values.prepay / 100

        delete values.prepay
        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { detailInfo = {}, type = 1 } = this.props

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 }
    }

    return (
      <>
        <Modal title='期货预付款设置'
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Form>
            <FormItem label='期货预付比' {...formItemLayout}>
              {
                getFieldDecorator('prepay', {
                  initialValue: type === 2 ? +detailInfo.endUser.prepaymentRatio * 100 / 100 : '',
                  rules: [
                    { required: true, message: '请填写期货预付比' }
                  ]
                })(
                  <InputNumber
                    min={0}
                    max={100}
                    formatter={value => `${value}%`}
                    parser={value => value.replace('%', '')}
                    placeholder='请输入...' autoComplete='off'
                  />
                )
              }

            </FormItem>
          </Form>
        </Modal>
      </>
    )
  }
}

const PrepayModal = Form.create()(PrepayForm)

export default PrepayModal
