/**
 * 云供应模块
*/
import SupplyBrand from '@/pages/cloud-supply/supply-brand/index' // 云供应申请
import SupplyProd from '@/pages/cloud-supply/supply-prod/index' // 云供应产品清单
import SupplyOrder from '@/pages/cloud-supply/supply-order/index' // 云供应订单列表

import PlatCredit from '@/pages/finance/iia-credit/index' // 云平台信用
import PlatcreditDetail from '@/pages/finance/platcredit-detail/index' // 云平台信用流水

export default [
  {
    path: '/admin/supplyCloudApply',
    name: '云供应品牌申请',
    component: SupplyBrand
  },
  {
    path: '/admin/supplyProdList',
    name: '产品云供应设置',
    component: SupplyProd
  },
  {
    path: '/admin/supplyOrderList',
    name: '云供应订单列表',
    component: SupplyOrder
  },
  {
    path: '/admin/platCredit',
    name: '云平台信用',
    component: PlatCredit
  },
  {
    path: '/admin/platcreditDetail',
    name: '云平台信用明细',
    component: PlatcreditDetail
  }
]
