import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, InputNumber } from 'antd'

import consts from '@/common/utils/consts'

const { MAX_NUMBER } = consts

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
}

function DelayForm (props) {
  const { form, detailInfo = {}, onConfirm, onCancel } = props
  const { getFieldDecorator, validateFields } = form

  const handleConfirm = () => {
    validateFields((err, values) => {
      if (!err) {
        values.orderId = detailInfo.orderId
        values.commoditySkuId = detailInfo.commoditySkuId
        values.purchaseOrderId = detailInfo.orderType === 'ORDER_CLOUD_FUTURES' ? detailInfo.outboundOrderNo : null

        onConfirm(values, detailInfo.orderType)
      }
    })
  }

  return (
    <>
      <Modal title='延期交货'
        visible
        onOk={() => handleConfirm()}
        onCancel={() => onCancel()}
      >
        <Form>
          <Form.Item label='延期时间' {...formItemLayout}>
            {
              getFieldDecorator('deliveryPeriodDays', {
                rules: [
                  { required: true, message: '请填写延期时间' }
                ]
              })(
                <InputNumber min={0} max={MAX_NUMBER} />
              )
            }
            <span style={{ marginLeft: '6px' }}>工作日</span>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

DelayForm.propTypes = {
  form: PropTypes.object,
  detailInfo: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

const DelayModal = Form.create()(DelayForm)

export default DelayModal
