import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Switch, Descriptions, Form, Radio } from 'antd'

import PaywordModal from './payword-modal'

import Api from '@/common/api/index'

import styles from './child.module.scss'

class PaymentForm extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    balanceInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    hasPayword: false, // 是否设置支付密码
    useBalance: false, // 是否使用余额
    paywordModal: false, // 支付密码弹窗
    paymethod: 1 // 支付方式 默认1-银行转账
  }

  componentDidMount () {
    this.getHasPayPassword()
  }

  // 查询是否有支付密码
  getHasPayPassword = () => {
    Api.getHasPayPassword({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          hasPayword: data || false
        })
      }
    })
  }

  /* 点击确定
  * 1- 判断是否使用余额，有使用弹出支付密码弹窗
  */

  handleConfirm = () => {
    const { useBalance } = this.state
    const { detailInfo = {} } = this.props

    if (useBalance) {
      this.setState({
        paywordModal: true
      })
    } else {
      this.props.onConfirm(detailInfo, 1)
    }
  }

  // 点击支付密码确定
  confirmPayword = (params) => {
    this.closeModal()

    const { detailInfo = {}, balanceInfo = {} } = this.props
    let shouldPay = +detailInfo.currentPayAmount * 100 // 本次应付

    let query = {
      ...params,
      orderId: detailInfo.orderId,
      amount: +balanceInfo.totalBalance * 100 < shouldPay ? (+balanceInfo.totalBalance * 100 / 100).toFixed(2) : (shouldPay / 100)
    }

    let _type = +balanceInfo.totalBalance * 100 < shouldPay ? 3 : 2
    this.props.onConfirm(query, _type)
  }

  /* 启用余额支付
  *  1. 查询是否设置支付密码
  *     有密码- 需输入支付密码
  *     无密码- 弹出提示框
  */
  handleChangeSwitch = () => {
    let { hasPayword = false, useBalance = false } = this.state

    if (!hasPayword) {
      Modal.warning({
        title: '温馨提示',
        content: (<div className={`${styles.messPop}`}>
          <p>企业账户没有设置支付密码</p>
          <p>请联系管理员前往&nbsp;首页/支付设置&nbsp;设置支付密码</p>
        </div>),
        onOk: () => {}
      })
    } else {
      this.setState({
        useBalance: !useBalance
      })
    }
  }

  // 点击选择付款方式
  onChangePaymethod = (e) => {
    this.setState({
      paymethod: +e.target.value
    })
  }

  // 动态计算实际支付
  calcPayAmount = () => {
    const { detailInfo = {}, balanceInfo = {} } = this.props
    const { useBalance = false } = this.state
    let shouldPay = detailInfo.currentPayAmount // 应支付
    let total = 0 // 实际要支付

    if (+balanceInfo.totalBalance > 0 && useBalance) {
      total = +balanceInfo.totalBalance > shouldPay ? 0 : +shouldPay - (+balanceInfo.totalBalance)
    } else {
      total = shouldPay
    }

    return (total * 100 / 100).toFixed(2)
  }

  // 计算余额抵扣
  calcBalanceDeduction = () => {
    const { detailInfo = {}, balanceInfo = {} } = this.props
    const { useBalance = false } = this.state
    let shouldPay = detailInfo.currentPayAmount // 应支付
    let deduction = 0 // 余额抵扣

    if (+balanceInfo.totalBalance > 0 && useBalance) {
      deduction = +balanceInfo.totalBalance > shouldPay ? shouldPay : +balanceInfo.totalBalance
    }

    return deduction
  }

  closeModal = () => {
    this.setState({
      paywordModal: false
    })
  }

  render () {
    const { detailInfo = {}, balanceInfo = {} } = this.props
    const { useBalance = false, paywordModal = false, paymethod = 1 } = this.state

    return (
      <>
        <Modal title='选择支付方式' width={1000}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Row style={{ marginBottom: '10px' }}>
            <Descriptions>
              <Descriptions.Item label='订单金额'>
                &yen;&nbsp;{(+detailInfo.payableAmount * 100 / 100).toFixed(2)}
              </Descriptions.Item>
              <Descriptions.Item label='已付金额'>
                &yen;&nbsp;{(+detailInfo.paidAmount * 100 / 100).toFixed(2)}
              </Descriptions.Item>
              <Descriptions.Item label='应付金额'>
                &yen;&nbsp;{this.calcPayAmount()}
              </Descriptions.Item>
              <Descriptions.Item label='账户余额'>
                &yen;&nbsp;{(+balanceInfo.totalBalance * 100 / 100).toFixed(2)}
              </Descriptions.Item>
            </Descriptions>
          </Row>
          <Row style={{ marginBottom: '20px' }}>
            <div>余额支付</div>
            <div className={`${styles.inline}`}>
              <Switch
                checked={useBalance}
                onChange={this.handleChangeSwitch}
              />
            </div>
            {
              useBalance
                ? <div className={`${styles.inline}`} style={{ marginLeft: '20px' }}>
                  余额抵扣<em>&yen;&nbsp;{(this.calcBalanceDeduction() * 100 / 100).toFixed(2)}</em>&nbsp;&nbsp;
                  应付<em>&yen;&nbsp;{this.calcPayAmount()}</em>
                </div>
                : null
            }
          </Row>
          <Row>
            <div>支付方式</div>
            <div>
              <Radio.Group
                value={paymethod}
                onChange={this.onChangePaymethod}
              >
                <Radio value={1}>银行转账</Radio>
              </Radio.Group>
            </div>
          </Row>
        </Modal>

        {
          paywordModal
            ? <PaywordModal
              onCancel={this.closeModal}
              onConfirm={(params) => this.confirmPayword(params)}
            />
            : null
        }
      </>
    )
  }
}

const PaymentModal = Form.create()(PaymentForm)

export default PaymentModal
