import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form } from 'antd'

const FormItem = Form.Item

export default class SheetZone extends Component {
  static propTypes = {
    name: PropTypes.string,
    sheetName: PropTypes.string
  }
  state = {}

  componentDidMount () {}

  render () {
    const { name, sheetName } = this.props

    return (
      <div style={{ marginBottom: '24px' }}>
        <Form>
          <Row gutter={24}>
            <Col span={8}>
              <FormItem label='EXCEL名称' style={{ marginBottom: '0px' }}>{ name }</FormItem>
            </Col>
            <Col span={8}>
              <FormItem label='选择列表' style={{ marginBottom: '0px' }}>{ sheetName }</FormItem>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}
