import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Table, message, Button } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import RefundModal from '../component/refund-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const auditMap = [
  { type: 'ALL', label: '全部' },
  { type: 'FINANCE_AUDITING', label: '待退款' },
  { type: 'ALREADY_CONFIRM', label: '已退款' }
]
const { pageSizeOptions } = filter

export default class OrderRefund extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'endUserId',
        formType: 'Select',
        itemParams: {
          label: '客户名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['endUserId', 'showName']
      },
      {
        name: 'afterSaleOrderId',
        formType: 'Input',
        itemParams: {
          label: '服务单号'
        },
        cptParams: {
          placeholder: '请输入...'
        }
      },
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '申请日期'
        },
        cptParams: {

        }
      },
      {
        name: 'afterSaleOrderStatus',
        formType: 'Select',
        itemParams: {
          label: '处理状态'
        },
        cptParams: {

        },
        options: auditMap,
        optionValue: ['type', 'label']
      }
    ],
    appletModal: false,
    saledModal: false,
    visible: false,
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    rowDetail: {},
    detailInfo: {}, // 详细信息
    imgList: [] // 售后详情图片列表
  }

  columns = [
    {
      title: '序号',
      key: 'salendorder-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '服务单号',
      key: 'financeSalend-1',
      dataIndex: 'afterSaleOrderId'
    },
    {
      title: '客户申请时间',
      key: 'financeSalend-2',
      render: (text, record, index) => (
        <div>
          {moment(record.afterSaleApplyTime).format('YYYY-MM-DD HH:mm:ss')}
        </div>
      )
    },
    {
      title: '客户名称',
      key: 'financeSalend-3',
      dataIndex: 'customerName'
    },
    {
      title: '申请客户',
      key: 'financeSalend-4',
      dataIndex: 'realName'
    },
    {
      title: '申请商务',
      key: 'financeSalend-5',
      dataIndex: 'oneLevelAuditName'
    },
    {
      title: '退款金额',
      key: 'financeSalend-6',
      dataIndex: 'backAmount',
      render: (text, record, index) => (
        <>&yen;{ ($lodash.round(+record.backAmount, 2) * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '商务处理时间',
      key: 'financeSalend-8',
      dataIndex: 'oneLevelAuditTime',
      render: (text, record, index) => (
        <>{ record.oneLevelAuditTime ? moment(record.oneLevelAuditTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },

    {
      title: '处理状态',
      key: 'financeSalend-7',
      render: (text, record, index) => (
        <div>{ this.renderSaledOrderStaus(record) }</div>
      )
    },
    {
      title: '操作',
      key: 'financeSalend-9',
      width: 200,
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small' onClick={() => this.handleShowDetailModal(record)}>
            查看详情
          </Button>

        </div>
      )
    }
  ]

  componentDidMount () {
    this.getGuesteuUserLike()
    this.getFinanceSaledOrderList()
  }

  // 获取售后订单列表
  getFinanceSaledOrderList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getFinanceSaledOrderList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 确认退款
  confirmRefund = (params) => {
    Api.confirmRefund(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getFinanceSaledOrderList()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 搜索客户信息
  getGuesteuUserLike = (val = '') => {
    let params = {
      pageNo: 1,
      pageSize: 999,
      searchName: val
    }
    Api.getGuesteuUserLike(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state
        searchData[0].options = data.list || []

        this.setState({
          searchData
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search) => {
    const { pages, query } = this.state

    if (search.orderTime) {
      search.beginDate = search.orderTime.length > 0 ? search.orderTime[0].format('YYYY-MM-DD') : undefined
      search.endDate = search.orderTime.length > 0 ? search.orderTime[1].format('YYYY-MM-DD') : undefined

      delete search.orderTime
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: {
        ...query,
        ...search
      }
    }, () => {
      this.getFinanceSaledOrderList()
    })
  }

  // 点击查看订单明细
  handleShowDetailModal = (record = {}) => {
    this.setState({
      rowDetail: record,
      saledModal: true
    })
  }

  // 渲染财务处理状态
  renderSaledOrderStaus = (record) => {
    const obj = auditMap.find(item => { return item.type === record.afterSaleOrderStatus })
    const label = obj !== undefined ? obj.label : ''

    return label
  }

  // 点击预览
  handleJumpOpenPreview = (url) => {
    window.open(url, '_blank')
  }

  closeModal = () => {
    this.setState({
      saledModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getFinanceSaledOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getFinanceSaledOrderList()
    })
  }

  render () {
    const { tableData, pages, searchData, saledModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>
        <Card bodyStyle={{ padding: '15px' }}>
          <Table rowKey='afterSaleOrderId'
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
          />
        </Card>

        {
          saledModal
            ? <RefundModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.confirmRefund(params)}
            />
            : null
        }
      </>
    )
  }
}
