import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Button, message, Modal } from 'antd'
import $lodash from 'lodash'

import { setOrderBatchSkuList, setManualOrderAmounts } from '@/reducers/action'
import Api from '@/common/api/index'

import AddressList from './addressList/index'
import EndLand from './endLand/index'
import SearchLand from './batchSearch/index'
import ProductTable from './productTable/index'
import InvoiceLand from './invoiceLand/index'

class ManualOrder extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func,
    list: PropTypes.array,
    manualOrderAmounts: PropTypes.object
  }
  state = {
    customerList: [], // 客户列表
    selectedEndInfo: {}, // 选择的客户信息

    addressList: [], // EU用户收货地址列表
    selectedAddressRow: {} // 选择的收货地址
  }

  componentDidMount () {
    this.props.dispatch(setOrderBatchSkuList({ batchSkuList: [] }))
    this.props.dispatch(setManualOrderAmounts({ manualOrderAmounts: {} }))
  }

  onRef = (ref) => {
    this.endLand = ref
  }

  // 批量查询SKU信息
  getBatchSearchSku = (params) => {
    Api.getBatchSearchSku(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { list = [] } = this.props
        let _list = data || []
        if (_list.length > 0) {
          _list.map((item, index) => {
            if (item.quickOrderSearchResult === 'SUCCESS') {
              let n = $lodash.findIndex(list, (record) => { return record.commoditySkuId === item.commoditySkuId && record.skuGoodsType === item.skuGoodsType })

              if (n > -1) { // 合并同一个商品相同价格数量
                let { supplyInfoList = [] } = list[n]
                let len = supplyInfoList.length

                if (supplyInfoList[len - 1]['checked']) {
                  supplyInfoList[len - 1]['quantity'] += item['quantity']
                } else {
                  supplyInfoList[len - 1]['checked'] = true
                }
                list[n]['supplyInfoList'] = supplyInfoList
              } else {
                item['supplyInfoList'] = this.setLoginPriceCellData(item)
                list.push(item)
              }
            } else {
              list.push(item)
            }
          })
          list.map((item, n) => { item['rowKey'] = `${item.commoditySkuId || 0}-${item.itemNumberModel}-${n}` })
        }

        const _manualOrderAmounts = this.calcSelectedAmounts([...list])

        this.props.dispatch(setOrderBatchSkuList({ batchSkuList: [...list] }))
        this.props.dispatch(setManualOrderAmounts({ manualOrderAmounts: { ..._manualOrderAmounts } }))
      }
    })
  }

  // 计算相关金额
  calcSelectedAmounts = (list) => {
    /* 过滤掉错误的数据 */
    const newdatas = $lodash.filter(list, (item) => { return item.skuGoodsType })
    let _totalAmount = 0 // 商品总金额
    let _spotAmount = 0 // 现货总金额
    let _futuresFrontMoney = 0 // 期货订金

    if (newdatas.length > 0) {
      for (let record of newdatas) {
        let { supplyInfoList = [], skuGoodsType } = record

        /* 过略掉未选中的价格和没填写数量的数据 */
        let newArr = $lodash.filter(supplyInfoList, (stem) => { return stem.checked && +stem.quantity > 0 })

        if (newArr.length > 0) {
          _totalAmount += $lodash.sumBy(newArr, (atem) => { return +atem.price * 100 * +atem.quantity / 100 })
        }

        if (['SPOT', 'CLOUD_SPOT'].includes(skuGoodsType)) {
          _spotAmount += $lodash.sumBy(newArr, (atem) => { return +atem.price * 100 * +atem.quantity / 100 })
        }

        if (['FUTURES', 'CLOUD_FUTURES'].includes(skuGoodsType)) {
          _futuresFrontMoney += $lodash.sumBy(newArr, (atem) => { return +atem.price * 100 * atem.prepaymentRatio * +atem.quantity / 100 / 100 })
        }
      }
    }

    return {
      totalAmount: _totalAmount,
      spotAmount: _spotAmount,
      futuresFrontMoney: _futuresFrontMoney
    }
  }

  // 设置价格数据格式
  setLoginPriceCellData = (record, i) => {
    const { supplyInfoList = [] } = record

    if (supplyInfoList && supplyInfoList.length > 0) {
      supplyInfoList[supplyInfoList.length - 1]['checked'] = true
      supplyInfoList[supplyInfoList.length - 1]['quantity'] = record.quantity
    }

    return supplyInfoList
  }

  // 下单
  submitManualOrder = (params) => {
    Api.submitManualOrder(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const { selectedEndInfo = {}, selectedAddressRow = {} } = this.state
        this.props.history.push({
          pathname: '/admin/orderSuccess',
          state: {
            orderId: data.orderId || 0,
            endUserId: selectedEndInfo.endUserId,
            receivedInfo: selectedAddressRow,
            backLinkUrl: '/admin/manualOrder'
          }
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 获取EU用户收货地址列表
  getEndUserReceivedAddressList = () => {
    const { selectedEndInfo = {} } = this.state
    let params = {
      endUserId: selectedEndInfo.endUserId,
      category: 0
    }
    Api.getEndUserReceivedAddressList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        if (data && data.length > 0) {
          data.forEach((item) => {
            item.name = item.receiver
            item.mobile = item.phoneNumber
            item.status = +item.category === 2 ? 'ENABLE' : 'DISABLE'
          })
        }

        this.setState({
          addressList: data || []
        }, () => {
          const { addressList = [] } = this.state
          if (addressList.length > 0) {
            this.handleSelectedAddress(addressList[0])
          }
        })
      }
    })
  }

  // 选择客户
  handleSelectCustomer = (record) => {
    this.setState({
      selectedEndInfo: record
    }, () => {
      this.getEndUserReceivedAddressList()
    })
  }

  // 点击搜索按钮
  handleSearch = (search = [], source) => {
    const { selectedEndInfo = {} } = this.state

    let unitList = []

    search.map(item => {
      if (item !== '') {
        let _arr = item.split('==')
        unitList.push({
          itemNumberModel: _arr.length > 0 ? _arr[0] : '',
          quantity: _arr[1] && +_arr[1] > 0 ? +_arr[1] : 1
        })
      }
    })

    let params = {
      unitList: unitList,
      endUserId: selectedEndInfo.endUserId
    }
    source.map((key) => { params[key] = true })

    this.getBatchSearchSku(params)
  }

  // 选择的收货地址
  handleSelectedAddress = (params) => {
    const { addressList = [] } = this.state

    const obj = addressList.find(item => { return item.id === params.id })

    this.setState({
      selectedAddressRow: obj !== undefined ? obj : {}
    })
  }

  // 点击提交订单
  handleSubmitOrder = () => {
    let { list = [], manualOrderAmounts = {} } = this.props

    const { selectedEndInfo = {}, selectedAddressRow = {} } = this.state
    let _list = $lodash.filter(list, (record) => { return record.quickOrderSearchResult === 'SUCCESS' })

    if (!selectedEndInfo.endUserId || +selectedEndInfo.endUserId === 0) {
      return message.warning('请输入要下单的客户')
    }

    if (_list.length === 0) {
      return message.warning('请选择要下单的商品')
    }

    for (let record of _list) {
      record['quantityInfoList'] = []
      let newArr = $lodash.filter(record['supplyInfoList'], (row) => { return row.checked && +row.quantity > 0 })

      if (newArr.length === 0) {
        Modal.warning({
          width: 353,
          title: <div>订货号:&nbsp;<span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />&nbsp;请选择价格并且填写数量</div>,
          maskClosable: true
        })

        return false
      }

      let rowTotal = $lodash.sumBy(newArr, 'quantity')

      if (rowTotal < (+record.minOrder)) {
        Modal.warning({
          width: 353,
          title: <div>订货号:&nbsp;<span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />&nbsp;最少购买{+record.minOrder}件</div>
        })

        return false
      }

      for (let row of newArr) {
        if (+row.price === 0) {
          Modal.warning({
            width: 353,
            title: <div>订货号:&nbsp;<span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />&nbsp;为价格面议产品，详情请咨询客服</div>,
            maskClosable: true
          })

          return false
        }

        if (['SPOT', 'CLOUD_SPOT'].includes(row.skuGoodsType) && +row.quantity > +row.stock) {
          Modal.warning({
            width: 353,
            title: <div>订货号:&nbsp;<span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />&nbsp;现货库存不足</div>,
            maskClosable: true
          })
          return false
        }

        let obj = {
          supplierId: row.supplierId,
          quantity: row.quantity
        }
        record['quantityInfoList'].push(obj)
      }
    }

    if (selectedEndInfo && +selectedEndInfo.enterpriseId > 0) { // 企业用户
      if (!selectedEndInfo.euEnterprise || !selectedEndInfo.euEnterprise.taxId || selectedEndInfo.euEnterprise.taxId === '') {
        return message.warning('企业用户暂无开票信息，不可下单。请去完善客户信息！')
      }
    }

    if (!selectedAddressRow.id || +selectedAddressRow.id === 0) {
      return message.warning('请选择客户收货地址')
    }
    let _unit = []
    _list.map((item, i) => {
      let obj = {
        commoditySkuId: item.commoditySkuId,
        skuGoodsType: item.skuGoodsType,
        quantityInfoList: item.quantityInfoList
      }

      _unit.push(obj)
    })

    let params = {
      endUserId: selectedEndInfo.endUserId,
      totalAmount: manualOrderAmounts.totalAmount ? manualOrderAmounts.totalAmount : 0,
      goodsInfoList: _unit,
      shippingAddressId: selectedAddressRow.id
    }

    this.submitManualOrder(params)
  }

  // 导入产品
  onChangeUploadSkuList = (data = []) => {
    let { list = [] } = this.props

    if (data.length > 0) {
      data.map((item, index) => {
        item['rowKey'] = `${item.commoditySkuId || 0}-${item.itemNumberModel}-${index}`

        if (item.quickOrderSearchResult === 'SUCCESS') {
          let n = $lodash.findIndex(list, (record) => { return record.commoditySkuId === item.commoditySkuId && record.skuGoodsType === item.skuGoodsType })

          if (n > -1) {
            const { quantity = 1 } = item
            let { supplyInfoList = [] } = list[n]
            let _totalQuantity = $lodash.sumBy(supplyInfoList, 'quantity')

            item['quantity'] = _totalQuantity + quantity
            list[n]['supplyInfoList'] = this.setLoginPriceCellData(item)
          } else {
            item['supplyInfoList'] = this.setLoginPriceCellData(item)
            list.push(item)
          }
        } else {
          list.push(item)
        }
      })
    }

    const _manualOrderAmounts = this.calcSelectedAmounts([...list])
    this.props.dispatch(setOrderBatchSkuList({ batchSkuList: [...list] }))
    this.props.dispatch(setManualOrderAmounts({ manualOrderAmounts: { ..._manualOrderAmounts } }))
  }

  render () {
    const { customerList = [], selectedEndInfo = {}, addressList = [], selectedAddressRow = {} } = this.state
    const { euEnterprise = {} } = selectedEndInfo

    return (
      <>
        <Row style={{ marginBottom: '15px' }} >
          <EndLand onRef={this.onRef}
            customerList={customerList}
            selectedRow={selectedEndInfo}
            onSelect={(rows) => this.handleSelectCustomer(rows)}
          />
        </Row>

        <Row style={{ marginBottom: '15px' }}>
          <SearchLand selectedEndInfo={selectedEndInfo}
            handleSubmit={(search, source) => this.handleSearch(search, source)}
            onUpload={(params) => this.onChangeUploadSkuList(params)}
          />
        </Row>

        { /* 产品信息 */}
        <Row style={{ marginBottom: '15px' }}>
          <ProductTable
            selectedEndInfo={selectedEndInfo}
          />
        </Row>

        { /* 地址信息 */
          selectedEndInfo && selectedEndInfo.endUserId
            ? <Row style={{ marginBottom: '15px' }}>
              <AddressList
                userInfo={selectedEndInfo}
                addressList={addressList}
                selectedRow={selectedAddressRow}
                onSelectAddress={(params) => { this.handleSelectedAddress(params) }}
                getAddressList={() => this.getEndUserReceivedAddressList()}
              />
            </Row>
            : null
        }

        {/* 开票信息 */
          selectedEndInfo && +selectedEndInfo.enterpriseId > 0
            ? <Row style={{ marginBottom: '15px' }}>
              <InvoiceLand info={euEnterprise}
                selectedEndInfo={selectedEndInfo}
              />
            </Row>
            : null
        }

        <Row style={{ marginBottom: '20px', textAlign: 'center' }}>
          <Button type='primary' onClick={() => this.handleSubmitOrder()}>
            提交订单
          </Button>
        </Row>

      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.batchSkuList,
    manualOrderAmounts: state.manualOrderAmounts
  }
}

export default connect(mapStateToProps)(ManualOrder)
