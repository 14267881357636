/**
 * 仓库模块
 */
import StoreEnter from '@/pages/store-house/store-enter/index' // 入库列表
import FuturesIn from '@/pages/store-house/futures-in/index' // 期货入库
import FuturesOut from './../pages/store-house/futures-out/index'// 期货出库
import StoreOut from '@/pages/store-house/store-out/index' // 出库订单列表
import StoreSend from '@/pages/store-house/store-send/index' // 发货
import SendAddress from '@/pages/store-house/send-address/index' // 发货地址编辑
import ExpressService from '@/pages/store-house/expressService/index' // 快递服务
import ExpressRecords from '@/pages/store-house/expressRecords/index' // 快递记录
import StoreVideo from '@/pages/store-house/store-video/index' // 仓库帮助视频

export default [
  {
    path: '/admin/storeIn',
    name: '商品入库',
    component: StoreEnter
  },
  {
    path: '/admin/futuresIn',
    name: '期货入库',
    component: FuturesIn
  },
  {
    path: '/admin/FuturesOut',
    name: '期货出库',
    component: FuturesOut
  },
  {
    path: '/admin/storeOut',
    name: '商品出库',
    component: StoreOut
  },
  {
    path: '/admin/storeSend',
    name: '商品发货',
    component: StoreSend
  },
  {
    path: '/admin/sendAddress',
    name: '发货地址',
    component: SendAddress
  },
  {
    path: '/admin/storeVideo',
    name: '帮助视频',
    component: StoreVideo
  },
  {
    path: '/admin/expressService',
    name: '快递服务',
    component: ExpressService
  },
  {
    path: '/admin/expressRecords',
    name: '快递记录',
    component: ExpressRecords
  }
]
