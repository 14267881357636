import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Table, Badge } from 'antd'

import filter from '@/common/utils/filter'

const { billStatusMaps } = filter

export default class BillCard extends Component {
  static propTypes = {
    tableData: PropTypes.array
  }
  state = {

  }
  columns = [
    {
      title: '序号',
      key: 'paninsuranceBill-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '账单日期',
      key: 'paninsuranceBill-1',
      dataIndex: 'dueMonth'
    },
    {
      title: '账单状态',
      key: 'paninsuranceBill-2',
      render: (text, record, index) => (
        <div>
          { this.renderInsuranceStatusLabel(record.status) }
        </div>
      )
    },
    {
      title: '应付金额',
      key: 'paninsuranceBill-3',
      dataIndex: 'totalAmount',
      render: (text, record, index) => (
        <>&yen;{ (+record.totalAmount * 100 / 100).toFixed(2) }</>
      )
    },
    {
      title: '已付金额',
      key: 'paninsuranceBill-4',
      dataIndex: 'payAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.payAmount * 100 / 100).toFixed(2)} </>
      )
    },
    {
      title: '未付金额',
      key: 'paninsuranceBill-5',
      render: (text, record, index) => (
        <>&yen;{ ((+record.totalAmount - (+record.payAmount)) * 100 / 100).toFixed(2) }</>
      )
    }
  ]

  componentDidMount () { }

  // 渲染账单状态
  renderInsuranceStatusLabel = (status) => {
    let label = ''
    let obj = billStatusMaps.find((item) => { return item.id === status })

    if (obj) {
      label = <Badge status={obj.badge} text={<span style={{ fontSize: '12px' }}>{obj.name}</span>} />
    }

    return label
  }

  render () {
    const { tableData = [] } = this.props

    return (
      <div>
        <Row>
          <Table rowKey='id'
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
        </Row>
      </div>
    )
  }
}
