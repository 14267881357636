import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Table, Button, Icon, message } from 'antd'

import Search from './search'
import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class QuickSearch extends Component {
  static propTypes = {
    len: PropTypes.number,
    onConfirm: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {},
    selectedRows: [],
    selectedRowKeys: []
  }
  columns = [
    {
      title: '型号',
      key: 'resultList-0',
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'resultList-1',
      dataIndex: 'itemNumber',
      ellipsis: true
    },
    {
      title: '产品',
      key: 'resultList-2',
      dataIndex: 'name',
      ellipsis: true
    }
  ]

  componentDidMount () { }

  // 获取产品列表
  getMyProductListInProductModule = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      // stockStatus: 'ENABLE',
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getMyProductListInProductModule(params).then(res => {
      const { code, data = {} } = res

      if (+code === 10000) {
        this.setState({
          tableData: data.pageBean.list || [],
          pages: {
            ...pages,
            total: data.pageBean.total || 0
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => this.getMyProductListInProductModule())
  }

  // 批量选择
  onSelectChange = (keys = [], rows = []) => {
    this.setState({
      selectedRows: rows,
      selectedRowKeys: keys
    })
  }

  // 点击快速新增
  handleFill = () => {
    const { len } = this.props
    let { selectedRows = [] } = this.state
    let _selectedRows = []

    if (len === 8) {
      return message.warning(`最多可添加 8 项数据！`)
    }

    selectedRows.map((item, i) => {
      let obj = {
        id: item.commoditySkuId,
        name: item.name || '',
        linkUrl: `/commodity-detail?commoditySkuId=${item.commoditySkuId}`,
        // linkUrl: `/commodity-detail?commoditySkuId=${item.commoditySkuId}&skuGoodsType=${item.skuGoodsType}`,
        imageUrl: item.imageUrl || ''
      }

      _selectedRows.push(obj)
    })

    this.setState({
      selectedRows: [],
      selectedRowKeys: []
    }, () => {
      this.props.onConfirm(_selectedRows)
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getMyProductListInProductModule()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getMyProductListInProductModule()
    })
  }

  render () {
    const { pages, tableData, selectedRowKeys = [] } = this.state

    const pagination = {
      size: 'small',
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys: selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <div>
        <Card bodyStyle={{ padding: '15px' }} title='快速搜索'>
          <Row style={{ marginBottom: '15px' }}>
            <Search handleSubmit={(obj) => this.handleSearch(obj)} />
          </Row>
          <Row>
            <Row style={{ marginBottom: '10px', textAlign: 'right' }}>
              <Button type='primary' size='small' disabled={selectedRowKeys.length === 0}
                onClick={() => this.handleFill()}
              >快速新增<Icon type='arrow-right' />
              </Button>
            </Row>
            <Row>
              <Table rowKey='commoditySkuId'
                columns={this.columns}
                dataSource={tableData}
                pagination={pagination}
                rowSelection={rowSelection}
              />
            </Row>
          </Row>
        </Card>
      </div>
    )
  }
}
