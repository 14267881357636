import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Radio, Button, Icon, Modal, message } from 'antd'
import $lodash from 'lodash'

import Api from '@/common/api/index'
import { renderAddressLabel } from '@/common/utils/mUtils'
import { saveEuKeyMap } from '../localdatas'
import AddressModal from '@/components/address-modal/index'

const { confirm } = Modal

class AddressList extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    addressList: PropTypes.array,
    selectedRow: PropTypes.object,
    getAddressList: PropTypes.func,
    onSelectAddress: PropTypes.func
  }
  state = {
    addressModal: false, // 收货地址弹窗
    type: 1,
    rowDetail: {}
  }

  componentDidMount () {

  }

  // 新增用户收货地址
  saveAddEndUserReceiveAddress = (params) => {
    Api.saveAddEndUserReceiveAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.props.getAddressList()
      } else {
        message.error(res.message)
      }
    })
  }

  // 修改用户收货地址
  saveEditEndUserReceiveAddress = (params) => {
    Api.saveEditEndUserReceiveAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.props.getAddressList()
      } else {
        message.error(res.message)
      }
    })
  }

  // 删除用户收货地址
  deleteEndUserReceivedAddress = (params) => {
    Api.deleteEndUserReceivedAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.props.getAddressList()
      } else {
        message.error(res.message)
      }
    })
  }

  // 点击确定保存
  handleConfirmAddress = (values) => {
    const { userInfo } = this.props
    const { type } = this.state

    let params = this.changeEnterParamsKey(values)
    params.category = values.status === 'ENABLE' ? 2 : 0

    if (type === 1) {
      this.saveAddEndUserReceiveAddress({ ...params, endUserId: userInfo.euId, category: 0 })
    } else {
      this.saveEditEndUserReceiveAddress({ ...params, endUserId: userInfo.euId, category: 0 })
    }
  }

  // 点击新增/编辑地址
  handleShowAddressModal = (record, type) => {
    this.setState({
      addressModal: true,
      rowDetail: record,
      type: type
    })
  }

  // 点击删除地址
  handleDeleteAddress = (record) => {
    confirm({
      title: '确定删除该地址？',
      content: '',
      onOk: () => {
        const { userInfo } = this.props
        this.deleteEndUserReceivedAddress({ endUserId: userInfo.euId, id: record.id, category: 0 })
      }
    })
  }

  closeModal = () => {
    this.setState({
      type: 1,
      rowDetail: {},
      addressModal: false
    })
  }

  changeEnterParamsKey = (params) => {
    const obj = {}

    $lodash.forEach(params, (value, key) => {
      obj[saveEuKeyMap[key]] = value
    })

    return obj
  }

  render () {
    const { addressList = [], selectedRow = {} } = this.props
    const { addressModal = false, type = 1, rowDetail = {} } = this.state

    const radioStyle = {
      paddingRight: '15px',
      marginBottom: '5px',
      display: 'block',
      height: '30px',
      lineHeight: '30px',
      fontSize: '14px',
      color: '#333333',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }

    return (
      <>
        <Card title='客户收货地址'>
          {
            addressList.length > 0
              ? <Row>
                <Radio.Group style={{ width: '100%' }}
                  value={selectedRow.id}
                  onChange={(e) => this.props.onSelectAddress({ id: e.target.value })}
                >
                  {
                    addressList.map((item, n) => (
                      <Row key={`addressList-${n}`}>
                        <Col span={14}>
                          <Radio value={item.id} key={`addressOption-${n}`} style={radioStyle}>
                            {item.receiver} {item.phoneNumber} {renderAddressLabel(item.province, item.city, item.county, item.address)}
                          </Radio>
                        </Col>
                        <Col span={6}>
                          <div style={{ lineHeight: '30px' }}>
                            <a style={{ marginRight: '10px' }}
                              onClick={() => this.handleShowAddressModal(item, 2)}
                            >
                              编辑地址
                            </a>

                            {
                              +selectedRow.id === +item.id
                                ? null
                                : <a onClick={() => this.handleDeleteAddress(item)}
                                >删除地址</a>
                            }

                          </div>
                        </Col>
                      </Row>
                    ))
                  }
                </Radio.Group>
              </Row>
              : <Row style={{ marginBottom: '8px' }}>暂无收货地址,请新增收货地址</Row>
          }

          <Row>
            <Button type='primary' onClick={() => this.handleShowAddressModal({}, 1)}>
              <Icon type='plus' />新增收货地址
            </Button>
          </Row>
        </Card>

        {
          addressModal
            ? <AddressModal
              type={type}
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.handleConfirmAddress(params)}
            />
            : null
        }
      </>
    )
  }
}

export default AddressList
