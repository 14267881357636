import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Card, Row, Pagination } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import DetailModal from '../component/detail-modal'
import DownloadApi from '@/common/api/downloadApi'
import Descriptions from './descriptions'
import Updowm from '../component/down'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderOrderStatusLabel, renderOrderPaymentStatusLabel, renderOrderDeliveryStatusLabel } from '@/common/utils/mUtils'

const { downOrderUrlFuture } = DownloadApi
const { pageSizeOptions, orderStatusMaps, orderDeliveryStatusMaps, orderPaymentStatusMaps } = filter

class FuturesOrder extends Component {
  static propTypes = {
    tableData: PropTypes.array
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {}, // 检索参数
    detailModal: false, // 查看明细弹窗
    rowDetail: {},
    summary: {},
    searchData: [
      {
        name: 'endUserId',
        formType: 'Select',
        itemParams: {
          label: '客户名称'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['endUserId', 'showName']
      },
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {

        }
      },
      {
        name: 'orderStatusList',
        formType: 'Select',
        itemParams: {
          label: '订单状态'
        },
        cptParams: {
          placeholder: '请选择...',
          mode: 'multiple',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderStatusMaps
        ]
      },
      {
        name: 'paymentStatus',
        formType: 'Select',
        itemParams: {
          label: '付款状态'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderPaymentStatusMaps
        ]
      },
      {
        name: 'deliveryStatus',
        formType: 'Select',
        itemParams: {
          label: '发货状态'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderDeliveryStatusMaps
        ]
      }
    ]
  }

  columns = [
    {
      title: '序号',
      key: 'goodsorder-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '订单日期',
      key: 'futuresorder-1',
      width: 120,
      render: (text, record, index) => (
        <div>
          { record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </div>
      )
    },
    {
      title: '订单编号',
      key: 'futuresorder-2',
      dataIndex: 'orderId'
    },
    {
      title: '客户名称',
      key: 'futuresorder-3',
      dataIndex: 'customerName'
    },
    {
      title: '订单金额',
      key: 'futuresorder-4',
      dataIndex: 'payableAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.payableAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '订单已付金额',
      key: 'futuresorder-5',
      dataIndex: 'paidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.paidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '首款应付金额',
      key: 'futuresorder-6',
      dataIndex: 'futuresFrontMoney',
      render: (text, record, index) => (
        <>&yen;{(+record.futuresFrontMoney * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '首款已付金额',
      key: 'futuresorder-7',
      dataIndex: 'futuresPaidFrontMoney',
      render: (text, record, index) => (
        <>&yen;{(+record.futuresPaidFrontMoney * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '尾款应付金额',
      key: 'futuresorder-8',
      dataIndex: 'futuresTailMoney',
      render: (text, record, index) => (
        <>&yen;{(+record.futuresTailMoney * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '尾款已付金额',
      key: 'futuresorder-9',
      dataIndex: 'futuresPaidTailMoney',
      render: (text, record, index) => (
        <>&yen;{(+record.futuresPaidTailMoney * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '订单状态',
      key: 'futuresorder-12',
      render: (text, record, index) => (
        <>{ renderOrderStatusLabel(record.orderStatus) }</>
      )
    },
    {
      title: '付款状态',
      key: 'euorder-10',
      render: (text, record, index) => (
        <>{ renderOrderPaymentStatusLabel(record.paymentStatus) }</>
      )
    },
    {
      title: '发货状态',
      key: 'euorder-11',
      render: (text, record, index) => (
        <>{ renderOrderDeliveryStatusLabel(record.deliveryStatus) }</>
      )
    },
    {
      title: '开票状态',
      key: 'futuresorder-13',
      render: (text, record, index) => (
        <>{ +record.invoiceStatus === 1 ? '已开票' : '未开票' }</>
      )
    },
    {
      title: '操作',
      key: 'futuresorder-14',
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small' onClick={() => this.handleShowDetail(record)}>
            查看明细
          </Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getGuesteuUserLike()
    this.getTradeOrderAmountSummary()
    this.getFuturesOrderList()
  }

   // 搜索客户信息
   getGuesteuUserLike = (val = '') => {
     let params = {
       pageNo: 1,
       pageSize: 999,
       searchName: val
     }
     Api.getGuesteuUserLike(params).then(res => {
       const { code, data } = res
       if (+code === 10000) {
         let { searchData } = this.state
         searchData[0].options = data.list || []

         this.setState({
           searchData
         })
       }
     })
   }

   // 获取期货订单列表
   getFuturesOrderList = () => {
     const { pages, query } = this.state

     let params = {
       ...query,
       pageNo: pages.pageNo,
       pageSize: pages.pageSize
     }

     if (params.orderStatus === '') {
       delete params.orderStatus
     }

     Api.getFuturesOrderList(params).then(res => {
       const { code, data } = res
       if (+code === 10000) {
         this.setState({
           tableData: data.list || [],
           pages: {
             ...pages,
             total: data.total || 0
           }
         })
       }
     })
   }

   // 点击搜索
   handleSearch = (search) => {
     const { searchData = [], pages } = this.state
     let _searchData = [...searchData]
     if (search.orderTime) {
       search.beginDate = search.orderTime.length > 0 ? search.orderTime[0].format('YYYY-MM-DD') : ''
       search.endDate = search.orderTime.length > 0 ? search.orderTime[1].format('YYYY-MM-DD') : ''
     }
     delete search.orderTime

     this.setState({
       pages: {
         ...pages,
         pageNo: 1
       },
       query: search || {},

       searchData: _searchData
     }, () => {
       this.getTradeOrderAmountSummary()
       this.getFuturesOrderList()
     })
   }
  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getFuturesOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getFuturesOrderList()
    })
  }

  // 点击查看商品明细
  handleShowDetail = (record) => {
    this.setState({
      detailModal: true,
      rowDetail: record || {}
    })
  }

  closeModal = () => {
    this.setState({
      detailModal: false,
      rowDetail: {}
    })
  }

  // 获取订单金额汇总
  getTradeOrderAmountSummary = () => {
    const { pages } = this.state
    let params = {
      orderType: 'ORDER_FUTURES',
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getTradeOrderAmountSummary(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          summary: data || {}
        })
      }
    })
  }

  render () {
    const { rowDetail = {}, query = {}, detailModal = false, searchData = [], summary = {}, pages, tableData = [] } = this.state

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '15px' }}>
          <Row>
            <Updowm query={query} future api={downOrderUrlFuture} />
            <Descriptions summary={summary} />
          </Row>

          <Table rowKey='orderId'
            pagination={false}
            columns={this.columns}
            dataSource={tableData}
          />

          {
            tableData.length > 0
              ? <Row style={{ marginTop: '16px', textAlign: 'right' }}>
                <Pagination
                  showSizeChanger
                  showQuickJumper
                  total={pages.total}
                  showTotal={(total) => { return `共 ${total} 条` }}
                  current={pages.pageNo}
                  pageSize={pages.pageSize}
                  pageSizeOptions={pageSizeOptions}
                  onChange={this.pageChange}
                  onShowSizeChange={this.pageSizeChange}
                />
              </Row> : null
          }

          {
            detailModal
              ? <DetailModal
                type={2}
                detailInfo={rowDetail}
                onCancel={() => this.closeModal()}
              />
              : null
          }
        </Card>
      </>
    )
  }
}

export default FuturesOrder
