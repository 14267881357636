import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Button, Row, Col } from 'antd'
import $lodash from 'lodash'

import InvoiceExpress from '@/components/invoiceExpress/index' // 发票凭证
import InvoiceAccount from '@/components/invoiceAccount/index' // 发票抬头
import InvoiceProduct from '@/components/invoiceProduct/index' // 发票商品
import InvoiceReceiving from '@/components/invoiceReceiving/index' // 发票地址

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import { renderInvoiceTypeLabel } from '@/common/utils/mUtils'

import styles from './index.module.scss'

const FormItem = Form.Item

class InvoiceForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    openStatus: PropTypes.string, // 开票状态 OPEN-开票 REPEAT_OPEN-重新开票 OPENING-开票中 OPENED-开票完成
    ids: PropTypes.array,
    invoiceType: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    tableData: [], // 发票商品信息
    totalAmount: 0, // 发票金额
    invoiceType: '', // 发票类型
    invoiceInfo: {}, // 发票抬头信息
    receivingAddress: {}, // 收票地址信息
    invoiceExpressInfo: {}, // 发票物流信息
    invoiceVoucherInfo: {}, // 发票凭证信息
    sendAddressList: [], // 发货地址
    selectedAddressKey: null, // 发货选中的地址ID
    count: 0,
    invoiceCodeKeys: [{ id: 0, value: null }]
  }

  componentDidMount () {
    this.getSendAddressList()
    this.getEUOrderInvoiceDetails()
  }

  // 获取发货地址列表
  getSendAddressList = async () => {
    const res = await Api.getSendAddressList({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        sendAddressList: data || [],
        selectedAddressKey: data && data.length > 0 ? data[0].id : null
      })
    }
  }

  // 获取自营开票- 开票抬头& 商品数据信息
  getEUOrderInvoiceDetails = async () => {
    const { ids = [], invoiceType } = this.props
    const res = await Api.getEUOrderInvoiceDetails({ invoiceRecordId: ids[0] })
    const { code, data } = res
    if (+code === 10000) {
      if (data && data.goodsList && data.goodsList.length > 0) {
        const { goodsList } = data

        data.goodsList = $lodash.map(goodsList, (item) => {
          return {
            ...item,
            price: item.sellingPrice
          }
        })
      }

      let _invoiceCodeKeys = [{ id: 0, value: undefined }]
      if (data && data.invoiceCode && $lodash.trim(data.invoiceCode) !== '') {
        const arr = $lodash.split(data.invoiceCode, ',')
        _invoiceCodeKeys = $lodash.map(arr, (o, i) => { return { id: i, value: o } })
      }

      this.setState({
        invoiceInfo: data.invoiceInfo || {},
        invoiceType: invoiceType,
        tableData: data.goodsList || [],
        totalAmount: +data.totalAmount,
        receivingAddress: data.addressInfo || {},
        invoiceVoucherInfo: data || {},
        invoiceCodeKeys: _invoiceCodeKeys,
        count: _invoiceCodeKeys.length - 1
      })
    }
  }

  // 点击确定
  handleConfirm = () => {
    const { form, ids = [], openStatus, onConfirm } = this.props
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { courierCode, invoiceCodeKeys } = values

        values.courierCode = $lodash.includes(courierCode, 'SAAS') ? $lodash.split(courierCode, '-')[1] : courierCode
        values.invoiceRecordId = ids[0]

        const newkeys = invoiceCodeKeys && invoiceCodeKeys.length > 0 ? $lodash.filter(invoiceCodeKeys, (o) => { return o && $lodash.trim(o) !== '' }) : []

        values.invoiceCode = newkeys.length > 0 ? $lodash.join(newkeys, ',') : undefined

        onConfirm(values, openStatus)
      }
    })
  }

  // 点击导出纸质发票表格
  handleDownload = () => {
    const { ids = [] } = this.props
    DownloadApi.downloadInvoiceTpl({ invoiceRecordId: ids[0] })
  }

  // 点击导出电子发票表格
  handleDownloadElectronic = () => {
    const { ids = [] } = this.props

    DownloadApi.downloadEUElectronicInvoiceExcel({ invoiceRecordId: ids[0] })
  }

  // 新增invoiceCode FormItem
  handleAddInvoiceCodeFormItem = (index) => {
    const { count = 0, invoiceCodeKeys = [{ id: 0, value: null }] } = this.state

    invoiceCodeKeys.splice(index + 1, 0, { id: count + 1, value: null })

    this.setState({
      invoiceCodeKeys: invoiceCodeKeys,
      count: count + 1
    })
  }

  // 新增invoiceCode FormItem
  handleRemoveInvoiceCodeFormItem = (index) => {
    const { invoiceCodeKeys = [0] } = this.state

    if (invoiceCodeKeys.length === 1) { return false }

    invoiceCodeKeys.splice(index, 1)

    this.setState({
      invoiceCodeKeys: invoiceCodeKeys
    })
  }

  render () {
    const { form, openStatus, onCancel } = this.props
    const { invoiceType, invoiceInfo = {}, tableData = [], totalAmount = 0, invoiceVoucherInfo = {}, invoiceCodeKeys = [],
      receivingAddress = {}, sendAddressList = [], selectedAddressKey = null } = this.state

    return (
      <>
        <Modal title='发票详情' width={1000} visible bodyStyle={{ padding: '16px', height: '500px', overflowY: 'scroll' }}
          maskClosable={false}
          onCancel={() => onCancel()}
          footer={[
            <Button key={`invoiceButton-0`} onClick={() => onCancel()}>关闭</Button>,
            <Button type='primary' key={`invoiceButton-3`} onClick={() => this.handleDownloadElectronic()}>导出电子发票表格</Button>,
            <Button type='primary' key={`invoiceButton-2`} onClick={() => this.handleDownload()}>导出纸质发票表格</Button>,
            <Button type='primary' key={`invoiceButton-1`} onClick={() => this.handleConfirm()}>{ openStatus === 'OPENED' ? '确定修改' : '确定开票' }</Button>
          ]}
        >
          <Form>
            <Row gutter={24}>
              <Col span={12}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='发票类型'>{ renderInvoiceTypeLabel(invoiceType) }</FormItem>
                </div>
              </Col>
            </Row>

            <InvoiceExpress form={form}
              invoiceVoucherInfo={invoiceVoucherInfo}
              openStatus={openStatus}
              invoiceCodeKeys={invoiceCodeKeys}
              addInvoiceCodeFormItem={(index) => this.handleAddInvoiceCodeFormItem(index)}
              removeInvoiceCodeFormItem={(index) => this.handleRemoveInvoiceCodeFormItem(index)}
            />

            <InvoiceAccount invoiceInfo={invoiceInfo} />

            <InvoiceProduct tableData={tableData} totalAmount={totalAmount} />

            <InvoiceReceiving form={form} receivingAddress={receivingAddress} sendAddressList={sendAddressList} selectedAddressKey={selectedAddressKey} />
          </Form>
        </Modal>
      </>
    )
  }
}

const InvoiceModal = Form.create()(InvoiceForm)

export default InvoiceModal
