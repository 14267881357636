import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Row, Input, InputNumber, Radio, Upload, Icon, message, Button } from 'antd'

import UploadThumbnail from '@/components/uploadThumbnail/index'
import MsgcodeModal from '../msgcodeModal/index'
import VerifyModal from '@/components/verify-modal/index'

import UploadApi from '@/common/api/uploadApi'
import Api from '@/common/api/index'
import { getStore } from '@/common/utils/mUtils'

import styles from './index.module.scss'

const FormItem = Form.Item
const { TextArea } = Input
const { uploadOrdinaryImageUrl } = UploadApi

class RechargeForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    yztime: 59,
    btnDisabled: false,
    msgModal: false,
    verifyModal: false,
    rowDetail: {},
    adminPhone: ''
  }

  componentDidMount () {
    this.getAdminPhoneToDesensitization()
  }

  // 组件卸载时 关闭定时器
  componentWillUnmount () {
    this.clearInterval()
  }

  // 获取手机号码
  getAdminPhoneToDesensitization = () => {
    Api.getAdminPhoneToDesensitization({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          adminPhone: data || ''
        })
      }
    })
  }

  // 发送短信验证码
  sendSmsCaptchaAndValidate = (params) => {
    Api.sendSmsCaptchaAndValidate(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.setInterval()
        this.setState({
          msgModal: true
        })
      }
    })
  }

  // 点击确定
  handleConfirm = (params) => {
    let { rowDetail } = this.state
    let query = {
      ...rowDetail,
      ...params
    }
    this.props.onConfirm(query)
    this.closeModal()
  }

  // 上传图片
  handleChangeUpload = (info, fieldKey) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        setTimeout(() => {
          this.props.form.setFieldsValue({ [fieldKey]: data.imageUrl })
        }, 20)
      } else {
        message.warning('上传失败')
      }
    }
  }

  // 点击删除上传的图片
  handleRemoveUploadPic = (fieldKey) => {
    this.props.form.setFieldsValue({ [fieldKey]: '' })
  }

  // 滑块验证码验证成功
  handleVerifySlideCheckSuccess = (params) => {
    this.setState({
      verifyModal: false
    }, () => {
      const { rowDetail = {} } = this.state
      this.sendSmsCaptchaAndValidate({ ...params, ...rowDetail })
    })
  }

  // 点击获取短信验证码
  handleGetMessCode = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {} } = this.props
        const { voucherUrl = '' } = values

        if (voucherUrl === '') {
          return message.warning('请上传凭证图片')
        }

        values['endUserId'] = detailInfo.endUserId
        values['sendSmsCaptchaEnum'] = 'EU_BALANCE_RECHARGE'

        this.setState({
          verifyModal: true,
          rowDetail: values
        })
      }
    })
  }

  // 倒计时
  countDown = () => {
    const { yztime = 59 } = this.state
    if (yztime === 1) {
      this.clearInterval()
    } else {
      this.setState({ btnDisabled: true, yztime: yztime - 1 })
    }
  }

  // 开始定时器
  setInterval = () => {
    this.timer = setInterval(this.countDown, 1000)
  }

  // 清除定时器
  clearInterval = () => {
    clearInterval(this.timer)
    this.setState({
      btnDisabled: false,
      yztime: 59
    })
  }

  closeModal = () => {
    this.setState({
      msgModal: false,
      verifyModal: false,
      rowDetail: {}
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { detailInfo } = this.props
    const { yztime = 59, btnDisabled = false, msgModal = false, verifyModal = false, adminPhone = '' } = this.state

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 }
    }

    return (
      <div>
        <Modal title='余额充值' width={640} bodyStyle={{ maxHeight: '400px', overflowY: 'scroll' }}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          footer={[
            <span style={{ marginRight: '12px' }} key='rechargeSpan'>发送手机：{adminPhone}</span>,
            <Button onClick={this.props.onCancel} key='rechargeCancel'>取消</Button>,
            <Button type='primary' key='rechargeConfirm'
              disabled={btnDisabled}
              onClick={this.handleGetMessCode}
            >
              {
                !btnDisabled ? '获取验证码' : `${yztime} s 后重新发送`
              }
            </Button>
          ]}
        >
          <Row>
            <Form>
              <FormItem label='客户名称' {...formItemLayout}>
                <div style={{ width: '380px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  <span style={{ marginRight: '5px' }}>{detailInfo.customerName}</span>
                  {
                    detailInfo.enterpriseName && detailInfo.enterpriseName !== ''
                      ? <span>({detailInfo.enterpriseName})</span>
                      : null
                  }

                </div>
              </FormItem>

              <FormItem label='充值类型' {...formItemLayout}>
                {
                  getFieldDecorator('rechargeType', {
                    initialValue: 'DRAFT',
                    rules: [
                      { required: true, message: '该项必填' }
                    ]
                  })(
                    <Radio.Group>
                      <Radio value={'DRAFT'} key={`rechargeType-0`}>承兑汇票</Radio>
                      <Radio value={'CASH'} key={`rechargeType-1`}>现金</Radio>
                    </Radio.Group>
                  )
                }
              </FormItem>

              <FormItem label='充值金额' {...formItemLayout}>
                {
                  getFieldDecorator('amount', {
                    rules: [
                      { required: true, message: '该项必填' }
                    ]
                  })(
                    <InputNumber placeholder='请输入...' autoComplete='off'
                      style={{ width: '305px' }}
                    />
                  )
                }
              </FormItem>

              <FormItem style={{ marginBottom: '10px' }}>
                <FormItem style={{ display: 'none', marginBottom: '0px' }}>
                  {
                    getFieldDecorator('voucherUrl', {
                      rules: [
                        { required: true, message: '请上传凭证图片' }
                      ]
                    })(
                      <Input autoComplete='off' />
                    )
                  }
                </FormItem>
                <Row className={`${styles.uploadFormitemRow}`}>
                  <FormItem label={<label className={`${styles.required}`}>凭证图片</label>} {...formItemLayout}>
                    {
                      this.props.form.getFieldValue('voucherUrl') === undefined || this.props.form.getFieldValue('voucherUrl') === ''
                        ? <Upload name='file' listType='picture-card' showUploadList={false}
                          accept='image/png, image/jpg, image/jpeg'
                          headers={{ Token: getStore('token') }}
                          action={uploadOrdinaryImageUrl}
                          onChange={(info) => this.handleChangeUpload(info, 'voucherUrl')}
                        >
                          <div>
                            <Icon type='plus' />
                            <div>点击上传</div>
                          </div>
                        </Upload>
                        : <UploadThumbnail
                          imageUrl={this.props.form.getFieldValue('voucherUrl')}
                          onDelete={() => this.handleRemoveUploadPic('voucherUrl')}
                        />
                    }
                  </FormItem>
                </Row>

              </FormItem>

              <FormItem label='备注' {...formItemLayout}>
                {
                  getFieldDecorator('remarks', {

                  })(
                    <TextArea placeholder='请输入...' autoComplete='off'
                      rows={4}
                      style={{ width: '305px' }}
                    />
                  )
                }
              </FormItem>
            </Form>
          </Row>
        </Modal>

        {
          msgModal
            ? <MsgcodeModal
              onConfirm={(params) => this.handleConfirm(params)}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          verifyModal
            ? <VerifyModal
              onConfirm={(params) => this.handleVerifySlideCheckSuccess(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </div>
    )
  }
}

const BalanceRecharge = Form.create()(RechargeForm)

export default BalanceRecharge
