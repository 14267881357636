import React, { } from 'react'
import PropTypes from 'prop-types'
import { concat } from 'lodash'

import styles from './index.module.scss'

function PayMoeny (props) {
  const { obj = {}, amount = 0 } = props

  return (
    <div className={styles.goodsAdress}>
      <div>
        <label className='label'>寄送至</label>
        {
          obj
            ? <span>{concat(obj.addressRegion, obj.address, ' ', '收货人：', obj.receiver, ' ', obj.phoneNumber)}</span>
            : null
        }
      </div>
      <div className={styles.costTitle}><label className='label'>实付款</label><span className={styles.costM}>&yen;{(+amount * 100 / 100).toFixed(2)}</span></div>
    </div>
  )
}

PayMoeny.propTypes = {
  obj: PropTypes.object,
  amount: PropTypes.number
}

export default PayMoeny
