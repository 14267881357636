import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Table, message, Button } from 'antd'
import moment from 'moment'
import { setNoticeDetail } from '@/reducers/action'

import SearchForm from '@/components/tool-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter
const notifyOptions = [
  { type: 'ALL', label: '全部' },
  { type: 'SYSTEM_NOTIFY', label: '活动通知' },
  { type: 'SYSTEM_ANNOUNCEMENT', label: '系统公告' },
  { type: 'TASK_DISTRIBUTE', label: '任务分发' }
]

class SendedInformation extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func
  }
  state = {
    searchData: [
      {
        name: 'msgType',
        formType: 'Select',
        itemParams: {
          label: '信息类型'
        },
        cptParams: {

        },
        options: notifyOptions,
        optionValue: ['type', 'label'],
        rules: {
          initialValue: 'ALL'
        }
      },
      {
        name: 'sendTime',
        formType: 'RangePicker',
        itemParams: {
          label: '发送时间'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {
      msgType: 'ALL'
    }
  }

  columns = [
    {
      title: '序号',
      key: 'sendedInformation-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '信息类型',
      key: 'sendedInformation-1',
      render: (text, record, index) => (
        <div>{ this.renderMsgTypeLabel(record.msgType) }</div>
      )
    },
    {
      title: '标题',
      key: 'sendedInformation-2',
      ellipsis: true,
      dataIndex: 'title'
    },
    {
      title: '内容',
      key: 'sendedInformation-3',
      ellipsis: true,
      dataIndex: 'content'
    },
    {
      title: '发送时间',
      key: 'sendedInformation-4',
      render: (text, record, index) => (
        <div>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </div>
      )
    },
    {
      title: '发送对象',
      key: 'sendedInformation-5',
      dataIndex: 'notifyObject'
    },
    {
      title: '发布人',
      key: 'sendedInformation-6',
      dataIndex: 'sendName'
    },
    {
      title: '操作',
      key: 'sendedInformation-7',
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small' onClick={() => this.handleLinkToNoticeDetail(record)}>查看</Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getNotifyManageList()
  }

  // 获取已发送的消息列表
  getNotifyManageList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      msgSendStatus: 'SENT',
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.getNotifyManageList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      } else {
        message.error(res.message)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.sendTime && search.sendTime.length === 2) {
      search['beginDate'] = moment(search.sendTime[0]).format('YYYY-MM-DD')
      search['endDate'] = moment(search.sendTime[1]).format('YYYY-MM-DD')

      delete search.sendTime
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getNotifyManageList()
    })
  }

  // 点击查看详情
  handleLinkToNoticeDetail = (record = {}) => {
    this.props.dispatch(setNoticeDetail({ noticeDetail: record }))
    this.props.history.push({ pathname: '/admin/notifyDetail' })
  }

  renderMsgTypeLabel = (status) => {
    const obj = notifyOptions.find(item => { return item.type === status })
    const label = obj !== undefined ? obj.label : ''

    return label
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getNotifyManageList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getNotifyManageList()
    })
  }

  render () {
    const { searchData, pages, tableData } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }} style={{ marginBottom: '15px' }}>
          <SearchForm {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </Card>

        <Card bodyStyle={{ padding: '15px' }}>
          <Table rowKey='id'
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}

export default connect()(SendedInformation)
