import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row } from 'antd'

import styles from './index.module.scss'

export default class InsuraexplanModal extends Component {
  static propTypes = {
    onCancel: PropTypes.func
  }
  render () {
    return (
      <div>
        <Modal title={<label className={`${styles.modalTitle}`}>平安保险说明</label>} bodyStyle={{ padding: '30px', color: '#333333', height: '460px', overflowY: 'auto' }}
          width={800}
          visible
          footer={false}
          onCancel={() => this.props.onCancel()}
        >
          <Row className={`${styles.explanWrap}`}>
            <div className={`${styles.title}`}>免赔额：每次事故绝对免赔额RMB300或损失金额的5%，以高者为准。</div>
            <div className={`${styles.title}`}>运量核对：商家应严格按照选择货物进行发货，不得随意添加或删减货物，若商家未按照规定发货则不予赔偿。</div>
            <div className={`${styles.title}`}>保险标的：普通货物，但须剔除如下货物：</div>
            <p>1）精密仪器（精密仪器的判断条件是:A.对运输有特别的防震动、防倾斜、防尘等特殊要求。B.受国内维修能力所限，设备受损后无法在国内修复。C.单件货物保额超过RMB500万元。满足上述条件之一的即为精密设备或仪器）</p>
            <p>2）鲜活动植物、瓜果蔬菜、蛋类；</p>
            <p>3）大宗散货（包括散装的矿产品、农产品、化工产品、煤、油品）；</p>
            <p>4）工艺品（包括各类雕刻、编织、艺术品、、刺绣、古董、字画、瓷器、玉雕及其他玉石制品等）、现金、有价证券、珠宝、具有收藏价值的货物以及计算机软件等难以客观确定保险价值的货物；</p>
            <p>5）车辆、航空器及航空发动机、船舶及船舶发动机有关运输设备；</p>
            <p>6）武器、弹药及其零配件；</p>
            <p>7）《危险货物品名表》GB12268中列明的货物；</p>
            <p>8）舱面货及甲板货；</p>
            <p>9）疫苗、针剂、液体药品、试剂、血液制品；</p>
            <p>10）镍矿、萤石、石英砂等易流态化精矿货物；</p>
            <p>11）各类农作物、粮食类货物（包括但不限于花生、大蒜、葵花籽、线麻籽、豆粕、鱼粉、非袋装白糖）；</p>
            <p>12）太阳能面板、光伏产品；</p>
            <p>13）水泥、玻璃；</p>
            <p>14）红木家具、进口家具、钢琴、乐器；</p>
            <p>15）手机、电脑、液晶电视、液晶显示器、平板电脑、数码相机以及其他电子3c产品；</p>
          </Row>
        </Modal>
      </div>
    )
  }
}
