import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'

export default class MatchExplain extends Component {
  static propTypes = {
    totalNum: PropTypes.number,
    successNum: PropTypes.number,
    failNum: PropTypes.number
  }
  state = {}

  componentDidMount () {}

  render () {
    const { totalNum, successNum, failNum } = this.props

    return (
      <>
        <Row gutter={12}>
          <Col span={12}>
            <Row gutter={12}>
              <Col span={8} style={{ color: 'rgba(0, 0, 0, 0.8)' }}>
                <div style={{ fontWeight: '600', marginBottom: '4px' }}>总共</div>
                <div style={{ fontSize: '18px' }}>{totalNum}</div>
              </Col>
              <Col span={8} style={{ color: '#52c41a' }}>
                <div style={{ fontWeight: '600', marginBottom: '4px' }}>匹配成功</div>
                <div style={{ fontSize: '18px' }}>{successNum}</div>
              </Col>
              <Col span={8} style={{ color: '#f5222d' }}>
                <div style={{ fontWeight: '600', marginBottom: '4px' }}>需要修正</div>
                <div style={{ fontSize: '18px' }}>{failNum}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }
}
