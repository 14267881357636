import Ajax from '../utils/ajax'

export default {
  // 成员管理-获取员工列表
  getPersonList (params) {
    return Ajax.post('/api/employee/list/page/user/info', params)
  },

  // 成员管理-新增员工
  saveAddPerson (params) {
    return Ajax.post('/api/employee/add/user', params)
  },

  // 成员管理-编辑员工
  saveEditPerson (params) {
    return Ajax.post('/api/employee/update/user', params)
  },

  // 成员管理-更新员工账户状态
  updatePersonStatus (params) {
    return Ajax.post('/api/employee/update/user/status', params)
  },

  // 成员管理-重置员工密码
  submitResetPassword (params) {
    return Ajax.post('/api/employee/resetEmployeeAccount', params)
  },

  // 成员管理-获取员工账户详情
  getPersonDetail (params) {
    return Ajax.post('/api/employee/get/user/detail', params)
  },

  // 角色管理- 获取角色列表
  getRoleList (params) {
    return Ajax.post('/api/employee/list/page/role/info', params)
  },

  // 角色管理- 保存角色权限
  saveRoleAuthor (params) {
    return Ajax.post('/api/employee/update/role', params)
  },

  // 角色管理- 更新员工角色状态
  updateRoleStatus (params) {
    return Ajax.post('/api/employee/update/role/status', params)
  },

  // 角色管理- 获取员工角色详情
  getRoleDetail (params) {
    return Ajax.post('/api/employee/get/role/detail', params)
  },

  // 角色管理 获取员工列表
  getMemberList (params) {
    return Ajax.post('/api/employee/list/page/user/role', params)
  },

  // 设置员工角色
  setPersonAuthor (params) {
    return Ajax.post('/api/employee/update/user/role', params)
  },

  // 获取销售人员列表
  getSellerList (params) {
    return Ajax.post('/api/guest/list/page/user/info', params)
  },

  // 获取菜单树
  getMenuListForRole (params) {
    return Ajax.get('/api/menu/list/allMenuTree', { params })
  },

  // 员工管理-新增角色
  addRoleAuthor (params) {
    return Ajax.post('/api/employee/add/role', params)
  }
}
