import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Drawer, Button } from 'antd'
import $lodash from 'lodash'

import EnquirePreviewTable from '../../components/enquirePreviewTable/index'
import Remarks from '../../components/remarks/index'

import consts from '@/common/utils/consts'
import styles from './index.module.scss'

const { ENQUIRE_TYPE } = consts

class PreviewDrawer extends Component {
  static propTypes = {
    editEnquireDetails: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {}

  // 点击发布询价
  handleSave = () => {
    const { editEnquireDetails = {}, onConfirm } = this.props
    const { distributorId, remarks, autoEnquireProducts = [], manualEnquireProducts = [] } = editEnquireDetails
    const newdatas = $lodash.filter([...autoEnquireProducts, ...manualEnquireProducts], (o) => {
      return o.brandName || o.model || o.itemNumber || o.name || o.unit || o.quantity || o.expectPrice || o.expectDiscount || o.expectDeliveryPeriodDays
    })

    const params = {
      distributorId: distributorId,
      remarks: remarks,
      enquiryOrderDetails: newdatas,
      quotationNumber: 1,
      enquiryType: ENQUIRE_TYPE,
      enquiryState: 'NO'
    }

    onConfirm(params)
  }

  render () {
    const { editEnquireDetails = {}, visible = false, onCancel } = this.props
    const { autoEnquireProducts = [], manualEnquireProducts = [], remarks = '' } = editEnquireDetails

    const newdatas = $lodash.filter([...autoEnquireProducts, ...manualEnquireProducts], (o) => {
      return o.brandName || o.model || o.itemNumber || o.name || o.unit || o.quantity || o.expectPrice || o.expectDiscount || o.expectDeliveryPeriodDays
    })

    return (
      <Drawer width={1200} bodyStyle={{ paddingBottom: '80px' }}
        visible={visible}
        placement='right'
        closable={false}
        onClose={() => onCancel()}
      >
        <div>
          <div style={{ marginBottom: '15px' }}>
            <EnquirePreviewTable list={newdatas} />
          </div>

          <Remarks remarks={[{ remarks: remarks }]} />
        </div>

        <div className={`${styles.btns}`}>
          <Button onClick={() => onCancel()}>修正询价</Button>

          <Button type='primary' disabled={newdatas.length === 0} style={{ marginLeft: '16px' }} onClick={() => this.handleSave()}>发布询价</Button>
        </div>
      </Drawer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    editEnquireDetails: state.editEnquireDetails
  }
}

export default connect(mapStateToProps)(PreviewDrawer)
