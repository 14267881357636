import React, { Component } from 'react'
import { Row, Alert, Descriptions } from 'antd'
import PropTypes from 'prop-types'
import styles from './descriptions.module.scss'
// import config from '@/common/utils/config'
// const { h5host } = config

class descriptions extends Component {
  static propTypes = {
    summary: PropTypes.object

  }
  render () {
    const { summary } = this.props

    return (
      <div style={{ marginBottom: '15px' }} >
        <Row>
          <Alert type='info'
            message={
              <Descriptions className={styles.dec}>
                <Descriptions.Item label='订单总金额'>
                  { +summary.payableAmount ? <div>&yen;{(+summary.payableAmount * 100 / 100).toFixed(2)}</div> : 0 }
                </Descriptions.Item>
                <Descriptions.Item label='已付总金额'>
                  { +summary.paidAmount ? <div>&yen;{(+summary.paidAmount * 100 / 100).toFixed(2)}</div> : 0 }
                </Descriptions.Item>
                <Descriptions.Item label='未付总金额'>
                  { +summary.payableAmount ? <div>&yen;{((+summary.payableAmount) - (+summary.paidAmount) * 100 / 100).toFixed(2)}</div> : 0 }
                </Descriptions.Item>
              </Descriptions>
            }
          />
        </Row>
      </div>
    )
  }
}

export default descriptions
