import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Badge } from 'antd'

import styles from './index.module.scss'

export default function GoodsPriceItem (props) {
  const { list = [], commoditySkuId, skuGoodsType, onChangeChecked } = props

  return (
    <ul className={`${styles.goodsPriceWrap}`}>
      {
        list.map((item, i) => (
          <li className={`${styles.goodsItemContent}`} key={`${commoditySkuId}-${skuGoodsType}-${item.price}-${i}`}>
            <Checkbox checked={item.checked} onChange={(e) => onChangeChecked(e.target.checked, i)} />
            <p className={`${styles.inline} ${styles.priceCol}`}>
              {
                +item.price > 0 ? <span>&yen;{ +item.price }</span> : <span>面议</span>
              }

              { item.special ? <Badge count={'特'} style={{ marginTop: '-4px', marginLeft: '4px' }} /> : null }
            </p>

            {
              skuGoodsType === 'CLOUD_FUTURES'
                ? <>
                  <p className={`${styles.inline} ${styles.stockCol}`}><label className={`label ${styles.label}`}>货期</label>{item.deliveryPeriodDays}工作日</p>
                  <p className={`${styles.inline} ${styles.stockCol}`}><label className={`label ${styles.label}`}>预付比例</label>{(+item.prepaymentRatio * 100 / 100).toFixed(2)}%</p>
                </>
                : <p className={`${styles.inline} ${styles.stockCol}`}><label className={`label ${styles.label}`}>库存</label>{+item.stock}</p>
            }
          </li>
        ))
      }
    </ul>
  )
}

GoodsPriceItem.propTypes = {
  list: PropTypes.array,
  commoditySkuId: PropTypes.number,
  skuGoodsType: PropTypes.string,
  onChangeChecked: PropTypes.func
}
