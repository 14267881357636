import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Upload, Icon, message } from 'antd'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'

import styles from './setfloor.module.scss'

const { uploadOrdinaryImageUrl } = UploadApi

const SIZE = 2
const imgSizeTips = [
  { type: 1, tipText: `图片宽为400px, 图片大小不超过${SIZE}MB` },
  { type: 2, tipText: `图片宽为1200px, 图片大小不超过${SIZE}MB` },
  { type: 3, tipText: `图片尺寸为 240px * 620px, 图片大小不超过${SIZE}MB` },
  { type: 4, tipText: `图片尺寸为 400px * 400px, 图片大小不超过${SIZE}MB` }
]

class SetfloorModal extends Component {
  static propTypes = {
    type: PropTypes.number,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    editInfo: {}
  }

  componentDidMount () {
    const { type = 1 } = this.props
    if (type === 2) {
      this.getNavFloorContainer()
    }
  }

  // 获取楼层内容
  getNavFloorContainer = () => {
    const { detailInfo = {} } = this.props
    Api.getNavFloorContainer({ id: detailInfo.id }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          editInfo: data
        })
      }
    })
  }

  // 点击确定
  handleConfirm = () => {
    const { editInfo = {} } = this.state

    if (!editInfo.imageUrl || editInfo.imageUrl === '') {
      return message.warning('请上传图片！')
    }

    let arr = [
      {
        imageUrl: editInfo.imageUrl,
        type: 6
      }
    ]

    this.props.onConfirm({ id: editInfo.id, elementList: arr })
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < 1
    if (!isLt1M) {
      message.warning('图片大小不可超过 1MB！')
    }

    return isLt1M
  }

  // 上传图片
  handleChangeUpload = (info, i) => {
    if (info.file.status === 'done') {
      let { editInfo } = this.state
      const { data } = info.file.response

      editInfo.imageUrl = data.imageUrl

      this.setState({
        editInfo
      })
    }
  }

  // 删除图片
  handleRemoveImage = () => {
    let { editInfo } = this.state

    editInfo.imageUrl = ''

    this.setState({
      editInfo
    })
  }

  // 上传图片文字提示
  renderImgSizeTips = (type) => {
    const obj = imgSizeTips.find(item => { return item.type === type })
    const text = obj !== undefined ? obj.tipText : ''

    return text
  }

  render () {
    const { editInfo = {} } = this.state

    return (
      <div>
        <Modal title='编辑楼层' bodyStyle={{ padding: '12px' }}
          width={800}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >

          <div style={{ color: 'rgb(229, 0, 79)' }}>
            建议：{this.renderImgSizeTips(2)}
          </div>

          <Row className={`${styles.editRow}`}>
            <div className={styles.uploadArea}>
              {
                editInfo.imageUrl && editInfo.imageUrl !== ''
                  ? (
                    <div className={styles.thumbWrap}>
                      <div className={styles.thumbInfo}>
                        <img src={editInfo.imageUrl} />

                        <a className={styles.prewModal}>
                          <Icon type='delete' style={{ fontSize: '20px' }}
                            onClick={() => this.handleRemoveImage()}
                          />
                        </a>
                      </div>
                    </div>
                  )
                  : (
                    <Upload
                      showUploadList={false}
                      listType='picture-card'
                      accept='image/png, image/jpg, image/jpeg'
                      headers={{ Token: getStore('token') }}
                      action={uploadOrdinaryImageUrl}
                      beforeUpload={this.beforeUpload}
                      onChange={(info) => this.handleChangeUpload(info)}
                    >
                      <div>
                        <Icon type='plus' />
                        <div style={{ fontSize: '12px' }}>点击上传</div>
                      </div>

                    </Upload>
                  )
              }
            </div>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default SetfloorModal
