import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input } from 'antd'

const FormItem = Form.Item

class CreateArea extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {

  }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type = 1, detailInfo = {} } = this.props
        if (type === 2) {
          values.pageContainerId = detailInfo.id
        }

        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 }
    }

    const { type = 1, detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form

    return (
      <div>
        <Modal title={`${type === 1 ? '新增专区' : '编辑专区'}`}
          visible
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Form {...formItemLayout}>
            <FormItem label='专区名称'>
              {
                getFieldDecorator('pageContainerName', {
                  initialValue: detailInfo.name || '',
                  rules: [
                    { required: true, message: '该项必填' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </div>
    )
  }
}

const CreateareaModal = Form.create()(CreateArea)

export default CreateareaModal
