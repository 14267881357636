import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'

import Swiper from 'swiper/dist/js/swiper.min.js'

import 'swiper/dist/css/swiper.min.css'
import styles from './previewSwiper.module.scss'

let mySwiper = null

export default class PreviewSwiper extends Component {
  static propTypes = {
    list: PropTypes.array
  }
  state = {
    size: 1,
    curIndex: 0,
    curSize: 1,
    showMagnifier: false,
    left: 0,
    top: 0
  }

  smallbox = null
  magnifier = null
  bigimg = null

  componentDidMount () {
    let len = this.props.list.length
    let num = Math.ceil(len / 4) || 1

    this.setState({
      size: num
    }, () => {
      this.initSwiper()
    })
  }

  initSwiper = () => {
    const { size = 1 } = this.state

    /* eslint-disable */
    mySwiper = new Swiper('#galleryThumbs', {
      slidesPerView: 4,
      spaceBetween: 5,
      slidesPerGroup: size,
    })
    /* eslint-enable */
  }

  // 点击缩略图
  handleSelectedThumbnail = (i) => {
    this.setState({
      curIndex: i
    })
  }

  // 点击下一页
  handleSwiperNext = () => {
    const { size = 1, curSize = 1 } = this.state

    if (curSize === size) {
      return false
    }

    this.setState({
      curSize: curSize + 1
    }, () => {
      mySwiper.slideTo(this.state.curSize, 500, false)
    })
  }

  // 点击上一页
  handleSwiperPrev = () => {
    const { curSize = 1 } = this.state

    if (curSize === 1) {
      return false
    }

    this.setState({
      curSize: curSize - 1
    }, () => {
      mySwiper.slideTo(this.state.curSize, 500, false)
    })
  }

  // 鼠标移入
  handleMouseEnter = () => {
    this.setState({
      showMagnifier: true
    })
  }

  // 鼠标移出
  handleMouseLeave = () => {
    this.setState({
      showMagnifier: false,
      top: 0,
      left: 0,
      zoomLeft: 0,
      zoomTop: 0
    })
  }

  // 鼠标移动
  handleMouseMove = (event) => {
    let pinX = event.clientX - this.smallbox.offsetParent.offsetLeft - this.magnifier.offsetWidth / 2
    let pinY = event.clientY - this.smallbox.offsetParent.offsetTop - this.magnifier.offsetHeight / 2

    if (pinX < 0) {
      pinX = 0
    }

    if (pinX >= this.smallbox.offsetWidth - this.magnifier.offsetWidth) {
      pinX = this.smallbox.offsetWidth - this.magnifier.offsetWidth
    }

    if (pinY < 0) {
      pinY = 0
    }

    if (pinY >= this.smallbox.offsetHeight - this.magnifier.offsetHeight) {
      pinY = this.smallbox.offsetHeight - this.magnifier.offsetHeight
    }

    this.setState({
      left: pinX,
      top: pinY,
      zoomLeft: -pinX / (this.smallbox.offsetWidth / this.bigimg.offsetWidth),
      zoomTop: -pinY / (this.smallbox.offsetHeight / this.bigimg.offsetHeight)
    })
  }

  render () {
    const { list } = this.props
    const { curIndex = 0, showMagnifier = false, left = 0, top = 0, zoomLeft = 0, zoomTop = 0 } = this.state

    return (
      <div>
        <div className={`${styles.preview}`}>
          <div className={`${styles.previewZoom}`} ref={el => { this.smallbox = el }}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            onMouseMove={this.handleMouseMove}
          >
            <img className={`${styles.previewPic}`} src={list[curIndex]} />

            <div className={`${styles.magnifier} ${!showMagnifier ? styles.none : ''}`}
              ref={(el) => { this.magnifier = el }}
              style={{ left: `${left}px`, top: `${top}px` }}
            />
          </div>

          <div className={`${styles.zoomBox} ${!showMagnifier ? styles.none : ''}`}>
            <img className={`${styles.zoompic}`}
              ref={(el) => { this.bigimg = el }}
              style={{ position: 'absolute', left: `${zoomLeft}px`, top: `${zoomTop}px` }}
              src={list[curIndex]}
            />
          </div>
        </div>

        <div className={`${styles.swiperLand}`}>
          <div className={`swiper-container ${styles.galleryThumbs}`} id='galleryThumbs'>
            <div className={`swiper-wrapper`}>
              {
                list.map((item, i) => (
                  <div className={`swiper-slide ${styles.picSlide}`} key={`slide-${i}`}
                    onClick={() => this.handleSelectedThumbnail(i)}
                  >
                    <a className={`${styles.imgBox} ${i === curIndex ? styles.cur : ''}`}>
                      <img className={`${styles.slideImg}`} src={item} />
                    </a>
                  </div>
                ))
              }
            </div>
          </div>

          <a className={`${styles.swiperNext}`} onClick={() => this.handleSwiperNext()}>
            <Icon type='right' style={{ fontSize: '22px' }} />
          </a>
          <a className={`${styles.swiperPrev}`} onClick={() => this.handleSwiperPrev()}>
            <Icon type='left' style={{ fontSize: '22px' }} />
          </a>
        </div>
      </div>
    )
  }
}
