import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Input, Divider, Button, Tag, message } from 'antd'
import $lodash from 'lodash'

import Api from '@/common/api/index'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

import styles from './index.module.scss'

const correctTitleDescribeMaps = {
  ERROR: '您输入的产品订货号/型号可能有误',
  CHANGE_CHANNEL: '您输入的产品订货号/型号有其他渠道'
}
const skuGoodsTypeClassMaps = {
  'SPOT': styles.spotTag,
  'FUTURES': styles.futuresTag,
  'CLOUD_SPOT': styles.cloudTag,
  'CLOUD_FUTURES': styles.futuresTag
}

export default class CorrectModal extends Component {
  constructor (props) {
    super(props)
    this.handleChangeInputSearch = $lodash.debounce(this.handleChangeInputSearch, 300)
  }
  static propTypes = {
    query: PropTypes.object, // 其他参数
    searchResultEnum: PropTypes.string, // ERROR:纠错, CHANGE_CHANNEL:更换渠道
    keyword: PropTypes.string,
    correctSource: PropTypes.string, // 纠错来源 ENQUIRE_CORRECT:询价纠错, ORDER_CORRECT:下单纠错
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    tableData: []
  }

  componentDidMount () {
    const { keyword } = this.props
    this.handleChangeInputSearch(keyword)
  }

  // 手工订单纠错- 获取可添加的产品列表
  getAmendedSkus = async (value) => {
    const { query = {} } = this.props
    const res = await Api.getAmendedSkus({ keyword: value, pageNo: 1, pageSize: 10, ...query })
    const { code, data } = res
    if (+code === 10000) {
      if (data && data.productList && data.productList.length > 0) {
        data.productList.map((item, index) => {
          item['quickOrderSearchResult'] = 'SUCCESS'
          item['supplyForEuList'] = this.setLoginPriceCellData(item)
        })
      }
      this.setState({
        tableData: data.productList || []
      })
    } else {
      message.error(res.message)
    }
  }

  // 手工订单纠错- 获取可更换渠道的产品列表
  getBatchSearchSku = async (value) => {
    const { query = {} } = this.props
    const params = {
      unitList: [
        { itemNumberModel: value, quantity: 1 }
      ],
      needSpot: true,
      needFutures: true,
      needCloudSpot: true,
      needCloudFutures: true,
      ...query
    }
    const res = await Api.getBatchSearchSku(params)
    const { code, data } = res

    if (+code === 10000) {
      this.setState({
        tableData: data || []
      })
    } else {
      message.error(res.message)
    }
  }

  // 监听输入框输入
  handleChangeInputSearch = (value) => {
    const { searchResultEnum } = this.props
    if (searchResultEnum === 'ERROR') {
      this.getAmendedSkus(value)
    } else {
      this.getBatchSearchSku(value)
    }
  }

  // 设置数据格式
  setLoginPriceCellData = (record) => {
    const { supplyInfoList = [] } = record
    if (supplyInfoList && supplyInfoList.length > 0) {
      supplyInfoList[supplyInfoList.length - 1]['checked'] = true
      supplyInfoList[supplyInfoList.length - 1]['quantity'] = 1
    }

    return supplyInfoList
  }

  render () {
    const { searchResultEnum, keyword, correctSource, onCancel, onConfirm } = this.props
    const { tableData = [] } = this.state

    return (
      <>
        <Modal title={correctTitleDescribeMaps[searchResultEnum]} bodyStyle={{ padding: '16px' }}
          width={560}
          visible
          onCancel={() => onCancel()}
          footer={null}
        >
          {
            searchResultEnum === 'ERROR'
              ? <div className={`${styles.searchLand}`}>
                <Input placeholder='请输入...' autoComplete='off' defaultValue={keyword}
                  onChange={(e) => this.handleChangeInputSearch(e.target.value)}
                />
              </div>
              : null
          }

          <div className={`${styles.resultLand}`}>
            <ul style={{ marginBottom: '0px', padding: '0px' }}>
              {
                tableData.map((sku, i) => (
                  <li className={`${styles.resultRow}`} key={`waitSelected-${i}`}>
                    <div className={`${styles.resultContainer}`}>
                      <span className={`${styles.brandTag}`}>{sku.brandName}</span>

                      <span className={`${styles.tag} ${skuGoodsTypeClassMaps[sku.skuGoodsType]}`}>{renderSkuGoodsTypeLabel(sku.skuGoodsType, 'aliasName')}</span>

                      <span dangerouslySetInnerHTML={{ __html: correctSource === 'ENQUIRE_CORRECT' ? sku.itemNumberHighlight : sku.itemNumber }} />

                      <Divider type='vertical' style={{ backgroundColor: '#999999' }} />

                      <span dangerouslySetInnerHTML={{ __html: correctSource === 'ENQUIRE_CORRECT' ? sku.modelHighlight : sku.model }} />
                    </div>
                    <div className={`${styles.resultBtns}`}>
                      { sku.modelExactMatch || sku.itemNumberExactMatch ? <Tag color='#01AA50'>完全匹配</Tag> : null }

                      <Button type='danger' size='small' onClick={() => onConfirm(sku)}>确定</Button>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </Modal>
      </>
    )
  }
}
