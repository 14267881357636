import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Row, Menu, Icon } from 'antd'

import styles from './aside.module.scss'

const { SubMenu } = Menu

class SideMenu extends Component {
  static propTypes = {
    history: PropTypes.object,
    data: PropTypes.array.isRequired
  }

  state = {
    openKeys: []
  }

  componentDidMount () {
    const { history, data } = this.props
    let curPath = history.location.pathname

    data.forEach((item) => {
      item.children.forEach(sitem => {
        if (sitem.path === curPath) {
          this.setState({
            openKeys: [item.code],
            selectedKeys: [curPath]
          })
        }
      })
    })
  }

  // 点击跳转路由
  handleJumpLink = (item) => {
    this.setState({
      selectedKeys: [item.path]
    }, () => {
      if (item.code === 'erp_gjp') {
        let hostArr = window.location.host.split('.')
        hostArr[0] = 'erp'
        let outUrl = PATH_ENV === 'dev' ? `http://gjp.iiasaas.com/cmc9/LogOn.gspx` : `http://${hostArr.join('.')}/cmc9/LogOn.gspx`
        window.open(outUrl, '_blank')
      } else {
        this.props.history.push(item.path)
      }
    })
  }

  // 展开/关闭 SubMenu
  onOpenChange = (keys) => {
    const { data } = this.props
    const { openKeys } = this.state

    let lastkeys = keys.find(key => { return openKeys.indexOf(key) === -1 })

    let i = data.findIndex(item => { return item.code === lastkeys })

    if (i === -1) {
      this.setState({
        openKeys: keys
      })
    } else {
      this.setState({
        openKeys: lastkeys ? [lastkeys] : []
      })
    }
  }

  render () {
    const { data = [] } = this.props
    const { openKeys, selectedKeys } = this.state

    return (
      <Row className={`${styles.asideWrap}`}>
        <Row className={`${styles.asideLogo}`} />
        <Row className={`${styles.asideContent}`}>
          <Menu mode='inline' theme='dark'
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            onOpenChange={this.onOpenChange}
          >
            {
              data.map((menu, index) => (
                <SubMenu key={menu.code}
                  title={<span><Icon type={menu.icon} /><span>{menu.menuName}</span></span>}
                >
                  {
                    menu.children.map((item, i) => (
                      <Menu.Item key={item.path} onClick={() => this.handleJumpLink(item)}>{item.menuName}</Menu.Item>
                    ))
                  }
                </SubMenu>
              ))
            }
          </Menu>
        </Row>

        <Row className={`${styles.asideLinks}`} />

      </Row>
    )
  }
}

export default withRouter(SideMenu)
