import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Row, Upload, Button, Icon, Input, message, Tooltip } from 'antd'

import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'

import styles from './supply.module.scss'

const FormItem = Form.Item
const { uploadOrdinaryImageUrl } = UploadApi

class ApplyForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {}

  componentDidMount () { }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo } = this.props
        this.props.onConfirm({ brandId: detailInfo.brandId, ...values })
      }
    })
  }

  // 上传代理证图片
  handleChangeUpload = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response

      if (+code === 10000) {
        message.success('上传成功')
        setTimeout(() => {
          this.props.form.setFieldsValue({
            agencyUrl: data.imageUrl
          })
        }, 20)
      } else {
        message.error('上传文件失败')
      }
    }
  }

  render () {
    const { getFieldDecorator } = this.props.form

    return (
      <>
        <Modal title='代理申请' bodyStyle={{ padding: '15px 24px' }}
          visible
          maskClosable
          onCancel={() => this.props.onCancel()}
          onOk={() => this.handleConfirm()}
        >
          <Row>
            <Form>
              <FormItem>
                <Row className={`${styles.confirmTips}`}>
                  <Icon type='question-circle' style={{ fontSize: '20px', color: '#faad14', marginRight: '10px' }} />
                  <span>确定为该品牌申请云供应？</span>
                </Row>
              </FormItem>

              <FormItem style={{ marginBottom: '0px' }}>
                <Row className={`${styles.formContent}`}>
                  <Row className={`${styles.formitemRow}`}>
                    <FormItem label='代理证' style={{ marginBottom: '0px' }}>
                      <Upload name='file'
                        showUploadList={false}
                        accept='image/png, image/jpg, image/jpeg'
                        headers={{ Token: getStore('token') }}
                        action={uploadOrdinaryImageUrl}
                        onChange={(info) => this.handleChangeUpload(info)}
                      >
                        <Button><Icon type='upload' />点击上传</Button>
                      </Upload>
                    </FormItem>
                  </Row>

                  <Row className={`${styles.formitemRow}`}>
                    <FormItem style={{ marginBottom: '0px', visibility: 'hidden' }}>
                      {
                        getFieldDecorator('agencyUrl', {
                          rules: [
                            { required: false, message: '请上传代理证图片' }
                          ]
                        })(
                          <Input autoComplete='off' disabled />
                        )
                      }
                    </FormItem>
                  </Row>
                </Row>
              </FormItem>

              {
                this.props.form.getFieldValue('agencyUrl') !== undefined && this.props.form.getFieldValue('agencyUrl') !== ''
                  ? <Row className={`${styles.linkTips}`}>
                    <span className={`${styles.leftText}`}>点击</span>
                    <Tooltip title={this.props.form.getFieldValue('agencyUrl')}>
                      <a className={`${styles.linkContent}`} href={this.props.form.getFieldValue('agencyUrl')} target='_blank'>
                        {this.props.form.getFieldValue('agencyUrl')}
                      </a>
                    </Tooltip>

                    <span className={`${styles.rightText}`}>查看图片</span>
                  </Row>
                  : null
              }

            </Form>
          </Row>
        </Modal>
      </>
    )
  }
}

const ApplyModal = Form.create()(ApplyForm)

export default ApplyModal
