import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, Form, Select, Row } from 'antd'

const FormItem = Form.Item
const Option = Select.Option

class RoleForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    type: PropTypes.number,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    getRoleList: PropTypes.func
  }
  state = {
    roleList: []
  }

  componentDidMount () {
    this.getAllRoles()
  }

  getAllRoles = () => {
    let params = {
      pageNo: 1,
      pageSize: 99999
    }
    this.props.getRoleList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let arr = []

        if (data && data.list && data.list.length > 0) {
          arr = data.list.filter(item => {
            return item.status !== 'FREEZE'
          })
        }

        this.setState({
          roleList: arr
        })
      }
    })
  }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo } = this.props

        values.supplierUserId = detailInfo.supplierUserId

        this.props.onConfirm(values)
      }
    })
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  renderinitiaRoles = (roles = []) => {
    let arr = []
    if (roles.length > 0) {
      roles.map(item => {
        arr.push(item.roleId)
      })
    }

    return arr
  }

  render () {
    const { detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form
    const { roleList = [] } = this.state
    const { roleInfoList = [] } = detailInfo

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 16 }
    }

    return (
      <div>
        <Modal title={'角色设置'}
          visible
          onOk={this.handleConfirm}
          onCancel={this.handleCancel}
        >
          <Row style={{ marginBottom: '24px' }}>
            <label>员工姓名：{detailInfo.realName}</label>
          </Row>
          <Form {...formItemLayout}>
            <FormItem label='选择角色'>
              {
                getFieldDecorator('roleIdList', {
                  initialValue: roleInfoList && roleInfoList.length > 0 ? this.renderinitiaRoles(detailInfo.roleInfoList) : []
                })(
                  <Select mode='multiple'>
                    {
                      roleList.map((item, i) => (
                        <Option value={item.roleId} key={`roleOpt-${i}`}>{item.roleName}</Option>
                      ))
                    }
                  </Select>
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </div>
    )
  }
}

const RoleModal = Form.create()(RoleForm)

export default RoleModal
