import Ajax from '../utils/ajax'

export default {
  // 获取ERP IP白名单列表
  getErpIpWhiteList (params) {
    return Ajax.post('/api/erp/ip/white/list/listPage', params)
  },

  // 新增ERP IP白名单
  saveAddErpIpWhiteItem (params) {
    return Ajax.post('/api/erp/ip/white/list/add', params)
  },

  // 删除ERP IP白名单
  deleteErpIpWhiteItem (params) {
    return Ajax.post('/api/erp/ip/white/list/delete', params)
  }
}
