/**
 * 价格模块
 */
import StockPriceSetting from '@/pages/price/price-setting/index' // 现货价格设置
import FuturesPrice from './../pages/price/futuresPrice-setting/index' // 期货价格设置
import CommonGroupList from '@/pages/price/commonGroupList/index' // 通用价格组列表
import GuestGroupList from '@/pages/price/guestGroupList/index' // 客户价格组列表
import CommonGroupDetail from '@/pages/price/commonGroupDetail/index' // 通用价格组详情设置
import GuestGroupDetail from '@/pages/price/guestGroupDetail/index' // 客户价格组详情

import EuquoteList from '@/pages/price/euquote-set/index' // eu客户报价单列表
import EushelvesList from '@/pages/price/eushelves-list/index' // eu客户BOM单列表
import EushelvesDetail from '@/pages/price/eushelves-detail/index' // eu客户BOM单详情
import PriceVideo from '@/pages/price/price-video/index' // 价格帮助视频

export default [
  {
    path: '/admin/priceSetting',
    name: '现货价格设置',
    component: StockPriceSetting
  },
  {
    path: '/admin/FuturesPriceSetting',
    name: '期货价格设置',
    component: FuturesPrice
  },
  {
    path: '/admin/commonGroupList',
    name: '通用价格组',
    component: CommonGroupList
  },
  {
    path: '/admin/guestGroupList',
    name: '客户价格组',
    component: GuestGroupList
  },
  {
    path: '/admin/commonGroupDetail',
    name: '通用价格组详情',
    component: CommonGroupDetail
  },
  {
    path: '/admin/guestGroupDetail',
    name: '客户价格组详情',
    component: GuestGroupDetail
  },
  {
    path: '/admin/euquoteList',
    name: '客户报价单',
    component: EuquoteList
  },
  {
    path: '/admin/eushelvesList',
    name: '客户BOM单列表',
    component: EushelvesList
  },
  {
    path: '/admin/eushelvesDetail',
    name: '客户BOM单详情',
    component: EushelvesDetail
  },
  {
    path: '/admin/priceVideo',
    name: '帮助视频',
    component: PriceVideo
  }
]
