import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, message, Row } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import filter from '@/common/utils/filter'

import styles from './child.module.scss'

const FormItem = Form.Item
const { TextArea } = Input

const { loginnameReg, phoneReg, passReg, emailReg } = filter

class MemberForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    data: PropTypes.object,
    type: PropTypes.number,
    getDetail: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    info: {}
  }

  componentDidMount () {
    const { type = 1 } = this.props
    if (type === 2) {
      this.getMemberInfo()
    }
  }

  getMemberInfo = () => {
    const { data } = this.props
    let params = {
      supplierUserId: data.supplierUserId
    }
    this.props.getDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data || {}
        })
      }
    })
  }

  // 点击确认
  handleConfirm = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { data, type } = this.props

        if (values.password !== values.confirmPassword) {
          return message.warning('两次输入密码不一致')
        }

        if (type === 1) {
          let { password, confirmPassword } = values

          let jsencrypt = new JSEncrypt()
          jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
          values['password'] = jsencrypt.encrypt(password)
          values['confirmPassword'] = jsencrypt.encrypt(confirmPassword)
        }

        if (type === 2) {
          values.supplierUserId = data.supplierUserId
        }

        this.props.onConfirm(values)
      }
    })
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  render () {
    const { data, type } = this.props
    const { getFieldDecorator } = this.props.form
    const { info } = this.state

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 16 }
    }

    return (
      <div>
        <Modal title={type === 1 ? '添加成员' : '编辑成员'}
          visible
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={this.handleCancel}
        >
          <Row style={{ maxHeight: '300px', overflow: 'auto' }}>
            <Form {...formItemLayout}>
              <FormItem label='员工名称'>
                {
                  getFieldDecorator('realName', {
                    initialValue: info && info.realName ? info.realName : '',
                    rules: [
                      { required: true, message: '请您填写员工名称' }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label='手机号码'>
                {
                  getFieldDecorator('phone', {
                    initialValue: info && info.phone ? info.phone : '',
                    getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                    validate: [
                      {
                        trigger: 'onBlur',
                        rules: [
                          {
                            validator (rule, value, callback) {
                              if (value && !phoneReg.test(value)) {
                                /* eslint-disable */
                                callback('手机号码格式不正确')
                                /* eslint-enable */
                              } else {
                                callback()
                              }
                            }
                          }
                        ]
                      },
                      {
                        trigger: ['onChange', 'onBlur'],
                        rules: [{ required: true, message: '请您填写手机号码' }]
                      }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label='邮箱'>
                {
                  getFieldDecorator('email', {
                    initialValue: info && info.email ? info.email : '',
                    getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                    validate: [
                      {
                        trigger: 'onBlur',
                        rules: [
                          {
                            validator (rule, value, callback) {
                              if (value && !emailReg.test(value)) {
                                /* eslint-disable */
                                callback('邮箱格式不正确')
                                /* eslint-enable */
                              } else {
                                callback()
                              }
                            }
                          }
                        ]
                      },
                      {
                        trigger: ['onChange', 'onBlur'],
                        rules: [{ required: false, message: '请您填写邮箱地址' }]
                      }
                    ]
                  })(
                    <Input placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>

              {
                type === 1
                  ? <FormItem label='登录名'
                    extra={<span className={`${styles.formExtra}`}>登录名长度为4-16位字符，只能包含数字,大小写字母,下划线</span>}
                  >
                    {
                      getFieldDecorator('account', {
                        initialValue: info && info.account ? info.account : '',
                        validate: [
                          {
                            trigger: 'onBlur',
                            rules: [
                              {
                                validator (rule, value, callback) {
                                  if (value && !loginnameReg.test(value)) {
                                    /* eslint-disable */
                                    callback('登录名格式不正确')
                                    /* eslint-enable */
                                  } else {
                                    callback()
                                  }
                                }
                              }
                            ]
                          },
                          {
                            trigger: ['onChange', 'onBlur'],
                            rules: [{ required: true, message: '请您填写登录名' }]
                          }
                        ]
                      })(
                        <Input placeholder='请输入...' autoComplete='off' />
                      )
                    }
                  </FormItem>
                  : <FormItem label='登录名'>
                    <div>{info.account}</div>
                  </FormItem>
              }

              {
                type === 1
                  ? <FormItem label='登录密码'
                    extra={<span className={`${styles.formExtra}`}>密码长度为6-12位字符，可包含数字,字母(区分大小写)</span>}
                  >
                    {
                      getFieldDecorator('password', {
                        initialValue: data.password || '',
                        getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                        validate: [
                          {
                            trigger: 'onBlur',
                            rules: [
                              {
                                validator (rule, value, callback) {
                                  if (value && !passReg.test(value)) {
                                    /* eslint-disable */
                                    callback('登录密码格式不正确')
                                    /* eslint-enable */
                                  } else {
                                    callback()
                                  }
                                }
                              }
                            ]
                          },
                          {
                            trigger: ['onChange', 'onBlur'],
                            rules: [{ required: true, message: '请您填写登录密码' }]
                          }
                        ]
                      })(
                        <Input.Password placeholder='请输入...' autoComplete='off' />
                      )
                    }
                  </FormItem>
                  : null
              }

              {
                type === 1
                  ? <FormItem label='确认密码'>
                    {
                      getFieldDecorator('confirmPassword', {
                        initialValue: data.confirmPassword || '',
                        rules: [
                          { required: true, message: '该项必填' }
                        ]
                      })(
                        <Input.Password placeholder='请输入...' autoComplete='off' />
                      )
                    }
                  </FormItem>
                  : null
              }

              <FormItem label='备注信息'>
                {
                  getFieldDecorator('description', {
                    initialValue: data.description || ''
                  })(
                    <TextArea rows={6} placeholder='请输入...' autoComplete='off' />
                  )
                }
              </FormItem>
            </Form>
          </Row>
        </Modal>
      </div>
    )
  }
}

const MemberModal = Form.create()(MemberForm)

export default MemberModal
