import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Form, Input, Upload, Icon, Button, message, Modal } from 'antd'
import $lodash from 'lodash'

import UploadThumbnail from '@/components/uploadThumbnail/index'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'
import filter from '@/common/utils/filter'

import styles from './index.module.scss'

const FormItem = Form.Item
const TextArea = Input.TextArea
const { uploadOrdinaryImageUrl, agreementUpload } = UploadApi
const { uploadImageRules, repLineBreak } = filter

class MallbaseForm extends Component {
  static propTypes = {
    form: PropTypes.object
  }
  state = {
    info: {},
    contracts: [],
    imageError: false
  }

  componentDidMount () {
    this.getMallConfig()
  }

  // 获取商城配置信息
  getMallConfig = async () => {
    const res = await Api.getMallConfig({})
    const { code, data } = res
    if (+code === 10000) {
      const newcontracts = data.contracts && data.contracts.length > 0 ? data.contracts : [{ id: 0, name: null, mobile: null }]

      this.setState({
        info: data || {},
        contracts: newcontracts
      })
    }
  }

  // 保存商城配置
  saveMallConfig = (params) => {
    Api.saveMallConfig(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        Modal.success({
          title: '保存成功',
          content: '请刷新商城页面， 查看效果'
        })
      }
    })
  }

  // 点击保存
  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { textAdvertisement, contractNames = [], contractMobiles = [], contractIds = [] } = values

        const contacts = $lodash.map(contractNames, (item, i) => {
          return {
            name: $lodash.trim(item),
            mobile: $lodash.trim(contractMobiles[i]),
            id: contractIds[i] === 0 ? null : contractIds[i]
          }
        })

        values.contacts = $lodash.filter(contacts, (o) => { return o.name && o.mobile })
        values.textAdvertisement = textAdvertisement !== '' ? repLineBreak(textAdvertisement, '<br>') : ''

        this.saveMallConfig(values)
      }
    })
  }

  // 限制上传图片内容大小
  beforeUploadImage = (file, imgType) => {
    const isLt2M = file.size / 1024 / 1024 < uploadImageRules[imgType].maxSize

    if (!isLt2M) {
      message.warning(`图片大小不可超过 ${uploadImageRules[imgType].maxSize}MB！`)
    }

    return isLt2M
  }

  // 点击上传图片
  handleChangeUpload = (info, fieldKey) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        setTimeout(() => {
          this.props.form.setFieldsValue({ [fieldKey]: data.imageUrl })
        }, 20)
      } else {
        message.warning('上传失败')
      }
    }
  }

  // 点击下载模板
  handleDownLoad = () => {
    const { info } = this.state

    if (!info.userServiceAgreementTemplateUrl || info.userServiceAgreementTemplateUrl === '') {
      return message.warning('暂无模板')
    }

    window.open(info.userServiceAgreementTemplateUrl)
  }

  // 点击删除上传的图片
  handleRemoveUploadPic = (fieldKey) => {
    this.props.form.setFieldsValue({ [fieldKey]: '' })
  }

  // 点击增加联系方式配置栏
  handleAddContractformItem = () => {
    const { contracts = [] } = this.state

    this.setState({
      contracts: [...contracts, { id: 0, name: null, mobile: null }]
    })
  }

  // 点击删除联系方式配置栏
  handleRemoveContractformItem = (index) => {
    const { contracts = [] } = this.state
    $lodash.pullAt(contracts, index)

    this.setState({
      contracts: contracts
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { info = {}, contracts = [] } = this.state

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 10 }
    }

    const formItemLayoutWithOutLabel = {
      wrapperCol: { span: 10, offset: 6 }
    }

    const tailFormItemLayout = {
      wrapperCol: {
        span: 16, offset: 6
      }
    }

    return (
      <>
        <Form>
          <FormItem label='商城名称' {...formItemLayout}
            help={<span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>此处输入值影响页面名称</span>}
          >
            {
              getFieldDecorator('name', {
                initialValue: info.name || '',
                rules: [
                  { required: false, message: '请填写商城名称' }
                ]
              })(
                <Input placeholder='请输入...' autoComplete='off' />
              )
            }
          </FormItem>
          <FormItem label='商城子名称' {...formItemLayout}
            help={<span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>此处输入值影响公告栏上方名称</span>}
          >
            {
              getFieldDecorator('title', {
                initialValue: info.title || ''
              })(
                <Input placeholder='请输入...' autoComplete='off' />
              )
            }
          </FormItem>

          <FormItem style={{ marginBottom: '10px' }}>
            <FormItem style={{ display: 'none', marginBottom: '0px' }}>
              {
                getFieldDecorator('logoImageUrl', {
                  initialValue: info.logoImageUrl || '',
                  rules: [
                    { required: false, message: '请上传商城Logo图片' }
                  ]
                })(
                  <Input autoComplete='off' />
                )
              }
            </FormItem>
            <Row className={`${styles.uploadFormitemRow}`}>
              <FormItem label='商城Logo' {...formItemLayout}
                help={<span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                  {`图片尺寸为 ${uploadImageRules['logoImage'].width}px * ${uploadImageRules['logoImage'].height}px，图片大小不超过${uploadImageRules['logoImage'].maxSize}MB`}
                </span>
                }
              >
                {
                  this.props.form.getFieldValue('logoImageUrl') === undefined || this.props.form.getFieldValue('logoImageUrl') === ''
                    ? <Upload name='file' listType='picture-card' showUploadList={false}
                      accept='image/png, image/jpg, image/jpeg'
                      headers={{ Token: getStore('token') }}
                      action={uploadOrdinaryImageUrl}
                      beforeUpload={(file) => this.beforeUploadImage(file, 'logoImage')}
                      onChange={(info) => this.handleChangeUpload(info, 'logoImageUrl')}
                    >
                      <div>
                        <Icon type='plus' />
                        <div style={{ fontSize: '12px' }}>点击上传</div>
                      </div>
                    </Upload>
                    : <UploadThumbnail
                      imageUrl={this.props.form.getFieldValue('logoImageUrl')}
                      onDelete={() => this.handleRemoveUploadPic('logoImageUrl')}
                    />
                }
              </FormItem>
            </Row>

          </FormItem>

          <FormItem style={{ marginBottom: '10px' }}>
            <FormItem style={{ display: 'none', marginBottom: '0x' }}>
              {
                getFieldDecorator('commonSealImageUrl', {
                  initialValue: info.commonSealImageUrl || '',
                  rules: [
                    { required: false, message: '请上传公司合同章图片' }
                  ]
                })(
                  <Input autoComplete='off' />
                )
              }
            </FormItem>
            <Row className={`${styles.uploadFormitemRow}`}>
              <FormItem label='公司合同章' {...formItemLayout}
                help={<span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                  {`图片尺寸为 ${uploadImageRules['commonSealImage'].width}px * ${uploadImageRules['commonSealImage'].height}px，图片大小不超过${uploadImageRules['commonSealImage'].maxSize}MB，仅支持png格式`}
                </span>
                }
              >
                {
                  this.props.form.getFieldValue('commonSealImageUrl') === undefined || this.props.form.getFieldValue('commonSealImageUrl') === ''
                    ? <Upload name='file' listType='picture-card' showUploadList={false}
                      accept='image/png'
                      headers={{ Token: getStore('token') }}
                      action={uploadOrdinaryImageUrl}
                      beforeUpload={(file) => this.beforeUploadImage(file, 'commonSealImage')}
                      onChange={(info) => this.handleChangeUpload(info, 'commonSealImageUrl')}
                    >
                      <div>
                        <Icon type='plus' />
                        <div style={{ fontSize: '12px' }}>点击上传</div>
                      </div>
                    </Upload>
                    : <UploadThumbnail
                      imageUrl={this.props.form.getFieldValue('commonSealImageUrl')}
                      onDelete={() => this.handleRemoveUploadPic('commonSealImageUrl')}
                    />
                }
              </FormItem>
            </Row>
          </FormItem>

          <>
            {
              contracts.map((item, index) => (
                <FormItem {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                  label={index === 0 ? '联系方式配置' : ''}
                  key={`contractItem-${index}`}
                >
                  <Input.Group compact style={{ width: '86%' }}>
                    {
                      getFieldDecorator(`contractNames[${index}]`, {
                        initialValue: item.name
                      })(<Input placeholder='请输入联系人名称...' autoComplete='off' maxLength={12} style={{ width: '50%' }} />)
                    }

                    {
                      getFieldDecorator(`contractMobiles[${index}]`, {
                        initialValue: item.mobile
                      })(<Input placeholder='请输入联系方式...' autoComplete='off' maxLength={20} style={{ width: '50%' }} />)
                    }

                    {
                      getFieldDecorator(`contractIds[${index}]`, {
                        initialValue: item.id
                      })(<Input placeholder='请输入联系方式...' autoComplete='off' style={{ display: 'none', width: '50%' }} />)
                    }
                  </Input.Group>

                  {
                    contracts.length > 1 ? (
                      <Icon type='minus-circle-o'
                        className={`${styles.dynamicDeleteButton}`}
                        onClick={() => this.handleRemoveContractformItem(index)}
                      />
                    ) : null
                  }
                </FormItem>
              ))
            }

            {
              contracts.length < 5
                ? <FormItem {...formItemLayoutWithOutLabel}>
                  <Button type='dashed' onClick={() => this.handleAddContractformItem()} style={{ width: '86%' }}>
                    <Icon type='plus' />增加联系方式配置
                  </Button>
                </FormItem>
                : null
            }
          </>

          <FormItem label='客服电话' {...formItemLayout}>
            {
              getFieldDecorator('customerPhoneNumber', {
                initialValue: info.customerPhoneNumber || '',
                rules: [
                  { required: false, message: '该项必填' }
                ]
              })(
                <Input placeholder='请输入...' autoComplete='off' />
              )
            }
          </FormItem>

          <FormItem label='客服QQ' {...formItemLayout}>
            {
              getFieldDecorator('customerQqNumber', {
                initialValue: info.customerQqNumber || ''
              })(
                <Input placeholder='请输入...' autoComplete='off' />
              )
            }
          </FormItem>

          <FormItem label='滚动文字广告' {...formItemLayout}
            help={<span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>此处输入值影响左上方滚动栏</span>}
          >
            {
              getFieldDecorator('textAdvertisement', {
                initialValue: info.textAdvertisement || '',
                rules: [
                  { required: false, message: '该项必填' }
                ]
              })(
                <TextArea rows={6} placeholder='请输入...' autoComplete='off' />
              )
            }

          </FormItem>

          <FormItem label='用户服务协议' {...formItemLayout}
            help={<span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>此处上传文件影响注册时要勾选的用户服务协议，请下载右侧协议模板进行参考</span>}
          >
            <FormItem style={{ display: 'inline-block', marginBottom: '0px' }}>
              <Upload name='file' showUploadList={false}
                accept='.doc,.docx'
                headers={{ Token: getStore('token') }}
                action={agreementUpload}
              >
                <Button>
                  <Icon type='upload' />点击上传
                </Button>
              </Upload>
            </FormItem>

            {
              info.userServiceAgreementTemplateUrl && info.userServiceAgreementTemplateUrl !== ''
                ? <FormItem style={{ display: 'inline-block', marginLeft: '24px', marginBottom: '0px' }}>
                  <label className={`${styles.formitemLabel}`}>协议模板</label>
                  <Button onClick={this.handleDownLoad}><Icon type='download' />下载模板</Button>
                </FormItem>
                : null
            }

          </FormItem>

          <FormItem {...tailFormItemLayout}>
            <Button type='primary' onClick={() => this.handleSubmit()}>保存</Button>
          </FormItem>
        </Form>
      </>
    )
  }
}

const Mallbase = Form.create()(MallbaseForm)

export default Mallbase
