import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Row, Button, message, Icon, Pagination, Badge, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import BatchSearchMenu from '@/components/batchSearchMenu/index'
import AlertLand from './alertLand/index'
import OperationCheckModal from './operationCheckModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, productPutOnMap, skuGoodsTypeMaps } = filter
const _skuGoodsTypeMaps = $lodash.filter(skuGoodsTypeMaps, (o) => { return $lodash.includes(o.id, 'CLOUD') })

// 云仓产品列表
export default class CloudLibrary extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'keyword',
        formType: 'Input',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '请输入型号或订货号',
          autoComplete: 'off'
        }
      },
      {
        name: 'classificationId',
        formType: 'Select',
        itemParams: {
          label: '分类'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['classificationId', 'classificationName']
      },
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children',
          onChange: (value) => this.getSeriesList(value)
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      },
      {
        name: 'seriesId',
        formType: 'Select',
        itemParams: {
          label: '系列'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['seriesId', 'seriesName']
      },
      {
        name: 'skuGoodsType',
        formType: 'Select',
        itemParams: {
          label: '产品类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ..._skuGoodsTypeMaps
        ]
      },
      {
        name: 'cloudPurchaseStatus',
        formType: 'Select',
        itemParams: {
          label: '上架状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...productPutOnMap
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    rowDetail: {}, // 当前选择的行数据
    selectedRowKeys: [], // 选择的行数据
    query: {},
    statisticsInfo: {},
    visible: false
  }

  columns = [
    {
      title: '序号',
      key: 'cloudLibraryCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '分类',
      key: 'cloudLibraryCol-1',
      dataIndex: 'classificationName',
      ellipsis: true
    },
    {
      title: '品牌',
      key: 'cloudLibraryCol-2',
      width: 120,
      dataIndex: 'brandName'
    },
    {
      title: '系列',
      key: 'cloudLibraryCol-3',
      dataIndex: 'series',
      ellipsis: true
    },
    {
      title: '型号',
      key: 'cloudLibraryCol-4',
      width: 140,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.model }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.model }} />
          </Tooltip>
        </>
      )
    },
    {
      title: '订货号',
      key: 'cloudLibraryCol-5',
      width: 130,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
          </Tooltip>
        </>
      )
    },
    {
      title: '产品名称',
      key: 'cloudLibraryCol-6',
      width: 150,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.name }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.name }} />
          </Tooltip>
        </>
      )
    },
    {
      title: '产品类型',
      key: 'cloudLibraryCol-7',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '厂家表价',
      key: 'cloudLibraryCol-8',
      dataIndex: 'price',
      render: (text, record, index) => (
        <>&yen;{ +record.price }</>
      )
    },
    {
      title: '云仓价格',
      key: 'cloudLibraryCol-9',
      width: 240,
      render: (text, record, index) => (
        <>
          {
            record.supplierList && record.supplierList.length > 0
              ? <>
                {
                  record.supplierList.map((supptem, suppidnx) => (
                    <div key={`priceCell-${suppidnx}`} style={{ height: '20px', lineHeight: '20px' }}>
                      <span>&yen;{+supptem.platformPrice}</span>

                      { supptem.special ? <Badge count={'特'} style={{ marginTop: '-4px', marginLeft: '4px' }} /> : null }

                      { ['FUTURES', 'CLOUD_FUTURES'].includes(record.skuGoodsType) ? <span style={{ marginLeft: '10px' }}>预付比例：{(+supptem.prepaymentRatio * 100 / 100).toFixed(2)}%</span> : null }
                    </div>
                  ))
                }
              </>
              : '----'
          }
        </>
      )
    },
    {
      title: '货期',
      key: 'cloudLibraryCol-10',
      dataIndex: 'deliveryPeriodDays',
      render: (text, record, index) => {
        const { supplierList = [], skuGoodsType } = record

        return ['FUTURES', 'CLOUD_FUTURES'].includes(skuGoodsType) ? <>
          {
            supplierList && supplierList.map((item, n) => (
              <div key={`${record.commoditySkuId}-${item.deliveryPeriodDays}-${index}-${n}`}>
                { +item.deliveryPeriodDays > 0 ? `${+item.deliveryPeriodDays}个工作日` : '当天发货' }
              </div>
            ))
          }
        </>
          : '当天发货'
      }
    },
    {
      title: '库存数量',
      key: 'cloudLibraryCol-11',
      width: 140,
      render: (text, record, index) => {
        const { skuGoodsType = 'SPOT', supplierList = [] } = record

        return ['FUTURES', 'CLOUD_FUTURES'].includes(skuGoodsType)
          ? (
            <span style={{ fontSize: '16px' }}>&infin;</span>
          )
          : (
            <div>
              {
                supplierList && supplierList.length > 0
                  ? <>
                    {
                      supplierList.map((supptem, suppidnx) => (
                        <Row key={`stockCell-${suppidnx}`} style={{ height: '20px', lineHeight: '20px' }}>{supptem.stock}</Row>
                      ))
                    }
                  </>
                  : '----'
              }
            </div>
          )
      }
    },
    {
      title: '上架状态',
      key: 'cloudLibraryCol-12',
      width: 80,
      render: (text, record, index) => (
        <>
          {
            record.cloudPurchaseStatus === 'ENABLE'
              ? <span style={{ color: '#52C41A' }}>已上架</span>
              : <span style={{ color: '#F5222D' }}>未上架</span>
          }
        </>
      )
    },
    {
      title: '操作',
      key: 'cloudLibraryCol-13',
      width: 150,
      fixed: 'right',
      render: (text, record, index) => (
        <>
          {
            record.cloudPurchaseStatus === 'ENABLE'
              ? <Button type='primary' size='small' onClick={() => this.saveCloudPurchasePutOffShelves(record, ['SPOT', 'CLOUD_SPOT'].includes(record.skuGoodsType) ? 'updateStockStatus' : 'updateFuturesStatus')}>下架</Button>
              : <Button type='primary' size='small' onClick={() => this.saveCloudPurchasePutOnShelves(record, ['SPOT', 'CLOUD_SPOT'].includes(record.skuGoodsType) ? 'updateStockStatus' : 'updateFuturesStatus')}>上架</Button>
          }
          <Link to={{ pathname: '/commodity-detail', search: `?commoditySkuId=${record.commoditySkuId}&skuGoodsType=${record.skuGoodsType}` }} target='_blank' style={{ marginLeft: '8px' }}>
            <Button type='primary' size='small' >产品详情</Button>
          </Link>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getCloudCategoryList()
    this.getCloudBrandList()
    this.getCloudLibraryProductList()
    this.getCloudLibraryStatisticsInfo()
  }

  // 获取分类列表
  getCloudCategoryList = () => {
    Api.getCloudCategoryList({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData = {} } = this.state
        searchData[1].options = data && data.length > 0 ? [{ classificationId: 0, classificationName: '全部' }, ...data] : []

        this.setState({
          searchData: [...searchData]
        })
      }
    })
  }

  // 获取品牌
  getCloudBrandList = () => {
    Api.getCloudBrandList({}).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state
        data.map(item => {
          item.showLabel = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}  ${item.count}` : `${item.brandName}  ${item.count}`
        })

        searchData[2].options = $lodash.concat([{ brandId: 0, showLabel: '全部' }], data)

        this.setState({
          searchData: [...searchData]
        })
      }
    })
  }

  // 获取系列
  getSeriesList = async (brandId) => {
    const res = await Api.getSeriesList({ brandId: brandId, pageNo: 1, pageSize: 999 })
    const { code, data } = res
    if (+code === 10000) {
      let { searchData } = this.state

      searchData[3].options = data && data.list && data.list.length > 0 ? [{ seriesId: 0, seriesName: '全部' }, ...data.list] : []

      this.setState({
        searchData: [...searchData]
      })
    }
  }

  // 获取云仓产品列表
  getCloudLibraryProductList = () => {
    const { pages, query = {} } = this.state

    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize,
      ...query
    }

    Api.getCloudLibraryProductList(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        let _list = data.list || []
        if (_list.length > 0) {
          _list.map(item => {
            if (item.supplyInfoList && item.supplyInfoList.length > 0) {
              let { supplyInfoList = [] } = item
              item['supplierList'] = this.setSupplierListRowSpan(supplyInfoList)
            }
          })
        }

        this.setState({
          tableData: _list,
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取统计信息
  getCloudLibraryStatisticsInfo = async () => {
    const res = await Api.getCloudLibraryStatisticsInfo({})
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        statisticsInfo: data || {}
      })
    }
  }

  // 设置库存详细数据格式
  setSupplierListRowSpan = (supplierList = []) => {
    let _arr = supplierList.reduce((result, item) => {
      if (result.indexOf(item.platformPrice) < 0) {
        result.push(item.platformPrice)
      }
      return result
    }, []).reduce((result, platformPrice) => {
      // 将name相同的数据作为新数组取出，并在其内部添加新字段**rowSpan**
      const children = supplierList.filter(item => item.platformPrice === platformPrice)
      result = result.concat(
        children.map((item, index) => ({
          ...item,
          rowSpan: index === 0 ? children.length : 0 // 将第一行数据添加rowSpan字段
        }))
      )
      return result
    }, [])

    return _arr
  }

  // 云库产品单个上架
  saveCloudPurchasePutOnShelves = async (record, type) => {
    const params = {
      commoditySkuIdList: [record.commoditySkuId],
      [type]: 'ENABLE'
    }

    const res = await Api.saveCloudPurchasePutOnShelves(params)
    const { code } = res
    if (+code === 10000) {
      this.setState({
        operationCheckModal: false,
        selectedRowKeys: [],
        visible: true
      }, () => {
        this.countDown()
      })
    } else {
      message.warning(res.message)
    }
  }

  // 云库产品单个下架
  saveCloudPurchasePutOffShelves = async (record, type) => {
    const params = {
      commoditySkuIdList: [record.commoditySkuId],
      [type]: 'DISABLE'
    }
    const res = await Api.saveCloudPurchasePutOffShelves(params)
    const { code } = res
    if (+code === 10000) {
      this.setState({
        operationCheckModal: false,
        selectedRowKeys: [],
        visible: true
      }, () => {
        this.countDown()
      })
    } else {
      message.warning(res.message)
    }
  }

  // 批量更新上下架
  updateBatchCloudPurchaseStatus = async (params) => {
    const res = await Api.updateBatchCloudPurchaseStatus(params)
    const { code } = res
    if (+code === 10000) {
      this.setState({
        operationCheckModal: false,
        selectedRowKeys: [],
        visible: true
      }, () => {
        this.countDown()
      })
    } else {
      message.warning(res.message)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    const { classificationId, brandId, seriesId } = search

    search.classificationIdList = +classificationId > 0 ? [classificationId] : []
    search.brandIdList = +brandId > 0 ? [brandId] : []
    search.seriesIdList = +seriesId > 0 ? [seriesId] : []

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getCloudLibraryProductList()
    })
  }

  // 点击批量搜索
  handleBatchSearch = (values) => {
    this.props.history.push({ pathname: '/admin/cloudSearchResult', state: { query: values } })
  }

  // 点击勾选
  onSelectChange = (keys, rows) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 点击勾选批量操作
  showCheckPutModal = () => {
    const { selectedRowKeys = [] } = this.state
    if (selectedRowKeys.length === 0) {
      return message.warning('请选择您要操作的产品！')
    }

    this.setState({
      operationCheckModal: true
    })
  }

  // 确定批量操作
  handleConfirmPutAway = (status) => {
    let { selectedRowKeys = [] } = this.state
    const newdatas = $lodash.map(selectedRowKeys, (values) => {
      return {
        commoditySkuId: $lodash.split(values, '-')[0],
        skuGoodsType: $lodash.split(values, '-')[1]
      }
    })

    const commoditySkuIdList = $lodash.map($lodash.filter(newdatas, (o) => { return ['SPOT', 'CLOUD_SPOT'].includes(o.skuGoodsType) }), 'commoditySkuId')
    const futureCommoditySkuIdList = $lodash.map($lodash.filter(newdatas, (o) => { return ['FUTURES', 'CLOUD_FUTURES'].includes(o.skuGoodsType) }), 'commoditySkuId')

    this.updateBatchCloudPurchaseStatus({ commoditySkuIdList: commoditySkuIdList, futureCommoditySkuIdList: futureCommoditySkuIdList, updateStatus: status })
  }

  // 倒计时
  countDown = () => {
    let secondsToGo = 3

    setTimeout(() => {
      this.setState({
        visible: false
      }, () => {
        this.getCloudLibraryProductList()
      })
    }, secondsToGo * 1000)
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      rowDetail: {},
      operationCheckModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCloudLibraryProductList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudLibraryProductList()
    })
  }

  render () {
    const { searchData, pages, tableData, selectedRowKeys = [], statisticsInfo = {}, operationCheckModal = false } = this.state

    // 分页配置
    const pagination = {
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <>
        <div style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <div style={{ marginBottom: '15px' }}>
              <Row style={{ display: 'inline-block', width: '120px', lineHeight: '40px', verticalAlign: 'top' }}>
                <BatchSearchMenu menuConfig={['itemNumberList', 'modelList']}
                  onConfirm={(values) => this.handleBatchSearch(values)}
                />
              </Row>
              <Row style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
                <SearchForm
                  {...this.props}
                  dataSource={searchData}
                  handleSubmit={(obj) => this.handleSearch(obj)}
                />
              </Row>
            </div>

            <AlertLand info={statisticsInfo} />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '15px' }}>
          <div style={{ marginBottom: '12px' }}>
            <Button type='primary' onClick={() => this.showCheckPutModal()}><Icon type='select' />批量操作</Button>
          </div>

          <Table rowKey={(record, i) => { return `${record.commoditySkuId}-${record.skuGoodsType}` }} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
            rowSelection={rowSelection}
            scroll={{ x: 1800 }}
          />
          <div style={{ margin: '16px 0px', textAlign: 'right' }}>
            <Pagination {...pagination} />
          </div>
        </Card>

        {
          operationCheckModal
            ? <OperationCheckModal
              total={selectedRowKeys.length}
              onConfirm={(status) => this.handleConfirmPutAway(status)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}
