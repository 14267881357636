import React, { Component } from 'react'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { Form, Table, InputNumber, Input } from 'antd'

import styles from '../send.module.scss'

const { TextArea } = Input

class SendProductTable extends Component {
  static propTypes = {
    form: PropTypes.object,
    remarks: PropTypes.string,
    isRedispatch: PropTypes.bool, // 是否为重新发货
    tableData: PropTypes.array,
    future: PropTypes.bool, // 是否为期货发货
    onChangeSendingNumber: PropTypes.func
  }
  state = {}
  columns = [
    {
      title: '序号',
      key: 'sendProductCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'sendProductCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'sendProductCol-2',
      ellipsis: true,
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'sendProductCol-3',
      ellipsis: true,
      dataIndex: 'itemNumber'
    },
    {
      title: '订单数量',
      key: 'sendProductCol-4',
      dataIndex: 'quantity'
    },
    {
      title: '已发货数量',
      key: 'sendProductCol-5',
      render: (text, record, index) => {
        const { isRedispatch = false } = this.props
        return <>
          {
            !isRedispatch ? <span>{ record.deliveryQuantity}</span> : '----'
          }
        </>
      }
    },
    {
      title: '已订货数量',
      key: 'sendProductCol-6',
      dataIndex: 'quantity',
      render: (text, record, index) => {
        const { future } = this.props
        return <>
          {
            future ? <span>{ record.arrivalQuantity}</span> : ''
          }
        </>
      }
    },
    {
      title: '发货数量',
      key: 'sendProductCol-7',
      render: (text, record, index) => {
        const { isRedispatch = false, onChangeSendingNumber } = this.props

        return (
          <>
            {
              !isRedispatch
                ? <InputNumber size='small' min={0}
                  value={record.sendNumber}
                  disabled={record.quantity - record.deliveryQuantity === 0}
                  onChange={(val) => onChangeSendingNumber({ value: val, index: index, arrivalQuantity: record.arrivalQuantity, deliveryQuantity: record.deliveryQuantity, quantity: record.quantity })}
                />
                : <span>{record.sendNumber}</span>
            }
          </>
        )
      }
    },
    {
      title: '单位',
      key: 'sendProductCol-8',
      dataIndex: 'unit'
    },
    {
      title: '货物金额',
      key: 'sendProductCol-9',
      render: (text, record, index) => (
        <>&yen;{ ((+record.sendNumber) * (+record.sellingPrice * 100) / 100).toFixed(2) }</>
      )
    }
  ]

  render () {
    const { form, remarks = '', tableData = [], future } = this.props
    const { getFieldDecorator } = form

    let columns = lodash.filter(this.columns, item => { return item.title !== '已订货数量' })

    return (
      <>
        <Table rowKey='itemNumber'
          columns={future === true ? this.columns : columns}
          dataSource={tableData}
          pagination={false}
          footer={() => (
            <Form>
              <div className={`${styles.formitemRow}`}>
                <Form.Item style={{ marginBottom: '0px' }} label='拣货单备注'>
                  {
                    getFieldDecorator('remarks', {
                      initialValue: remarks
                    })(<TextArea placeholder='请输入内容， 不超过200字...' rows={4} maxLength={200} />)
                  }
                </Form.Item>
              </div>
            </Form>
          )}
        />
      </>
    )
  }
}

export default SendProductTable
