import React, { Component } from 'react'
import { Row, Card, Table, message, Badge, Modal, Icon } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import BilldetailCard from './billdetailCard'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'

const { downinsuranceBillByMonth } = DownloadApi
const { billStatusMaps } = filter
const { warning } = Modal

export default class PaninsuranceBill extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'dueMonth',
        formType: 'MonthPicker',
        itemParams: {
          label: '账单日期'
        },
        cptParams: {

        },
        options: []
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '账单状态'
        },
        rules: {
          initialValue: 'ALL'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: billStatusMaps
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20, options: ['10', '20', '50', '100', '200'] },
    billData: [],
    detailData: [],
    dueMonthList: [], // 当前页面账单日期
    query: {
      // status: 'ALL'
    },
    rowDetail: {},
    detailModal: false
  }

  columns = [
    {
      title: '序号',
      key: 'paninsuranceBill-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '账单日期',
      key: 'paninsuranceBill-1',
      dataIndex: 'dueMonth'
    },
    {
      title: '账单状态',
      key: 'paninsuranceBill-2',
      render: (text, record, index) => (
        <div>
          { this.renderInsuranceStatusLabel(record.status) }
        </div>
      )
    },
    {
      title: '应付金额',
      key: 'paninsuranceBill-3',
      dataIndex: 'totalAmount',
      render: (text, record, index) => (
        <>&yen;{ (+record.totalAmount * 100 / 100).toFixed(2) }</>
      )
    },
    {
      title: '已付金额',
      key: 'paninsuranceBill-4',
      dataIndex: 'payAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.payAmount * 100 / 100).toFixed(2)} </>
      )
    },
    {
      title: '未付金额',
      key: 'paninsuranceBill-5',
      render: (text, record, index) => (
        <>&yen;{ ((+record.totalAmount - (+record.payAmount)) * 100 / 100).toFixed(2) }</>
      )
    }
  ]

  componentDidMount () {
    this.getInsuranceBillList()
  }

  // 获取保险账单列表
  getInsuranceBillList = () => {
    const { query = {} } = this.state
    let params = {
      ...query,
      pageNo: 1,
      pageSize: 999
    }

    Api.getInsuranceBillList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let list = data.list || []
        let dueMonthList = []

        if (list.length > 0) {
          list.map((item) => {
            dueMonthList.push(item.dueMonth)
          })
        }

        this.setState({
          billData: data.list || [],
          dueMonthList: dueMonthList
        }, () => {
          if (this.state.dueMonthList.length > 0) {
            this.getInsuranceBillDetail()
          }
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 获取账单明细
  getInsuranceBillDetail = () => {
    const { pages, dueMonthList = [] } = this.state

    let params = {
      dueMonthList: dueMonthList,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getInsuranceBillDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          detailData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    search['dueMonth'] = search.dueMonth ? moment(search.dueMonth).format('YYYYMM') : undefined

    this.setState({
      query: search,
      detailData: [],
      pages: {
        ...pages,
        pageNo: 1,
        total: 0
      }
    }, () => {
      this.getInsuranceBillList()
    })
  }

  // 点击导出月度账单
  handleDownloadBill = () => {
    const { query = {} } = this.state
    if (!query.dueMonth) {
      warning({
        width: 353,
        icon: <Icon type='exclamation-circle' style={{ color: '#F5222D' }} />,
        title: <span style={{ color: '#F5222D' }}>请选择您想导出的月份</span>,
        content: <span>请在搜索栏——账单日期中<br />选择您想导出的日期<br />然后点击搜索</span>
      })

      return false
    }

    downinsuranceBillByMonth({ dueMonth: query.dueMonth })
  }

  // 渲染账单状态
  renderInsuranceStatusLabel = (status) => {
    let label = ''
    let obj = billStatusMaps.find((item) => { return item.id === status })

    if (obj) {
      label = <Badge status={obj.badge} text={<span style={{ fontSize: '12px' }}>{obj.name}</span>} />
    }

    return label
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getInsuranceBillDetail()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getInsuranceBillDetail()
    })
  }

  render () {
    const { pages, searchData, billData = [], detailData = [] } = this.state

    return (
      <div>
        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <Row style={{ marginBottom: '15px' }}>
              <SearchForm {...this.props} dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
            <Row>
              <Table rowKey='id'
                columns={this.columns}
                dataSource={billData}
                pagination={false}
              />
            </Row>
          </Card>
        </Row>

        <BilldetailCard
          onDownload={() => this.handleDownloadBill()}
          tableData={detailData}
          pages={pages}
          pageChange={(page, pageSize) => this.pageChange(page, pageSize)}
          pageChangeSize={(current, size) => this.pageChangeSize(current, size)}
        />
      </div>
    )
  }
}
