import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Button, Icon, Menu, Modal } from 'antd'
import $lodash from 'lodash'

import BatchModal from '@/components/batchsearchModal/index'

const menuitems = [
  { id: 'itemNumberList', name: '订货号批量' },
  { id: 'modelList', name: '型号批量' }
]

export default class BatchSearchMenu extends Component {
  static propTypes = {
    rule: PropTypes.object,
    menuConfig: PropTypes.array,
    onConfirm: PropTypes.func
  }
  state = {
    visible: false,
    batchKey: null
  }

  componentDidMount () { }

  // 点击确定
  handleBatchSearch = (search = {}) => {
    this.props.onConfirm(search)
    this.closeModal()
  }

  // 点击批量搜索弹窗
  showBatchSearchModal = (menuitem) => {
    const { rule } = this.props

    if (rule && !rule.endUserId) {
      Modal.warning({
        width: 353,
        title: rule.message
      })
      return false
    }

    this.setState({
      batchKey: menuitem.key,
      visible: true
    })
  }

  renderDropdownOverlay = (menuConfig = []) => {
    let menus = menuitems
    if (menuConfig.length > 0) {
      menus = []
      menuConfig.map((key) => {
        let obj = $lodash.find(menuitems, (v, i) => { return v.id === key })
        menus.push(obj)
      })
    }

    return (
      <Menu onClick={(item) => this.showBatchSearchModal(item)}>
        {
          menus.map((item) => (
            <Menu.Item key={item.id}>{ item.name}</Menu.Item>
          ))
        }
      </Menu>
    )
  }

  closeModal = () => {
    this.setState({
      visible: false,
      batchKey: null
    })
  }

  render () {
    const { menuConfig = [] } = this.props
    const { visible = false, batchKey = null } = this.state

    return (
      <>
        <Dropdown overlay={this.renderDropdownOverlay(menuConfig)}>
          <Button type='primary'>批量搜索<Icon type='down' /></Button>
        </Dropdown>

        {
          visible
            ? <BatchModal batchKey={batchKey}
              onCancel={() => this.closeModal()}
              onConfirm={(search) => this.handleBatchSearch(search)}
            />
            : null
        }
      </>
    )
  }
}
