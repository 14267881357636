import React from 'react'
import PropTypes from 'prop-types'
// import $lodash from 'lodash'

import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

import styles from './index.module.scss'

function GoodsInfo (props) {
  const { orderType, list = [], goodsCount = 0, goodsTotalAmount = 0, payAmount = 0 } = props

  return (
    <>
      <table className={styles.tabs} width='100%' >
        <thead>
          <tr className={styles.tabsthead}>
            <td className={`${styles.tGoodsimg}`}>{orderType === 'ORDER_CLOUD_FUTURES' ? '期货商品' : '现货商品'}</td>
            <td className={`${styles.tGoodsinfo}`}>商品信息</td>
            <td className={`${styles.tCK}`}>产品类型</td>
            <td className={`${styles.tGoodsprice}`}>单价</td>
            <td className={`${styles.tGoodsnum}`}>数量</td>
            <td className={`${styles.tGoodsmoney}`}>单品小计</td>
            <td className={`${styles.tGoodsOp}`}>实付</td>
          </tr>
        </thead>

        <tbody>
          {
            list.map((record, i) => (
              <tr key={`${record.commoditySkuId}-${record.skuGoodsType}-${i}`}>
                <td>
                  <div className={styles.GoodsImgOne}>
                    <div className={styles.GoodsInfoimg}>
                      <img className={styles.Goodsimgs} src={record.imageUrl} />
                    </div>
                  </div>
                </td>

                <td className={`${styles.Lgoodsinfo}`}>
                  <div className={styles.GoodsInfo}>
                    <div>
                      <span className={styles.borderstyles}><span>{record.brandName}</span></span>
                      <div className={styles.ttitle}>{record.name}</div>
                      <div className={styles.tfont}>型号：{record.model}</div>
                      <div className={styles.tfont}>订货号：{record.itemNumber}</div>
                    </div>
                  </div>
                </td>

                <td className={`${styles.tdminH}`} style={{ textAlign: 'center' }}>
                  {
                    record.quickOrderSearchResult !== 'ERROR'
                      ? <span className={`${styles.bstylesW} ${record.skuGoodsType === 'CLOUD_SPOT' ? styles.borderstyles : styles.borderstylesRed}`}>
                        { renderSkuGoodsTypeLabel(record.skuGoodsType) }
                      </span>
                      : null
                  }
                </td>

                {/* 单价 */}
                <td>
                  {
                    record.supplyInfoList && record.supplyInfoList.length > 0
                      ? <>
                        {
                          record.supplyInfoList.map((item, j) => (
                            <div className={`${styles.LgoodsPrice}`} key={`${record.commoditySkuId}-${record.skuGoodsType}-${item.price}-${j}`}>
                              <p className={styles.LgoodsPriceCell}>&yen;{+item.price}</p>
                              {
                                orderType === 'ORDER_CLOUD_FUTURES'
                                  ? <p className={`${styles.LgoodsPrepaymentRatio}`}><label className='label'>预付比例</label><span>{ (item.prepaymentRatio * 100 / 100).toFixed(2) }%</span></p>
                                  : null
                              }
                            </div>
                          ))
                        }
                      </>
                      : null
                  }
                </td>

                { /* 数量 */ }
                <td>
                  {
                    record.supplyInfoList && record.supplyInfoList.length > 0
                      ? <>
                        {
                          record.supplyInfoList.map((item, k) => (
                            <p className={`${styles.tGoodsnum}`} key={`${record.commoditySkuId}-${record.skuGoodsType}-${item.quantity}-${k}`}>{item.quantity}</p>
                          ))
                        }
                      </>
                      : null
                  }
                </td>

                {/* 单品小计 */}
                <td className={`${styles.LgoodsMoney}`}>
                  {
                    orderType === 'ORDER_CLOUD_FUTURES'
                      ? <>&yen;{ (record.totalAmount * 100 / 100).toFixed(2) }</>
                      : <>&yen;{ (record.totalAmount * 100 / 100).toFixed(2) }</>
                  }
                </td>

                {/* 实付 */}
                <td className={`${styles.LgoodsOp}`}>
                  {
                    orderType === 'ORDER_CLOUD_FUTURES'
                      ? <>&yen;{(record.frontMoney * 100 / 100).toFixed(2)}</>
                      : <>&yen;{(record.totalAmount * 100 / 100).toFixed(2)}</>
                  }
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>

      <div className={styles.footset}>
        <div>
          <span>已选择{list.length}种商品，共{ goodsCount }件</span>
          <span style={{ marginLeft: '12px' }}><label className='label'>总价</label>&yen;{ (goodsTotalAmount * 100 / 100).toFixed(2) }</span>
          <span style={{ marginLeft: '12px' }}>
            <label className='label'>应付金额</label>
            <span className={styles.costmoeny}>&yen;{ (payAmount * 100 / 100).toFixed(2) }</span>
          </span>
        </div>
      </div>
    </>
  )
}

GoodsInfo.propTypes = {
  orderType: PropTypes.string,
  list: PropTypes.array,
  goodsCount: PropTypes.number, // 商品总件数
  goodsTotalAmount: PropTypes.number, // 商品总金额
  payAmount: PropTypes.number // 应付金额
}

export default GoodsInfo
