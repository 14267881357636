import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Button, Col, Row } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'

import styles from './index.module.scss'

const ZY = [
  { id: 1, info: '1、付款金额务必与订单金额保持一致，支付成功后将直接关联订单，订单状态自动切换成已支付状态' },
  { id: 2, info: '2、付款金额务必与左侧付款账户保持一致，切勿付款到任何线下等联系方式联系的其他账号' },
  { id: 3, info: '3、如果订单金额与付款金额不一致，付款金额将会自动转入账户余额' },
  { id: 4, info: '4、多次支付后，余额金额满足订单金额后，再订单页面点确认支付，将会自动关联订单并完成支付' }
]

export default class CostSuccess extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    bankInfo: {}, // 转账信息
    orderId: null,
    paidAmount: 0 // 本次应付
  }

  componentDidMount () {
    const { orderId, paidAmount = 0 } = this.props.history.location.state

    this.setState({
      orderId: orderId,
      paidAmount: paidAmount
    }, () => {
      this.getOfflinePayInfo({ orderId: orderId })
    })
  }

  // 获取线下支付信息
  getOfflinePayInfo = async (params) => {
    const res = await Api.getOfflinePayInfo(params)
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        bankInfo: data || {}
      })
    }
  }

  goOrder = () => {
    this.props.history.push('/admin/euOrder')
  }

  render () {
    const { orderId, paidAmount = 0, bankInfo = {} } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '16px' }}>
          <div className={styles.topTips}>
            <div className={`${styles.tips}`}>
              <p><label className='label'>订单编号</label>{orderId}</p>
              <p><label className='label'>支付有效日期</label>{bankInfo.orderTime ? moment(bankInfo.orderTime).format('YYYY-MM-DD HH:mm:ss') : null}</p>
            </div>
            <div className={`${styles.tips} ${styles.tipRight}`}>
              <p><label className='label'>应付金额</label><span className={styles.showMoneny}>&yen;{(+paidAmount * 100 / 100).toFixed(2)}</span></p>
              <p><Button type='primary' size='small' onClick={() => { this.goOrder() }}>查看订单</Button></p>
            </div>
          </div>

          {/* 银行装转账信息 */}
          <div style={{ marginTop: '30px' }}>
            <h1 style={{ fontSize: '18px', color: '#000000' }}>银行转账信息</h1>

            <Row>
              <Col span={10}>
                <div className={styles.bankInfo}>
                  <div className={styles.bankUs}>
                    <div>收款方：</div>
                    <div>收款账号：</div>
                    <div>银行联行号：</div>
                    <div>银行行号：</div>
                    <div>开户行：</div>
                    <div>订单编号：</div>
                  </div>
                  <div className={styles.bankcontent}>
                    <div>{bankInfo.payee ? bankInfo.payee : '----'}</div>
                    <div>
                      {bankInfo.receivableAccount ? bankInfo.receivableAccount.replace(/[\s]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ') : '----'}
                    </div>
                    <div>{bankInfo.bankNo ? bankInfo.bankNo : '----'}</div>
                    <div>{bankInfo.bank ? bankInfo.bank : '----'}</div>
                    <div>{bankInfo.openBank ? bankInfo.openBank : '----'}</div>
                    <div>{orderId}</div>
                  </div>
                  <div className={styles.bankFaly}>
                    <div>必填</div>
                    <div>必填</div>
                    <div>非必填</div>
                    <div>必填</div>
                    <div>必填</div>
                    <div>非必填</div>
                  </div>
                </div>
              </Col>
              <Col span={10} style={{ marginLeft: '30px' }}>
                <div className={styles.Tpis}>
                  <div className={styles.TipsTitle}>注意事项：</div>
                  <div className={styles.tipsInfo}>
                    {
                      ZY.map((item, i) => (
                        <div className={styles.tipsContent} key={i}>{item.info}</div>
                      ))
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </>
    )
  }
}
