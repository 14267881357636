import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setHandleModal } from '@/reducers/action'
import { Card, Table, Row, Modal, Switch, Button, Icon, Badge, message, Tag, Tooltip, Upload } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import BatchSearchMenu from '@/components/batchSearchMenu/index'
import UploadModal from '@/pages/store-house/component/upload-modal'
import OperateModal from './operate-modal'
import ProgressModal from '../component/progress-modal'
import LogsModal from './logs-modal'
import HandleModal from '@/pages/price/price-setting/handle-modal'
import UploadSheetModal from '@/components/uploadSheetModal'

import Api from '@/common/api'
import UploadApi from '@/common/api/uploadApi'
import filter from '@/common/utils/filter'
import { getStore, getBracketStr, getCaption } from '@/common/utils/mUtils'
import { downloadExcel } from '@/common/utils/downloadFile'

import styles from './operate.module.scss'

const { uploadMatchExcelUrl } = UploadApi
const { pageSizeOptions, productPutOnMap, shipmentUtilMap, importConfig } = filter

class ProductLibrary extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func
  }
  state = {
    searchData: [
      {
        name: 'keyword',
        formType: 'Input',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '请输入型号或订货号',
          autoComplete: 'off'
        }
      },
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      },
      {
        name: 'stockStatus',
        formType: 'Select',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '现货上架状态'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...productPutOnMap
        ]
      },
      {
        name: 'futuresStatus',
        formType: 'Select',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '期货上架状态'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...productPutOnMap
        ]
      }
    ],
    type: 1,
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    selectedRowKeys: [],
    rowDetail: {}, // 当前选择的行数据
    operateModal: false,
    progressModal: false,
    batchSearchResult: {},
    searchFailModal: false,
    logs: false,
    handleModal: false,
    uploadSheetModal: false,
    uploadSheetDetails: {}
  }

  columns = [
    {
      title: '序号',
      key: 'productLibrary-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'productLibraryt-1',
      width: 120,
      ellipsis: true,
      render: (text, record, index) => (
        <div>
          <div>{record.brandName}</div>
          <div>
            {
              record.englishBrandName && record.englishBrandName !== ''
                ? record.englishBrandName
                : '--'
            }
          </div>
        </div>
      )
    },
    {
      title: '型号',
      key: 'productLibrary-2',
      width: 160,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.model }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.model }} />
          </Tooltip>

          { record.modelExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '订货号',
      key: 'productLibrary-3',
      width: 130,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
          </Tooltip>

          { !record.modelExactMatch && record.itemNumberExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '产品名称',
      key: 'productLibrary-5',
      width: 180,
      ellipsis: true,
      render: (text, record, index) => (
        <div className={`coloneLine`}>{record.name}</div>
      )
    },
    {
      title: '分类',
      key: 'productLibrary-7',
      width: 120,
      dataIndex: 'classificationName',
      ellipsis: true
    },
    {
      title: '上架状态',
      key: 'productLibrary-8',
      colSpan: 2,
      render: (text, record, index) => (
        <div>
          {
            record.stockStatus === 'ENABLE'
              ? <Badge status='success' text={<span style={{ fontSize: '12px' }}>现货已上架</span>} />
              : <Badge status='error' text={<span style={{ fontSize: '12px' }}>现货未上架</span>} />
          }

          <div>
            <span style={{ marginRight: '2px' }}>库存</span>
            <span>{+record.stock}</span>
          </div>

          <div>
            <span style={{ visibility: 'hidden' }}>占位</span>
          </div>
        </div>
      )
    },
    {
      title: '期货状态',
      key: 'productLibrary-9',
      colSpan: 0,
      render: (text, record, index) => (
        <div>
          {
            record.futuresStatus === 'ENABLE'
              ? <Badge status='success' text={<span style={{ fontSize: '12px' }}>期货已上架</span>} />
              : <Badge status='error' text={<span style={{ fontSize: '12px' }}>期货未上架</span>} />
          }

          <div>
            <span style={{ marginRight: '2px' }}>货期</span>
            {
              record.futuresStatus === 'ENABLE'
                ? <span>
                  {`${+record.deliveryPeriodDays} ${shipmentUtilMap['FUTURES']}`}
                </span>
                : <span>--</span>
            }
          </div>
          <div>
            <span style={{ marginRight: '2px' }}>预付款</span>
            {
              record.futuresStatus === 'ENABLE'
                ? <span>{`${(+record.prepaymentRatio * 100 / 100).toFixed(2)}%`}</span>
                : <span>--</span>
            }
          </div>

        </div>
      )
    },
    {
      title: '标签',
      key: 'productLibrary-10',
      render: (text, record, index) => (
        <div>
          {
            +record.stockStatus === 'DISABLE' && +record.futuresStatus === 'DISABLE'
              ? <div>--</div>
              : <div className={`inline`}>
                <label style={{ marginRight: '5px', verticalAlign: 'middle' }}>推荐</label>
                <Switch size='small'
                  checkedChildren={<Icon type='check' />}
                  unCheckedChildren={<Icon type='close' />}
                  checked={record.recommendStatus === 'ENABLE'}
                  onChange={(checked) => this.handleSwitchRecommend(checked, index)}
                />
              </div>
          }
        </div>
      )
    },
    {
      title: '商品图片',
      key: 'productLibrary-11',
      width: 80,
      render: (text, record, index) => (
        <div>
          {
            record.imageUrl && record.imageUrl !== ''
              ? <Button type='primary' size='small' onClick={() => this.handleLookImg(record)}>查看图片</Button>
              : <span>暂无图片</span>
          }
        </div>
      )
    },
    {
      title: '操作',
      key: 'productLibrary-13',
      width: 180,
      fixed: 'right',
      render: (text, record, index) => (
        <div>
          {
            +record.taskId === 0
              ? <Button type='primary' size='small' style={{ marginRight: '8px' }}
                onClick={() => this.handleShowOperateModal([record.commoditySkuId])}
              >设置</Button>
              : <Row>{ record.taskDesc }</Row>
          }
          <Button type='primary' size='small' onClick={() => { this.handleLogs(record) }}>操作日志</Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getBrandList({ brandListType: 'MERCHANT_BRAND' })
    this.getMyProductListInProductModule()
  }

  // 获取品牌
  getBrandList = (params) => {
    Api.getBrandList(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        data.map(item => {
          item['showLabel'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[1].options = $lodash.concat([{ brandId: 0, showLabel: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取产品列表
  getMyProductListInProductModule = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getMyProductListInProductModule(params).then(res => {
      const { code, data = {} } = res

      if (+code === 10000) {
        this.setState({
          tableData: data.pageBean.list || [],
          pages: {
            ...pages,
            total: data.pageBean.total || 0
          },
          batchSearchResult: {
            failNumber: +data.failNumber,
            totalNumber: +data.totalNumber,
            successNumber: +data.successNumber,
            failList: data.failMessageList || []
          },
          searchFailModal: +data.failNumber > 0
        })
      }
    })
  }

  // 设置上架&添加到产品库
  setProductPutOnShelves = (params) => {
    Api.setProductPutOnShelves(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        setTimeout(() => {
          this.props.dispatch(setHandleModal({ handleModal: false }))
          this.closeModal()

          Modal.success({
            content: <div>
              <div>操作成功</div>
              <p>已成功操作{data}个型号</p>
            </div>,
            onOk: () => {
              this.getMyProductListInProductModule()
            }
          })
        }, 2000)
      }
    })
  }

  handleLogs = (record) => {
    this.setState({
      rowDetail: record,
      logs: true
    })
  }

  // 设置下架
  setProductPullOffShelves = (params) => {
    Api.setProductPullOffShelves(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        setTimeout(() => {
          this.props.dispatch(setHandleModal({ handleModal: false }))
          this.closeModal()

          Modal.success({
            content: <div>
              <div>操作成功</div>
              <p>已成功下架{data}个型号</p>
            </div>,
            onOk: () => {
              this.getMyProductListInProductModule()
            }
          })
        }, 2000)
      }
    })
  }

  // 删除型号
  deleteProductInProductModule = (params) => {
    Api.deleteProductInProductModule(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        setTimeout(() => {
          this.props.dispatch(setHandleModal({ handleModal: false }))
          this.closeModal()

          Modal.success({
            content: <div>
              <div>操作成功</div>
              <p>已成功删除{data}个型号</p>
            </div>,
            onOk: () => {
              this.getMyProductListInProductModule()
            }
          })
        }, 2000)
      }
    })
  }

  // 保存商品标签状态
  setProdLbaleStatus = (params) => {
    return Api.setProdLbaleStatus(params)
  }

  // 上传EXCEL智能解析
  saveUploadExcelMatchSmartparse = async (params) => {
    const res = await Api.saveUploadExcelMatchSmartparse(params)
    const { code, data } = res
    if (+code === 10000) {
      this.changeUploadSuccess(data)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.brandId && +search.brandId > 0) {
      search.brandId = [search.brandId]
    } else {
      delete search.brandId
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => this.getMyProductListInProductModule())
  }

  // 操作设置弹窗
  handleShowOperateModal = (record) => {
    this.setState({
      operateModal: true,
      rowDetail: record
    })
  }

  // 点击确定批量操作
  handleConfirmOperate = (params, operateType) => {
    const { type = 1, query = {} } = this.state

    if (operateType === 5) {
      if (type === 1) {
        this.setProductPutOnShelves(params)
      } else {
        this.props.history.push({ pathname: '/admin/searchCondition', state: { detailInfo: params, operateType: operateType, brandId: +query.brandId } })
      }
    }

    if (operateType === 2) {
      if (type === 1) {
        this.setProductPullOffShelves(params)
      } else {
        this.props.history.push({ pathname: '/admin/searchCondition', state: { detailInfo: params, operateType: operateType, brandId: +query.brandId } })
      }
    }

    if (operateType === 3) {
      if (type === 1) {
        this.setProductPutOnShelves(params)
      } else {
        this.props.history.push({ pathname: '/admin/searchCondition', state: { detailInfo: params, operateType: operateType, brandId: +query.brandId } })
      }
    }

    if (operateType === 4) {
      if (type === 1) {
        this.deleteProductInProductModule(params)
      } else {
        this.props.history.push({ pathname: '/admin/searchCondition', state: { detailInfo: params, operateType: operateType, brandId: +query.brandId } })
      }
    }
  }

  // 批量选择
  onSelectChange = (keys = []) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 点击条件批量设置按钮
  handleShowConditionModal = (type) => {
    this.setState({
      operateModal: true,
      type: type
    })
  }

  // 点击查看图片
  handleLookImg = (record) => {
    window.open(record.imageUrl, '_blank')
  }

  // 点击切换特惠标签
  handleSwitchHotSale = (checked, i) => {
    let { tableData } = this.state

    let params = {
      commoditySkuId: tableData[i].commoditySkuId,
      hotSaleStatus: checked ? 'ENABLE' : 'DISABLE'
    }

    this.setProdLbaleStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        tableData[i].hotSaleStatus = checked ? 'ENABLE' : 'DISABLE'

        this.setState({
          tableData
        })
      }
    })
  }

  // 点击切换推荐标签
  handleSwitchRecommend = (checked, i) => {
    let { tableData } = this.state

    let params = {
      commoditySkuId: tableData[i].commoditySkuId,
      recommendStatus: checked ? 'ENABLE' : 'DISABLE'
    }

    this.setProdLbaleStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        tableData[i].recommendStatus = checked ? 'ENABLE' : 'DISABLE'

        this.setState({
          tableData
        })
      }
    })
  }

  // 点击导出批量检索失败明细
  handleDownFailDetail = () => {
    const { batchSearchResult = {} } = this.state
    if (!batchSearchResult.failList || batchSearchResult.failList.length === 0) {
      return message.warning('暂无数据')
    }
    let _list = [
      ['品牌', '订货号', '型号', '失败原因']
    ]

    batchSearchResult.failList.forEach(item => {
      let arr = getBracketStr(item).split('-')
      let _res = getCaption(item)
      let _itemNo = $lodash.slice(arr, 3, arr.length).join('-')
      let _arr = []
      _arr.push(arr[0], arr[1], arr[2], _itemNo, _res)

      _list.push(_arr)
    })

    downloadExcel(_list, '失败明细.xlsx')
  }

  // 上传价格
  onChangeUploadFile = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ handleModal: true })

      return false
    }

    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        setTimeout(() => {
          this.setState({
            handleModal: false,
            uploadSheetDetails: {
              name: data.tableName || '',
              list: data.sheetNames || [],
              file: info.file.originFileObj
            },
            uploadSheetModal: true
          })
        }, 2000)
      } else {
        this.closeModal()
        message.error(info.file.response.message || `${info.file.name}上传失败`)
      }
    } else if (info.file.status === 'error') {
      this.closeModal()
      message.error(`${info.file.name}上传失败`)
    }
  }

  // 点击确定选择导入EXCEL的sheet
  handleConfirmUploadSheet = (values) => {
    const formData = new FormData()

    $lodash.forEach(values, (value, key) => {
      formData.append(key, value)
    })

    this.saveUploadExcelMatchSmartparse(formData)
  }

  // 监听上传成功
  changeUploadSuccess = (params) => {
    let config = {
      pageSourch: 'PRODUCT_LIBRARY_IMPORT',
      sheetSourch: importConfig['PRODUCT_LIBRARY_IMPORT'].sheetSourch
    }

    this.props.history.push({ pathname: '/admin/sheetsConfirm', state: { config: config, data: { importId: params } } })
  }

  // 渲染table行背景色
  renderTableRowClassName = (record) => {
    return +record.taskId > 0 ? styles.progress : ''
  }

  // 查看进度
  handleLookAddProgress = () => {
    this.setState({
      progressModal: true
    })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      operateModal: false,
      searchFailModal: false,
      progressModal: false,
      uploadSheetModal: false,
      logs: false,
      type: 1,
      rowDetail: {},
      selectedRowKeys: []
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getMyProductListInProductModule()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getMyProductListInProductModule()
    })
  }

  // 上传图片页面
  getUploadimg = () => {
    this.props.history.push('/admin/uploadimg')
  }

  // 批量上传图片
  getLotUploadimg = () => {
    this.props.history.push({ pathname: '/admin/lotuploadimg' })
  }

  render () {
    const { searchData, tableData, pages, selectedRowKeys, operateModal = false, rowDetail = {}, type = 1, progressModal = false,
      searchFailModal = false, batchSearchResult = {}, logs, handleModal = false, uploadSheetModal = false, uploadSheetDetails = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    // table选中配置
    const rowSelection = {
      columnWidth: 40,
      selectedRowKeys: selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => ({
        disabled: +record.taskId > 0
      })
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }} style={{ marginBottom: '15px' }}>
          <Row style={{ display: 'inline-block', width: '120px', lineHeight: '40px', verticalAlign: 'top' }}>
            <BatchSearchMenu onConfirm={(values) => this.handleSearch(values)} />
          </Row>
          <Row style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Row>
        </Card>

        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ marginBottom: '15px' }}>
            <Button type='primary' disabled={selectedRowKeys.length === 0}
              onClick={() => this.handleShowOperateModal(selectedRowKeys)}
            >
              <Icon type='check-square' />勾选批量设置
            </Button>

            <Button type='primary' style={{ marginLeft: '12px' }}
              onClick={() => this.handleShowConditionModal(2)}
            >
              <Icon type='file-search' />条件批量设置
            </Button>

            <Button type='primary' style={{ marginLeft: '12px' }}
              onClick={() => this.handleLookAddProgress()}
            >
              <Icon type='eye' />查看任务进度
            </Button>

            <Button type='primary'
              style={{ marginLeft: '12px' }}
              onClick={() => this.getUploadimg()}
            >上传图片</Button>

            <Button type='primary'
              style={{ marginLeft: '12px' }}
              onClick={() => this.getLotUploadimg()}
            >批量设置图片</Button>

            <Upload name='file' showUploadList={false}
              headers={{ Token: getStore('token') }}
              accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              action={uploadMatchExcelUrl}
              onChange={this.onChangeUploadFile}
            >
              <Button type='primary' style={{ marginLeft: '12px' }}><Icon type='cloud-upload' />导入产品</Button>
            </Upload>

          </Row>

          <Table rowKey='commoditySkuId'
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
            rowSelection={rowSelection}
            rowClassName={(record) => this.renderTableRowClassName(record)}
            scroll={{ x: 1500 }}
          />
        </Card>

        {
          operateModal
            ? <OperateModal
              type={type}
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params, type) => this.handleConfirmOperate(params, type)}
            />
            : null
        }

        {
          searchFailModal
            ? <UploadModal type={1} hasDownBtn={1}
              totalNumber={batchSearchResult.totalNumber}
              successNumber={batchSearchResult.successNumber}
              failNumber={batchSearchResult.failNumber}
              list={batchSearchResult.failList}
              onCancel={() => this.closeModal()}
              onDownload={() => this.handleDownFailDetail()}
            />
            : null
        }

        { // 任务进度弹窗
          progressModal
            ? <ProgressModal
              taskKey='PRODUCT_LIBRARY'
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          logs
            ? <LogsModal
              onCancel={() => this.closeModal()}
              detailInfo={rowDetail}
            /> : null
        }

        {
          handleModal
            ? <HandleModal letterArr={['EXCEL', '分', '析', '中', '...']} />
            : null
        }

        {
          uploadSheetModal
            ? <UploadSheetModal
              name={uploadSheetDetails.name}
              list={uploadSheetDetails.list}
              file={uploadSheetDetails.file}
              onConfirm={(values) => this.handleConfirmUploadSheet(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

export default connect()(ProductLibrary)
