import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Form, Radio, Alert } from 'antd'

import styles from './index.module.scss'

const FormItem = Form.Item

class OperationCheckForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    total: PropTypes.number,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {}

  componentDidMount () { }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onConfirm(values.putType)
      }
    })
  }

  render () {
    const { total = 0 } = this.props
    const { getFieldDecorator } = this.props.form

    return (
      <Modal title='操作设置' visible width={800}
        maskClosable={false}
        onCancel={() => this.props.onCancel()}
        onOk={() => this.handleConfirm()}
      >
        <Row style={{ marginBottom: '15px' }}>
          <Alert type='info' message={`当前已选择 ${total} 个型号`} />
        </Row>
        <Form>
          <Row className={`${styles.formitemRow}`}>
            <FormItem label='操作类型'>
              {
                getFieldDecorator('putType', {
                  initialValue: 'ENABLE'
                })(
                  <Radio.Group>
                    <Radio value={'ENABLE'}>云采购上架</Radio>
                    <Radio value={'DISABLE'}>云采购下架</Radio>
                  </Radio.Group>
                )
              }
            </FormItem>
          </Row>
        </Form>
      </Modal>
    )
  }
}

const OperationCheckModal = Form.create()(OperationCheckForm)

export default OperationCheckModal
