import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Radio, InputNumber, Input, Row, Button } from 'antd'

import MsgcodeModal from './msgcodeModal/index'
import VerifyModal from '@/components/verify-modal/index'

import Api from '@/common/api/index'

const FormItem = Form.Item

class SetcreditForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number,
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    enable: 'ENABLE_ON',
    btnDisabled: false,
    yztime: 59,
    adminPhone: '',
    msgModal: false,
    verifyModal: false,
    rowDetail: {}
  }

  componentDidMount () {
    const { detailInfo } = this.props

    this.setState({
      enable: detailInfo.enable === 'ENABLE_UNKNOWN' ? 'ENABLE_ON' : detailInfo.enable
    }, () => {
      this.getAdminPhoneToDesensitization()
    })
  }

  // 组件卸载时 关闭定时器
  componentWillUnmount () {
    this.clearInterval()
  }

  // 获取手机号码
  getAdminPhoneToDesensitization = () => {
    Api.getAdminPhoneToDesensitization({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          adminPhone: data || ''
        })
      }
    })
  }

  // 发送短信验证码
  sendSmsCaptchaAndValidate = (params) => {
    Api.sendSmsCaptchaAndValidate(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.setInterval()
        this.setState({
          msgModal: true
        })
      }
    })
  }

  // 点击确定
  handleConfirm = (params) => {
    let { rowDetail = {} } = this.state
    let query = {
      ...rowDetail,
      ...params
    }

    this.props.onConfirm(query)
    this.closeModal()
  }

  // 确定禁用
  handleConfirmUnable = () => {
    const { detailInfo, type = 0 } = this.props
    const { enable } = this.state

    let params = {
      enable: enable
    }

    if (+type !== 99) {
      params.endUserId = detailInfo.endUserId
    }

    this.props.onConfirm(params)
  }

  // 点击选择启用、禁用
  handleChangeEnable = (e) => {
    this.setState({
      enable: e.target.value
    })
  }

  // 点击获取验证码
  handleGetMessCode = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo, type = 0 } = this.props
        const { enable } = this.state

        if (+type !== 99) {
          values.endUserId = detailInfo.endUserId
        }

        if (enable === 'ENABLE_ON') {
          values.sendSmsCaptchaEnum = +type === 99 ? 'SMS_CAPTCHA_TO_ADMIN' : 'EU_CREDIT_OPEN'
        } else {
          values.sendSmsCaptchaEnum = +type === 99 ? 'SMS_CAPTCHA_TO_ADMIN' : 'EU_CREDIT_CLOSE'
        }

        values.enable = enable

        this.setState({
          verifyModal: true,
          rowDetail: values
        })
      }
    })
  }

  // 滑块验证码验证成功
  handleVerifySlideCheckSuccess = (params) => {
    this.setState({
      verifyModal: false
    }, () => {
      const { rowDetail = {} } = this.state
      this.sendSmsCaptchaAndValidate({ ...params, ...rowDetail })
    })
  }

  // 倒计时
  countDown = () => {
    const { yztime = 59 } = this.state
    if (yztime === 1) {
      this.clearInterval()
    } else {
      this.setState({ btnDisabled: true, yztime: yztime - 1 })
    }
  }

  // 开始定时器
  setInterval = () => {
    this.timer = setInterval(this.countDown, 1000)
  }

  // 清除定时器
  clearInterval = () => {
    clearInterval(this.timer)
    this.setState({
      btnDisabled: false,
      yztime: 59
    })
  }

  renderModalFooterInner = () => {
    const { enable, yztime = 59, btnDisabled = false, adminPhone = '' } = this.state

    let arr = [
      <Button onClick={this.props.onCancel} key='creditCancel'>取消</Button>,
      <Button type='primary' key='creditConfirm'
        onClick={this.handleConfirmUnable}
      >确定</Button>
    ]

    if (enable === 'ENABLE_ON') {
      arr = [
        <span style={{ marginRight: '12px' }} key='creditSpan'>发送手机：{adminPhone}</span>,
        <Button onClick={this.props.onCancel} key='creditCancel'>取消</Button>,
        <Button type='primary' key='creditConfirm'
          disabled={btnDisabled}
          onClick={this.handleGetMessCode}
        >
          {
            !btnDisabled ? '获取验证码' : `${yztime} s 后重新发送`
          }
        </Button>
      ]
    }

    return arr
  }

  closeModal = () => {
    this.setState({
      msgModal: false,
      verifyModal: false,
      rowDetail: {}
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { detailInfo } = this.props
    const { enable, msgModal = false, verifyModal = false } = this.state

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 }
    }

    return (
      <div>
        <Modal title='设置信用支付'
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          footer={this.renderModalFooterInner()}
        >
          <Form>
            <FormItem label='启用信用支付' {...formItemLayout}>
              <Radio.Group
                value={enable}
                onChange={this.handleChangeEnable}
              >
                <Radio value={'ENABLE_ON'} key={`ENABLE_ON`}>启用</Radio>
                {
                  detailInfo.enable === 'ENABLE_UNKNOWN'
                    ? ''
                    : <Radio value={'ENABLE_OFF'} key={`ENABLE_OFF`}>禁用</Radio>
                }

              </Radio.Group>
            </FormItem>

            {
              enable === 'ENABLE_ON'
                ? <Row>
                  <FormItem label='信用额度' {...formItemLayout}>
                    {
                      getFieldDecorator('amount', {
                        initialValue: detailInfo.maxAmount || 0,
                        rules: [
                          { required: true, message: '该项必填' }
                        ]
                      })(
                        <InputNumber style={{ width: '100%' }}
                          placeholder='请输入...'
                        />
                      )
                    }

                  </FormItem>
                  <FormItem label='账期' {...formItemLayout}>
                    {
                      getFieldDecorator('period', {
                        initialValue: detailInfo.period || 0,
                        rules: [
                          { required: true, message: '该项必填' }
                        ]
                      })(
                        <Input placeholder='请输入...' autoComplete='off' suffix='天' />
                      )
                    }
                  </FormItem>
                </Row>
                : null
            }

          </Form>
        </Modal>

        {
          msgModal
            ? <MsgcodeModal
              onConfirm={(params) => this.handleConfirm(params)}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          verifyModal
            ? <VerifyModal
              onConfirm={(params) => this.handleVerifySlideCheckSuccess(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </div>
    )
  }
}

const SetcreditModal = Form.create()(SetcreditForm)

export default SetcreditModal
