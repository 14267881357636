import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Tabs, Button, Table, message } from 'antd'
import moment from 'moment'
import { getLodop } from '@/assets/plugin/LodopFuncs'

import OutboundTableHtm from '@/components/outboundTableHtm/index'
import OutboundRemarksModal from '../../component/outboundRemarksModal/index'
import LogisticsTrace from '@/components/logisticsTrace/index'

import Api from '@/common/api/index'

const { TabPane } = Tabs
const tabMaps = [
  { code: 'DETAIL', label: '产品明细' },
  { code: 'TRAIL', label: '物流跟踪' }
]

export default class RecordDetailsModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirmOutboundRemarks: PropTypes.func
  }

  state = {
    activeTabKey: 'DETAIL',
    outboundInfo: {},
    outboundTable: [],
    logisticsTraces: [],
    outboundRemarksModal: false // 出库单备注弹窗
  }

  columns = [
    {
      title: '序号',
      key: 'recordDetailsCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'recordDetailsCol-2',
      dataIndex: 'brandName'
    },
    {
      title: '型号/订货号',
      key: 'recordDetailsCol-3',
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '数量',
      key: 'recordDetailsCol-4',
      dataIndex: 'quantity'
    },
    {
      title: '货物金额',
      key: 'recordDetailsCol-5',
      dataIndex: 'amount',
      render: (text, record, index) => (
        <>&yen;{ (+record.amount * 100 / 100).toFixed(2) }</>
      )
    }
  ]

  componentDidMount () {}

  // 获取物流轨迹
  getLogisticsTraceByMailNo = async () => {
    const { detailInfo = {} } = this.props

    const params = {
      ShipperCode: detailInfo.courierCode,
      LogisticCode: detailInfo.logisticsOrderNo,
      CustomerName: detailInfo.receiverMobile ? detailInfo.receiverMobile.substr(-4) : ''
    }

    const res = await Api.getLogisticsTraceByMailNo(params)
    const { code, data } = res
    if (+code === 10000) {
      const _list = data.traces || []

      this.setState({
        logisticsTraces: _list.reverse()
      })
    }
  }

  // 更新出库单备注信息
  updateExpressRecordsOutboundRemarks = async (values) => {
    const { detailInfo = {}, onConfirmOutboundRemarks } = this.props
    const res = await Api.updateExpressRecordsOutboundRemarks({ ...values, id: detailInfo.id })
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.closeModal()

      onConfirmOutboundRemarks(values.remarks)
    }
  }

  // 点击切换tab
  handleChangeTab = (key) => {
    this.setState({
      activeTabKey: key
    }, () => {
      if (key === 'TRAIL') {
        this.getLogisticsTraceByMailNo()
      }
    })
  }

  // 点击打印拣货单
  handlePrintOutboundHtm = () => {
    const { detailInfo = {} } = this.props
    this.setState({
      outboundInfo: {
        distributorOrderNo: detailInfo.distributorOrderNo,
        customerName: detailInfo.customerName,
        remarks: detailInfo.remarks || '',
        time: moment().format('YYYY-MM-DD HH:mm:ss')
      },
      outboundTable: detailInfo.distributorPickingGoodsList || []
    }, () => {
      this.lodopPrintSalesBill()
    })
  }

  // 点击重新生成拣货单
  showOutboundRemarksModal = () => {
    this.setState({
      outboundRemarksModal: true
    })
  }

  // 打印拣货单
  lodopPrintSalesBill = () => {
    const htmlTemplateObj = document.getElementById('outboundTableHtm').innerHTML
    const LODOP = getLodop()
    if (!LODOP) { return message.error('请下载打印插件') }

    LODOP.PRINT_INIT('初始化打印') // 打印初始化
    LODOP.ADD_PRINT_HTM('8mm', '3mm', '195mm', '290mm', htmlTemplateObj)
    LODOP.PREVIEW()
  }

  // 渲染弹窗底部按钮
  renderModalBtns = (activeTabKey) => {
    const { onCancel } = this.props
    let arr = [
      <Button key={'cancelBtn'} onClick={() => onCancel()}>取消</Button>,
      <Button type='primary' key={'submitBtn'} onClick={() => this.handlePrintOutboundHtm()}>查看拣货单</Button>,
      <Button type='primary' key={'refreshBtn'} onClick={() => this.showOutboundRemarksModal()}>重新生成拣货单</Button>
    ]

    if (activeTabKey === 'TRAIL') {
      arr = [
        <Button key={'cancelBtn'} onClick={() => onCancel()}>关闭</Button>
      ]
    }

    return arr
  }

  closeModal = () => {
    this.setState({
      outboundRemarksModal: false
    })
  }

  render () {
    const { detailInfo = {} } = this.props
    const { activeTabKey = 'DETAIL', outboundInfo = {}, outboundTable = [], logisticsTraces = [], outboundRemarksModal = false } = this.state

    return (
      <>
        <Modal title='运单明细' width={1000} bodyStyle={{ padding: '15px' }}
          visible
          maskClosable={false}
          onCancel={() => this.props.onCancel()}
          footer={this.renderModalBtns(activeTabKey)}
        >
          <Tabs onChange={this.handleChangeTab} size='small'>
            {
              tabMaps.map((item, i) => (
                <TabPane tab={item.label} key={item.code} />
              ))
            }
          </Tabs>

          {
            activeTabKey === 'TRAIL'
              ? <LogisticsTrace
                courierCode={detailInfo.courierCode}
                logisticsTraces={logisticsTraces}
              />
              : <>
                <Table rowKey={(record, index) => `recordDetailsRow-${index}`}
                  columns={this.columns}
                  dataSource={detailInfo.distributorPickingGoodsList || []}
                  pagination={false}
                  scroll={{ y: 500 }}
                />
                <OutboundTableHtm
                  orderInfo={outboundInfo}
                  orderDetail={outboundTable}
                />
              </>
          }
        </Modal>

        {
          outboundRemarksModal
            ? <OutboundRemarksModal
              outboundRemarks={detailInfo.remarks}
              onCancel={() => this.closeModal()}
              onConfirm={(values) => this.updateExpressRecordsOutboundRemarks(values)}
            />
            : null
        }
      </>
    )
  }
}
