import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Upload, Icon } from 'antd'

import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'

import styles from './index.module.scss'

const FormItem = Form.Item

const { uploadOrdinaryImageUrl } = UploadApi

const colorMap = [
  '#00a0e9', '#ffffff', '#FFEFD5'
]

class EditSearch extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    getDetail: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    selectedColorIndex: 0,
    backgroundImgUrl: ''
  }

  componentDidMount () {
    this.getSearchLandDetail()
  }

  getSearchLandDetail = () => {
    const { detailInfo } = this.props

    this.props.getDetail({ pageContainerId: detailInfo.id }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { selectedColorIndex = 0, backgroundImgUrl = '' } = this.state

        if (data && data.elementList && data.elementList.length > 0) {
          let _list = data.elementList
          backgroundImgUrl = _list[0].imageUrl || ''

          if (_list[0].backgroudColor && _list[0].backgroudColor !== '') {
            colorMap.map((item, i) => {
              if (item === _list[0].backgroundColor) {
                selectedColorIndex = i
              }
            })
          }
        }

        this.setState({
          selectedColorIndex: selectedColorIndex || 0,
          backgroundImgUrl: backgroundImgUrl || ''
        })
      }
    })
  }

  handleConfirm = () => {
    const { detailInfo } = this.props
    const { selectedColorIndex, backgroundImgUrl } = this.state
    let arr = []
    let obj = {
      backgroundColor: colorMap[selectedColorIndex],
      imageUrl: backgroundImgUrl
    }

    arr.push(obj)

    let params = {
      pageContainerId: detailInfo.id,
      pageElementDTOList: arr
    }

    this.props.onConfirm(params)
  }

  handleSelectedColor = (i) => {
    this.setState({
      selectedColorIndex: i
    })
  }

  handleChangeUpload = (info) => {
    if (info.file.status === 'done') {
      const { data } = info.file.response

      this.setState({
        backgroundImgUrl: data.imageUrl || ''
      })
    }
  }

  removeImg = () => {
    this.setState({
      backgroundImgUrl: ''
    })
  }

  render () {
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 }
    }

    const { selectedColorIndex = 0, backgroundImgUrl = '' } = this.state

    return (
      <div>
        <Modal title='编辑搜索栏'
          visible
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Form {...formItemLayout}>
            <FormItem label='背景颜色'>
              {
                colorMap.map((item, i) => (
                  <a href='javascript:;' key={`color-${i}`}
                    className={`${styles.colorBlock} ${selectedColorIndex === i ? styles.cur : ''}`}
                    style={{ backgroundColor: `${item}` }}
                    onClick={() => this.handleSelectedColor(i)}
                  />
                ))
              }
            </FormItem>
            <FormItem label='背景图片' extra='建议图片格式为：1920*126 png文件'>
              {
                backgroundImgUrl !== ''
                  ? (
                    <div className={styles.thumbWrap}>
                      <div className={styles.thumbInfo}>
                        <img src={backgroundImgUrl} />

                        <a className={styles.prewModal}>
                          <Icon type='delete' style={{ fontSize: '20px' }}
                            onClick={() => this.removeImg()}
                          />
                        </a>
                      </div>
                    </div>
                  )
                  : (
                    <Upload
                      showUploadList={false}
                      listType='picture-card'
                      accept='image/png, image/jpg, image/jpeg'
                      headers={{ Token: getStore('token') }}
                      action={uploadOrdinaryImageUrl}
                      beforeUpload={this.beforeUpload}
                      onChange={(info) => this.handleChangeUpload(info, 1)}
                    >
                      <div>
                        <Icon type='plus' />
                        <div style={{ fontSize: '12px' }}>点击上传</div>
                      </div>

                    </Upload>
                  )
              }

            </FormItem>
          </Form>
        </Modal>
      </div>
    )
  }
}

const EditsearchModal = Form.create()(EditSearch)

export default EditsearchModal
