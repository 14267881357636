import Ajax from '../utils/ajax'

export default {
  // 用户登录
  login (params) {
    return Ajax.post('/api/user/login', params)
  },

  // 获取当前登录用户信息
  getLoginUserInfo (params) {
    return Ajax.get('/api/home/page/get/account/info', { params })
  },

  // 获取登录用户endUserId
  getPurchaserEndUserId (params) {
    return Ajax.get('/api/guest/get/inner/purchaserEndUser', { params })
  },

  // 获取菜单
  getMenus (params) {
    return Ajax.get('/api/menu/getMenuTree', { params })
  },

  // 保存用户设置
  submitSetAccount (params) {
    return Ajax.post('/api/home/page/update/account/password', params)
  },

  // 图片上传
  upload (params) {
    return Ajax.post('/api/image/upload', params)
  },

  // 查询是否设置了支付密码
  getHasPayPassword (params) {
    return Ajax.get('/api/finance/pay/hasPayPassword', { params })
  },

  // 更新支付密码
  updatePayPassword (params) {
    return Ajax.post('/api/finance/pay/updatePayPassword', params)
  },

  // 获取线下支付信息
  getOfflinePayInfo (params) {
    return Ajax.get('/api/finance/pay/offlinePayInfo', { params })
  },

  // 获取收件地址列表(收发票)
  getReceivingAddressList (params) {
    return Ajax.post('/api/address/list/invoice', params)
  },

  // 获取收票地址详情
  getReceivingAddressInfo (params) {
    return Ajax.post('/api/address/get/invoice', params)
  },

  // 新增收件地址(收发票)
  saveAddReceivingAddress (params) {
    return Ajax.post('/api/address/add/invoice', params)
  },

  // 编辑收件地址
  saveEditReceivingAddress (params) {
    return Ajax.post('/api/address/update/invoice', params)
  },

  // 删除收件地址(收发票)
  deleteReceivingAddress (params) {
    return Ajax.post('/api/address/update/status/invoice', params)
  }
}
