import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, Upload, Input, Icon, Divider, Button, Modal, message } from 'antd'
import $lodash from 'lodash'

import UploadThumbnail from '@/components/uploadThumbnail/index'

import UploadApi from '@/common/api/uploadApi'
import filter from '@/common/utils/filter'
import { getStore } from '@/common/utils/mUtils'

import styles from './renovatset.module.scss'

const { confirm } = Modal
const FormItem = Form.Item
const { uploadOrdinaryImageUrl } = UploadApi
const { IMAGEMAXSIZE } = filter

class RenovatForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    coverList: PropTypes.array,
    list: PropTypes.array,
    onCreate: PropTypes.func,
    onDelete: PropTypes.func,
    onUpload: PropTypes.func,
    onRemove: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    requiredIndex: -1
  }

  componentDidMount () {

  }

  // 点击保存
  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { detailInfo = {} } = this.props
        const { item = [], cover = [] } = values

        if (item.length === 0) {
          return message.warning('至少有一个系列信息！')
        }

        let i = item.findIndex(record => {
          return record.imageUrl === ''
        })

        if (i > -1) {
          return message.warning('请上传图片！')
        }

        let params = {
          pageContainerId: detailInfo.id,
          pageElementDTOList: $lodash.concat(item, cover)
        }

        this.props.onConfirm(params)
      }
    })
  }

  // 点击删除
  showDeleteConfirmModal = (i) => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        this.props.onDelete(i)
      }
    })
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < IMAGEMAXSIZE
    if (!isLt2M) {
      message.warning(`图片大小不可超过 ${IMAGEMAXSIZE}MB！`)
    }

    return isLt2M
  }

  // 上传图片
  handleChangeUpload = (info, i, type) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        this.props.onUpload(data.imageUrl, i, type)
      }
    }
  }

  render () {
    const { list = [], coverList = [] } = this.props
    const { getFieldDecorator } = this.props.form
    const { requiredIndex = -1 } = this.state

    const uploadButton = (
      <div>
        <Icon type='plus' />
        <div>点击上传</div>
      </div>)

    return (
      <div>
        <Row>
          <Form>
            <Row style={{ marginBottom: '10px' }}>
              <Row style={{ marginBottom: '10px' }}>专区封面</Row>
              {
                coverList.map((cover, n) => (
                  <Row key={`brickCover-${n}`}>
                    <Row gutter={24}>
                      <Col span={6}>
                        <Row>
                          {
                            !cover.imageUrl || cover.imageUrl === ''
                              ? <Upload name='file' listType='picture-card'
                                showUploadList={false}
                                accept='image/png, image/jpg, image/jpeg'
                                headers={{ Token: getStore('token') }}
                                action={uploadOrdinaryImageUrl}
                                beforeUpload={this.beforeUpload}
                                onChange={(info) => this.handleChangeUpload(info, n, 99)}
                              >
                                {uploadButton}
                              </Upload>
                              : <UploadThumbnail
                                imageUrl={cover.imageUrl}
                                onDelete={() => this.props.onRemove(n, 99)}
                              />
                          }
                        </Row>
                      </Col>
                      <Col span={18}>
                        <Row className={`${styles.setwrap}`}>
                          <FormItem label='跳转链接' style={{ marginBottom: '0px' }}>
                            {
                              getFieldDecorator(`cover[${n}].linkUrl`, {
                                initialValue: cover.linkUrl || ''
                              })(
                                <Input placeholder='请输入跳转链接' autoComplete='off' />
                              )
                            }
                          </FormItem>
                          <FormItem style={{ marginBottom: '0px', display: 'inline-block', visibility: 'hidden', width: 'calc(50% - 30px)' }}>
                            {
                              getFieldDecorator(`cover[${n}].imageUrl`, {
                                initialValue: cover.imageUrl || '',
                                rules: [
                                  { required: false, message: '请上传图片' }
                                ]
                              })(
                                <Input disabled />
                              )
                            }
                          </FormItem>
                          <FormItem style={{ marginBottom: '0px', display: 'inline-block', visibility: 'hidden', width: 'calc(50% - 30px)' }}>
                            {
                              getFieldDecorator(`cover[${n}].type`, {
                                initialValue: cover.type || 99
                              })(
                                <Input disabled />
                              )
                            }
                          </FormItem>
                        </Row>
                      </Col>
                    </Row>
                    <Divider style={{ margin: '0px 0px 15px 0px', backgroundColor: '#1890FF' }} />
                  </Row>

                ))
              }
            </Row>
            {/* 封面END */}
            <Row>
              {
                list.map((item, i) => (
                  <Row key={`brickItem-${i}`} id={`brickItem-${i}`} className={`${styles.brickItem}`}

                  >
                    <Row className={`${styles.brickContainer} ${requiredIndex === i ? styles.errorBorder : ''}`}>
                      <Col span={6}>
                        <Row style={{ paddingTop: '4px' }}>
                          {
                            !item.imageUrl || item.imageUrl === ''
                              ? <Upload name='file' listType='picture-card'
                                showUploadList={false}
                                accept='image/png, image/jpg, image/jpeg'
                                headers={{ Token: getStore('token') }}
                                action={uploadOrdinaryImageUrl}
                                beforeUpload={this.beforeUpload}
                                onChange={(info) => this.handleChangeUpload(info, i, 1)}
                              >
                                {uploadButton}
                              </Upload>
                              : <UploadThumbnail
                                imageUrl={item.imageUrl}
                                onDelete={() => this.props.onRemove(i, 1)}
                              />
                          }
                        </Row>
                      </Col>
                      <Col span={18}>
                        <Row className={`${styles.setwrap}`}>
                          <FormItem label='名称' style={{ marginBottom: '5px' }}>
                            {
                              getFieldDecorator(`item[${i}].name`, {
                                initialValue: item.name || ''
                              })(
                                <Input placeholder='请输入名称' autoComplete='off' />
                              )
                            }
                          </FormItem>
                          <FormItem label='跳转链接' style={{ marginBottom: '0px' }}>
                            {
                              getFieldDecorator(`item[${i}].linkUrl`, {
                                initialValue: item.linkUrl || ''
                              })(
                                <Input placeholder='请输入跳转链接' autoComplete='off' />
                              )
                            }
                          </FormItem>
                          <FormItem style={{ marginBottom: '0px', display: 'inline-block', visibility: 'hidden', width: 'calc(50% - 30px)' }}>
                            {
                              getFieldDecorator(`item[${i}].imageUrl`, {
                                initialValue: item.imageUrl || ''
                              })(
                                <Input disabled />
                              )
                            }
                          </FormItem>
                          <FormItem style={{ marginBottom: '0px', display: 'inline-block', visibility: 'hidden', width: 'calc(50% - 30px)' }}>
                            {
                              getFieldDecorator(`item[${i}].type`, {
                                initialValue: +item.type || 1
                              })(
                                <Input disabled />
                              )
                            }
                          </FormItem>

                          <FormItem style={{ marginBottom: '0px', display: 'inline-block', width: '60px', textAlign: 'right' }}>
                            <Button type='danger' size='small' disabled={item.id === 0}
                              onClick={() => this.showDeleteConfirmModal(i)}
                            >删除</Button>
                          </FormItem>

                        </Row>
                      </Col>
                    </Row>

                  </Row>
                ))
              }

              <Row style={{ paddingTop: '15px' }}>
                <Button type='primary' disabled={list.length === 8} onClick={() => this.props.onCreate()}>
                  <Icon type='plus' />自定义新增
                </Button>
              </Row>

            </Row>
          </Form>
        </Row>

        <Row className={`${styles.footerLand}`}>
          <Button style={{ marginRight: '24px' }} onClick={this.props.onCancel}>
            返回
          </Button>

          <Button type='primary' onClick={this.handleSubmit}>保存</Button>
        </Row>

      </div >
    )
  }
}

const RenovatSet = Form.create()(RenovatForm)

export default RenovatSet
