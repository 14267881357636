/* 报价单模块 */

// 报价单模板列表
import QuotetempList from '@/pages/quote/quotetemp-list/index'

export default [
  {
    path: '/admin/quotetempList',
    name: '报价单模板列表',
    component: QuotetempList
  }
]
