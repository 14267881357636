import React, { Component } from 'react'
import { Card, Table, Row, message, Modal, Button } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import SupplydetailModal from '../component/supplydetailModal'
import Descriptions from './../../order/euorder/descriptions'
import Updowm from '../../order/component/down'
import PayVoucherModal from './payVoucherModal/index'
import OrderEnclosureDrawer from '@/pages/order/component/orderEnclosureDrawer'

import Api from '@/common/api'
import DownloadApi from '@/common/api/downloadApi'
import { downloadFileByGet } from '@/common/utils/downloadFile'
import filter from '@/common/utils/filter'
import { renderOrderStatusLabel, renderOrderPaymentStatusLabel, renderOrderDeliveryStatusLabel, renderOrderTypeLabel } from '@/common/utils/mUtils'

const { downOrderUrlcloudSupply } = DownloadApi
const { pageSizeOptions, orderStatusMaps, orderDeliveryStatusMaps, orderPaymentStatusMaps } = filter
const { confirm } = Modal

export default class SupplyOrder extends Component {
  static propTypes = {}
  state = {
    summary: {},
    searchData: [
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...'
        }
      },
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {

        }
      },
      {
        name: 'orderStatusList',
        formType: 'Select',
        itemParams: {
          label: '订单状态'
        },
        cptParams: {
          placeholder: '请选择...',
          mode: 'multiple',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderStatusMaps
        ]
      },
      {
        name: 'paymentStatus',
        formType: 'Select',
        itemParams: {
          label: '付款状态'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderPaymentStatusMaps
        ]
      },
      {
        name: 'deliveryStatus',
        formType: 'Select',
        itemParams: {
          label: '发货状态'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderDeliveryStatusMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    supplyModal: false,
    downcolModal: false, // 自定义导出项弹窗
    payVoucherModal: false, // 付款凭证弹窗
    enclosureModal: false, // 订单附件弹窗
    rowDetail: {} // 当前选择的行数据
  }

  columns = [
    {
      title: '序号',
      key: 'supplyOrder-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '订单类型',
      key: 'supplyOrderCol-1',
      dataIndex: 'orderType',
      render: (text, record, index) => (
        <>{ renderOrderTypeLabel(record.orderType) }</>
      )
    },
    {
      title: '订单日期',
      key: 'supplyOrder-2',
      render: (text, record, index) => (
        <>{ record.orderTime ? <>{moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss')}</> : '----'}</>
      )
    },
    {
      title: '订单编号',
      key: 'supplyOrder-3',
      dataIndex: 'orderId'
    },
    {
      title: '客户名称',
      key: 'supplyOrder-4',
      ellipsis: true,
      dataIndex: 'customerName'
    },
    {
      title: '订单金额',
      key: 'supplyOrder-5',
      dataIndex: 'payableAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.payableAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '已付金额',
      key: 'supplyOrder-6',
      dataIndex: 'paidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.paidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '未付款金额',
      key: 'supplyOrder-7',
      dataIndex: 'unpaidAmount',
      render: (text, record, index) => (
        <>
          &yen;{(+record.unpaidAmount * 100 / 100).toFixed(2)}
        </>
      )
    },
    {
      title: '订单状态',
      key: 'supplyOrder-8',
      dataIndex: 'orderStatus',
      render: (text, record, index) => (
        <>{ renderOrderStatusLabel(record.orderStatus) }</>
      )
    },
    {
      title: '付款状态',
      key: 'supplyOrder-9',
      dataIndex: 'paymentStatus',
      render: (text, record, index) => (
        <>{ renderOrderPaymentStatusLabel(record.paymentStatus) }</>
      )
    },
    {
      title: '发货状态',
      key: 'supplyOrder-10',
      dataIndex: 'deliveryStatus',
      render: (text, record, index) => (
        <>{ renderOrderDeliveryStatusLabel(record.deliveryStatus) }</>
      )
    },
    {
      title: '付款凭证',
      key: 'supplyOrder-11',
      render: (text, record, index) => (
        <Button type='link' size='small' onClick={() => this.handleShowVoucherModal(record)}>点击显示</Button>
      )
    },
    {
      title: '支付方式',
      key: 'supplyOrder-12',
      dataIndex: 'payTypeDesc'
    },
    {
      title: '订单附件',
      key: 'supplyOrder-13',
      render: (text, record, index) => (
        <>
          <Button type='primary' size='small' onClick={() => this.showOrderEnclosureModal(record)}>点击查看</Button>
        </>
      )
    },
    {
      title: '操作',
      key: 'supplyOrder-14',
      width: 260,
      render: (text, record, index) => {
        const { orderType, orderStatus } = record

        return (
          <>
            <Button type='primary' size='small' style={{ marginRight: '8px' }}
              onClick={() => this.showSupplyDetailModal(record)}
            >查看明细</Button>

            {
              orderType !== 'ORDER_CLOUD_FUTURES' && ['PAYING', 'TAIL_PAYING'].includes(orderStatus)
                ? <Button type='primary' size='small' style={{ marginRight: '8px' }}
                  onClick={() => this.handleConfirmCollect(record.purchaseOrderId)}>
                  确认收款
                </Button>
                : null
            }

            <Button type='primary' size='small' style={{ marginRight: '8px' }}
              onClick={() => this.handleDownloadContract(record)}
            >下载合同</Button>

          </>
        )
      }
    }
  ]

  componentDidMount () {
    this.getSupplyOrderList()
    this.getTradeOrderAmountSummary()
  }

  // 获取云供应订单列表
  getSupplyOrderList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getSupplyOrderList(params).then(res => {
      const { code, data } = res
      let newtableData = data.list || []
      newtableData.map((item, i) => (
        item['url'] = item.paymentUrl.split(',')
      ))
      if (+code === 10000) {
        this.setState({
          tableData: newtableData,
          pages: {
            ...pages,
            total: data.total
          }
        })
      }
    })
  }

  // 点击下载合同
  handleDownloadContract = async (record) => {
    const res = await Api.getCloudSupplyContractDownFile({ purchaseOrderId: record.purchaseOrderId })
    const { code, data } = res
    if (+code === 10000) {
      window.open(data.fileKey)
    }
  }

  // 获取订单明细
  getSupplyOrderDetail = (params) => {
    return Api.getSupplyOrderDetail(params)
  }

  // 确认收款
  confirmSupplyCollect = (purchaseOrderId) => {
    Api.confirmSupplyCollect({ purchaseOrderId: purchaseOrderId }).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getSupplyOrderList()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.orderTime) {
      search.beginDate = search.orderTime.length > 0 ? search.orderTime[0].format('YYYY-MM-DD') : ''
      search.endDate = search.orderTime.length > 0 ? search.orderTime[1].format('YYYY-MM-DD') : ''

      delete search.orderTime
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => {
      this.getSupplyOrderList()
    })
  }

  // 查看订单明细
  showSupplyDetailModal = (record = {}) => {
    this.setState({
      supplyModal: true,
      rowDetail: record || {}
    })
  }

  // 点击确认收款
  handleConfirmCollect = (purchaseOrderId) => {
    confirm({
      title: '确认已经收款?',
      content: '',
      onOk: () => {
        this.confirmSupplyCollect(purchaseOrderId)
      }
    })
  }

  // 点击查看付款凭证
  handleShowVoucherModal = (record) => {
    this.setState({
      rowDetail: record,
      payVoucherModal: true
    })
  }

  // 点击导出订单
  showDowncolModal = () => {
    this.setState({
      downcolModal: true
    })
  }

  // 点击导出订单
  downloadOrder = (values) => {
    const { query } = this.state

    if ((!query.orderId || query.orderId === '') && !query.beginDate) {
      return message.warning('请选择要导出订单的时间段！')
    }

    let params = {
      orderId: query.orderId,
      fields: values.fields,
      beginDate: query.beginDate,
      endDate: query.endDate
    }

    downloadFileByGet('/api/excel/export/cloudSupplyOrderInfoExport', params)
  }

  // 点击查看订单附件
  showOrderEnclosureModal = (record) => {
    this.setState({
      rowDetail: record,
      enclosureModal: true
    })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      supplyModal: false,
      downcolModal: false,
      payVoucherModal: false,
      enclosureModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSupplyOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSupplyOrderList()
    })
  }

  // 获取订单金额汇总
  getTradeOrderAmountSummary = () => {
    const { pages } = this.state
    let params = {
      orderType: 'PLATFORM_ORDER',
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getTradeOrderAmountSummary(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          summary: data || {}
        })
      }
    })
  }
  render () {
    const { tableData, summary = {}, query = {}, pages, searchData, supplyModal = false, payVoucherModal = false, enclosureModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }} style={{ marginBottom: '15px' }}>
          <SearchForm
            {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </Card>

        <Card bodyStyle={{ padding: '15px' }}>
          <Updowm query={query} api={downOrderUrlcloudSupply} />
          <Descriptions summary={summary} />
          <Row>
            <Table rowKey={(item, i) => { return `${item.orderId}-${i}` }}
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination}
            />
          </Row>
        </Card>

        {
          supplyModal
            ? <SupplydetailModal
              detailInfo={rowDetail}
              getDetail={(params) => this.getSupplyOrderDetail(params)}
              onConfirm={this.closeModal}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          payVoucherModal
            ? <PayVoucherModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {/* 订单附件弹窗 */}
        <OrderEnclosureDrawer
          visible={enclosureModal}
          name='SUPPLY_ENCLOSURE'
          detailInfo={rowDetail}
          onCancel={() => this.closeModal()}
        />
      </>
    )
  }
}
