import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Form, Input, Button, Icon, message } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import Api from '@/common/api/index'
import { setStore } from '@/common/utils/mUtils'

import styles from './login.module.scss'

const FormItem = Form.Item

class LoginForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleEnterKey)
  }

  // 组件卸载时 关闭定时器
  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleEnterKey)
  }

  handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      this.handleLogin()
    }
  }

  login = async (params) => {
    const res = await Api.login(params)
    const { code, data } = res
    if (+code === 10000) {
      setStore('token', data)
      this.props.history.push('/admin/home')
    } else {
      message.error(res.message)
    }
  }

  // 点击登录
  handleLogin = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let jsencrypt = new JSEncrypt()
        let { password } = values
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values['password'] = jsencrypt.encrypt(password)

        this.login(values)
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form

    return (
      <div className={`${styles.loginWrapper}`}>
        <div className={`${styles.loginContent}`}>
          <h3 className={`${styles.title}`}>商城管理后台</h3>
          <p className={`${styles.desc}`}>Business Management System</p>
          <Row className={`${styles.loginFormWrap}`}>
            <Form>
              <FormItem>
                {
                  getFieldDecorator('username', {
                    rules: [
                      { required: true, message: '请输入用户名!' }
                    ]
                  })(
                    <Input placeholder='请输入用户名'
                      style={{ height: '44px' }}
                      prefix={<Icon type='user' style={{ color: 'rgba(0, 0, 0, 0.3)' }} />}
                    />
                  )
                }
              </FormItem>
              <FormItem>
                {
                  getFieldDecorator('password', {
                    rules: [
                      { required: true, message: '请输入密码!' }
                    ]
                  })(
                    <Input type='password' placeholder='请输入密码'
                      style={{ height: '44px' }}
                      prefix={<Icon type='lock' style={{ color: 'rgba(0, 0, 0, 0.3)' }} />}
                    />
                  )
                }
              </FormItem>
              <FormItem>
                <Button type='primary' className={`${styles.loginButton}`} onClick={(e) => this.handleLogin(e)}>
                  登录
                </Button>
              </FormItem>
            </Form>
          </Row>
        </div>
      </div>
    )
  }
}

const Login = Form.create()(LoginForm)

export default Login
