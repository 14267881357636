import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Col, Form, Icon, Upload, Input, message } from 'antd'
import { ReactSortable } from 'react-sortablejs'
import $lodash from 'lodash'

import UploadThumbnail from '@/components/uploadThumbnail/index'
import EmptyWidget from './emptyWidget'

import UploadApi from '@/common/api/uploadApi'
import filter from '@/common/utils/filter'
import { getStore } from '@/common/utils/mUtils'

import styles from './editwidget.module.scss'

const { uploadOrdinaryImageUrl } = UploadApi
const { IMAGEMAXSIZE } = filter
const { confirm } = Modal
const FormItem = Form.Item

const imgSizeTips = [
  { type: 1, tipText: `图片尺寸为 740px * 460px, 图片大小不超过${IMAGEMAXSIZE}MB` },
  { type: 2, tipText: `图片尺寸为 1920px * 450px, 图片大小不超过${IMAGEMAXSIZE}MB` }
]

class EditbannerModal extends Component {
  static propTypes = {
    bannerSize: PropTypes.number,
    detailInfo: PropTypes.object,
    getDetail: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    list: [],
    activekey: null // 当前激活的widgetItem
  }

  componentDidMount () {
    this.getBannerDetail()
  }

  // 获取详情
  getBannerDetail = () => {
    const { detailInfo } = this.props
    let params = {
      pageContainerId: detailInfo.id
    }
    this.props.getDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          list: data.elementList || []
        })
      }
    })
  }

  // 点击保存
  handleConfirm = () => {
    const { detailInfo = {} } = this.props
    const { list = [] } = this.state

    let newArr = $lodash.filter(list, (item) => { return item.imageUrl && item.imageUrl !== '' })

    if (newArr.length === 0) {
      return message.warning('请至少上传一张图片')
    }

    let params = {
      pageContainerId: detailInfo.id,
      pageElementDTOList: newArr
    }

    this.props.onConfirm(params)
  }

  // 拖动排序
  handleChangeDragWidget = (newState) => {
    this.setState({
      list: newState
    })
  }

  // 监听输入框变化
  changeInputValue = (val, i) => {
    let { list = [] } = this.state

    list[i].linkUrl = val

    this.setState({
      list
    })
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < IMAGEMAXSIZE
    if (!isLt2M) {
      message.warning(`图片大小不可超过${IMAGEMAXSIZE}MB`)
    }

    return isLt2M
  }

  // 上传图片
  handleChangeUpload = (info, i) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        let { list = [] } = this.state
        list[i]['imageUrl'] = data.imageUrl

        this.setState({
          list
        })
      } else {
        message.error('上传失败')
      }
    }
  }

  // 点击激活选择的widgetItem
  handleActiveWidgetItem = (record) => {
    this.setState({
      activekey: `${record.id}`
    })
  }

  // 点击新增widgetItem
  handleAddWidgetItem = (i) => {
    let { list = [] } = this.state
    let row = { id: `BANNER-${new Date().getTime()}`, imageUrl: '', linkUrl: '' }

    list.splice(i + 1, 0, row)

    this.setState({
      list,
      activekey: `${row.id}`
    })
  }

  // 点击删除widgetItem
  handleDeleteWidgetItem = (record, i) => {
    confirm({
      title: '确定删除?',
      content: '',
      onOk: () => {
        this.deleteWidgetItem(record, i)
      }
    })
  }

  deleteWidgetItem = (record, i) => {
    let { list = [] } = this.state
    let lastIndex = i === list.length - 1
    let _newArr = $lodash.pullAllBy(list, [record], 'id')

    this.setState({
      list: _newArr,
      activekey: lastIndex ? `${_newArr[i - 1]['id']}` : `${_newArr[i]['id']}`
    })
  }

  // 点击删除图片
  handleDeleteImage = (i) => {
    let { list = [] } = this.state

    list[i].imageUrl = ''

    this.setState({
      list
    })
  }

  // 渲染上传图片大小文案
  renderImgSizeTips = (imgSize) => {
    let text = ''

    let obj = imgSizeTips.find(item => { return item.type === imgSize })

    if (obj) {
      text = obj.tipText
    }

    return text
  }

  render () {
    const { bannerSize = 1 } = this.props
    const { activekey, list = [] } = this.state

    return (
      <div>
        <Modal title='编辑banner' bodyStyle={{ padding: '16px', maxHeight: '500px', overflow: 'auto' }}
          width={800}
          visible
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Row style={{ marginBottom: '12px', color: '#F5222D' }}>建议：{this.renderImgSizeTips(bannerSize)}</Row>
          <Row>
            <Form>
              {
                list.length > 0
                  ? <ReactSortable animation={150} handle={'.draghandle'}
                    list={list}
                    setList={newState => this.handleChangeDragWidget(newState)}
                  >
                    {
                      list.map((item, i) => (
                        <Row className={`${styles.widgetItem} ${activekey === `${item.id}` ? styles.active : ''}`} key={`brickItem-${item.id}`}
                          onClick={() => this.handleActiveWidgetItem(item)}
                        >
                          <Row className={`${styles.widgetContent}`}>
                            <Col span={6}>
                              <Row style={{ paddingTop: '4px' }}>
                                {
                                  !item.imageUrl || item.imageUrl === ''
                                    ? <Upload name='file' showUploadList={false} listType='picture-card'
                                      accept='image/png, image/jpg, image/jpeg'
                                      headers={{ Token: getStore('token') }}
                                      action={uploadOrdinaryImageUrl}
                                      beforeUpload={this.beforeUpload}
                                      onChange={(info) => this.handleChangeUpload(info, i)}
                                    >
                                      <div>
                                        <Icon type='plus' />
                                        <div style={{ fontSize: '12px' }}>点击上传</div>
                                      </div>
                                    </Upload>
                                    : <UploadThumbnail
                                      imageUrl={item.imageUrl}
                                      onDelete={() => this.handleDeleteImage(i)}
                                    />
                                }
                              </Row>
                            </Col>
                            <Col span={18}>
                              <Row className={`${styles.formitemRow}`}>
                                <FormItem label='跳转链接'>
                                  <Input placeholder='请输入跳转链接' autoComplete='off'
                                    value={item.linkUrl}
                                    onChange={(e) => this.changeInputValue(e.target.value, i)}
                                  />
                                </FormItem>
                              </Row>
                            </Col>
                          </Row>

                          {
                            activekey === `${item.id}`
                              ? <Row className={`draghandle ${styles.widgetDrag}`}>
                                <Icon type='drag' className={`${styles.iconDrag}`} />
                              </Row>
                              : null
                          }

                          {
                            activekey === `${item.id}`
                              ? <Row className={`${styles.widgetAction}`}>
                                <Icon type='plus' className={`${styles.iconAction}`} onClick={() => this.handleAddWidgetItem(i)} />
                                <Icon type='delete' className={`${styles.iconAction}`} onClick={() => this.handleDeleteWidgetItem(item, i)} />
                              </Row>
                              : null
                          }

                        </Row>
                      ))
                    }
                  </ReactSortable>
                  : <EmptyWidget onConfirm={() => this.handleAddWidgetItem(0)} />
              }

            </Form>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default EditbannerModal
