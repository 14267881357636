import React, { Component } from 'react'
import { Table, Button } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import InvoiceModal from './invoiceModal/index'
import LogisticsModal from '@/components/logisticsModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderInvoiceTypeLabel, renderInvoiceOpenedStatusLabel } from '@/common/utils/mUtils'

const { pageSizeOptions, invoiceTypeMaps } = filter

class RecordTable extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'id',
        formType: 'Input',
        itemParams: {
          label: '开票申请编号'
        },
        cptParams: {
          placeholder: '请输入...'
        }
      },
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'orderTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {

        }
      },
      {
        name: 'invoiceType',
        formType: 'Select',
        itemParams: {
          label: '发票类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...invoiceTypeMaps
        ]
      },
      {
        name: 'applyTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '申请日期'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    invoiceModal: false,
    logisticsModal: false, // 物流轨迹弹窗
    rowDetail: {},
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'invoiceRecord-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '开票申请编号',
      key: 'invoiceRecord-1',
      dataIndex: 'id'
    },
    {
      title: '订单编号',
      key: 'invoiceRecord-2',
      dataIndex: 'orderId'
    },
    {
      title: '订单日期',
      key: 'invoiceRecord-3',
      dataIndex: 'orderDate',
      render: (text, record, index) => (
        <>{ record.orderDate ? moment(record.orderDate).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '发票类型',
      key: 'invoiceRecord-4',
      dataIndex: 'invoiceType',
      render: (text, record, index) => (
        <>{ renderInvoiceTypeLabel(record.invoiceType) }</>
      )
    },
    {
      title: '开票状态',
      key: 'invoiceRecord-5',
      dataIndex: 'invoiceOrderStatus',
      render: (text, record, index) => (
        <>{ renderInvoiceOpenedStatusLabel(record.invoiceOrderStatus) }</>
      )
    },
    {
      title: '申请日期',
      key: 'invoiceRecord-6',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----'}</>
      )
    },
    {
      title: '操作',
      key: 'invoiceRecord-7',
      render: (text, record, index) => (
        <>
          <Button type='primary' size='small' onClick={() => this.showInvoiceModal(record)}>查看详情</Button>

          <Button type='primary' size='small' style={{ marginLeft: '6px' }} onClick={() => this.showLogisticsTrackModal(record)}>物流跟踪</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getPurchaseOrderInvoiceOpenedList()
  }

  // 获取开票申请记录
  getPurchaseOrderInvoiceOpenedList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getPurchaseOrderInvoiceOpenedList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.orderTimes) {
      search.orderBeginDate = search.orderTimes.length > 0 ? search.orderTimes[0].format('YYYY-MM-DD') : undefined
      search.orderEndDate = search.orderTimes.length > 0 ? search.orderTimes[1].format('YYYY-MM-DD') : undefined

      delete search.orderTimes
    }

    if (search.applyTimes) {
      search.invoiceBeginDate = search.applyTimes.length > 0 ? search.applyTimes[0].format('YYYY-MM-DD') : undefined
      search.invoiceEndDate = search.applyTimes.length > 0 ? search.applyTimes[1].format('YYYY-MM-DD') : undefined

      delete search.applyTimes
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => this.getPurchaseOrderInvoiceOpenedList())
  }

  // 点击查看详情
  showInvoiceModal = (record = {}) => {
    this.setState({
      rowDetail: record,
      invoiceModal: true
    })
  }

  // 点击物流跟踪
  showLogisticsTrackModal = (record = {}) => {
    this.setState({
      rowDetail: record.courierCode && record.courierCode !== '' ? { courierCode: record.courierCode, courierNumber: record.courierNumber, mobile: record.phoneNumber } : [],
      logisticsModal: true
    })
  }

  closeModal = () => {
    this.setState({
      invoiceModal: false,
      logisticsModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPurchaseOrderInvoiceOpenedList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPurchaseOrderInvoiceOpenedList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], rowDetail = {}, invoiceModal = false, logisticsModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <SearchForm dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </div>

        <Table rowKey='id'
          dataSource={tableData}
          columns={this.columns}
          pagination={pagination}
        />

        {
          invoiceModal
            ? <InvoiceModal
              openStatus={'OPENED'}
              ids={[rowDetail.invoiceRecordId]}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          logisticsModal
            ? <LogisticsModal
              courierNumberList={[rowDetail]}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

export default RecordTable
