import React, { Component } from 'react'
import { Row, Card, Table, Button, Icon, message, Modal } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { confirm } = Modal
const { pageSizeOptions } = filter

export default class WhiteList extends Component {
  static propTypes = {}
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    currentIp: ''
  }
  columns = [
    {
      title: '序号',
      key: 'whitelist-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: 'IP地址',
      key: 'whitelist-1',
      dataIndex: 'ip'
    },
    {
      title: '创建时间',
      key: 'whitelist-2',
      render: (text, record, index) => (
        <div>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----'}</div>
      )
    },
    {
      title: '创建人',
      key: 'whitelist-3',
      dataIndex: 'createName'
    },
    {
      title: '操作',
      key: 'whitelist-4',
      render: (text, record, index) => (
        <div>
          <Button size='small' type='primary' onClick={() => this.handleDelete(record)}>删除</Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getCurrentNetworkAddress()
    this.getErpIpWhiteList()
  }

  // 获取当前IP地址
  getCurrentNetworkAddress = () => {
    Api.getCurrentNetworkAddress({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          currentIp: data || ''
        })
      }
    })
  }

  // 获取白名单列表
  getErpIpWhiteList = () => {
    const { pages } = this.state

    Api.getErpIpWhiteList({ pageNo: pages.pageNo, pageSize: pages.pageSize }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 保存IP白名单
  saveAddErpIpWhiteItem = (params) => {
    Api.saveAddErpIpWhiteItem(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getErpIpWhiteList()
      }
    })
  }

  // 删除IP白名单
  deleteErpIpWhiteItem = (params) => {
    Api.deleteErpIpWhiteItem(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getErpIpWhiteList()
      }
    })
  }

  // 点击添加到白名单
  handleShowEditModal = (currentIp) => {
    confirm({
      title: `确定将当前IP ${currentIp} 添加到白名单？`,
      onOk: () => {
        this.saveAddErpIpWhiteItem({ ip: currentIp })
      }
    })
  }

  // 点击删除白名单
  handleDelete = (record) => {
    confirm({
      title: '确定删除？',
      onOk: () => {
        this.deleteErpIpWhiteItem({ ip: record.ip })
      }
    })
  }

  closeModal = () => {
    this.setState({
      editModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getErpIpWhiteList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getErpIpWhiteList()
    })
  }

  render () {
    const { tableData = [], pages, currentIp = '' } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ marginBottom: '15px' }}>
            {
              currentIp !== ''
                ? <Button type='primary' onClick={() => this.handleShowEditModal(currentIp)}><Icon type='plus' />添加当前IP</Button>
                : null
            }

            <span style={{ marginLeft: '15px', fontSize: '16px', color: 'rgba(0, 0, 0, 0.85)' }}>当前IP地址: <span>{ currentIp}</span></span>
          </Row>
          <Row>
            <Table rowKey='id'
              pagination={pagination}
              columns={this.columns}
              dataSource={tableData}
            />
          </Row>
        </Card>
      </div>
    )
  }
}
