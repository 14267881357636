import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Card, Button, Icon } from 'antd'
import moment from 'moment'
import { setUnnoticeCount, setNoticeDetail } from '@/reducers/action'

import Api from '@/common/api/index'

import styles from './notify.module.scss'

class NotifyDetail extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func,
    noticeDetail: PropTypes.object
  }
  state = {

  }

  componentDidMount () {
    this.updateNoticeReadStatus()
  }

  componentDidUpdate () {
    this.updateNoticeReadStatus()
  }

  componentWillUnmount () {
    this.props.dispatch(setNoticeDetail({ noticeDetail: {} }))
  }

  // 标记通知消息已读
  updateNoticeReadStatus = () => {
    const { noticeDetail = {} } = this.props
    Api.updateNoticeReadStatus({ id: noticeDetail.id }).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.getUnReadNotifyCount()
      }
    })
  }

  // 获取未读消息总数
  getUnReadNotifyCount = () => {
    Api.getUnReadNotifyCount({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.props.dispatch(setUnnoticeCount({ unnoticeCount: +data }))
      }
    })
  }

  handleLinkToBack = () => {
    this.props.history.go(-1)
  }

  render () {
    const { noticeDetail = {} } = this.props

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <Button onClick={() => this.handleLinkToBack()}><Icon type='left' />返回</Button>
          </Card>
        </Row>
        <Row>
          <Card bodyStyle={{ padding: '15px' }}>
            <Row className={`${styles.noticeWrap}`}>
              <h4 className={`${styles.title}`}>{noticeDetail.title}</h4>
              <div className={`${styles.subInfo}`}>{moment(noticeDetail.createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
              <div className={`${styles.noticeContent}`} dangerouslySetInnerHTML={{ __html: noticeDetail.content }} />
            </Row>
          </Card>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    noticeDetail: state.noticeDetail
  }
}

export default connect(mapStateToProps)(NotifyDetail)
