import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setHandleModal } from '@/reducers/action'
import { Modal, Row, Col, Form, Radio, Checkbox, InputNumber, Alert } from 'antd'
import styles from './operate.module.scss'
import filter from '@/common/utils/filter'
const { shipmentUtilMap } = filter

const FormItem = Form.Item
const { confirm } = Modal

class OperateForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    type: PropTypes.number, // 1-选择 2-条件
    detailInfo: PropTypes.array,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    dispatch: PropTypes.func
  }
  state = {
    selectedKey: 0, // 1-型号库上架 2-下架 3-添加产品库 4-删除 5-产品库上架
    hasFutures: false // 是否有期货
  }

  componentDidMount () { }

  handleConfirm = () => {
    const { type = 1 } = this.props
    const { selectedKey = 0 } = this.state
    if (type === 1) {
      if (+selectedKey === 1) {
        this.handleConfirmPutOn(+selectedKey)
      }

      if (+selectedKey === 2) {
        this.showPullOffModal(+selectedKey)
      }

      if (+selectedKey === 3) {
        this.showAddToProductLibModal(+selectedKey)
      }

      if (+selectedKey === 4) {
        this.showDeleteModal(+selectedKey)
      }
    } else {
      if (+selectedKey === 1) {
        this.handleSubmitPutOnByCondition(+selectedKey)
      }

      if (+selectedKey === 2) {
        this.handleSubmitPullOffByCondition(+selectedKey)
      }

      if (+selectedKey === 3) {
        this.handleSubmitAddToProductLibraryByCondition(+selectedKey)
      }

      if (+selectedKey === 4) {
        this.handleSubmitDeleteByCondition(+selectedKey)
      }
    }
  }

  // 点击选择操作
  handleChangeRadio = (value) => {
    this.setState({
      selectedKey: value,
      hasFutures: false
    }, () => {
      this.props.form.setFieldsValue({
        selectedTypes: []
      })
    })
  }

  // 选择上架类型
  handleChangeCheckbox = (values) => {
    let _hasFutures = false

    if (values.length > 0) {
      let i = values.findIndex(item => {
        return item === 'futures'
      })

      if (i > -1) {
        _hasFutures = true
      }
    }

    this.setState({
      hasFutures: _hasFutures
    })
  }

  // 确认上架
  handleConfirmPutOn = (operateType) => {
    this.props.dispatch(setHandleModal({ handleModal: true }))

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = [] } = this.props
        if (values.selectedTypes && values.selectedTypes.length > 0) {
          values.selectedTypes.map(item => {
            if (item === 'stock') {
              values.updateStockStatus = 'ENABLE'
            }

            if (item === 'futures') {
              values.updateFuturesStatus = 'ENABLE'
              values.updateDeliveryPeriodUnit = 0
            }
          })
        }

        delete values.selectedTypes
        values.commoditySkuIdList = detailInfo
        values.pageNo = 1
        values.pageSize = 20

        this.props.onConfirm(values, operateType)
      }
    })
  }

  // 确认下架
  handleConfirmPullOff = (operateType) => {
    this.props.dispatch(setHandleModal({ handleModal: true }))

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = [] } = this.props
        if (values.selectedTypes && values.selectedTypes.length > 0) {
          values.selectedTypes.map(item => {
            if (item === 'stock') {
              values.updateStockStatus = 'DISABLE'
            }

            if (item === 'futures') {
              values.updateFuturesStatus = 'DISABLE'
            }
          })
        }

        delete values.selectedTypes
        values.commoditySkuIdList = detailInfo
        values.pageNo = 1
        values.pageSize = 20

        this.props.onConfirm(values, operateType)
      }
    })
  }

  // 添加产品库弹窗
  showAddToProductLibModal = (operateType) => {
    confirm({
      title: '确认添加到我的产品库？',
      content: '',
      onOk: () => {
        const { detailInfo = [] } = this.props
        let params = {
          commoditySkuIdList: detailInfo,
          pageNo: 1,
          pageSize: 20
        }
        this.props.dispatch(setHandleModal({ handleModal: true }))
        this.props.onConfirm(params, operateType)
      }
    })
  }

  // 下架弹窗
  showPullOffModal = (operateType) => {
    confirm({
      title: '确认下架？',
      content: '',
      onOk: () => {
        this.handleConfirmPullOff(operateType)
      }
    })
  }

  // 删除弹窗
  showDeleteModal = (operateType) => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        const { detailInfo = [] } = this.props
        let params = {
          commoditySkuIdList: detailInfo,
          pageNo: 1,
          pageSize: 20
        }
        this.props.dispatch(setHandleModal({ handleModal: true }))
        this.props.onConfirm(params, operateType)
      }
    })
  }

  // 条件-上架
  handleSubmitPutOnByCondition = (operateType) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.selectedTypes && values.selectedTypes.length > 0) {
          values.selectedTypes.map(item => {
            if (item === 'stock') {
              values.updateStockStatus = 'ENABLE'
            }

            if (item === 'futures') {
              values.updateFuturesStatus = 'ENABLE'
              values.updateDeliveryPeriodUnit = 0
            }
          })
        }

        delete values.selectedTypes
        this.props.onConfirm(values, operateType)
      }
    })
  }

  // 条件-下架
  handleSubmitPullOffByCondition = (operateType) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.selectedTypes && values.selectedTypes.length > 0) {
          values.selectedTypes.map(item => {
            if (item === 'stock') {
              values.updateStockStatus = 'DISABLE'
            }

            if (item === 'futures') {
              values.updateFuturesStatus = 'DISABLE'
            }
          })
        }
        delete values.selectedTypes

        this.props.onConfirm(values, operateType)
      }
    })
  }

  // 条件-添加到产品库
  handleSubmitAddToProductLibraryByCondition = (operateType) => {
    this.props.onConfirm({}, operateType)
  }

  // 条件-删除操作
  handleSubmitDeleteByCondition = (operateType) => {
    this.props.onConfirm({}, operateType)
  }

  render () {
    const { form, type = 1, detailInfo = [], onCancel } = this.props
    const { getFieldDecorator } = form
    const { selectedKey = 0, hasFutures = false } = this.state

    const formItemLayout = {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 18
      }
    }

    return (
      <>
        <Modal title='操作设置' width={800}
          visible
          maskClosable={false}
          onCancel={() => onCancel()}
          onOk={() => this.handleConfirm()}
          okText={type === 2 ? '下一步' : '确定'}
        >
          {
            type === 1
              ? <Row style={{ marginBottom: '24px' }}>
                <Alert type='info' showIcon
                  message={`当前已选择 ${detailInfo.length} 个型号`}
                />
              </Row>
              : null
          }
          <Row>
            <Row style={{ marginBottom: '24px' }}>
              <Row style={{ marginBottom: '8px' }}>
                <span className={`${styles.stepTitle}`}>选择操作</span>
              </Row>
              <Row>
                <Col offset={2}>
                  <Radio.Group onChange={(e) => this.handleChangeRadio(e.target.value)}>
                    <Radio value={1}>上架操作</Radio>
                    <Radio value={2}>下架操作</Radio>
                    <Radio value={3}>添加到产品库</Radio>
                    <Radio value={4}>删除操作</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </Row>

            <Row>
              <Form>
                {
                  +selectedKey === 1
                    ? <>
                      <Row style={{ marginBottom: '8px' }}>
                        <span className={`${styles.stepTitle}`}>设置类型</span>
                      </Row>

                      <FormItem label='上架类型' {...formItemLayout}>
                        {
                          getFieldDecorator('selectedTypes', {
                            rules: [
                              { required: true, message: '请选择上架类型' }
                            ]
                          })(
                            <Checkbox.Group onChange={this.handleChangeCheckbox}>
                              <Checkbox value={'stock'} key={`stock`}>现货上架</Checkbox>
                              <Checkbox value={'futures'} key={`futures`}>期货上架</Checkbox>
                            </Checkbox.Group>
                          )
                        }
                      </FormItem>

                      {
                        hasFutures
                          ? <Row>
                            <FormItem label={<span className={`${styles.isRequired}`}>期货到货货期</span>} {...formItemLayout} style={{ marginBottom: '0px' }}>
                              <FormItem style={{ display: 'inline-block', width: '120px' }}>
                                {
                                  getFieldDecorator('updateDeliveryPeriodDays', {
                                    initialValue: 7,
                                    rules: [
                                      { required: true, message: '请填写到货货期' }
                                    ]
                                  })(
                                    <InputNumber min={1} max={999} style={{ width: '120px' }} />
                                  )
                                }
                              </FormItem>

                              <span style={{ display: 'inline-block', padding: '0px 6px' }}>{ shipmentUtilMap['FUTURES']}</span>
                            </FormItem>

                            <FormItem label={<span className={`${styles.isRequired}`}>期货预付款比例</span>} {...formItemLayout}
                              style={{ marginBottom: '0px' }}
                            >
                              <FormItem style={{ display: 'inline-block', marginBottom: '10px' }}>
                                {
                                  getFieldDecorator('updatePrepaymentRatio', {
                                    rules: [
                                      { required: true, message: '请填写预付款比例' }
                                    ]
                                  })(
                                    <InputNumber style={{ width: '268px' }}
                                      min={0}
                                      max={100}
                                    />
                                  )
                                }
                              </FormItem>
                              <span style={{ display: 'inline-block', padding: '0px 6px' }}>%</span>
                            </FormItem>
                          </Row>
                          : null
                      }

                    </>
                    : null
                }

                {
                  +selectedKey === 2
                    ? <Row>
                      <Row style={{ marginBottom: '8px' }}>
                        <span className={`${styles.stepTitle}`}>选择操作</span>
                      </Row>

                      <FormItem label='下架类型' {...formItemLayout}>
                        {
                          getFieldDecorator('selectedTypes', {
                            rules: [
                              { required: true, message: '请选择下架类型' }
                            ]
                          })(
                            <Checkbox.Group onChange={this.handleChangeCheckbox}>
                              <Checkbox value={'stock'} key={`stock`}>现货下架</Checkbox>
                              <Checkbox value={'futures'} key={`futures`}>期货下架</Checkbox>
                            </Checkbox.Group>
                          )
                        }
                      </FormItem>
                    </Row>
                    : null
                }

              </Form>
            </Row>
          </Row>
        </Modal>
      </ >
    )
  }
}

const OperateModal = Form.create()(OperateForm)

export default connect()(OperateModal)
