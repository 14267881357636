import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form, Select, Alert } from 'antd'

import styles from './endSelect.module.scss'

const FormItem = Form.Item
const Option = Select.Option

export default class EndSelect extends Component {
  static propTypes = {
    list: PropTypes.array,
    onChange: PropTypes.func
  }
  state = {}

  componentDidMount () {}

  render () {
    const { list = [] } = this.props

    return (
      <Row>
        <Col span={6}>
          <Form>
            <Row className={`${styles.formitemRow}`}>
              <FormItem label={<span className={`${styles.required}`}>客户</span>} style={{ marginBottom: '0px' }}>
                <Select placeholder='请选择客户' showSearch optionFilterProp='children'
                  onChange={(value) => this.props.onChange(value)}
                >
                  {
                    list.map((enditem) => (
                      <Option value={enditem.endUserId} key={enditem.endUserId}>{ enditem.showName }</Option>
                    ))
                  }
                </Select>
              </FormItem>
            </Row>
          </Form>
        </Col>
        <Col span={6}>
          <div style={{ marginLeft: '16px' }}>
            <Alert type='warning' message={'请选择客户'} showIcon style={{ fontSize: '12px' }} />
          </div>
        </Col>
      </Row>
    )
  }
}
