import Ajax from '../utils/ajax'

export default {
  // 获取自营售后申请列表
  getSelfOrderSaledList (params) {
    return Ajax.post('/backend/eu/after/sale/page/list', params)
  },

  // 获取自营订单售后详情
  getSelfOrderSaledDetails (params) {
    return Ajax.post('/backend/eu/after/sale/detail', params)
  },

  // 自营订单售后审核
  saveSelfOrderSaledApplyAudit (params) {
    return Ajax.post('/backend/eu/after/sale/audit', params)
  },

  // 云采购售后- 获取可售后的订单产品
  getCanSaledProductListByOrderId (params) {
    return Ajax.get('/backend/cloud/purchase/aftersale/query/goods/list', { params })
  },

  // 云采购售后- 云采购订单提交售后申请
  savePurchaseOrderSaledApply (params) {
    return Ajax.post('/backend/cloud/purchase/aftersale/add', params)
  },

  // 云采购售后- 获取云采购订单售后申请列表
  getPurchaseOrderSaledList (params) {
    return Ajax.post('/backend/cloud/purchase/aftersale/page/list', params)
  },

  // 云采购售后- 获取云采购订单售后详情
  getPurchaseOrderSaledDetails (params) {
    return Ajax.get('/backend/cloud/purchase/aftersale/detail', { params })
  },

  // 云采购售后- 获取云采购售后进度详情
  getPurchaseOrderSaledProgressDetails (params) {
    return Ajax.get('/backend/cloud/purchase/aftersale/get/schedule', { params })
  },

  // 云供应售后- 获取云供应订单售后申请列表
  getSupplyOrderSaledApplyList (params) {
    return Ajax.post('/backend/cloud/supply/afterSale/listPage', params)
  },

  // 云供应售后- 获取云供应订单售后详情
  getSupplyOrderSaledDetails (params) {
    return Ajax.post('/backend/cloud/supply/afterSale/detail', params)
  },

  // 云供应售后- 云供应订单售后审核
  saveSupplyOrderSaledAudit (params) {
    return Ajax.post('/backend/cloud/supply/afterSale/audit', params)
  }
}
