import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Descriptions } from 'antd'

import { renderAddressLabel, formatBankAccount } from '@/common/utils/mUtils'

export default class invoiceLand extends Component {
  static propTypes = {
    info: PropTypes.object,
    selectedEndInfo: PropTypes.object
  }
  state = {}

  componentDidMount () { }

  render () {
    const { info = {}, selectedEndInfo = {} } = this.props

    return (
      <Row style={{ marginBottom: '20px' }}>
        <Card title='开票信息'>
          <Descriptions>
            <Descriptions.Item label='公司名称'>
              { info.name ? info.name : '----' }
            </Descriptions.Item>

            <Descriptions.Item label='税务等级号'>
              { info.taxId ? info.taxId : '----' }
            </Descriptions.Item>

            <Descriptions.Item label='公司地址'>
              {renderAddressLabel(selectedEndInfo.euEnterprise.province, selectedEndInfo.euEnterprise.city, selectedEndInfo.euEnterprise.county, selectedEndInfo.euEnterprise.address)}
            </Descriptions.Item>

            <Descriptions.Item label='电话号码'>
              { info.telephone ? info.telephone : '----'}
            </Descriptions.Item>

            <Descriptions.Item label='开户银行'>
              { info.bank ? info.bank : '----' }
            </Descriptions.Item>

            <Descriptions.Item label='开户账号'>
              { info.bankAccount ? formatBankAccount(info.bankAccount) : '----' }
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Row>
    )
  }
}
