import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import styles from './index.module.scss'

export default function GoodsBaseItem (props) {
  const { commoditySkuId, skuGoodsType, imageUrl = '', brandName = '', englishBrandName = '', name = '', model = '', itemNumber = '', canLink = false, detailsHasCart = false } = props

  return (
    <>
      {
        canLink
          ? <Link to={{ pathname: '/commodity-detail', search: `?commoditySkuId=${commoditySkuId}&skuGoodsType=${skuGoodsType}&detailsHasCart=${detailsHasCart}` }} target='_blank'>
            <div className={`${styles.goodsBaseItem}`}>
              <div className={`${styles.imgBox}`}>
                <img className={`${styles.pic}`} src={imageUrl} />
              </div>
              <div>
                <span className={`${styles.brand}`}>{ englishBrandName && englishBrandName !== '' ? `${brandName}/${englishBrandName}` : brandName }</span>
                <div className={`${styles.name}`}>{ name }</div>
                <p className={`${styles.props}`}><label className='label' style={{ color: '#999999' }}>型号</label>{ model }</p>
                <p className={`${styles.props}`}><label className='label' style={{ color: '#999999' }}>订货号</label>{ itemNumber }</p>
              </div>
            </div>
          </Link>
          : <div className={`${styles.goodsBaseItem}`}>
            <div className={`${styles.imgBox}`}>
              <img className={`${styles.pic}`} src={imageUrl} />
            </div>
            <div>
              <span className={`${styles.brand}`}>{ englishBrandName && englishBrandName !== '' ? `${brandName}/${englishBrandName}` : brandName }</span>
              <div className={`${styles.name}`}>{ name }</div>
              <p className={`${styles.props}`}><label className='label' style={{ color: '#999999' }}>型号</label>{ model }</p>
              <p className={`${styles.props}`}><label className='label' style={{ color: '#999999' }}>订货号</label>{ itemNumber }</p>
            </div>
          </div>
      }
    </>
  )
}

GoodsBaseItem.propTypes = {
  commoditySkuId: PropTypes.number,
  skuGoodsType: PropTypes.string,
  imageUrl: PropTypes.string,
  brandName: PropTypes.string,
  englishBrandName: PropTypes.string,
  name: PropTypes.string,
  model: PropTypes.string,
  itemNumber: PropTypes.string,
  canLink: PropTypes.bool,
  detailsHasCart: PropTypes.bool // 详情页是否可加入购物车
}
