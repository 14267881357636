import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Popconfirm, Button, Icon, message, Modal, Tooltip } from 'antd'

import Api from '@/common/api/index'

import EditsearchModal from '../component/editsearch-modal'
import EditbannerModal from '../component/editbanner-modal'
import EditseriesModal from '../component/editseries-modal'
import CreateareaModal from '../component/createarea-modal'
import RenovationModal from '../component/renovation-modal'

import styles from './poster.module.scss'

const { confirm } = Modal

class HomeUi1 extends Component {
  static propTypes = {
    history: PropTypes.object,
    onCancel: PropTypes.func
  }
  state = {
    modal: {
      searchModal: false,
      bannerModal: false,
      createModal: false,
      seriesModal: false,
      renovationModal: false
    },
    type: 1,
    info: {},
    rowDetail: {},
    inputConfig: {
      hideName: false,
      hideDesc: false,
      hideLink: false
    },
    autofitup: false, // 是否自动装修
    tooltipVisible: false // 提示弹窗
  }

  componentDidMount () {
    this.getMallInitialInfo()
    this.getMallHomePageContainer()
  }

  // 查询是否有初始化商城
  getMallInitialInfo = () => {
    Api.getMallInitialInfo({}).then(res => {
      const { code, data = {} } = res
      if (+code === 10000) {
        if (!data || !data.distributorId || +data.distributorId === 0) {
          this.setState({
            autofitup: true,
            tooltipVisible: true
          }, () => {
            let { tooltipVisible = false } = this.state
            if (tooltipVisible) {
              setTimeout(() => {
                this.setState({
                  tooltipVisible: false
                })
              }, 5000)
            }
          })
        }
      }
    })
  }

  // 获取配置
  getMallHomePageContainer = () => {
    Api.getMallHomePageContainer({ modelType: 0 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data
        })
      }
    })
  }

  // 获取区域详情
  getMallHomeInfo = (params) => {
    return Api.getMallHomeInfo(params)
  }

  // 保存皮肤模板
  updateAutoFitup = (params) => {
    return Api.updateAutoFitup(params)
  }

  // 保存快速装修
  handleConfirmQuickInitialSet = (params) => {
    this.updateAutoFitup({ modelType: 0 }).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.saveMallInitialSet({ ...params, modelType: 0 })
      }
    })
  }

  // 保存初始化商城参数
  saveMallInitialSet = (params) => {
    Api.saveMallInitialSet(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.closeModal()
        Modal.success({
          title: '操作成功！',
          content: <div>
            <p>可在前端商城查看看设置效果</p>
          </div>
        })
      }
    })
  }

  // 保存应用皮肤
  saveConfirmUpdateAutoFitup = (params) => {
    this.updateAutoFitup(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        Modal.success({
          title: '操作成功！',
          content: <div>
            <p>可在前端商城查看看设置效果</p>
          </div>
        })
      }
    })
  }

  // 保存修改
  saveMallHomeEdit = (params) => {
    params['modelType'] = 0
    Api.saveMallHomeEdit(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getMallHomePageContainer()
      }
    })
  }

  // 新建专区
  saveCreateSeriesArea = (params) => {
    Api.saveCreateSeriesArea(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.closeModal()
        this.getMallHomePageContainer()
      }
    })
  }

  // 保存专区名称修改
  saveModifySeriesAreaName = (params) => {
    Api.saveModifySeriesAreaName(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.closeModal()
        this.getMallHomePageContainer()
      }
    })
  }

  // 删除系列专区
  removeSeriesArea = (params) => {
    Api.removeSeriesArea(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getMallHomePageContainer()
      }
    })
  }

  showEditBannerModal = (record = {}) => {
    const { modal } = this.state

    this.setState({
      modal: {
        ...modal,
        bannerModal: true
      },
      rowDetail: record || {}
    })
  }

  // 点击品牌，推荐 ，系列，促销广告专区
  showEditSeriesAreaModal = (record = {}, count = 99999, config) => {
    const { modal } = this.state
    this.setState({
      modal: {
        ...modal,
        seriesModal: true
      },
      rowDetail: record,
      count: count || 99999,
      inputConfig: config || {
        hideName: false,
        hideDesc: false,
        hideLink: false
      }
    })
  }

  // 点击编辑产品专区
  handleLinkToRenovation = (record) => {
    this.props.history.push({ pathname: '/admin/mallRenovating', state: { record } })
  }

  // 点击创建专区
  handleCreateSpecial = (type, row = {}) => {
    const { modal } = this.state
    this.setState({
      modal: {
        ...modal,
        createModal: true
      },
      type: type,
      rowDetail: row || {}
    })
  }

  // 保存专区名称
  handleSaveSeriesAreaName = (params) => {
    const { type = 1 } = this.state

    if (type === 1) {
      this.saveCreateSeriesArea({ ...params, modelType: 0 })
    } else {
      this.saveModifySeriesAreaName({ ...params, modelType: 0 })
    }
  }

  // 点击删除系列专区
  handleRemoveSeries = (record) => {
    confirm({
      title: '确定要删除该专区？',
      onOk: () => {
        let params = {
          pageContainerId: record.id
        }
        this.removeSeriesArea(params)
      }
    })
  }

  // 开启快速装修
  handleChangeQuickFitup = (uickchecked) => {
    confirm({
      title: '温馨提示',
      content: '是否开始快速装修？',
      onOk: () => {
        const { modal } = this.state
        this.setState({
          modal: {
            ...modal,
            renovationModal: true
          }
        })
      }
    })
  }

  // 点击应用皮肤
  handleConfirmSkin = (params) => {
    confirm({
      title: '确定使用该皮肤？',
      onOk: () => {
        this.saveConfirmUpdateAutoFitup(params)
      }
    })
  }

  closeModal = () => {
    this.setState({
      modal: {
        searchModal: false,
        bannerModal: false,
        createModal: false,
        seriesModal: false,
        nameModal: false,
        renovationModal: false
      }
    })
  }

  render () {
    const { modal, info, rowDetail = {}, type = 1, count = 99999, inputConfig, autofitup = false, tooltipVisible = false } = this.state
    const { searchModal = false, createModal = false, bannerModal = false,
      seriesModal = false, renovationModal = false } = modal
    const { homePageBanner = {}, brandRegion = {}, seriesRegionList = [] } = info

    return (
      <div>
        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ textAlign: 'right', marginBottom: '16px' }}>
            <Tooltip placement='left' title={`点击此处可以给您的商城快速装修哟！`}
              visible={tooltipVisible}
            >
              <div style={{ display: 'inline-block' }}>
                {
                  autofitup
                    ? <Button type='primary' onClick={this.handleChangeQuickFitup}
                    >快速装修</Button>
                    : null
                }

              </div>
            </Tooltip>
          </Row>

          {/* topBar */}
          <div className={`${styles.topBar}`}>顶部登录栏</div>

          {/* 导航栏 */}
          <div className={`${styles.topBar}`}>导航栏</div>

          <div style={{ padding: '0px 100px' }}>
            {/* 第一层 */}
            <Row className={`${styles.mb24} ${styles.flex}`}>
              <div className={styles.leftLand}>
                {/* banner通栏 */}
                <Row className={`${styles.flex} ${styles.mb16}`}>
                  <div className={styles.cateArea}>
                    商品分类
                  </div>

                  {/* banner */}

                  <Popconfirm title='确定编辑该栏？'
                    onConfirm={() => this.showEditBannerModal(homePageBanner)}
                  >
                    <a className={styles.bannerArea}>banner</a>
                  </Popconfirm>

                  <div className={`${styles.cardArea}`}>
                    用户
                  </div>
                </Row>

                {/* 品牌专区 */}
                <Row className={styles.mb16}>
                  <Popconfirm title='确定编辑该栏？' onConfirm={() => {
                    this.showEditSeriesAreaModal(brandRegion, 99999, { hideName: true, hideDesc: true, hideLink: false, imgSize: 2 })
                  }}
                  >
                    <a className={`${styles.special}`}>
                      {brandRegion && brandRegion.name ? brandRegion.name : '品牌专区'}
                    </a>
                  </Popconfirm>
                </Row>

                <Row className={`${styles.hotsaleArea}`}>
                  热销商品
                </Row>
              </div>
            </Row>

            {/* 第二层 */}
            <Row className={`${styles.flex} ${styles.mb16}`}>
              <div className={styles.leftLand}>
                {
                  seriesRegionList && seriesRegionList.length > 0
                    ? <div>
                      {
                        seriesRegionList.map((item, i) => (
                          <Row className={`${styles.mb16} ${styles.flex}`} key={`seriesfloor-${i}`}>
                            <div className={styles.specialLand}>
                              <Popconfirm title='确定编辑该栏？'
                                onConfirm={() => {
                                  this.handleLinkToRenovation(item)
                                }}
                              >
                                <a className={`${styles.special}`}>
                                  {item.name || '专区'}
                                </a>
                              </Popconfirm>
                            </div>
                            <div className={`${styles.operateLand}`}>
                              <div className={`${styles.btns}`}>
                                <a style={{ marginRight: '10px' }}>
                                  <Icon type='edit' style={{ fontSize: '22px' }}
                                    onClick={() => this.handleCreateSpecial(2, item)}
                                  />
                                </a>

                                <a>
                                  <Icon type='close-circle' style={{ fontSize: '22px' }}
                                    onClick={() => this.handleRemoveSeries(item)}
                                  />
                                </a>
                              </div>
                            </div>
                          </Row>
                        ))
                      }
                    </div>
                    : null
                }

                <Button type='dashed' block
                  onClick={() => this.handleCreateSpecial(1, {})}
                >
                  <Icon type='plus' />
                  创建专区
                </Button>
              </div>
            </Row>

          </div>
        </Card>

        {/* 按钮操作栏 */}
        <Row className={`${styles.fixfooterLand}`}>
          <Button onClick={this.props.onCancel}>取消</Button>
          <Button type='primary' style={{ marginLeft: '16px' }}
            onClick={() => this.handleConfirmSkin({ modelType: 0 })}
          >
            应用皮肤
          </Button>
        </Row>
        {
          searchModal
            ? <EditsearchModal
              detailInfo={rowDetail}
              getDetail={(params) => this.getMallHomeInfo(params)}
              onConfirm={(params) => this.saveMallHomeEdit(params)}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          createModal
            ? <CreateareaModal
              type={type}
              detailInfo={rowDetail}
              onConfirm={(params) => this.handleSaveSeriesAreaName(params)}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          bannerModal
            ? <EditbannerModal
              detailInfo={rowDetail}
              getDetail={(params) => this.getMallHomeInfo(params)}
              onConfirm={(params) => this.saveMallHomeEdit(params)}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          seriesModal
            ? <EditseriesModal
              count={count}
              detailInfo={rowDetail}
              inputConfig={inputConfig}
              getDetail={(params) => this.getMallHomeInfo(params)}
              onConfirm={(params) => this.saveMallHomeEdit(params)}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          renovationModal
            ? <RenovationModal
              modelType={0}
              onCancel={this.closeModal}
              onConfirm={(params) => { this.handleConfirmQuickInitialSet(params) }}
            />
            : null
        }
      </div>
    )
  }
}

export default HomeUi1
