import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Empty, Button, Icon } from 'antd'

export default class EmptyWidget extends Component {
  static propTypes = {
    onConfirm: PropTypes.func
  }
  render () {
    return (
      <Empty description={<span>
        Sorry~~暂无数据<br />点击<Button type='link' onClick={() => this.props.onConfirm()}><Icon type='plus' />新增</Button>，开始您的装修吧~~
      </span>}
      />
    )
  }
}
