import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Form, Input, Button, DatePicker, message, Radio } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'

const FormItem = Form.Item
const { TextArea } = Input

class AnnounceForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }
  state = {
    type: 1,
    detailInfo: {}
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location
    this.setState({
      type: state.type,
      detailInfo: state.detailInfo
    })
  }

  // 保存公告设置
  saveMallAnnouncement = (params) => {
    Api.saveMallAnnouncement(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.props.history.push({ pathname: '/admin/announcement' })
      }
    })
  }

  // 点击发布
  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type = 1, detailInfo = {} } = this.state
        if (type === 2) {
          values['id'] = detailInfo.id
          values['beginDate'] = moment(detailInfo.beginDate).format('YYYY-MM-DD HH:mm:ss')
        } else {
          values['beginDate'] = moment().format('YYYY-MM-DD HH:mm:ss')
        }

        values['endDate'] = moment(values.endedTime).format('YYYY-MM-DD HH:mm:ss')
        delete values.endedTime

        this.saveMallAnnouncement(values)
      }
    })
  }

  // 点击取消
  handleCancel = () => {
    this.props.history.push({ pathname: '/admin/announcement' })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { type = 1, detailInfo = {} } = this.state

    const formItemLayout = {
      labelCol: { xs: { span: 24 }, sm: { span: 7 } },
      wrapperCol: { xs: { span: 24 }, sm: { span: 7 }, md: { span: 10 } }
    }

    return (
      <div>
        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ paddingTop: '15px' }}>
            <Form>
              <FormItem label='公告标题' {...formItemLayout}>
                {
                  getFieldDecorator('title', {
                    initialValue: detailInfo.title || '',
                    rules: [
                      { required: true, message: '请填写公告标题' }
                    ]
                  })(
                    <Input autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label='公告内容' {...formItemLayout}>
                {
                  getFieldDecorator('content', {
                    initialValue: detailInfo.content || '',
                    rules: [
                      { required: true, message: '请填写公告内容' }
                    ]
                  })(
                    <TextArea autoComplete='off' rows={6} />
                  )
                }
              </FormItem>
              <FormItem label='截止时间' {...formItemLayout}>
                {
                  getFieldDecorator('endedTime', {
                    initialValue: type === 2
                      ? moment(moment(detailInfo.endDate).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss')
                      : '',
                    rules: [
                      { required: true, message: '请设置截止时间' }
                    ]
                  })(
                    <DatePicker showTime />
                  )
                }
              </FormItem>
              <FormItem label='是否启用' {...formItemLayout}>
                {
                  getFieldDecorator('status', {
                    initialValue: detailInfo.status || 'ENABLE',
                    rules: [
                      { required: true, message: '请选择是否启用' }
                    ]
                  })(
                    <Radio.Group>
                      <Radio value='ENABLE'>启用</Radio>
                      <Radio value='DISABLE'>禁用</Radio>
                    </Radio.Group>
                  )
                }
              </FormItem>
              <FormItem>
                <Row style={{ textAlign: 'center' }}>
                  <Button onClick={() => this.handleCancel()}>取消</Button>
                  <Button type='primary' style={{ marginLeft: '20px' }} onClick={() => this.handleSubmit()}>发布</Button>
                </Row>
              </FormItem>
            </Form>
          </Row>
        </Card>
      </div>
    )
  }
}

const AnnouncementEdit = Form.create()(AnnounceForm)

export default AnnouncementEdit
