import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Table, Button, Modal, message } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import BomModal from '../component/bom-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { confirm } = Modal
const { pageSizeOptions } = filter

export default class EushelvesList extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'endUserId',
        formType: 'Select',
        itemParams: {
          label: '客户名称'
        },
        cptParams: {
          showSearch: true,
          placeholder: '请输入...',
          autoComplete: 'off',
          optionFilterProp: 'children',
          onSelect: (val) => {
            this.onSelectedEndUserInfo(val)
          }
        },
        options: [],
        optionValue: ['endUserId', 'showName']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {}, // 查询条件
    userInfo: {}, // 当前客户信息
    type: 1,
    bomModal: false,
    tableData: [],
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'eushelvesList-0',
      width: 34,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: 'BOM单名称',
      dataIndex: 'name'
    },
    {
      title: '日期',
      render: (text, record, index) => (
        <div>
          {moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')}
        </div>
      )
    },
    {
      title: '操作',
      key: 'eushelvesList-3',
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small' style={{ marginRight: '6px' }}
            onClick={() => this.handleJumpToEushelvesDetail(record, 2)}
          >
            查看详情
          </Button>
          <Button type='primary' size='small'
            onClick={() => this.handleDeleteShelvesRow(record)}
          >
            删除
          </Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getGuesteuUserLike()
  }

  // 查询客户信息
  getGuesteuUserLike = (val = '') => {
    let params = {
      pageNo: 1,
      pageSize: 999,
      searchName: val
    }

    Api.getGuesteuUserLike(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const { searchData } = this.state
        searchData[0].options = data.list || []

        this.setState({
          searchData
        })
      }
    })
  }

  // 选择客户
  onSelectedEndUserInfo = (val) => {
    let { searchData, query } = this.state

    if (searchData[0].options && searchData[0].options.length > 0) {
      let obj = searchData[0].options.find(item => {
        return +item.endUserId === +val
      })

      this.setState({
        searchData,
        query: {
          ...query,
          endUserId: val
        },
        userInfo: obj || {}
      })
    }
  }

  // 获取eu客户BOM单列表
  getEuShelvesList = () => {
    const { query = {} } = this.state
    let params = {
      ...query,
      pageNo: 1,
      pageSize: 999
    }

    Api.getEuShelvesList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data || []
        })
      }
    })
  }

  // 删除EU客户BOM单
  deleteEuShelvesItem = (params) => {
    Api.deleteEuShelvesItem(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getEuShelvesList()
      }
    })
  }

  // 修改订单名称
  editEuShelvesItemName = (params) => {
    Api.editEuShelvesItemName(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getEuShelvesList()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    if (!search.endUserId) {
      return message.warning('请选择客户！')
    }

    this.setState({
      query: search || {}
    }, () => {
      this.getEuShelvesList()
    })
  }

  // 点击新增BOM
  handleAddShelvesRow = (record, type) => {
    this.setState({
      type: type,
      bomModal: true,
      rowDetail: record
    })
  }

  // 点击查看BOM详情
  handleJumpToEushelvesDetail = (record, type = 1) => {
    const { userInfo } = this.state
    this.props.history.push({ pathname: '/admin/eushelvesDetail', state: { detailInfo: record, type: type, userInfo: userInfo } })
  }

  // 点击删除
  handleDeleteShelvesRow = (record) => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        const { query = {} } = this.state
        let params = {
          ...query,
          id: record.id,
          name: record.name
        }

        this.deleteEuShelvesItem(params)
      }
    })
  }

  closeModal = () => {
    this.setState({
      type: 1,
      bomModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEuShelvesList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEuShelvesList()
    })
  }

  render () {
    const { pages, tableData = [], searchData, query = {}, type = 1, bomModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Card>
          <Row style={{ marginBottom: '20px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Row>

          <Row style={{ marginBottom: '15px' }}>
            <Button type='primary'
              disabled={!query.endUserId || +query.endUserId === 0}
              style={{ marginRight: '10px', width: '110px', height: '30px' }}
              onClick={() => this.handleAddShelvesRow({}, 1)}
            >
              新增BOM单
            </Button>
          </Row>

          <Row>
            <Table rowKey='id'
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination}
            />
          </Row>
        </Card>

        {
          bomModal
            ? <BomModal
              type={type}
              detailInfo={rowDetail}
              onConfirm={(params) => this.handleJumpToEushelvesDetail(params, 1)}
              onCancel={this.closeModal}
            />
            : null
        }
      </div>
    )
  }
}
