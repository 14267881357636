import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table, Badge } from 'antd'
import moment from 'moment'

import filter from '@/common/utils/filter'

const { pageSizeOptions, auditStatusMap } = filter

class ApplyModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    getDetail: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }
  columns = [
    {
      title: '申请品牌',
      key: 'applyLog-0',
      width: 180,
      dataIndex: 'brandName'
    },
    {
      title: '申请时间',
      key: 'applyLog-1',
      width: 120,
      render: (text, record, index) => (
        <>{ record.applyTime ? moment(record.applyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '申请人',
      key: 'applyLog-2',
      width: 120,
      dataIndex: 'applyName'
    },
    {
      title: '执行结果',
      key: 'applyLog-3',
      width: 120,
      render: (text, record, index) => (
        <>
          {this.renderAuditStatus(record.auditStatus)}
        </>
      )
    },
    {
      title: '拒绝原因',
      key: 'applyLog-4',
      dataIndex: 'remarks'
    }
  ]

  componentDidMount () {
    this.getBrandApplyLog()
  }

  // 获取品牌申请日志
  getBrandApplyLog = () => {
    const { detailInfo } = this.props
    const { pages } = this.state
    let params = {
      brandId: detailInfo.brandId,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    this.props.getDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 渲染申请状态
  renderAuditStatus = (status) => {
    const obj = auditStatusMap.find(item => { return item.id === status })
    const el = obj !== undefined ? <Badge status={obj.badge} text={obj.name} /> : null

    return el
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getBrandApplyLog()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getBrandApplyLog()
    })
  }

  render () {
    const { pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Modal title='云供应品牌申请日志' bodyStyle={{ padding: '16px' }}
          width={800}
          visible
          maskClosable={false}
          onOk={this.props.onCancel}
          onCancel={this.props.onCancel}
        >
          <Table rowKey='id'
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
            scroll={{ y: 400 }}
          />
        </Modal>
      </>
    )
  }
}

export default ApplyModal
