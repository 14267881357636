import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Steps, Button, Icon } from 'antd'

const { Step } = Steps
const stepMaps = [
  { title: '确认数据', code: 0 },
  { title: '匹配数据', code: 1 },
  { title: '生成列表', code: 2 }
]

export default class MatchSteps extends Component {
  static propTypes = {
    activeStep: PropTypes.number,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  render () {
    const { activeStep = 0, onCancel, onConfirm } = this.props

    return (
      <>
        <Row gutter={8} style={{ marginBottom: '24px' }}>
          <Col span={8}>
            <Steps size='small' current={activeStep}>
              {
                stepMaps.map((item, i) => (
                  <Step title={item.title} key={`step-${i}`} />
                ))
              }
            </Steps>
          </Col>
          <Col span={8} offset={8} style={{ textAlign: 'right' }}>
            {
              activeStep === 0
                ? <>
                  <Button onClick={() => onCancel()}>取消匹配</Button>
                  <Button type='primary' style={{ marginLeft: '15px' }} onClick={() => onConfirm()}>
                    开始匹配<Icon type='arrow-right' />
                  </Button>
                </>
                : null
            }

            {
              activeStep === 1
                ? <>
                  <Button type='primary' onClick={() => onConfirm()}>
                    下一步<Icon type='arrow-right' />
                  </Button>
                </>
                : null
            }
          </Col>
        </Row>
      </>
    )
  }
}
