import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input } from 'antd'

const FormItem = Form.Item

class PageForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {}

  componentDidMount () { }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.parentId = 0
        values.type = 20000
        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form

    return (
      <div>
        <Modal title='页面名称'
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          onOk={this.handleConfirm}
        >
          <Form>
            <FormItem label='页面名称'>
              {
                getFieldDecorator('name', {
                  rules: [
                    { required: true, message: '请填写新增的页面名称' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </div>
    )
  }
}

const PageModal = Form.create()(PageForm)

export default PageModal
