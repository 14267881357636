import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Table } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter
const flowTypeMap = [
  { type: 'CASH_IN_BALANCE', label: '余额现金充入' },
  { type: 'CASH_ORDER_PAY', label: '现金支付' },
  { type: 'AFTER_SALE_REFUND', label: '售后退款' },
  { type: 'CHECK_RECHARGE', label: '承兑充值' },
  { type: 'CASH_RECHARGE', label: '现金充值' },
  { type: 'BALANCE_ORDER_PAY', label: '余额支付' },
  { type: 'CREDIT_ORDER_PAY', label: '信用支付' },
  { type: 'CASH_OUT', label: '余额提现' },
  { type: 'ORDER_CANCEL', label: '订单取消' },
  { type: 'EU_ORDER_PAYMENT_CONFIRM', label: '商家确认收款' }
]

export default class EuaccountFlow extends Component {
  static propTypes = {
    detailInfo: PropTypes.object
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }
  columns = [
    {
      title: '类型',
      key: 'accountFlow-0',
      render: (text, record, index) => (
        <div>
          {this.renderFlowTypeLabel(record.flowType)}
        </div>
      )
    },
    {
      title: '金额',
      key: 'accountFlow-1',
      render: (text, record, index) => (
        <>
          {record.amountType === 'SUB'
            ? <span>-&yen;{(+record.amount * 100 / 100).toFixed(2)}</span>
            : <span style={{ color: '#FF4444' }}>+&yen;{(+record.amount * 100 / 100).toFixed(2)}</span>}
        </>
      )
    },
    {
      title: '日期',
      key: 'accountFlow-2',
      render: (text, record, index) => (
        <div>
          {moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')}
        </div>
      )
    },
    {
      title: '备注',
      key: 'accountFlow-3',
      dataIndex: 'remarks'
    }
  ]

  componentDidMount () {
    this.getEuAccountFlow()
  }

  // 获取用户汇总
  getEuAccountFlow = () => {
    const { detailInfo } = this.props
    const { pages } = this.state
    let params = {
      endUserId: detailInfo.endUserId,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getEuAccountFlow(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  renderFlowTypeLabel = (status) => {
    const obj = flowTypeMap.find(item => { return item.type === status })
    const label = obj !== undefined ? obj.label : ''

    return label
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEuAccountFlow()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEuAccountFlow()
    })
  }

  render () {
    const { tableData = [], pages } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Row>
          <Table rowKey='id'
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Row>
      </div>
    )
  }
}
