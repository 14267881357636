import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Card, Button, Icon, Table, Form, InputNumber, Popconfirm, message, Upload, Modal, Tag, Tooltip } from 'antd'
import $lodash from 'lodash'
import { setShowLoading } from '@/reducers/action'

import SearchForm from '@/components/tool-form/index'
import BatchSearchMenu from '@/components/batchSearchMenu'
import EndSelect from './endSelect'
import ProductModal from './productModal'
import UploadModal from '@/pages/store-house/component/upload-modal'
import HandleModal from '@/pages/price/price-setting/handle-modal'
import UploadSheetModal from '@/components/uploadSheetModal'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import filter from '@/common/utils/filter'
import { getStore, getBracketStr, getCaption } from '@/common/utils/mUtils'
import { downloadExcel } from '@/common/utils/downloadFile'

const EditableContext = React.createContext()
const { confirm } = Modal
const { uploadMatchExcelUrl } = UploadApi
const { pageSizeOptions, importConfig } = filter

class EditableCell extends Component {
  static propTypes = {
    form: PropTypes.object,
    editing: PropTypes.bool,
    dataIndex: PropTypes.string,
    title: PropTypes.string,
    record: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.array
  }

  renderEditCell = (form) => {
    const { getFieldDecorator } = form
    const { editing, dataIndex, title, record, index, children, ...restProps } = this.props

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: '0px' }}>
            <Form.Item style={{ display: 'inline-block', margin: '0px' }}>
              {
                getFieldDecorator(dataIndex, {
                  rules: [
                    { required: true, message: `请填写${title}!` }
                  ],
                  initialValue: +record[dataIndex]
                })(
                  <InputNumber />
                )
              }
            </Form.Item>
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  render () {
    return <EditableContext.Consumer>{this.renderEditCell}</EditableContext.Consumer>
  }
}

class CustomerformTable extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func,
    form: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      },
      {
        name: 'keyword',
        formType: 'Input',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '请输入型号或订货号...',
          autoComplete: 'off'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    endUserList: [], // EU客户列表
    endUserId: null,
    tableData: [],
    query: {},
    editingKey: '',
    selectedRowKeys: [],
    batchKey: '', // 批量搜索类型
    productModal: false, // 添加产品
    searchFailModal: false,
    batchSearchResult: {},
    loading: false,
    handleModal: false,
    uploadSheetModal: false,
    uploadSheetDetails: {
      name: '',
      list: []
    }
  }

  columns = [
    {
      title: '序号',
      key: 'customerPriceList-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'customerPriceList-1',
      width: 150,
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'customerPriceList-2',
      width: 160,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.model }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.model }} />
          </Tooltip>

          { record.modelExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '订货号',
      key: 'customerPriceList-3',
      width: 150,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
          </Tooltip>

          { !record.modelExactMatch && record.itemNumberExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '产品名称',
      key: 'customerPriceList-5',
      dataIndex: 'name'
    },
    {
      title: '客户价格',
      key: 'customerPriceList-7',
      width: 150,
      editable: true,
      dataIndex: 'guestPrice',
      render: (text, record, index) => (
        <div>
          {
            +record.guestPrice > 0
              ? <span>&yen;{+record.guestPrice}</span>
              : <span>面议</span>
          }
        </div>
      )
    },
    {
      title: '价格组',
      key: 'customerPriceList-8',
      width: 150,
      render: (text, record, index) => (
        <>
          {
            +record.groupPrice > 0
              ? <span>&yen;{+record.groupPrice}</span>
              : '----'
          }
        </>
      )
    },
    {
      title: '操作',
      key: 'customerPriceList-9',
      width: 140,
      fixed: 'right',
      render: (text, record, index) => {
        const { editingKey } = this.state
        const editable = this.isEditing(record)

        return editable ? (
          <div>
            {
              <EditableContext.Consumer>
                {
                  form => (
                    <Button type='primary' size='small' style={{ marginRight: '8px' }}
                      onClick={() => this.handleSave(form, record)}
                    >保存</Button>
                  )
                }
              </EditableContext.Consumer>
            }

            <Popconfirm title='确定放弃编辑?'
              onConfirm={() => this.handleCancelEdit(index)}
            >
              <Button type='primary' size='small'>取消</Button>
            </Popconfirm>
          </div>
        ) : (
          <div>
            <Button type='primary' size='small' style={{ marginRight: '8px' }}
              disabled={editingKey !== ''}
              onClick={() => this.handleEdit(record.commoditySkuId)}
            >编辑</Button>
          </div>
        )
      }
    }
  ]

  componentDidMount () {
    this.getGuesteuUserLike('')
    this.getBrandList({ brandListType: 'MERCHANT_BRAND' })
    this.setTableColumns()
  }

  // 查询客户信息
  getGuesteuUserLike = (val = '') => {
    let params = {
      pageNo: 1,
      pageSize: 999,
      searchName: val
    }

    Api.getGuesteuUserLike(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          endUserList: data.list || []
        })
      }
    })
  }

  // 获取品牌
  getBrandList = (params) => {
    Api.getBrandList(params).then(res => {
      const { code, data } = res
      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        data.map(item => {
          item['showLabel'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[0].options = $lodash.concat([{ brandId: 0, showLabel: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取客户特殊价格列表
  getGuestPriceList = () => {
    const { pages, endUserId, query = {} } = this.state
    let params = {
      ...query,
      endUserId: endUserId,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getGuestPriceList(params).then(res => {
      const { code, data = {} } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.pageBean.list || [],
          pages: {
            ...pages,
            total: data.pageBean.total || 0
          },
          batchSearchResult: {
            failNumber: +data.failNumber,
            totalNumber: +data.totalNumber,
            successNumber: +data.successNumber,
            failList: data.failMessageList || []
          },
          searchFailModal: +data.failNumber > 0
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 获取可添加的产品列表
  getAllowAddSkuListbyGuestPrice = (params) => {
    return Api.getAllowAddSkuListbyGuestPrice(params)
  }

  // 更新产品客户价格
  updateGuestPrice = (params) => {
    const { endUserId } = this.state
    Api.updateGuestPrice({ ...params, endUserId: endUserId }).then(res => {
      const { code } = res
      if (+code === 10000) {
        const newData = [...this.state.tableData]
        const index = newData.findIndex(item => params.commoditySkuId === item.commoditySkuId)

        if (index > -1) {
          const item = newData[index]
          newData.splice(index, 1, { ...item, ...params })

          this.setState({ tableData: newData, editingKey: '' })
        }
      } else {
        message.warning(res.message)
      }
    })
  }

  // 保存添加的客价SKU
  saveAddSkuByGuestPrice = async (params) => {
    const { endUserId } = this.state
    params['endUserId'] = endUserId
    let res = await Api.saveAddSkuByGuestPrice(params, { HideLoading: true })

    if (+res.code === 10000) {
      this.closeModal()
      message.success('操作成功')
      setTimeout(() => {
        this.props.dispatch(setShowLoading({ showLoading: { loading: false } }))
        this.getGuestPriceList()
      }, 1500)
    } else {
      this.props.dispatch(setShowLoading({ showLoading: { loading: false } }))
      message.warning(res.message)
    }
  }

  // 删除客价SKU
  deleteSkuByGuestPrice = async (params) => {
    let res = await Api.deleteSkuByGuestPrice(params, { HideLoading: true })

    if (+res.code === 10000) {
      setTimeout(() => {
        this.props.dispatch(setShowLoading({ showLoading: { loading: false } }))
        message.success('操作成功')
        this.setState({
          selectedRowKeys: []
        }, () => this.getGuestPriceList())
      }, 1500)
    } else {
      this.props.dispatch(setShowLoading({ showLoading: { loading: false } }))
      message.warning(res.message)
    }
  }

  // 上传EXCEL智能解析
  saveUploadExcelMatchSmartparse = async (params) => {
    const res = await Api.saveUploadExcelMatchSmartparse(params)
    const { code, data } = res
    if (+code === 10000) {
      this.changeUploadSuccess(data)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages, endUserId = null } = this.state

    if (!endUserId) {
      Modal.warning({
        width: 353,
        title: '请选择客户！',
        maskClosable: true
      })

      return false
    }

    if (+search.brandId > 0) {
      search['brandId'] = [search.brandId]
    } else {
      delete search.brandId
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getGuestPriceList()
    })
  }

  // 选择END客户
  handleSelectEndUser = (value) => {
    this.setState({
      endUserId: value
    }, () => {
      this.getGuestPriceList()
    })
  }

  // 点击添加产品
  showProductModal = () => {
    this.setState({
      productModal: true
    })
  }

  // 点击删除产品
  handleDelete = () => {
    confirm({
      title: '确定删除？',
      onOk: () => {
        const { endUserId = null, selectedRowKeys } = this.state
        this.props.dispatch(setShowLoading({ showLoading: { loading: true, tip: '处理中...' } }))
        this.deleteSkuByGuestPrice({ endUserId: endUserId, commoditySkuIdList: selectedRowKeys })
      }
    })
  }

  // 批量选择
  onSelectChange = (keys) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 点击编辑按钮
  handleEdit = (key) => {
    this.setState({
      editingKey: key
    })
  }

  // 取消保存
  handleCancelEdit = (i) => {
    this.setState({
      editingKey: ''
    })
  }

  // 点击保存编辑
  handleSave = (form, record) => {
    form.validateFields((err, values) => {
      if (err) { return false }
      values['commoditySkuId'] = record.commoditySkuId

      this.updateGuestPrice(values)
    })
  }

  // 编辑中
  isEditing = (record) => record.commoditySkuId === this.state.editingKey

  // 跳转客户价格组
  handleLinkToPriceGroup = () => {
    this.props.history.push({ pathname: '/admin/guestGroupList' })
  }

  // 设置columns项
  setTableColumns = async () => {
    let res = await Api.getLowestPriceDisabeldStatus({ configType: 'LOWEST_PRICE' })
    const { code, data } = res
    if (+code === 10000 && data && data === 'ENABLE') {
      let obj = {
        title: '最低价格',
        key: 'customerPriceList-6',
        width: 150,
        dataIndex: 'lowestPrice',
        render: (text, record, index) => (
          <>
            {
              +record.lowestPrice > 0
                ? <span>&yen;{+record.lowestPrice}</span>
                : <span>面议</span>
            }
          </>
        )
      }

      this.columns.splice(6, 0, obj)
    }
  }

  // 上传excel
  changeUploadFile = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ handleModal: true })

      return false
    }

    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        setTimeout(() => {
          this.setState({
            handleModal: false,
            uploadSheetDetails: {
              name: data.tableName || '',
              list: data.sheetNames || [],
              file: info.file.originFileObj
            },
            uploadSheetModal: true
          })
        }, 2000)
      } else {
        this.closeModal()
        message.error(info.file.response.message || `${info.file.name}上传失败`)
      }
    } else if (info.file.status === 'error') {
      this.closeModal()
      message.error(`${info.file.name}上传失败`)
    }
  }

  // 点击确定选择导入EXCEL的sheet
  handleConfirmUploadSheet = (values) => {
    const formData = new FormData()

    $lodash.forEach(values, (value, key) => {
      formData.append(key, value)
    })

    this.saveUploadExcelMatchSmartparse(formData)
  }

  // 监听上传成功
  changeUploadSuccess = (params) => {
    const { endUserId } = this.state

    let config = {
      pageSourch: 'cuspriceSetting',
      endUserId: endUserId || 0,
      sheetSourch: importConfig['cuspriceSetting'].sheetSourch
    }

    this.props.history.push({ pathname: '/admin/sheetsConfirm', state: { config: config, data: { importId: params } } })
  }

  // 点击导出批量检索失败明细
  handleDownFailDetail = () => {
    const { batchSearchResult = {} } = this.state
    if (!batchSearchResult.failList || batchSearchResult.failList.length === 0) {
      return message.warning('暂无数据')
    }

    let _list = [
      ['品牌', '订货号', '型号', '失败原因']
    ]

    batchSearchResult.failList.forEach(item => {
      let arr = getBracketStr(item).split('/')
      let _res = getCaption(item)
      let _arr = []
      _arr.push(arr[0], arr[1], arr[2], arr[3], _res)

      _list.push(_arr)
    })

    downloadExcel(_list, '失败明细.xlsx')
  }

  closeModal = () => {
    this.setState({
      productModal: false,
      searchFailModal: false,
      handleModal: false,
      uploadSheetModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getGuestPriceList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getGuestPriceList()
    })
  }

  render () {
    const { searchData, pages, endUserList = [], endUserId = null, tableData = [], productModal = false, selectedRowKeys = [],
      searchFailModal = false, batchSearchResult = {}, handleModal = false, uploadSheetModal = false, uploadSheetDetails = {} } = this.state

    const components = {
      body: {
        cell: EditableCell
      }
    }

    const columns = this.columns.map(col => {
      if (!col.editable) { return col }

      return {
        ...col,
        onCell: record => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      }
    })

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 40,
      selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <div>
        <Row style={{ marginBottom: '16px' }}>
          <Card bodyStyle={{ padding: '16px' }}>
            <Row style={{ marginBottom: '12px' }}>
              <EndSelect list={endUserList} onChange={(value) => this.handleSelectEndUser(value)} />
            </Row>
            <Row style={{ display: 'inline-block', width: '120px', lineHeight: '40px', verticalAlign: 'top' }}>
              <BatchSearchMenu
                rule={{ endUserId: endUserId, message: '请选择客户' }}
                onConfirm={(values) => this.handleSearch(values)}
              />
            </Row>
            <Row style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
              <SearchForm
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Card>
        </Row>

        <Row>
          <Card bodyStyle={{ padding: '16px' }}>
            <Row style={{ marginBottom: '16px' }}>
              <Button type='primary'
                onClick={() => this.handleLinkToPriceGroup()}
              >
                <Icon type='snippets' />客户价格组
              </Button>

              <Button type='primary' style={{ marginLeft: '12px' }}
                disabled={!endUserId}
                onClick={() => this.showProductModal()}
              >
                <Icon type='plus' />添加产品
              </Button>

              <Button type='primary' style={{ marginLeft: '12px' }}
                disabled={selectedRowKeys.length === 0}
                onClick={() => this.handleDelete()}
              >
                <Icon type='delete' />删除产品
              </Button>

              <Upload name='file'
                headers={{ Token: getStore('token') }}
                showUploadList={false}
                accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                action={uploadMatchExcelUrl}
                onChange={this.changeUploadFile}
              >
                <Button type='primary' style={{ marginLeft: '12px' }}
                  disabled={!endUserId}
                >
                  <Icon type='cloud-upload' />上传EXCEL
                </Button>
              </Upload>
            </Row>

            <Row>
              <EditableContext.Provider value={this.props.form}>
                <Table rowKey='commoditySkuId'
                  components={components}
                  columns={columns}
                  dataSource={tableData}
                  rowSelection={rowSelection}
                  pagination={pagination}
                  scroll={{ x: 1400 }}
                />
              </EditableContext.Provider>
            </Row>
          </Card>
        </Row>

        { // 添加产品弹层
          productModal
            ? <ProductModal
              detailInfo={{ endUserId: endUserId }}
              type={'GUESTPRICE'}
              getProductList={(params) => this.getAllowAddSkuListbyGuestPrice(params)}
              onConfirm={(params) => {
                this.props.dispatch(setShowLoading({ showLoading: { loading: true, tip: '数据处理中...' } }))
                this.saveAddSkuByGuestPrice(params)
              }}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          searchFailModal
            ? <UploadModal type={1} hasDownBtn={1}
              totalNumber={batchSearchResult.totalNumber}
              successNumber={batchSearchResult.successNumber}
              failNumber={batchSearchResult.failNumber}
              list={batchSearchResult.failList}
              onCancel={() => this.closeModal()}
              onDownload={() => this.handleDownFailDetail()}
            />
            : null
        }

        {
          handleModal
            ? <HandleModal letterArr={['EXCEL', '分', '析', '中', '...']} />
            : null
        }

        {
          uploadSheetModal
            ? <UploadSheetModal
              name={uploadSheetDetails.name}
              list={uploadSheetDetails.list}
              file={uploadSheetDetails.file}
              onConfirm={(values) => this.handleConfirmUploadSheet(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }

      </div>
    )
  }
}

const CustomerPrice = Form.create()(CustomerformTable)

export default connect()(CustomerPrice)
