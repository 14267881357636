import Ajax from '../utils/ajax'

export default {

  // 获取eu客户报价单列表
  getEndUserQuoteList (params) {
    return Ajax.post('/api/price/list/page/euQuotation', params)
  },

  // 获取报价单详情
  getQuoteDetail (params) {
    return Ajax.post('/api/price/list/euQuotationDetails', params)
  },

  // 获取EU客户 BOM单列表
  getEuShelvesList (params) {
    return Ajax.get('/api/euBom/list', { params })
  },

  // 获取EU客户 BOM单详情
  getEuShelvesItemDetail (params) {
    return Ajax.get('/api/euBom/details', { params })
  },

  // EU客户BOM单- 快速查找
  getEuShelvesItemQuickSearch (params) {
    return Ajax.post('/api/euBom/quickSearch', params)
  },

  // 新增保存EU客户BOM单
  saveAddEuShelvesItem (params) {
    return Ajax.post('/api/euBom/insertSkuBom', params)
  },

  // 保存编辑EU客户BOM单
  saveUpdateEuShelvesItem (params) {
    return Ajax.post('/api/euBom/insertDetails', params)
  },

  // 修改EU客户BOM单名称
  editEuShelvesItemName (params) {
    return Ajax.post('/api/euBom/euBom/name', params)
  },

  // 删除EU客户BOM单
  deleteEuShelvesItem (params) {
    return Ajax.post('/api/euBom/euBom/delete', params)
  },

  // 从EU客户BOM单删除SKU
  deleteEuShelvesItemSku (params) {
    return Ajax.post('/api/euBom/deleteDetails', params)
  },

  // EU客户BOM单- 纠错查找
  getEuShelvesCorrectSearch (params) {
    return Ajax.get('/api/euBom/correctSearch', { params })
  }
}
