import Ajax from '../utils/ajax'

export default {
  // EXCEL智能解析
  saveUploadExcelMatchSmartparse (params) {
    return Ajax.post('/api/excel/import/smart/parse', params)
  },

  // 获取导入分页数据
  getUploadExcelPaginationData (params) {
    return Ajax.post('/api/product/info/management/list/page/analysis/data', params)
  },

  // 获取匹配编号
  getMatchRequestNo (params) {
    return Ajax.post('/api/common/get/requestNo', params)
  },

  // 导入匹配
  saveUploadPriceMatch (params) {
    return Ajax.post('/api/product/info/management/match', params)
  },

  // 获取匹配状态信息
  getUploadPriceMatchStatus (params) {
    return Ajax.post('/api/product/info/management/get/status', params)
  },

  // 获取导入列表详情
  getUploadPriceMatchResult (params) {
    return Ajax.post('/api/product/info/management/list/page/match/info', params)
  },

  // 保存单个修正匹配错误
  saveUpdateMetchFailResult (params) {
    return Ajax.post('/api/product/info/management/update/result', params)
  },

  // 确认导入价格设置
  saveImportPriceSetting (params) {
    return Ajax.post('/api/product/info/management/save', params)
  }
}
