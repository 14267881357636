import Ajax from '../utils/ajax'

export default {
  // 获取未读消息数
  getUnReadNotifyCount (params, headerConfig) {
    return Ajax.get('/api/msg/unreadCount', { params, headers: { ...headerConfig } })
  },

  // 获取接收的消息列表
  getReceivedNotifysList (params, headerConfig) {
    return Ajax.post('/api/msg/receiveListPage', params, { headers: { ...headerConfig } })
  },

  // 获取发送的消息列表
  getSendedNotifysList (params) {
    return Ajax.post('/api/msg/systemMsgSendListPage', params)
  },

  // 获取系统通知列表
  getIIASystemNoticeList (params) {
    return Ajax.post('/api/msg/manager/listPageSystemMsgInfo', params)
  },

  // 获取消息管理列表
  getNotifyManageList (params) {
    return Ajax.post('/api/msg/manager/listPage', params)
  },

  // 保存自定义任务发送
  saveDispenseNotifyTask (params) {
    return Ajax.post('/api/msg/manager/saveMsgSendTask', params)
  },

  // 删除消息任务
  deleteNotifyTask (params) {
    return Ajax.post('/api/msg/manager/deleteMsgSendTask', params)
  },

  // 获取发送消息的用户姓名列表
  getSendedNotifyUserNameList (params) {
    return Ajax.post('/api/msg/manager/listPageUserName', params)
  },

  // 获取需要发送消息的客户/员工列表
  getNotifySendedUserIdList (params) {
    return Ajax.post('/api/msg/manager/listPageUserId', params)
  },

  // 标记通知消息已读
  updateNoticeReadStatus (params) {
    return Ajax.post('/api/msg/markRead', params)
  },

  // 获取商城公告管理列表
  getMallAnnouncementList (params) {
    return Ajax.post('/api/msg/manager/merchant/system/announcement/listPage', params)
  },

  // 保存商家系统公告设置
  saveMallAnnouncement (params) {
    return Ajax.post('/api/msg/manager/merchant/system/announcement/save', params)
  }
}
