import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Descriptions } from 'antd'

export default class ResultModal extends Component {
  static propTypes = {
    total: PropTypes.number,
    success: PropTypes.number,
    fail: PropTypes.number,
    resemble: PropTypes.number,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {}

  componentDidMount () {}

  render () {
    const { total = 0, success = 0, fail = 0, resemble = 0 } = this.props

    return (
      <Modal title='搜索结果' visible
        maskClosable={false}
        onCancel={() => this.props.onCancel()}
        onOk={() => this.props.onConfirm()}
        okText='导出明细'
      >
        <Descriptions column={4}>
          <Descriptions.Item label='搜索总数'>{ total }</Descriptions.Item>
          <Descriptions.Item label='搜索成功'>{ success }</Descriptions.Item>
          <Descriptions.Item label='搜索失败'>{ fail }</Descriptions.Item>
          <Descriptions.Item label='近似型号'>{ resemble }</Descriptions.Item>
        </Descriptions>
        <div>若无您想找的型号，请联系IIASaaS相关人员</div>
      </Modal>
    )
  }
}
