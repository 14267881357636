import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon, Upload, Radio, message, Row, Checkbox } from 'antd'

import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'
import styless from './index.module.scss'

const { uploadNeedLogoImageUrl } = UploadApi
const tipsMaps = {
  listImages: `(建议上传200*200尺寸的图片)`,
  detailsImages: `(建议上传大小为1M以下的图片)`
}

export default class img1cp extends Component {
  static propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    list: PropTypes.array,
    onUpload: PropTypes.func,
    onDelete: PropTypes.func,
    onSelect: PropTypes.func
  }
  state={

  }

  componentDidMount () {

  }

  // 产品图上传图片
  handleChangeUpload = (info, type) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000 && data && data.imageUrl) {
        this.props.onUpload(data.imageUrl, type)
      } else {
        message.error('上传失败')
      }
    } else if (info.file.status === 'error') {
      message.error('上传失败')
    }
  }

  // 根据组件类型渲染选择框
  renderCheckBox = (item, i, type) => {
    let el = (
      <Radio checked={item.mainType === 'MAIN'}
        disabled={item.imageUrl === ''}
        onChange={() => this.props.onSelect(i, type)}
      >设置主要</Radio>
    )

    if (type === 'detailsImages') {
      el = (<Checkbox checked={item.showStatus === 'SHOW'}
        onChange={(e) => this.props.onSelect(i, type, e.target.checked)}>设为显示</Checkbox>)
    }

    return el
  }

  render () {
    const { title = '产品图', type = 'listImages', list = [] } = this.props

    return (
      <div style={{ marginBottom: '20px' }}>

        <Row>
          <div className={`'mt10'`}>
            <p>{title}：<span style={{ marginLeft: '20px', color: 'red' }}>{ tipsMaps[type] }</span></p>
            {
              list.map((item, i) => (
                <div className={`${styless.inline}`} key={`uploadProd-${i}`}>
                  <div>
                    <div className={`${styless.thumbWrap}`}>
                      <div className={styless.thumbInfo}>
                        <img src={item.imageUrl} />
                        <span className={styless.prewModal}>
                          <a href={item.imageUrl} target='_blank'>
                            <Icon type='eye' style={{ fontSize: '20px', color: 'rgba(255, 255, 255, 0.85)' }}
                            />
                          </a>
                          <a >
                            <Icon type='delete' style={{ fontSize: '20px', color: 'rgba(255, 255, 255, 0.85)' }}
                              onClick={() => this.props.onDelete(i, type)}
                            />
                          </a>
                        </span>

                      </div>
                    </div>
                    <div>{ this.renderCheckBox(item, i, type) }</div>

                  </div>
                </div>
              ))
            }

            <div className={`${styless.inline}`}>
              <Upload showUploadList={false} key={`upload-${0}`}
                accept='image/png, image/jpg, image/jpeg'
                headers={{ Token: getStore('token') }}
                action={uploadNeedLogoImageUrl}
                listType='picture-card'
                onPreview={this.handlePreview}
                onChange={(info) => this.handleChangeUpload(info, type)}
              >
                <div>
                  <Icon type='plus' />
                  <div>点击上传</div>
                </div>
              </Upload>
            </div>

          </div>

        </Row>
      </div>
    )
  }
}
