/**
 * 员工管理模块
 */
import RoleList from '@/pages/personnel/role/index' // 角色管理
import MemberList from '@/pages/personnel/member/index' // 成员管理
import Authority from '@/pages/personnel/authority/index' // 权限设置
import RoleSet from '@/pages/personnel/set-role/index' // 角色设置

export default [
  {
    path: '/admin/roleList',
    name: '角色管理',
    component: RoleList
  },
  {
    path: '/admin/memberList',
    name: '成员管理',
    component: MemberList
  },
  {
    path: '/admin/setAuthor',
    name: '权限设置',
    component: Authority
  },
  {
    path: '/admin/setRole',
    name: '员工角色',
    component: RoleSet
  }
]
