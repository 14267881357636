import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Table } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class CustomerTable extends Component {
  static propTypes = {
    onSelect: PropTypes.func,
    selectedRowKeys: PropTypes.array
  }
  state = {
    searchData: [
      {
        name: 'phoneNumber',
        formType: 'Input',
        itemParams: {
          label: '联系方式'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'creatTime',
        formType: 'RangePicker',
        itemParams: {
          label: '注册时间'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'status',
        formType: 'Select',
        itemParams: {
          label: '客户类型'
        },
        cptParams: {
        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'ENDUSER', name: '个人用户' },
          { id: 'ENTERPRISE', name: '企业用户' }
        ],
        rules: {
          initialValue: 'ALL'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    selectedRowKeys: [] // 选中的员工ID集合
  }
  columns = [
    {
      title: '序号',
      key: 'editTable-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '公司名称',
      key: 'editTable-1',
      render: (text, record, index) => (
        <div>
          { +record.enterpriseId > 0 ? record.company : '----' }
        </div>
      )
    },
    {
      title: '真实姓名',
      key: 'editTable-2',
      render: (text, record, index) => (
        <div>
          {
            record.endUser && record.endUser.realname
              ? record.endUser.realname
              : '----'
          }
        </div>
      )
    },
    {
      title: '联系方式',
      key: 'editTable-3',
      width: 100,
      render: (text, record, index) => (
        <div>
          {
            record.endUser && record.endUser.phoneNumber
              ? <span>{record.endUser.phoneNumber}</span>
              : null
          }
        </div>
      )
    },
    {
      title: '客户类型',
      key: 'editTable-4',
      render: (text, record, index) => (
        <div>
          { +record.enterpriseId > 0 ? '企业用户' : '个人用户' }
        </div>
      )
    },
    {
      title: '注册时间',
      key: 'editTable-5',
      render: (text, record, index) => (
        <div>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD hh:mm:ss') : '----' }</div>
      )
    }
  ]

  componentDidMount () {
    this.getGuestListBySeller()
  }

  // 获取客户列表
  getGuestListBySeller = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getGuestListBySeller(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  onChangeSelectedRowKeys = (keys) => {
    this.props.onSelect(keys)
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.creatTime) {
      search['beginDate'] = search.creatTime.length > 0 ? moment(search.creatTime[0]).format('YYYY-MM-DD') : undefined
      search['endDate'] = search.creatTime.length > 0 ? moment(search.creatTime[1]).format('YYYY-MM-DD') : undefined

      delete search.creatTime
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getGuestListBySeller()
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getGuestListBySeller()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getGuestListBySeller()
    })
  }

  render () {
    const { selectedRowKeys = [] } = this.props
    const { searchData, pages, tableData } = this.state

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys,
      onChange: this.onChangeSelectedRowKeys
    }

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Row style={{ marginBottom: '15px' }}>
          <SearchForm {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </Row>
        <Row>
          <Table rowKey='euId'
            dataSource={tableData}
            columns={this.columns}
            rowSelection={rowSelection}
            pagination={pagination}
          />
        </Row>
      </div>
    )
  }
}
