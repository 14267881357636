import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Button, Icon, Table, message, Switch } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import AnnounceModal from './announceModal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import styles from './announce.module.scss'

const { pageSizeOptions } = filter
// 启用状态枚举
const announceStatusOptions = [
  { id: 'ALL', name: '全部' },
  { id: 'ENABLE', name: '启用' },
  { id: 'DISABLE', name: '禁用' }
]

export default class AnnouncementManage extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'systemAnnouncementStatus',
        formType: 'Select',
        itemParams: {
          label: '启用状态'
        },
        cptParams: {

        },
        options: announceStatusOptions,
        rules: {
          initialValue: 'ALL'
        }
      },
      {
        name: 'searchTime',
        formType: 'RangePicker',
        itemParams: {
          label: '截止时间'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {
      msgType: 'ALL',
      msgReadType: 'ALL'
    },
    announceModal: false,
    rowDetail: {}
  }
  columns = [
    {
      title: '序号',
      key: 'announce-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '标题',
      key: 'announce-1',
      ellipsis: true,
      dataIndex: 'title'
    },
    {
      title: '内容',
      key: 'announce-2',
      ellipsis: true,
      dataIndex: 'content'
    },
    {
      title: '发布人',
      key: 'announce-3',
      dataIndex: 'createName'
    },
    {
      title: '发布时间',
      key: 'announce-4',
      render: (text, record, index) => (
        <div>
          { record.beginDate ? moment(record.beginDate).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </div>
      )
    },
    {
      title: '截止时间',
      key: 'announce-5',
      render: (text, record, index) => (
        <div>
          { record.endDate ? moment(record.endDate).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </div>
      )
    },
    {
      title: '是否启用',
      key: 'announce-6',
      render: (text, record, index) => (
        <div>
          <Switch
            checked={record.status === 'ENABLE'}
            checkedChildren={'启用'}
            unCheckedChildren={'禁用'}
            onChange={(checked) => this.handleChangeSwitch(checked, record)}
          />
        </div>
      )
    },
    {
      title: '操作',
      key: 'announce-7',
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small' style={{ marginRight: '6px' }}
            onClick={() => this.showAnnounceModal(record)}>查看</Button>
          <Button type='primary' size='small'
            onClick={() => this.handleLinkToEditAnnounce(record, 2)}>编辑</Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getMallAnnouncementList()
  }

  // 获取商城公告列表
  getMallAnnouncementList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.getMallAnnouncementList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      } else {
        message.error(res.message)
      }
    })
  }

  // 保存公告设置
  saveMallAnnouncement = (params) => {
    Api.saveMallAnnouncement(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getMallAnnouncementList()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.searchTime) {
      search['beginDate'] = search.searchTime.length > 0 ? moment(search.searchTime[0]).format('YYYY-MM-DD') : undefined
      search['endDate'] = search.searchTime.length > 0 ? moment(search.searchTime[1]).format('YYYY-MM-DD') : undefined
    }

    delete search.searchTime

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getMallAnnouncementList()
    })
  }

  // 切换启用禁用
  handleChangeSwitch = (checked, record) => {
    let params = {
      id: record.id,
      title: record.title,
      content: record.content,
      beginDate: moment(record.beginDate).format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(record.endDate).format('YYYY-MM-DD HH:mm:ss'),
      status: checked ? 'ENABLE' : 'DISABLE'
    }

    this.saveMallAnnouncement(params)
  }

  // 点击查看公告
  showAnnounceModal = (record) => {
    this.setState({
      announceModal: true,
      rowDetail: record
    })
  }

  // 点击新增、编辑公告
  handleLinkToEditAnnounce = (record, type) => {
    this.props.history.push({ pathname: '/admin/announcementEdit', state: { detailInfo: record, type: type } })
  }

  // 渲染table行class
  renderRowClassName = (record) => {
    let _classname = ''

    if (record.showStatus && record.showStatus === 'DISABLE') {
      _classname = `greyBackground`
    }

    return _classname
  }

  closeModal = () => {
    this.setState({
      announceModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getMallAnnouncementList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getMallAnnouncementList()
    })
  }

  render () {
    const { searchData, pages, tableData, announceModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <SearchForm {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>
        <Row>
          <Card bodyStyle={{ padding: '15px' }}>
            <Row className={`${styles.operateLand}`}>
              <Row className={`${styles.operateCol}`}>
                <Button type='primary' onClick={() => this.handleLinkToEditAnnounce({}, 1)}>
                  <Icon type='plus' />新增公告
                </Button>
              </Row>
              <Row className={`${styles.actionCol}`}>
                <span className={`greyBackground ${styles.colorBlock}`} />
                <span>表示该公告不在首页显示</span>
              </Row>
            </Row>

            <Row>
              <Table rowKey='id'
                rowClassName={(row) => this.renderRowClassName(row)}
                dataSource={tableData}
                columns={this.columns}
                pagination={pagination}
              />
            </Row>
          </Card>
        </Row>

        {
          announceModal
            ? <AnnounceModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </div>
    )
  }
}
