/**
 * 第三方应用管理
 */
import ErpwhiteList from '@/pages/outside-apply/whiteList/index' // erp白名单

export default [
  {
    path: '/admin/erpwhiteList',
    name: 'ERP白名单列表',
    component: ErpwhiteList
  }
]
