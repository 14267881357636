import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Button } from 'antd'
import $lodash from 'lodash'
import { setOrderBatchSkuList, setManualOrderAmounts } from '@/reducers/action'

import CorrectModal from '../correctModal'

import styles from './errorRow.module.scss'

const errorDescribeMaps = {
  'ERROR': {
    type: 'danger',
    btnText: '纠错',
    btnClass: styles.correctBtn,
    desc: '您输入的产品订货号/型号可能有误，可点击下方按钮修正~~'
  },
  'CHANGE_CHANNEL': {
    type: 'primary',
    btnText: '更换渠道',
    btnClass: styles.primaryBtn,
    desc: '您输入的产品其他渠道有货，需要更换么？可点击下方按钮更换~~'
  }
}

class ErrorRow extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    list: PropTypes.array,
    itemNumber: PropTypes.string,
    rowIndex: PropTypes.number,
    onRemove: PropTypes.func,
    selectedEndInfo: PropTypes.object, // EU用户信息,
    searchResult: PropTypes.string
  }
  state = {
    overModal: false,
    tableData: []
  }

  componentDidMount () { }

  // 计算相关金额
  calcSelectedAmounts = (list) => {
    /* 过滤掉错误的数据 */
    const newdatas = $lodash.filter(list, (item) => { return item.skuGoodsType })
    let _totalAmount = 0 // 商品总金额
    let _spotAmount = 0 // 现货总金额
    let _futuresFrontMoney = 0 // 期货订金

    if (newdatas.length > 0) {
      for (let record of newdatas) {
        let { supplyInfoList = [], skuGoodsType } = record

        /* 过略掉未选中的价格和没填写数量的数据 */
        let newArr = $lodash.filter(supplyInfoList, (stem) => { return stem.checked && +stem.quantity > 0 })

        if (newArr.length > 0) {
          _totalAmount += $lodash.sumBy(newArr, (atem) => { return +atem.price * 100 * +atem.quantity / 100 })
        }

        if (['SPOT', 'CLOUD_SPOT'].includes(skuGoodsType)) {
          _spotAmount += $lodash.sumBy(newArr, (atem) => { return +atem.price * 100 * +atem.quantity / 100 })
        }

        if (['FUTURES', 'CLOUD_FUTURES'].includes(skuGoodsType)) {
          _futuresFrontMoney += $lodash.sumBy(newArr, (atem) => { return +atem.price * 100 * atem.prepaymentRatio * +atem.quantity / 100 / 100 })
        }
      }
    }

    return {
      totalAmount: _totalAmount,
      spotAmount: _spotAmount,
      futuresFrontMoney: _futuresFrontMoney
    }
  }

  // 点击纠错确定
  handleConfirm = (record) => {
    let { list = [], rowIndex } = this.props
    let i = $lodash.findIndex(list, (item) => { return item.commoditySkuId === record.commoditySkuId && item.skuGoodsType === record.skuGoodsType })
    if (i > -1) {
      let { supplyInfoList } = list[i]
      let len = supplyInfoList.length

      if (supplyInfoList[len - 1]['checked']) {
        supplyInfoList[len - 1]['quantity'] += list[rowIndex]['quantity']
      } else {
        supplyInfoList[len - 1]['checked'] = true
        supplyInfoList[len - 1]['quantity'] = 1
      }
      list[i]['supplyInfoList'] = supplyInfoList
      list.splice(rowIndex, 1)
    } else {
      const { supplyInfoList = [] } = record

      if (supplyInfoList && supplyInfoList.length > 0) {
        supplyInfoList[supplyInfoList.length - 1]['checked'] = true
        supplyInfoList[supplyInfoList.length - 1]['quantity'] = list[rowIndex]['quantity']
      }

      list[rowIndex] = record
    }

    list.map((item, n) => { item['rowKey'] = `${item.commoditySkuId || 0}-${item.itemNumberModel}-${n}` })

    const _manualOrderAmounts = this.calcSelectedAmounts([...list])
    this.props.dispatch(setOrderBatchSkuList({ batchSkuList: [...list] }))
    this.props.dispatch(setManualOrderAmounts({ manualOrderAmounts: { ..._manualOrderAmounts } }))

    this.handleVisibleChange(false)
  }

  // 点击删除错误信息
  handleRemoveRow = () => {
    this.props.onRemove()
  }

  // 监听纠错弹窗显示隐藏
  handleVisibleChange = (visible) => {
    this.setState({
      overModal: visible
    })
  }

  render () {
    const { selectedEndInfo = {}, itemNumber, searchResult } = this.props
    const { overModal = false } = this.state

    return (
      <>
        <Row className={`${styles.errorRow}`}>
          <Row className={`${styles.rowContent}`}>
            <div className={`${styles.errorDetail}`}>{itemNumber}</div>
            <div className={`${styles.hintText}`}>{errorDescribeMaps[searchResult].desc}</div>
            <div>
              <Button type={errorDescribeMaps[searchResult].type} size='small' className={`${styles.btn} ${errorDescribeMaps[searchResult].btnText}`}
                onClick={() => this.handleVisibleChange(true)}
              >
                {errorDescribeMaps[searchResult].btnText}
              </Button>
            </div>
          </Row>
          <Row className={`${styles.rowAction}`}>
            <Button type='primary' size='small' onClick={() => this.handleRemoveRow()}>删除</Button>
          </Row>
        </Row>

        {
          overModal
            ? <CorrectModal
              correctSource='ORDER_CORRECT'
              keyword={itemNumber}
              searchResultEnum={searchResult}
              query={{ endUserId: selectedEndInfo.endUserId }}
              onConfirm={(sku) => this.handleConfirm(sku)}
              onCancel={() => this.handleVisibleChange(false)}
            />
            : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.batchSkuList
  }
}

export default connect(mapStateToProps)(ErrorRow)
