import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Steps, Row, Col, Select, Button, Icon, Pagination, message } from 'antd'
import $lodash from 'lodash'

import HandleModal from '@/pages/price/price-setting/handle-modal'
import OperationsModal from './operationsModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { sheetSourchMaps } from './localdatas'
import styles from './index.module.scss'

const { Step } = Steps
const Option = Select.Option
const { pageSizeOptions } = filter
const operationTypeMap = [
  { id: 'PRODUCT_STOCK_PUT_ON', name: '现货上架' },
  { id: 'PRODUCT_FUTURE_PUT_ON', name: '期货上架' },
  { id: 'PRODUCT_ADD', name: '添加到我的产品库' }
]

const stepMaps = [
  { title: '确认数据', code: '1' },
  { title: '匹配数据', code: '2' },
  { title: '生成列表', code: '3' }
]

export default class WorkSheets extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    handleModal: false,
    excelName: '',
    config: {}, // 页面参数: pageSourch: 页面来源, sheetSourch: 导入来源, endUserId: 客户ID(非必要), groupId: 价格组ID(非必要)
    tableData: [], // EXCEL源数据
    curSheetIndex: 0, // 当前选择的sheet项下标
    curSheetData: [], // 当前sheet数据源数据
    showData: [], // 页面展示数据
    importId: -1,
    operationsModal: false,
    selectOperationParams: {} // 选择的操作参数
  }

  componentDidMount () {
    const { config, data = {} } = this.props.history.location.state
    const defaultdatas = data

    let _showData = []
    let _selectedPartIndex = []
    let i = sheetSourchMaps.findIndex(item => { return item.type === config.sheetSourch })

    if (i > -1) {
      _showData = sheetSourchMaps[i].sourch
    }

    if (['PRICE_DEFAULT', 'FUTURE_PRICE_DEFAULT'].includes(config.sheetSourch)) {
      Api.getLowestPriceDisabeldStatus({ configType: 'LOWEST_PRICE' }).then(res => {
        const { code } = res
        if (+code === 10000) {
          if (res.data && res.data === 'DISABLE') {
            _showData = $lodash.filter(_showData, (item) => { return item.type !== 'lowestPriceColNum' })

            _showData.map((item, i) => {
              _selectedPartIndex.push(i)
            })
          }

          this.setState({
            importId: defaultdatas.importId,
            query: defaultdatas || {},
            showData: _showData,
            config: config || {},
            selectedPartIndex: _selectedPartIndex
          }, () => {
            this.getUploadExcelPaginationData()
          })
        }
      })
    } else {
      _showData.map((item, i) => {
        _selectedPartIndex.push(i)
      })

      this.setState({
        importId: defaultdatas.importId,
        query: defaultdatas || {},
        showData: _showData,
        config: config || {},
        selectedPartIndex: _selectedPartIndex,
        pages: { total: 0, pageNo: 1, pageSize: config && config.pageSourch === 'cloudLibrarySetting' ? 200 : 20 }
      }, () => {
        this.getUploadExcelPaginationData()
      })
    }
  }

  // 获取分页数据
  getUploadExcelPaginationData = () => {
    const { pages, importId, query } = this.state
    let params = {
      ...query,
      importId: importId,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    Api.getUploadExcelPaginationData(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          excelName: data.data.excelName,
          tableData: data.data.sheetDTOList || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        }, () => {
          this.handleChangeSheetItem(0)
        })
      }
    })
  }

  // 提交匹配
  saveUploadPriceMatch = () => {
    const { showData = [], config, importId, selectOperationParams = {} } = this.state

    let params = {
      importId: importId,
      importType: config.sheetSourch === 'PRODUCT_LIBRARY_IMPORT' ? selectOperationParams.uploadSetType : config.sheetSourch,
      ...selectOperationParams
    }

    if (config.endUserId) {
      params.endUserId = config.endUserId
    }

    if (config.commonGuestType) {
      params.commonGuestType = config.commonGuestType
      params.priceGroupId = config.priceGroupId
    }

    showData.map(item => {
      params[item.type] = item.data.columnIndex
    })

    Api.saveUploadPriceMatch(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        setTimeout(() => {
          this.props.history.push({
            pathname: '/admin/matchReport',
            state: {
              importId: importId,
              config: { ...config, importType: config.sheetSourch === 'PRODUCT_LIBRARY_IMPORT' ? selectOperationParams.uploadSetType : config.sheetSourch }
            }
          })
        }, 3000)
      }
    })
  }

  // 选择EXCEL Sheet
  handleChangeSheetItem = (i) => {
    let { tableData = [], showData } = this.state
    let _arr = tableData[i].columnDTOList || []

    let unselectObj = {
      columnName: '不选',
      cellArray: this.setNotselectCellArray(_arr[0].cellArray.length)
    }

    if (_arr.length > 0) {
      _arr.map((item, n) => {
        showData.map((row, k) => {
          if (item.columnIndex === row.selectedPartIndex) {
            showData[k].data = item || {}
          }
        })
      })
    }
    this.setState({
      curSheetIndex: i,
      curSheetData: $lodash.concat(tableData[i].columnDTOList, unselectObj),
      showData
    })
  }

  // 选择替换该列数据
  handleChangeSheetCol = (key, i) => {
    let { curSheetData = [], showData = [] } = this.state

    const n = curSheetData.findIndex(item => { return item.columnIndex === key })

    if (n > -1) {
      showData[i].data = curSheetData[n] || {}
      showData[i].selectedPartIndex = n
    }

    this.setState({
      showData
    })
  }

  // 设置不选的数据
  setNotselectCellArray = (len) => {
    let _arr = []

    for (let i = 0; i < len; i++) {
      _arr.push('')
    }

    return _arr
  }

  // 点击选择操作
  showOperationsModal = (bool) => {
    console.log('选择操作')
    this.setState({
      operationsModal: bool
    })
  }

  // 确定选择操作
  handleConfirmOperation = (values) => {
    this.setState({
      selectOperationParams: values,
      operationsModal: false
    })
  }

  // 点击开始匹配
  handleSubmit = () => {
    const { history } = this.props
    const { config, selectOperationParams = {}, showData = [] } = this.state

    if (config.sheetSourch === 'PRODUCT_LIBRARY_IMPORT' && !selectOperationParams.uploadSetType) {
      return message.warning('请选择导入的操作')
    }

    this.setState({
      handleModal: true
    }, () => {
      if (config.pageSourch === 'cloudLibrarySetting') {
        const list = showData[1].data.cellArray || []
        const brandNameList = showData[0].data.cellArray || []

        setTimeout(() => {
          this.setState({
            handleModal: false
          }, () => {
            history.push({ pathname: '/admin/cloudLibProducts', state: { keywordList: list, brandNameList: brandNameList, pageSource: 'UPLOAD_SEARCH' } })
          })
        }, 1000)
      } else {
        this.saveUploadPriceMatch()
      }
    })
  }

  // 点击取消
  handleGoBack = () => {
    this.props.history.go(-1)
  }

  renderOperationName = (status) => {
    const obj = operationTypeMap.find((o) => { return o.id === status })
    const label = obj !== undefined ? obj.name : null

    return label
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getUploadExcelPaginationData()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getUploadExcelPaginationData()
    })
  }

  render () {
    const { pages, handleModal = false, excelName = '', config = {}, tableData = [], curSheetData = [], showData = [],
      operationsModal = false, selectOperationParams = {} } = this.state

    const colSpan = showData && showData.length > 0 ? Math.floor(24 / showData.length) : 6

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div>
          <Card bodyStyle={{ padding: '16px' }}>
            <Row style={{ marginBottom: '24px' }}>
              <Col span={8}>
                <Steps size='small'>
                  {
                    stepMaps.map((item, i) => (
                      <Step title={item.title} key={`step-${i}`} />
                    ))
                  }
                </Steps>
              </Col>
              <Col span={8} offset={8} style={{ textAlign: 'right' }}>
                <Button style={{ marginRight: '12px' }} onClick={() => this.handleGoBack()}>取消匹配</Button>

                <Button type='primary' onClick={() => this.handleSubmit()}>开始匹配<Icon type='arrow-right' /></Button>
              </Col>
            </Row>

            <Row style={{ marginBottom: '24px' }} gutter={18}>
              <Col span={6}>
                <div style={{ marginBottom: '6px' }}>EXCEL名称</div>
                <div className={`${styles.name}`}>{excelName}</div>
              </Col>
              <Col span={6}>
                <div style={{ marginBottom: '6px' }}>选择列表</div>
                <div className={`${styles.name}`}>{ tableData.length > 0 ? tableData[0].sheetName : null }</div>
              </Col>

              {
                config.sheetSourch === 'PRODUCT_LIBRARY_IMPORT'
                  ? <Col span={6}>
                    <div style={{ marginBottom: '6px' }}>
                      <a className='required' onClick={() => this.showOperationsModal(true)}>选择操作</a>
                    </div>
                    <div style={{ lineHeight: '32px' }}>
                      {
                        selectOperationParams.uploadSetType
                          ? <>
                            <span>{this.renderOperationName(selectOperationParams.uploadSetType)}</span>

                            {
                              selectOperationParams.uploadSetType === 'PRODUCT_FUTURE_PUT_ON'
                                ? <>
                                  <span style={{ marginLeft: '12px' }}>
                                    <label className='label'>期货交货日期</label>
                                    <span>{ selectOperationParams.updateDeliveryPeriodDays }</span>
                                  </span>
                                  <span style={{ marginLeft: '12px' }}>
                                    <label className='label'>期货预付款比例</label>
                                    <span>{ selectOperationParams.updatePrepaymentRatio }%</span>
                                  </span>
                                </>
                                : null
                            }
                          </>
                          : null
                      }
                    </div>
                  </Col>
                  : null
              }

            </Row>

            <Row gutter={12}>
              {
                showData.map((col, j) => (
                  <Col span={colSpan} key={`col-${j}`}>
                    <div className={`${styles.colTitle}`}>
                      <div className={`${styles.titleLabel}`}>{col.name}</div>

                      <div className={`${styles.titleControl}`}>
                        <Select style={{ width: '100%' }}
                          value={col.selectedPartIndex}
                          key={`selection-${j}`}
                          size='small'
                          placeholder='换一列'
                          onChange={(val) => this.handleChangeSheetCol(val, j)}
                        >
                          {
                            curSheetData.length > 0 && curSheetData.map((item, n) => (
                              <Option value={item.columnIndex} key={`opt-${j}-${n}`}>{item.columnName}</Option>
                            ))
                          }
                        </Select>
                      </div>
                    </div>

                    {
                      col.data && col.data.cellArray && col.data.cellArray.length > 0
                        ? <ul className={`${styles.listWrapper}`}>
                          {
                            col.data.cellArray.map((sheetCol, i) => (
                              <li className={`${styles.listItem}`} key={`brandCol-${j}-${i}`}>
                                {
                                  sheetCol && sheetCol !== ''
                                    ? <span>{sheetCol}</span>
                                    : <span>----</span>
                                }

                              </li>
                            ))
                          }
                        </ul>
                        : null
                    }
                  </Col>
                ))
              }
            </Row>

            <Row style={{ marginTop: '24px' }}>
              <Pagination {...pagination} />
            </Row>
          </Card>
        </div>

        {
          handleModal
            ? <HandleModal
              letterArr={['型', '号', '匹', '配', '中']}
            />
            : null
        }

        {
          operationsModal
            ? <OperationsModal
              options={operationTypeMap}
              onConfirm={(values) => this.handleConfirmOperation(values)}
              onCancel={() => this.showOperationsModal(false)}
            />
            : null
        }
      </>
    )
  }
}
