import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Table, InputNumber } from 'antd'

import Api from '@/common/api/index'

export default class FuturesInModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'futuresProductCol-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'futuresProductCol-1',
      dataIndex: 'brandName',
      ellipsis: true
    },
    {
      title: '型号',
      key: 'futuresProductCol-2',
      width: 140,
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'futuresProductCol-3',
      width: 130,
      dataIndex: 'itemNumber',
      ellipsis: true
    },
    {
      title: '订单数量',
      key: 'futuresProductCol-4',
      dataIndex: 'quantity'
    },
    {
      title: '已订数量',
      key: 'futuresProductCol-5',
      dataIndex: 'arrivalQuantity'
    },
    {
      title: '待订数量',
      key: 'futuresProductCol-6',
      render: (text, record, index) => (
        <>{ +(record.quantity) - +(record.arrivalQuantity)}</>
      )
    },
    {
      title: '入库数量',
      key: 'futuresProductCol-7',
      render: (text, record, index) => {
        return (
          <>
            {
              record.receiptQuantity !== 0
                ? <InputNumber size='small' min={1} value={record.receiptQuantity} onChange={(value) => { this.handleLowestFuture(value, index) }} />
                : null
            }
          </>

        )
      }
    }
  ]

  componentDidMount () {
    const { detailInfo = {} } = this.props
    const params = {
      orderId: detailInfo.orderId,
      purchaseOrderId: detailInfo.orderType === 'ORDER_CLOUD_FUTURES' ? detailInfo.outboundOrderNo : null,
      pageNo: 1,
      pageSize: 999
    }
    if (detailInfo.orderType === 'ORDER_FUTURES') {
      this.getStockFuturesInDetail(params)
    }

    if (detailInfo.orderType === 'ORDER_CLOUD_FUTURES') {
      this.getCloudfuturesOrderProductDetails(params)
    }
  }

  // 获取自营期货订单产品详情
  getStockFuturesInDetail = async (params) => {
    const res = await Api.getStockFuturesInDetail(params)
    const { code, data } = res
    if (+code === 10000) {
      if (data && data.list) {
        data.list.forEach(item => {
          item.receiptQuantity = +(item.quantity) - +(item.arrivalQuantity)
        })
      }
      this.setState({
        tableData: data.list || []
      })
    }
  }

  // 获取云期货供应订单产品详情
  getCloudfuturesOrderProductDetails = async (params) => {
    const res = await Api.getCloudfuturesOrderProductDetails(params)
    const { code, data } = res
    if (+code === 10000) {
      if (data && data.list) {
        data.list.forEach(item => {
          item.receiptQuantity = +(item.quantity) - +(item.arrivalQuantity)
        })
      }
      this.setState({
        tableData: data.list || []
      })
    }
  }

  // 监听入库数量输入
  handleLowestFuture = (value, i) => {
    let { tableData = [] } = this.state
    if (value <= +(tableData[i].quantity) - +(tableData[i].arrivalQuantity)) {
      tableData[i].receiptQuantity = value
    } else {
      tableData[i].receiptQuantity = +(tableData[i].quantity) - +(tableData[i].arrivalQuantity)
    }

    this.setState({
      tableData
    })
  }

  // 点击确定
  handleConfirm = () => {
    const { detailInfo = {} } = this.props
    const { tableData } = this.state
    let unit = []

    tableData.forEach(item => {
      if (item.receiptQuantity !== 0) {
        let obj = {
          commoditySkuId: item.commoditySkuId,
          quantity: item.receiptQuantity
        }
        unit.push(obj)
      }
    })

    const params = {
      orderId: detailInfo.orderId,
      purchaseOrderId: detailInfo.orderType === 'ORDER_CLOUD_FUTURES' ? detailInfo.outboundOrderNo : null,
      putInQuantity: unit
    }

    this.props.onConfirm(params, detailInfo.orderType)
  }

  render () {
    const { tableData = [] } = this.state

    return (
      <>
        <Modal title='期货入库' width={1000} bodyStyle={{ padding: '16px' }}
          visible
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Row style={{ paddingRight: '16px', maxHeight: '500px', overflow: 'auto' }}>
            <Table
              rowKey='commoditySkuId'
              columns={this.columns}
              dataSource={tableData}
              pagination={false}
            />
          </Row>
        </Modal>
      </>
    )
  }
}
