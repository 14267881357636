import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Table, Button } from 'antd'

import Api from '@/common/api/index'

export default class DetailModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20, options: ['10', '20', '50', '100', '200'] },
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'sideSku-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'sideSku-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'sideSku-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'sideSku-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '单价',
      key: 'sideSku-4',
      dataIndex: 'price',
      render: (text, record, index) => (
        <>&yen;{+record.price}</>
      )
    },
    {
      title: '数量',
      key: 'sideSku-5',
      dataIndex: 'quantity'
    }
  ]

  componentDidMount () {
    this.getOutsideSkuList()
  }

  // 获取外部订单产品列表
  getOutsideSkuList = () => {
    const { detailInfo = {} } = this.props

    let params = {
      orderNo: detailInfo.orderNo,
      pageNo: 1,
      pageSize: 9999
    }

    Api.getOutsideSkuList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || []
        })
      }
    })
  }

  handleConfirm = () => {
    const { detailInfo = {} } = this.props
    const { tableData = [] } = this.state
    let params = {
      searchList: tableData,
      endUserId: detailInfo.endUserId,
      orderNo: detailInfo.orderNo
    }

    this.props.onConfirm(params)
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  renderModalFooter = (type) => {
    let footerArr = [
      <Button key='modalBtn-0'
        onClick={() => this.handleCancel()}
      >
        关闭
      </Button>
    ]

    if (type === 'FAIL') {
      footerArr.push(
        <Button key='modalBtn-1' type='primary'
          onClick={() => this.handleConfirm()}
        >
          创建手工订单
        </Button>
      )
    }

    return footerArr
  }

  render () {
    const { detailInfo = {} } = this.props
    const { tableData } = this.state

    return (
      <div>
        <Modal bodyStyle={{ padding: '15px' }}
          title='订单产品明细'
          width={1000}
          visible
          maskClosable={false}
          onCancel={this.props.onCancel}
          footer={this.renderModalFooter(detailInfo.result)}
        >
          <Row>
            <Table rowKey='id'
              dataSource={tableData}
              columns={this.columns}
              pagination={false}
              scroll={{ y: 500 }}
            />
          </Row>
        </Modal>
      </div>
    )
  }
}
