export const sheetSourchMaps = [
  {
    type: 'FUTURE_PRICE_DEFAULT', // 期货
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 },
      { name: '最低价格', type: 'lowestPriceColNum', data: {}, selectedPartIndex: 2 },
      { name: '通用价格', type: 'defaultPriceColNum', data: {}, selectedPartIndex: 3 }
    ]
  },
  {
    type: 'PRICE_DEFAULT',
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 },
      { name: '最低价格', type: 'lowestPriceColNum', data: {}, selectedPartIndex: 2 },
      { name: '通用价格', type: 'defaultPriceColNum', data: {}, selectedPartIndex: 3 }
    ]
  },
  {
    type: 'FUTURE_PRICE_GUEST', // 期货
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 },
      { name: '客户价格', type: 'guestPriceColNum', data: {}, selectedPartIndex: 2 }
    ]
  },
  {
    type: 'PRICE_GUEST',
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 },
      { name: '客户价格', type: 'guestPriceColNum', data: {}, selectedPartIndex: 2 }
    ]
  },
  {
    type: 'FUTURE_PRICE_GROUP_COMMON', // 期货
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 }
    ]
  },
  {
    type: 'PRICE_GROUP_COMMON',
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 }
    ]
  },
  {
    type: 'FUTURE_PRICE_GROUP_GUEST', // 期货
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 }
    ]
  },
  {
    type: 'PRICE_GROUP_GUEST',
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 }
    ]
  },
  {
    type: 'PRODUCT_STOCK',
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 },
      { name: '总库存', type: 'totalStockColNum', data: {}, selectedPartIndex: 2 }
    ]
  },
  {
    type: 'PRODUCT_CLOUD_SUPPLY',
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 },
      { name: '供应价格', type: 'guestPriceColNum', data: {}, selectedPartIndex: 2 }
    ]
  },
  {
    type: 'ENQUIRY',
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '型号', type: 'modelColNum', data: {}, selectedPartIndex: 1 },
      { name: '订货号', type: 'itemNumbeColNum', data: {}, selectedPartIndex: 2 },
      { name: '数量', type: 'quantityColNum', data: {}, selectedPartIndex: 3 }
    ]
  },
  {
    type: 'PRODUCT_CLOUD_FUTURES_SUPPLY', // 云供应期货导入设置
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 },
      { name: '价格', type: 'guestPriceColNum', data: {}, selectedPartIndex: 2 },
      { name: '货期', type: 'dekiveryTimeColNum', data: {}, selectedPartIndex: 3 },
      { name: '预付比例', type: 'prepaymentColNum', data: {}, selectedPartIndex: 4 }
    ]
  },
  {
    type: 'PRODUCT_LIBRARY_IMPORT',
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 }
    ]
  },
  {
    type: 'CLOUDLIBRARY_BATCHSEARCH_PRODUCT', // 云库产品列表-导入搜索
    sourch: [
      { name: '品牌', type: 'brandNameColNum', data: {}, selectedPartIndex: 0 },
      { name: '匹配编号', type: 'matchNoColNum', data: {}, selectedPartIndex: 1 }
    ]
  }
]
