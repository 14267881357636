import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Tabs, Card } from 'antd'

import ReceivedInformation from './receivedInformation'
import SendedInformation from './sendedInformation'

import styles from './index.module.scss'

const { TabPane } = Tabs

export default class InformationList extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    activeTab: 'RECEIVED'
  }

  componentDidMount () {
    const { state = {} } = this.props.history.location
    let { activeTab = 'RECEIVED' } = this.state
    if (state.activeTab && state.activeTab !== '') {
      activeTab = state.activeTab
    }

    this.setState({
      activeTab
    })
  }

  // 点击切换tab
  handleChangeTabPane = (key) => {
    this.setState({
      activeTab: key
    })
  }

  render () {
    const { activeTab = 'RECEIVED' } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '12px 0px 0px 0px' }} style={{ marginBottom: '15px' }}>
          <Row className={styles.tabsLand}>
            <Tabs onChange={this.handleChangeTabPane}>
              <TabPane tab={'已接收'} key={'RECEIVED'} />
              <TabPane tab={'已发送'} key={'SENDED'} />
            </Tabs>
          </Row>
        </Card>

        {
          activeTab === 'RECEIVED'
            ? <ReceivedInformation history={this.props.history} />
            : null
        }

        {
          activeTab === 'SENDED'
            ? <SendedInformation history={this.props.history} />
            : null
        }
      </>
    )
  }
}
