import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input } from 'antd'

const FormItem = Form.Item

class BomnameModal extends Component {
  static propTypes = {
    form: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {}

  componentDidMount () { }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form

    return (
      <div>
        <Modal title='新增BOM单'
          visible
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Form>
            <FormItem label='BOM单名称'>
              {
                getFieldDecorator('name', {
                  rules: [
                    { required: true, message: '该项必填' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </div>
    )
  }
}

const BomModal = Form.create()(BomnameModal)

export default BomModal
