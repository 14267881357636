import React, { Component } from 'react'
import { Tabs, Card } from 'antd'

import SelfSaled from '@/pages/saled/selfSaled/index'
import PurchaseSaled from '@/pages/saled/purchaseSaled/index'
import SupplySaled from '@/pages/saled/supplySaled/index'

import styles from './index.module.scss'

const { TabPane } = Tabs
const tabPaneMaps = [
  { id: 'SELF_SALED', name: '自营售后申请' },
  { id: 'PURCHASE_SALED', name: '云采购售后申请' },
  { id: 'SUPPLY_SALED', name: '云供应售后申请' }
]

export default class SaledOrder extends Component {
  state = {
    activeKey: 'SELF_SALED'
  }

  handleTabPane = (value) => {
    this.setState({
      activeKey: value
    })
  }

  render () {
    const { activeKey = 'SELF_SALED' } = this.state

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '4px 0px 0px 0px' }} bordered={false}>
            <div className={`${styles.tabBar}`}>
              <Tabs value={activeKey} onChange={(value) => this.handleTabPane(value)}>
                {
                  tabPaneMaps.map((item) => (
                    <TabPane key={item.id} tab={item.name} />
                  ))
                }
              </Tabs>
            </div>
          </Card>
        </div>

        {
          activeKey === 'SELF_SALED' ? <SelfSaled /> : null
        }

        {
          activeKey === 'PURCHASE_SALED' ? <PurchaseSaled /> : null
        }

        {
          activeKey === 'SUPPLY_SALED' ? <SupplySaled /> : null
        }
      </>
    )
  }
}
