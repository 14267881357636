import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Card, Modal, message } from 'antd'
import $lodash from 'lodash'

import SearchForm from './searchForm/index'
import GoodsTable from './goodsTable/index'
import Footer from './footer/index'

import Api from '@/common/api/index'

const { warning, confirm, success } = Modal

// 快速下单
function QuickOrderForm (props) {
  const { history } = props
  const [tableData, setTableData] = useState([])
  const [checkedAll, setCheckedAll] = useState(false)
  const [selectedTotalValues, setSelectedTotalValues] = useState({ goods: 0, nums: 0, amount: 0 })

  /* 快速下单- 获取搜索结果
   * 1- 每次搜索结果叠加, 不清空tableData
   * 2- 单品若存在tableData， 则单品数量累加，若不存在或型号订货号错误 则push到tableData
   * 3- 默认选中单品最后一个价格
  */
  const getPurchaseCloudBatchSearch = async (params) => {
    const res = await Api.getPurchaseCloudBatchSearch(params)
    const { code, data = [] } = res
    if (+code === 10000) {
      $lodash.forEach(data, (item) => {
        if (item.quickOrderSearchResult === 'SUCCESS') {
          const n = $lodash.findIndex(tableData, (record) => { return record.commoditySkuId === item.commoditySkuId && record.skuGoodsType === item.skuGoodsType })

          if (n > -1) {
            const { supplyInfoList = [] } = tableData[n]
            const len = supplyInfoList.length
            if (supplyInfoList[len - 1].checked) {
              supplyInfoList[len - 1].quantity += item.quantity
            } else {
              supplyInfoList[len - 1].checked = true
            }

            tableData[n].supplyInfoList = supplyInfoList
          } else {
            item.supplyInfoList = setCheckedDefaultPriceItem(item)
            item.checked = false
            tableData.push(item)
          }
        } else {
          tableData.push(item)
        }
      })

      const k = $lodash.findIndex(tableData, (record) => { return record.quickOrderSearchResult === 'SUCCESS' && !record.checked })
      const obj = calcSelectedAmount([...tableData])

      setTableData([...tableData])
      setCheckedAll(k === -1)
      setSelectedTotalValues(obj)
    } else {
      message.warning(res.message)
    }
  }

  // 添加到购物车
  const saveAddToCart = async (params) => {
    const res = await Api.saveAddToCart(params)
    const { code } = res
    if (+code === 10000) {
      success({
        title: '加入购物车成功~'
      })
    } else {
      message.warning(res.message)
    }
  }

  // EXCEL上传产品信息
  const handleChangeUploadSkuList = (info) => {
    if (info.file.status === 'done') {
      const { code, data = [] } = info.file.response
      if (+code === 10000) {
        $lodash.forEach(data, (item) => {
          if (item.quickOrderSearchResult === 'SUCCESS') {
            const n = $lodash.findIndex(tableData, (record) => { return record.commoditySkuId === item.commoditySkuId && record.skuGoodsType === item.skuGoodsType })

            if (n > -1) {
              const { supplyInfoList = [] } = tableData[n]
              const len = supplyInfoList.length
              if (supplyInfoList[len - 1].checked) {
                supplyInfoList[len - 1].quantity += item.quantity
              } else {
                supplyInfoList[len - 1].checked = true
              }

              tableData[n].supplyInfoList = supplyInfoList
            } else {
              item.supplyInfoList = setCheckedDefaultPriceItem(item)
              item.checked = false
              tableData.push(item)
            }
          } else {
            tableData.push(item)
          }
        })

        const k = $lodash.findIndex(tableData, (record) => { return record.quickOrderSearchResult === 'SUCCESS' && !record.checked })
        const obj = calcSelectedAmount([...tableData])

        setTableData([...tableData])
        setCheckedAll(k === -1)
        setSelectedTotalValues(obj)
      } else {
        message.error('上传失败')
      }
    }
  }

  // 保存新增询价单
  const saveAddEnquireOrder = async (params) => {
    const res = await Api.saveAddEnquireOrder(params)
    const { code } = res
    if (+code === 10000) {
      Modal.success({
        title: '操作成功',
        content: '请到我的询价中查看具体状态'
      })
    }
  }

  // 设置价格默认选中
  const setCheckedDefaultPriceItem = (record = {}) => {
    const { supplyInfoList = [] } = record

    if (supplyInfoList && supplyInfoList.length > 0) {
      supplyInfoList[supplyInfoList.length - 1].checked = true
      supplyInfoList[supplyInfoList.length - 1].quantity = record.quantity || 1
    }
    return supplyInfoList
  }

  /* 点击选择、取消选择
   *
  */
  const handleCheckedGoods = (checked, index) => {
    if (index > -1) {
      tableData[index].checked = checked
    } else {
      tableData.forEach((record) => {
        if (record.quickOrderSearchResult === 'SUCCESS') {
          record.checked = checked
        }
      })
    }

    const k = $lodash.findIndex(tableData, (record) => { return record.quickOrderSearchResult === 'SUCCESS' && !record.checked })
    const obj = calcSelectedAmount([...tableData])

    setTableData([...tableData])
    setCheckedAll(k === -1)
    setSelectedTotalValues(obj)
  }

  // 删除SKU项
  const removeSkuItem = (indexs) => {
    confirm({
      title: '确定删除？',
      onOk: () => {
        $lodash.pullAt(tableData, indexs)

        const k = $lodash.findIndex(tableData, (record) => { return record.quickOrderSearchResult === 'SUCCESS' && !record.checked })
        const obj = calcSelectedAmount([...tableData])

        setTableData([...tableData])
        setCheckedAll(k === -1)
        setSelectedTotalValues(obj)
      }
    })
  }

  // 点击删除
  const handleRemoveSku = (type, index) => {
    if (type === 'ALL') {
      const checkdatas = $lodash.filter(tableData, (record) => { return record.checked })
      if (checkdatas.length === 0) {
        warning({
          title: '至少选中一种哦!'
        })

        return false
      }

      let _indexs = []
      tableData.forEach((record, i) => {
        if (record.checked) {
          _indexs.push(i)
        }
      })

      removeSkuItem(_indexs)
    } else {
      removeSkuItem([index])
    }
  }

  // 确定纠错
  const handleConfirmCorrect = (skuParams, index) => {
    const n = $lodash.findIndex(tableData, (record) => { return record.commoditySkuId === skuParams.commoditySkuId && record.skuGoodsType === skuParams.skuGoodsType })
    if (n > -1) {
      const { supplyInfoList = [] } = tableData[n]
      const len = supplyInfoList.length

      if (supplyInfoList[len - 1].checked) {
        supplyInfoList[len - 1].quantity = supplyInfoList[len - 1].quantity + 1
      } else {
        supplyInfoList[len - 1].checked = true
        supplyInfoList[len - 1].quantity = 1
      }

      tableData[n].supplyInfoList = [...supplyInfoList]

      tableData.splice(index, 1)
    } else {
      skuParams.checked = false
      skuParams.quickOrderSearchResult = 'SUCCESS'

      const _supplyInfoList = setCheckedDefaultPriceItem(skuParams)

      skuParams.supplyInfoList = [..._supplyInfoList]

      tableData[index] = skuParams
    }

    const k = $lodash.findIndex(tableData, (record) => { return record.quickOrderSearchResult === 'SUCCESS' && !record.checked })
    const obj = calcSelectedAmount([...tableData])

    setTableData([...tableData])
    setCheckedAll(k === -1)
    setSelectedTotalValues(obj)
  }

  // 监听数量输入
  const handleChangeQuantity = (value, pricedex, rowdex) => {
    tableData[rowdex].supplyInfoList[pricedex].quantity = value

    const obj = calcSelectedAmount([...tableData])
    setTableData([...tableData])
    setSelectedTotalValues(obj)
  }

  // 监听价格选择
  const handleChangeCheckedPrice = (checked, pricedex, rowdex) => {
    const { supplyInfoList = [] } = tableData[rowdex]

    supplyInfoList[pricedex].checked = checked
    supplyInfoList[pricedex].quantity = +supplyInfoList[pricedex].quantity > 0 ? +supplyInfoList[pricedex].quantity : 1

    tableData[rowdex].supplyInfoList = [...supplyInfoList]

    const obj = calcSelectedAmount([...tableData])

    setTableData([...tableData])
    setSelectedTotalValues(obj)
  }

  // 计算选择的商品数量和金额
  const calcSelectedAmount = (tableData = []) => {
    let obj = { goods: 0, nums: 0, amount: 0 }

    // 过滤未选择的产品
    const datas = $lodash.filter(tableData, (record) => { return record.checked })

    obj.goods = datas.length

    $lodash.forEach(datas, (record) => {
      const { supplyInfoList = [] } = record
      /* 过略掉未选中的价格和没填写数量的数据 */
      const newArr = $lodash.filter(supplyInfoList, (stem) => { return stem.checked && +stem.quantity > 0 })

      if (newArr.length > 0) {
        obj.nums += $lodash.sumBy(newArr, 'quantity')
        obj.amount += $lodash.sumBy(newArr, (atem) => { return +($lodash.round(+atem.price * +atem.quantity, 2)) })
      }
    })

    return obj
  }

  // 点击加入购物车
  const handleConfirmAddToCart = () => {
    // 过滤掉未选中的产品
    const checkdatas = $lodash.filter(tableData, (record) => { return record.checked })
    if (checkdatas.length === 0) {
      warning({
        title: '至少选中一种商品哦!'
      })

      return false
    }

    for (const record of checkdatas) {
      const { supplyInfoList } = record
      // 过滤未选中价格 和 未填写数量
      const newArr = $lodash.filter(supplyInfoList, (item) => { return item.checked && item.quantity > 0 })
      if (newArr.length === 0) {
        warning({
          title: <>订货号:&nbsp;{record.itemNumber}&nbsp;请选择产品价格并且填写数量</>,
          maskClosable: true
        })

        return false
      }

      for (const row of newArr) {
        if (record.skuGoodsType === 'CLOUD_SPOT' && +row.quantity > +row.stock) {
          Modal.warning({
            title: <>订货号:&nbsp;{record.itemNumber}&nbsp;现货库存不足</>,
            maskClosable: true
          })

          return false
        }
      }

      const rowTotal = $lodash.sumBy(newArr, 'quantity')

      if (rowTotal < +record.minOrder) {
        warning({
          title: <>订货号:&nbsp;{record.itemNumber}&nbsp;最少购买{+record.minOrder}件</>,
          maskClosable: true
        })

        return false
      }
    }

    const cartList = []

    checkdatas.forEach(item => {
      item.supplyInfoList.forEach(itemm => {
        if (item.checked === true && itemm.checked === true) {
          const obj = {
            commoditySkuId: item.commoditySkuId,
            goodsType: item.skuGoodsType,
            supplierId: itemm.supplierId,
            quantity: itemm.quantity
          }
          cartList.push(obj)
        }
      })
    })

    if (cartList.length === 0) {
      return message.warning('暂无可添加的商品')
    }

    saveAddToCart({ cartList: cartList })
  }

  // 点击去购物车
  const handleLinkToCart = () => {
    history.push({ pathname: '/admin/cart' })
  }

  // 点击一键询价
  const handleEnquiry = () => {
    // 过滤掉未选中的产品
    const checkdatas = $lodash.filter(tableData, (record) => { return record.checked })
    if (checkdatas.length === 0) {
      warning({
        title: '至少选中一种商品哦!'
      })

      return false
    }

    const newdatas = $lodash.map(checkdatas, (record) => {
      const { supplyInfoList = [] } = record
      const newarr = $lodash.filter(supplyInfoList, (o) => { return o.checked })

      return {
        ...record,
        quantity: newarr.length > 0 ? $lodash.sumBy(newarr, 'quantity') : 1,
        unit: newarr.length > 0 && newarr[0].unit ? newarr[0].unit : null,
        expectDeliveryPeriodDays: record.deliveryPeriodDays,
        expectPrice: record.price,
        matchingState: 'MATCHED',
        dataType: 'SEARCH'
      }
    })

    const params = {
      enquiryOrderDetails: newdatas,
      enquiryType: 'MERCHANT_QUICK_ORDER',
      enquiryState: 'NO',
      quotationNumber: 1
    }

    saveAddEnquireOrder(params)
  }

  return (
    <>
      <Card bodyStyle={{ padding: '15px' }}>
        <SearchForm
          onSearch={(params) => getPurchaseCloudBatchSearch(params)}
          onUploadGoods={(info) => handleChangeUploadSkuList(info)}
        />

        <GoodsTable
          checkedAll={checkedAll}
          tableData={tableData}
          onSelectedAll={(checked) => handleCheckedGoods(checked, -1)}
          onSelectedGoods={(checked, index) => handleCheckedGoods(checked, index)}
          onRemoveSkuItem={(index) => handleRemoveSku('SINGLE', index)}
          onCorrect={(skuParams, index) => handleConfirmCorrect(skuParams, index)}
          onChangeQuantity={(val, pricedex, rowdex) => handleChangeQuantity(val, pricedex, rowdex)}
          onChangeCheckedPrice={(checked, pricedex, rowdex) => handleChangeCheckedPrice(checked, pricedex, rowdex)}
        />

        <Footer
          totalValues={selectedTotalValues}
          checkboxDisabled={tableData.length === 0}
          checkedAll={checkedAll}
          onSelectedAll={(checked) => handleCheckedGoods(checked, -1)}
          onRemove={() => handleRemoveSku('ALL')}
          onAddToCart={() => handleConfirmAddToCart()}
          onLinkToCart={() => handleLinkToCart()}
          onCreateEnquire={() => handleEnquiry()}
        />
      </Card>
    </>
  )
}

QuickOrderForm.propTypes = {
  history: PropTypes.object
}

const QuickOrder = Form.create()(QuickOrderForm)

export default QuickOrder
