import React, { Component } from 'react'
import { Card, Tabs } from 'antd'

import UninvoiceList from './uninvoice-list' // 可开票的云采购订单列表
import InvoiceRecord from './invoiced-record' // 开票申请历史

const { TabPane } = Tabs
const tabMaps = [
  { key: 'INVOICE_OPENING', label: '开票中' },
  { key: 'INVOICE_OPENED', label: '开票完成' }
]

export default class PurchaseInvoice extends Component {
  static propTypes = {}
  state = {
    activeKey: 'INVOICE_OPENING'
  }

  componentDidMount () { }

  // 点击选择tab类型
  handleChangeTab = (key) => {
    this.setState({
      activeKey: key
    })
  }

  render () {
    const { activeKey = 'INVOICE_OPENING' } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }}>
          <Tabs onChange={this.handleChangeTab}>
            {
              tabMaps.map(item => (
                <TabPane tab={item.label} key={item.key} />
              ))
            }
          </Tabs>

          {
            activeKey === 'INVOICE_OPENING'
              ? <UninvoiceList />
              : null
          }

          {
            activeKey === 'INVOICE_OPENED'
              ? <InvoiceRecord />
              : null
          }
        </Card>
      </>
    )
  }
}
