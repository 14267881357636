import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Col, Form, Button, Input, message } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import Api from '@/common/api/index'

import { removeStore } from '@/common/utils/mUtils'

const FormItem = Form.Item

class SetAccountForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }

  state = {
    info: {}
  }

  componentDidMount () {
    this.getLoginUserInfo()
  }

  // 获取当前登录用户信息
  getLoginUserInfo = () => {
    Api.getLoginUserInfo({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data || {}
        })
      }
    })
  }

  // 提交
  submitSetAccount = (params) => {
    Api.submitSetAccount(params).then(res => {
      if (+res.code === 10000) {
        message.success('保存成功')

        removeStore('token')
        this.props.history.push('/login')
      }
    })
  }

  // 点击提交
  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let reg = /^\w{6,20}$/

        if (!reg.test(values.newPassword)) {
          return message.warning('您输入的新密码不符合规则')
        }

        if (values.confirmPassword !== values.newPassword) {
          return message.warning('两次输入的密码不一致')
        }

        let { oldPassword, newPassword, confirmPassword } = values
        let jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
        values['oldPassword'] = jsencrypt.encrypt(oldPassword)
        values['newPassword'] = jsencrypt.encrypt(newPassword)
        values['confirmPassword'] = jsencrypt.encrypt(confirmPassword)

        this.submitSetAccount(values)
      }
    })
  }

  render () {
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    }

    const { getFieldDecorator } = this.props.form
    const { info = {} } = this.state

    return (
      <div>
        <Card title='账户设置'>
          <Row style={{ margin: '0px auto', width: '500px' }}>
            <Col>
              <Row>
                <Form>
                  <FormItem label='登录名' {...formItemLayout}>
                    {
                      getFieldDecorator('username', {
                        initialValue: info && info.username ? info.username : ''
                      })(<Input disabled />)
                    }
                  </FormItem>

                  <FormItem label='原登录密码' {...formItemLayout}>
                    {
                      getFieldDecorator('oldPassword', {
                        rules: [
                          { required: true, message: '请输入原登录密码' }
                        ]
                      })(<Input.Password placeholder='请输入...' autoComplete='off' />)
                    }
                  </FormItem>
                  <FormItem label='新登录密码' {...formItemLayout}
                    help='密码长度为6-20位，英文字母或数字'
                  >
                    {
                      getFieldDecorator('newPassword', {
                        rules: [
                          { required: true, message: '请输入新登录密码' }
                        ]
                      })(<Input.Password placeholder='请输入...' autoComplete='off' />)
                    }
                  </FormItem>
                  <FormItem label='确认登录密码' {...formItemLayout}>
                    {
                      getFieldDecorator('confirmPassword', {
                        rules: [
                          {
                            required: true,
                            message: '请确认登录密码'
                          }
                        ]
                      })(<Input.Password placeholder='请输入...' autoComplete='off' />)
                    }
                  </FormItem>
                </Form>
              </Row>
              <Row style={{ textAlign: 'center' }}>
                <Button type='primary' onClick={this.handleSubmit}>提交</Button>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}

const SetAccount = Form.create()(SetAccountForm)

export default SetAccount
