import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row } from 'antd'
import moment from 'moment'

import styles from './announce.module.scss'

export default class AnnounceModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }
  state = {}

  componentDidMount () { }

  render () {
    const { detailInfo = {} } = this.props
    return (
      <div>
        <Modal title={<span style={{ visibility: 'hidden' }}>公告详情</span>}
          bodyStyle={{ padding: '15px', height: '500px', overflow: 'auto' }}
          width={1000}
          visible
          onCancel={this.props.onCancel}
          footer={null}
        >
          <Row className={`${styles.announceWrap}`}>
            <h4 className={`${styles.announceTitle}`}>{detailInfo.title}</h4>
            <div className={`${styles.announceSub}`}>{moment(detailInfo.beginDate).format('YYYY-MM-DD HH:mm:ss')}</div>
            <div className={`${styles.announceContent}`}>{detailInfo.content}</div>
          </Row>
        </Modal>
      </div>
    )
  }
}
