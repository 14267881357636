import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Modal, Row, Col, Form, Input, Icon, Upload, Button, Select, message,
  Checkbox, Pagination
} from 'antd'

import $lodash from 'lodash'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'
import filter from '@/common/utils/filter'

import styles from './index.module.scss'

const FormItem = Form.Item
const TextArea = Input.TextArea
const Option = Select.Option
const { uploadOrdinaryImageUrl, agreementUpload } = UploadApi
const { pageSizeOptions, repLineBreak } = filter

class RenovationForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object,
    modelType: PropTypes.number,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    brandList: [], // 所有的品牌
    bannerList: [], // 所有的bannner
    isNext: false, // 是否下一步
    logoUrl: '', // LOGO图片
    commonSealUrl: '', // 公章图片
    mallInfo: {}, // 设置好的商城基本信息
    brandIdList: [], // 选择的品牌ID
    selectedBannerKeys: [] // 选中的banner模板
  }

  componentDidMount () {
    this.getBrandList({ brandListType: 'ALL_BRAND' })
    this.getMallBannerTempList()
  }

  // 获取banner图片模板列表
  getMallBannerTempList = () => {
    const { modelType = 0 } = this.props
    const { pages } = this.state
    let params = {
      type: 'BANNER',
      modelType: modelType,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getMallBannerTempList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          bannerList: data.resultList || [],
          pages: {
            ...pages,
            total: data.totalNum
          }
        })
      }
    })
  }

  // 获取品牌
  getBrandList = (params) => {
    Api.getBrandList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          brandList: data || []
        })
      }
    })
  }

  // 点击下一步
  handleNext = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { logoUrl = '', commonSealUrl = '' } = this.state
        if (logoUrl === '') {
          return message.error('请上传公司logo')
        }

        if (commonSealUrl === '') {
          return message.error('请上传公司合同章图片')
        }

        let _mallInfo = {
          ...values,
          logoImageUrl: logoUrl,
          commonSealImageUrl: commonSealUrl,
          textAdvertisement: repLineBreak(values.textAdvertisement, '<br>')
        }

        delete _mallInfo.brandIdList

        this.setState({
          mallInfo: _mallInfo,
          brandIdList: values.brandIdList,
          isNext: true
        })
      }
    })
  }

  // 点击确定保存
  handleConfirm = () => {
    let { mallInfo = {}, brandIdList = [], selectedBannerKeys = [] } = this.state
    if (selectedBannerKeys.length === 0) {
      return message.warning('请至少选择一个banner模板！')
    }

    let _bannerList = []

    selectedBannerKeys.map(item => {
      let obj = {
        imageUrl: item.imageKey,
        name: item.textValue || ''
      }

      _bannerList.push(obj)
    })

    let params = {
      mallInfo: mallInfo,
      brandIdList: brandIdList,
      bannerList: _bannerList
    }

    this.props.onConfirm(params)
  }

  // 上传图片
  handleChangeUpload = (info, type = 1) => {
    let _type = type
    if (info.file.status === 'done') {
      const { data } = info.file.response

      if (_type === 1) {
        this.setState({
          logoUrl: data.imageUrl || ''
        })
      } else {
        this.setState({
          commonSealUrl: data.imageUrl || ''
        })
      }
    }
  }

  // 前端删除图片
  removeImg = (type) => {
    let { logoUrl = '', commonSealUrl = '' } = this.state

    if (type === 1) {
      logoUrl = ''
    } else {
      commonSealUrl = ''
    }

    this.setState({
      logoUrl,
      commonSealUrl
    })
  }

  // 监听选择banner
  handleChangeCheckbox = (record) => {
    let { selectedBannerKeys = [] } = this.state
    let i = selectedBannerKeys.findIndex(item => {
      return item.id === record.id
    })

    if (i > -1) {
      selectedBannerKeys.splice(i, 1)
    } else {
      selectedBannerKeys.push(record)
    }

    this.setState({
      selectedBannerKeys
    })
  }

  // 监听banner的输入
  handleChangeInputValue = (val, id) => {
    let { selectedBannerKeys = [] } = this.state
    let i = selectedBannerKeys.findIndex(item => {
      return item.id === id
    })

    if (i > -1) {
      selectedBannerKeys[i].textValue = val
    }

    this.setState({
      selectedBannerKeys
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getMallBannerTempList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getMallBannerTempList()
    })
  }

  renderRenovationFooter = () => {
    const { isNext = false } = this.state
    let arr = [
      <Button key='cancel' onClick={() => this.props.onCancel()}>取消</Button>,
      <Button type='primary' key='next'
        onClick={this.handleNext}
      >
        下一步
      </Button>
    ]

    if (isNext) {
      arr = [
        <Button type='primary' key='confirm'
          onClick={this.handleConfirm}
        >
          确定
        </Button>
      ]
    }

    return arr
  }

  render () {
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 10 }
    }

    const { getFieldDecorator } = this.props.form

    const { logoUrl = '', commonSealUrl = '', brandList = [], isNext = false,
      pages, selectedBannerKeys = [], bannerList = [] } = this.state

    return (
      <div>
        <Modal title='快速装修'
          visible
          width={1000}
          bodyStyle={{ padding: '12px', height: '500px', overflow: 'auto' }}
          maskClosable={false}
          onCancel={this.props.onCancel}
          footer={this.renderRenovationFooter()}
        >
          {
            !isNext
              ? <Row>
                <Form>
                  <FormItem label='商城名称' {...formItemLayout}>
                    {
                      getFieldDecorator('name', {
                        rules: [
                          { required: true, message: '请填写商城的名称' }
                        ]
                      })(
                        <Input placeholder='请输入...' autoComplete='off' />
                      )
                    }

                  </FormItem>

                  <FormItem label='客服电话' {...formItemLayout}>
                    {
                      getFieldDecorator('customerPhoneNumber', {
                        rules: [
                          { required: true, message: '请填写客服联系电话' }
                        ]
                      })(
                        <Input placeholder='请输入...' autoComplete='off' />
                      )
                    }
                  </FormItem>

                  <FormItem label='客服QQ' {...formItemLayout}>
                    {
                      getFieldDecorator('customerQqNumber', {
                        rules: [
                          { required: false, message: '请填写客户联系QQ' }
                        ]
                      })(
                        <Input placeholder='请输入...' autoComplete='off' />
                      )
                    }
                  </FormItem>

                  <FormItem label='滚动文字广告' {...formItemLayout}>
                    {
                      getFieldDecorator('textAdvertisement', {
                        rules: [
                          { required: true, message: '请填写滚动文字广告' }
                        ]
                      })(
                        <TextArea rows={6} placeholder='请输入...' autoComplete='off' />
                      )
                    }
                  </FormItem>

                  <Row style={{ marginBottom: '12px' }}>
                    <Col span={6} style={{ textAlign: 'right' }}>
                      <label className={styles.formLabel}>商城logo：</label>
                    </Col>
                    <Col span={10}>
                      <div style={{ display: 'inline-block' }}>
                        {
                          logoUrl !== ''
                            ? <div className={styles.thumbWrap}>
                              <div className={styles.thumbInfo}>
                                <img src={logoUrl} />

                                <a className={styles.prewModal}>
                                  <Icon type='delete' style={{ fontSize: '20px' }}
                                    onClick={() => this.removeImg(1)}
                                  />
                                </a>
                              </div>
                            </div>
                            : <Upload
                              listType='picture-card'
                              accept='image/png, image/jpg, image/jpeg'
                              headers={{ Token: getStore('token') }}
                              action={uploadOrdinaryImageUrl}
                              onChange={(info) => this.handleChangeUpload(info, 1)}
                            >
                              <div>
                                <Icon type='plus' />
                                <div style={{ fontSize: '12px' }}>点击上传</div>
                              </div>

                            </Upload>
                        }

                      </div>

                      <div style={{ float: 'right' }}>
                        <label className={styles.formLabel} style={{ verticalAlign: 'top' }}>
                          公司合同章：
                        </label>
                        <div style={{ display: 'inline-block' }}>
                          {
                            commonSealUrl !== ''
                              ? <div className={styles.thumbWrap}>
                                <div className={styles.thumbInfo}>
                                  <img src={commonSealUrl} />

                                  <a className={styles.prewModal}>
                                    <Icon type='delete' style={{ fontSize: '20px' }}
                                      onClick={() => this.removeImg(2)}
                                    />
                                  </a>
                                </div>
                              </div>
                              : <Upload
                                listType='picture-card'
                                accept='image/png, image/jpg, image/jpeg'
                                headers={{ Token: getStore('token') }}
                                action={uploadOrdinaryImageUrl}
                                onChange={(info) => this.handleChangeUpload(info, 2)}
                              >
                                <div>
                                  <Icon type='plus' />
                                  <div style={{ fontSize: '12px' }}>点击上传</div>
                                </div>
                              </Upload>
                          }
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <FormItem label='主营品牌' {...formItemLayout}>
                    {
                      getFieldDecorator('brandIdList', {
                        rules: [
                          { required: true, message: '请选择商城主营品牌' }
                        ]
                      })(
                        <Select
                          showSearch
                          mode='multiple'
                          placeholder='请选择品牌'
                          optionFilterProp='children'
                        >
                          {
                            brandList.map((brandOpt, i) => (
                              <Option value={brandOpt.brandId} key={`brandOptions-${brandOpt.brandId}`}>
                                {brandOpt.brandName}
                              </Option>
                            ))
                          }
                        </Select>
                      )
                    }
                  </FormItem>

                  <FormItem label='用户服务协议' {...formItemLayout}
                    style={{ marginBottom: '0px' }}
                  >
                    <FormItem style={{ display: 'inline-block' }}>
                      <Upload
                        showUploadList={false}
                        accept='.doc,.docx'
                        headers={{ Token: getStore('token') }}
                        action={agreementUpload}
                      >
                        <Button>
                          <Icon type='upload' />点击上传
                        </Button>
                      </Upload>
                    </FormItem>
                  </FormItem>
                </Form>
              </Row>
              : <Row>
                <Row gutter={10}>
                  {
                    bannerList.map((item, i) => (
                      <Col span={8} key={`bannerList-${i}`} style={{ marginBottom: '12px' }}>
                        <div className={`${styles.cardItem}`}>
                          <div className={`${styles.cardBody}`}>
                            <img className={`${styles.pic}`} src={item.imageKey} />
                          </div>
                          <div className={`${styles.cardMeta}`}>
                            <div className={`${styles.metaPull}`}>
                              <Checkbox onChange={() => this.handleChangeCheckbox(item)} />
                            </div>
                            <div className={`${styles.metaBody}`}>
                              {
                                $lodash.findIndex(selectedBannerKeys, (sitem) => {
                                  return sitem.id === item.id
                                }) > -1
                                  ? <Input placeholder='您可编辑banner上的文字...'
                                    autoComplete='off'
                                    onChange={(e) => this.handleChangeInputValue(e.target.value, item.id)}
                                  />
                                  : null
                              }

                            </div>
                          </div>
                        </div>
                      </Col>
                    ))
                  }
                </Row>

                <Row style={{ textAlign: 'center' }}>
                  <Pagination
                    size='small'
                    showSizeChanger
                    total={pages.total}
                    showTotal={(total) => { return `共 ${total} 条` }}
                    current={pages.pageNo}
                    pageSize={pages.pageSize}
                    pageSizeOptions={pageSizeOptions}
                    onChange={this.pageChange}
                    onShowSizeChange={this.pageSizeChange}
                  />
                </Row>
              </Row>
          }

        </Modal>
      </div>
    )
  }
}

const RenovationModal = Form.create()(RenovationForm)

export default RenovationModal
