import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Table, Switch, message, Button } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class RoleList extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    modal: {
      roleModal: false
    },
    modaltype: 1,
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    rowDetail: {}
  }

  columns = [
    {
      title: '序号',
      key: 'role-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '角色职务',
      key: 'role-1',
      dataIndex: 'roleName'
    },
    {
      title: '角色类型',
      key: 'role-3',
      render: (text, record, index) => (
        <div>
          {
            record.roleType && record.roleType === 'DIY_ROLE'
              ? '自定义角色'
              : '系统角色'
          }
        </div>
      )
    },
    {
      title: '职能描述',
      key: 'role-2',
      dataIndex: 'description'
    },
    {
      title: '添加时间',
      key: 'role-4',
      render: (text, record, index) => (
        <div>
          {
            +record.createTime > 0
              ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')
              : null
          }
        </div>
      )
    },
    {
      title: '是否启用',
      key: 'role-5',
      render: (text, record, index) => (
        <div>
          <Switch
            size='small'
            checkedChildren='启用' unCheckedChildren='禁用'
            checked={record.status && record.status === 'ENABLED'}
            onChange={(checked) => this.changeRoleStatus(checked, record)}
          />
        </div>
      )
    },
    {
      title: '操作',
      key: 'role-6',
      width: 200,
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small'
            onClick={() => this.handleJumpToSetAuthority(record, 2)}
          >编辑</Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getRoleList()
  }

  // 获取角色列表
  getRoleList = () => {
    const { pages } = this.state
    let params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getRoleList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 更新状态
  updateRoleStatus = (params) => {
    Api.updateRoleStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.closeModal()
        this.getRoleList()
      }
    })
  }

  // 跳转到权限设置
  handleJumpToSetAuthority = (record, type) => {
    this.props.history.push({ pathname: '/admin/setAuthor', state: { ...record, type: type } })
  }

  changeRoleStatus = (checked, record) => {
    let params = {
      roleId: record.roleId,
      status: checked ? 'ENABLED' : 'FREEZE'
    }

    this.updateRoleStatus(params)
  }

  closeModal = () => {
    this.setState({
      modal: {
        departModal: false
      },
      modaltype: 1,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getRoleList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getRoleList()
    })
  }

  render () {
    const { tableData = [], pages } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共${total}` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ marginBottom: '15px' }}>
            <Button type='primary' onClick={() => this.handleJumpToSetAuthority({}, 1)}>添加角色</Button>
          </Row>

          <Row>
            <Table rowKey='roleId'
              pagination={pagination}
              columns={this.columns}
              dataSource={tableData}
            />
          </Row>
        </Card>
      </>
    )
  }
}
