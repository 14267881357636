/**
 * 客户模块
 */
import CustomerList from '@/pages/customer/customer-list/index' // 客户列表
import EnduserDetail from '@/pages/customer/enduser-detail/index' // 编辑客户
import Behavior from '@/pages/customer/behavior/index' // 用户行为
import CustomerAdress from '@/pages/customer/customer-addressDetail/index' // 客户地址
import Beforeuoload from '../pages/customer/customer-list/beforeuoload' // 客户预导入
import Uploadinfo from '../pages/customer/customer-list/uploadinfo' // 客户导入信息展示
import EndUserCartDetails from '@/pages/customer/enduserCartDetails/index' // EU客户购物车详情

export default [
  {
    path: '/admin/customerList',
    name: '客户列表',
    component: CustomerList
  },
  {
    path: '/admin/enduser-detail',
    name: '客户详情',
    component: EnduserDetail
  },
  {
    path: '/admin/enduserBehavior',
    name: '客户行为分析',
    component: Behavior
  },
  {
    path: '/admin/customerAdress',
    name: '客户地址详情',
    component: CustomerAdress
  },
  {
    path: '/admin/beforeuoload',
    name: '匹配结果详情',
    component: Beforeuoload
  },
  {
    path: '/admin/uploadinfo',
    name: '导入客户信息',
    component: Uploadinfo
  },
  {
    path: '/admin/enduserCartDetails',
    name: '客户购物车详情',
    component: EndUserCartDetails
  }
]
