import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Row, Card, Pagination } from 'antd'
import moment from 'moment'

import DetailModal from '../component/detail-modal'
import SearchForm from '@/components/tool-form/index'
import Descriptions from './descriptions'
import Updowm from '../component/down'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderOrderStatusLabel, renderOrderPaymentStatusLabel, renderOrderDeliveryStatusLabel } from '@/common/utils/mUtils'
import DownloadApi from '@/common/api/downloadApi'

const { downOrderUrlStore } = DownloadApi
const { pageSizeOptions, orderStatusMaps, orderDeliveryStatusMaps, orderPaymentStatusMaps } = filter

class StockOrder extends Component {
  static propTypes = {
    tableData: PropTypes.array
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    detailModal: false, // 查看详情弹窗
    rowDetail: {},
    summary: {},
    query: {},
    searchData: [
      {
        name: 'endUserId',
        formType: 'Select',
        itemParams: {
          label: '客户名称'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['endUserId', 'showName']
      },
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {

        }
      },
      {
        name: 'orderStatusList',
        formType: 'Select',
        itemParams: {
          label: '订单状态'
        },
        cptParams: {
          placeholder: '请选择...',
          mode: 'multiple',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderStatusMaps
        ]
      },
      {
        name: 'paymentStatus',
        formType: 'Select',
        itemParams: {
          label: '付款状态'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderPaymentStatusMaps
        ]
      },
      {
        name: 'deliveryStatus',
        formType: 'Select',
        itemParams: {
          label: '发货状态'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderDeliveryStatusMaps
        ]
      }
    ]

  }
  columns = [
    {
      title: '序号',
      key: 'goodsorder-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '订单日期',
      key: 'goodsorder-1',
      width: 120,
      render: (text, record, index) => (
        <div>
          { record.orderTime ? moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----'}
        </div>
      )
    },
    {
      title: '订单编号',
      key: 'goodsorder-2',
      dataIndex: 'orderId'
    },
    {
      title: '客户名称',
      key: 'goodsorder-3',
      dataIndex: 'customerName'
    },
    {
      title: '订单金额',
      key: 'goodsorder-4',
      dataIndex: 'payableAmount',
      render: (text, record, index) => (
        <div>
          &yen;{(+record.payableAmount * 100 / 100).toFixed(2)}
        </div>
      )
    },
    {
      title: '已付金额',
      key: 'goodsorder-5',
      dataIndex: 'paidAmount',
      render: (text, record, index) => (
        <div>&yen;{(+record.paidAmount * 100 / 100).toFixed(2)}</div>
      )
    },
    {
      title: '未付金额',
      key: 'goodsorder-6',
      dataIndex: 'unpaidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.unpaidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '订单状态',
      key: 'goodsorder-7',
      render: (text, record, index) => (
        <>{ renderOrderStatusLabel(record.orderStatus) }</>
      )
    },
    {
      title: '付款状态',
      key: 'euorder-8',
      render: (text, record, index) => (
        <>{ renderOrderPaymentStatusLabel(record.paymentStatus) }</>
      )
    },
    {
      title: '发货状态',
      key: 'euorder-9',
      render: (text, record, index) => (
        <>{ renderOrderDeliveryStatusLabel(record.deliveryStatus) }</>
      )
    },
    {
      title: '操作',
      key: 'goodsorder-11',
      render: (text, record, index) => (
        <>
          <Button type='primary' size='small' onClick={() => this.handleShowDetail(record)}>
            查看明细
          </Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getGuesteuUserLike() // 客户联动查询
    this.getStockOrderList() // 列表初始化
    this.getTradeOrderAmountSummary() // 价格显示初始化
  }

  // 搜索客户信息
  getGuesteuUserLike = (val = '') => {
    let params = {
      pageNo: 1,
      pageSize: 999,
      searchName: val
    }
    Api.getGuesteuUserLike(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state
        searchData[0].options = data.list || []

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取现货订单列表
  getStockOrderList = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    if (params.orderStatus === '') {
      delete params.orderStatus
    }

    Api.getStockOrderList(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getStockOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getStockOrderList()
    })
  }

  // 获取订单金额汇总
  getTradeOrderAmountSummary = () => {
    const { pages } = this.state
    let params = {
      orderType: 'ORDER_STOCK',
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getTradeOrderAmountSummary(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          summary: data || {}
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search) => {
    const { searchData = [], pages } = this.state
    let _searchData = [...searchData]
    if (search.orderTime) {
      search.beginDate = search.orderTime.length > 0 ? search.orderTime[0].format('YYYY-MM-DD') : ''
      search.endDate = search.orderTime.length > 0 ? search.orderTime[1].format('YYYY-MM-DD') : ''
    }
    delete search.orderTime
    console.log(search)

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {},

      searchData: _searchData
    }, () => {
      this.getTradeOrderAmountSummary()
      this.getStockOrderList()
    })
  }

  // 点击查看订单明细
  handleShowDetail = (record) => {
    this.setState({
      detailModal: true,
      rowDetail: record || {}
    })
  }

  closeModal = () => {
    this.setState({
      detailModal: false,
      rowDetail: {}
    })
  }

  render () {
    const { detailModal = false, query, rowDetail = {}, searchData = [], summary = {}, pages, tableData = [] } = this.state

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />

          </Card>
        </div>

        <Card bodyStyle={{ padding: '15px' }}>
          <Updowm query={query} api={downOrderUrlStore} />
          <Descriptions summary={summary} />
          <Table rowKey='orderId'
            pagination={false}
            columns={this.columns}
            dataSource={tableData}
          />

          {
            tableData.length > 0 ? <Row style={{ marginTop: '16px', textAlign: 'right' }}>
              <Pagination
                showSizeChanger
                showQuickJumper
                total={pages.total}
                showTotal={(total) => { return `共 ${total} 条` }}
                current={pages.pageNo}
                pageSize={pages.pageSize}
                pageSizeOptions={pageSizeOptions}
                onChange={this.pageChange}
                onShowSizeChange={this.pageSizeChange}
              />
            </Row> : null
          }

          {
            detailModal
              ? <DetailModal
                type={3}
                detailInfo={rowDetail}
                onCancel={() => this.closeModal()}
              />
              : null
          }
        </Card>
      </>
    )
  }
}

export default StockOrder
