import React, { Component } from 'react'

import styles from './index.module.scss'

export default class balanceLand extends Component {
  render () {
    return (
      <div className={styles.main}>
        <h1>支付方式:</h1>
        <div className={styles.info}>
          <div className={`${styles.useBalance} ${styles.selected}`} >
            <span>银行转账</span><i />
          </div>
        </div>
      </div>
    )
  }
}
