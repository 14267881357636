import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table } from 'antd'
import moment from 'moment'

import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter
// SUB: 支出 ADD:收入

export default class EubalanceflowModal extends Component {
  static propTypes = {
    type: PropTypes.number, // 99- 云平台消费流水
    detailInfo: PropTypes.object,
    getDetail: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'eubillDetail-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '金额',
      key: 'eubillDetail-1',
      width: 200,
      render: (text, record, index) => (
        <>
          {
            record.amountType === 'SUB'
              ? <span>-&yen;{(record.amount * 100 / 100).toFixed(2)}</span>
              : <span style={{ color: '#FF4444' }}>+&yen;{(record.amount * 100 / 100).toFixed(2)}</span>
          }
        </>
      )
    },
    {
      title: '日期',
      key: 'eubillDetail-2',
      width: 200,
      render: (text, record, index) => (
        <div>
          {
            record.createTime
              ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')
              : '--'
          }
        </div>
      )
    },
    {
      title: '备注',
      key: 'eubillDetail-3',
      dataIndex: 'remarks'
    }
  ]

  componentDidMount () {
    this.getEuCreditFlow()
  }

  // 获取用户信用支付流水
  getEuCreditFlow = () => {
    const { detailInfo, type = 0 } = this.props
    const { pages } = this.state
    let params = {
      dueMonth: detailInfo.dueMonth,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }
    if (type === 99) {
      params.consumeType = 'BEFORE'
    } else {
      params.endUserId = detailInfo.endUserId
    }
    this.props.getDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEuCreditFlow()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEuCreditFlow()
    })
  }

  render () {
    const { pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <div>
        <Modal title='信用账单详细'
          width={800}
          visible
          onCancel={this.props.onCancel}
          onOk={this.props.onCancel}
        >
          <Table rowKey='id'
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
            scroll={{ y: 350 }}
          />
        </Modal>
      </div>
    )
  }
}
