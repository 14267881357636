import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Button } from 'antd'
import $lodash from 'lodash'

import InvoiceExpressEmpty from '@/components/invoiceExpressEmpty/index'
import InvoiceExpressView from '@/components/invoiceExpressView/index' // 发票凭证
import InvoiceAccount from '@/components/invoiceAccount/index' // 发票抬头
import InvoiceProduct from '@/components/invoiceProduct/index' // 发票商品
import InvoiceReceiving from '@/components/invoiceReceiving/index' // 发票地址

import Api from '@/common/api/index'

class InvoiceForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    openStatus: PropTypes.string, // 开票状态 OPENING-开票中 OPENED-开票完成
    ids: PropTypes.array,
    onCancel: PropTypes.func
  }
  state = {
    tableData: [], // 发票商品信息
    totalAmount: 0, // 发票金额
    invoiceType: '', // 发票类型
    invoiceInfo: {}, // 发票抬头信息
    receivingAddress: {}, // 收票地址信息
    invoiceExpressInfo: {}, // 发票物流信息
    invoiceVoucherInfo: {}, // 发票凭证信息
    sendAddressList: [], // 发货地址
    selectedAddressKey: null // 发货选中的地址ID
  }

  componentDidMount () {
    const { openStatus } = this.props

    if (openStatus === 'OPENING') {
      this.getPurchaseOrderInvoiceOpeningDetails()
    }

    if (openStatus === 'OPENED') {
      this.getPurchaseOrderInvoiceOpenedDetails()
    }
  }

  // 获取云采购发票- 开票中详情数据
  getPurchaseOrderInvoiceOpeningDetails = async () => {
    const { ids = [] } = this.props
    const res = await Api.getPurchaseOrderInvoiceOpeningDetails({ ids: ids })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.orderGoodsListMap || [],
        totalAmount: data.invoiceAmount || 0,
        invoiceInfo: data.invoiceInfo || {}
      })
    }
  }

  // 获取云采购开票-开票完成详情数据
  getPurchaseOrderInvoiceOpenedDetails = async () => {
    const { ids = [] } = this.props
    const res = await Api.getPurchaseOrderInvoiceOpenedDetails({ id: ids[0] })
    const { code, data } = res

    if (+code === 10000) {
      this.setState({
        tableData: data.orderGoodsListMap || [],
        totalAmount: data.orderGoodsListMap && data.orderGoodsListMap.length > 0 ? $lodash.sumBy(data.orderGoodsListMap, 'totalPrice') : 0,
        invoiceInfo: data.invoiceRecordInfo || {},
        receivingAddress: data.invoiceRecordReceiverAddress || {},
        invoiceVoucherInfo: data.merchantInvoiceRecord || {},
        invoiceType: data.merchantInvoiceRecord.invoiceType || ''
      })
    }
  }

  render () {
    const { form, openStatus, onCancel } = this.props
    const { invoiceType, invoiceInfo = {}, tableData = [], totalAmount = 0, invoiceVoucherInfo = {}, receivingAddress = {}, sendAddressList = [], selectedAddressKey = null } = this.state

    return (
      <>
        <Modal title='发票详情' width={1000} visible bodyStyle={{ padding: '16px', height: '500px', overflowY: 'scroll' }}
          maskClosable={false}
          onCancel={() => onCancel()}
          footer={<>
            <Button onClick={() => onCancel()} key={`invoiceButton-0`}>关闭</Button>
          </>
          }
        >
          <Form>
            {
              openStatus === 'OPENING'
                ? <InvoiceExpressEmpty />
                : <InvoiceExpressView
                  invoiceType={invoiceType}
                  invoiceCode={invoiceVoucherInfo.invoiceCode}
                  invoiceImageUrl={invoiceVoucherInfo.invoiceImageUrl}
                />
            }

            <div style={{ marginTop: '16px' }}>
              <InvoiceAccount invoiceInfo={invoiceInfo} />
            </div>

            <InvoiceProduct tableData={tableData} totalAmount={totalAmount} />

            {
              openStatus === 'OPENING'
                ? null
                : <InvoiceReceiving form={form} receivingAddress={receivingAddress} sendAddressList={sendAddressList} selectedAddressKey={selectedAddressKey} />
            }
          </Form>
        </Modal>
      </>
    )
  }
}

const InvoiceModal = Form.create()(InvoiceForm)

export default InvoiceModal
