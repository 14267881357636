import React, { } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Button } from 'antd'

import styles from './index.module.scss'

function Footer (props) {
  const { checkboxDisabled = false, checkedAll = false, totalValues = {}, onSelectedAll, onRemove, onConfirm, onDownload } = props

  return (
    <div className={styles.goodsAccount}>
      <div className={`${styles.actions}`}>
        <Checkbox disabled={checkboxDisabled} checked={checkedAll} style={{ color: '#333333' }} onChange={(e) => onSelectedAll(e.target.checked)}>全选</Checkbox>
        <Button type='link' size='small' className={`${styles.deleteLinkBtn}`} onClick={() => onRemove()}>删除选中</Button>
      </div>

      <div className={`${styles.contents}`}>
        <span>已选中&nbsp;{totalValues.goods}&nbsp;种商品，共&nbsp;{ totalValues.nums }&nbsp;件</span>
        <label className={`label`} style={{ marginLeft: '12px' }}>总价（不含运费）</label>
        <span className={`${styles.money}`}>&yen;{(+totalValues.amount * 100 / 100).toFixed(2)}</span>
        <Button type='primary' style={{ marginLeft: '32px' }} onClick={() => onDownload()}>导出报价单</Button>
        <Button type='danger' style={{ marginLeft: '12px' }} onClick={() => onConfirm()}>结算</Button>
      </div>
    </div>
  )
}

Footer.propTypes = {
  totalValues: PropTypes.object,
  checkedAll: PropTypes.bool,
  checkboxDisabled: PropTypes.bool,
  onSelectedAll: PropTypes.func,
  onRemove: PropTypes.func,
  onConfirm: PropTypes.func,
  onDownload: PropTypes.func
}

export default Footer
