import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import $lodash from 'lodash'
import { Modal, Form, Select, Input, Row, Alert, Checkbox, Col } from 'antd'

import Api from '@/common/api/index'

const FormItem = Form.Item
const Option = Select.Option
const { TextArea } = Input

const tempTypeMap = [
  { type: 'COMMON', label: '通用模板' },
  { type: 'GUEST', label: '客户模板' }
]

const requiredFields = ['IDX', 'TOTAL_PRICE', 'TOTAL_PRICE_CHN', 'PREPAY_PRICE', 'BALANCE_PAYMENT']

class QuoteForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    type: PropTypes.number,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {
    info: {},
    euOptions: [], // 客户列表
    selectedUserId: 0, // 当前选择的用户
    plainOptions: [], // 自定义选项集合
    selectedKeys: [] // 选择的选项

  }

  componentDidMount () {
    axios.all([Api.getQuoteTemplateFieldMap({}), Api.getGuesteuUserLike({ pageNo: 1, pageSize: 999, searchName: '' })])
      .then(axios.spread((fieldRes, euoptionsRes) => {
        if (+fieldRes.code === 10000 && +euoptionsRes.code === 10000) {
          const { type = 1 } = this.props

          let _fieldMaps = []

          if (fieldRes.data) {
            Object.keys(fieldRes.data).forEach(item => {
              let obj = {
                label: fieldRes.data[item],
                value: item,
                disabled: $lodash.includes(requiredFields, item)
              }
              _fieldMaps.push(obj)
            })
          }

          this.setState({
            plainOptions: _fieldMaps,
            selectedKeys: $lodash.map(_fieldMaps, (o) => { return o.value }),
            euOptions: euoptionsRes.data.list || []
          }, () => {
            if (+type === 2) {
              this.getQUoteTempDetail()
            }
          })
        }
      }))
  }

  // 查询客户信息
  getGuesteuUserLike = (val = '') => {
    let params = {
      pageNo: 1,
      pageSize: 999,
      searchName: val
    }

    Api.getGuesteuUserLike(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          euOptions: data.list || []
        })
      }
    })
  }

  // 获取模板详情
  getQUoteTempDetail = () => {
    const { detailInfo } = this.props
    let params = {
      templateId: detailInfo.quotationTemplateId
    }

    Api.getQuoteTempDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const { selectedKeys = [] } = this.state
        let str = ''
        let _selectedUserIds = []
        let _selectedKeys = []

        if (data && data.quotationTemplateType && data.quotationTemplateType !== '') {
          str = data.quotationTemplateType
        }

        if (data.userInfoList && data.userInfoList.length > 0) {
          data.userInfoList.map(item => {
            _selectedUserIds.push(item.endUserId)
          })
        }

        if (data && data.quotationProductFields && data.quotationProductFields !== '') {
          _selectedKeys = data.quotationProductFields.split(',')
        }

        this.setState({
          info: data || {},
          defaultUserIds: _selectedUserIds,
          selectedTempType: str,
          selectedKeys: _selectedKeys.length > 0 ? _selectedKeys : selectedKeys
        })
      }
    })
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo, type } = this.props

        if (type === 2) {
          values.quotationTemplateId = detailInfo.quotationTemplateId
        }

        this.props.onConfirm(values)
      }
    })
  }

  // 监听模板选择
  onSelectedTempType = (val) => {
    this.setState({
      selectedTempType: val
    })
  }

  render () {
    const { type = 1 } = this.props
    const { getFieldDecorator } = this.props.form
    const { euOptions = [], selectedTempType = '', info = {}, defaultUserIds = [],
      plainOptions = [], selectedKeys = [] } = this.state

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 }
    }

    return (
      <div>
        <Modal title={type === 1 ? '新增报价单模板' : '编辑报价单模板'}
          width={800}
          bodyStyle={{ height: '400px', overflow: 'auto' }}
          visible
          maskClosable={false}
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Form>
            <FormItem label='模板类型' {...formItemLayout}>
              {
                getFieldDecorator('quotationTemplateType', {
                  initialValue: info && info.quotationTemplateType ? info.quotationTemplateType : '',
                  rules: [
                    { required: true, message: '该项必选' }
                  ]
                })(
                  <Select
                    onChange={this.onSelectedTempType}
                  >
                    {
                      tempTypeMap.map((item, i) => (
                        <Option value={item.type} key={`tempType-${i}`}>{item.label}</Option>
                      ))
                    }
                  </Select>
                )
              }
            </FormItem>
            {
              selectedTempType === 'GUEST'
                ? <FormItem label='客户名称' {...formItemLayout}>
                  {
                    getFieldDecorator('endUserIdList', {
                      initialValue: defaultUserIds,
                      rules: [
                        { required: true, message: '该项必选' }
                      ]
                    })(
                      <Select
                        mode='multiple'
                        showSearch
                        optionFilterProp='children'
                        onSearch={(val) => this.getGuesteuUserLike(val)}
                      >
                        {
                          euOptions.map((cusitem, i) => (
                            <Option value={cusitem.endUserId} key={cusitem.endUserId}>{cusitem.showName}</Option>
                          ))
                        }
                      </Select>
                    )
                  }
                </FormItem>
                : null
            }

            <FormItem label='模板名称' {...formItemLayout}>
              {
                getFieldDecorator('quotationTemplateName', {
                  initialValue: info && info.quotationTemplateName ? info.quotationTemplateName : '',
                  rules: [
                    { required: true, message: '该项必选' }
                  ]
                })(
                  <Input placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>
            <FormItem label='备注' {...formItemLayout}>
              {
                getFieldDecorator('quotationRemarks', {
                  initialValue: info && info.quotationRemarks ? info.quotationRemarks : '',
                  rules: [
                    { required: false, message: '该项必选' }
                  ]
                })(
                  <TextArea placeholder='请输入...' autoComplete='off' rows={4} />
                )
              }
            </FormItem>

            <Row>
              {
                plainOptions.length > 0
                  ? <>
                    <Alert type='info' message='您可以勾选以下导出选项' style={{ marginBottom: '10px' }} />

                    <FormItem>
                      {
                        getFieldDecorator('quotationFieldsList', {
                          initialValue: selectedKeys,
                          rules: [
                            { required: true, message: '请选择要导出的选项' }
                          ]
                        })(
                          <Checkbox.Group style={{ width: '100%' }}>
                            <Row>
                              {
                                plainOptions.map((item, i) => (
                                  <Col span={6} key={`optionsCol-${i}`}>
                                    <Checkbox value={item.value} disabled={item.disabled}>{item.label}</Checkbox>
                                  </Col>
                                ))
                              }
                            </Row>
                          </Checkbox.Group>
                        )
                      }
                    </FormItem>
                  </>
                  : null
              }
            </Row>
          </Form>

        </Modal>
      </div>
    )
  }
}

const SetquoteModal = Form.create()(QuoteForm)
export default SetquoteModal
