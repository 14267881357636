import axios from 'axios'
import { message } from 'antd'
import config from './config'
import configureStore from './configureStore'
import { getStore, removeStore } from './mUtils'

const { host } = config
const { store } = configureStore()
console.log('host=======', host)

let instance = axios.create({
  baseURL: host, // 默认基础请求url
  timeout: 60000 // 请求60s超时
})

let needLoadingRequestCount = 0 // 需要loading的请求个数

// request 拦截器
instance.interceptors.request.use(config => {
  const token = getStore('token') || ''
  if (token && token !== '') {
    config.headers['Token'] = token
  }

  if (!config.headers.HideLoading) {
    showLoading()
  }

  return config
}, error => {
  hideLoading()
  return Promise.reject(error)
})

// http response响应器
instance.interceptors.response.use(response => {
  hideLoading()
  // store.dispatch({ type: 'SET_HANDLE_MODAL', data: { handleModal: false } })

  return Promise.resolve(response.data)
}, error => {
  message.destroy()
  hideLoading()
  // store.dispatch({ type: 'SET_HANDLE_MODAL', data: { handleModal: false } })
  const { status, data } = error.response

  switch (status) {
    case 400:
      message.warning(data.message)
      break
    case 401:
      removeStore('token')
      store.dispatch({ type: 'SET_USER_INFO', data: { userInfo: {} } })
      window.location.href = '/login'
      break
    case 500:
      message.error('服务器开小差了，请稍后再试！')
      break
  }

  return Promise.reject(error.response.status)
})

function startLoading () {
  let loading = document.getElementById('loadingWrapper')
  loading.style.display = 'block'
}

function endLoading () {
  let loading = document.getElementById('loadingWrapper')
  loading.style.display = 'none'
}

function showLoading () {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}

function hideLoading () {
  setTimeout(tryCloseLoading, 300)
}

function tryCloseLoading () {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}

export default instance
