import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table } from 'antd'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class DetailModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'orderDetailCol-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'orderDetailCol-1'
    },
    {
      title: '型号',
      key: 'orderDetailCol-2',
      width: 140,
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'orderDetailCol-3',
      width: 130,
      dataIndex: 'itemNumber',
      ellipsis: true
    },
    {
      title: '产品名称',
      key: 'orderDetailCol-4',
      width: 150,
      dataIndex: 'name',
      ellipsis: true
    },
    {
      title: '数量',
      key: 'orderDetailCol-5',
      dataIndex: 'quantity'
    },
    {
      title: '当前发货数量',
      key: 'orderDetailCol-6',
      dataIndex: 'batchQuantity'
    },
    {
      title: '单位',
      key: 'orderDetailCol-7',
      width: 70,
      dataIndex: 'unit'
    }
  ]

  componentDidMount () {
    this.getStoreOutPickingDetail()
  }

  // 获取订单产品明细
  getStoreOutPickingDetail = () => {
    const { detailInfo } = this.props
    const { pages } = this.state
    let params = {
      pickingId: detailInfo.pickingId,
      batchNum: detailInfo.batchNum,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getStoreOutPickingDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list
        })
      }
    })
  }
  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getStoreOutPickingDetail()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getStoreOutPickingDetail()
    })
  }

  render () {
    const { tableData, pages } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Modal title='发货详情' width={1000}
          visible
          maskClosable={false}
          bodyStyle={{
            padding: '0px 12px 10px 12px',
            maxHeight: '500px',
            overflow: 'auto'
          }}
          onOk={this.props.onCancel}
          onCancel={this.props.onCancel}
        >
          <Table rowKey='orderId'
            pagination={pagination}
            columns={this.columns}
            dataSource={tableData}
            scroll={{ x: 900, y: 700 }}
          />
        </Modal>
      </>
    )
  }
}
