import productApi from './productApi'
import userApi from './userApi'
import customerApi from './customerApi'
import storeApi from './storeApi'
import cloudsupplyApi from './cloudsupplyApi'
import cloudpurchApi from './cloudpurchApi'
import priceApi from './priceApi'
import pricesetApi from './pricesetApi'
import orderApi from './orderApi'
import mallApi from './mallApi'
import personnelApi from './personnelApi'
import contractApi from './contractApi'
import homeApi from './homeApi'
import financeApi from './financeApi'
import quoteApi from './quoteApi'
import matchApi from './matchApi'
import notifyApi from './notifyApi'
import outapplyApi from './outapplyApi'
import enquireApi from './enquireApi'
import invoiceApi from './invoiceApi'
import saledApi from './saledApi'
import expressApi from './expressApi'

export default {
  ...userApi,
  ...productApi,
  ...customerApi,
  ...storeApi,
  ...cloudsupplyApi,
  ...cloudpurchApi,
  ...priceApi,
  ...pricesetApi,
  ...orderApi,
  ...mallApi,
  ...personnelApi,
  ...contractApi,
  ...homeApi,
  ...financeApi,
  ...quoteApi,
  ...matchApi,
  ...notifyApi,
  ...outapplyApi,
  ...enquireApi,
  ...invoiceApi,
  ...saledApi,
  ...expressApi
}
