import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Form, Input, Select, Radio, DatePicker } from 'antd'
import moment from 'moment'

import styles from './editforms.module.scss'

const FormItem = Form.Item
const { TextArea } = Input
const Option = Select.Option

class EditForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object
  }
  state = {

  }

  componentDidMount () { }

  render () {
    const { detailInfo = {} } = this.props
    const { getFieldDecorator } = this.props.form

    return (
      <div>
        <Row>
          <Form>
            <Row className={`${styles.formitemRow}`}>
              <FormItem label='消息类型'>
                {
                  getFieldDecorator('msgType', {
                    initialValue: detailInfo.msgType || '',
                    rules: [
                      { required: true, message: '请选择消息类型' }
                    ]
                  })(
                    <Select onChange={this.onChangeMsgType}>
                      <Option value={'SYSTEM_NOTIFY'}>活动通知</Option>
                      <Option value={'SYSTEM_ANNOUNCEMENT'}>系统公告</Option>
                      <Option value={'TASK_DISTRIBUTE'}>任务分发</Option>
                    </Select>
                  )
                }
              </FormItem>
            </Row>
            <FormItem label='标题'>
              {
                getFieldDecorator('title', {
                  initialValue: detailInfo.title || '',
                  rules: [
                    { required: true, message: '请填写标题' }
                  ]
                })(
                  <Input autoComplete='off' />
                )
              }
            </FormItem>
            <FormItem label='内容'>
              {
                getFieldDecorator('content', {
                  initialValue: detailInfo.content || '',
                  rules: [
                    { required: true, message: '请填写内容' }
                  ]
                })(
                  <TextArea autoComplete='off' rows={4} />
                )
              }
            </FormItem>
            <Row className={`${styles.formitemRow}`}>
              <FormItem label='上线类型'>
                {
                  getFieldDecorator('sendType', {
                    initialValue: detailInfo.sendType || 'NOW',
                    rules: [
                      { required: true, message: '请选择上线类型' }
                    ]
                  })(
                    <Radio.Group>
                      <Radio value={'NOW'}>即时上线</Radio>
                      <Radio value={'TIME_TASK'}>自动上线</Radio>
                    </Radio.Group>
                  )
                }
              </FormItem>
            </Row>

            {
              this.props.form.getFieldValue('sendType') === 'TIME_TASK'
                ? <Row className={`${styles.formitemRow}`}>
                  <FormItem label='上线时间'>
                    {
                      getFieldDecorator('sendTime', {
                        initialValue: detailInfo.sendTime ? moment(moment(detailInfo.sendTime).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss') : moment(moment().format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss'),
                        rules: [
                          { required: true, message: '请选择上线时间' }
                        ]
                      })(
                        <DatePicker showTime />
                      )
                    }
                  </FormItem>
                </Row>
                : null
            }

            {
              this.props.form.getFieldValue('msgType') === 'SYSTEM_NOTIFY'
                ? <Row className={`${styles.formitemRow}`}>
                  <FormItem label='发送对象' style={{ marginBottom: '10px' }}>
                    {
                      getFieldDecorator('notifyToEu', {
                        initialValue: (detailInfo.merchantNotifyType === 'M_EU_PART' || detailInfo.merchantNotifyType === 'M_EU_SINGLE') ? 'M_EU_PART' : 'M_EU_ALL',
                        rules: [
                          { required: true, message: '请设置发送的对象' }
                        ]
                      })(
                        <Radio.Group>
                          <Radio value={'M_EU_ALL'}>全体客户</Radio>
                          <Radio value={'M_EU_PART'}>自定义客户</Radio>
                        </Radio.Group>
                      )
                    }
                  </FormItem>
                </Row>
                : null
            }

            {
              this.props.form.getFieldValue('msgType') === 'TASK_DISTRIBUTE'
                ? <Row className={`${styles.formitemRow}`}>
                  <FormItem label='发送对象' style={{ marginBottom: '10px' }}>
                    {
                      getFieldDecorator('notifyToMember', {
                        initialValue: (detailInfo.merchantNotifyType === 'M_EMPLOYEE_PART' || detailInfo.merchantNotifyType === 'M_EMPLOYEE_SINGLE') ? 'M_EMPLOYEE_PART' : 'M_EMPLOYEE_ALL',
                        rules: [
                          { required: true, message: '请设置发送的对象' }
                        ]
                      })(
                        <Radio.Group>
                          <Radio value={'M_EMPLOYEE_ALL'}>全体员工</Radio>
                          <Radio value={'M_EMPLOYEE_PART'}>自定义员工</Radio>
                        </Radio.Group>
                      )
                    }
                  </FormItem>
                </Row>
                : null
            }

          </Form>
        </Row>
      </div>
    )
  }
}

export default Form.create()(EditForm)
