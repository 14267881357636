import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table, Button } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { pageSizeOptions } = filter
const { SERIALWIDTH } = consts

class PayVoucherModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }

  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'payVoucherCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '付款时间',
      key: 'payVoucherCol-1',
      dataIndex: 'payTime',
      render: (text, record, index) => (
        <>{ record.payTime ? <>{ moment(record.payTime).format('YYYY-MM-DD HH:mm:ss') }</> : '----' }</>
      )
    },
    {
      title: '付款凭证',
      key: 'payVoucherCol-2',
      dataIndex: 'url',
      render: (text, record, index) => (
        <>
          {
            record.payUrl && record.payUrl !== ''
              ? <Button type='link' size='small' href={record.payUrl} target='_blank'>付款凭证</Button>
              : '----'
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getCloudOrderPayVoucherDetails()
  }

  // 获取付款凭证详情
  getCloudOrderPayVoucherDetails = async () => {
    const { detailInfo = {} } = this.props
    const { pages } = this.state
    const res = await Api.getCloudOrderPayVoucherDetails({ purchaseOrderId: detailInfo.purchaseOrderId, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCloudOrderPayVoucherDetails()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudOrderPayVoucherDetails()
    })
  }

  render () {
    const { pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <Modal title='付款凭证' width={600} bodyStyle={{ padding: '16px' }}
        visible
        onCancel={() => this.props.onCancel()}
        footer={[<Button key={'btns-cancel'} onClick={() => this.props.onCancel()}>关闭</Button>]}
      >
        <Table rowKey={(record, index) => `${record.url}-${index}`}
          columns={this.columns}
          dataSource={tableData}
          pagination={pagination}
        />
      </Modal>
    )
  }
}

export default PayVoucherModal
