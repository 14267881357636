import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form, Tooltip } from 'antd'

import { renderInvoiceTypeLabel } from '@/common/utils/mUtils'

import styles from './index.module.scss'

const FormItem = Form.Item

export default class index extends Component {
  static propTypes = {
    invoiceType: PropTypes.string, // 发票类型
    invoiceCode: PropTypes.string, // 发票号码集合
    invoiceImageUrl: PropTypes.string // 发票图片
  }
  render () {
    const { invoiceType, invoiceCode, invoiceImageUrl } = this.props

    return (
      <>
        <Row gutter={24}>
          <Col span={24}>
            <div className={`${styles.formitemRow}`}>
              <FormItem label='发票类型' style={{ marginBottom: '0px' }}>{ renderInvoiceTypeLabel(invoiceType) }</FormItem>
            </div>
          </Col>

          <Col span={24}>
            <div className={`${styles.formitemRow}`}>
              <FormItem label='发票号码' style={{ marginBottom: '0px' }}>{ invoiceCode }</FormItem>
            </div>
          </Col>
          <Col span={12}>
            <div className={`${styles.row}`}>
              <label className={styles.formitemLabel}>发票图片</label>
              <div className={`${styles.rowInner}`}>
                {
                  invoiceImageUrl && invoiceImageUrl !== ''
                    ? <Tooltip placement='topLeft' title='点击可查看详情'>
                      <a href={invoiceImageUrl} target='_blank' rel='noopener noreferer'>{invoiceImageUrl}</a>
                    </Tooltip>
                    : null
                }
              </div>
            </div>
          </Col>
        </Row>
      </>
    )
  }
}
