import React, { Component } from 'react'
// import styles from './error.module.scss'
import img from './../../assets/images/404.png'

class error extends Component {
  render () {
    return (
      <div style={{ textAlign: 'center' }}>
        <img src={img} style={{ width: '60%' }} />
      </div>
    )
  }
}

export default error
