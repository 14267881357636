import React, { Component } from 'react'
import { Row, Card, Tabs } from 'antd'

import Euorder from './euorder' // 订单汇总
import StockOrder from './stockorder' // 现货订单
import FuturesOrder from './futuresorder' // 期货订单
import CloudPurchOrder from './../../cloud-purchase/purch-order/index' // 云采购订单
import SupplyOrderList from './../../cloud-supply/supply-order/index' // 云供应订单

import styles from './index.module.scss'

const { TabPane } = Tabs

const tablist = [
  { key: 'EU-order', name: '订单汇总' },
  { key: 'STOCK-order', name: '现货订单' },
  { key: 'FUTURES-order', name: '期货订单' },
  { key: 'CloudPurchOrder', name: '云采购订单' },
  { key: 'SupplyOrderList', name: '云供应订单' }
]

export default class GoodsOrder extends Component {
  static propTypes = {}
  state = {
    activekey: 'EU-order'
  }

  componentDidMount () { }

  // 选择Tbls页
  onChangeTab=(key) => {
    this.setState({
      activekey: key
    })
  }

  render () {
    const { activekey = 'EU-order' } = this.state

    return (
      < >
        <Row style={{ marginBottom: '15px' }}>
          <Card bodyStyle={{ padding: '12px 0px 0px 0px' }} bordered={false}>
            <Row className={`${styles.tabWrap}`}>
              <Tabs activeKey={activekey} onChange={(key) => { this.onChangeTab(key) }} >
                {
                  tablist.map((item) => (
                    <TabPane tab={item.name} key={item.key} />
                  ))
                }
              </Tabs>
            </Row>
          </Card>
        </Row>

        { activekey === 'EU-order' ? <Euorder /> : null }
        { activekey === 'STOCK-order' ? <StockOrder /> : null}
        {activekey === 'FUTURES-order' ? <FuturesOrder /> : null}
        {activekey === 'CloudPurchOrder' ? <CloudPurchOrder /> : null}
        { activekey === 'SupplyOrderList' ? <SupplyOrderList /> : null}

      </>
    )
  }
}
