export const manualItem = {
  brandId: null,
  brandName: null,
  model: null,
  itemNumber: null,
  quantity: null,
  name: null,
  unit: null,
  expectPrice: null,
  expectDiscount: null,
  expectDeliveryPeriodDays: null,
  matchingState: 'UNMATCHED',
  dataType: 'WRITE'
}
