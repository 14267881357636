import React, { Component } from 'react'
import { Card, Row, Descriptions, Modal, Button, Icon, message } from 'antd'

import Api from '@/common/api/index'
import AddressModal from '@/components/address-modal/index'
import { renderAddressLabel } from '@/common/utils/mUtils'

import styles from './address.module.scss'

const { confirm } = Modal
const MAXNUM = 10 // 收票地址最大数量

export default class AddressList extends Component {
  static propTypes = {}
  state = {
    tableData: [],
    type: 1,
    editModal: false,
    rowDetail: {}
  }

  componentDidMount () {
    this.getReceivingAddressList()
  }

  // 获取收件票地址
  getReceivingAddressList = () => {
    Api.getReceivingAddressList({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data || []
        })
      }
    })
  }

  // 保存新增收件地址
  saveAddReceivingAddress = (params) => {
    Api.saveAddReceivingAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getReceivingAddressList()
      }
    })
  }

  // 保存编辑收件地址
  saveEditReceivingAddress = (params) => {
    Api.saveEditReceivingAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getReceivingAddressList()
      }
    })
  }

  // 删除收票地址
  deleteReceivingAddress = (params) => {
    Api.deleteReceivingAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getReceivingAddressList()
      }
    })
  }

  // 点击新增/编辑
  handleShowEditModal = (record = {}, type) => {
    this.setState({
      type: type,
      editModal: true,
      rowDetail: record
    })
  }

  // 点击删除地址
  handleDeleteAddress = (record) => {
    confirm({
      title: '确定删除该地址？',
      content: '',
      onOk: () => {
        this.deleteReceivingAddress({ id: record.id, deleteStatus: 'ENABLE' })
      }
    })
  }

  // 确定保存地址
  handleConfirmSaveAddress = (params) => {
    const { type } = this.state
    if (type === 1) {
      this.saveAddReceivingAddress(params)
    } else {
      this.saveEditReceivingAddress(params)
    }
  }

  // 渲染DESC title
  renderCardItemTitle = (record) => {
    return (
      <div>
        <span>{record.name}</span>
        {
          record.status && record.status === 'ENABLE'
            ? <span className={`${styles.defaultLabel}`}>默认地址</span>
            : null
        }
      </div>
    )
  }

  closeModal = () => {
    this.setState({
      type: 1,
      editModal: false,
      rowDetail: {}
    })
  }

  render () {
    const { tableData = [], editModal = false, type = 1, rowDetail = {} } = this.state

    return (
      <div>
        <Row>
          <Card title='收票地址'>
            <Row style={{ marginBottom: '16px' }}>
              <Button type='primary'
                disabled={tableData.length === MAXNUM}
                onClick={() => this.handleShowEditModal({}, 1)}
              >
                <Icon type='plus' />新增收票地址
              </Button>

              <span style={{ marginLeft: '20px' }}>
                已创建{tableData.length}个收票地址，最多可创建{MAXNUM}个
              </span>
            </Row>
            <Row>
              {
                tableData.map((item, index) => (
                  <Row key={`address-${item.id}`}
                    className={`${styles.addressRow}`}
                  >
                    <Row>
                      <Descriptions title={this.renderCardItemTitle(item)}>
                        <Descriptions.Item label='收件人'>{item.name}</Descriptions.Item>
                        <Descriptions.Item label='手机号码'>
                          {item.mobile}
                        </Descriptions.Item>
                        <Descriptions.Item label='所在地区'>
                          { renderAddressLabel(item.province, item.city, item.county) }
                        </Descriptions.Item>
                        <Descriptions.Item label='详细地址'>
                          {item.address}
                        </Descriptions.Item>
                      </Descriptions>
                      <Row className={`${styles.extraRow}`}>
                        <a className={`${styles.extraItem}`}
                          onClick={() => this.handleShowEditModal(item, 2)}
                        > 编辑</a>

                        <a className={`${styles.extraItem}`}
                          onClick={() => this.handleDeleteAddress(item)}
                        >删除</a>

                      </Row>
                    </Row>
                  </Row>
                ))
              }
            </Row>
          </Card>
        </Row>

        {
          editModal
            ? <AddressModal
              type={type}
              detailInfo={rowDetail}
              onConfirm={(params) => this.handleConfirmSaveAddress(params)}
              onCancel={this.closeModal}
            />
            : null
        }
      </div>
    )
  }
}
