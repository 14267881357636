import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Row, Col, Input, InputNumber, Button, Table, Modal, Icon, Popover, message } from 'antd'

import Api from '@/common/api/index'
import $lodash from 'lodash'
import filter from '@/common/utils/filter'

import styles from './eushelves.module.scss'

const FormItem = Form.Item
const { TextArea } = Input
const { confirm } = Modal
const { repLineBreak } = filter
const discountTypeMaps = [
  { type: 'no', label: '无折扣表价' },
  { type: 'common', label: '原始默认价' },
  { type: 'modelGroup', label: '型号组价格' },
  { type: 'series', label: '客户系列价格' },
  { type: 'bom', label: '客户批量价格' },
  { type: 'sku', label: '客户特殊价格' }
]

class EushelvesForm extends Component {
  constructor (props) {
    super(props)
    this.onChangeInputValue = $lodash.debounce(this.onChangeInputValue, 300)
  }

  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }
  state = {
    isEdit: false, // 编辑状态 true-编辑中
    type: 1, // 1-新增BOM 2-查看详情
    curIndex: -1,
    userInfo: {},
    detailInfo: {},
    tableData: [],
    waitProducts: [] // 纠错待选的列表
  }

  columns = [
    {
      title: '产品',
      key: 'eushelvesDetail-1',
      render: (text, record, index) => (
        <div>
          {
            record.correct
              ? <div>
                <span className={`rowBrandName`}>
                  {record.brandName}{record.englishBrandName && record.englishBrandName !== '' ? `/${record.englishBrandName}` : null}
                </span>
                <span>{record.name}</span>
              </div>
              : null
          }
        </div>
      )
    },
    {
      title: '型号/订货号',
      key: 'eushelvesDetail-3',
      width: 160,
      render: (text, record, index) => {
        const { curIndex = -1 } = this.state

        return (
          <div>
            {
              !record.correct
                ? <div>
                  <div>{record.itemNumber}</div>
                  <div>
                    <Popover
                      placement='bottom' title={this.renderPopoverTitle()}
                      content={this.renderPopoverContent()}
                      trigger='click'
                      visible={curIndex === index}
                      onVisibleChange={() => this.setVisibleChange(index)}
                    >
                      <Button type='primary' size='small' style={{ fontSize: '12px' }}>纠错</Button>
                    </Popover>
                  </div>
                </div>
                : <div>
                  <div>订货号：<span dangerouslySetInnerHTML={{ __html: record.itemNumber }} /></div>
                  <div>型号：<span dangerouslySetInnerHTML={{ __html: record.model }} /></div>
                </div>
            }
          </div>
        )
      }
    },
    {
      title: '价格',
      key: 'eushelvesDetail-4',
      dataIndex: 'discountPrice',
      render: (text, record, index) => (
        <>
          {
            record.correct
              ? <>{+record.discountPrice > 0 ? `￥${(+record.discountPrice * 100 / 100).toFixed(2)}` : '面议'}</>
              : null
          }
        </>
      )
    },
    {
      title: '价格类型',
      key: 'eushelvesDetail-5',
      render: (text, record, index) => (
        <div>
          {this.renderEffectPriceInner(record.discountType)}
        </div>
      )
    },
    {
      title: '数量',
      key: 'eushelvesDetail-6',
      render: (text, record, index) => {
        const { isEdit = false } = this.state

        return (
          <div>
            {
              record.correct
                ? <div>
                  {
                    isEdit
                      ? <div>
                        <InputNumber
                          min={1}
                          value={+record.quantity > 0 ? +record.quantity : 1}
                          onChange={(val) => this.onChangeQuantity(+val, index)}
                        />
                        <div>最小订货量：{+record.minOrder === 0 ? 1 : +record.minOrder} {record.unit}</div>
                      </div>
                      : <div>
                        <div>{record.quantity}</div>
                        <div>最小订货量：{+record.minOrder === 0 ? 1 : +record.minOrder} {record.unit}</div>
                      </div>
                  }
                </div>
                : null
            }
          </div>
        )
      }
    },
    {
      title: '操作',
      key: 'eushelvesDetail-7',
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small'
            onClick={() => this.handleRemoveRow(index)}
          >
            删除
          </Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    const { state = {} } = this.props.history.location

    this.setState({
      type: state.type,
      userInfo: state.userInfo,
      detailInfo: state.detailInfo
    }, () => {
      if (+state.type === 2) {
        this.getEuShelvesItemDetail()
      }
    })
  }

  // 获取BOM单详情
  getEuShelvesItemDetail = () => {
    const { userInfo = {}, detailInfo = {} } = this.state
    let params = {
      endUserId: userInfo.endUserId,
      bomId: detailInfo.id,
      pageNo: 1,
      pageSize: 99999
    }

    Api.getEuShelvesItemDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        if (data.resultList && data.resultList.length > 0) {
          data.resultList.map((item, i) => {
            item.correct = true
            item.isOrigin = true
            item.quantity = +item.quantity > 0 ? +item.quantity : 1
          })
        } else {
          message.warning(res.message)
        }

        this.setState({
          tableData: data.resultList || []
        })
      }
    })
  }

  // 快速搜索
  getEuShelvesItemQuickSearch = (unitList) => {
    const { userInfo = {} } = this.state
    let params = {
      endUserId: userInfo.endUserId,
      unitList: unitList
    }

    Api.getEuShelvesItemQuickSearch(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { tableData = [] } = this.state

        // 取出两个数组交集
        let arr1 = $lodash.intersectionBy(tableData, data, 'itemNumber')

        arr1.map(item => {
          const val = $lodash.filter(data, ['itemNumber', item.itemNumber])

          item.quantity = item.quantity + val[0].quantity
          return item
        })

        let arr2 = $lodash.differenceWith(tableData, arr1, (item1, item2) => {
          return item1.itemNumber === item2.itemNumber
        })

        let arr3 = $lodash.differenceWith(data, arr1, (item1, item2) => {
          return item1.itemNumber === item2.itemNumber
        })

        let _list = [...arr1, ...arr2, ...arr3]

        this.setState({
          tableData: _list
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 纠错查找
  getEuShelvesCorrectSearch = (params) => {
    Api.getEuShelvesCorrectSearch(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          waitProducts: data || []
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 保存新增BOM单
  saveAddEuShelvesItem = (params) => {
    Api.saveAddEuShelvesItem(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.setState({
          isEdit: false
        }, () => {
          this.props.history.push({ pathname: '/admin/eushelvesList' })
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 保存更新BOM单
  saveUpdateEuShelvesItem = (params) => {
    Api.saveUpdateEuShelvesItem(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.setState({
          isEdit: false
        }, () => {
          this.getEuShelvesItemDetail()
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 点击快速查找
  handleBatchSearch = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let _val = repLineBreak(values.inputValues, '<br>')
        let arr = _val.split('<br>')

        let unitList = []

        arr.map(item => {
          if (item !== '') {
            let _arr = item.split(',')
            let obj = {
              itemNumber: _arr.length > 0 ? _arr[0] : '',
              model: _arr.length > 0 ? _arr[0] : '',
              quantity: _arr[1] && +_arr[1] > 0 ? +_arr[1] : 1
            }

            unitList.push(obj)
          }
        })

        this.getEuShelvesItemQuickSearch(unitList)
      }
    })
  }

  // 点击删除
  handleRemoveRow = (i) => {
    confirm({
      title: '确定删除？',
      content: '',
      onOk: () => {
        let { userInfo = {}, detailInfo = {}, tableData = [] } = this.state

        if (tableData[i].isOrigin) {
          let arr = []
          let obj = {
            commoditySkuId: tableData[i].commoditySkuId
          }
          arr.push(obj)

          let params = {
            detailList: arr,
            id: detailInfo.id,
            endUserId: userInfo.endUserId
          }

          Api.deleteEuShelvesItemSku(params).then(res => {
            const { code } = res
            if (+code === 10000) {
              let { tableData = [] } = this.state
              tableData.splice(i, 1)

              this.setState({
                tableData
              })
            } else {
              message.warning(res.message)
            }
          })
        } else {
          tableData.splice(i, 1)
        }

        this.setState({
          tableData
        })
      }
    })
  }

  // 点击编辑BOM单/取消编辑
  handleEdit = (type = 1) => {
    this.setState({
      isEdit: type === 1
    })
  }

  // 点击保存BOM单
  handleSaveToShelves = () => {
    const { type = 1, detailInfo = {}, userInfo = {}, tableData = [] } = this.state

    let errIdx = tableData.findIndex(item => {
      return !item.correct
    })

    if (errIdx > -1) {
      return message.warning('有不正确的产品信息，不可保存至BOM单')
    }

    let is0index = tableData.findIndex(item => {
      return +item.quantity === 0
    })

    if (is0index > -1) {
      return message.warning('BOM单商品数量不可为0')
    }

    let minIndex = tableData.findIndex(row => {
      return +row.quantity < (+row.minOrder === 0 ? 1 : +row.minOrder)
    })

    if (minIndex > -1) {
      return message.warning(`订货号${tableData[minIndex].itemNumber}最小订货量为` +
        `${+tableData[minIndex].minOrder === 0 ? 1 : +tableData[minIndex].minOrder} ${tableData[minIndex].unit}`)
    }

    let arr = []
    tableData.map(item => {
      if (item.correct) {
        let obj = {
          commoditySkuId: item.commoditySkuId,
          quantity: item.quantity
        }

        arr.push(obj)
      }
    })

    let params = {
      detailList: arr,
      endUserId: userInfo.endUserId
    }

    if (type === 1) {
      params.name = detailInfo.name

      this.saveAddEuShelvesItem(params)
    } else {
      params.id = detailInfo.id
      this.saveUpdateEuShelvesItem(params)
    }
  }

  // 改变数量
  onChangeQuantity = (val, i) => {
    let { tableData = [] } = this.state

    tableData[i].quantity = val

    this.setState({
      tableData,
      curIndex: -1
    })
  }

  // 监听纠错输入
  onChangeInputValue = (value) => {
    const { userInfo = {} } = this.state
    this.getEuShelvesCorrectSearch({ keyword: value, endUserId: userInfo.endUserId })
  }

  // 纠错-选择正确的sku
  handleSelectedSku = (record) => {
    let { curIndex = -1, tableData = [] } = this.state
    record.correct = true
    record.quantity = 1

    let n = tableData.findIndex(item => {
      return +item.commoditySkuId === +record.commoditySkuId
    })

    if (n > -1) {
      tableData[n].quantity = tableData[n].quantity + 1
      tableData.splice(curIndex, 1)
    } else {
      tableData[curIndex] = record
    }

    this.setState({
      tableData
    })
  }

  // 点击返回
  handleBack = () => {
    this.props.history.go(-1)
  }

  // 设置当前正在编辑下标
  setVisibleChange = (index) => {
    let { userInfo = {}, tableData = [] } = this.state
    tableData[index].visible = !tableData[index].visible

    this.setState({
      curIndex: index
    }, () => {
      let _val = tableData[index].itemNumber
      this.getEuShelvesCorrectSearch({ keyword: _val, endUserId: userInfo.endUserId })
    })
  }

  // 展示生效价格类型
  renderEffectPriceInner = (type) => {
    const obj = discountTypeMaps.find(item => { return item.type === type })
    const label = obj !== undefined ? obj.label : ''

    return label
  }

  // 渲染popover 内容
  renderPopoverContent = () => {
    const { waitProducts = [] } = this.state

    return (
      <div style={{ width: '500px' }}>
        <div className={`${styles.searchInput}`}>
          <Input placeholder='请输入...' autoComplete='off'
            onChange={(e) => this.onChangeInputValue(e.target.value)}
          />
        </div>
        {
          waitProducts.length > 0
            ? <div className={`${styles.searchResult}`}>
              <ul>
                {
                  waitProducts.map((sku, i) => (
                    <li className={`flex ${styles.searchItem}`} key={`waitSelected-${i}`}>
                      <div className={`${styles.product}`}
                        dangerouslySetInnerHTML={{ __html: sku.name }}
                      />
                      <Button type='primary' size='small'
                        onClick={() => this.handleSelectedSku(sku)}
                      >确定</Button>
                    </li>
                  ))
                }
              </ul>
            </div>
            : null
        }
      </div>
    )
  }

  // 渲染popover title
  renderPopoverTitle = () => {
    return (
      <div className={`${styles.popoverTitleLand}`}>
        <div className={`${styles.popoverTitle}`}>您输入的型号/订货号信息可能有误</div>
        <div className={`${styles.closeWrap}`}>
          <Icon type='close'
            onClick={this.closePopover}
          />
        </div>
      </div>
    )
  }

  // 根据条件渲染rowClassName
  renderErrorRowName = (record) => {
    let name = ''

    if (!record.correct) {
      name = styles.errorBg
    }

    return name
  }

  // 关闭纠错popover弹层
  closePopover = () => {
    this.setState({
      curIndex: -1
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { isEdit = false, detailInfo = {}, userInfo = {}, tableData = [] } = this.state

    if (tableData.length > 0) {
      tableData.map((item, i) => {
        item.rowkey = `${item.itemNumber}-${i}`
      })
    }

    return (
      <>
        <Card>
          <Row style={{ marginBottom: '10px' }}>
            <Button icon='left' onClick={() => this.handleBack()}>返回列表</Button>
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <span style={{ marginRight: '30px' }}>BOM名称：{detailInfo.name}</span>
            <span>客户：{userInfo.showName}</span>
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            {
              !isEdit
                ? <Button type='primary'
                  style={{ width: '110px', height: '30px', paddingLeft: '0px', paddingRight: '0px' }}
                  onClick={() => this.handleEdit(1)}
                >编辑BOM单</Button>
                : <Button type='primary'
                  style={{ width: '110px', height: '30px', paddingLeft: '0px', paddingRight: '0px' }}
                  onClick={() => this.handleEdit(2)}
                >取消编辑</Button>
            }
          </Row>

          {
            isEdit
              ? <Row gutter={14} style={{ marginBottom: '15px' }}>
                <Col span={16}>
                  <Form>
                    <FormItem style={{ marginBottom: '0px' }}>
                      {
                        getFieldDecorator('inputValues', {
                          rules: [
                            { required: true, message: '请填写您要查找的型号货订货号' }
                          ]
                        })(
                          <TextArea rows={4} />
                        )
                      }
                    </FormItem>
                  </Form>
                </Col>

                <Col span={8}>
                  <Button type='primary'onClick={() => this.handleBatchSearch()}>快速查找</Button>
                </Col>
              </Row>
              : null
          }

          <Row style={{ marginBottom: '24px' }}>
            <Table rowKey='rowkey'
              dataSource={tableData}
              columns={this.columns}
              pagination={false}
              rowClassName={(record) => this.renderErrorRowName(record)}
            />
          </Row>

          <Row style={{ textAlign: 'center' }}>
            {
              isEdit
                ? <Button type='primary' onClick={this.handleSaveToShelves}>
                  保存BOM单
                </Button>
                : null
            }

          </Row>
        </Card>
      </>
    )
  }
}

const EushelvesDetail = Form.create()(EushelvesForm)

export default EushelvesDetail
