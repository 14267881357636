import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Col, Form, Input, Upload, Icon } from 'antd'

import UploadThumbnail from '@/components/uploadThumbnail/index'

import UploadApi from '@/common/api/uploadApi'

const FormItem = Form.Item
const { TextArea } = Input
const { uploadOrdinaryImageUrl } = UploadApi

export default class VoucherModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }
  state = {
    voucherImg: '',
    remark: ''
  }

  componentDidMount () {}

  // 点击确认
  handleConfirm = () => {
    const { detailInfo } = this.props
    const { remark = '', voucherImg = '' } = this.state
    let params = {
      id: detailInfo.id,
      remarks: remark,
      voucherUrl: voucherImg || ''
    }

    this.props.onConfirm(params)
  }

  // 监听输入
  onChangeTextValue = (val) => {
    this.setState({
      remark: val
    })
  }

  // 点击上传
  onChangeVoucherFile = (info) => {
    if (info.file.status === 'done') {
      const { data } = info.file.response

      this.setState({
        voucherImg: data.imageUrl || ''
      })
    }
  }

  // 删除图片
  removeImg = () => {
    this.setState({
      voucherImg: ''
    })
  }

  render () {
    const { voucherImg = '' } = this.state

    return (
      <div>
        <Modal title='凭证'
          visible
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Row style={{ marginBottom: '20px', textAlign: 'center', fontSize: '18px' }}>
            确认已经为该用户打款？
          </Row>
          <Row>
            <Form>
              <Col span={6} offset={2}>
                <FormItem label='打款凭证'>
                  {
                    voucherImg !== ''
                      ? <UploadThumbnail
                        imageUrl={voucherImg}
                        onDelete={() => this.removeImg}
                      />
                      : <Upload
                        name='file'
                        accept='image/png, image/jpg, image/jpeg'
                        action={uploadOrdinaryImageUrl}
                        listType='picture-card'
                        onChange={this.onChangeVoucherFile}
                      >
                        <div style={{ textAlign: 'center' }}>
                          <Icon type='plus' />
                          <p>点击上传</p>
                        </div>
                      </Upload>

                  }
                </FormItem>
              </Col>

              <Col span={14} style={{ paddingLeft: '10px' }}>
                <FormItem label='备注'>
                  <TextArea rows={4} placeholder='请输入...'
                    onChange={(e) => this.onChangeTextValue(e.target.value)}
                  />
                </FormItem>
              </Col>

            </Form>
          </Row>
        </Modal>
      </div>
    )
  }
}
