import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Button, Table, Icon } from 'antd'
import moment from 'moment'

import DetailModal from './detailModal'

import filter from '@/common/utils/filter'
import { getCourierName } from '@/common/utils/mUtils'

const { pageSizeOptions } = filter

export default class BilldetailCard extends Component {
  static propTypes = {
    pages: PropTypes.object,
    tableData: PropTypes.array,
    onDownload: PropTypes.func,
    pageChange: PropTypes.func,
    pageSizeChange: PropTypes.func
  }
  state = {
    rowDetail: {},
    detailModal: false
  }
  columns = [
    {
      title: '序号',
      key: 'detailList-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '订单编号',
      key: 'detailList-1',
      dataIndex: 'orderId'
    },
    {
      title: '发货日期',
      key: 'detailList-2',
      render: (text, record, index) => (
        <div>
          { record.senderTime ? moment(record.senderTime).format('YYYY-MM-DD HH:mm:ss') : null }
        </div>
      )
    },
    {
      title: '收货日期',
      key: 'detailList-3',
      render: (text, record, index) => (
        <div>
          { record.signedStatus === 'SIGNED' && record.receiverTime ? moment(record.receiverTime).format('YYYY-MM-DD HH:mm:ss') : null }
        </div>
      )
    },
    {
      title: '运单号',
      key: 'detailList-4',
      dataIndex: 'mailNoList',
      render: (text, record, index) => (
        <div>{ record.mailNoList && record.mailNoList.length > 0 ? record.mailNoList.join(',') : '----'}</div>
      )
    },
    {
      title: '发货地址',
      key: 'detailList-5',
      width: 160,
      ellipsis: true,
      render: (text, record, index) => (
        <span>{record.senderProvince}&nbsp;{record.senderCity}&nbsp;{record.senderCounty}&nbsp;{record.senderAddress}</span>
      )
    },
    {
      title: '收货地址',
      key: 'detailList-6',
      width: 160,
      ellipsis: true,
      render: (text, record, index) => (
        <span>
          {record.receiverProvince}&nbsp;{record.receiverCity}&nbsp;{record.receiverCounty}&nbsp;{record.receiverAddress}
        </span>
      )
    },
    {
      title: '快递公司',
      key: 'detailList-7',
      render: (text, record, index) => (
        <>{ getCourierName(record.courierCode) }</>
      )
    },
    {
      title: '发货人',
      key: 'detailList-8',
      dataIndex: 'senderName'
    },
    {
      title: '收货人',
      key: 'detailList-9',
      dataIndex: 'receiverName'
    },
    {
      title: '货物名称',
      key: 'detailList-10',
      width: 80,
      render: (text, record, index) => (
        <a onClick={() => this.showDetailModal(record)}>工业品</a>
      )
    },
    {
      title: '件数',
      key: 'detailList-11',
      dataIndex: 'totalNumber'
    },
    {
      title: '货物总价值',
      key: 'detailList-12',
      dataIndex: 'goodsAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.goodsAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '保险金额',
      key: 'detailList-13',
      dataIndex: 'insureAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.insureAmount * 100 / 100).toFixed(2)}</>
      )
    }
  ]

  componentDidMount () { }

  // 点击查看详情
  showDetailModal = (record) => {
    this.setState({
      detailModal: true,
      rowDetail: record
    })
  }

  closeModal = () => {
    this.setState({
      detailModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    this.props.pageChange(page, pageSize)
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    this.props.pageSizeChange(current, size)
  }

  render () {
    const { pages, tableData = [] } = this.props
    const { detailModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card title='账单明细' bodyStyle={{ padding: '15px' }}
          extra={<Button type='primary' onClick={() => this.props.onDownload()}><Icon type='download' />导出月度明细</Button>}
        >
          <Row>
            <Table rowKey={(record, index) => { return `billDetailFlow-${record.orderId}-${index}` }}
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination}
              scroll={{ x: 1200 }}
            />
          </Row>
        </Card>

        {
          detailModal
            ? <DetailModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}
