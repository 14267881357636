import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Descriptions, Table, Select, Input, InputNumber, Row, Col, Upload, Button, Icon, message } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import UploadThumbnail from '@/components/uploadThumbnail/index'
import ProductModal from '../productModal/index'
import AddressModal from '@/components/address-modal/index'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'

import filter from '@/common/utils/filter'
import { getStore, renderSaledRefundTypeLabel, renderAddressLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'
import styles from './index.module.scss'

const FormItem = Form.Item
const Option = Select.Option
const { TextArea } = Input
const { uploadOrdinaryImageUrl } = UploadApi
const { saledTypeMaps, saledReasonTypeMaps, saledBackGoodsTypeMaps } = filter
const { REMARKS_MAX_NUM, IMG_LT2M } = consts
const UPLOAD_MAX_NUM = 9

export default class DrawerContents extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    onSelectedProduct: PropTypes.func, // 监听选择的产品
    onChangePickupAddress: PropTypes.func // 监听取件地址
  }
  state = {
    productModal: false,
    addressModal: false,
    tableData: [],
    sendAddressData: [], // 取件地址
    uploadImageUrls: [] // 上传的图片url
  }
  columns = [
    {
      title: '序号',
      key: 'drawerProductCol-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '型号',
      key: 'drawerProductCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'drawerProductCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'drawerProductCol-4',
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'drawerProductCol-5',
      dataIndex: 'unit'
    },
    {
      title: '数量',
      key: 'drawerProductCol-6',
      dataIndex: 'quantity'
    },
    {
      title: '含税单价',
      key: 'drawerProductCol-7',
      dataIndex: 'taxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{+record.taxUnitPrice}</>
      )
    },
    {
      title: '含税单品小计',
      key: 'drawerProductCol-8',
      dataIndex: '_totalPrice',
      render: (text, record, index) => (
        <>&yen;{ ($lodash.round(+record.quantity * +record.taxUnitPrice, 2) * 100 / 100).toFixed(2)}</>
      )
    }
  ]

  componentDidMount () {
    this.getSendAddressList()
  }

  // 获取发货地址列表
  getSendAddressList = () => {
    Api.getSendAddressList({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          sendAddressData: data || []
        })
      }
    })
  }

  // 新增发货地址
  saveSendAddress = (params) => {
    Api.saveSendAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getSendAddressList()
      }
    })
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过${IMG_LT2M}MB`)
    }

    return isLt1M
  }

  // 上传图片
  handleChangeUpload = (info = {}) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        const { uploadImageUrls = [] } = this.state

        uploadImageUrls.push(data.imageUrl)

        this.setState({
          uploadImageUrls: [...uploadImageUrls]
        }, () => {
          this.props.form.setFieldsValue({ uploadImageUrls: uploadImageUrls.join(',') })
        })
      } else {
        message.warning('上传失败')
      }
    }
  }

  // 删除图片
  handleRemoveUpload = (n) => {
    const { uploadImageUrls = [] } = this.state

    uploadImageUrls.splice(n, 1)

    this.setState({
      uploadImageUrls: [...uploadImageUrls]
    }, () => {
      this.props.form.setFieldsValue({ uploadImageUrls: uploadImageUrls.join(',') })
    })
  }

  // 点击选择售后商品
  showSaledProductModal = () => {
    this.setState({
      productModal: true
    })
  }

  // 点击新增地址
  showAddressModal = () => {
    this.setState({
      addressModal: true
    })
  }

  closeModal = () => {
    this.setState({
      productModal: false,
      addressModal: false
    })
  }

  // 确定售后产品
  confirmSelectedProduct = (list = []) => {
    this.setState({
      tableData: list,
      productModal: false
    }, () => {
      this.props.onSelectedProduct([...list])
    })
  }

  // 监听取件地址变化
  changePickupAddress = (value) => {
    const { sendAddressData = [] } = this.state

    const obj = sendAddressData.find((item) => { return item.id === value })
    this.props.onChangePickupAddress(obj !== undefined ? obj : {})
  }

  render () {
    const { form, detailInfo = {} } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { sendAddressData = [], tableData = [], uploadImageUrls = [], productModal = false, addressModal = false } = this.state

    return (
      <>
        <Descriptions>
          <Descriptions.Item label='订单编号'>{ detailInfo.orderId }</Descriptions.Item>
          <Descriptions.Item label='订单日期'>{ detailInfo.orderTime ? moment(detailInfo.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</Descriptions.Item>
        </Descriptions>

        <div>
          <Button type='primary' style={{ marginBottom: '15px' }} onClick={() => this.showSaledProductModal()}>请选择售后商品</Button>

          <Table rowKey={(record, index) => `${record.commoditySkuId}-${record.skuGoodsType}-${record._index}`} bordered
            columns={this.columns}
            dataSource={tableData}
            pagination={false}
          />
        </div>

        <Row style={{ marginTop: '24px' }}>
          <Col span={12}>
            <div className={`${styles.formitemRow}`}>
              <FormItem label='售后类型'>
                {
                  getFieldDecorator('afterSaleOrderType', {
                    rules: [
                      { required: true, message: '请选择售后类型' }
                    ]
                  })(
                    <Select placeholder='请选择...' >
                      {
                        saledTypeMaps.map((item, i) => (
                          <Option value={item.id} key={`saledTypeOption-${i}`}>{ item.name }</Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>
            </div>

            <div className={`${styles.formitemRow}`}>
              <FormItem label='售后原因'>
                {
                  getFieldDecorator('reasonType', {
                    rules: [
                      { required: true, message: '请选择售后原因' }
                    ]
                  })(
                    <Select placeholder='请选择...'>
                      {
                        saledReasonTypeMaps.map((item, i) => (
                          <Option value={item.id} key={`reasonTypeOption-${i}`}>{ item.name }</Option>
                        ))
                      }
                    </Select>
                  )
                }
              </FormItem>
            </div>

            <div className={`${styles.formitemRow}`}>
              <FormItem label='详细描述'>
                {
                  getFieldDecorator('backRemark', {

                  })(
                    <TextArea rows={4}
                      placeholder={`请输入内容，不超过${REMARKS_MAX_NUM}字...`}
                      autoComplete='off'
                      maxLength={REMARKS_MAX_NUM}
                    />
                  )
                }
              </FormItem>
            </div>

            <FormItem label='可添加图片来帮助售后人员判断(最多9张)' style={{ marginBottom: '0px' }}>
              <FormItem style={{ display: 'none' }}>
                {
                  getFieldDecorator('uploadImageUrls', {

                  })(<Input disabled autoComplete='off' />)
                }
              </FormItem>
              {
                uploadImageUrls.length > 0
                  ? <>
                    {
                      uploadImageUrls.map((item, n) => (
                        <div className={`${styles.pictureWrap}`} key={`pictureCol-${n}`}>
                          <UploadThumbnail imageUrl={item} onDelete={() => this.handleRemoveUpload(n)} />
                        </div>
                      ))
                    }
                  </>
                  : null
              }

              {
                uploadImageUrls.length < UPLOAD_MAX_NUM
                  ? <div className={`${styles.pictureWrap}`}>
                    <Upload name='file'
                      listType='picture-card'
                      accept='image/png, image/jpg, image/jpeg'
                      showUploadList={false}
                      action={uploadOrdinaryImageUrl}
                      headers={{ Token: getStore('token') }}
                      beforeUpload={this.beforeUpload}
                      onChange={(info) => this.handleChangeUpload(info)}
                    >
                      <div>
                        <Icon type='plus' />
                        <div>点击上传</div>
                      </div>
                    </Upload>
                  </div>
                  : null
              }
            </FormItem>

            {
              ['REFUND_RETURN_PRODUCTS', 'CHANGE_PRODUCTS'].includes(getFieldValue('afterSaleOrderType'))
                ? <>
                  <div className={`${styles.formitemRow}`}>
                    <FormItem label='退货方式'>
                      {
                        getFieldDecorator('backGoodsType', {
                          rules: [
                            { required: true, message: '请选择退货方式' }
                          ]
                        })(
                          <Select>
                            {
                              saledBackGoodsTypeMaps.map((item, i) => (
                                <Option value={item.id} key={`backGoodsTypeOption-${item.id}-${i}`}>{ item.name }</Option>
                              ))
                            }
                          </Select>
                        )
                      }
                    </FormItem>
                  </div>

                  {
                    getFieldValue('backGoodsType') === 'SELF'
                      ? <div className={`${styles.formitemRow}`}>
                        <FormItem label='请退货至'>上海市 闵行区 江月路1388号伍信禾风广场3号楼255室 19532535667 萨斯云售后组</FormItem>
                      </div>
                      : null
                  }
                </>
                : null
            }
          </Col>
        </Row>

        {
          ['REFUND_RETURN_PRODUCTS', 'CHANGE_PRODUCTS'].includes(getFieldValue('afterSaleOrderType')) && getFieldValue('backGoodsType') === 'IIASAAS'
            ? <Row>
              <Col span={16}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='上门取件地址'>
                    {
                      getFieldDecorator('pickupCitys', {
                        rules: [
                          { required: true, message: '请选择取件地址' }
                        ]
                      })(
                        <Select onChange={(value) => this.changePickupAddress(value)}>
                          {
                            sendAddressData.map((opt, i) => (
                              <Option value={opt.id} key={`addressOpt-${opt.id}-${i}`}>
                                {renderAddressLabel(opt.province, opt.city, opt.county, opt.address)} {opt.name} {opt.mobile}
                              </Option>
                            ))
                          }
                        </Select>
                      )
                    }
                  </FormItem>
                </div>
              </Col>
              <Col span={4}>
                <FormItem>
                  <Button type='link' size='small' onClick={() => this.showAddressModal()}>新增地址</Button>
                </FormItem>
              </Col>
            </Row>
            : null
        }

        {
          ['REFUND_RETURN_PRODUCTS', 'REFUND'].includes(getFieldValue('afterSaleOrderType'))
            ? <Row>
              <Col span={12}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem style={{ display: 'none' }}>
                    {
                      getFieldDecorator('refundType', {
                        initialValue: 'ORIGINAL_PAYMENT_TYPE'
                      })(<Input disabled autoComplete='off' />)
                    }
                  </FormItem>
                  <FormItem label='退款方式' style={{ marginBottom: '0px' }}>{ renderSaledRefundTypeLabel(getFieldValue('refundType')) }</FormItem>
                </div>

                <div className={`${styles.formitemRow}`}>
                  <FormItem label='预估退款金额' style={{ display: 'none' }}>
                    {
                      getFieldDecorator('backAmount', {
                        initialValue: $lodash.sumBy(tableData, (o) => { return o.quantity * o.taxUnitPrice }) * 100 / 100
                      })(<InputNumber disabled autoComplete='off' />)
                    }
                  </FormItem>

                  <FormItem label='预估退款金额' style={{ marginBottom: '0px' }}>
                      &yen;{($lodash.round($lodash.sumBy(tableData, (o) => { return o.quantity * o.taxUnitPrice }), 2) * 100 / 100).toFixed(2)}
                  </FormItem>
                </div>
              </Col>
            </Row>
            : null
        }

        {
          ['ADD_DELIVER'].includes(getFieldValue('afterSaleOrderType'))
            ? <Row>
              <Col span={16}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='补寄地址'>
                    {
                      getFieldDecorator('replacementAddressId', {
                        rules: [
                          { required: true, message: '请选择补寄地址' }
                        ]
                      })(
                        <Select onChange={(value) => this.changePickupAddress(value)}>
                          {
                            sendAddressData.map((opt, i) => (
                              <Option value={opt.id} key={`replacementOpt-${opt.id}-${i}`}>
                                {renderAddressLabel(opt.province, opt.city, opt.county, opt.address)} {opt.name} {opt.mobile}
                              </Option>
                            ))
                          }
                        </Select>
                      )
                    }
                  </FormItem>
                </div>
              </Col>
              <Col span={4}>
                <FormItem>
                  <Button type='link' size='small' onClick={() => this.showAddressModal()}>新增地址</Button>
                </FormItem>
              </Col>
            </Row>
            : null
        }

        {
          productModal
            ? <ProductModal
              orderId={detailInfo.orderId}
              onCancel={() => this.closeModal()}
              onConfirm={(list) => this.confirmSelectedProduct(list)}
            />
            : null
        }

        {
          addressModal
            ? <AddressModal
              type={1}
              componentType={['REFUND_RETURN_PRODUCTS', 'CHANGE_PRODUCTS'].includes(getFieldValue('afterSaleOrderType')) ? 'SEND' : null}
              detailInfo={{}}
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.saveSendAddress(params)}
            />
            : null
        }
      </>
    )
  }
}
