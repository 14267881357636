import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, InputNumber } from 'antd'

const FormItem = Form.Item
const { TextArea } = Input

class AppletForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }

  onChangeAppletSaled = (e) => {
    this.setState({
      afterSaleOrderStatus: e.target.value
    })
  }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 }
    }

    return (
      <div>
        <Modal title='售后处理'
          visible
          onOk={this.handleConfirm}
          onCancel={this.props.onCancel}
        >
          <Form>

            <FormItem label='退款金额' {...formItemLayout}>
              {
                getFieldDecorator('backAmount', {
                  rules: [
                    { required: true, message: '该项必填' }
                  ]
                })(
                  <InputNumber style={{ width: '100%' }} placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>

            <FormItem label='备注' {...formItemLayout}>
              {
                getFieldDecorator('feedbackDesc', {
                })(
                  <TextArea rows={4} placeholder='请输入...' autoComplete='off' />
                )
              }
            </FormItem>
          </Form>
        </Modal>
      </div>
    )
  }
}

const AppletModal = Form.create()(AppletForm)

export default AppletModal
