import React, { Component } from 'react'
import { Row, Card, Table, message } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class Behavior extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'endUserId',
        formType: 'Select',
        itemParams: {
          label: '客户名称'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['endUserId', 'showName']
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {}
  }
  columns = [
    {
      title: '序号',
      key: 'behavior-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '客户',
      key: 'behavior-1',
      render: (text, record, index) => (
        <div>
          {
            record.realName && record.realName !== ''
              ? <div style={{ color: '#eb2f96' }}>{record.realName}</div>
              : <div>游客</div>
          }

          {
            record.enterpriseName
              ? <div>{record.enterpriseName}</div>
              : <div>--</div>
          }
        </div>
      )
    },
    {
      title: '行为',
      key: 'behavior-2',
      dataIndex: 'remark'
    },
    {
      title: '时间',
      key: 'behavior-3',
      render: (text, record, index) => (
        <div>
          {
            record.createTime
              ? <span>{moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')}</span>
              : <span>--</span>
          }
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getGuesteuUserLike()
    this.getEucustomerBehavior()
  }

  // 搜索客户信息
  getGuesteuUserLike = (val = '') => {
    let params = {
      pageNo: 1,
      pageSize: 999,
      searchName: val
    }
    Api.getGuesteuUserLike(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state

        searchData[0].options = data.list || []

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取用户行为分析
  getEucustomerBehavior = () => {
    const { pages, query } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getEucustomerBehavior(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.resultList || [],
          pages: {
            ...pages,
            total: data.totalNum
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    if (!search.endUserId) {
      return message.warning('请选择客户')
    }

    const { pages } = this.state
    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getEucustomerBehavior()
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEucustomerBehavior()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEucustomerBehavior()
    })
  }

  render () {
    let { searchData, pages, tableData } = this.state

    if (tableData.length > 0) {
      tableData.map((item, i) => {
        item.id = `behavior-${i}`
      })
    }

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </Row>

        <Row>
          <Card bodyStyle={{ padding: '15px' }}>
            <Table rowKey='id'
              pagination={pagination}
              columns={this.columns}
              dataSource={tableData}
            />
          </Card>
        </Row>
      </>
    )
  }
}
