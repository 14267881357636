import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setHandleModal } from '@/reducers/action'
import { Card, Table, Row, Button, Icon, Modal, Badge, message, Tag, Tooltip } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import BatchSearchMenu from '@/components/batchSearchMenu/index'
import UploadModal from '@/pages/store-house/component/upload-modal'
import ProgressModal from '../component/progress-modal'
import OperateModal from './operate-modal'

import Api from '@/common/api'
import { getBracketStr, getCaption } from '@/common/utils/mUtils'
import { downloadExcel } from '@/common/utils/downloadFile'
import filter from '@/common/utils/filter'

import styles from './operate.module.scss'

const { pageSizeOptions, productPutOnMap, productAddMap } = filter

class ModelLibrary extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func
  }
  state = {
    searchData: [
      {
        name: 'keyword',
        formType: 'Input',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '请输入型号或订货号',
          autoComplete: 'off'
        }
      },
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      },
      {
        name: 'stockStatus',
        formType: 'Select',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '现货上架状态'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...productPutOnMap
        ]
      },
      {
        name: 'futuresStatus',
        formType: 'Select',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '期货上架状态'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...productPutOnMap
        ]
      },
      {
        name: 'addStatus',
        formType: 'Select',
        itemParams: {
          label: ''
        },
        cptParams: {
          placeholder: '添加到产品库'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...productAddMap
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {}, // 查询条件
    tableData: [],
    selectedRowKeys: [], // 选中的产品
    rowDetail: [],
    type: 1, // 1-选择设置 2-条件设置
    searchFailModal: false,
    batchSearchResult: {}
  }

  columns = [
    {
      title: '序号',
      key: 'modelLibrary-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'modelLibrary-1',
      width: 120,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <p>{record.brandName}</p>
          <p>{ record.englishBrandName || '----' }</p>
        </>
      )
    },
    {
      title: '型号',
      key: 'modelLibrary-2',
      width: 160,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.model }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.model }} />
          </Tooltip>

          { record.modelExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '订货号',
      key: 'modelLibrary-3',
      width: 130,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <Tooltip placement='topLeft' title={<span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />}>
            <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
          </Tooltip>

          { !record.modelExactMatch && record.itemNumberExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '表价',
      key: 'modelLibrary-4',
      width: 120,
      dataIndex: 'price',
      render: (text, record, index) => (
        <>{ +record.price > 0 ? <>&yen;{+record.price}</> : '面议' }</>
      )
    },
    {
      title: '产品名称',
      key: 'modelLibrary-5',
      width: 180,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '分类',
      key: 'modelLibrary-6',
      width: 120,
      dataIndex: 'classificationName',
      ellipsis: true
    },
    {
      title: '上架状态',
      key: 'modelLibrary-7',
      colSpan: 2,
      render: (text, record, index) => (
        <div>
          {
            record.stockStatus === 'ENABLE'
              ? <Badge status='success' text={<span style={{ fontSize: '12px' }}>现货已上架</span>} />
              : <Badge status='error' text={<span style={{ fontSize: '12px' }}>现货未上架</span>} />
          }
        </div>
      )
    },
    {
      title: '期货',
      key: 'modelLibrary-10',
      colSpan: 0,
      render: (text, record, index) => (
        <div>
          {
            record.futuresStatus === 'ENABLE'
              ? <Badge status='success' text={<span style={{ fontSize: '12px' }}>期货已上架</span>} />
              : <Badge status='error' text={<span style={{ fontSize: '12px' }}>期货未上架</span>} />
          }
        </div>
      )
    },
    {
      title: '添加状态',
      key: 'modelLibrary-8',
      width: 100,
      render: (text, record, index) => (
        <>
          {
            record.addStatus
              ? <div>已添加到产品库</div>
              : <div>未添加到产品库</div>
          }

        </>
      )
    },
    {
      title: '操作',
      key: 'modelLibrary-9',
      width: 120,
      fixed: 'right',
      render: (text, record, index) => (
        <div>
          {
            +record.taskId === 0
              ? <Button type='primary' size='small' onClick={() => this.handleShowOperateModal([record.commoditySkuId], 1)}>
                设置
              </Button>
              : <Row>{record.taskDesc}</Row>
          }
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getBrandList({ brandListType: 'ALL_BRAND' })
    this.getModelLibraryListInProductModule()
  }

  // 获取品牌
  getBrandList = (params) => {
    Api.getBrandList(params).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        let { searchData } = this.state

        data.map(item => {
          item['showLabel'] = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
        })

        searchData[1].options = $lodash.concat([{ brandId: 0, showLabel: '全部' }], data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取型号库列表
  getModelLibraryListInProductModule = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getModelLibraryListInProductModule(params).then(res => {
      const { code, data = {} } = res

      if (+code === 10000) {
        this.setState({
          tableData: data.pageBean.list || [],
          pages: {
            ...pages,
            total: data.pageBean.total
          },
          batchSearchResult: {
            failNumber: +data.failNumber,
            totalNumber: +data.totalNumber,
            successNumber: +data.successNumber,
            failList: data.failMessageList || []
          },
          searchFailModal: +data.failNumber > 0
        })
      }
    })
  }

  // 设置上架&添加到产品库
  setProductPutOnShelves = (params) => {
    Api.setProductPutOnShelves(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        setTimeout(() => {
          this.props.dispatch(setHandleModal({ handleModal: false }))
          this.closeModal()

          Modal.success({
            content: <div>
              <div>操作成功</div>
              <p>已成功操作{data}个型号</p>
            </div>,
            onOk: () => {
              this.getModelLibraryListInProductModule()
            }
          })
        }, 2000)
      }
    })
  }

  // 设置下架
  setProductPullOffShelves = (params) => {
    Api.setProductPullOffShelves(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        setTimeout(() => {
          this.props.dispatch(setHandleModal({ handleModal: false }))
          this.closeModal()

          Modal.success({
            content: <div>
              <div>操作成功</div>
              <p>已成功下架{data}个型号</p>
            </div>,
            onOk: () => {
              this.getModelLibraryListInProductModule()
            }
          })
        }, 2000)
      }
    })
  }

  // 删除型号
  deleteProductInProductModule = (params) => {
    Api.deleteProductInProductModule(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        setTimeout(() => {
          this.props.dispatch(setHandleModal({ handleModal: false }))
          this.closeModal()

          Modal.success({
            content: <div>
              <div>操作成功</div>
              <p>已成功删除{data}个型号</p>
            </div>,
            onOk: () => {
              this.getModelLibraryListInProductModule()
            }
          })
        }, 2000)
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.brandId && +search.brandId > 0) {
      search.brandId = [search.brandId]
    } else {
      delete search.brandId
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => {
      this.getModelLibraryListInProductModule()
    })
  }

  // 勾选
  onSelectChange = (keys, rows) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  // 点击勾选批量设置按钮
  handleShowOperateModal = (skuIds, type) => {
    this.setState({
      operateModal: true,
      rowDetail: skuIds,
      type: type
    })
  }

  // 点击确定批量操作
  handleConfirmOperate = (params, operateType) => {
    const { type = 1, query = {} } = this.state

    if (operateType === 1) {
      if (type === 1) {
        this.setProductPutOnShelves(params)
      } else {
        this.props.history.push({ pathname: '/admin/searchCondition', state: { detailInfo: params, operateType: operateType, brandId: +query.brandId } })
      }
    }

    if (operateType === 2) {
      if (type === 1) {
        this.setProductPullOffShelves(params)
      } else {
        this.props.history.push({ pathname: '/admin/searchCondition', state: { detailInfo: params, operateType: operateType, brandId: +query.brandId } })
      }
    }

    if (operateType === 3) {
      if (type === 1) {
        this.setProductPutOnShelves(params)
      } else {
        this.props.history.push({ pathname: '/admin/searchCondition', state: { detailInfo: params, operateType: operateType, brandId: +query.brandId } })
      }
    }

    if (operateType === 4) {
      if (type === 1) {
        this.deleteProductInProductModule(params)
      } else {
        this.props.history.push({ pathname: '/admin/searchCondition', state: { detailInfo: params, operateType: operateType, brandId: +query.brandId } })
      }
    }
  }

  // 点击条件批量设置按钮
  handleShowConditionModal = (type) => {
    this.setState({
      operateModal: true,
      type: type
    })
  }

  // 查看进度
  handleLookAddProgress = (key) => {
    this.setState({
      progressModal: true
    })
  }

  // 点击导出批量检索失败明细
  handleDownFailDetail = () => {
    const { batchSearchResult = {} } = this.state
    if (!batchSearchResult.failList || batchSearchResult.failList.length === 0) {
      return message.warning('暂无数据')
    }

    let _list = [
      ['品牌', '订货号', '型号', '失败原因']
    ]

    batchSearchResult.failList.forEach(item => {
      let arr = getBracketStr(item).split('-')
      let _res = getCaption(item)
      let _itemNo = $lodash.slice(arr, 3, arr.length).join('-')
      let _arr = []
      _arr.push(arr[0], arr[1], arr[2], _itemNo, _res)

      _list.push(_arr)
    })

    downloadExcel(_list, '失败明细.xlsx')
  }

  // 渲染table行背景色
  renderTableRowClassName = (record) => {
    return +record.taskId > 0 ? styles.progress : ''
  }

  closeModal = () => {
    this.setState({
      searchFailModal: false,
      progressModal: false,
      type: 1,
      selectedRowKeys: [],
      rowDetail: [],
      operateModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getModelLibraryListInProductModule()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getModelLibraryListInProductModule()
    })
  }

  render () {
    const { searchData, tableData = [], pages, selectedRowKeys = [], rowDetail = [], type = 1,
      searchFailModal = false, batchSearchResult = {}, progressModal = false, operateModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 40,
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => ({
        disabled: +record.taskId > 0
      })
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }} style={{ marginBottom: '15px' }}>
          <Row>
            <Row style={{ display: 'inline-block', width: '120px', lineHeight: '40px', verticalAlign: 'top' }}>
              <BatchSearchMenu menuConfig={['itemNumberList', 'modelList']}
                onConfirm={(values) => this.handleSearch(values)}
              />
            </Row>
            <Row style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
              <SearchForm {...this.props}
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Row>
        </Card>

        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ marginBottom: '15px' }}>
            <Button type='primary'
              disabled={selectedRowKeys.length === 0}
              onClick={() => this.handleShowOperateModal(selectedRowKeys, 1)}
            >
              <Icon type='check-square' />勾选批量设置
            </Button>

            <Button type='primary' style={{ marginLeft: '12px' }}
              onClick={() => this.handleShowConditionModal(2)}
            >
              <Icon type='file-search' />条件批量设置
            </Button>

            <Button type='primary' style={{ marginLeft: '12px' }}
              onClick={() => this.handleLookAddProgress('MODEL_LIBRARY')}
            >
              <Icon type='eye' />查看任务进度
            </Button>
          </Row>

          <Row>
            <Table rowKey='commoditySkuId'
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination}
              rowSelection={rowSelection}
              rowClassName={(record) => this.renderTableRowClassName(record)}
              scroll={{ x: 1300 }}
            />
          </Row>
        </Card>

        {
          searchFailModal
            ? <UploadModal
              type={1}
              hasDownBtn={1}
              totalNumber={batchSearchResult.totalNumber}
              successNumber={batchSearchResult.successNumber}
              failNumber={batchSearchResult.failNumber}
              list={batchSearchResult.failList}
              onCancel={() => this.closeModal()}
              onDownload={() => this.handleDownFailDetail()}
            />
            : null
        }

        {
          progressModal
            ? <ProgressModal
              taskKey='MODEL_LIBRARY'
              onConfirm={this.closeModal}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          operateModal
            ? <OperateModal
              type={type}
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params, type) => this.handleConfirmOperate(params, type)}
            />
            : null
        }

      </>
    )
  }
}

export default connect()(ModelLibrary)
