import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Row, Icon, Button } from 'antd'

import SendModal from '@/components/contractnotice-modal/index'
import Api from '@/common/api/index'

import styles from './ordersuccess.module.scss'

export default class OrderSuccess extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    orderId: 0,
    endUserId: 0,
    receivedInfo: {},
    backLinkUrl: '/admin/quickOrder',
    sendModal: false
  }

  componentDidMount () {
    const { state } = this.props.history.location

    this.setState({
      orderId: state.orderId || 0,
      endUserId: state.endUserId || 0,
      receivedInfo: state.receivedInfo || {},
      backLinkUrl: state.backLinkUrl || '/admin/quickOrder'
    })
  }

  // 生成自定义合同
  createCustomContract = (params, sendParams) => {
    Api.createCustomContract(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.sendContractNoticeSms(sendParams)
      }
    })
  }

  // 发送合同短信通知
  sendContractNoticeSms = (params) => {
    Api.sendContractNoticeSms(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.closeModal()
      }
    })
  }

  // 点击继续下单
  handleJumpToLink = () => {
    const { backLinkUrl = '/admin/quickOrder' } = this.state
    this.props.history.push({ pathname: backLinkUrl })
  }

  // 点击查看订单
  handleLinkToOrder = () => {
    this.props.history.push({ pathname: '/admin/euOrder' })
  }

  // 点击合同发送
  showSendModal = () => {
    this.setState({
      sendModal: true
    })
  }

  // 点击确定发送
  handleConfirmSend = (values) => {
    let params = {
      orderId: values.orderId,
      contractNumber: +values.isCustom === 0 ? '' : values.contractNumber,
      demanderCover: values.demanderCover
    }
    this.createCustomContract(params, { phoneNumber: values.phoneNumber, orderId: values.orderId })
  }

  closeModal = () => {
    this.setState({
      sendModal: false
    })
  }

  render () {
    const { orderId = 0, endUserId = 0, sendModal = false } = this.state
    let minHeight = document.body.clientHeight - 80

    return (
      <>
        <Card bodyStyle={{ padding: '0px', minHeight: minHeight <= 0 ? '100%' : minHeight }}>
          <Row className={`${styles.resultContainer}`}>
            <Row className={`${styles.resulticonRow}`}>
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52C41A' style={{ fontSize: '72px' }} />
            </Row>
            <Row className={`${styles.resultTitle}`}>
              下单成功
            </Row>
            <Row className={`${styles.resultSubtitle}`}>
              订单编号：{orderId}
            </Row>
            <Row className={`${styles.resultExtra}`}>
              <Button type='primary' style={{ marginRight: '20px' }} onClick={() => this.handleJumpToLink()}>继续下单</Button>

              <Button style={{ marginRight: '20px' }} onClick={() => this.handleLinkToOrder()}>查看订单</Button>
            </Row>
          </Row>
        </Card>

        {
          sendModal
            ? <SendModal
              endUserId={endUserId}
              orderId={orderId}
              onConfirm={(params) => this.handleConfirmSend(params)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}
