import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Row, Button, message, Upload, Switch, Modal } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import SetsalerModal from '../component/setsalerModal'
import PrepayModal from '../component/prepay-modal'
import AddressModal from '@/components/address-modal/index'

import { getStore, removeStore } from '@/common/utils/mUtils'
import { saveEuKeyMap } from './localdatas'

import Api from '@/common/api/index'
import UploadApi from '@/common/api/uploadApi'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'

const { beforeuploadEndUser } = UploadApi
const { downloadUploadEndUserTemplate, downloadEndUserList } = DownloadApi

const { pageSizeOptions } = filter
const customerType = [
  { type: '', label: '全部' },
  { type: '2', label: '企业用户' },
  { type: '1', label: '个人用户' }
]

const qstate = [
  { type: '', label: '全部' },
  { type: '1', label: '启用' },
  { type: '0', label: '禁用' }
]

// 获取销售
const getsalers = [
  { supplierSysUserId: '', realName: '全部' },
  { supplierSysUserId: '0', realName: '未关联' }
]

export default class CustomerList extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    allocatModal: false, // 分配销售弹窗
    prepayModal: false, // 客户期货预付款设置弹窗
    type: 1, // 1-新增 2-编辑
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    searchData: [
      {
        name: 'company',
        formType: 'Input',
        itemParams: {
          label: '公司名称',
          extra: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'realName',
        formType: 'Input',
        itemParams: {
          label: '真实名称',
          extra: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'createTime',
        formType: 'RangePicker',
        itemParams: {
          label: '注册时间'
        },
        cptParams: {

        }
      },
      {
        name: 'userType',
        formType: 'Select',
        itemParams: {
          label: '客户类型'
        },
        cptParams: {

        },
        options: customerType,
        optionValue: ['type', 'label'],
        rules: {
          initialValue: ''
        }
      },
      {
        name: 'sysSalerId',
        formType: 'Select',
        itemParams: {
          label: '关联销售'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['supplierSysUserId', 'realName']
      },
      {
        name: 'prepaymentStatus',
        formType: 'Select',
        itemParams: {
          label: '期货预付状态'
        },
        cptParams: {

        },
        options: qstate,
        optionValue: ['type', 'label'],
        rules: {
          initialValue: ''
        }
      }
    ],
    query: {},
    tableData: [],
    rowDetail: {}, // 当前选择的行数据
    salerType: 0,
    addressModal: false
  }

  columns = [
    {
      title: '序号',
      key: 'customer-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '公司名称',
      width: 240,
      key: 'customer-1',
      render: (text, record, index) => (
        <>
          {+record.enterpriseId > 0 ? record.company : '----'}
        </>
      )
    },
    {
      title: '真实姓名',
      key: 'customer-2',
      width: 100,
      render: (text, record, index) => (
        <div>
          {
            record.endUser && record.endUser.realname
              ? record.endUser.realname
              : null
          }
        </div>
      )
    },
    {
      title: '手机号码',
      key: 'customer-3',
      width: 80,
      render: (text, record, index) => (
        <div>
          {
            record.endUser && record.endUser.phoneNumber
              ? <span>{record.endUser.phoneNumber}</span>
              : null
          }
        </div>
      )
    },
    {
      title: '客户类型',
      key: 'customer-4',
      width: 80,
      render: (text, record, index) => (
        <div>
          {
            +record.enterpriseId > 0
              ? '企业用户'
              : '个人用户'
          }
        </div>
      )
    },
    {
      title: '注册时间',
      key: 'customer-5',
      width: 100,
      sorter: (a, b) => a.createTime - b.createTime,
      render: (text, record, index) => (
        <div>
          {record.createTime ? moment(record.createTime).format('YYYY-MM-DD hh:mm:ss') : '----'}
        </div>
      )
    },
    {
      title: '账户余额',
      key: 'customer-8',
      width: 120,
      dataIndex: 'totalBalance',
      render: (text, record, index) => (
        <>
          &yen;{(+record.totalBalance * 100 / 100).toFixed(2)}
        </>
      )
    },
    {
      title: '客户状态',
      key: 'customer-6',
      width: 100,
      render: (text, record, index) => (
        <div>
          <Switch
            checked={record.endUser.euStatus === 'DEFAULT_YES'}
            checkedChildren={'启用'}
            unCheckedChildren={'禁用'}
            onChange={(checked) => { this.setStatus(checked, record) }} />
        </div>
      )
    },
    {
      title: '关联销售',
      key: 'customer-9',
      width: 100,
      dataIndex: 'sellerName',
      render: (text, record, index) => (
        <div>
          {
            record && record.sellerName && record.sellerName !== ''
              ? <span>{record.sellerName}</span>
              : <>未关联</>
          }
        </div>
      )
    },
    {
      title: '期货预付比例',
      key: 'customer-10',
      width: 100,
      render: (text, record, index) => (
        <div>
          {
            record.endUser && +record.endUser.prepaymentRatio > 0 && +record.endUser.prepaymentRatio <= 1
              ? <span>{+record.endUser.prepaymentRatio * 100}%</span>
              : <span>--</span>
          }
        </div>
      )
    },

    {
      title: '期货启禁用',
      key: 'customer-12',
      width: 100,
      render: (text, record, index) => (
        <div>
          <Switch
            checked={record.endUser.prepaymentStatus === 'ENABLE'}
            checkedChildren={'启用'}
            unCheckedChildren={'禁用'}
            onChange={(checked) => { this.getonChangeSwitch(checked, record) }} />
        </div>
      )
    },
    {
      title: '操作',
      key: 'customer-11',
      width: 400,
      fixed: 'right',
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small'
            style={{ marginRight: '8px' }}
            onClick={() => this.handleJumpToEnduserDetail(record, 2)}
          >编辑</Button>

          <Button type='primary' size='small'
            style={{ marginRight: '8px' }}
            onClick={() => this.getEndUserReceivedAddressList(record, 2)}
          >查看地址</Button>

          <Button type='primary' size='small'
            style={{ marginRight: '8px' }}
            onClick={() => this.getAddEnduserDetail(record, 1)}
          >添加地址</Button>

          <Button type='primary' size='small'
            style={{ marginRight: '8px' }}
            onClick={() => this.showPrepayRadioModal(record)}>
            期货预付
          </Button>
          {
            record && record.sellerName && record.sellerName !== ''
              ? <Button type='primary' size='small'
                style={{ marginRight: '8px' }}
                onClick={() => this.handleAllocationSeller(record, 2)}
              >更换销售</Button>
              : <Button type='primary' size='small'
                style={{ marginRight: '8px' }}
                onClick={() => this.handleAllocationSeller(record, 1)}
              >分配销售</Button>
          }
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getGuestListBySeller()
    this.getAllSellers()
  }

  // 获取客户列表
  getGuestListBySeller = () => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getGuestListBySeller(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取销售员列表
  getSellerList = (params) => {
    return Api.getSellerList(params)
  }

  // 获取销售员列表
  getAllSellers = () => {
    Api.getSellerList({ pageNo: 1, pageSize: 999 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let { searchData } = this.state

        searchData[4].options = $lodash.concat(getsalers, data.list || [])
        this.setState({
          searchData
        })
      }
    })
  }

  // 分配销售员
  saveSetsaler = (params) => {
    Api.saveSetEuAllotSeller(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getGuestListBySeller()
      }
    })
  }

  // 点击添加地址
  getAddEnduserDetail = (record, type) => {
    this.setState({
      addressModal: true,
      rowDetail: record,
      type: type
    })
  }

  // 导入客户
  changeUploadEndUser = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        this.props.history.push({ pathname: '/admin/beforeuoload', state: data })
      } else if (code === 'SUPPLIER_ERROR_00001') {
        removeStore('token')
        window.location.href = 'http://' + window.location.host + '/login'
      } else {
        message.error(`${info.file.name}上传失败`)
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name}上传失败`)
    }
  }

  // 设置期货预付款比
  setPrepaymentRatio = (params) => {
    Api.setPrepaymentRatio(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getGuestListBySeller()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.createTime) {
      let _times = search.createTime
      search['beginDate'] = _times[0].format('YYYY-MM-DD')
      search['endDate'] = _times[1].format('YYYY-MM-DD')
      delete search.createTime
    }
    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => {
      this.getGuestListBySeller()
    })
  }

  // 启禁用事件
  getonChangeSwitch = (checked, record) => {
    let params = {
      endUserId: record.euId,
      prepaymentStatus: record.endUser.prepaymentStatus === 'ENABLE' ? 'DISABLE' : 'ENABLE'
    }
    Api.getonChangeSwitch(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getGuestListBySeller()
      } else {
        message.error(res.message)
      }
    })
  }
  // 跳转到详情
  handleJumpToEnduserDetail = (record, type = 1) => {
    this.props.history.push({ pathname: '/admin/enduser-detail', state: { ...record, type: type } })
  }

  // 点击分配销售
  handleAllocationSeller = (row, type) => {
    this.setState({
      rowDetail: row || {},
      salerType: type,
      allocatModal: true
    })
  }

  // 点击期货预付
  showPrepayRadioModal = (record = {}) => {
    this.setState({
      prepayModal: true,
      type: +record.endUser.prepaymentRatio <= 100 ? 2 : 1,
      rowDetail: record
    })
  }

  // 下载模板
  handleDownloadTemplate = () => {
    downloadUploadEndUserTemplate({})
  }

  // 导出客户列表
  downloadCustomer = () => {
    const { query } = this.state
    downloadEndUserList(query)
  }

  // 客户状态启用禁用弹窗
  setStatus = (check, record) => {
    let params = {
      euStatus: record.endUser.euStatus === 'DEFAULT_YES' ? 'DEFAULT_NO' : 'DEFAULT_YES',
      id: record.endUser.id
    }
    if (check === true) {
      this.getcumStatus(params)
    } else {
      Modal.confirm({
        title: '确认禁用此客户？',
        content: '此操作将禁止客户登录商城和下单',
        onOk: () => {
          this.getcumStatus(params)
        }
      })
    }
  }

  // 客户状态启用禁用api
  getcumStatus = (param) => {
    Api.getcumStatus(param).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getGuestListBySeller()
      } else {
        message.error(res.message)
      }
    })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      allocatModal: false,
      prepayModal: false,
      type: 1,
      addressModal: false,
      rowDetail: {}
    })
  }

  // 查看地址
  getEndUserReceivedAddressList = (record) => {
    this.props.history.push({ pathname: '/admin/customerAdress', state: { record } })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getGuestListBySeller()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getGuestListBySeller()
    })
  }

  // 确定保存地址
  handleConfirmSaveAddress = (values) => {
    let params = this.changeEnterParamsKey(values)
    params.category = values.status === 'ENABLE' ? 2 : 0

    this.saveAddEndUserReceiveAddress(params)
  }

  // 保存新增收件地址
  saveAddEndUserReceiveAddress = (params) => {
    Api.saveAddEndUserReceiveAddress(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
      } else {
        message.error(res.message)
      }
    })
  }

  changeEnterParamsKey = (params) => {
    const obj = {}

    $lodash.forEach(params, (value, key) => {
      obj[saveEuKeyMap[key]] = value
    })

    return obj
  }

  render () {
    const { tableData, pages, searchData, type = 1, salerType, addressModal = false,
      allocatModal = false, prepayModal = false, rowDetail } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }} style={{ marginBottom: '15px' }}>
          <SearchForm
            {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </Card>

        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ marginBottom: '15px' }}>
            <Button type='primary'
              style={{ padding: '0px', marginRight: '10px', width: '110px', height: '30px' }}
              onClick={() => this.handleJumpToEnduserDetail({}, 1)}
            >新增客户</Button>

            <Upload
              name='file'
              headers={{
                Token: getStore('token')
              }}
              accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              action={beforeuploadEndUser}
              showUploadList={false}
              onChange={this.changeUploadEndUser}
            >
              <Button type='primary' style={{ marginRight: '10px', width: '110px', height: '30px' }}>
                导入客户
              </Button>
            </Upload>

            <Button type='primary' style={{ marginRight: '10px', width: '110px', height: '30px' }}
              onClick={() => this.handleDownloadTemplate()}
            >
              下载模板
            </Button>

            <Button type='primary' style={{ marginRight: '10px', width: '110px', height: '30px' }}
              onClick={this.downloadCustomer}
            >
              导出客户
            </Button>
          </Row>

          <Row>
            <Table rowKey='euId'
              pagination={pagination}
              columns={this.columns}
              dataSource={tableData}
              scroll={{ x: 1500 }}
            />
          </Row>
        </Card>

        {
          allocatModal
            ? <SetsalerModal
              data={rowDetail}
              getSellerList={(params) => this.getSellerList(params)}
              onConfirm={(params) => this.saveSetsaler(params)}
              salerType={salerType}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          prepayModal
            ? <PrepayModal
              type={type}
              detailInfo={rowDetail}
              onConfirm={(params) => this.setPrepaymentRatio(params)}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          addressModal
            ? <AddressModal
              type={type}
              endUserId={rowDetail.endUser.id || 0}
              detailInfo={{}}
              onCancel={() => this.closeModal()}
              onConfirm={(params) => this.handleConfirmSaveAddress(params)}
            />
            : null
        }
      </>
    )
  }
}
