import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Row, Button, Upload, message, Tooltip, Icon, InputNumber, Popconfirm, Tag } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import BatchSearchMenu from '@/components/batchSearchMenu/index'
import UploadModal from '../component/upload-modal'
import HandleModal from '@/pages/price/price-setting/handle-modal'
import OccupyModal from '../component/occupy-modal'
import UploadSheetModal from '@/components/uploadSheetModal'

import Api from '@/common/api'
import UploadApi from '@/common/api/uploadApi'
import filter from '@/common/utils/filter'
import { getStore } from '@/common/utils/mUtils'

const { pageSizeOptions, importConfig } = filter
const { uploadMatchExcelUrl } = UploadApi

const totalStockSyncTypeLabels = [
  { id: 'SELF_SINGLE_SYNC', name: '单个更新' },
  { id: 'SELF_FILE_SYNC', name: '导入更新' },
  { id: 'SYSTEM_SYNC', name: '系统同步' },
  { id: 'FUTURES_STOCK_IMPORT', name: '期货导入' }
]

export default class StoreEnter extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择品牌',
          showSearch: true,
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      },
      {
        name: 'keyword',
        formType: 'Input',
        itemParams: {
          label: '型号/订货号'
        },
        cptParams: {
          placeholder: '请输入型号或订货号',
          autoComplete: 'off'
        }
      },
      {
        name: 'orderStock',
        formType: 'Select',
        itemParams: {
          label: '是否存在订单库存'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'ENABLE', name: '是' },
          { id: 'DISABLE', name: '否' }
        ]
      },
      {
        name: 'totalStockSyncType',
        formType: 'Select',
        itemParams: {
          label: '数据传输类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...totalStockSyncTypeLabels
        ]
      },
      {
        name: 'modifyTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '更新时间'
        },
        cptParams: {

        }
      }
    ],
    modaltype: 1,
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    rowDetail: {}, // 当前选择的行数据
    uploadModal: false,
    failList: [], // 导入库存失败列表
    totalNumber: 0, // 导入总数
    successNumber: 0,
    failNumber: 0,

    failModal: false,
    searchfailList: [], // 检索失败列表
    searchFailNumber: 0, // 检索失败数
    searchSuccessNumber: 0, // 检索成功数
    searchTotalNumber: 0, // 检索总数

    handleModal: false,
    occupyModal: false, // 占用库存明细弹窗
    uploadSheetModal: false,
    uploadSheetDetails: {
      name: '',
      list: []
    },
    editingKey: ''
  }

  columns = [
    {
      title: '序号',
      key: 'storeIn-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'storeIn-1',
      width: 120,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <p>{record.brandName}</p>
          <p>{ record.englishBrandName && record.englishBrandName !== '' ? record.englishBrandName : '--'}</p>
        </>
      )
    },
    {
      title: '系列',
      key: 'storeIn-2',
      width: 120,
      dataIndex: 'series',
      ellipsis: true
    },
    {
      title: '型号',
      key: 'storeIn-3',
      width: 160,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <span dangerouslySetInnerHTML={{ __html: record.model }} />
          { record.modelExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '订货号',
      key: 'storeIn-4',
      width: 150,
      ellipsis: true,
      render: (text, record, index) => (
        <>
          <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
          { !record.modelExactMatch && record.itemNumberExactMatch ? <span style={{ marginLeft: '8px' }}><Tag>完全匹配</Tag></span> : null }
        </>
      )
    },
    {
      title: '产品名称',
      key: 'storeIn-5',
      width: 180,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '总库存',
      key: 'storeIn-6',
      width: 120,
      render: (text, record, index) => {
        const editable = this.isEditing(record)
        return (
          <div>
            {
              editable
                ? <InputNumber size='small' onChange={(value) => this.onChangeTotalStock(value, index)} />
                : <span>{record.totalStock}</span>
            }
          </div>
        )
      }
    },
    {
      title: '销售库存',
      key: 'storeIn-7',
      width: 120,
      dataIndex: 'stock'
    },
    {
      title: '订单库存',
      key: 'storeIn-8',
      width: 120,
      render: (text, record, index) => (
        <div>
          {
            +record.orderStock > 0
              ? <Tooltip placement='top' title={'点击可查看订单库存明细'}>
                <a style={{ textDecoration: 'underline' }} onClick={() => this.handleShowOccupyDetailModal(record)}>
                  {+record.orderStock}
                </a>
              </Tooltip>
              : <span>{+record.orderStock}</span>
          }
        </div>
      )
    },
    {
      title: '数据传输类型',
      key: 'storeIn-9',
      dataIndex: 'totalStockSyncType',
      render: (text, record, index) => (
        <>{this.renderTotalStockSyncTypeLabel(record.totalStockSyncType)}</>
      )
    },
    {
      title: '更新时间',
      key: 'storeIn-10',
      dataIndex: 'modifyTime',
      render: (text, record, index) => (
        <>
          { record.modifyTime ? moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '操作',
      key: 'storeIn-11',
      width: 120,
      render: (text, record, index) => {
        const { editingKey } = this.state
        const editable = this.isEditing(record)

        return editable ? (
          <Row>
            <Button type='primary' size='small' onClick={() => this.handleSave(record)} style={{ marginRight: '8px' }}>保存</Button>

            <Popconfirm title='确定放弃编辑?' onConfirm={() => this.handleCancelEdit(index)}>
              <Button type='primary' size='small'>取消</Button>
            </Popconfirm>
          </Row>
        )
          : (
            <Button type='primary' size='small' disabled={editingKey !== ''} onClick={() => this.handleEdit(record.commoditySkuId)}>
              编辑库存
            </Button>
          )
      }
    }
  ]

  componentDidMount () {
    this.getBrandList({ brandListType: 'MERCHANT_BRAND' })
    this.getStoreInProdList()
  }

  // 获取品牌
  getBrandList = (params) => {
    Api.getBrandList(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        let { searchData } = this.state
        let _options = [
          { brandId: 0, showLabel: '全部' }
        ]

        if (data && data.length > 0) {
          data.map(item => {
            item.showLabel = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName
          })
        }

        searchData[0].options = _options.concat(data)

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取产品列表
  getStoreInProdList = (readDatabaseType) => {
    const { pages, query } = this.state

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    if (readDatabaseType && readDatabaseType !== '') {
      params.readDatabaseType = readDatabaseType
    }

    Api.getStoreInProdList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let _list = data.pageBean.list || []

        if (_list.length > 0) {
          _list.map(item => {
            item.copyTotalStock = item.totalStock || 0
          })
        }

        this.setState({
          tableData: _list,
          pages: {
            ...pages,
            total: data.pageBean.total || 0
          },
          failModal: +data.failNumber > 0,
          searchFailNumber: +data.failNumber,
          searchTotalNumber: +data.totalNumber,
          searchSuccessNumber: +data.successNumber,
          searchfailList: data.failMessageList || []
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 单个更新总库存
  updateTotalStockInSingle = (params) => {
    Api.updateTotalStockInSingle(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.setState({
          editingKey: ''
        }, () => this.getStoreInProdList())
      } else {
        message.error(res.message)
      }
    })
  }

  // 上传EXCEL智能解析
  saveUploadExcelMatchSmartparse = async (params) => {
    const res = await Api.saveUploadExcelMatchSmartparse(params)
    const { code, data } = res
    if (+code === 10000) {
      this.changeUploadSuccess(data)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    const { brandId = 0, modifyTimes } = search

    if (modifyTimes) {
      search['beginDate'] = modifyTimes.length === 2 ? modifyTimes[0].format('YYYY-MM-DD') : undefined
      search['endDate'] = modifyTimes.length === 2 ? modifyTimes[1].format('YYYY-MM-DD') : undefined
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: {
        ...search,
        brandId: brandId > 0 ? [brandId] : []
      }
    }, () => {
      this.getStoreInProdList()
    })
  }

  // 导入库存
  onChangeUploadFile = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ handleModal: true })

      return false
    }

    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        setTimeout(() => {
          this.setState({
            handleModal: false,
            uploadSheetDetails: {
              name: data.tableName || '',
              list: data.sheetNames || [],
              file: info.file.originFileObj
            },
            uploadSheetModal: true
          })
        }, 2000)
      } else {
        message.error(info.file.response.message || `${info.file.name}上传失败`)
        this.closeModal()
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name}上传失败`)
      this.closeModal()
    }
  }

  // 点击确定选择导入EXCEL的sheet
  handleConfirmUploadSheet = (values) => {
    const formData = new FormData()

    $lodash.forEach(values, (value, key) => {
      formData.append(key, value)
    })

    this.saveUploadExcelMatchSmartparse(formData)
  }

  // 监听上传成功
  changeUploadSuccess = (params) => {
    let config = {
      pageSourch: 'storeInSetting',
      sheetSourch: importConfig['storeInSetting'].sheetSourch
    }

    this.props.history.push({ pathname: '/admin/sheetsConfirm', state: { config: config, data: { importId: params } } })
  }

  // 点击查看失败详情
  showFailDetailModal = () => {
    this.setState({
      uploadModal: true
    })
  }

  // 点击查看订单占用明细弹窗
  handleShowOccupyDetailModal = (record) => {
    this.setState({
      rowDetail: record,
      occupyModal: true
    })
  }

  // 点击编辑库存
  handleEdit = (key) => {
    this.setState({
      editingKey: key
    })
  }

  // 监听库存输入
  onChangeTotalStock = (value, i) => {
    let { tableData } = this.state

    if (i > -1) {
      tableData[i].totalStock = value
    }

    this.setState({
      tableData: tableData
    })
  }

  // 点击保存库存输入
  handleSave = (record) => {
    this.updateTotalStockInSingle({ commoditySkuId: record.commoditySkuId, totalStock: record.totalStock })
  }

  // 取消编辑
  handleCancelEdit = (i) => {
    let { tableData } = this.state

    tableData[i].totalStock = tableData[i].copyTotalStock

    this.setState({
      tableData,
      editingKey: ''
    })
  }

  // 渲染总库存标签
  renderTotalStockSyncTypeLabel = (status) => {
    const obj = totalStockSyncTypeLabels.find(item => { return item.id === status })
    const name = obj !== undefined ? obj.name : null

    return name
  }

  // 编辑中
  isEditing = (record) => record.commoditySkuId === this.state.editingKey

  closeModal = () => {
    this.setState({
      uploadModal: false,
      uploadSheetModal: false,
      failModal: false,
      handleModal: false,
      occupyModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getStoreInProdList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getStoreInProdList()
    })
  }

  render () {
    const { searchData, tableData, pages, failList = [], uploadModal = false, failModal = false, searchfailList = [], handleModal = false,
      searchFailNumber = 0, searchSuccessNumber = 0, searchTotalNumber = 0, rowDetail = {},
      totalNumber = 0, successNumber = 0, failNumber = 0, occupyModal = false, uploadSheetModal = false, uploadSheetDetails } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <Row>
              <div style={{ display: 'inline-block', width: '120px', lineHeight: '40px', verticalAlign: 'top' }}>
                <BatchSearchMenu
                  onConfirm={(values) => this.handleSearch(values)}
                />
              </div>
              <Row style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
                <SearchForm {...this.props}
                  dataSource={searchData}
                  handleSubmit={(obj) => this.handleSearch(obj)}
                />
              </Row>
            </Row>
          </Card>
        </Row>

        <Row>
          <Card bodyStyle={{ padding: '15px' }}>
            <Row style={{ marginBottom: '10px' }}>
              <Upload name='file' showUploadList={false}
                headers={{
                  Token: getStore('token')
                }}
                accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                action={uploadMatchExcelUrl}
                onChange={this.onChangeUploadFile}
              >
                <Button type='primary'>
                  <Icon type='cloud-upload' />总库存导入
                </Button>
              </Upload>

              {
                failList.length > 0
                  ? <Button onClick={() => this.showFailDetailModal()}>查看失败详情</Button>
                  : null
              }

            </Row>

            <Row>
              <Table rowKey='commoditySkuId'
                columns={this.columns}
                dataSource={tableData}
                pagination={pagination}
                scroll={{ x: 1220 }}
              />
            </Row>
          </Card>
        </Row>

        {
          uploadModal
            ? <UploadModal
              totalNumber={totalNumber}
              successNumber={successNumber}
              failNumber={failNumber}
              list={failList}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          failModal
            ? <UploadModal
              type={1}
              totalNumber={searchTotalNumber}
              successNumber={searchSuccessNumber}
              failNumber={searchFailNumber}
              list={searchfailList}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          handleModal
            ? <HandleModal letterArr={['EXCEL', '分', '析', '中', '...']} />
            : null
        }

        {
          occupyModal
            ? <OccupyModal
              detailInfo={rowDetail}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          uploadSheetModal
            ? <UploadSheetModal
              name={uploadSheetDetails.name}
              list={uploadSheetDetails.list}
              file={uploadSheetDetails.file}
              onConfirm={(values) => this.handleConfirmUploadSheet(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}
