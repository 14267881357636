import React, { Component } from 'react'
import { Card, Table, message, Button } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderInvoiceOpenedStatusLabel, renderInvoiceTypeLabel } from '@/common/utils/mUtils'

import SearchForm from '@/components/tool-form/index'
import InvoiceModal from './invoiceModal/index'
import LogisticsModal from '@/components/logisticsModal/index'

const { pageSizeOptions, invoiceTypeMaps, invoiceOpenedStatusMaps } = filter

export default class EUInvoiceList extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'invoiceNo',
        formType: 'Input',
        itemParams: {
          label: '开票申请编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'endUserId',
        formType: 'Select',
        itemParams: {
          label: '客户名称',
          help: ''
        },
        cptParams: {
          showSearch: true,
          placeholder: '请输入...',
          autoComplete: 'off',
          optionFilterProp: 'children'
        },
        options: [],
        optionValue: ['endUserId', 'showName']
      },
      {
        name: 'invoiceType',
        formType: 'Select',
        itemParams: {
          label: '发票类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...invoiceTypeMaps
        ]
      },
      {
        name: 'applyTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '申请日期'
        },
        cptParams: {

        }
      },
      {
        name: 'invoiceRecordStatus',
        formType: 'Select',
        itemParams: {
          label: '开票状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...invoiceOpenedStatusMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    query: {},
    invoiceModal: false,
    rowDetail: {},
    openStatus: 'OPENING'
  }

  columns = [
    {
      title: '序号',
      key: 'invoiceList-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '开票申请编号',
      key: 'invoiceList-1',
      dataIndex: 'invoiceNo'
    },
    {
      title: '客户名称',
      key: 'invoiceList-2',
      dataIndex: 'customerName'
    },
    {
      title: '发票金额',
      key: 'invoiceList-3',
      dataIndex: 'amount',
      render: (text, record, index) => (
        <>&yen;{(+record.amount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '发票类型',
      key: 'invoiceList-4',
      dataIndex: 'amount',
      render: (text, record, index) => (
        <>{ renderInvoiceTypeLabel(record.invoiceType) }</>
      )
    },
    {
      title: '申请时间',
      key: 'invoiceList-5',
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----'}
        </>
      )
    },
    {
      title: '开票状态',
      key: 'invoiceList-6',
      render: (text, record, index) => (
        <>{ renderInvoiceOpenedStatusLabel(record.status) }</>
      )
    },
    {
      title: '操作',
      key: 'invoiceList-7',
      width: 200,
      render: (text, record, index) => (
        <>
          {
            record.status === 'OPENED'
              ? <>
                <Button type='primary' size='small' onClick={() => this.showInvoiceModal(record)}>重新开票</Button>

                <Button type='primary' size='small' style={{ marginLeft: '6px' }} onClick={() => this.showLogisticsModal(record)}>查看物流</Button>
              </>
              : <>
                <Button type='primary' size='small' onClick={() => this.showInvoiceModal(record)}>查看详情</Button>
              </>
          }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getGuesteuUserLike()
    this.getEUOrderInvoiceList()
  }

  // 查询客户信息
  getGuesteuUserLike = (val = '') => {
    let params = {
      pageNo: 1,
      pageSize: 999,
      searchName: val
    }

    Api.getGuesteuUserLike(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const { searchData } = this.state
        searchData[1].options = data.list || []

        this.setState({
          searchData
        })
      }
    })
  }

  // 获取开票申请列表
  getEUOrderInvoiceList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getEUOrderInvoiceList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 确认开具发票
  submitInvoiceOpend = async (params) => {
    const res = await Api.submitInvoiceOpend(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getEUOrderInvoiceList()
    }
  }

  // 确认重新开票
  submitEUOrderInvoiceRepeatOpend = async (params) => {
    const res = await Api.submitEUOrderInvoiceRepeatOpend(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getEUOrderInvoiceList()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.applyTimes) {
      search.beginDate = search.applyTimes.length > 0 ? search.applyTimes[0].format('YYYY-MM-DD') : undefined
      search.endDate = search.applyTimes.length > 0 ? search.applyTimes[1].format('YYYY-MM-DD') : undefined

      delete search.applyTimes
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getEUOrderInvoiceList()
    })
  }

  // 点击确定开票
  handleConfirmInvoiceOpen = (params, openStatus) => {
    if (openStatus === 'OPENING') {
      this.submitInvoiceOpend(params)
    }

    if (openStatus === 'OPENED') {
      this.submitEUOrderInvoiceRepeatOpend(params)
    }
  }

  // 点击查看开票详情
  showInvoiceModal = (record) => {
    this.setState({
      rowDetail: record || {},
      openStatus: record.openStatus,
      invoiceModal: true
    })
  }

  // 点击物流跟踪
  showLogisticsModal = (record = {}) => {
    this.setState({
      rowDetail: { courierCode: record.courierCode, courierNumber: record.courierNumber, mobile: record.phoneNumber },
      logisticsModal: true
    })
  }

  closeModal = () => {
    this.setState({
      invoiceModal: false,
      logisticsModal: false,
      openStatus: 'OPENING',
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEUOrderInvoiceList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEUOrderInvoiceList()
    })
  }

  render () {
    const { searchData, pages, tableData = [], invoiceModal = false, rowDetail = {}, openStatus = 'OPENING', logisticsModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <SearchForm
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '15px' }}>
          <Table rowKey='id'
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>

        {
          invoiceModal
            ? <InvoiceModal
              invoiceType={rowDetail.invoiceType}
              openStatus={openStatus}
              ids={[rowDetail.id]}
              onConfirm={(params, status) => this.handleConfirmInvoiceOpen(params, status)}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          logisticsModal
            ? <LogisticsModal
              courierNumberList={[rowDetail]}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}
