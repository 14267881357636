import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Radio, InputNumber } from 'antd'

import filter from '@/common/utils/filter'

const { shipmentUtilMap } = filter

class OperationsForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    options: PropTypes.array,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {}

  // 点击确定
  handleConfirm = () => {
    const { form, onConfirm } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        onConfirm(values)
      }
    })
  }

  render () {
    const { form, options = [], onCancel } = this.props
    const { getFieldDecorator, getFieldValue } = form

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 }
    }

    return (
      <>
        <Modal title='导入产品设置' width={600}
          visible
          maskClosable={false}
          onCancel={() => onCancel()}
          onOk={() => this.handleConfirm()}
        >
          <Form>
            <Form.Item label='选择操作' {...formItemLayout}>
              {
                getFieldDecorator('uploadSetType', {
                  rules: [
                    { required: true, message: '请选择操作' }
                  ]
                })(
                  <Radio.Group>
                    {
                      options.map((record) => (
                        <Radio value={record.id} key={record.id}>{ record.name }</Radio>
                      ))
                    }
                  </Radio.Group>
                )
              }
            </Form.Item>

            {
              getFieldValue('uploadSetType') === 'PRODUCT_FUTURE_PUT_ON'
                ? <>
                  <Form.Item label='期货交货日期' {...formItemLayout}>
                    {
                      getFieldDecorator('updateDeliveryPeriodDays', {
                        rules: [
                          { required: true, message: '请填写到货货期' }
                        ]
                      })(
                        <InputNumber min={1} max={999} style={{ width: '120px' }} />
                      )
                    }

                    <span style={{ display: 'inline-block', marginLeft: '6px' }}>{ shipmentUtilMap['FUTURES']}</span>
                  </Form.Item>
                  <Form.Item label='期货预付款比例' {...formItemLayout}>
                    {
                      getFieldDecorator('updatePrepaymentRatio', {
                        rules: [
                          { required: true, message: '请填写预付款比例' }
                        ]
                      })(
                        <InputNumber min={0} max={100} style={{ width: '120px' }} />
                      )
                    }

                    <span style={{ display: 'inline-block', marginLeft: '6px' }}>%</span>
                  </Form.Item>
                </>
                : null
            }
          </Form>
        </Modal>
      </>
    )
  }
}

const OperationsModal = Form.create()(OperationsForm)

export default OperationsModal
