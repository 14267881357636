import { downloadFileByGet, downloadFileByPost } from '../utils/downloadFile'

export default {
  // 下载-下载通用模板
  downloadCommonTemplate (params) {
    return downloadFileByGet('/api/excel/template/download/common', params)
  },

  // 客户列表导入模板下载
  downloadUploadEndUserTemplate (params) {
    return downloadFileByGet('/api/excel/download/endUserImportTemplate', params)
  },

  // 导出客户列表
  downloadEndUserList (params) {
    return downloadFileByPost('/api/excel/export/euUserInfo', params)
  },

  // 导出失败详情
  downloadfailList (params) {
    return downloadFileByPost('/api/guest/supplier/endUser/export', params)
  },

  // 开票下载
  downloadInvoiceTpl (params) {
    return downloadFileByGet('/api/excel/export/euOpenInvoiceInfo', params)
  },

  // 电子发票表格导出
  downloadEUElectronicInvoiceExcel (params) {
    return downloadFileByGet('/api/excel/export/euOpenElectronicInvoiceInfo', params)
  },

  // 云供应开票管理 导出开票产品
  downloadProductsBySupplyInvoice (params) {
    return downloadFileByPost('/api/platformOrderInvoice/exportInvoiceInfo', params)
  },

  // 云供应开票管理 导出电子发票开票产品表格
  downloadProductsExcelBySupplyInvoiceElectronic (params) {
    return downloadFileByPost('/api/platformOrderInvoice/invoiceElectronicInfoExport', params)
  },

  // EU总订单导出
  downOrderUrl (params) {
    return downloadFileByPost('/api/excel/data/download/euOrderInfo', params)
  },
  // 现货订单导出
  downOrderUrlStore (params) {
    return downloadFileByPost('/api/excel/data/download/euSpotOrderInfo', params)
  },
  // 期货订单导出
  downOrderUrlFuture (params) {
    return downloadFileByPost('/api/excel/data/download/euFuturesOrderInfo', params)
  },
  // 云采购订单导出
  downOrderUrlcloudPurchase (params) {
    return downloadFileByPost('/api/excel/data/download/cloudPurchaseOrderInfo', params)
  },
  // 云供应订单导出
  downOrderUrlcloudSupply (params) {
    return downloadFileByPost('/api/excel/data/download/cloudSupplyOrderInfo', params)
  },

  // 下载报价单模板
  downQuoteTemplate (params) {
    return downloadFileByGet('/api/quotation/template/download', params)
  },
  // 导出报价单
  downQuote (params) {
    return downloadFileByGet('/api/excel/export/euQuotation', params)
  },

  // 下载最低价格导入模板
  downDefaultpriceTpl (params) {
    return downloadFileByGet('/api/excel/download/priceDefaultSkuImportTemplate', params)
  },

  // 预览合同模板
  previewContractTemp (params) {
    return downloadFileByGet('/api/contract/manager/preview', params)
  },

  // 手工订单-下载导入模板
  downManualTemp (params) {
    return downloadFileByGet('/api/excel/download/handOrderTemplate', params)
  },

  // 商户产品导入匹配结果导出
  downMatchResult (params) {
    return downloadFileByGet('/api/excel/export/product/import/result', params)
  },

  // 导出保险月度账单
  downinsuranceBillByMonth (params) {
    return downloadFileByGet('/api/excel/export/logistics/insure/flow', params)
  },

  // 导出IIASaaS的德邦快递服务月度账单
  downIIASaaSDepponExpressBillByMonth (params) {
    return downloadFileByGet('/api/excel/data/download/logistics/deppon/fee', params)
  },

  // 云库产品列表批量搜索-搜索结果导出明细
  downCloudLibraryBatchSearchResult (params) {
    return downloadFileByPost('/api/excel/data/download/matchCloudProductSearch', params)
  },

  // 云供应- 现货云供应设置- 下载模板
  downloadCloudSupplyPriceTemplate (params) {
    return downloadFileByGet('/api/excel/download/justSupplyTemplate', params)
  },

  // 云供应-期货云供应设置- 下载期货导入模板
  downloadCloudSupplyFuturesTemplate (params) {
    return downloadFileByGet('/api/excel/download/futures/justSupplyTemplate', params)
  },

  // 购物车- 生成导出报价单
  downloadAddToCartSkuQuoteExcel (params) {
    return downloadFileByPost('/api/just/inner/purchase/shopping/cart/quotation/export', params)
  },

  // 快递记录导出
  downloadExpressServiceRecords (params) {
    return downloadFileByPost('/api/warehouse/get/distributorPickingInfo/export', params)
  }
}
