import React, { Component } from 'react'
import { Tabs, Row } from 'antd'

import BalanceList from './balance-list' // 客户余额信用
import CashoutList from '../cashout-list/index' // 客户提现申请
import SaledRefund from '../order-refund/index' // 客户售后退款

import styles from './index.module.scss'

const { TabPane } = Tabs
const menuMaps = [
  { title: '客户余额信用', key: 'EUBALANCE' },
  { title: '客户提现申请', key: 'EUCASHOUT' },
  { title: '客户售后退款', key: 'EUREFUND' }
]

export default class Eubanlance extends Component {
  static propTypes = {

  }
  state = {
    curTabkey: 'EUBALANCE'
  }

  componentDidMount () {

  }

  // 点击切换tab
  handleToggleTabmenu = (key) => {
    this.setState({
      curTabkey: key
    })
  }

  render () {
    const { curTabkey = 'EUBALANCE' } = this.state

    return (
      <>
        <Row className={`${styles.tabsLand}`}>
          <Tabs onChange={this.handleToggleTabmenu}>
            {
              menuMaps.map((menu, n) => (
                <TabPane tab={menu.title} key={menu.key} />
              ))
            }
          </Tabs>
        </Row>

        {
          curTabkey === 'EUBALANCE'
            ? <BalanceList />
            : null
        }

        {
          curTabkey === 'EUCASHOUT'
            ? <CashoutList />
            : null
        }

        {
          curTabkey === 'EUREFUND'
            ? <SaledRefund />
            : null
        }

      </>
    )
  }
}
