import React from 'react'
import PropTypes from 'prop-types'
import $lodash from 'lodash'

import styles from './index.module.scss'

export default function GoodsTotalItem (props) {
  const { commoditySkuId, skuGoodsType, list = [] } = props

  return (
    <>
      <ul className={`${styles.goodsPriceWrap}`}>
        {
          list.map((item, i) => (
            <li className={`${styles.goodsItemContent}`} key={`GOODSTOTALITEM-${commoditySkuId}-${skuGoodsType}-${i}`}>
              {
                item.checked
                  ? <>&yen;{ $lodash.round(+item.quantity * item.price * 100 / 100, 2) }</>
                  : null
              }
            </li>
          ))
        }
      </ul>
    </>
  )
}

GoodsTotalItem.propTypes = {
  commoditySkuId: PropTypes.number,
  skuGoodsType: PropTypes.string,
  list: PropTypes.array
}
