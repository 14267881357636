import Ajax from '../utils/ajax'

export default {
  // 云采购设置列表
  getCloudPurchaseList (params) {
    return Ajax.post('/api/cloud/procurement/listPage', params)
  },

  // 获取云采购产品默认利润
  getDefaultProfitSetting (params) {
    return Ajax.get('/api/cloud/procurement/profit/default/query', { params })
  },

  // 云采购产品利润设置
  saveCloudPurchaseProductProfit (params) {
    return Ajax.post('/api/cloud/procurement/manage/profit/setting', params)
  },

  // 云库产品列表
  getCloudLibraryProductList (params) {
    return Ajax.post('/api/cloud/procurement/keyWordSearch', params)
  },

  // 云库产品列表-批量搜索
  getCloudLibraryBatchSearchResult (params) {
    return Ajax.post('/api/cloud/procurement/matchCloudProductSearch', params)
  },

  // 云采购上架
  saveCloudPurchasePutOnShelves (params) {
    return Ajax.post('/api/cloud/procurement/manage/putOnShelves', params)
  },

  // 云采购下架
  saveCloudPurchasePutOffShelves (params) {
    return Ajax.post('/api/cloud/procurement/manage/pullOffShelves', params)
  },

  // 云采购- 批量上下架
  updateBatchCloudPurchaseStatus (params) {
    return Ajax.post('/api/cloud/procurement/manage/batch/putOnOrOffShelves', params)
  },

  // 云采购订单列表-财务
  getCloudPurchOrderList (params) {
    return Ajax.post('/api/cloud/procurementOrder/pageQuery', params)
  },

  // 云采购订单列表-财务(子列表)
  getCloudPurchOrdersunList (params) {
    return Ajax.post('/api/warehouse/list/purchase/order/picking/batch/info', params)
  },

  // 获取eu云采购订单明细
  getEuCloudPurchOrderDetail (params) {
    return Ajax.post('/api/order/list/page/cloud/order/details', params)
  },

  // 获取云采购订单详情
  getCloudPurchOrderDetail (params) {
    return Ajax.post('/api/cloud/procurementOrder/detailQuery', params)
  },

  // 查询是否有合同生成
  getHasCloudPurchContract (params) {
    return Ajax.get('/api/merchant/contract', { params })
  },

  // 自定义合同
  submitCustractNumber (params) {
    return Ajax.post('/api/merchant/contract/create', params)
  },

  // 获取云产品详情信息
  getCloudProductDetail (params) {
    return Ajax.get('/api/cloud/procurement/skuPage', { params })
  },

  // 云采购利润设置进度查询
  getCloudPurchaseProfitProgress (params) {
    return Ajax.get('/api/cloud/procurement/profit/batchNoPage', { params })
  },

  // 获取云采购分类列表
  getCloudCategoryList (params) {
    return Ajax.get('/api/cloud/procurement/listCloudClassification', { params })
  },

  // 获取云采购品牌列表
  getCloudBrandList (params) {
    return Ajax.get('/api/cloud/procurement/listCloudBrand', { params })
  },

  // 获取云库产品列表统计信息
  getCloudLibraryStatisticsInfo (params) {
    return Ajax.get('/api/cloud/procurement/getStatisticsInfo', { params })
  }
}
