import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Descriptions } from 'antd'

import filter from '@/common/utils/filter'
import { renderAddressLabel } from '@/common/utils/mUtils'

import styles from './index.module.scss'

const { authorMap } = filter

export default class MerchantBase extends Component {
  static propTypes = {
    getDetail: PropTypes.func
  }
  state = {
    info: {}
  }

  componentDidMount () {
    this.getMerchantBaseInfo()
  }

  // 获取商户基本信息
  getMerchantBaseInfo = () => {
    this.props.getDetail({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data || {}
        })
      }
    })
  }

  renderMerchantAuthor = (type) => {
    const obj = authorMap.find(item => { return +item.type === +type })
    const label = obj !== undefined ? obj.label : ''

    return label
  }

  render () {
    const { info = {} } = this.state

    return (
      <div>
        <Descriptions bordered>
          <Descriptions.Item label='公司名称（发票抬头）'>
            {info.merchantName}
          </Descriptions.Item>

          <Descriptions.Item label='统一社会信用代码'>
            {info.merchantCreditNo || ''}
          </Descriptions.Item>

          <Descriptions.Item label='注册地址'>
            {info.address}
          </Descriptions.Item>

          <Descriptions.Item label='所在地区'>
            { info.province ? renderAddressLabel(info.province, info.city, info.county) : '' }
          </Descriptions.Item>

          <Descriptions.Item label='开票电话'>
            {info.mobile}
          </Descriptions.Item>

          <Descriptions.Item label='营业执照电子版'>
            <div className={styles.thumbWrap}>
              <div className={styles.thumbInfo}>
                <a style={{ display: 'block' }} href={info.businessLicenseUrl} target='_blank' rel='noopener noreferrer'>
                  <img src={info.businessLicenseUrl} />
                </a>
              </div>
            </div>
          </Descriptions.Item>

          <Descriptions.Item label='开户银行'>
            {info.accountBank}
          </Descriptions.Item>

          <Descriptions.Item label='银行联行号'>
            {info.bankNo}
          </Descriptions.Item>

          <Descriptions.Item label='银行账号'>
            {info.account}
          </Descriptions.Item>

          <Descriptions.Item label='商户域名'>
            {info.domainAddress}
          </Descriptions.Item>

          <Descriptions.Item label='域名备案号'>
            {info.recordNum}
          </Descriptions.Item>

          <Descriptions.Item label='商户权限'>
            {this.renderMerchantAuthor(info.author)}
          </Descriptions.Item>

          <Descriptions.Item label='商户记账宝账号'>
            { info.bankLinkageNo }
          </Descriptions.Item>

          <Descriptions.Item label='商户管理员'>
            { info.adminUser }
          </Descriptions.Item>

          <Descriptions.Item label='管理员联系方式'>
            { info.adminMobile }
          </Descriptions.Item>
        </Descriptions>
      </div>
    )
  }
}
