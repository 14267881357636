import React, { Component } from 'react'
import { Card, Table, message } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions, iiaNoticeObjectMaps } = filter

export default class SysnotifysSended extends Component {
  static propTypes = {}
  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }
  columns = [
    {
      title: '序号',
      key: 'sysnotifysSended-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '通知标题',
      key: 'sysnotifysSended-1',
      dataIndex: 'title'
    },
    {
      title: '通知内容',
      key: 'sysnotifysSended-2',
      dataIndex: 'content'
    },
    {
      title: '发送时间',
      key: 'sysnotifysSended-3',
      render: (text, record, index) => (
        <div>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : null }</div>
      )
    },
    {
      title: '通知对象',
      key: 'sysnotifysSended-4',
      render: (text, record, index) => (
        <div>
          { this.renderIIASystemNotifyObjectLabel(record) }
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getSendedNotifysList()
  }

  // 获取发送的系统消息
  getSendedNotifysList = () => {
    const { pages } = this.state
    let params = {
      msgType: 'SYSTEM_MSG',
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getSendedNotifysList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      } else {
        message.error(res.message)
      }
    })
  }

  // 渲染通知对象
  renderIIASystemNotifyObjectLabel = (record) => {
    const obj = iiaNoticeObjectMaps.find(item => { return item.id === record.notifyObject })
    const label = obj !== undefined ? obj.name : null

    return label
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getSendedNotifysList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getSendedNotifysList()
    })
  }

  render () {
    const { pages, tableData } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }}>
          <Table rowKey='id'
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>
      </>
    )
  }
}
