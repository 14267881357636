import React, { Component } from 'react'
import { Empty } from 'antd'

export default class InvoiceExpressEmpty extends Component {
  render () {
    return (
      <div>
        <Empty description={<>开票中，请耐心等待...<br />有问题请拨打19532535667</>} />
      </div>
    )
  }
}
