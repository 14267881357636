import filter from './filter'
import CourierJson from '@/assets/json/courier.json'
import AREA from '@/assets/json/area'
import $lodash from 'lodash'

const { logisticsWayMaps, skuGoodsTypeMaps, orderStatusMaps, orderPaymentStatusMaps, orderDeliveryStatusMaps, orderArriverStatusMaps, orderTypeMaps, pickingOrderSendStatusMaps, notifyTypeMaps,
  invoiceTypeMaps, invoiceOpenedStatusMaps, saledTypeMaps, saledStatusMaps, selfSaledStatusMaps, saledReasonTypeMaps, saledRefundTypeMaps, saledBackGoodsTypeMaps, cloudStoreLevelMaps,
  answerEnquiredStatusMaps } = filter
/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.localStorage.setItem(name, content)
}

/**
 * 获取localStorage
 */
export const getStore = (name) => {
  if (!name) return
  return window.localStorage.getItem(name)
}

/**
 * 删除localStorage
 */
export const removeStore = (name) => {
  if (!name) return
  window.localStorage.removeItem(name)
}

/**
 * 获取url上的参数
 */
export const getUrlParams = () => {
  const url = location.search // 获取url中"?"符后的字串
  let theRequest = {}
  if (url.indexOf('?') !== -1) {
    let str = url.substr(1)
    let strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
    }
  }
  return theRequest
}

let needLoadingRequestCount = 0

function startLoading () {
  let loading = document.getElementById('loadingWrapper')
  loading.style.display = 'block'
}

function endLoading () {
  let loading = document.getElementById('loadingWrapper')
  loading.style.display = 'none'
}

/**
 * 显示全屏loading
 */
export const showFullLoading = () => {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}

/**
 * 隐藏全屏loading
 */
export const hideFullLoading = () => {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}

/**
 * 省市区数据改为多维数组结构
 */
export const addressToTree = () => {
  const newdatas = []

  $lodash.forEach(AREA.provinceList, (provinceValue, provinceKey) => {
    const obj = { code: provinceKey, name: provinceValue }
    const newCityArr = []

    $lodash.forEach(AREA.cityList, (cityValue, cityKey) => {
      if (provinceKey.substring(0, 2) === cityKey.substring(0, 2)) {
        const cityObj = { code: cityKey, name: cityValue }
        const newAreaArr = []

        $lodash.forEach(AREA.countyList, (areaValue, areaKey) => {
          if (cityKey.substring(0, 4) === areaKey.substring(0, 4)) {
            const areaObj = { code: areaKey, name: areaValue }

            newAreaArr.push(areaObj)
            cityObj.childList = newAreaArr
          }
        })

        newCityArr.push(cityObj)
        obj.childList = newCityArr
      }
    })

    newdatas.push(obj)
  })

  return newdatas
}

/**
 * 根据城市code码 获取城市名称
 */
export const getCityName = (provinceCode, cityCode, countyCode) => {
  return [AREA.provinceList[provinceCode] || '', AREA.cityList[cityCode] || '', AREA.countyList[countyCode] || '']
}

// 渲染地址中文
export const renderAddressLabel = (provinceCode, cityCode, countyCode, address = '') => {
  let str = '----'

  if (+provinceCode > 0) {
    const names = getCityName(provinceCode, cityCode, countyCode)

    str = address && address !== '' ? `${names[0]} ${names[1]} ${names[2]} ${address}` : `${names[0]} ${names[1]} ${names[2]}`
  }

  return str
}

// 渲染快递服务方式
export const renderLogisticsWayLabel = (status) => {
  const obj = logisticsWayMaps.find(item => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染订单状态
export const renderOrderStatusLabel = (status) => {
  const obj = orderStatusMaps.find(item => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染订单付款状态
export const renderOrderPaymentStatusLabel = (status) => {
  const obj = orderPaymentStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染订单发货状态
export const renderOrderDeliveryStatusLabel = (status) => {
  const obj = orderDeliveryStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染订单订货状态
export const renderOrderArriverStatusLabel = (status) => {
  const obj = orderArriverStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染拣货单发货状态
export const renderPickingOrderSendStatusLabel = (status) => {
  const obj = pickingOrderSendStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

// 渲染订单类型
export const renderOrderTypeLabel = (status) => {
  const obj = orderTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 根据物流公司code 获取物流公司名称
*/
export const getCourierName = (code = '') => {
  const obj = CourierJson.find(item => { return item.courierCode === code })
  const name = obj !== undefined ? obj.courierName : '----'

  return name
}

/**
 * 渲染产品类型
 * @param { status, key: 'name' || 'aliasName' }
 * @returns str
 */
export const renderSkuGoodsTypeLabel = (status, key = 'name') => {
  const obj = skuGoodsTypeMaps.find((o) => { return o.id === status })
  const label = obj !== undefined ? obj[key] : null

  return label
}

// 渲染消息类型
export const renderNotifyTypeLabel = (status) => {
  const obj = notifyTypeMaps.find(item => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 取出中括号内的内容
 */
export const getBracketStr = (text) => {
  let result = ''

  let regex = /\[(.+?)\]/g
  let options = text.match(regex)
  let option = options[0]
  result = option.substring(1, option.length - 1)
  return result
}

/**
 * 获取指定字符后面的字符
 */
export const getCaption = (text) => {
  let index = text.lastIndexOf(']')
  text = text.substring(index + 1, text.length)
  return text
}

/**
 * 银行卡号每4位 空格分隔
 */
export const formatBankAccount = (value) => {
  return value.replace(/\s/g, '').replace(/(.{4})/g, '$1 ')
}

/**
 * 渲染发票类型
 */
export const renderInvoiceTypeLabel = (status) => {
  const obj = invoiceTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染开票状态
 */
export const renderInvoiceOpenedStatusLabel = (status) => {
  const obj = invoiceOpenedStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染售后类型
 */
export const renderSaledTypeLabel = (status) => {
  const obj = saledTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染售后状态
 */
export const renderSaledStatusLabel = (status) => {
  const obj = saledStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染自营售后状态
 */
export const renderSelfSaledStatusLabel = (status) => {
  const obj = selfSaledStatusMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染售后原因
 */
export const renderSaledReasonTypeLabel = (status) => {
  const obj = saledReasonTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染售后退款方式
 */
export const renderSaledRefundTypeLabel = (status) => {
  const obj = saledRefundTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染售后退货方式
 */
export const renderSaledBackGoodsTypeLabel = (status) => {
  const obj = saledBackGoodsTypeMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染云仓等级
 */
export const renderCloudStoreLevelLabel = (status) => {
  const obj = cloudStoreLevelMaps.find((item) => { return item.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}

/**
 * 渲染询价单-询价状态
 */
export const renderAnswerStatusLabel = (status) => {
  const obj = answerEnquiredStatusMaps.find((o) => { return o.id === status })
  const label = obj !== undefined ? obj.name : null

  return label
}
