import React, { Component } from 'react'
import { Alert, message, Row, Switch, Modal } from 'antd'

import Api from '@/common/api/index'

const { confirm } = Modal

export default class RealpriceSet extends Component {
  static propTypes = {}
  state = {
    status: 'DISABLE',
    lowestPrice: 'DISABLE'
  }

  componentDidMount () {
    this.getShowRealPriceSeted()
    this.getLowestPriceDisabeldStatus()
  }

  // 获取是否展示真实价格配置信息
  getShowRealPriceSeted = () => {
    Api.getShowRealPriceSeted({ configType: 'SHOW_PRICE' }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          status: data || 'DISABLE'
        })
      }
    })
  }

  // 获取最低价格启用/禁用状态
  getLowestPriceDisabeldStatus = () => {
    Api.getLowestPriceDisabeldStatus({ configType: 'LOWEST_PRICE' }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          lowestPrice: data || 'DISABLE'
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 保存真实价格设置
  saveShowRealPriceSetting = (params) => {
    Api.saveShowRealPriceSetting(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功！')
        this.getShowRealPriceSeted()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 保存最低价格启用/禁用设置
  saveLowestPriceDisabledStatus = (params) => {
    Api.saveLowestPriceDisabledStatus(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.getLowestPriceDisabeldStatus()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 点击切换按钮
  handleChangeSwitch = (checked) => {
    confirm({
      title: `确定${checked ? '开启真实价格展示' : '禁止真实价格展示'}`,
      content: '该操作将会影响商城上面产品的真实价格展示',
      onOk: () => {
        this.saveShowRealPriceSetting({ status: checked ? 'ENABLE' : 'DISABLE', configType: 'SHOW_PRICE' })
      }
    })
  }

  // 切换最低折扣
  handleChangeLowestPrice = (checked) => {
    confirm({
      title: `确定${checked ? '启用最低折扣' : '禁用最低折扣'}`,
      content: '该操作将会影响产品价格体系是否启用最低折扣',
      onOk: () => {
        this.saveLowestPriceDisabledStatus({ status: checked ? 'ENABLE' : 'DISABLE', configType: 'LOWEST_PRICE' })
      }
    })
  }

  render () {
    const { status = 'DISABLE', lowestPrice = 'DISABLE' } = this.state

    return (
      <div>
        <Row style={{ width: '700px', marginBottom: '20px' }}>
          <Row style={{ marginBottom: '16px' }}>
            <Switch
              checked={status === 'ENABLE'}
              checkedChildren={'是'}
              unCheckedChildren={'否'}
              onClick={(checked) => this.handleChangeSwitch(checked)}
            />
            <span style={{ verticalAlign: 'middle', marginLeft: '16px' }}>是否展示真实价格</span>
          </Row>
          <Row>
            <Alert type='warning' showIcon
              message={'该按钮设置真实的产品价格是否在商城中展示， 请慎重！'}
            />
          </Row>
        </Row>

        <Row style={{ width: '700px' }}>
          <Row style={{ marginBottom: '16px' }}>
            <Switch checked={lowestPrice === 'ENABLE'}
              checkedChildren={'是'}
              unCheckedChildren={'否'}
              onClick={(checked) => this.handleChangeLowestPrice(checked)}
            />
            <span style={{ verticalAlign: 'middle', marginLeft: '16px' }}>是否启用最低折扣</span>
          </Row>
          <Row>
            <Alert type='warning' showIcon
              message={'该按钮设置产品价格体系是否启用最低折扣， 请慎重！'}
            />
          </Row>
        </Row>
      </div>
    )
  }
}
