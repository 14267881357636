import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

export default class Remarks extends Component {
  static propTypes = {
    title: PropTypes.string,
    remarks: PropTypes.array
  }
  render () {
    const { title = '备注', remarks = [] } = this.props

    return (
      <>
        {
          remarks.map((item, i) => (
            <div key={`remarksRow-${i}`} style={{ marginBottom: '10px' }}>
              <label className={`${styles.label}`}>{title}</label>
              <span>{item.remarks}</span>
            </div>
          ))
        }
      </>
    )
  }
}
