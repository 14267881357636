import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Col, Form, Input, Button, Upload, Icon, message } from 'antd'

import UploadApi from '@/common/api/uploadApi'
import { getStore } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

import IntelImage from '@/assets/images/intel_upload_image.png'
import styles from './index.module.scss'

const FormItem = Form.Item
const { TextArea } = Input
const { Dragger } = Upload
const { uploadIntelDiscern } = UploadApi
const { IMG_LT4M } = consts

class DiscernForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
  }
  state = {}

  componentDidMount () { }

  // 点击复制到文本框
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onConfirm(values.intelResult)
      }
    })
  }

  // 图片校验
  beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT4M
    if (!isLt1M) {
      message.warning(`图片大小不可超过${IMG_LT4M}MB！`)
    }

    return isLt1M
  }

  // 监听图片上传
  handleChangeUpload = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response

      if (+code === 10000) {
        if (data && data.length > 0) {
          setTimeout(() => {
            this.props.form.setFieldsValue({
              intelResult: data.join('\n')
            })
          }, 30)
        } else {
          Modal.warning({
            title: `Sorry~~暂无结果`
          })
        }
      } else {
        message.error('上传失败')
      }
    }
  }

  render () {
    const { getFieldDecorator } = this.props.form

    return (
      <>
        <Modal width={980} closable={false}
          visible
          maskClosable={false}
          footer={null}
        >
          <Row gutter={24}>
            <Col span={10}>
              <h3 className={`${styles.title} ${styles.hidden}`}>上传</h3>
              <Row className={`${styles.uploadLand}`}>
                <Dragger name='file' showUploadList={false}
                  accept={'image/png, image/jpg, image/jpeg, image/bmp'}
                  headers={{ Token: getStore('token') }}
                  action={uploadIntelDiscern}
                  beforeUpload={this.beforeUpload}
                  onChange={(info) => this.handleChangeUpload(info)}
                >
                  <Row className={`${styles.draggerContent}`}>
                    <Row style={{ flex: '1' }}>
                      <Row className={`${styles.imgBox}`}>
                        <img className={`${styles.pic}`} src={IntelImage} />
                      </Row>
                      <Row style={{ marginBottom: '12px' }}>
                        <Button><Icon type='plus' style={{ fontSize: '14px' }} />点击上传</Button>
                      </Row>
                      <p>支持PNG、JPG、BMP格式图片，可将图片拖拽至此</p>
                      <p className={`${styles.tipText}`}>(仅支持4M以内图片，超过将无法识别)</p>
                    </Row>
                  </Row>
                </Dragger>
              </Row>
            </Col>
            <Col span={14}>
              <h3 className={`${styles.title}`}>识别结果</h3>
              <Form>
                <FormItem style={{ marginBottom: '10px' }}>
                  {
                    getFieldDecorator('intelResult', {

                    })(
                      <TextArea style={{ height: '300px' }} />
                    )
                  }
                </FormItem>
                <FormItem>
                  <Row className={`${styles.operateBtns}`}>
                    <Button className={`${styles.operateBtn}`} onClick={() => this.props.onCancel()}>取消</Button>
                    <Button type='primary' className={`${styles.operateBtn}`}
                      onClick={() => this.handleConfirm()}
                    >复制到文本框</Button>
                  </Row>
                </FormItem>
              </Form>
            </Col>
          </Row>
        </Modal>
      </>
    )
  }
}

const DiscernModal = Form.create()(DiscernForm)

export default DiscernModal
