import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Card, Row, Form, Modal, Button, message } from 'antd'
import $lodash from 'lodash'
import moment from 'moment'
import { getLodop } from '@/assets/plugin/LodopFuncs'

import SalesbillCard from './salesbillCard' // 单据信息
import SendserverCard from './sendserverCard' // 快递服务信息
import SendProductTable from './sendProductTable/index' // 发货产品信息
import FreightCalculator from './freightCalculator/index' // 运费推荐
import SendProductPrintHtm from '../component/sendProductPrintHtm/index' // 发货拣货单htm
import PrintLogistics from '../component/print-logistics'
import PrinterModal from '@/components/printer-modal/index'

import Api from '@/common/api/index'
import { renderAddressLabel } from '@/common/utils/mUtils'

const { confirm, warning } = Modal

class SendForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    history: PropTypes.object
  }
  state = {
    sendSuccess: false,
    rowInfo: {},
    info: {}, // 单据信息
    sendAddressList: [], // 发货地址列表
    tableData: [], // 产品列表
    ewaybillInfo: {}, // 物流面单信息
    insureConfig: {}, // 保险服务配置信息
    defaultLogisticsPayType: '', // 默认快递付费类型
    courierCode: '', // SAAS服务-快递公司编码
    courierNumberList: [], // SAAS服务-运单号集合
    printerModal: false, // 打印机弹窗
    printerList: [], // 打印机列表
    payAmount: 0 // 当前货物总金额
  }

  componentDidMount () {
    const { location } = this.props.history

    if (location.state) {
      this.setState({
        rowInfo: location.state || {}
      }, () => {
        this.getSendAddressList()
        this.getEuOrderReceivingAddress()
        this.getPinganInsureConfig()
        this.getFreightPayType()

        if (this.state.rowInfo.type && this.state.rowInfo.type === 'redispatched') {
          this.getRedispatchedSendOrderInfo()
        } else {
          this.getSendOrderInfo()
        }
      })
    }
  }

  componentWillUnmount () {

  }

  // 获取发货地址列表
  getSendAddressList = () => {
    Api.getSendAddressList({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        if (data && data.length > 0) {
          data.map(item => {
            item.addressStr = renderAddressLabel(item.province, item.city, item.county, item.address)
          })
        }

        this.setState({
          sendAddressList: data || []
        })
      }
    })
  }

  // 获取重新发货出库产品信息详细
  getRedispatchedSendOrderInfo = () => {
    const { rowInfo = {} } = this.state
    const { location } = this.props.history
    let params = {
      pickingId: rowInfo.pickingId,
      batchNum: rowInfo.batchNum,
      pageNo: 1,
      pageSize: 999
    }

    axios.all([Api.getSendOrderInfo(params), Api.getStoreOutPickingDetail(params)]).then(axios.spread((res, qres) => {
      if (+res.code === 10000 && +qres.code === 10000 && res.data && qres.data && qres.data.list) {
        res.data.map((item) => {
          let obj = qres.data.list.find((o) => { return o.commoditySkuId === item.commoditySkuId })
          item['sendNumber'] = location.state.future === true
            ? obj !== undefined ? obj.batchQuantity : item.arrivalQuantity - item.deliveryQuantity
            : obj !== undefined ? obj.batchQuantity : item.quantity - item.deliveryQuantity
        })

        this.setState({
          tableData: res.data || []
        })
      }
    }))
  }

  // 获取出库信息详细
  getSendOrderInfo = () => {
    const { rowInfo = {} } = this.state
    const { location } = this.props.history
    let params = {
      pickingId: rowInfo.pickingId,
      pageNo: 1,
      pageSize: 999
    }

    Api.getSendOrderInfo(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        if (data && data.length > 0) {
          data.map(item => {
            item['sendNumber'] = location.state.future === true
              ? item.arrivalQuantity - item.deliveryQuantity
              : item.quantity - item.deliveryQuantity
          })
        }

        this.setState({
          tableData: data || []
        })
      }
    })
  }

  // 获取EU订单收货信息
  getEuOrderReceivingAddress = () => {
    const { rowInfo = {} } = this.state
    Api.getEuOrderReceivingAddress({ pickingId: rowInfo.pickingId }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          info: data || {}
        })
      }
    })
  }

  // 获取平安保险服务配置信息
  getPinganInsureConfig = () => {
    Api.getPinganInsureConfig({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          insureConfig: data || {}
        })
      }
    })
  }

  // 获取快递付费类型
  getFreightPayType = () => {
    const { rowInfo = {} } = this.state
    Api.getFreightPayType({ pickingId: rowInfo.pickingId }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          defaultLogisticsPayType: data
        })
      }
    })
  }

  // 获取物流面单信息
  getEWaybillInfo = (params) => {
    Api.getEWaybillInfo(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          ewaybillInfo: data || {}
        }, () => {
          this.lodopPrintEWaybill()
        })
      }
    })
  }

  // 云产品&SaaS服务发货
  saveSaasServerSend = (params) => {
    Api.saveSend(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          courierCode: data.courierCode,
          courierNumberList: data.courierNumberList,
          sendSuccess: true
        }, () => {
          const { info } = this.state
          this.getEWaybillInfo({ courierCode: data.courierCode, mailNoList: data.courierNumberList, isSupplyOrder: info.orderType === 'ORDER_CLOUD_STOCK' })
        })
      }
    })
  }

  // 自营服务发货
  saveSelfServerSend = (params) => {
    Api.saveSend(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.setState({
          sendSuccess: true
        }, () => {
          this.countDown()
        })
      }
    })
  }

  // 重新发货
  saveRedispatchedSend = async (params, type) => {
    const res = await Api.saveRedispatchedSend(params)
    const { code, data } = res
    if (+code === 10000) {
      if (type === 'SAAS') {
        this.setState({
          courierCode: data.courierCode,
          courierNumberList: data.courierNumberList,
          sendSuccess: true
        }, () => {
          const { info } = this.state
          this.getEWaybillInfo({ courierCode: data.courierCode, mailNoList: data.courierNumberList, isSupplyOrder: info.orderType === 'ORDER_CLOUD_STOCK' })
        })
      } else {
        this.setState({
          sendSuccess: true
        }, () => {
          this.countDown()
        })
      }
    }
  }

  // 监听发货数量输入
  handleChangeSendingNumber = (params) => {
    const { location } = this.props.history
    if (location.state.future === true) {
      if (params.value > params.arrivalQuantity - params.deliveryQuantity || params.value > params.quantity) {
        message.warning(`当前最多只能发${params.arrivalQuantity - params.deliveryQuantity}`)
        return false
      } else {
        let { tableData = [] } = this.state
        tableData[params.index].sendNumber = params.value
        this.setState({
          tableData
        })
      }
    }
    let { tableData = [] } = this.state
    tableData[params.index].sendNumber = params.value
    this.setState({
      tableData
    })
  }

  // 计算保险金额
  calcPinganInsurePay = () => {
    const { insureConfig = {}, tableData = [] } = this.state
    let pay = 0
    let totalAmount = $lodash.sumBy(tableData, (record) => { return +record.sendNumber * (+record.sellingPrice * 100) })

    if (+insureConfig.rate > 0) {
      pay = totalAmount * (+insureConfig.rate)
    }

    return `${(pay / 100).toFixed(2)}元`
  }

  // 计算发货总金额
  calcSendProductsPayAmount = () => {
    const { tableData = [] } = this.state
    let total = $lodash.sumBy(tableData, (record) => { return +record.sendNumber * (+record.sellingPrice * 100) / 100 })

    return total
  }

  /* 点击确认发货
   * 1. 判断产品类型 云产品直接发货&打印
   * 2. 如果不是云产品 判断服务类型 SAAS服务直接发货&打印
   *    自营服务判断是否有快递单号
  */
  handleSend = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { rowInfo = {}, sendAddressList = [], tableData = [] } = this.state
        let { logisticsWayEnum, pickUpTimes } = values
        let serverTypes = logisticsWayEnum.split('-')

        values['pickingId'] = rowInfo.pickingId
        values['confirmDeliverGoodsSkuQuantityList'] = this.setConfirmDeliverGoodsSkuQuantityList(tableData)
        values['logisticsWayEnum'] = serverTypes[0]
        values['goodsAmount'] = $lodash.sumBy(tableData, (record) => { return +record.sendNumber * 100 * +record.sellingPrice / 100 })
        values.sendStartTime = pickUpTimes && pickUpTimes.length === 2 ? moment(pickUpTimes[0]).format('YYYY-MM-DD HH:mm:ss') : undefined
        values.sendEndTime = pickUpTimes && pickUpTimes.length === 2 ? moment(pickUpTimes[1]).format('YYYY-MM-DD HH:mm:ss') : undefined

        if (serverTypes[0] === 'SAAS') {
          if (sendAddressList.length === 0) {
            warning({
              title: '暂无发货地址',
              content: <>请在仓库 - 发货地址 管理您的发货地址</>
            })

            return false
          }

          confirm({
            title: '请确认发货地址',
            content: (<>{this.findSendAddressLabel(values.addressId)}</>),
            onOk: () => {
              values['courierCode'] = serverTypes[1]

              if (rowInfo.type && rowInfo.type === 'redispatched') {
                values['batchNum'] = rowInfo.batchNum
                this.saveRedispatchedSend(values, 'SAAS')
              } else {
                this.saveSaasServerSend(values)
              }
            }
          })
        } else {
          values['isPinganInsure'] = false
          if (rowInfo.type && rowInfo.type === 'redispatched') {
            values['batchNum'] = rowInfo.batchNum
            this.saveRedispatchedSend(values, 'SELF')
          } else {
            this.saveSelfServerSend(values)
          }
        }
      }
    })
  }

  // 点击打印拣货单
  handlePrintSalesBill = () => {
    const { form } = this.props
    const { tableData = [], info } = this.state

    if (tableData.length === 0) {
      return message.warning('暂无打印信息')
    }

    let remarks = form.getFieldValue('remarks')

    this.setState({
      info: {
        ...info,
        remarks: remarks
      }
    }, () => {
      this.lodopPrintSalesBill()
    })
  }

  // 打印拣货单
  lodopPrintSalesBill = () => {
    let htmlTemplateObj = document.getElementById('salesTable').innerHTML
    let LODOP = getLodop()
    if (!LODOP) {
      return message.error('请下载打印插件')
    }

    LODOP.PRINT_INIT('初始化打印') // 打印初始化
    LODOP.ADD_PRINT_HTM('8mm', '3mm', '195mm', '290mm', htmlTemplateObj)
    LODOP.PREVIEW()
  }

  // 校验是否可打印 &选择打印机
  lodopPrintEWaybill = () => {
    let { courierNumberList = [], printerList = [] } = this.state
    let LODOP = getLodop()

    if (!LODOP) {
      return message.error('请下载打印插件')
    }

    if (courierNumberList.length === 0) {
      return message.warning('暂无运单号')
    }

    let printerCount = LODOP.GET_PRINTER_COUNT() || 0

    if (printerCount === 0) {
      return message.warning('暂无打印机，请确认是否已连接打印机')
    }

    for (let i = 0; i < printerCount; i++) {
      printerList.push(LODOP.GET_PRINTER_NAME(i))
    }

    this.setState({
      printerList,
      printerModal: true
    })
  }

  // 选择打印机确认
  onConfirmPriter = (name) => {
    this.setState({
      printerModal: false,
      printerList: []
    }, () => {
      this.printEWayBill(name)
    })
  }

  // 打印物流面单
  printEWayBill = (name) => {
    let ewayHtml = document.getElementById('expressPrintArea').innerHTML
    let LODOP = getLodop()
    const { courierNumberList = [] } = this.state

    for (let i = 0; i < courierNumberList.length; i++) {
      LODOP.PRINT_INIT('初始化打印') // 打印初始化
      LODOP.SET_PRINTER_INDEXA(name)
      LODOP.SET_PRINT_PAGESIZE(0, '75mm', '130mm', 'CreateCustomPage')
      LODOP.SET_SHOW_MODE('BKIMG_WIDTH', '75mm')
      LODOP.SET_SHOW_MODE('BKIMG_HEIGHT', '130mm')
      LODOP.SET_PRINT_MODE('CUSTOM_TASK_NAME', 'EWaybill' + i)

      LODOP.ADD_PRINT_TABLE(0, 0, '75mm', '130mm', ewayHtml)
      LODOP.ADD_PRINT_BARCODE('61mm', '12mm', '50mm', '18mm', '128B', courierNumberList[i])

      LODOP.PRINT()
    }
  }

  // 点击关闭
  handleBack = () => {
    const { location } = this.props.history
    if (location && location.state && location.state.future === true) {
      this.props.history.push('/admin/FuturesOut')
    } else {
      this.props.history.push('/admin/storeOut')
    }
  }

  findSendAddressLabel = (key) => {
    const { sendAddressList = [] } = this.state
    let str = ''

    if (sendAddressList.length > 0) {
      let obj = sendAddressList.find(item => { return item.id === key })

      if (obj) {
        str = obj.addressStr
      }
    }

    return str
  }

  // 设置保存发货产品数据格式
  setConfirmDeliverGoodsSkuQuantityList = (tableData) => {
    let arr = []

    tableData.map((item) => {
      if (item.sendNumber && +item.sendNumber > 0) {
        arr.push({
          commoditySkuId: item.commoditySkuId,
          quantity: item.sendNumber
        })
      }
    })

    return arr
  }

  // 倒计时定时器
  countDown = () => {
    let secondsToGo = 5
    const modal = Modal.success({
      title: '操作成功',
      content: `页面将在${secondsToGo}s后自动返回...`,
      onOk: () => {
        clearInterval(timer)
        clearTimeout(outTimer)
        modal.destroy()

        this.goBack()
      }

    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        content: `页面将在${secondsToGo}s后自动返回...`
      })
    }, 1000)

    const outTimer = setTimeout(() => {
      if (secondsToGo === 0) {
        this.goBack()
      }
      clearInterval(timer)
      modal.destroy()
    }, secondsToGo * 1000)
  }

  closeModal = () => {
    this.setState({
      printerModal: false
    })
  }

  // 返回列表
  goBack = () => {
    this.props.history.goBack()
  }

  render () {
    const { history, form } = this.props
    const { location } = history

    const { rowInfo = {}, tableData = [], info = {}, insureConfig = {}, defaultLogisticsPayType = '', sendSuccess = false, sendAddressList = [],
      ewaybillInfo = {}, printerModal = false, printerList = [] } = this.state

    return (
      <>
        <Row style={{ marginBottom: '15px' }}>
          <SalesbillCard form={form}
            info={info}
            sendAddressList={sendAddressList}
            onSuccess={() => this.getEuOrderReceivingAddress()}
          />
        </Row>

        <Row style={{ marginBottom: '15px' }}>
          <Card title='发货明细' bodyStyle={{ padding: '15px' }}>
            <SendProductTable
              form={form}
              remarks={info.remarks || ''}
              isRedispatch={rowInfo.type && rowInfo.type === 'redispatched'}
              tableData={tableData}
              future={location && location.state && location.state.future ? location.state.future : false}
              onChangeSendingNumber={(params) => this.handleChangeSendingNumber(params)}
            />
          </Card>
        </Row>

        <Row style={{ marginBottom: '15px' }}>
          <FreightCalculator
            info={info}
            sendAddressId={form.getFieldValue('addressId')}
            addressList={sendAddressList}
          />
        </Row>

        {/* 快递服务 */}
        <Row style={{ marginBottom: '15px' }}>
          <SendserverCard {...this.props}
            info={info}
            insureConfig={insureConfig}
            defaultLogisticsPayType={defaultLogisticsPayType}
            insurePay={this.calcPinganInsurePay()}
            productAmount={this.calcSendProductsPayAmount()}
          />
        </Row>

        <Row style={{ textAlign: 'center' }}>
          <Button type='primary' onClick={() => this.handlePrintSalesBill()}>打印拣货单</Button>

          {
            !sendSuccess && (rowInfo.type === 'redispatched' || info.pickingStatus === 'AWAIT_SEND_OUT' || info.pickingStatus === 'PART_DELIVER_GOODS')
              ? <Button type='primary' style={{ marginLeft: '30px' }} onClick={() => this.handleSend()}>确认发货</Button>
              : null
          }

          <Button style={{ marginLeft: '30px' }} onClick={() => this.handleBack()}>关闭</Button>
        </Row>

        {/* 拣货单 */}
        <SendProductPrintHtm
          orderInfo={info}
          orderDetail={tableData}
        />

        {
          printerModal && printerList.length > 0
            ? <PrinterModal
              list={printerList}
              onConfirm={(name) => this.onConfirmPriter(name)}
              onCancel={this.closeModal}
            />
            : null
        }

        <div>
          <PrintLogistics
            ewaybillInfo={ewaybillInfo}
            orderInfo={info}
          />
        </div>
      </>
    )
  }
}

const StoreSend = Form.create()(SendForm)

export default StoreSend
