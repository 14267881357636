import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { setShowLoading, setUnnoticeCount, setNoticeDetail, setUserInfo } from '@/reducers/action'
import { Layout, Modal, Icon, message, notification } from 'antd'
import { routerMap } from '@/routes/router.js'

import Api from '@/common/api/index'
import { createSocket, oncloseWS, Socket } from '@/common/utils/websocket.js'
import { getStore, renderNotifyTypeLabel } from '@/common/utils/mUtils'
import config from '@/common/utils/config'

import HeaderBar from '../header-bar/index'
import SideMenu from '../side-menu/index'
// import HandleModal from '../handleing/index' // 处理中loading
import PageLoading from '../pageLoading/index'
import TaskIcon from '@/assets/images/icon/icon_task.png'
import NoticeIcon from '@/assets/images/icon/icon_notice.png'
import AnnounceIcon from '@/assets/images/icon/icon_announce.png'
import IIASaaSIcon from '@/assets/images/icon/icon_iiasaas.png'
import AdvertImage from '@/assets/images/advert/advert_2021630.png'

import styles from './main.module.scss'

const { Sider, Content } = Layout
const { socketWs } = config
const noticeTypeMaps = [
  { id: 'SYSTEM_MSG', name: '系统通知', icon: NoticeIcon },
  { id: 'SYSTEM_NOTIFY', name: '活动通知', icon: NoticeIcon },
  { id: 'SYSTEM_ANNOUNCEMENT', name: '系统公告', icon: AnnounceIcon },
  { id: 'TASK_DISTRIBUTE', name: '任务分发', icon: TaskIcon },
  { id: 'IIASAAS_NOTITF', name: 'IIASaaS公告', icon: IIASaaSIcon }
]

class Main extends Component {
  static propTypes = {
    history: PropTypes.object,
    showLoading: PropTypes.object,
    // handleModal: PropTypes.bool
    dispatch: PropTypes.func
  }

  state = {
    userInfo: {},
    menus: [],
    advertModal: false
  }

  componentDidMount () {
    this.props.dispatch(setShowLoading({ showLoading: {} }))
    axios.all([Api.getLoginUserInfo({}), Api.getMenus({})]).then(axios.spread((userRes, menusRes) => {
      if (+userRes.code === 10000 && +menusRes.code === 10000) {
        this.getUnReadNotifyCount()

        this.props.dispatch(setUserInfo({ userInfo: userRes.data || {} }))

        this.setState({
          userInfo: userRes.data || {},
          menus: menusRes.data || []
        }, () => {
          this.initWebsocket()
          // this.showAdvertModal()
        })
      } else {
        message.error('服务器开小差了，请稍后再试！')
      }
    }))

    // 注册监听事件
    window.addEventListener('onmessageWS', (e) => {
      this.getNotificationDetail(e)
    })
  }

  componentWillUnmount () {
    // 关闭socket链接
    Socket && oncloseWS()
    // 卸载监听事件
    window.removeEventListener('onmessageWS', () => {})
  }

  // 获取未读消息总数
  getUnReadNotifyCount = () => {
    Api.getUnReadNotifyCount({}, { HideLoading: true, NoError: true }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.props.dispatch(setUnnoticeCount({ unnoticeCount: +data || 0 }))
      }
    })
  }

  // 获取推送的消息
  getNotificationDetail = (e) => {
    const data = e && e.detail.data ? JSON.parse(e.detail.data) : {}

    if (data && data.msgId) {
      notification.open({
        message: <div>{ renderNotifyTypeLabel(data.msgType)}</div>,
        description: <div className={`${styles.noticeDescription}`}>{data.msgTitle}</div>,
        icon: <span className={`${styles.noticeIcon}`}>
          <img src={this.renderNotificationIcon(data.msgType)} />
        </span>,
        onClose: () => {
          this.props.dispatch(setUnnoticeCount({ unnoticeCount: +data.msgUnreadTotal }))
        },
        onClick: () => {
          let _record = {
            id: data.msgId,
            title: data.msgTitle,
            content: data.msgContent,
            msgType: data.msgType,
            createTime: data.msgCreateTime
          }
          this.props.dispatch(setNoticeDetail({ noticeDetail: _record }))
          this.props.history.push({ pathname: '/admin/notifyDetail' })
        }
      })
    }
  }

  // 初始化websocket
  initWebsocket = () => {
    let _token = getStore('token')
    let _domain = document.domain
    let url = `${socketWs}/token=${_token}&channel=supp&domain=${_domain}`

    createSocket(url)
  }

  // 弹窗广告
  showAdvertModal = () => {
    setTimeout(() => {
      this.setState({
        advertModal: true
      })
    }, 1500)
  }

  // 关闭广告弹窗
  closeAdvertModal = () => {
    this.setState({
      advertModal: false
    })
  }

  // 获取用户信息
  getUserInfo = () => {
    Api.getLoginUserInfo({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          userInfo: data || {}
        })
      }
    })
  }

  // 获取菜单信息
  getMenus = () => {
    Api.getMenus({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          menus: data || []
        })
      }
    })
  }

  // 渲染消息icon
  renderNotificationIcon = (status) => {
    let _url = ''
    let obj = noticeTypeMaps.find(item => {
      return item.id === status
    })

    if (obj) {
      _url = obj.icon
    }

    return _url
  }

  render () {
    const { showLoading = {} } = this.props
    const { menus = [], userInfo = {}, advertModal = false } = this.state

    return (
      <>
        <Layout style={{ minHeight: '100%' }}>
          <div style={{ width: '200px', overflow: 'hidden', flex: '0px 0px 200px', minWidth: '200px', maxWidth: '200px', minHeight: '100vh' }} />
          <Sider width='200' className={`${styles.leftSider}`}>
            {
              menus.length > 0
                ? <SideMenu data={menus} userInfo={userInfo} />
                : null
            }
          </Sider>

          <Layout style={{ position: 'relative' }}>
            <HeaderBar history={this.props.history} />

            <Content className={`${styles.singlePage}`}>
              <Switch>
                {routerMap.map((route, i) => (
                  <Route
                    name={route.name}
                    exact
                    key={i}
                    path={route.path}
                    render={props => (
                      <route.component {...props} />
                    )}
                  />
                ))}
              </Switch>
            </Content>

          </Layout>
        </Layout>

        <Modal width={800} centered bodyStyle={{ padding: '0px' }}
          closeIcon={<span style={{ color: 'rgba(255, 255, 255, 0.75)', position: 'absolute', top: '-40px', right: '-34px' }}>
            <Icon type='close-circle' style={{ fontSize: '28px' }} /></span>
          }
          visible={advertModal}
          onCancel={this.closeAdvertModal}
          footer={null}
        >
          <img src={AdvertImage} width={800} style={{ display: 'block', width: '800px' }} />
        </Modal>

        {
          showLoading.loading ? <PageLoading tip={showLoading.tip} /> : null
        }
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    showLoading: state.showLoading
    // handleModal: state.handleModal
  }
}

export default connect(mapStateToProps)(Main)
