import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Button, Badge } from 'antd'

import DetailModal from './detailModal'
import SearchForm from '@/components/tool-form/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { pageSizeOptions } = filter

export default class OutsideOrder extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    searchData: [
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号',
          extra: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'orderNo',
        formType: 'Input',
        itemParams: {
          label: 'IIASaaS订单',
          extra: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'result',
        formType: 'Select',
        itemParams: {
          label: 'IIASaaS状态',
          extra: ''
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off'
        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'SUCCESS', name: '成功' },
          { id: 'FAIL', name: '失败' }
        ]
      },
      {
        name: 'createName',
        formType: 'Input',
        itemParams: {
          label: '操作人',
          extra: ''
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    rowDetail: {},
    detailModal: false
  }
  columns = [
    {
      title: '序号',
      key: 'outsideorder-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '订单编号',
      key: 'outsideorder-1',
      dataIndex: 'orderNo'
    },
    {
      title: 'IIASaas订单',
      key: 'outsideorder-2',
      render: (text, record, index) => (
        <div>
          {
            +record.orderId && +record.orderId > 0
              ? <div>{record.orderId}</div>
              : <div>--</div>
          }
        </div>
      )
    },
    {
      title: 'IIASaaS订单状态',
      key: 'outsideorder-3',
      render: (text, record, index) => (
        <div>
          {
            record.result === 'SUCCESS'
              ? <div><Badge status='success' text='成功' /></div>
              : <div><Badge status='error' text='失败' /></div>
          }
        </div>
      )
    },
    {
      title: '备注',
      key: 'outsideorder-4',
      dataIndex: 'failReason'
    },
    {
      title: '操作人',
      key: 'outsideorder-5',
      dataIndex: 'createName'
    },
    {
      title: '操作',
      key: 'outsideorder-5',
      render: (text, record, index) => (
        <div>
          <Button size='small' type='primary'
            onClick={() => this.showDetailModal(record)}
          >
            查看详细
          </Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getOutsideOrderList()
  }

  // 获取外部订单列表
  getOutsideOrderList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getOutsideOrderList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => {
      this.getOutsideOrderList()
    })
  }

  // 点击查看明细
  showDetailModal = (record) => {
    this.setState({
      rowDetail: record,
      detailModal: true
    })
  }

  // 点击跳转到手工订单
  handleLinkToManual = (params) => {
    this.closeModal()

    this.props.history.push({ pathname: '/admin/manualOrder', state: { ...params, type: 'OUTSIDE' } })
  }

  closeModal = () => {
    this.setState({
      rowDetail: {},
      detailModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getOutsideOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getOutsideOrderList()
    })
  }

  render () {
    const { searchData, pages, tableData, rowDetail = {}, detailModal = false } = this.state
    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }} style={{ marginBottom: '15px' }}>
          <SearchForm
            {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </Card>

        <Card bodyStyle={{ padding: '15px' }}>
          <Table rowKey='id'
            dataSource={tableData}
            columns={this.columns}
            pagination={pagination}
          />
        </Card>

        {
          detailModal
            ? <DetailModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              onConfirm={(params) => this.handleLinkToManual(params)}
            />
            : null
        }
      </>
    )
  }
}
