const data = {
  '110100': {
    'name': '北京市',
    'telAreaCode': '010'
  },
  '120100': {
    'name': '天津市',
    'telAreaCode': '022'
  },
  '130100': {
    'name': '石家庄市',
    'telAreaCode': '0311'
  },
  '130200': {
    'name': '唐山市',
    'telAreaCode': '0315'
  },
  '130300': {
    'name': '秦皇岛市',
    'telAreaCode': '0335'
  },
  '130400': {
    'name': '邯郸市',
    'telAreaCode': '0310'
  },
  '130500': {
    'name': '邢台市',
    'telAreaCode': '0319'
  },
  '130600': {
    'name': '保定市',
    'telAreaCode': '0312'
  },
  '130700': {
    'name': '张家口市',
    'telAreaCode': '0313'
  },
  '130800': {
    'name': '承德市',
    'telAreaCode': '0314'
  },
  '130900': {
    'name': '沧州市',
    'telAreaCode': '0317'
  },
  '131000': {
    'name': '廊坊市',
    'telAreaCode': '0316'
  },
  '131100': {
    'name': '衡水市',
    'telAreaCode': '0318'
  },
  '140100': {
    'name': '太原市',
    'telAreaCode': '0351'
  },
  '140200': {
    'name': '大同市',
    'telAreaCode': '0352'
  },
  '140300': {
    'name': '阳泉市',
    'telAreaCode': '0353'
  },
  '140400': {
    'name': '长治市',
    'telAreaCode': '0355'
  },
  '140500': {
    'name': '晋城市',
    'telAreaCode': '0356'
  },
  '140600': {
    'name': '朔州市',
    'telAreaCode': '0349'
  },
  '140700': {
    'name': '晋中市',
    'telAreaCode': '0354'
  },
  '140800': {
    'name': '运城市',
    'telAreaCode': '0359'
  },
  '140900': {
    'name': '忻州市',
    'telAreaCode': '0350'
  },
  '141000': {
    'name': '临汾市',
    'telAreaCode': '0357'
  },
  '141100': {
    'name': '吕梁市',
    'telAreaCode': '0358'
  },
  '150100': {
    'name': '呼和浩特市',
    'telAreaCode': '0471'
  },
  '150200': {
    'name': '包头市',
    'telAreaCode': '0472'
  },
  '150300': {
    'name': '乌海市',
    'telAreaCode': '0473'
  },
  '150400': {
    'name': '赤峰市',
    'telAreaCode': '0476'
  },
  '150500': {
    'name': '通辽市',
    'telAreaCode': '0475'
  },
  '150600': {
    'name': '鄂尔多斯市',
    'telAreaCode': '0477'
  },
  '150700': {
    'name': '呼伦贝尔市',
    'telAreaCode': '0470'
  },
  '150800': {
    'name': '巴彦淖尔市',
    'telAreaCode': '0478'
  },
  '150900': {
    'name': '乌兰察布市',
    'telAreaCode': '0474'
  },
  '152200': {
    'name': '兴安盟',
    'telAreaCode': '0482'
  },
  '152500': {
    'name': '锡林郭勒盟',
    'telAreaCode': '0479'
  },
  '152900': {
    'name': '阿拉善盟',
    'telAreaCode': '0483'
  },
  '210100': {
    'name': '沈阳市',
    'telAreaCode': '024'
  },
  '210200': {
    'name': '大连市',
    'telAreaCode': '0411'
  },
  '210300': {
    'name': '鞍山市',
    'telAreaCode': '0412'
  },
  '210400': {
    'name': '抚顺市',
    'telAreaCode': '024'
  },
  '210500': {
    'name': '本溪市',
    'telAreaCode': '0414'
  },
  '210600': {
    'name': '丹东市',
    'telAreaCode': '0415'
  },
  '210700': {
    'name': '锦州市',
    'telAreaCode': '0416'
  },
  '210800': {
    'name': '营口市',
    'telAreaCode': '0417'
  },
  '210900': {
    'name': '阜新市',
    'telAreaCode': '0418'
  },
  '211000': {
    'name': '辽阳市',
    'telAreaCode': '0419'
  },
  '211100': {
    'name': '盘锦市',
    'telAreaCode': '0427'
  },
  '211200': {
    'name': '铁岭市',
    'telAreaCode': '024'
  },
  '211300': {
    'name': '朝阳市',
    'telAreaCode': '0421'
  },
  '211400': {
    'name': '葫芦岛市',
    'telAreaCode': '0429'
  },
  '220100': {
    'name': '长春市',
    'telAreaCode': '0431'
  },
  '220200': {
    'name': '吉林市',
    'telAreaCode': '0432'
  },
  '220300': {
    'name': '四平市',
    'telAreaCode': '0434'
  },
  '220400': {
    'name': '辽源市',
    'telAreaCode': '0437'
  },
  '220500': {
    'name': '通化市',
    'telAreaCode': '0435'
  },
  '220600': {
    'name': '白山市',
    'telAreaCode': '0439'
  },
  '220700': {
    'name': '松原市',
    'telAreaCode': '0438'
  },
  '220800': {
    'name': '白城市',
    'telAreaCode': '0436'
  },
  '222400': {
    'name': '延边朝鲜族自治州',
    'telAreaCode': '0433'
  },
  '230100': {
    'name': '哈尔滨市',
    'telAreaCode': '0451'
  },
  '230200': {
    'name': '齐齐哈尔市',
    'telAreaCode': '0452'
  },
  '230300': {
    'name': '鸡西市',
    'telAreaCode': '0467'
  },
  '230400': {
    'name': '鹤岗市',
    'telAreaCode': '0468'
  },
  '230500': {
    'name': '双鸭山市',
    'telAreaCode': '0469'
  },
  '230600': {
    'name': '大庆市',
    'telAreaCode': '0459'
  },
  '230700': {
    'name': '伊春市',
    'telAreaCode': '0458'
  },
  '230800': {
    'name': '佳木斯市',
    'telAreaCode': '0454'
  },
  '230900': {
    'name': '七台河市',
    'telAreaCode': '0464'
  },
  '231000': {
    'name': '牡丹江市',
    'telAreaCode': '0453'
  },
  '231100': {
    'name': '黑河市',
    'telAreaCode': '0456'
  },
  '231200': {
    'name': '绥化市',
    'telAreaCode': '0455'
  },
  '232700': {
    'name': '大兴安岭地区',
    'telAreaCode': '0456'
  },
  '310100': {
    'name': '上海市',
    'telAreaCode': '021'
  },
  '320100': {
    'name': '南京市',
    'telAreaCode': '025'
  },
  '320200': {
    'name': '无锡市',
    'telAreaCode': '0510'
  },
  '320300': {
    'name': '徐州市',
    'telAreaCode': '0516'
  },
  '320400': {
    'name': '常州市',
    'telAreaCode': '0519'
  },
  '320500': {
    'name': '苏州市',
    'telAreaCode': '0512'
  },
  '320600': {
    'name': '南通市',
    'telAreaCode': '0513'
  },
  '320700': {
    'name': '连云港市',
    'telAreaCode': '0518'
  },
  '320800': {
    'name': '淮安市',
    'telAreaCode': '0517'
  },
  '320900': {
    'name': '盐城市',
    'telAreaCode': '0515'
  },
  '321000': {
    'name': '扬州市',
    'telAreaCode': '0514'
  },
  '321100': {
    'name': '镇江市',
    'telAreaCode': '0511'
  },
  '321200': {
    'name': '泰州市',
    'telAreaCode': '0523'
  },
  '321300': {
    'name': '宿迁市',
    'telAreaCode': '0527'
  },
  '330100': {
    'name': '杭州市',
    'telAreaCode': '0571'
  },
  '330200': {
    'name': '宁波市',
    'telAreaCode': '0574'
  },
  '330300': {
    'name': '温州市',
    'telAreaCode': '0577'
  },
  '330400': {
    'name': '嘉兴市',
    'telAreaCode': '0573'
  },
  '330500': {
    'name': '湖州市',
    'telAreaCode': '0572'
  },
  '330600': {
    'name': '绍兴市',
    'telAreaCode': '0575'
  },
  '330700': {
    'name': '金华市',
    'telAreaCode': '0579'
  },
  '330800': {
    'name': '衢州市',
    'telAreaCode': '0570'
  },
  '330900': {
    'name': '舟山市',
    'telAreaCode': '0580'
  },
  '331000': {
    'name': '台州市',
    'telAreaCode': '0576'
  },
  '331100': {
    'name': '丽水市',
    'telAreaCode': '0578'
  },
  '340100': {
    'name': '合肥市',
    'telAreaCode': '0551'
  },
  '340200': {
    'name': '芜湖市',
    'telAreaCode': '0553'
  },
  '340300': {
    'name': '蚌埠市',
    'telAreaCode': '0552'
  },
  '340400': {
    'name': '淮南市',
    'telAreaCode': '0554'
  },
  '340500': {
    'name': '马鞍山市',
    'telAreaCode': '0555'
  },
  '340600': {
    'name': '淮北市',
    'telAreaCode': '0561'
  },
  '340700': {
    'name': '铜陵市',
    'telAreaCode': '0562'
  },
  '340800': {
    'name': '安庆市',
    'telAreaCode': '0556'
  },
  '341000': {
    'name': '黄山市',
    'telAreaCode': '0559'
  },
  '341100': {
    'name': '滁州市',
    'telAreaCode': '0550'
  },
  '341200': {
    'name': '阜阳市',
    'telAreaCode': '0558'
  },
  '341300': {
    'name': '宿州市',
    'telAreaCode': '0557'
  },
  '341500': {
    'name': '六安市',
    'telAreaCode': '0564'
  },
  '341600': {
    'name': '亳州市',
    'telAreaCode': '0558'
  },
  '341700': {
    'name': '池州市',
    'telAreaCode': '0566'
  },
  '341800': {
    'name': '宣城市',
    'telAreaCode': '0563'
  },
  '350100': {
    'name': '福州市',
    'telAreaCode': '0591'
  },
  '350200': {
    'name': '厦门市',
    'telAreaCode': '0592'
  },
  '350300': {
    'name': '莆田市',
    'telAreaCode': '0594'
  },
  '350400': {
    'name': '三明市',
    'telAreaCode': '0598'
  },
  '350500': {
    'name': '泉州市',
    'telAreaCode': '0595'
  },
  '350600': {
    'name': '漳州市',
    'telAreaCode': '0596'
  },
  '350700': {
    'name': '南平市',
    'telAreaCode': '05999'
  },
  '350800': {
    'name': '龙岩市',
    'telAreaCode': '0597'
  },
  '350900': {
    'name': '宁德市',
    'telAreaCode': '0593'
  },
  '360100': {
    'name': '南昌市',
    'telAreaCode': '0791'
  },
  '360200': {
    'name': '景德镇市',
    'telAreaCode': '0798'
  },
  '360300': {
    'name': '萍乡市',
    'telAreaCode': '0799'
  },
  '360400': {
    'name': '九江市',
    'telAreaCode': '0792'
  },
  '360500': {
    'name': '新余市',
    'telAreaCode': '0790'
  },
  '360600': {
    'name': '鹰潭市',
    'telAreaCode': '07011'
  },
  '360700': {
    'name': '赣州市',
    'telAreaCode': '0797'
  },
  '360800': {
    'name': '吉安市',
    'telAreaCode': '0796'
  },
  '360900': {
    'name': '宜春市',
    'telAreaCode': '0795'
  },
  '361000': {
    'name': '抚州市',
    'telAreaCode': '0794'
  },
  '361100': {
    'name': '上饶市',
    'telAreaCode': '0793'
  },
  '370100': {
    'name': '济南市',
    'telAreaCode': '0531'
  },
  '370200': {
    'name': '青岛市',
    'telAreaCode': '0532'
  },
  '370300': {
    'name': '淄博市',
    'telAreaCode': '0533'
  },
  '370400': {
    'name': '枣庄市',
    'telAreaCode': '0632'
  },
  '370500': {
    'name': '东营市',
    'telAreaCode': '0546'
  },
  '370600': {
    'name': '烟台市',
    'telAreaCode': '0535'
  },
  '370700': {
    'name': '潍坊市',
    'telAreaCode': '0536'
  },
  '370800': {
    'name': '济宁市',
    'telAreaCode': '0537'
  },
  '370900': {
    'name': '泰安市',
    'telAreaCode': '0538'
  },
  '371000': {
    'name': '威海市',
    'telAreaCode': '0631'
  },
  '371100': {
    'name': '日照市',
    'telAreaCode': '0633'
  },
  '371300': {
    'name': '临沂市',
    'telAreaCode': '0539'
  },
  '371400': {
    'name': '德州市',
    'telAreaCode': '0534'
  },
  '371500': {
    'name': '聊城市',
    'telAreaCode': '0635'
  },
  '371600': {
    'name': '滨州市',
    'telAreaCode': '0543'
  },
  '371700': {
    'name': '菏泽市',
    'telAreaCode': '0530'
  },
  '410100': {
    'name': '郑州市',
    'telAreaCode': '0371'
  },
  '410200': {
    'name': '开封市',
    'telAreaCode': '0371'
  },
  '410300': {
    'name': '洛阳市',
    'telAreaCode': '0379'
  },
  '410400': {
    'name': '平顶山市',
    'telAreaCode': '0375'
  },
  '410500': {
    'name': '安阳市',
    'telAreaCode': '0372'
  },
  '410600': {
    'name': '鹤壁市',
    'telAreaCode': '0392'
  },
  '410700': {
    'name': '新乡市',
    'telAreaCode': '0373'
  },
  '410800': {
    'name': '焦作市',
    'telAreaCode': '0391'
  },
  '410900': {
    'name': '濮阳市',
    'telAreaCode': '0393'
  },
  '411000': {
    'name': '许昌市',
    'telAreaCode': '0374'
  },
  '411100': {
    'name': '漯河市',
    'telAreaCode': '0395'
  },
  '411200': {
    'name': '三门峡市',
    'telAreaCode': '0398'
  },
  '411300': {
    'name': '南阳市',
    'telAreaCode': '0377'
  },
  '411400': {
    'name': '商丘市',
    'telAreaCode': '0370'
  },
  '411500': {
    'name': '信阳市',
    'telAreaCode': '0376'
  },
  '411600': {
    'name': '周口市',
    'telAreaCode': '0394'
  },
  '411700': {
    'name': '驻马店市',
    'telAreaCode': '0396'
  },
  '419000': {
    'name': '省直辖县',
    'telAreaCode': '0391'
  },
  '420100': {
    'name': '武汉市',
    'telAreaCode': '027'
  },
  '420200': {
    'name': '黄石市',
    'telAreaCode': '0714'
  },
  '420300': {
    'name': '十堰市',
    'telAreaCode': '0719'
  },
  '420500': {
    'name': '宜昌市',
    'telAreaCode': '0717'
  },
  '420600': {
    'name': '襄阳市',
    'telAreaCode': '0710'
  },
  '420700': {
    'name': '鄂州市',
    'telAreaCode': '0711'
  },
  '420800': {
    'name': '荆门市',
    'telAreaCode': '0724'
  },
  '420900': {
    'name': '孝感市',
    'telAreaCode': '0712'
  },
  '421000': {
    'name': '荆州市',
    'telAreaCode': '0716'
  },
  '421100': {
    'name': '黄冈市',
    'telAreaCode': '0713'
  },
  '421200': {
    'name': '咸宁市',
    'telAreaCode': '0715'
  },
  '421300': {
    'name': '随州市',
    'telAreaCode': '0722'
  },
  '422800': {
    'name': '恩施土家族苗族自治州',
    'telAreaCode': '0718'
  },
  '429000': {
    'name': '省直辖县',
    'telAreaCode': '0728'
  },
  '430100': {
    'name': '长沙市',
    'telAreaCode': '0731'
  },
  '430200': {
    'name': '株洲市',
    'telAreaCode': '0731'
  },
  '430300': {
    'name': '湘潭市',
    'telAreaCode': '0731'
  },
  '430400': {
    'name': '衡阳市',
    'telAreaCode': '0734'
  },
  '430500': {
    'name': '邵阳市',
    'telAreaCode': '0739'
  },
  '430600': {
    'name': '岳阳市',
    'telAreaCode': '0730'
  },
  '430700': {
    'name': '常德市',
    'telAreaCode': '0736'
  },
  '430800': {
    'name': '张家界市',
    'telAreaCode': '0744'
  },
  '430900': {
    'name': '益阳市',
    'telAreaCode': '0737'
  },
  '431000': {
    'name': '郴州市',
    'telAreaCode': '0735'
  },
  '431100': {
    'name': '永州市',
    'telAreaCode': '0746'
  },
  '431200': {
    'name': '怀化市',
    'telAreaCode': '0745'
  },
  '431300': {
    'name': '娄底市',
    'telAreaCode': '0738'
  },
  '433100': {
    'name': '湘西土家族苗族自治州',
    'telAreaCode': '0743'
  },
  '440100': {
    'name': '广州市',
    'telAreaCode': '020'
  },
  '440200': {
    'name': '韶关市',
    'telAreaCode': '0751'
  },
  '440300': {
    'name': '深圳市',
    'telAreaCode': '0755'
  },
  '440400': {
    'name': '珠海市',
    'telAreaCode': '0756'
  },
  '440500': {
    'name': '汕头市',
    'telAreaCode': '0754'
  },
  '440600': {
    'name': '佛山市',
    'telAreaCode': '0757'
  },
  '440700': {
    'name': '江门市',
    'telAreaCode': '0750'
  },
  '440800': {
    'name': '湛江市',
    'telAreaCode': '0759'
  },
  '440900': {
    'name': '茂名市',
    'telAreaCode': '0668'
  },
  '441200': {
    'name': '肇庆市',
    'telAreaCode': '0758'
  },
  '441300': {
    'name': '惠州市',
    'telAreaCode': '0752'
  },
  '441400': {
    'name': '梅州市',
    'telAreaCode': '0753'
  },
  '441500': {
    'name': '汕尾市',
    'telAreaCode': '0660'
  },
  '441600': {
    'name': '河源市',
    'telAreaCode': '0762'
  },
  '441700': {
    'name': '阳江市',
    'telAreaCode': '0662'
  },
  '441800': {
    'name': '清远市',
    'telAreaCode': '0763'
  },
  '441900': {
    'name': '东莞市',
    'telAreaCode': '0769'
  },
  '442000': {
    'name': '中山市',
    'telAreaCode': '0760'
  },
  '445100': {
    'name': '潮州市',
    'telAreaCode': '0768'
  },
  '445200': {
    'name': '揭阳市',
    'telAreaCode': '0663'
  },
  '445300': {
    'name': '云浮市',
    'telAreaCode': '0766'
  },
  '450100': {
    'name': '南宁市',
    'telAreaCode': '0771'
  },
  '450200': {
    'name': '柳州市',
    'telAreaCode': '0772'
  },
  '450300': {
    'name': '桂林市',
    'telAreaCode': '0773'
  },
  '450400': {
    'name': '梧州市',
    'telAreaCode': '0774'
  },
  '450500': {
    'name': '北海市',
    'telAreaCode': '0779'
  },
  '450600': {
    'name': '防城港市',
    'telAreaCode': '0770'
  },
  '450700': {
    'name': '钦州市',
    'telAreaCode': '0777'
  },
  '450800': {
    'name': '贵港市',
    'telAreaCode': '0775'
  },
  '450900': {
    'name': '玉林市',
    'telAreaCode': '0775'
  },
  '451000': {
    'name': '百色市',
    'telAreaCode': '0776'
  },
  '451100': {
    'name': '贺州市',
    'telAreaCode': '0774'
  },
  '451200': {
    'name': '河池市',
    'telAreaCode': '0778'
  },
  '451300': {
    'name': '来宾市',
    'telAreaCode': '0772'
  },
  '451400': {
    'name': '崇左市',
    'telAreaCode': '0771'
  },
  '460100': {
    'name': '海口市',
    'telAreaCode': '0898'
  },
  '460200': {
    'name': '三亚市',
    'telAreaCode': '0898'
  },
  '460300': {
    'name': '三沙市',
    'telAreaCode': '0898'
  },
  '460400': {
    'name': '儋州市',
    'telAreaCode': '0898'
  },
  '469000': {
    'name': '省直辖县',
    'telAreaCode': '0898'
  },
  '500100': {
    'name': '重庆市',
    'telAreaCode': '023'
  },
  '500200': {
    'name': '县',
    'telAreaCode': '023'
  },
  '510100': {
    'name': '成都市',
    'telAreaCode': '028'
  },
  '510300': {
    'name': '自贡市',
    'telAreaCode': '0813'
  },
  '510400': {
    'name': '攀枝花市',
    'telAreaCode': '0812'
  },
  '510500': {
    'name': '泸州市',
    'telAreaCode': '0830'
  },
  '510600': {
    'name': '德阳市',
    'telAreaCode': '0838'
  },
  '510700': {
    'name': '绵阳市',
    'telAreaCode': '0816'
  },
  '510800': {
    'name': '广元市',
    'telAreaCode': '0839'
  },
  '510900': {
    'name': '遂宁市',
    'telAreaCode': '0825'
  },
  '511000': {
    'name': '内江市',
    'telAreaCode': '0832'
  },
  '511100': {
    'name': '乐山市',
    'telAreaCode': '0833'
  },
  '511300': {
    'name': '南充市',
    'telAreaCode': '0817'
  },
  '511400': {
    'name': '眉山市',
    'telAreaCode': '028'
  },
  '511500': {
    'name': '宜宾市',
    'telAreaCode': '0831'
  },
  '511600': {
    'name': '广安市',
    'telAreaCode': '0826'
  },
  '511700': {
    'name': '达州市',
    'telAreaCode': '0818'
  },
  '511800': {
    'name': '雅安市',
    'telAreaCode': '0835'
  },
  '511900': {
    'name': '巴中市',
    'telAreaCode': '0827'
  },
  '512000': {
    'name': '资阳市',
    'telAreaCode': '028'
  },
  '513200': {
    'name': '阿坝藏族羌族自治州',
    'telAreaCode': '0837'
  },
  '513300': {
    'name': '甘孜藏族自治州',
    'telAreaCode': '0836'
  },
  '513400': {
    'name': '凉山彝族自治州',
    'telAreaCode': '0834'
  },
  '520100': {
    'name': '贵阳市',
    'telAreaCode': '0851'
  },
  '520200': {
    'name': '六盘水市',
    'telAreaCode': '0858'
  },
  '520300': {
    'name': '遵义市',
    'telAreaCode': '0852'
  },
  '520400': {
    'name': '安顺市',
    'telAreaCode': '0853'
  },
  '520500': {
    'name': '毕节市',
    'telAreaCode': '0857'
  },
  '520600': {
    'name': '铜仁市',
    'telAreaCode': '0856'
  },
  '522300': {
    'name': '黔西南布依族苗族自治州',
    'telAreaCode': '0859'
  },
  '522600': {
    'name': '黔东南苗族侗族自治州',
    'telAreaCode': '0855'
  },
  '522700': {
    'name': '黔南布依族苗族自治州',
    'telAreaCode': '0854'
  },
  '530100': {
    'name': '昆明市',
    'telAreaCode': '0871'
  },
  '530300': {
    'name': '曲靖市',
    'telAreaCode': '0874'
  },
  '530400': {
    'name': '玉溪市',
    'telAreaCode': '0877'
  },
  '530500': {
    'name': '保山市',
    'telAreaCode': '0875'
  },
  '530600': {
    'name': '昭通市',
    'telAreaCode': '0870'
  },
  '530700': {
    'name': '丽江市',
    'telAreaCode': '0888'
  },
  '530800': {
    'name': '普洱市',
    'telAreaCode': '0879'
  },
  '530900': {
    'name': '临沧市',
    'telAreaCode': '0883'
  },
  '532300': {
    'name': '楚雄彝族自治州',
    'telAreaCode': '0878'
  },
  '532500': {
    'name': '红河哈尼族彝族自治州',
    'telAreaCode': '0873'
  },
  '532600': {
    'name': '文山壮族苗族自治州',
    'telAreaCode': '0876'
  },
  '532800': {
    'name': '西双版纳傣族自治州',
    'telAreaCode': '0691'
  },
  '532900': {
    'name': '大理白族自治州',
    'telAreaCode': '0872'
  },
  '533100': {
    'name': '德宏傣族景颇族自治州',
    'telAreaCode': '0692'
  },
  '533300': {
    'name': '怒江傈僳族自治州',
    'telAreaCode': '0886'
  },
  '533400': {
    'name': '迪庆藏族自治州',
    'telAreaCode': '0887'
  },
  '540100': {
    'name': '拉萨市',
    'telAreaCode': '0891'
  },
  '540200': {
    'name': '日喀则市',
    'telAreaCode': '0892'
  },
  '540300': {
    'name': '昌都市',
    'telAreaCode': '0895'
  },
  '540400': {
    'name': '林芝市',
    'telAreaCode': '0894'
  },
  '540500': {
    'name': '山南市',
    'telAreaCode': '0893'
  },
  '540600': {
    'name': '那曲市',
    'telAreaCode': '0896'
  },
  '542500': {
    'name': '阿里地区',
    'telAreaCode': '0897'
  },
  '610100': {
    'name': '西安市',
    'telAreaCode': '029'
  },
  '610200': {
    'name': '铜川市',
    'telAreaCode': '0919'
  },
  '610300': {
    'name': '宝鸡市',
    'telAreaCode': '0917'
  },
  '610400': {
    'name': '咸阳市',
    'telAreaCode': '029'
  },
  '610500': {
    'name': '渭南市',
    'telAreaCode': '0913'
  },
  '610600': {
    'name': '延安市',
    'telAreaCode': '0911'
  },
  '610700': {
    'name': '汉中市',
    'telAreaCode': '0916'
  },
  '610800': {
    'name': '榆林市',
    'telAreaCode': '0912'
  },
  '610900': {
    'name': '安康市',
    'telAreaCode': '0915'
  },
  '611000': {
    'name': '商洛市',
    'telAreaCode': '0914'
  },
  '620100': {
    'name': '兰州市',
    'telAreaCode': '0931'
  },
  '620200': {
    'name': '嘉峪关市',
    'telAreaCode': '0937'
  },
  '620300': {
    'name': '金昌市',
    'telAreaCode': '0935'
  },
  '620400': {
    'name': '白银市',
    'telAreaCode': '0943'
  },
  '620500': {
    'name': '天水市',
    'telAreaCode': '0938'
  },
  '620600': {
    'name': '武威市',
    'telAreaCode': '0935'
  },
  '620700': {
    'name': '张掖市',
    'telAreaCode': '0936'
  },
  '620800': {
    'name': '平凉市',
    'telAreaCode': '0933'
  },
  '620900': {
    'name': '酒泉市',
    'telAreaCode': '0937'
  },
  '621000': {
    'name': '庆阳市',
    'telAreaCode': '0934'
  },
  '621100': {
    'name': '定西市',
    'telAreaCode': '0932'
  },
  '621200': {
    'name': '陇南市',
    'telAreaCode': '0939'
  },
  '622900': {
    'name': '临夏回族自治州',
    'telAreaCode': '0930'
  },
  '623000': {
    'name': '甘南藏族自治州',
    'telAreaCode': '0941'
  },
  '630100': {
    'name': '西宁市',
    'telAreaCode': '0971'
  },
  '630200': {
    'name': '海东市',
    'telAreaCode': '0972'
  },
  '632200': {
    'name': '海北藏族自治州',
    'telAreaCode': '0970'
  },
  '632300': {
    'name': '黄南藏族自治州',
    'telAreaCode': '0973'
  },
  '632500': {
    'name': '海南藏族自治州',
    'telAreaCode': '0974'
  },
  '632600': {
    'name': '果洛藏族自治州',
    'telAreaCode': '0975'
  },
  '632700': {
    'name': '玉树藏族自治州',
    'telAreaCode': '0976'
  },
  '632800': {
    'name': '海西蒙古族藏族自治州',
    'telAreaCode': '0979'
  },
  '640100': {
    'name': '银川市',
    'telAreaCode': '0951'
  },
  '640200': {
    'name': '石嘴山市',
    'telAreaCode': '0952'
  },
  '640300': {
    'name': '吴忠市',
    'telAreaCode': '0953'
  },
  '640400': {
    'name': '固原市',
    'telAreaCode': '0954'
  },
  '640500': {
    'name': '中卫市',
    'telAreaCode': '0955'
  },
  '650100': {
    'name': '乌鲁木齐市',
    'telAreaCode': '0991'
  },
  '650200': {
    'name': '克拉玛依市',
    'telAreaCode': '0990'
  },
  '650400': {
    'name': '吐鲁番市',
    'telAreaCode': '0995'
  },
  '650500': {
    'name': '哈密市',
    'telAreaCode': '0902'
  },
  '652300': {
    'name': '昌吉回族自治州',
    'telAreaCode': '0994'
  },
  '652700': {
    'name': '博尔塔拉蒙古自治州',
    'telAreaCode': '0909'
  },
  '652800': {
    'name': '巴音郭楞蒙古自治州',
    'telAreaCode': '0996'
  },
  '652900': {
    'name': '阿克苏地区',
    'telAreaCode': '0997'
  },
  '653000': {
    'name': '克孜勒苏柯尔克孜自治州',
    'telAreaCode': '0908'
  },
  '653100': {
    'name': '喀什地区',
    'telAreaCode': '0998'
  },
  '653200': {
    'name': '和田地区',
    'telAreaCode': '0903'
  },
  '654000': {
    'name': '伊犁哈萨克自治州',
    'telAreaCode': '0999'
  },
  '654200': {
    'name': '塔城地区',
    'telAreaCode': '0901'
  },
  '654300': {
    'name': '阿勒泰地区',
    'telAreaCode': '0906'
  },
  '659000': {
    'name': '自治区直辖县级行政区划',
    'telAreaCode': '0992'
  },
  '710100': {
    'name': '台北市',
    'telAreaCode': '886'
  },
  '710200': {
    'name': '高雄市',
    'telAreaCode': '886'
  },
  '710300': {
    'name': '台南市',
    'telAreaCode': '886'
  },
  '710400': {
    'name': '台中市',
    'telAreaCode': '886'
  },
  '710500': {
    'name': '金门县',
    'telAreaCode': '886'
  },
  '710600': {
    'name': '南投县',
    'telAreaCode': '886'
  },
  '710700': {
    'name': '基隆市',
    'telAreaCode': '886'
  },
  '710800': {
    'name': '新竹市',
    'telAreaCode': '886'
  },
  '710900': {
    'name': '嘉义市',
    'telAreaCode': '886'
  },
  '711100': {
    'name': '新北市',
    'telAreaCode': '886'
  },
  '711200': {
    'name': '宜兰县',
    'telAreaCode': '886'
  },
  '711300': {
    'name': '新竹县',
    'telAreaCode': '886'
  },
  '711400': {
    'name': '桃园市',
    'telAreaCode': '886'
  },
  '711500': {
    'name': '苗栗县',
    'telAreaCode': '886'
  },
  '711700': {
    'name': '彰化县',
    'telAreaCode': '886'
  },
  '711900': {
    'name': '嘉义县',
    'telAreaCode': '886'
  },
  '712100': {
    'name': '云林县',
    'telAreaCode': '886'
  },
  '712400': {
    'name': '屏东县',
    'telAreaCode': '886'
  },
  '712500': {
    'name': '台东县',
    'telAreaCode': '886'
  },
  '712600': {
    'name': '花莲县',
    'telAreaCode': '886'
  },
  '712700': {
    'name': '澎湖县',
    'telAreaCode': '886'
  },
  '712800': {
    'name': '连江县',
    'telAreaCode': '886'
  },
  '810100': {
    'name': '香港岛',
    'telAreaCode': '852'
  },
  '810200': {
    'name': '九龙',
    'telAreaCode': '852'
  },
  '810300': {
    'name': '新界',
    'telAreaCode': '852'
  },
  '820100': {
    'name': '澳门半岛',
    'telAreaCode': '853'
  },
  '820200': {
    'name': '离岛',
    'telAreaCode': '853'
  }
}

export default data
