import React, { Component } from 'react'
import { Card, Row, Button, Switch, Table, Modal, message } from 'antd'
import moment from 'moment'

import DownloadApi from '@/common/api/downloadApi'
import SearchForm from '@/components/tool-form/index'
import QuoteModal from '../component/setquote-modal'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'

const { confirm } = Modal
const { pageSizeOptions } = filter

export default class QuotetempList extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'agreementTemplateName',
        formType: 'Input',
        itemParams: {
          label: '模板名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'quotationTemplateType',
        formType: 'Select',
        itemParams: {
          label: '模板类型'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'COMMON', name: '通用模板' },
          { id: 'GUEST', name: '客户模板' }
        ]
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '创建时间'
        },
        cptParams: {

        }
      },
      {
        name: 'enableDisableStatus',
        formType: 'Select',
        itemParams: {
          label: '启用状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'ENABLE', name: '启用' },
          { id: 'DISABLE', name: '禁用' }
        ]
      }
    ],
    type: 1,
    quoteModal: false,
    rowDetail: {},
    query: {},
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'quoteTempList-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '模板名称',
      key: 'quoteTempList-1',
      dataIndex: 'quotationTemplateName'
    },
    {
      title: '模板类型',
      key: 'quoteTempList-3',
      dataIndex: 'quotationTemplateType',
      render: (text, record, index) => (
        <>
          { record.quotationTemplateType === 'GUEST' ? '客户模板' : '通用模板' }
        </>
      )
    },
    {
      title: '添加时间',
      key: 'quoteTempList-5',
      dataIndex: 'createTime',
      render: (text, record, index) => (
        <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '是否启用',
      key: 'quoteTempList-6',
      dataIndex: 'enableDisableStatus',
      render: (text, record, index) => (
        <>
          <Switch
            size='small'
            checked={record.enableDisableStatus === 'ENABLE'}
            checkedChildren={'启用'}
            unCheckedChildren={'禁用'}
            onChange={(checked) => this.handleChangeSwitch(checked, record)}
          />
        </>
      )
    },
    {
      title: '操作',
      key: 'quoteTempList-7',
      render: (text, record, index) => (
        <>
          <Button type='primary' size='small' style={{ marginRight: '8px' }}
            onClick={() => this.showEditTempModal(record, 2)}
          >编辑</Button>

          <Button type='primary' size='small' style={{ marginRight: '8px' }}
            onClick={() => this.handleDeleteRow(record)}
          >删除</Button>

          <Button type='primary' size='small' onClick={() => this.handleDownloadTemplate(record)}>预览下载</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getQuoteTempList()
  }

  // 获取报价单模板列表
  getQuoteTempList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getQuoteTempList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 删除合同模板
  deleteQuoteTemp = (params) => {
    Api.deleteQuoteTemp(params).then(res => {
      const { code } = res

      if (+code === 10000) {
        message.success('操作成功')
        this.getQuoteTempList()
      }
    })
  }

  // 保存新增模板
  saveAddQuoteTemp = (params) => {
    Api.saveAddQuoteTemp(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getQuoteTempList()
      }
    })
  }

  // 保存编辑模板
  saveEditQuoteTemp = (params) => {
    Api.saveEditQuoteTemp(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getQuoteTempList()
      }
    })
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.times) {
      search.beginDate = search.times.length === 2 ? moment(search.times[0]).format('YYYY-MM-DD HH:mm:ss') : undefined
      search.endDate = search.times.length === 2 ? moment(search.times[1]).format('YYYY-MM-DD HH:mm:ss') : undefined

      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search
    }, () => {
      this.getQuoteTempList()
    })
  }

  // 保存报价单模板
  handleSaveQuoteTemp = (params) => {
    const { type = 1 } = this.state

    if (type === 1) {
      this.saveAddQuoteTemp(params)
    } else {
      this.saveEditQuoteTemp(params)
    }
  }

  // 点击删除
  handleDeleteRow = (record) => {
    confirm({
      title: '确定删除模板？',
      content: '',
      onOk: () => {
        this.deleteQuoteTemp({ quotationTemplateId: record.quotationTemplateId })
      }
    })
  }

  // 切换是否启用
  handleChangeSwitch = (checked, record) => {
    let params = {
      enableDisableStatus: checked ? 'ENABLE' : 'DISABLE',
      quotationTemplateId: record.quotationTemplateId
    }

    Api.updateQuoteTempEnableStatus(params).then(res => {
      const { code } = res

      if (+code === 10000) {
        this.getQuoteTempList()
      }
    })
  }

  // 新增/编辑合同模板
  showEditTempModal = (record, type) => {
    this.setState({
      quoteModal: true,
      type: type,
      rowDetail: record || {}
    })
  }

  // 点击预览
  handleDownloadTemplate = (record) => {
    if (!record.quotationTemplateId) {
      return message.warning('报价单模板不存在')
    }

    DownloadApi.downQuoteTemplate({ quotationTemplateId: record.quotationTemplateId, distributorId: record.distributorId })
  }

  closeModal = () => {
    this.setState({
      quoteModal: false,
      rowDetail: {},
      type: 1
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getQuoteTempList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getQuoteTempList()
    })
  }

  render () {
    const { searchData, tableData, pages, quoteModal = false, type = 1, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }} >
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ marginBottom: '15px' }}>
            <Button type='primary' onClick={() => this.showEditTempModal({}, 1)}>
              新增模板
            </Button>
          </Row>

          <Table rowKey='quotationTemplateId'
            columns={this.columns}
            dataSource={tableData}
            pagination={pagination}
          />
        </Card>

        {
          quoteModal
            ? <QuoteModal
              type={type}
              detailInfo={rowDetail}
              onConfirm={(params) => this.handleSaveQuoteTemp(params)}
              onCancel={this.closeModal}
            />
            : null

        }
      </>
    )
  }
}
