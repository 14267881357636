import React, { Component } from 'react'
import { Card, Row, Button, Switch, Table, Modal, message } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import RactModal from '../component/ract-modal'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import filter from '@/common/utils/filter'

const { confirm } = Modal
const { previewContractTemp } = DownloadApi

const { pageSizeOptions, contractTemplateEnableStatusMaps } = filter

export default class ContractTemp extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'agreementTemplateName',
        formType: 'Input',
        itemParams: {
          label: '模板名称'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'quotationTemplateType',
        formType: 'Select',
        itemParams: {
          label: '模板类型'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off'
        },
        options: [
          { id: 'ALL', name: '全部' },
          { id: 'COMMON', name: '通用模板' },
          { id: 'GUEST', name: '客户模板' }
        ]
      },
      {
        name: 'enableDisableStatus',
        formType: 'Select',
        itemParams: {
          label: '模板状态'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...contractTemplateEnableStatusMaps
        ]
      }
    ],
    type: 1,
    ractModal: false,
    rowDetail: {},
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'contractTemp-0',
      width: 44,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '模板名称',
      key: 'contractTemp-1',
      dataIndex: 'agreementTemplateName'
    },
    {
      title: '合同类型',
      key: 'contractTemp-3',
      render: (text, record, index) => (
        <div>
          {
            record.agreementTemplateType === 'GUEST'
              ? '客户模板'
              : '通用模板'
          }
        </div>
      )
    },
    {
      title: '添加时间',
      key: 'contractTemp-5',
      render: (text, record, index) => (
        <>
          { record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
        </>
      )
    },
    {
      title: '是否启用',
      key: 'contractTemp-6',
      render: (text, record, index) => (
        <div>
          <Switch
            checked={record.disableStatus === 'ENABLE'}
            checkedChildren={'启用'}
            unCheckedChildren={'禁用'}
            onChange={(checked) => this.handleChangeSwitch(checked, record)}
          />
        </div>
      )
    },
    {
      title: '操作',
      key: 'contractTemp-7',
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small' style={{ marginRight: '8px' }} onClick={() => this.showEditTempModal(record, 2)}>
            编辑
          </Button>

          <Button type='primary' size='small' style={{ marginRight: '8px' }}
            onClick={() => this.handleDeleteRow(record)}
          >
            删除
          </Button>

          <Button type='primary' size='small'
            onClick={() => this.handleDownloadPreview(record)}
          >预览下载</Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getContractTemplList()
  }

  // 获取合同模板列表
  getContractTemplList = () => {
    const { pages, query = {} } = this.state
    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getContractTemplList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取合同模板详情
  getContractTempDetail = (params) => {
    return Api.getContractTempDetail(params)
  }

  // 模糊查询客户
  getGuesteuUserLike = (params) => {
    return Api.getGuesteuUserLike(params)
  }

  // 删除合同模板
  saveDeleteContract = (params) => {
    Api.deleteContractTemp(params).then(res => {
      const { code } = res

      if (+code === 10000) {
        message.success('操作成功')
        this.getContractTemplList()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 保存新增模板
  saveAddContractTemp = (params) => {
    Api.saveAddContractTemp(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getContractTemplList()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 保存编辑模板
  saveEditContractTemp = (params) => {
    Api.saveEditContractTemp(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        this.closeModal()
        this.getContractTemplList()
      } else {
        message.warning(res.message)
      }
    })
  }

  // 保存合同模板
  handleSaveContractTemp = (params) => {
    const { type = 1 } = this.state

    if (type === 1) {
      this.saveAddContractTemp(params)
    } else {
      this.saveEditContractTemp(params)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => {
      this.getContractTemplList()
    })
  }

  // 点击删除
  handleDeleteRow = (row) => {
    confirm({
      title: '确定删除该项？',
      content: '',
      onOk: () => {
        let arr = []
        arr.push(row.agreementId)

        let params = {
          agreementIdList: arr
        }

        this.saveDeleteContract(params)
      }
    })
  }

  // 切换是否启用
  handleChangeSwitch = (checked, record) => {
    let arr = []
    arr.push(record.agreementId)

    let params = {
      disableStatus: checked ? 'ENABLE' : 'DISABLE',
      agreementIdList: arr
    }

    Api.updateContractTempStatus(params).then(res => {
      const { code } = res

      if (+code === 10000) {
        this.getContractTemplList()
      }
    })
  }

  // 新增/编辑合同模板
  showEditTempModal = (record, type) => {
    this.setState({
      ractModal: true,
      type: type,
      rowDetail: record || {}
    })
  }

  // 点击预览
  handleDownloadPreview = (record) => {
    if (!record.agreementId) {
      return message.warning('合同模板不存在')
    }

    previewContractTemp({ templateId: record.agreementId })
  }

  closeModal = () => {
    this.setState({
      ractModal: false,
      rowDetail: {},
      type: 1
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getContractTemplList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getContractTemplList()
    })
  }

  render () {
    const { searchData, tableData, pages, ractModal = false, type = 1, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <Card bodyStyle={{ padding: '15px' }}>
            <SearchForm
              {...this.props}
              dataSource={searchData}
              handleSubmit={(obj) => this.handleSearch(obj)}
            />
          </Card>
        </div>

        <Card bodyStyle={{ padding: '15px' }}>
          <Row style={{ marginBottom: '15px' }}>
            <Button type='primary' style={{ width: '110px', height: '30px' }}
              onClick={() => this.showEditTempModal({}, 1)}
            >
              新增模板
            </Button>
          </Row>
          <Row>
            <Table rowKey='agreementId'
              columns={this.columns}
              dataSource={tableData}
              pagination={pagination}
            />
          </Row>
        </Card>

        {
          ractModal
            ? <RactModal
              type={type}
              detailInfo={rowDetail}
              getSearchCustomer={(params) => this.getGuesteuUserLike(params)}
              getDetail={(params) => this.getContractTempDetail(params)}
              onConfirm={(params) => this.handleSaveContractTemp(params)}
              onCancel={this.closeModal}
            />
            : null

        }
      </>
    )
  }
}
