import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Descriptions, Form, Row, Col, Button, Modal, Select } from 'antd'
import moment from 'moment'

import Api from '@/common/api/index'

import AddressModal from '@/components/address-modal/index'
import { renderAddressLabel } from '@/common/utils/mUtils'
import styles from './send.module.scss'

const FormItem = Form.Item
const Option = Select.Option

class SalesbillCardForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    info: PropTypes.object,
    onSuccess: PropTypes.func,
    sendAddressList: PropTypes.array
  }
  state = {
    addressModal: false // 地址弹窗
  }

  componentDidMount () { }

  // 更新EU订单收货信息
  updateRecevingAddressByOrderId = (params) => {
    Api.updateRecevingAddressByOrderId(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        Modal.success({
          title: '修改收货信息成功！'
        })

        this.closeModal()
        this.props.onSuccess()
      }
    })
  }

  // 点击修改收货信息
  handleShowAddressModal = () => {
    this.setState({
      addressModal: true
    })
  }

  // 点击确定保存收货地址
  handleConfirmModifyAddress = (params) => {
    const { info = {} } = this.props
    params['pickingId'] = info.pickingId
    this.updateRecevingAddressByOrderId(params)
  }

  closeModal = () => {
    this.setState({
      addressModal: false
    })
  }

  render () {
    const { form, info = {}, sendAddressList = [] } = this.props
    const { getFieldDecorator } = form
    const { addressModal = false } = this.state

    return (
      <>
        <Card title='单据信息' bodyStyle={{ padding: '15px' }}>
          <Descriptions>
            <Descriptions.Item label='出库单号'>{info.outboundOrderNo}</Descriptions.Item>
            <Descriptions.Item label='订单编号'>{ info.orderId }</Descriptions.Item>
            <Descriptions.Item label='订单日期'>
              { info.orderTime ? moment(info.orderTime).format('YYYY-MM-DD HH:mm:ss') : '----' }
            </Descriptions.Item>

            <Descriptions.Item label='客户名称'>{info.customerName}</Descriptions.Item>
            <Descriptions.Item label='收货地址' span={2}>
              <span>
                { ['ORDER_CLOUD_STOCK', 'ORDER_CLOUD_FUTURES'].includes(info.orderType) ? '----' : renderAddressLabel(info.province, info.city, info.county, info.address)}
              </span>

              {
                ['ORDER_STOCK', 'ORDER_FUTURES'].includes(info.orderType)
                  ? <Button size='small' type='primary' style={{ marginLeft: '15px' }}
                    onClick={() => this.handleShowAddressModal()}
                  >修改收货信息</Button>
                  : null
              }

            </Descriptions.Item>
          </Descriptions>

          <Form>
            <Row>
              <Col span={14}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='发货地址'>
                    {
                      getFieldDecorator('addressId', {
                        initialValue: sendAddressList && sendAddressList.length > 0 ? sendAddressList[0].id : null,
                        rules: [
                          { required: true, message: '请选择快递发货地址' }
                        ]
                      })(
                        <Select placeholder='请选择...'>
                          {
                            sendAddressList.map((opt, n) => (
                              <Option value={opt.id} key={`addressOpt-${opt.id}-${n}`}>
                                { renderAddressLabel(opt.province, opt.city, opt.county, opt.address) } {opt.name} {opt.mobile}
                              </Option>
                            ))
                          }
                        </Select>
                      )
                    }
                  </FormItem>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>

        {
          addressModal
            ? <AddressModal
              type={3}
              detailInfo={{ ...info, name: info.receiverName }}
              onCancel={this.closeModal}
              onConfirm={(params) => this.handleConfirmModifyAddress(params)}
            />
            : null
        }
      </>
    )
  }
}

const SalesbillCard = Form.create()(SalesbillCardForm)

export default SalesbillCard
