import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Row, message, Button, Modal, Switch, Icon, PageHeader } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'

import SearchForm from '@/components/tool-form'
import PriceGroupModal from '../component/pricegroup-modal'
import ProgressModal from '@/pages/product/component/progress-modal' // 任务进度
import { setCallBackQuery } from '@/reducers/action'

import Api from '@/common/api'
import filter from '@/common/utils/filter'

const { confirm } = Modal
const { pageSizeOptions } = filter

// 启用禁用状态
const enableStatusMaps = [
  { id: 'ALL', name: '全部' },
  { id: 'ENABLE', name: '启用' },
  { id: 'DISABLE', name: '禁用' }
]

class CommonGroupList extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func
  }
  state = {
    searchData: [
      {
        name: 'enableDisableStatus',
        formType: 'Select',
        itemParams: {
          label: '启用状态'
        },
        cptParams: {
          placeholder: '请选择...',
          autoComplete: 'off'
        },
        options: enableStatusMaps
      }
    ],
    groupType: 'COMMON', // 价格组类型
    userInfo: {}, // 客户信息
    pricegroupModal: false, // 价格组弹窗
    progressModal: false, // 任务进度弹窗
    type: 1, // 1-新增 2-编辑
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    rowDetail: {} // 当前选择的行数据
  }

  columns = [
    {
      title: '序号',
      key: 'pricegroupList-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '价格组名称',
      key: 'pricegroupList-1',
      dataIndex: 'priceGroupName'
    },
    {
      title: '折扣率',
      key: 'pricegroupList-2',
      render: (text, record, index) => (
        <div>
          { +record.discount > 0 ? `${(+record.discount * 100).toFixed(2)}%` : '----' }
        </div>
      )
    },
    {
      title: '组类型',
      key: 'pricegroupList-3',
      render: (text, record, index) => (
        <div>
          { record.commonGuestType === 'COMMON' ? '通用类型' : '客户类型' }
        </div>
      )
    },
    {
      title: '创建日期',
      key: 'pricegroupList-4',
      render: (text, record, index) => (
        <div>
          {record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '--'}
        </div>
      )
    },
    {
      title: '是否启用',
      key: 'pricegroupList-5',
      render: (text, record, index) => (
        <div>
          <Switch
            checked={record.enableDisableStatus === 'ENABLE'}
            checkedChildren={'启用'}
            unCheckedChildren={'禁用'}
            onChange={(checked) => this.handleChangeSwitch(checked, record)}
          />
        </div>
      )
    },
    {
      title: '操作',
      key: 'pricegroupList-6',
      width: 250,
      render: (text, record, index) => (
        <div>
          <Button type='primary' size='small' style={{ marginRight: '8px' }} onClick={() => this.showPriceGroupModal(record, 2)}>
            编辑
          </Button>

          <Button type='primary' size='small' style={{ marginRight: '8px' }}
            onClick={() => this.handleDeletePriceGroup(record)}
          >删除</Button>

          <Button type='primary' size='small'
            onClick={() => this.handleJumpToPriceGroupDetail({ ...record, groupType: this.state.groupType })}
          >查看产品</Button>
        </div>
      )
    }
  ]

  componentDidMount () {
    this.getPriceListInCommonPriceGroup()
  }

  // 获取通用价格组列表
  getPriceListInCommonPriceGroup = async () => {
    const { pages, query = {} } = this.state
    const { location } = this.props.history

    let params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    let handleList = location.state === 'future' ? await Api.getFuturePriceListInCommonPriceGroup(params) : await Api.getPriceListInCommonPriceGroup(params)
    const { code, data } = handleList
    if (+code === 10000) {
      const { pages } = this.state
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total
        }
      })
    } else {
      message.warning(handleList.message)
    }
  }

  // 新增通用价格组
  saveAddGroupInCommonPriceGroup =async (params) => {
    const { location } = this.props.history
    let handleSava = location.state === 'future' ? await Api.saveFutureAddGroupInCommonPriceGroup(params) : await Api.saveAddGroupInCommonPriceGroup(params)
    const { code } = handleSava
    if (+code === 10000) {
      message.success('操作成功')
      setTimeout(() => {
        this.closeModal()
        this.getPriceListInCommonPriceGroup()
      }, 1000)
    } else {
      message.warning(handleSava.message)
    }
  }

  // 编辑通用价格组
  saveEditGroupInCommonPriceGroup = async (params) => {
    const { location } = this.props.history
    let handleSava = location.state === 'future' ? await Api.saveFutureEditGroupInCommonPriceGroup(params) : await Api.saveEditGroupInCommonPriceGroup(params)
    const { code } = handleSava
    if (+code === 10000) {
      message.success('操作成功')
      setTimeout(() => {
        this.closeModal()
        this.getPriceListInCommonPriceGroup()
      }, 1000)
    } else {
      message.warning(handleSava.message)
    }
  }

  // 删除通用价格组
  deleteGroupInCommonPriceGroup = async (params) => {
    const { location } = this.props.history
    let handleDel = location.state === 'future' ? await Api.deleteFutureGroupInCommonPriceGroup(params) : await Api.deleteGroupInCommonPriceGroup(params)
    const { code } = handleDel
    if (+code === 10000) {
      message.success('操作成功')
      setTimeout(() => {
        this.getPriceListInCommonPriceGroup()
      }, 1000)
    } else {
      message.warning(handleDel.message)
    }
  }

  // 切换通用价格组启用/禁用
  updateEnabledStatusInCommonPriceGroup =async (params) => {
    const { location } = this.props.history
    let handleOnchanage = location.state === 'future' ? await Api.updateFutureEnabledStatusInCommonPriceGroup(params) : await Api.updateEnabledStatusInCommonPriceGroup(params)
    const { code } = handleOnchanage
    if (+code === 10000) {
      this.getPriceListInCommonPriceGroup()
    } else {
      message.warning(handleOnchanage.message)
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getPriceListInCommonPriceGroup()
    })
  }

  // 点击新增/编辑价格组
  showPriceGroupModal = (record, type) => {
    const { location } = this.props.history
    this.setState({
      type: type,
      pricegroupModal: true,
      rowDetail: { ...record, state: location.state } || {}
    })
  }

  // 点击删除通用价格组
  handleDeletePriceGroup = (record) => {
    confirm({
      title: '确定删除？',
      onOk: () => {
        this.deleteGroupInCommonPriceGroup({ priceGroupId: record.priceGroupId })
      }
    })
  }

  // 切换是否启用
  handleChangeSwitch = (checked, record) => {
    let params = {
      enableDisableStatus: checked ? 'ENABLE' : 'DISABLE',
      priceGroupId: record.priceGroupId
    }

    this.updateEnabledStatusInCommonPriceGroup(params)
  }

  // 点击确定保存价格组
  handleConfirmSavePriceGroup = (params) => {
    const { type = 1 } = this.state

    if (type === 1) {
      this.saveAddGroupInCommonPriceGroup(params)
    } else {
      this.saveEditGroupInCommonPriceGroup(params)
    }
  }

  // 点击查看价格组产品
  handleJumpToPriceGroupDetail = (record) => {
    const { location } = this.props.history
    this.props.dispatch(setCallBackQuery({ callbackQuery: { detailInfo: record } }))
    this.props.history.push({ pathname: '/admin/commonGroupDetail', state: { detailInfo: record, state: location.state } })
  }

  // 点击查看任务进度
  showProgressModal = () => {
    this.setState({
      progressModal: true
    })
  }

  closeModal = () => {
    this.setState({
      pricegroupModal: false,
      progressModal: false,
      type: 1,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPriceListInCommonPriceGroup()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPriceListInCommonPriceGroup()
    })
  }

  render () {
    const { state } = this.props.history.location
    const { searchData, pages, tableData, pricegroupModal = false, type = 1, rowDetail = {}, groupType = 'COMMON', progressModal = false } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Row style={{ marginBottom: '16px' }}>
          <Card bodyStyle={{ padding: '16px' }}>
            <PageHeader style={{ padding: '0px 0px 16px 0px' }}
              title={<span style={{ fontSize: '16px' }}>{groupType === 'COMMON' ? '通用价格组' : '客户价格组'}</span>}
              onBack={() => this.props.history.go(-1)}
            />
            <Row>
              <SearchForm
                dataSource={searchData}
                handleSubmit={(obj) => this.handleSearch(obj)}
              />
            </Row>
          </Card>
        </Row>
        <Row>
          <Card bodyStyle={{ padding: '16px' }}>
            <Row style={{ marginBottom: '16px' }}>
              <Button type='primary' onClick={() => this.showPriceGroupModal({ groupType: groupType }, 1)}>
                <Icon type='plus' />新增价格组
              </Button>

              <Button type='primary' style={{ marginLeft: '12px' }} onClick={() => this.showProgressModal('PRICE_COMMON_GROUP')}>
                <Icon type='eye' />查看任务进度
              </Button>
            </Row>

            <Row>
              <Table rowKey='priceGroupId'
                columns={this.columns}
                dataSource={tableData}
                pagination={pagination}
              />
            </Row>
          </Card>
        </Row>

        {
          pricegroupModal
            ? <PriceGroupModal
              type={type}
              groupType={groupType}
              detailInfo={rowDetail}
              onConfirm={(params) => this.handleConfirmSavePriceGroup(params)}
              onCancel={this.closeModal}
            />
            : null
        }

        {
          progressModal
            ? <ProgressModal
              taskKey={state === 'future' ? 'FUTURESPRICE_COMMON_GROUP' : 'SPOTPRICE_COMMON_GROUP'}
              onConfirm={this.closeModal}
              onCancel={this.closeModal}
            />
            : null
        }

      </>
    )
  }
}

export default connect()(CommonGroupList)
