/**
 * 云采购模块
 */
import PurchProduct from '@/pages/cloud-purchase/purch-product/index' // 云采购配置
import CloudLibrary from '@/pages/cloud-purchase/cloud-library/index' // 云仓配置
import CloudPurchOrder from '@/pages/cloud-purchase/purch-order/index' // 云采购订单列表
import CloudSearchResult from '@/pages/cloud-purchase/cloudSearchResult/index'
import CloudLibProducts from '@/pages/cloud-purchase/cloudLibProducts/index' // 云库产品列表

export default [
  {
    path: '/admin/purchProduct',
    name: '云采购配置',
    component: PurchProduct
  },
  {
    path: '/admin/cloudLibrary',
    name: '云仓配置',
    component: CloudLibrary
  },
  {
    path: '/admin/cloudPurchOrder',
    name: 'EU云采购订单',
    component: CloudPurchOrder
  },
  {
    path: '/admin/cloudSearchResult',
    name: '搜索结果',
    component: CloudSearchResult
  },
  {
    path: '/admin/cloudLibProducts',
    name: '云库产品列表',
    component: CloudLibProducts
  }
]
