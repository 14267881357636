import React, { Component } from 'react'
import { Table, Button } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import InvoiceModal from './invoiceModal/index'

import Api from '@/common/api/index'
import filter from '@/common/utils/filter'
import { renderInvoiceOpenedStatusLabel } from '@/common/utils/mUtils'

const { pageSizeOptions } = filter

export default class UninvoiceList extends Component {
  static propTypes = {}
  state = {
    searchData: [
      {
        name: 'id',
        formType: 'Input',
        itemParams: {
          label: '开票申请编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'times',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {

        }
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: [],
    invoiceModal: false,
    rowDetail: {}
  }
  columns = [
    {
      title: '序号',
      key: 'iinvoiceOpeningCol-0',
      width: 40,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '开票申请编号',
      key: 'invoiceOpeningCol-1',
      dataIndex: 'id'
    },
    {
      title: '订单编号',
      key: 'invoiceOpeningCol-2',
      dataIndex: 'orderId'
    },
    {
      title: '订单日期',
      key: 'invoiceOpeningCol-3',
      dataIndex: 'orderDate',
      render: (text, record, index) => (
        <>{ record.orderDate ? moment(record.orderDate).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '订单金额',
      key: 'invoiceOpeningCol-4',
      dataIndex: 'invoiceAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.invoiceAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '开票状态',
      key: 'invoiceOpeningCol-5',
      dataIndex: 'invoiceOrderStatus',
      render: (text, record, index) => (
        <>{ renderInvoiceOpenedStatusLabel(record.invoiceOrderStatus) }</>
      )
    },
    {
      title: '操作',
      key: 'invoiceOpeningCol-6',
      render: (text, record, index) => (
        <>
          <Button type='primary' size='small' onClick={() => this.showInvoiceModal(record)}>查看详情</Button>
        </>
      )
    }
  ]

  componentDidMount () {
    this.getPurchaseOrderInvoiceOpeningList()
  }

  // 获取云采购订单-开票中数据列表
  getPurchaseOrderInvoiceOpeningList = async () => {
    const { query = {}, pages } = this.state

    const res = await Api.getPurchaseOrderInvoiceOpeningList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.times) {
      search.orderBeginDate = search.times.length > 0 ? search.times[0].format('YYYY-MM-DD') : ''
      search.orderEndDate = search.times.length > 0 ? search.times[1].format('YYYY-MM-DD') : ''

      delete search.times
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search || {}
    }, () => this.getPurchaseOrderInvoiceOpeningList())
  }

  // 点击查看详情
  showInvoiceModal = (record) => {
    this.setState({
      rowDetail: record,
      invoiceModal: true
    })
  }

  closeModal = () => {
    this.setState({
      rowDetail: {},
      invoiceModal: false
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getPurchaseOrderInvoiceOpeningList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getPurchaseOrderInvoiceOpeningList()
    })
  }

  render () {
    const { pages, searchData, tableData = [], invoiceModal = false, rowDetail = {} } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <div style={{ marginBottom: '20px' }}>
          <SearchForm dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </div>

        <Table rowKey='id'
          dataSource={tableData}
          columns={this.columns}
          rowClassName={(record, index) => record.invoiceSource === 'AFTERSALE_INVOICE' ? 'redColor' : null}
          pagination={pagination}
        />

        {
          invoiceModal
            ? <InvoiceModal
              openStatus={'OPENING'}
              ids={[rowDetail.id]}
              onCancel={this.closeModal}
            />
            : null
        }
      </>
    )
  }
}
