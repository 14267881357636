import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Badge, Button } from 'antd'

import Api from '@/common/api/index'

import Homeuitpl1 from '@/assets/images/homeui_tpl_1.png'
import Homeuitpl2 from '@/assets/images/homeui_tpl_2.png'

import styles from './homeuilist.module.scss'

export default class HomeDecorate extends Component {
  static propTypes = {
    history: PropTypes.object
  }
  state = {
    templateId: 0, // 当前使用的UI模板
    homeuiList: [
      { id: 0, name: '皮肤一', tplUrl: Homeuitpl1 },
      { id: 1, name: '皮肤二', tplUrl: Homeuitpl2 }
    ]
  }

  componentDidMount () {
    this.getHasAutoFitup()
  }

  // 获取首页模板配置信息
  getHasAutoFitup = () => {
    Api.getHasAutoFitup({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          templateId: data && data.modelType ? data.modelType : 0
        })
      }
    })
  }

  handleLinkToDetail = (id) => {
    this.props.history.push({ pathname: '/admin/posterDetail', state: { templateId: id } })
  }

  render () {
    const { templateId = 0, homeuiList = [] } = this.state
    return (
      <div>
        <Row gutter={24}>
          {
            homeuiList.map((item, i) => (
              <Col span={6} key={`homeui-${i}`}>
                <div className={`${styles.cardItem}`}>
                  <div className={`${styles.cardMeta}`}>
                    <div className={`${styles.status}`}>
                      {
                        templateId === item.id
                          ? <Badge status='success' text='使用中' />
                          : <Badge status='error' text='禁用中' />
                      }

                    </div>
                    <div className={`${styles.operate}`}>
                      <Button type='link' onClick={() => this.handleLinkToDetail(item.id)}>
                        皮肤详细
                      </Button>
                    </div>
                  </div>
                  <div className={`${styles.imgBox}`}>
                    <img className={`${styles.pic}`} src={item.tplUrl} />
                  </div>
                </div>
              </Col>
            ))
          }
        </Row>
      </div>
    )
  }
}
