import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Select } from 'antd'

const Option = Select.Option

export default class SetsalerModal extends Component {
  static propTypes = {
    data: PropTypes.object,
    getSellerList: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    salerType: PropTypes.number
  }
  state = {
    sellerList: [], // 销售员列表
    selectedkey: ''
  }

  componentDidMount () {
    const { data = {} } = this.props

    this.setState({
      selectedkey: +data.sysSalerId > 0 ? +data.sysSalerId : ''
    }, () => {
      this.getAllSellers()
    })
  }

  // 获取销售员列表
  getAllSellers = () => {
    let params = {
      pageNo: 1,
      pageSize: 999
    }

    this.props.getSellerList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          sellerList: data && data.list != null ? [{ supplierSysUserId: '', realName: '无' }, ...data.list] : [{ supplierSysUserId: '', realName: '无' }]
        })
      }
    })
  }

  handleConfirm = () => {
    const { data } = this.props
    const { selectedkey } = this.state

    let params = {
      euId: data.euId,
      realName: data.realName || '',
      company: data.company || '',
      sysSalerId: selectedkey
    }
    this.props.onConfirm(params)
  }

  onChangeSaler = (value) => {
    this.setState({
      selectedkey: value
    })
  }

  render () {
    const { onCancel, data = {}, salerType } = this.props
    const { sellerList = [], selectedkey = '' } = this.state

    return (
      <>
        <Modal title={salerType === 1 ? '分配销售员' : '更换销售员'}
          visible
          onOk={this.handleConfirm}
          onCancel={onCancel}
        >
          <Row style={{ marginBottom: '24px' }}>
            <label>公司名称：</label>
            <span>
              {data && data.company ? data.company : '--'}
            </span>
          </Row>
          {
            salerType === 2
              ? <Row style={{ marginBottom: '24px' }}>
                <label>原指定销售员：</label>
                {data && data.sellerName ? data.sellerName : '--'}
              </Row> : null
          }

          <Row>
            <label>{ salerType === 1 ? '指定销售员：' : '现定销售员：'}</label>
            <Select style={{ width: '200px' }}
              value={selectedkey}
              onChange={this.onChangeSaler}
            >
              {
                sellerList.map((item, i) => (
                  <Option value={item.supplierSysUserId} key={`saler-${i}`}>
                    {item.realName}
                  </Option>
                ))
              }
            </Select>
          </Row>
        </Modal>
      </>
    )
  }
}
