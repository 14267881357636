import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { Spin } from 'antd'
import { getStore } from './common/utils/mUtils'
import Login from './pages/login/index'
import Main from './components/main/index'
import CommodityDetail from './pages/pins/cloudproduct-detail/index' // 云产品详情页

import './App.css'

export default class App extends Component {
  render () {
    return (
      <>
        <Router>
          <Switch>
            <Route
              exact
              path='/'
              render={props => {
                const token = getStore('token')
                return token ? <Redirect to='/admin/home' /> : <Login {...props} />
              }}
            />
            <Route path='/login' component={Login} />
            <Route path='/commodity-detail' component={CommodityDetail} />
            <Route
              path='/admin'
              render={props => {
                const token = getStore('token')
                return token ? <Main {...props} /> : <Redirect to='/' />
              }}
            />
          </Switch>
        </Router>

        <div className='loadingWrapper' id='loadingWrapper'>
          <Spin />
        </div>
      </>
    )
  }
}
