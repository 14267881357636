import HomeRouter from './homeRouter' // 首页模块
import ProdRouter from './prodRouter' // 产品模块
import StoreRouter from './storeRouter' // 仓库模块
import SupplyRouter from './supplyRouter' // 云供应模块
import PurchRouter from './purchRouter' // 云采购模块
import MallRouter from './mallRouter' // 商城模块
import PriceRouter from './priceRouter' // 价格模块
import CusRouter from './cusRouter' // 客户模块
import PersonnelRouter from './personnelRouter' // 员工模块
import OrderRouter from './orderRouter' // 订单模块
import RactRouter from './ractRouter' // 合同模块
import FinanceRouter from './financeRouter' // 财务模块
import QuoteRouter from './quoteRouter' // 报价单模块
import NotifyRouter from './notifyRouter' // 消息通知模块
import PublicRouter from './publicRouter' // 公用页面路由
import OutapplyRouter from './outapplyRouter' // 第三方应用管理
import EnquireRouter from './enquireRouter' // 询价模块

import Error404 from './error' // 404错误页面提示

export const routerMap = [
  ...HomeRouter,
  ...ProdRouter,
  ...StoreRouter,
  ...SupplyRouter,
  ...MallRouter,
  ...PriceRouter,
  ...CusRouter,
  ...PersonnelRouter,
  ...OrderRouter,
  ...PurchRouter,
  ...RactRouter,
  ...FinanceRouter,
  ...QuoteRouter,
  ...PublicRouter,
  ...NotifyRouter,
  ...OutapplyRouter,
  ...EnquireRouter,
  ...Error404
]
