import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Card, Form, Button, message, Modal } from 'antd'
import moment from 'moment'

import EditForms from '../component/editForms'
import EditTable from './editTable'
import CustomerTable from './customerTable'

import Api from '@/common/api/index'

import styles from './notifys.module.scss'

const { confirm } = Modal

class NotifysForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }

  state = {
    type: 1,
    detailInfo: {},
    selectedRowKeys: []
  }

  componentDidMount () {
    const { state } = this.props.history.location
    this.setState({
      type: state.type,
      detailInfo: state.detailInfo
    }, () => {
      const { type = 1, detailInfo = {} } = this.state
      if (type === 2 && (['M_EU_PART', 'M_EU_SINGLE', 'M_EMPLOYEE_PART', 'M_EMPLOYEE_SINGLE'].includes(detailInfo.merchantNotifyType))) {
        this.getNotifySendedUserIdList()
      }
    })
  }

  // 获取需要发送消息的用户/员工列表
  getNotifySendedUserIdList = () => {
    const { detailInfo = {} } = this.state
    let params = {
      msgManagerId: detailInfo.id,
      pageNo: 1,
      pageSize: 99999
    }
    Api.getNotifySendedUserIdList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          selectedRowKeys: data.list || []
        })
      } else {
        message.error(res.message)
      }
    })
  }

  // 保存消息任务
  saveDispenseNotifyTask = (params, needBack) => {
    Api.saveDispenseNotifyTask(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('操作成功')
        if (needBack) {
          this.props.history.push({ pathname: '/admin/informationManage' })
        }
      } else {
        message.error(res.message)
      }
    })
  }

  // 点击取消
  handleCancel = () => {
    confirm({
      title: '是否保存已经编辑的信息',
      content: '',
      okText: '是',
      cancelText: '否',
      onOk: () => {
        const { type = 1, detailInfo = {} } = this.state
        let _status = 'UNKNOWN'
        if (type === 2) {
          _status = detailInfo.status === 'UNKNOWN' ? 'UNKNOWN' : 'SENT'
        }
        this.handleSubmit(_status, true)
      },
      onCancel: () => {
        this.props.history.push({ pathname: '/admin/informationManage' })
      }
    })
  }

  // 点击保存/发布
  handleSubmit = (status, needBack) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { type = 1, detailInfo = {}, selectedRowKeys = [] } = this.state

        if (values.msgType === 'SYSTEM_NOTIFY') {
          values['notifyType'] = values.notifyToEu
          delete values.notifyToEu
        } else if (values.msgType === 'TASK_DISTRIBUTE') {
          values['notifyType'] = values.notifyToMember
          delete values.notifyToMember
        }

        if (values['notifyType'] && (values['notifyType'] === 'M_EMPLOYEE_PART' || values['notifyType'] === 'M_EU_PART')) {
          if (selectedRowKeys.length === 0) {
            return message.warning('请选择要发送的对象')
          }
          values['userIdList'] = selectedRowKeys
        }

        if (type === 2) {
          values['id'] = detailInfo.id
        }

        if (values.sendType === 'TIME_TASK') {
          let { sendTime } = values
          values['sendTime'] = moment(sendTime).format('YYYY-MM-DD HH:mm:ss')
        }

        values['status'] = status

        this.saveDispenseNotifyTask(values, needBack)
      }
    })
  }

  onSelectedRowKeys = (keys) => {
    this.setState({
      selectedRowKeys: keys
    })
  }

  render () {
    const { type = 1, detailInfo = {}, selectedRowKeys = [] } = this.state

    return (
      <div>
        <Card bodyStyle={{ padding: '15px' }}>
          <Row>
            <Row className={`${styles.editContent}`}>
              <EditForms {...this.props}
                detailInfo={detailInfo}
              />
            </Row>

            {
              this.props.form.getFieldValue('msgType') === 'TASK_DISTRIBUTE' && this.props.form.getFieldValue('notifyToMember') === 'M_EMPLOYEE_PART'
                ? <Row>
                  <EditTable
                    selectedRowKeys={selectedRowKeys}
                    onSelect={(params) => this.onSelectedRowKeys(params)}
                  />
                </Row>
                : null
            }

            {
              this.props.form.getFieldValue('msgType') === 'SYSTEM_NOTIFY' && this.props.form.getFieldValue('notifyToEu') === 'M_EU_PART'
                ? <Row>
                  <CustomerTable
                    selectedRowKeys={selectedRowKeys}
                    onSelect={(params) => this.onSelectedRowKeys(params)}
                  />
                </Row>
                : null
            }

            <Row style={{ marginTop: '24px', textAlign: 'center' }}>
              <Button onClick={() => this.handleCancel()}>取消</Button>
              <Button type='primary' style={{ marginLeft: '15px' }}
                onClick={() => this.handleSubmit(`${type === 1 ? 'UNKNOWN' : (detailInfo.status === 'UNKNOWN' ? 'UNKNOWN' : 'SENT')}`, true)}
              >保存</Button>
              <Button type='primary' style={{ marginLeft: '15px' }}
                onClick={() => this.handleSubmit('SENT', true)}>发布</Button>
            </Row>
          </Row>
        </Card>
      </div>
    )
  }
}

const EditNotifys = Form.create()(NotifysForm)

export default EditNotifys
